import React from "react";
import { Link, browserHistory } from "react-router";

import {
  Loader,
  PhoneNumberLink,
  EmailLink,
} from "../../lib/coc-common-components";

import ContactsApi from "../../services/resources/ContactsApi";
import SchoolsApi from "../../services/resources/SchoolsApi";
// import { School } from "../../models/School";
import { SearchDirectory } from "../Contact/SearchDirectory";
import { SchoolList } from "../School/SchoolList/SchoolList";

// interface AdvancedSearchContainerProps {
//   location: { [key: string] };
//   params: { [key: string] };
// }

// interface AdvancedSearchContainerState {
//   loading: boolean;
//   displayMessage: string;
//   displayAndRedirectMessage: string;
//   displayDeleteMessage: string;
//   contactsToMerge: Array<any>;
//   visibleResults?: Array<object>;
//   schoolCount?: number;
//   filters?: SchoolFilters;
//   shluchimResults?: boolean;
//   studentsResults?: boolean;
//   schoolsResults?: boolean;
// }

// interface SchoolFilters {
//   mosadName?: string;
//   schoolName?: string;
//   selectedPage?: number;
//   resultsPerPage?: number;
//   countries?: string;
//   states?: string;
//   association?: string;
// }

const contactGenders = {
  Male: "Shliach",
  Female: "Shlucha",
  Unknown: "Unknown",
};
/** @type {SchoolFilters} */
const defaultSearchFilters = {
  mosadName: "",
  schoolName: "",
  selectedPage: 1,
  resultsPerPage: 10,
  countries: "All",
  states: "All",
  association: "All",
};

class AdvancedSearchContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      displayMessage: "",
      displayAndRedirectMessage: "",
      displayDeleteMessage: "",
      contactsToMerge: [],
      filters: {
        ...defaultSearchFilters,
        schoolName: `${this.props.location.pathname}${this.props.location.search}`,
      },
      tabs: [
        {
          id: 0,
          name: "Shluchim",
        },
        {
          id: 1,
          name: "Students",
        },
      ],
      activeTab: 0,
    };

    this.fetchContactsStudents = this.fetchContactsStudents.bind(this);
    this.fetchContactsShluchim = this.fetchContactsShluchim.bind(this);
    this.fetchCsvDownload = this.fetchCsvDownload.bind(this);
    this.fetchContactsList = this.fetchContactsList.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.cleanContactsToMerge = this.cleanContactsToMerge.bind(this);
    this.fetchResultsSchools = this.fetchResultsSchools.bind(this);
    // this.fetchForCsv = this.fetchForCsv.bind(this);
    // this.handleFiltersSchools = this.handleFiltersSchools.bind(this);
  }

  // handleFiltersSchools(val) {
  //     this.setState(
  //         {
  //             filters: {
  //                 ...this.state.filters,
  //                 countries: val.countries,
  //                 states: val.states,
  //                 association: val.association,
  //             },
  //         },
  //         this.fetchResultsSchools
  //     );
  // }

  fetchResultsSchools() {
    this.setState({ loading: true }, () =>
      SchoolsApi.search(this.state.filters && this.state.filters.schoolName, {
        basicListData: true,
        filterCountries: this.state.filters && this.state.filters.countries,
        filterStates: this.state.filters && this.state.filters.states,
        filterAssociation: this.state.filters && this.state.filters.association,
      })
        .then((response) => {
          this.setState({
            visibleResults: response.data.results,
            schoolCount: response.data.count,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.error(err);
        }),
    );
  }

  fetchContactsStudents(searchQuery, startingOffset, maxResults, searchParams) {
    return ContactsApi.searchStudents(
      `${this.props.location.pathname}${this.props.location.search}`,
      startingOffset,
      maxResults,
      searchParams,
    );
  }

  fetchContactsShluchim(searchQuery, startingOffset, maxResults, searchParams) {
    return ContactsApi.searchShluchim(
      `${this.props.location.pathname}${this.props.location.search}`,
      startingOffset,
      maxResults,
      searchParams,
    );
  }

  fetchCsvDownload(searchQuery, csvParams) {
    return ContactsApi.csvDownload(searchQuery, csvParams);
  }
  fetchContactsList(contactList) {
    return ContactsApi.listContacts(contactList);
  }

  mergeContacts(contacts) {
    return ContactsApi.mergeStudent(contacts);
  }

  handleChange(e, contact) {
    e.stopPropagation();
    const checked = e.target.checked;
    let { contactsToMerge } = this.state;

    if (checked && !contactsToMerge.some((c) => c.id === contact.id)) {
      contactsToMerge.push(contact);
    } else {
      contactsToMerge = contactsToMerge.filter((c) => c.id !== contact.id);
    }

    this.setState({
      contactsToMerge,
    });
  }

  cleanContactsToMerge() {
    this.setState({
      contactsToMerge: [],
    });
  }

  updateDisplayState(url) {
    this.setState(
      {
        shluchimResults: url.indexOf("Shluchim") >= 0,
        studentsResults: url.indexOf("Students") >= 0,
        schoolsResults: url.indexOf("Schools") >= 0,
      },
      () => {
        this.state.shluchimResults &&
          this.fetchContactsShluchim()
            .then((response) => {
              this.setState({ shluchimCount: response.data.count });
            })
            .catch((err) => {
              this.setState({ loading: false });
              console.error(err);
            });

        this.state.studentsResults &&
          this.fetchContactsStudents()
            .then((response) => {
              this.setState({ studentsCount: response.data.count });
            })
            .catch((err) => {
              this.setState({ loading: false });
              console.error(err);
            });

        this.state.schoolsResults && this.fetchResultsSchools();
      },
    );
  }

  componentDidMount() {
    var url = decodeURIComponent(`${this.props.location.search}`);
    this.updateDisplayState(url);
  }

  componentWillReceiveProps(newProps) {
    // if (newProps.location.search !== this.props.location.search) {
    var url = decodeURIComponent(`${newProps.location.search}`);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          schoolName: `${newProps.location.pathname}${newProps.location.search}`,
        },
      },
      () => this.updateDisplayState(url),
    );
    // }
  }

  render() {
    /** @param {boolean} mobile */
    const getFirstPhoneNumber = (contact, mobile) => {
      const firstPhone = contact.contactMethods.find(
        (cm) => cm.type === (mobile ? "CellPhoneNumber" : "PhoneNumber"),
      );
      return firstPhone ? (
        <PhoneNumberLink phoneNumber={firstPhone.value} />
      ) : (
        ""
      );
    };

    const renderTableHeader = () => {
      return (
        <tr>
          {activeTab === 1 && <th />}
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Phone</th>
          <th>School</th>
          <th />
        </tr>
      );
    };

    const renderContactShluchim = (contact) => {
      const emailAddresses = contact.contactMethods.filter(
        (cm) =>
          cm.type === "EmailAddress" &&
          cm.value.indexOf("proxymail.facebook.com") < 0,
      );
      const campuses =
        contact.contactAsShliach &&
        contact.contactAsShliach.campuses.map((c) => c.name);

      const contactPath = `/shluchim/${contact.id}/details`;

      return (
        <tr
          key={contact.id}
          onClick={() => browserHistory.push(contactPath)}
          style={{ cursor: "pointer" }}
        >
          <td>
            {contact.firstName} {contact.lastName}&nbsp;
            {contact.contactAsShliach && (
              <span className="label label-primary">
                {contact.gender
                  ? contactGenders[contact.gender]
                  : contactGenders.Unknown}
              </span>
            )}
            &nbsp;
            {contact.contactAsShliach &&
              contact.contactAsShliach.isPartTime && (
                <span className="label label-primary">Part-time</span>
              )}
          </td>
          <td>
            {emailAddresses.length > 0 && (
              <EmailLink emailAddress={emailAddresses[0].value} />
            )}{" "}
          </td>
          <td>{getFirstPhoneNumber(contact, true)}</td>
          <td>{getFirstPhoneNumber(contact, false)}</td>
          <td>{campuses && campuses.length > 0 ? campuses[0] : ""}</td>
          <td>
            <Link to={contactPath} className="btn btn-info btn-sm">
              View
            </Link>
          </td>
        </tr>
      );
    };

    const renderContactStudent = (contact) => {
      const emailAddresses = contact.contactMethods.filter(
        (cm) =>
          cm.type === "EmailAddress" &&
          cm.value.indexOf("proxymail.facebook.com") < 0,
      );
      const campuses = contact.studentCampuses.map((c) => c.name);

      const contactPath = `/students/${contact.id}`;

      return (
        <tr
          key={contact.id}
          onClick={() => browserHistory.push(contactPath)}
          style={{ cursor: "pointer" }}
        >
          <td onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              id={"checkbox-" + contact.id}
              className="styled-checkbox"
              onChange={(e) => this.handleChange(e, contact)}
              value={contact.id}
              checked={this.state.contactsToMerge.some(
                (c) => c.id === contact.id,
              )}
            />
            <label htmlFor={"checkbox-" + contact.id} />
          </td>
          <td>
            {contact.firstName} {contact.lastName}&nbsp;
            {contact.contactAsShliach && (
              <span className="label label-primary">
                {contact.gender
                  ? contactGenders[contact.gender]
                  : contactGenders.Unknown}
              </span>
            )}
            &nbsp;
            {contact.contactAsShliach &&
              contact.contactAsShliach.isPartTime && (
                <span className="label label-primary">Part-time</span>
              )}
          </td>
          <td>
            {emailAddresses.length > 0 && (
              <EmailLink emailAddress={emailAddresses[0].value} />
            )}{" "}
          </td>
          <td>{getFirstPhoneNumber(contact, true)}</td>
          <td>{getFirstPhoneNumber(contact, false)}</td>
          <td>{campuses && campuses.length > 0 ? campuses[0] : ""}</td>
          <td>
            <Link to={contactPath} className="btn btn-info btn-sm">
              View
            </Link>
          </td>
        </tr>
      );
    };

    const { tabs, activeTab } = this.state;

    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>Advanced Search</li>
        </ol>
        <React.Fragment>
          <div
            className="courses-details-tabs flex"
            style={{ marginLeft: "2px" }}
          >
            {tabs.map((tab, index) => (
              <p
                key={index}
                className={`large-text ${index > 0 ? "ml-40" : ""} ${
                  activeTab === index ? "active" : ""
                }`}
                onClick={() => this.setState({ activeTab: index })}
              >
                {tab.name}
              </p>
            ))}
          </div>
        </React.Fragment>
        {activeTab === 0 && (
          <SearchDirectory
            location={this.props.location}
            renderTableHeader={renderTableHeader}
            renderResult={renderContactShluchim}
            fetchResults={this.fetchContactsShluchim}
            fetchResultsList={this.fetchContactsList}
            fetchCsvDownload={this.fetchCsvDownload}
            mergeContacts={this.mergeContacts}
            searchContactType={"shluchim"}
            allowDownload={false}
            contactsToMerge={this.state.contactsToMerge}
            cleanContactsToMerge={this.cleanContactsToMerge}
            isAdvancedSearch={true}
          />
        )}
        {activeTab === 1 && (
          <SearchDirectory
            location={this.props.location}
            renderTableHeader={renderTableHeader}
            renderResult={renderContactStudent}
            fetchResults={this.fetchContactsStudents}
            fetchResultsList={this.fetchContactsList}
            fetchCsvDownload={this.fetchCsvDownload}
            mergeContacts={this.mergeContacts}
            searchContactType={"student"}
            allowDownload={false}
            contactsToMerge={this.state.contactsToMerge}
            cleanContactsToMerge={this.cleanContactsToMerge}
            isAdvancedSearch={true}
          />
        )}
        {this.state.schoolsResults && this.state.schoolCount > 0 && (
          <div>
            <h3>Schools</h3>
            <SchoolList schools={this.state.visibleResults || []} />
          </div>
        )}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default AdvancedSearchContainer;
