import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Storage } from "../../lib/coc-common-scripts";

import "./DataTable.css";

// export interface DataTableColumn {
//   Header: string;
//   Cell?: ((d) => any);
//   accessor: string | ((d) => any);
//   id?: string;
//   maxWidth?: number;
//   sortable?: boolean;
//   style?: object;
// }

// interface DataTableProps {
//   columns: Array<DataTableColumn>;
//   reportData: Array<any>;
//   fullClientSide?: boolean;
//   totalPageCount?: number;
//   defaultPageSize?: number;
//   onDataRequest?: (tableState, instance) => void;
//   striped?: boolean;
//   styleClasses?: string;
// }

const CustomHeader = ({ toggleSort, className, children, ...rest }) => {
  const isSortable = className.indexOf("-cursor-pointer") >= 0;
  const isSortedAsc = className.indexOf("-sort-asc") >= 0;
  const isSortedDesc = !isSortedAsc && className.indexOf("-sort-desc") >= 0;

  const sortClassName = !isSortable
    ? ""
    : "dt-glyphicon glyphicon " +
      (isSortedAsc
        ? "glyphicon-sort-by-attributes"
        : isSortedDesc
        ? "glyphicon-sort-by-attributes-alt"
        : "glyphicon glyphicon-sort");
  const newClassName = className
    .split(" ")
    .map((cn) =>
      cn === "-sort-asc"
        ? "dt-sort-asc"
        : cn === "-sort-desc"
        ? "dt-sort-desc"
        : cn === "-cursor-pointer"
        ? "-cursor-pointer dt-sortable"
        : "",
    )
    .join(" ");

  return (
    <div
      className={"rt-th " + newClassName}
      onClick={(e) => toggleSort && toggleSort(e)}
      {...rest}
    >
      <div className="search-mobile-header">
        <span />
        {children}
        <div className={sortClassName} />
      </div>
    </div>
  );
};

const DataTable = ({
  columns,
  reportData,
  fullClientSide,
  totalPageCount,
  defaultPageSize,
  onDataRequest,
  striped = true,
  styleClasses,
}) => {
  /** @type {number} */
  let newDefaultPageSize;
  if (defaultPageSize) {
    Storage().storeItem("rtPageSize", defaultPageSize.toString());
    newDefaultPageSize = defaultPageSize;
  } else {
    /** @type {string} */
    const storedSize = Storage().getItem("rtPageSize") || "";
    newDefaultPageSize = +storedSize || 10;
  }
  /** @param {number} pageSize
   * @param {number} pageIndex
   */
  const handleOnPageSizeChange = (pageSize, pageIndex) => {
    Storage().storeItem("rtPageSize", pageSize.toString());
  };

  return fullClientSide ? (
    <ReactTable
      columns={columns}
      data={reportData}
      defaultPageSize={newDefaultPageSize}
      className={(striped ? "-striped" : "") + (styleClasses || "")}
      ThComponent={CustomHeader}
      defaultSorting={
        (columns && [{ id: columns[0].id || columns[0].accessor }]) || []
      }
      onPageSizeChange={handleOnPageSizeChange}
    />
  ) : (
    <ReactTable
      manual={true}
      columns={columns}
      data={reportData}
      pages={totalPageCount}
      defaultPageSize={newDefaultPageSize}
      onFetchData={onDataRequest}
      className={(striped ? "-striped" : "") + (styleClasses || "")}
      ThComponent={CustomHeader}
      onPageSizeChange={handleOnPageSizeChange}
    />
  );
};

export default DataTable;
