import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import RewardsTableActions from "./RewardsTableActions";
import RewardsTableFilters from "./RewardsTableFilters";
import RewardsTableHeader from "./RewardsTableHeader";
import RewardsTableRow from "./RewardsTableRow";

import EduApi from "../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class RewardsTable extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    results: 12,
    rewards: [],
    sortBy: "name",
    success: true,
    totalRewards: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterRewards = (filters) => {
    this.setState({ filters }, () => {
      this.getRewards();
    });
  };

  getRewards = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        EduApi.getEduProgramRewards(
          this.apiSignal.token,
          this.props.eduProgram.id,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ rewards, totalRewards }) => {
            this.setState({
              loading: false,
              rewards,
              totalRewards,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                rewards: [],
                success: false,
                totalRewards: 0,
              });
            }
          });
      },
    );
  };

  getRewardsForExport = async () => {
    const { sortBy, filters } = this.state;

    const rewardsForExport = await EduApi.getEduProgramRewards(
      this.apiSignal.token,
      this.props.eduProgram.id,
      undefined,
      undefined,
      sortBy,
      filters,
      true,
    );

    return rewardsForExport.rewards;
  };

  render() {
    const { eduProgram, mobileMode, viewOnly } = this.props;

    const { loading, page, results, rewards, sortBy, success, totalRewards } =
      this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Rewards</p>
          <RewardsTableActions
            eduProgram={eduProgram}
            getRewardsForExport={this.getRewardsForExport}
            viewOnly={viewOnly}
          />
        </div>
        <div className="courses-table">
          <PaginatedTable
            filterComponent={
              <RewardsTableFilters applyFilters={this.filterRewards} />
            }
            loading={loading}
            loadData={this.getRewards}
            mobileMode={mobileMode}
            page={page}
            records={rewards}
            renderHeader={() => <RewardsTableHeader />}
            renderRow={(reward, index) => (
              <RewardsTableRow
                eduProgramScheduleId={eduProgram.programScheduleID}
                key={index}
                reward={reward}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            sortOptions={[
              { id: "name", name: "Name" },
              { id: "type", name: "Type" },
            ]}
            success={success}
            totalCount={totalRewards}
          />
        </div>
      </div>
    );
  }
}
