import React from "react";
import Select from "react-select";
import StudentsListSearch from "./StudentsTableSearch";
import _debounce from "lodash.debounce";

export default class StudentsTableFilters extends React.PureComponent {
  constructor(props) {
    super();

    const {
      grantId,
      grantStatus,
      includeInactive,
      keyword,
      shliachId,
      studentId,
      page,
    } = props.filters;

    this.state = {
      grantId,
      grantStatus,
      includeInactive,
      keyword,
      shliachId,
      studentId,
      page,
    };
  }

  componentDidUpdate(prevProps) {
    //if includeInactive filter is changed externally (by View All Students button in no results list), update state to reflect the change in the UI
    const {
      filters: { includeInactive },
    } = this.props;
    if (prevProps.filters.includeInactive !== includeInactive) {
      this.setState({ includeInactive });
    }
  }

  onChange = (name, value, debounce, other) => {
    this.setState(
      {
        [name]: value,
        ...(other || {}),
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => this.props.applyFilters(this.state);

  onFilterDebounce = _debounce(this.onFilter, 500);

  render() {
    const {
      grants,
      grantStatuses,
      scheduleId,
      shluchimList: {
        errorMessage: shluchimErrorMessage,
        loading: shluchimLoading,
        list: shluchim,
      },
    } = this.props;

    const {
      grantId,
      grantStatus,
      includeInactive,
      keyword,
      shliachId,
      studentId,
    } = this.state;

    const grantOptions = grants
      ? grants.map((g) => ({
          value: g.id,
          label: g.name,
        }))
      : [];

    const grantStatusOptions = grantStatuses
      ? grantStatuses.map((s) => ({
          value: s.enumValue,
          label: s.displayValue,
        }))
      : [];

    const shluchimOptions =
      shluchim &&
      shluchim.map((s) => ({
        label: s.fullName,
        value: s.shliachID,
      }));

    return (
      <div>
        <div className="flex flex-align-center lamplighters-students-filters mt-8">
          <StudentsListSearch
            isLamplightersActiveOnlyList={!includeInactive}
            onChange={this.onChange}
            searchText={keyword}
            studentId={studentId}
            scheduleId={scheduleId}
          />
          <div
            className="flex flex-align-center ml-24 mobile-ml-0"
            style={{ minWidth: "200px" }}
          >
            <p className="mr-8 nowrap-text small-text">Filter by</p>
            <Select
              className="lamplighters-filter-select"
              isClearable={true}
              isSearchable={true}
              onChange={(val) =>
                this.onChange("shliachId", val ? val.value : "")
              }
              options={shluchimOptions}
              placeholder={
                shluchimLoading ? "Loading Shluchim..." : "All Shluchim"
              }
              value={shliachId}
            />
            {shluchimErrorMessage && (
              <p className="error-text xs-text">
                Something went wrong and Shluchim could not be retreived.
              </p>
            )}
          </div>
          <div
            className="flex flex-align-center ml-24 mobile-ml-0"
            style={{ minWidth: "200px" }}
          >
            <p className="mr-8 nowrap-text small-text">Filter by</p>
            <Select
              className="lamplighters-filter-select"
              onChange={(val) => this.onChange("grantId", val ? val.value : "")}
              options={grantOptions}
              placeholder="All grants"
              searchable={false}
              value={grantOptions.find(
                (o) => o.value.toString() === decodeURIComponent(grantId),
              )}
            />
          </div>
          <div
            className="flex flex-align-center ml-24 mobile-ml-0"
            style={{ minWidth: "200px" }}
          >
            <p className="mr-8 nowrap-text small-text">Filter by</p>
            <Select
              className="lamplighters-filter-select"
              onChange={(val) =>
                this.onChange("grantStatus", val ? val.value : "")
              }
              options={grantStatusOptions}
              placeholder="All grant statuses"
              searchable={false}
              value={grantStatusOptions.find(
                (o) => o.value.toString() === decodeURIComponent(grantStatus),
              )}
            />
          </div>
        </div>
        <div className="flex flex-align-center custom-checkbox-container lamplighters-students-checkbox accent-text mt-16">
          <input
            checked={!includeInactive}
            className="custom-checkbox"
            id="lamplighters-active-checkbox"
            onChange={(e) =>
              this.onChange("includeInactive", !e.target.checked)
            }
            type="checkbox"
          />
          <label
            className="flex-align-center"
            htmlFor="lamplighters-active-checkbox"
          >
            Only show active Lamplighters students
          </label>
        </div>
      </div>
    );
  }
}
