import React from "react";
// import PropTypes from "prop-types";

const truncateString = (str, length) => {
  return str.length > length ? str.slice(0, length) + "..." : str;
};
const mql = window.matchMedia && window.matchMedia(`(min-width: 800px)`);

// const propTypes = {
//     phoneNumber: PropTypes.string,
// };

export const PhoneNumberLink = ({ phoneNumber = "" }) => {
  if (mql) {
    if (phoneNumber !== null && !mql.matches) {
      return (
        <a onClick={(e) => e.stopPropagation()} href={`tel:${phoneNumber}`}>
          {truncateString(phoneNumber, 15)}
        </a>
      );
    }
  }
  return <span> {phoneNumber}</span>;
};
// PhoneNumberLink.propTypes = propTypes;
