import React from "react";
import { Bar, BarChart, Cell, XAxis, YAxis } from "recharts";
import { formatCurrency, formatNumber } from "../../../../lib";

const barColors = [
  { dark: "#6369D1", light: "rgba(99,105,209,0.08)" },
  { dark: "#63C674", light: "rgba(99,198,116,0.08)" },
  { dark: "#EC5150", light: "rgba(236,81,80,0.08)" },
  { dark: "#F3893D", light: "rgba(243,137,61,0.08)" },
  { dark: "#53B7E8", light: "rgba(83,183,232,0.08)" },
];

function getBarColor(index) {
  return (
    barColors[index < 5 ? index : index % 5] || {
      dark: "rgba(0,0,0,0.87)",
      light: "#F4F4F4",
    }
  );
}

class CustomSalesByShliachLabel extends React.Component {
  render() {
    const { data, getShliachLabel, height, index, x, y } = this.props;
    const fontSize = 14;
    const fill = getBarColor(index).dark;

    const { currencyCode, totalRaised, totalTicketsSold } = data[index];

    return (
      <text
        dx={5}
        dy={height / 2 + fontSize / 2}
        fill={fill}
        fontSize={fontSize}
        fontFamily="sans-serif"
        textAnchor="start"
        x={x}
        y={y}
      >
        {getShliachLabel(data[index])} ({formatNumber(totalTicketsSold)} /{" "}
        {formatCurrency(totalRaised, currencyCode)})
      </text>
    );
  }
}

export default class RaffleSalesByShliachChart extends React.Component {
  getShliachLabel = ({ shliachFirstName, shliachLastName, shluchaFirstName }) =>
    `${shliachFirstName || ""}${
      shliachFirstName && shluchaFirstName
        ? ` and ${shluchaFirstName}`
        : shluchaFirstName
        ? ` ${shluchaFirstName}`
        : ""
    } ${shliachLastName}`;

  render() {
    const { data = [] } = this.props;

    return (
      <div className="graph-outline ml-24">
        <p className="medium-text fw-700">
          {data.length < 10 ? "Sales By Shliach" : "Top 10 Sales by Shluchim"}
        </p>

        {!data.length ? (
          <span>No Sales By Shliach found</span>
        ) : (
          <BarChart data={data} height={300} layout="vertical" width={500}>
            <Bar
              dataKey="totalTicketsSold"
              fill="#8884d8"
              label={
                <CustomSalesByShliachLabel
                  data={data}
                  getShliachLabel={this.getShliachLabel}
                />
              }
            >
              {data.map((entry, index) => {
                const fill = getBarColor(index).light;
                return <Cell key={index} fill={fill} />;
              })}
            </Bar>

            <YAxis dataKey={this.getShliachLabel} hide type="category" />

            <XAxis
              dataKey="totalTicketsSold"
              tickFormatter={this.formatSales}
              type="number"
            />
          </BarChart>
        )}
      </div>
    );
  }
}
