import React from "react";
import Checkbox from "../../../shared/Checkbox";
import Toggle from "../../../shared/Toggle";
import debounce from "lodash.debounce";

export default class RaffleEnrollmentFilters extends React.PureComponent {
  state = {
    enrolled: true,
    keyword: "",
    onlyWithTickets: false,
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
        ...(name === "enrolled" && !value && this.state.onlyWithTickets
          ? { onlyWithTickets: false } //clear onlyWithTickets filter when table is toggled to unenrolled
          : {}),
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => {
    let { sortBy, sortOptions } = this.props;
    if (
      !this.state.enrolled &&
      sortOptions.find((option) => option.id === sortBy).enrolledOnly
    ) {
      sortBy = undefined;
    }
    this.props.filterEnrollments(this.state, sortBy);
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { enrolled, keyword, onlyWithTickets } = this.state;

    return (
      <div className="flex flex-align-center">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search Name, Chabad House or Campus"
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        <div className="ml-24">
          <Toggle
            name="enrolled"
            onChange={this.onChange}
            options={[
              {
                value: true,
                display: "Enrolled",
              },
              {
                value: false,
                display: "Not Enrolled",
              },
            ]}
            value={enrolled}
          />
        </div>
        {enrolled && (
          <div className="ml-16 mr-16">
            <Checkbox
              checked={!!onlyWithTickets}
              className="raffle-tickets-sold-filter"
              label="Only with tickets sold"
              name="onlyWithTickets"
              onChange={this.onChange}
            />
          </div>
        )}
      </div>
    );
  }
}
