import React from "react";
import { Contacts } from "./Contacts";

export class Shluchim extends React.Component {
  render() {
    return (
      <Contacts
        {...this.props}
        searchContactType="shliach"
        contactBasePath="/shluchim"
        breadcrumbRoot="Shluchim"
      />
    );
  }
}
