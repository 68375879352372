import React from "react";
import { Link, browserHistory } from "react-router";
import Autocomplete from "react-autocomplete";
import debounce from "lodash.debounce";
import moment from "moment";
import { Storage, ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons,
  // ModalButton,
  EmailLink,
  PhoneNumberLink,
  typeaheadStyles,
} from "../../lib/coc-common-components";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import ContactsApi from "../../services/resources/ContactsApi";
import { Paginator } from "../common/Paginator";
import { ContactUtils } from "../Contact";

import ImpersonateUser from "../shared/ImpersonateUser";
import { impersonationUserTypes } from "../../lib";

const contactGenders = {
  Male: "Shliach",
  Female: "Shlucha",
  Unknown: "Unknown",
};

// interface AdminProps {
//   mobileMode: boolean;
//   [propName: string];
// }

// interface AdminState {
//   displayError?: string;
//   loading?: boolean;
//   updatingContact?: AdminContact | null;
//   contacts?: Array<any>;
//   resultsPerPage?: number;
//   selectedPage?: number;
//   totalResults?: number;
//   contactSearch?: ContactAutocomplete;
//   processingModalAction?: boolean;
// }

// interface AdminContact {
//   id: number;
//   isAdmin: boolean;
// }

// interface ContactAutocomplete {
//   searchText: string;
//   suggestions: Array<any>;
//   selectedContact;
// }

export default class Admins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: "",
      contactSearch: { searchText: "", suggestions: [], selectedContact: null },
      loading: false,
      updatingContact: null,
      contacts: [],
      resultsPerPage: +Storage().getItem("pgrPageSize") || 10,
      selectedPage: 1,
      totalResults: 0,
      processingModalAction: false,
    };

    this.onSelectResultsPerPage = this.onSelectResultsPerPage.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.addAdmin = this.addAdmin.bind(this);
    this.getAddAdminConfirmContent = this.getAddAdminConfirmContent.bind(this);
    this.handleSearchContactControlChange =
      this.handleSearchContactControlChange.bind(this);
    this.getContactSuggestions = debounce(
      this.getContactSuggestions.bind(this),
      200,
    );
  }
  /** @param {string} searchQuery
   * @param {number} selectedPage
   * @param {number} maxResults
   */
  fetchContacts(searchQuery, selectedPage, maxResults) {
    this.setState({ loading: true }, () =>
      ContactsApi.searchAdmins(
        searchQuery,
        true,
        (selectedPage - 1) * (this.state.resultsPerPage || 10),
        maxResults,
      )
        .then((response) => {
          this.setState({
            loading: false,
            contacts: response.data.results,
            totalResults: response.data.count,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  onSelectResultsPerPage(event) {
    this.setState(
      {
        resultsPerPage: parseInt(event.target.value, 10),
      },
      () => this.onChangePage(1, this.state.resultsPerPage || 10),
    );
  }
  /** @param {number} selectedPage
   * @param {number} resultsPerPage
   */
  onChangePage(selectedPage, resultsPerPage) {
    this.setState({ selectedPage });
    this.fetchContacts(
      this.props.location.query.q,
      selectedPage,
      resultsPerPage,
    );
  }
  /** @param {AdminContact} contactToUpdate */
  handleToggleClick(contactToUpdate) {
    return (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ updatingContact: contactToUpdate });
    };
  }

  toggleAdmin() {
    this.setState({ processingModalAction: true }, () => {
      const updatingContact = this.state.updatingContact || {
        isAdmin: true,
        id: 0,
      };
      const newIsAdminValue = !updatingContact.isAdmin;
      ContactsApi.updateAdmin(
        (this.state.updatingContact || { id: 0 }).id,
        newIsAdminValue,
      )
        .then((response) => {
          const newContacts = (this.state.contacts || []).map((c) =>
            c.id !== updatingContact.id
              ? c
              : { ...c, isAdmin: newIsAdminValue },
          );
          this.setState({
            updatingContact: null,
            loading: false,
            processingModalAction: false,
            contacts: newContacts,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({
            loading: false,
            processingModalAction: false,
            updatingContact: null,
            displayError: errors.join("\n"),
          });
        });
    });
  }

  addAdmin() {
    this.setState({ processingModalAction: true }, () => {
      const contactSearch = this.state.contactSearch || {
        selectedContact: { id: 0 },
      };
      const selectedContactId = contactSearch.selectedContact.id;
      ContactsApi.updateAdmin(selectedContactId, true)
        .then((response) => {
          this.fetchContacts(
            this.props.location.query.q,
            this.state.selectedPage || 0,
            this.state.resultsPerPage || 10,
          );
          this.setState({
            loading: false,
            processingModalAction: false,
            contactSearch: {
              searchText: "",
              suggestions: [],
              selectedContact: null,
            },
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({
            loading: false,
            processingModalAction: false,
            updatingContact: null,
            displayError: errors.join("\n"),
          });
        });
    });
  }

  getAddAdminConfirmContent(contact) {
    const contactAsShliach = contact.contactAsShliach;
    const mosad = (contactAsShliach && contactAsShliach.mosad) || {};

    let shliachHomeAddress = "";
    let shliachMosadAddress = "";

    if (contactAsShliach) {
      shliachHomeAddress = [
        contactAsShliach.homeAddressLine1,
        contactAsShliach.homeAddressLine2,
        contactAsShliach.homeCity,
        contactAsShliach.homeState,
        contactAsShliach.homePostCode,
        contactAsShliach.homeCountry,
      ]
        .filter(ContactUtils.valueIsPresent)
        .join(", ");

      shliachMosadAddress = [
        mosad.address,
        mosad.address2,
        mosad.city,
        mosad.state,
        mosad.zipCode,
        mosad.country,
      ]
        .filter(ContactUtils.valueIsPresent)
        .join(", ");
    }

    return (
      <div className="row">
        <dl className="dl-horizontal col-md-12">
          {contact.fbid && (
            <div>
              <dt>Facebook Photo</dt>
              <dd>
                <img
                  src={`https://graph.facebook.com/${contact.fbid}/picture`}
                  alt="facebook profile"
                />
              </dd>
            </div>
          )}
          <dt>First Name</dt>
          <dd>{contact.firstName}</dd>
          <dt>Last Name</dt>
          <dd>{contact.lastName}</dd>
          {contact.dob && moment(contact.dob).isValid() && (
            <div>
              <dt>Birthdate</dt>
              <dd>{moment(contact.dob).format("MMMM D, YYYY")}</dd>
            </div>
          )}
          {contactAsShliach && shliachHomeAddress && (
            <div>
              <dt>Home Address</dt>
              <dd>{shliachHomeAddress}</dd>
            </div>
          )}
          {contactAsShliach && shliachMosadAddress && (
            <div>
              <dt>Mosad Address</dt>
              <dd>{shliachMosadAddress}</dd>
            </div>
          )}
        </dl>
      </div>
    );
  }

  handleSearchContactControlChange(event) {
    let newValue = event.currentTarget.value;
    this.setState(
      {
        contactSearch: {
          searchText: newValue,
          suggestions: [],
          selectedContact: null,
        },
      },
      () => {
        if (newValue) {
          this.getContactSuggestions(newValue);
        }
      },
    );
  }
  /** @param {string} queryString */
  getContactSuggestions(queryString) {
    ContactsApi.searchAdmins(queryString, false, 0, 10)
      .then((response) => {
        this.setState({
          contactSearch: Object.assign({}, this.state.contactSearch, {
            suggestions: response.data.results,
          }),
        });
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err);
        this.setState({ loading: false, displayError: errors.join("\n") });
      });
  }

  handleContactSelected(selectedValue, item) {
    this.setState({ loading: true }, () => {
      ContactsApi.get(selectedValue)
        .then((response) => {
          this.setState({
            loading: false,
            contactSearch: {
              searchText: "",
              suggestions: [],
              selectedContact: response.data,
            },
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        });
    });
  }

  confirmAddAdminButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "Confirm",
      className: "btn btn-danger",
      onClick: this.addAdmin,
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({
          contactSearch: Object.assign({}, this.state.contactSearch, {
            selectedContact: null,
          }),
        }),
    });
    return buttons;
  }

  confirmToggleButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "Confirm",
      className: "btn btn-danger",
      onClick: this.toggleAdmin,
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ updatingContact: null }),
    });
    return buttons;
  }

  errorOnSaveButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayError: "" }),
    });
    return buttons;
  }

  componentDidMount() {
    if (
      !AuthService.UserHasClaim(PermissionClaims.DirectoryRead) ||
      !AuthService.UserHasClaim(PermissionClaims.RoleManager)
    ) {
      return;
    }

    this.fetchContacts(
      this.props.location.query.q,
      this.state.selectedPage || 0,
      this.state.resultsPerPage || 10,
    );
  }

  render() {
    if (
      !AuthService.UserHasClaim(PermissionClaims.DirectoryRead) ||
      !AuthService.UserHasClaim(PermissionClaims.RoleManager)
    ) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    /** @param {boolean} mobile */
    const getFirstPhoneNumber = (contact, mobile) => {
      const firstPhone = contact.contactMethods.find(
        (cm) => cm.type === (mobile ? "CellPhoneNumber" : "PhoneNumber"),
      );
      return firstPhone ? (
        this.props.mobileMode ? (
          <PhoneNumberLink phoneNumber={firstPhone.value} />
        ) : (
          firstPhone.value
        )
      ) : (
        ""
      );
    };

    const renderContact = (contact) => {
      const contactPath = `/users/${contact.id}`;
      const emailAddresses = contact.contactMethods.filter(
        (cm) =>
          cm.type === "EmailAddress" &&
          cm.value.indexOf("proxymail.facebook.com") < 0,
      );

      return (
        <tr key={contact.id} onClick={() => browserHistory.push(contactPath)}>
          <td>
            <Link to={contactPath}>
              {contact.firstName + " " + contact.lastName}
            </Link>
            &nbsp;
            {contact.contactAsShliach && (
              <span className="label label-primary">
                {contact.gender
                  ? contactGenders[contact.gender]
                  : contactGenders.Unknown}
              </span>
            )}
            &nbsp;
            {contact.contactAsShliach &&
              contact.contactAsShliach.isPartTime && (
                <span className="label label-primary">Part-time</span>
              )}
          </td>
          <td>
            {emailAddresses.length > 0 && (
              <EmailLink emailAddress={emailAddresses[0].value} />
            )}{" "}
          </td>
          <td>{getFirstPhoneNumber(contact, true)}</td>
          <td>{getFirstPhoneNumber(contact, false)}</td>
          <td style={{ textAlign: "center" }}>
            {contact.isAdmin ? "Yes" : "No"}
          </td>
          <td>
            <span
              className={
                "btn btn-sm " + (contact.isAdmin ? "btn-danger" : "btn-primary")
              }
              onClick={this.handleToggleClick(contact)}
            >
              {contact.isAdmin ? "Revoke" : "Grant"}
            </span>
            {AuthService.UserHasClaim(PermissionClaims.UserImpersonation) &&
              contact.isAdmin &&
              !!emailAddresses[0]?.value && (
                <ImpersonateUser
                  className="mt-8"
                  username={emailAddresses[0].value}
                  userType={impersonationUserTypes.Admin}
                />
              )}
          </td>
        </tr>
      );
    };

    const renderContactMobile = (contact) => {
      const contactPath = `/users/${contact.id}`;

      return (
        <tr
          key={contact.id}
          onClick={() => browserHistory.push(contactPath)}
          style={{ cursor: "pointer" }}
        >
          <td>{contact.firstName + " " + contact.lastName}&nbsp;</td>
          <td>
            {getFirstPhoneNumber(contact, true) ||
              getFirstPhoneNumber(contact, false)}
          </td>
        </tr>
      );
    };

    const renderTableHeader = () => {
      return (
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Phone</th>
          <th style={{ textAlign: "center" }}>App&nbsp;User</th>
          <th />
          <th />
        </tr>
      );
    };

    const rendertTableHeaderMobile = () => {
      return (
        <tr>
          <th>Name</th>
          <th>Phone</th>
        </tr>
      );
    };

    const contactSearch = this.state.contactSearch || {
      searchText: "",
      suggestions: [],
      selectedContact: { firstName: "", lastName: "" },
    };

    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>Directory</li>
          <li>Users</li>
        </ol>
        <div className="list-container">
          <h1>Users</h1>
          <div className="row">
            <div className="form-group">
              <div className="col-sm-12 text-left">
                <div className="results-summary results-summary-small">
                  <div className="results-summary-item">
                    <i className="icon icon-results">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span className="results-summary-desc">
                      <label className="control-label">Results</label>
                      {this.state.totalResults}
                    </span>
                  </div>
                </div>
                <Link
                  to={"/users/new"}
                  className="btn btn-primary btn-sm rfloat"
                >
                  Add New
                </Link>
              </div>
            </div>
            <div className="col-sm-6" />
          </div>

          <hr />

          {!this.props.mobileMode && (
            <div className="row">
              <div className="col-md-4">
                <span style={{ whiteSpace: "nowrap" }}>
                  Add existing contact as application User
                </span>
              </div>
            </div>
          )}
          {!this.props.mobileMode && (
            <div className="row">
              <div className="col-md-4">
                <Autocomplete
                  autoHighlight={false}
                  value={contactSearch.searchText}
                  inputProps={{
                    style: { borderRadius: "0", fontSize: "16px" },
                    placeholder: "Search by name",
                  }}
                  getItemValue={(item) => item.id}
                  items={contactSearch.suggestions}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={Math.random()}
                      className={
                        "typeahead-suggestion " +
                        (isHighlighted ? "highlighted" : "")
                      }
                    >
                      {item.firstName + " " + item.lastName}
                    </div>
                  )}
                  menuStyle={typeaheadStyles.typeaheadSuggestions}
                  onChange={this.handleSearchContactControlChange}
                  onSelect={(value, item) => {
                    this.handleContactSelected(value, item);
                  }}
                />
              </div>
            </div>
          )}

          <div className="content-window">
            <table className="table table-custom table-custom-responsive">
              <thead>
                {this.props.mobileMode
                  ? rendertTableHeaderMobile()
                  : renderTableHeader()}
              </thead>
              <tbody>
                {(this.state.contacts || []).map(
                  this.props.mobileMode ? renderContactMobile : renderContact,
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            totalResults={this.state.totalResults || 0}
            currentPage={this.state.selectedPage || 1}
            handleOnChange={this.onChangePage}
          />
          {contactSearch.selectedContact &&
            contactSearch.selectedContact.lastName && (
              <ModalWithButtons
                buttons={this.confirmAddAdminButtons()}
                text={`Are you sure you want to grant permission for ${
                  contactSearch.selectedContact.firstName +
                  " " +
                  contactSearch.selectedContact.lastName
                }?`}
                content={this.getAddAdminConfirmContent(
                  contactSearch.selectedContact,
                )}
                disableButtons={this.state.processingModalAction}
              />
            )}
          {this.state.updatingContact && (
            <ModalWithButtons
              buttons={this.confirmToggleButtons()}
              text={`Are you sure you want to ${
                this.state.updatingContact.isAdmin ? "revoke" : "grant"
              } this contact as an application user?`}
              disableButtons={this.state.processingModalAction}
            />
          )}
          {this.state.displayError && (
            <ModalWithButtons
              buttons={this.errorOnSaveButtons()}
              text={this.state.displayError}
            />
          )}
          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }
}
