import React from "react";
import { Link } from "react-router";
import ExportCSVButton from "../../shared/ExportCSVButton";
import moment from "moment";

export default class CoursesTableActions extends React.PureComponent {
  render() {
    const {
      eduProgram: { name, programScheduleID },
      getCoursesForExport,
      viewOnly,
    } = this.props;

    return (
      <div className="flex">
        {!viewOnly && (
          <Link
            className="btn custom-btn btn-accent flex flex-align-center mr-16"
            to={`/edu/${programScheduleID}/courses`}
          >
            Add
          </Link>
        )}
        <ExportCSVButton
          className="btn custom-btn btn-accent flex flex-align-center"
          fileName={`${name.replace(/ /g, "_")}_Courses`}
          getExportData={getCoursesForExport}
          headers={[
            { label: "Course Name", value: "name" },
            { label: "Category", value: "categoryDisplay" },
            {
              label: "Date Created",
              value: ({ createdOn }) =>
                createdOn ? moment(createdOn).format("MM/DD/YYYY") : null,
            },
            {
              label: "Status",
              value: ({ isOpen }) => (isOpen ? "Active" : "Inactive"),
            },
          ]}
        />
      </div>
    );
  }
}
