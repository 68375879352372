import axios from "axios";
import urlBase from "../UrlBaseUtil";
import AuthClient from "./AuthClient";

const Auth = {
  login: (payload) => {
    return axios.post("api/auth/token", payload, {
      baseURL: urlBase.getLegacyApiBase(),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  googleLogin: (token) => {
    return axios.post(
      "api/auth/googleLogin",
      { token, authType: "admin" },
      {
        baseURL: urlBase.getLegacyApiBase(),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    );
  },

  impersonateUser: async (authType, username, cancelToken) => {
    return AuthClient.post(
      "auth/impersonate",
      { authType, username },
      { cancelToken },
    );
  },
};

export default Auth;
