import React from "react";
import AddParticipantsModal from "./AddParticipantsModal";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import PaginatedTable from "../../../shared/PaginatedTable";
import ParticipantsTableHeader from "./ParticipantsTableHeader";
import ParticipantsTableRecord from "./ParticipantsTableRecord";
import ParticipantsTableSearch from "./ParticipantsTableSearch";

export default class EventParticipants extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    participants: [],
    participantToRemove: null,
    results: 12,
    showConfirmRemoveParticipantModal: false,
    showAddParticipantsModal: false,
    success: true,
    totalParticipants: 0,
  };

  componentDidUpdate(prevProps) {
    //reload table on external update from cancelled changes
    const {
      event: { participatingChabadHouses },
    } = this.props;
    const {
      event: { participatingChabadHouses: prevParticipatingChabadHouses },
    } = prevProps;
    if (
      !this.state.updatingParticipants &&
      prevParticipatingChabadHouses &&
      participatingChabadHouses &&
      prevParticipatingChabadHouses.length !== participatingChabadHouses.length
    ) {
      this.getParticipants();
    }
  }

  filterParticipants = (filters) => {
    this.setState({ filters }, () => {
      this.getParticipants();
    });
  };

  getParticipants = (page = 1, results = this.state.results) => {
    this.setState(
      {
        //loading/success not needed for FE pagination/search, keeping for potential changes
        loading: true,
        page,
        results,
        success: true,
      },
      () => {
        const {
          event: { participatingChabadHouses },
        } = this.props;
        const {
          filters: { keyword },
        } = this.state;

        let participants = [...participatingChabadHouses].sort((p1, p2) =>
          p1.chabadHouseName > p2.chabadHouseName ? 1 : -1,
        );
        //FE Search
        if (keyword) {
          participants = participants.filter((p) => {
            const participantSearch = [
              p.shliachFirstName,
              p.shliachLastName,
              p.shluchaFirstName,
              p.shluchaLastName,
              p.chabadHouseName,
              p.campusName,
              p.regionName,
            ]
              .map((s) => (s ? s.toLowerCase().trim() : ""))
              .join();
            return participantSearch.indexOf(keyword.toLowerCase().trim()) >= 0;
          });
        }

        const paginationStart = (page - 1) * results;
        const paginationEnd = paginationStart + results;
        this.setState({
          loading: false,
          participants: participants.slice(paginationStart, paginationEnd), //FE pagination
          totalParticipants: participants.length,
        });
      },
    );
  };

  addParticipants = (newParticipants) => {
    //if adding region filter beforehand so does not include already participating
    const {
      event: { participatingChabadHouses },
    } = this.props;

    this.updateParticipatingChabadHouses([
      ...participatingChabadHouses,
      ...newParticipants,
    ]);

    this.setState(
      {
        showAddParticipantsModal: false,
      },
      () => this.getParticipants(),
    );
  };

  removeParticipant = async () => {
    const { page, participantToRemove } = this.state;

    const {
      event: { participatingChabadHouses },
    } = this.props;

    this.updateParticipatingChabadHouses(
      participatingChabadHouses.filter(
        (p) => p.chabadHouseID !== participantToRemove.chabadHouseID,
      ),
      page,
    );

    this.setState({
      participantToRemove: null,
      showConfirmRemoveParticipantModal: false,
    });
  };

  updateParticipatingChabadHouses = async (participatingChabadHouses, page) => {
    this.setState({ updatingParticipants: true });
    await this.props.onChange(
      "participatingChabadHouses",
      participatingChabadHouses,
    );
    this.setState({ updatingParticipants: false });

    this.getParticipants(page);
  };

  render() {
    const {
      chabadHouses,
      event: { hasStartedShluchimEnrollment, participatingChabadHouses },
      initialEvent,
      mobileMode,
    } = this.props;

    const {
      loading,
      page,
      participants,
      participantToRemove,
      results,
      showConfirmRemoveParticipantModal,
      showAddParticipantsModal,
      success,
      totalParticipants,
    } = this.state;

    return (
      <React.Fragment>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">
            Participants
          </p>
        </div>
        <div className="program-settings-page-section">
          <div className="flex flex-align-center flex-justify-space">
            <ParticipantsTableSearch applySearch={this.filterParticipants} />
            <button
              className="btn custom-btn btn-accent uppercase-text btn-medium"
              onClick={() =>
                this.setState({
                  showAddParticipantsModal: true,
                })
              }
            >
              Add Participants
            </button>
          </div>
          <div className="event-participants-table">
            <PaginatedTable
              loading={loading}
              loadData={this.getParticipants}
              mobileMode={mobileMode}
              page={page}
              records={participants}
              renderHeader={() => <ParticipantsTableHeader />}
              renderRow={(participant, index) => (
                <ParticipantsTableRecord
                  key={index}
                  onRemoveParticipant={() => {
                    this.setState({
                      participantToRemove: participant,
                      showConfirmRemoveParticipantModal: true,
                    });
                  }}
                  participant={participant}
                />
              )}
              results={results}
              showResultsCount={true}
              success={success}
              totalCount={totalParticipants}
            />
          </div>
        </div>

        <ConfirmationModal
          cancel={() =>
            this.setState({
              participantToRemove: null,
              showConfirmRemoveParticipantModal: false,
            })
          }
          confirm={this.removeParticipant}
          message={`Are you sure you want to remove ${
            (participantToRemove && participantToRemove.chabadHouseName) ||
            "this participant"
          } from this event?  ${
            participantToRemove &&
            hasStartedShluchimEnrollment &&
            initialEvent.participatingChabadHouses.find(
              (p) => p.chabadHouseID === participantToRemove.chabadHouseID,
            ) //participant saved to event
              ? "Doing so will not affect existing enrollments associated with this participant."
              : ""
          }`}
          show={showConfirmRemoveParticipantModal}
        />

        <AddParticipantsModal
          chabadHouses={chabadHouses}
          close={() =>
            this.setState({
              showAddParticipantsModal: false,
            })
          }
          currentParticipants={participatingChabadHouses} //to filter available participants/regions by so dont add twice
          show={showAddParticipantsModal}
          submit={this.addParticipants}
        />
      </React.Fragment>
    );
  }
}
