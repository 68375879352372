import React from "react";
import { Link } from "react-router";

export default ({
  actions,
  errMessage,
  hasChanges,
  okMessage,
  schedule: { isPublished },
}) => {
  return (
    <div className="form-actions">
      <div className="lfloat">
        <strong style={{ color: "#d03b38" }}>{errMessage}</strong>
        <strong style={{ color: "#3cb389" }}>{okMessage}</strong>
      </div>
      <div className="rfloat">
        <button
          className="btn btn-primary btn-md"
          title={
            isPublished
              ? "The schedule is already published."
              : "Click to publish the program schedule."
          }
          disabled={isPublished}
          onClick={actions.onPublish}
        >
          Publish
        </button>
        <button
          className="btn btn-primary btn-md"
          type="submit"
          title={
            hasChanges
              ? "Click to save changes."
              : "There are no unsaved changes."
          }
          disabled={!hasChanges}
          onClick={actions.onSave}
        >
          Save
        </button>
        <Link className="btn btn-secondary btn-md" onClick={actions.onCancel}>
          Cancel
        </Link>
      </div>
    </div>
  );
};
