import React from "react";
import StudentsPricingDetails from "./pricing/StudentsPricingDetails";
import ValidatedInput from "../../shared/ValidatedInput";
import MultiSelect from "../../shared/MultiSelect";
import { TripTypes } from "../TripEventConsts";

export default class EventTrackOverrideConfigs extends React.PureComponent {
  render() {
    const {
      event: {
        hasStartedShluchimEnrollment,
        hasStudentRegistrations,
        isTravelTrip,
        rewardCreditValue,
        type,
      },
      onChange,
      onChangeEvt,
      submitAttempted,
      track: {
        ageMinimum,
        ageMaximum,
        earlyBirdStudentDeadline,
        earlyBirdStudentPrice,
        id: trackId,
        maxStudentRegistrants,
        regionIDs,
        regularStudentPrice,
        studentDepositAmount,
        trackName,
        trackDescription,
      },
      trackFieldPrefix,
      systemLists: { regions = [] },
    } = this.props;

    return (
      <React.Fragment>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">
            General Settings
          </p>
        </div>
        <div
          className="program-settings-page-section"
          style={{ borderBottom: "0px" }}
        >
          <div className="flex flex-align-center mb-16 event-settings-inputs">
            <label className="input-label">Track Name</label>
            <ValidatedInput
              name={`${trackFieldPrefix}.trackName`}
              onChange={onChangeEvt}
              required={true}
              showRequired={submitAttempted}
              value={trackName}
            />
          </div>
          <p className="mb-8 flex flex-align-center">
            Track Description{" "}
            <span className="tooltip-container">
              <i className="material-icons large-text ml-8">info</i>
              <span className="tooltip">
                This will be displayed on the student registration form
              </span>
            </span>
          </p>
          <textarea
            className="custom-input mb-24"
            name={`${trackFieldPrefix}.trackDescription`}
            onChange={onChangeEvt}
            required={false}
            value={trackDescription}
          />
          <div className="event-settings-inputs">
            <div className="flex flex-align-center">
              <label className="input-label">
                Max student capacity{" "}
                <span className="small-text accent-text">(optional)</span>
              </label>
              <input
                className="custom-input"
                min="1"
                name={`${trackFieldPrefix}.maxStudentRegistrants`}
                onChange={onChangeEvt}
                value={maxStudentRegistrants}
                type="number"
              />
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">
                Age restriction{" "}
                <span className="small-text accent-text">(optional)</span>
              </label>
              <div className="flex flex-align-center">
                <input
                  className="custom-input"
                  min="1"
                  name={`${trackFieldPrefix}.ageMinimum`}
                  onChange={onChangeEvt}
                  placeholder="Min"
                  value={ageMinimum}
                  type="number"
                />
                <span className="accent-text mr-8 ml-8">to</span>
                <input
                  className="custom-input"
                  min="1"
                  name={`${trackFieldPrefix}.ageMaximum`}
                  onChange={onChangeEvt}
                  placeholder="Max"
                  value={ageMaximum}
                  type="number"
                />
              </div>
            </div>
            <div className="flex flex-align-center">
              <label className="input-label">
                Region restriction{" "}
                <span className="small-text accent-text">(optional)</span>
              </label>
              <MultiSelect
                name={`${trackFieldPrefix}.regionIDs`}
                onChange={(name, val) =>
                  onChange(
                    name,
                    val?.map((v) => v.value)
                  )
                }
                options={
                  regions &&
                  regions.map((r) => ({
                    label: r.name,
                    value: r.id,
                  }))
                }
                placeholder="Select region"
                value={regionIDs}
              />
            </div>
          </div>
        </div>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">Pricing</p>
          {hasStartedShluchimEnrollment && (
            <span className="accent-text">
              * Pricing changes will not affect existing enrollments or
              registrations.
            </span>
          )}
        </div>

        <StudentsPricingDetails
          hasStudentRegistrations={!!trackId && hasStudentRegistrations}
          isJewishUTrip={type === TripTypes.JewishU}
          isTravelTrip={isTravelTrip}
          onChange={onChange}
          onChangeEvt={onChangeEvt}
          priceDetails={{
            earlyBirdStudentDeadline,
            earlyBirdStudentPrice,
            rewardCreditValue,
            regularStudentPrice,
            studentDepositAmount,
          }}
          trackFieldPrefix={trackFieldPrefix}
          submitAttempted={submitAttempted}
        />
      </React.Fragment>
    );
  }
}
