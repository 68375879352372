import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import TripEventsApi from "../../../../services/resources/TripEventsApi";
import { Tooltip, IconButton, Typography } from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import { notify } from "react-notify-toast";
import axios from "axios";

function ShluchimTableActions(props) {
  const {
    isTravelTrip,
    scheduleId,
    shliachEnrollment: { chabadHouseName, id, hasAnyAppliedStudents },
    reloadTableData,
  } = props;

  const apiSignalRef = useRef(axios.CancelToken.source());

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    const apiSignal = apiSignalRef.current;
    return () => apiSignal.cancel();
  }, []);

  const deleteEnrollment = useCallback(() => {
    TripEventsApi.deleteTripEnrollment(apiSignalRef.current.token, id)
      .then(({ status }) => {
        if (status !== 204) {
          notify.show("Error deleting Shliach enrollment", "error");
        } else {
          reloadTableData();
        }
      })
      .catch((_) => {
        notify.show("Error deleting Shliach enrollment", "error");
      });
  }, [id, reloadTableData]);

  return (
    <div>
      <p className="flex flex-align-center">
        {!isTravelTrip && (
          <IconButton>
            <Link
              className="flex flex-align-center ml-8"
              to={`/trips/${scheduleId}/enrollments/${id}`}
            >
              <Visibility fontSize="large" />
            </Link>
          </IconButton>
        )}
        <Tooltip
          placement="top"
          disableHoverListener={!hasAnyAppliedStudents}
          title={
            <Typography fontSize={14}>
              Enrollment cannot be deleted once students have applied
            </Typography>
          }
        >
          <span>
            <IconButton
              disabled={hasAnyAppliedStudents}
              onClick={() => setShowDeleteModal(!showDeleteModal)}
            >
              <Delete fontSize="large" />
            </IconButton>
          </span>
        </Tooltip>
      </p>
      {showDeleteModal && (
        <ConfirmationModal
          cancel={() => setShowDeleteModal(!showDeleteModal)}
          confirm={(_) => {
            deleteEnrollment();
            setShowDeleteModal(!showDeleteModal);
          }}
          message={`Are you sure you'd like to delete the enrollment for ${chabadHouseName}?`}
          show={showDeleteModal}
          title="Confirm Delete"
          confirmText="Delete"
        />
      )}
    </div>
  );
}

export default React.memo(ShluchimTableActions);
