import React from "react";
import { Link } from "react-router";

// import { CustomPage } from "../../../models/CustomPage";
import { CustomPageList } from "./CustomPageList";

// interface CustomPageListPageProps {
//   customPages: Array<CustomPage>;
//   linksBase: string;
//   hasEditPermission: boolean;
// }

export const CustomPageListPage = ({
  customPages,
  linksBase,
  hasEditPermission,
}) => {
  return (
    <div>
      <ol className="breadcrumb">
        <li>Custom Pages</li>
      </ol>
      <div className="list-container">
        <h1>Custom Pages</h1>
        <div
          className="row"
          style={{ marginBottom: "1em", position: "relative" }}
        >
          {hasEditPermission && (
            <div className="col-sm-12 text-left lfloatmb1">
              <Link
                to={"/config/custompages/new"}
                className="btn btn-primary btn-sm rfloat"
              >
                Add New
              </Link>
            </div>
          )}
        </div>
        <div className="row">
          <CustomPageList customPages={customPages} linksBase={linksBase} />
        </div>
      </div>
    </div>
  );
};
