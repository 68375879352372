import React from "react";
import { browserHistory, Link } from "react-router";
import { notify } from "react-notify-toast";

import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

import Configuration from "./RaffleWizard/Configuration";
import Prizes from "./RaffleWizard/Prizes";
import LegalText from "./RaffleWizard/LegalText";
import MarketingContent from "./RaffleWizard/MarketingContent";
import Winners from "./RaffleWizard/Winners";

import RafflesApi from "../../services/resources/RafflesApi";
import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import { Unauthorized } from "../../lib/coc-common-components";
import { replaceValuesInObject } from "../../lib";
import { getRequiredRaffleFields } from "./RaffleLogic";
import SaveConfirmationModal from "../shared/ConfirmationModal";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";

export default class RaffleSettingsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,

      errorMessage: "",
      loading: true,

      raffle: null,

      requiredRaffleFields: {},

      saveRaffleErrorMessage: "",
      saveRaffleLoading: false,

      showSaveConfirmationModal: false,

      submitAttempted: false,

      tabIndex: null,
      tabs: [
        { name: "Configuration", id: "config", component: Configuration },
        { name: "Prizes", id: "prizes", component: Prizes },
        { name: "Legal Text", id: "legal", component: LegalText },
        { name: "Marketing", id: "web", component: MarketingContent },
        { name: "Winners", id: "winners", component: Winners },
      ],
    };

    this.state.initialRaffle = _cloneDeep(this.state.raffle);
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.RaffleFullEdit)) {
      this.setState({ authorized: false });
    }

    const {
      location: {
        query: { tab },
      },
      route,
      router,
    } = this.props;

    const currentTabIndex = this.state.tabs
      .map((tab) => tab.id)
      .indexOf(decodeURIComponent(tab));
    this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0);

    router.setRouteLeaveHook(route, this.onLeave);

    this.getRaffleDetails();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { initialRaffle, raffle } = this.state;

    if (!_isEqual(initialRaffle, raffle)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getRaffleDetails = () => {
    const {
      params: { scheduleId },
    } = this.props;
    RafflesApi.getRaffle(this.apiSignal.token, scheduleId)
      .then((raffleDetails) => {
        const newState = {
          loading: false,
        };

        //redirect to new raffle pg for unpublished raffle
        if (!raffleDetails.isPublished) {
          browserHistory.replace(`/grand-draw/new/${scheduleId}`);
          return;
        }

        const raffle = this.formatRaffleForForm(raffleDetails);

        newState.raffle = raffle;
        newState.initialRaffle = raffle;

        this.setState({ ...this.state, ...newState });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          //redirect to new raffle pg if no raffle event configured for schedule
          const is404 = (err.response && err.response.status) === 404;
          if (is404) {
            browserHistory.replace(`/grand-draw/new/${scheduleId}`);
          }

          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          });
        }
      });
  };

  formatRaffleForForm = (raffle) => {
    const formattedRaffle = { ...raffle };

    replaceValuesInObject(
      formattedRaffle,
      (prop) => prop === null,
      () => "",
    );

    return formattedRaffle;
  };

  getErrorClassName = (className, err) =>
    err ? `${className} error` : className;

  onChangeRaffle = (name, value) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(raffle, name, value);

    this.setState({ raffle });
  };

  onChangeEventRaffle = (event) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(raffle, event.target.name, event.target.value);

    this.setState({ raffle });
  };

  onImageUpload = (name, value) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(
      raffle,
      name,
      raffle?.defaultCampaignImages
        ? [...raffle.defaultCampaignImages, { url: value }]
        : [{ url: value }],
    );

    this.setState({ raffle });
  };

  onImageDelete = (name, value) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(raffle, name, [
      ...raffle?.defaultCampaignImages.filter((img) => img.url !== value),
    ]);

    this.setState({ raffle });
  };

  toTab = (index) => {
    const { tabIndex, tabs } = this.state;
    const {
      location: { pathname },
    } = this.props;

    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      browserHistory.replace(
        `${pathname}?tab=${encodeURIComponent(tabs[index].id)}`,
      );
    }
  };

  onCancelRaffle = () => {
    this.setState({
      raffle: this.state.initialRaffle,
      submitAttempted: false,
    });
  };

  onSaveRaffle = () => {
    const requiredRaffleFields = getRequiredRaffleFields(this.state.raffle);
    this.setState({
      requiredRaffleFields,
      showSaveConfirmationModal: true,
      submitAttempted: true,
    });
  };

  saveRaffle = () => {
    const { raffle } = this.state;

    this.setState(
      {
        saveRaffleErrorMessage: "",
        saveRaffleLoading: true,
      },
      () => {
        RafflesApi.updateRaffle(this.apiSignal.token, raffle)
          .then((savedRaffle) => {
            this.setState(
              {
                initialRaffle: _cloneDeep(savedRaffle),
                raffle: savedRaffle,
                saveRaffleLoading: false,
                showSaveConfirmationModal: false,
                submitAttempted: false,
              },
              () => {
                notify.show(
                  "Your Grand Draw settings have been saved",
                  "success",
                );
              },
            );
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                saveRaffleErrorMessage: ApiCallErrorMessageHandler(err),
                saveRaffleLoading: false,
              });
            }
          });
      },
    );
  };

  render() {
    const {
      authorized,
      errorMessage,
      initialRaffle,
      loading,
      requiredRaffleFields,
      raffle,
      saveRaffleErrorMessage,
      saveRaffleLoading,
      showSaveConfirmationModal,
      submitAttempted,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="page raffles-page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !raffle ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              Sorry, something went wrong and we could not retrieve Grand Draw
              settings. Please try again.
            </p>
          </div>
        ) : (
          <React.Fragment>
            <div className="raffles-page-subheader raffles-page-settings-subheader">
              {!!raffle && (
                <div className="container">
                  <ProgramBreadcrumbsHeader
                    pageTitle="Settings"
                    prevPages={[
                      {
                        path: `/grand-draw/${raffle.programScheduleID}?view=shluchim`,
                        title: raffle.raffleTitle,
                      },
                    ]}
                    scheduleId={raffle.programScheduleID}
                  />
                </div>
              )}
              <div className="container flex flex-justify-space flex-align-center">
                <Link
                  to={`/grand-draw/${raffle.programScheduleID}`}
                  className="mr-24"
                >
                  <i className="material-icons link-text">arrow_back</i>
                </Link>
                <ul className="flex">
                  {tabs.map((tab, index) => (
                    <li
                      key={index}
                      className={tabIndex === index ? "active" : ""}
                      onClick={() => this.toTab(index)}
                    >
                      {tab.name}
                    </li>
                  ))}
                </ul>
                {!_isEqual(initialRaffle, raffle) && (
                  <div
                    className="raffles-page-subheader-actions relative"
                    style={{ top: "-16px" }}
                  >
                    <button
                      className="btn btn-cancel btn-medium uppercase-text"
                      onClick={this.onCancelRaffle}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-accent btn-medium ml-16 uppercase-text"
                      disabled={saveRaffleLoading}
                      onClick={this.onSaveRaffle}
                    >
                      Save Changes
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="container">
              {React.createElement(tabs[tabIndex].component, {
                getErrorClassName: this.getErrorClassName,
                onChange: this.onChangeRaffle,
                onChangeEvent: this.onChangeEventRaffle,
                onImageUpload: this.onImageUpload,
                onImageDelete: this.onImageDelete,
                raffle,
                submitAttempted,
              })}
            </div>
          </React.Fragment>
        )}

        <SaveConfirmationModal
          cancel={() =>
            this.setState({
              saveRaffleErrorMessage: "",
              showSaveConfirmationModal: false,
            })
          }
          cancelText={requiredRaffleFields.isIncomplete ? "OK" : "Cancel"}
          confirm={this.saveRaffle}
          confirmText="Save"
          errorMessage={saveRaffleErrorMessage}
          message={
            requiredRaffleFields.isIncomplete
              ? `Please update the following required or invalid fields before saving this Grand Draw:
                        \n${requiredRaffleFields.incompleteFields}`
              : `Save changes to ${
                  raffle ? raffle.raffleTitle : "this Grand Draw"
                }?`
          }
          noConfirm={requiredRaffleFields.isIncomplete}
          loading={saveRaffleLoading}
          show={showSaveConfirmationModal}
        />
      </div>
    );
  }
}
