import React from "react";
import { StyledSelect } from "../../lib/coc-common-components/StyledComponents/StyledSelect";

// interface KinusSelectionProps {
//   kinus: Array<any>;
//   selectedKinusId: Number;
//   handleProgramSessionChange: (str: string) => void;
// }

const KinusSelection = ({
  kinus,
  selectedKinusId,
  handleProgramSessionChange,
}) => {
  const displayableSessions = [...kinus];

  return (
    <StyledSelect>
      <select
        value={selectedKinusId.toString() || ""}
        onChange={(e) => handleProgramSessionChange(e.currentTarget.value)}
      >
        {displayableSessions.map((year) => (
          <option key={year} value={year}>
            {"Year " + year.toString()}
          </option>
        ))}
      </select>
    </StyledSelect>
  );
};

export default KinusSelection;
