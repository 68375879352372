import React from "react";
import CurrentRaffleCard from "./CurrentRaffleCard";
import RaffleHistory from "./RaffleHistory";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";

import RafflesApi from "../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";

export default class RafflesPage extends React.PureComponent {
  state = {
    authorized: true,

    currentRaffle: {},
    currentRaffleErrorMessage: "",
    currentRaffleLoading: true,

    history: [],
    historyErrorMessage: "",
    historyLoading: true,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.RaffleDataView)) {
      this.setState({ authorized: false });
    }

    this.getCurrentRaffle();
    this.getRaffleHistory();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getRaffleHistory = async () => {
    await RafflesApi.getHistory(this.apiSignal.token)
      .then((data) => {
        this.setState({
          history: data,
          historyLoading: false,
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            history: [],
            historyErrorMessage: ApiCallErrorMessageHandler(err),
            historyLoading: false,
          });
        }
      });
  };

  getCurrentRaffle = async () => {
    await RafflesApi.getCurrentRaffle(this.apiSignal.token, true)
      .then((data) => {
        this.setState({
          currentRaffle: data,
          currentRaffleLoading: false,
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          const noCurrentRaffle = (err.response && err.response.status) === 404;
          this.setState({
            currentRaffle: {},
            currentRaffleErrorMessage: noCurrentRaffle
              ? ""
              : ApiCallErrorMessageHandler(err),
            currentRaffleLoading: false,
          });
        }
      });
  };

  render() {
    const {
      authorized,

      currentRaffle,
      currentRaffleErrorMessage,
      currentRaffleLoading,

      history,
      historyErrorMessage,
      historyLoading,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const hasRaffleSettingsAccess = AuthService.UserHasClaim(
      PermissionClaims.RaffleFullEdit,
    );

    return (
      <div className="page raffles-page container">
        <p className="xxl-text fw-700 mb-24">Grand Draws</p>

        {currentRaffleLoading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {currentRaffleErrorMessage ? (
              <div className="card raffles-card error-text">
                Sorry, something went wrong and we could not retrieve current
                Grand Draw information. Please try again.
              </div>
            ) : (
              !!currentRaffle.id && (
                <React.Fragment>
                  <ProgramBreadcrumbsHeader
                    scheduleId={currentRaffle.programScheduleID}
                  />
                  <CurrentRaffleCard
                    hasRaffleSettingsAccess={hasRaffleSettingsAccess}
                    raffle={currentRaffle}
                    reloadRaffles={this.getRafflesInfo}
                  />
                </React.Fragment>
              )
            )}

            {historyLoading ? (
              <div>
                <div className="raffles-history-card">
                  <Loader />
                </div>
              </div>
            ) : historyErrorMessage ? (
              <div className="raffles-history-card">
                <p className="error-text mb-0" style={{ position: "relative" }}>
                  Sorry, something went wrong and we could not retrieve Grand
                  Draw history. Please try again.
                </p>
              </div>
            ) : (
              <RaffleHistory history={history} />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
