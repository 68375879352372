import React from "react";
import { Link } from "react-router";

import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";

// interface ConfigQuickLinksProps { }

const ConfigQuickLinks = () => {
  const hasCustomEventsManagementPermission = AuthService.UserHasClaim(
    PermissionClaims.CustomEventManager,
  );
  const hasPermissionManagementPermission = AuthService.UserHasClaim(
    PermissionClaims.RoleManager,
  );
  const hasDirectoryEditPermission = AuthService.UserHasClaim(
    PermissionClaims.DirectoryEdit,
  );
  const hasConfigsEditPermission = AuthService.UserHasClaim(
    PermissionClaims.ConfigsEdit,
  );

  // prettier-ignore
  return (
    <div className="col-sm-12">
      <ol className="breadcrumb">
        <li>Configure</li>
      </ol>
      <ul>
        {hasPermissionManagementPermission && <li><Link to="/config/roles">Roles and Permissions</Link></li>}
        {hasCustomEventsManagementPermission && <li><Link to="/config/customevents">Custom Events</Link></li>}
        {hasDirectoryEditPermission && <li><Link to="/config/orgcategories">Organization Categories</Link></li>}
        {hasConfigsEditPermission && <li><Link to="/config/portalredirects">Portal Redirects</Link></li>}
        {hasConfigsEditPermission && <li><Link to="/config/cashtypes">Cash Types</Link></li>}
      </ul>
    </div>
  );
};

export default ConfigQuickLinks;
