import React from "react";
import { Bar, BarChart, Cell, XAxis, YAxis } from "recharts";
import { formatNumber } from "../../../lib";

const barColors = [
  { dark: "#6369D1", light: "rgba(99,105,209,0.08)" },
  { dark: "#63C674", light: "rgba(99,198,116,0.08)" },
  { dark: "#EC5150", light: "rgba(236,81,80,0.08)" },
  { dark: "#F3893D", light: "rgba(243,137,61,0.08)" },
  { dark: "#53B7E8", light: "rgba(83,183,232,0.08)" },
];

function getBarColor(index) {
  return (
    barColors[index < 5 ? index : index % 5] || {
      dark: "rgba(0,0,0,0.87)",
      light: "#F4F4F4",
    }
  );
}

class CustomSalesByReferrerLabel extends React.Component {
  render() {
    const { data, formatValue, height, index, value, x, y } = this.props;
    const fontSize = 14;
    const fill = getBarColor(index).dark;

    return (
      <text
        dx={5}
        dy={height / 2 + fontSize / 2}
        fill={fill}
        fontSize={fontSize}
        fontFamily="sans-serif"
        textAnchor="start"
        x={x}
        y={y}
      >
        {data[index].referrerName} ({formatValue(value)})
      </text>
    );
  }
}

export default class EnrollmentSalesOverTimeChart extends React.Component {
  state = {
    timeframe: "7 days",
  };

  formatSales = (amount) => formatNumber(amount, this.props.currencyCode);

  getChartHeight = (dataLength) => {
    let baseHeight = 350;
    if (dataLength <= 15) {
      return baseHeight;
    }
    //add 25px to the base height for every referrer over 15 to accomodate the data within the chart
    return baseHeight + 25 * (dataLength - 15);
  };

  render() {
    const { data = [] } = this.props;
    const height = this.getChartHeight(data.length);

    return (
      <div className="graph-outline ml-24">
        <p className="large-text fw-700">Sales By Team</p>

        {!data.length ? (
          <span>No Sales By Team found</span>
        ) : (
          <BarChart data={data} height={height} layout="vertical" width={500}>
            <Bar
              dataKey="salesByReferrer"
              fill="#8884d8"
              label={
                <CustomSalesByReferrerLabel
                  data={data}
                  formatValue={this.formatSales}
                />
              }
            >
              {data.map((entry, index) => {
                const fill = getBarColor(index).light;
                return <Cell key={index} fill={fill} />;
              })}
            </Bar>

            <YAxis dataKey="referrerName" hide type="category" />

            <XAxis
              dataKey="salesByReferrer"
              tickFormatter={this.formatSales}
              type="number"
            />
          </BarChart>
        )}
      </div>
    );
  }
}
