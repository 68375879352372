import React from "react";
import PrizeDetails from "./PrizeDetails";
import ChabadHousesApi from "../../../services/resources/ChabadHousesApi";
import DeletePrizeConfirmationModal from "../../shared/ConfirmationModal";

export default class Prizes extends React.PureComponent {
  state = {
    currentPrize: null,
    currentPrizeIndex: null,
    deletePrizeIndex: null,
    editPrizeMode: false,
    initialCurrentPrize: null,
    locations: [],
    showDeletePrizeConfirmationModal: false,
  };

  componentDidMount() {
    ChabadHousesApi.getChabadHousesDetails().then((chabadHouses) => {
      const chabadHousesData = chabadHouses.map((ch) => ({
        chabadHouseID: ch.chabadHouseID,
        campusName: ch.campusName,
        chabadHouseName: ch.chabadHouseName,
        shluchim: `${[ch.shliachFirstName, ch.shluchaFirstName]
          .filter((n) => n)
          .join(" & ")} ${ch.shliachLastName || ch.shluchaLastName || ""}`,
      }));
      this.setState({ locations: [...chabadHousesData] });
    });
  }

  onAddPrize = () => {
    const newPrize = {
      cadPrizeImageURL: "",
      cadPrizeTitle: "",
      gbpPrizeImageURL: "",
      gbpPrizeTitle: "",
      id: 0,
      numberOfWinners: "1",
      place: "",
      // prizeOfferedIn: "Everywhere", //Removing PrizeOfferedIn implementation as all prizes will be available in Texas for now, API will default to Everywhere for new prizes
      chabadHousePrizes: [],
      usdPrizeImageURL: "",
      usdPrizeTitle: "",
    };

    this.setState({
      currentPrize: newPrize,
      currentPrizeIndex: null,
      editPrizeMode: true,
      initialCurrentPrize: newPrize,
    });
  };

  onDeletePrize = () => {
    const {
      onChange,
      raffle: { prizes },
    } = this.props;
    const { currentPrizeIndex, deletePrizeIndex } = this.state;

    onChange(
      "prizes",
      prizes.filter((prize, index) => index !== deletePrizeIndex),
    );

    this.setState({
      deletePrizeIndex: null,
      showDeletePrizeConfirmationModal: false,
      ...(currentPrizeIndex === deletePrizeIndex
        ? {
            currentPrize: null,
            currentPrizeIndex: null,
            editPrizeMode: false,
            initialCurrentPrize: null,
          }
        : {}),
    });
  };

  onChangePrizeProperties = (properties) => {
    this.setState({
      currentPrize: {
        ...this.state.currentPrize,
        ...properties,
      },
    });
  };

  onCancelPrizeChanges = () => {
    if (this.state.currentPrizeIndex === null) {
      //cancel new prize
      this.onToggleViewPrize();
    } else {
      this.setState({
        currentPrize: this.state.initialCurrentPrize,
        editPrizeMode: false,
      });
    }
  };

  onSavePrizeChanges = () => {
    const { currentPrize, currentPrizeIndex } = this.state;
    const {
      onChange,
      raffle: { prizes },
    } = this.props;
    let updatedPrizes = [...prizes];
    if (currentPrizeIndex !== null) {
      updatedPrizes[currentPrizeIndex] = currentPrize;
    } else {
      updatedPrizes.push(currentPrize);
    }

    updatedPrizes = updatedPrizes.sort((p1, p2) => p1.place - p2.place);
    const updatedPrizeIndex = updatedPrizes.indexOf(currentPrize);

    onChange("prizes", updatedPrizes);

    this.setState({
      currentPrizeIndex: updatedPrizeIndex,
      editPrizeMode: false,
      initialCurrentPrize: currentPrize,
    });
  };

  onToggleViewPrize = (prizeIndex = null) => {
    const currentPrize = this.props.raffle.prizes[prizeIndex] || null;

    this.setState({
      currentPrize,
      currentPrizeIndex: prizeIndex,
      editPrizeMode: false,
    });
  };

  render() {
    const {
      getErrorClassName,
      raffle: { daysRemaining, id: raffleId, prizes },
      submitAttempted,
    } = this.props;
    const {
      currentPrize,
      currentPrizeIndex,
      editPrizeMode,
      initialCurrentPrize,
      showDeletePrizeConfirmationModal,
    } = this.state;

    return (
      <div className="new-raffle-second-step">
        <div className="flex">
          <div className="new-raffle-step mr-8">
            <div className="flex flex-justify-space flex-align-center mb-24">
              <p
                className={getErrorClassName(
                  "xl-text",
                  submitAttempted && !prizes.length,
                )}
              >
                Prizes
              </p>
              {!raffleId || !!daysRemaining ? (
                <button
                  className="btn btn-accent btn-small"
                  disabled={editPrizeMode}
                  onClick={this.onAddPrize}
                  tabIndex={10}
                >
                  Add
                </button>
              ) : (
                <span className="small-text accent-text text-right ml-8">
                  *Prizes cannot be updated for past Grand Draws
                </span>
              )}
            </div>
            {submitAttempted && !prizes.length && !currentPrize && (
              <span className="error-text">*At least one prize required</span>
            )}
            <div className="prizes">
              {prizes.map((prize, index) => (
                <div
                  key={index}
                  className={`prize flex flex-align-center flex-justify-space ${
                    currentPrizeIndex === index ? "active" : ""
                  } ${!editPrizeMode ? "hover" : ""}`}
                  onClick={() =>
                    !editPrizeMode && this.onToggleViewPrize(index)
                  }
                >
                  <p className="prize-name medium-text">
                    {prize.usdPrizeTitle ||
                      prize.cadPrizeTitle ||
                      prize.gbpPrizeTitle}
                  </p>
                  {(!raffleId || !!daysRemaining) && (
                    <div className="prize-actions flex">
                      <i
                        className="material-icons large-text"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({
                            deletePrizeIndex: index,
                            showDeletePrizeConfirmationModal: true,
                          });
                        }}
                      >
                        delete
                      </i>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="new-raffle-step ml-8">
            {currentPrize && (
              <PrizeDetails
                close={() => this.onToggleViewPrize()}
                getErrorClassName={getErrorClassName}
                editMode={editPrizeMode}
                initialPrize={initialCurrentPrize}
                isNewPrize={currentPrizeIndex === null}
                locations={this.state.locations}
                onCancelChanges={this.onCancelPrizeChanges}
                onChangePrizeProperties={this.onChangePrizeProperties}
                onEditPrize={() =>
                  this.setState({
                    editPrizeMode: true,
                    initialCurrentPrize:
                      this.props.raffle.prizes[this.state.currentPrizeIndex],
                  })
                }
                onSaveChanges={this.onSavePrizeChanges}
                prize={currentPrize}
                prizeIndex={currentPrizeIndex}
                readOnly={raffleId && !daysRemaining}
                submitAttempted={submitAttempted}
                usedPrizePlaces={prizes
                  .filter(
                    (prize, index) =>
                      index !== currentPrizeIndex && prize.place,
                  )
                  .map((prize) => prize.place.toString())}
              />
            )}
          </div>

          <DeletePrizeConfirmationModal
            cancel={() =>
              this.setState({
                deletePrizeIndex: null,
                showDeletePrizeConfirmationModal: false,
              })
            }
            confirm={this.onDeletePrize}
            confirmText="Delete"
            message="Are you sure you want to delete this prize?"
            show={showDeletePrizeConfirmationModal}
          />
        </div>
      </div>
    );
  }
}
