import React from "react";
import { Link } from "react-router";
import ImpersonateUser from "../../../shared/ImpersonateUser";

import {
  formatCurrency,
  formatFullName,
  formatNumber,
  impersonationUserTypes,
} from "../../../../lib";

export default ({ enrolled, enrollment, raffleProgramScheduleId }) => {
  const {
    campusName,
    chabadHouseName,
    chabadHouseOwes,
    cociOwes,
    currencyCode,
    sellerEnrollmentID,
    shliachFirstName,
    shliachID,
    shliachLastName,
    shliachEmail,
    shluchaFirstName,
    shluchaLastName,
    shluchaEmail,
    totalTicketsSold,
  } = enrollment;

  return (
    <Link
      className={`raffle-details-table-row raffle-details-shluchim-table-row ${
        (enrolled ? sellerEnrollmentID : shliachID) ? "clickable" : ""
      }`}
      to={
        enrolled
          ? sellerEnrollmentID
            ? `/grand-draw/${raffleProgramScheduleId}/enrollment/${sellerEnrollmentID}`
            : ""
          : shliachID
          ? `/shluchim/${shliachID}`
          : ""
      }
    >
      <div>
        <p className="fw-500">
          {formatFullName(shliachFirstName, shliachLastName)}
        </p>
        <div>
          {shliachEmail && (
            <span className="tooltip-container">
              <ImpersonateUser
                username={shliachEmail}
                userType={impersonationUserTypes.Shliach}
                redirectUrlEnd={`?after=grand-draw/${raffleProgramScheduleId}?tab=settings`}
                preventClickDefault={true}
              />
              <span
                className="tooltip"
                style={{ left: "-40px", bottom: "50%" }}
              >
                Impersonate
              </span>
            </span>
          )}
        </div>
      </div>
      <div>
        <p className="fw-500">
          {formatFullName(shluchaFirstName, shluchaLastName)}
        </p>
        <div>
          {shluchaEmail && (
            <span className="tooltip-container">
              <ImpersonateUser
                username={shluchaEmail}
                userType={impersonationUserTypes.Shliach}
                redirectUrlEnd={`?after=grand-draw/${raffleProgramScheduleId}?tab=settings`}
                preventClickDefault={true}
              />
              <span
                className="tooltip"
                style={{ left: "-40px", bottom: "50%" }}
              >
                Impersonate
              </span>
            </span>
          )}
        </div>
      </div>
      <div>
        <p className="fw-500">{chabadHouseName}</p>
      </div>
      <div>
        <p className="fw-500">{campusName}</p>
      </div>
      <div>
        <p className="fw-500">
          {enrolled ? formatNumber(totalTicketsSold) : `—`}
        </p>
      </div>
      <div>
        <p className="fw-500">
          {enrolled ? formatCurrency(cociOwes, currencyCode) : "—"}
        </p>
      </div>
      <div>
        <p className="fw-500">
          {enrolled ? formatCurrency(chabadHouseOwes, currencyCode) : "—"}
        </p>
      </div>
    </Link>
  );
};
