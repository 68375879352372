import React from "react";
import { browserHistory } from "react-router";

import Loader from "../common/Loader";
import LifeInsurancePolicyInfo from "./LifeInsurancePolicyInfo";
import EnrollmentsTable from "./enrollments/EnrollmentsTable";
import NotEnrolledTable from "./notEnrolled/NotEnrolledTable";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";

import LifeInsuranceApi from "../../services/resources/LifeInsuranceApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import axios from "axios";

export default class LifeInsurancePage extends React.PureComponent {
  state = {
    authorized: true,

    errorMessage: "",
    lifeInsurancePolicy: null,
    loading: false,

    systemLists: {
      lifeInsuranceStatuses: [],
    },

    tabIndex: 0,
    tabs: [
      {
        name: "Enrolled",
        id: "enrolled",
        component: EnrollmentsTable,
      },
      {
        name: "Not Enrolled",
        id: "not-enrolled",
        component: NotEnrolledTable,
      },
    ],
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.LIFullEdit)) {
      this.setState({ authorized: false });
    }

    const {
      location: {
        query: { tab },
      },
      routeParams: { scheduleId },
    } = this.props;
    const currentTabIndex = this.state.tabs
      .map((tab) => tab.id)
      .indexOf(decodeURIComponent(tab));
    this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0);

    this.getLifeInsurancePolicy(scheduleId);

    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getLifeInsurancePolicy = async (scheduleId) => {
    try {
      this.setState({
        errorMessage: "",
        loading: true,
      });

      const lifeInsurancePolicy = await LifeInsuranceApi.getLifeInsurancePolicy(
        this.apiSignal.token,
        scheduleId,
        true,
      );

      this.setState({
        loading: false,
        lifeInsurancePolicy,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve Life Insurance information. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists(["lifeInsuranceStatuses"]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong. Please try again.",
          ),
        });
      }
    }
  };

  toTab = (index) => {
    const { tabs } = this.state;
    const {
      location: {
        query: { tab: prevTabIndex },
        pathname,
      },
    } = this.props;

    if (prevTabIndex !== index) {
      this.setState({ tabIndex: index });
      browserHistory.replace(`${pathname}?tab=${tabs[index].id}`);
    }
  };

  render() {
    const {
      mobileMode,
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      errorMessage,
      lifeInsurancePolicy,
      loading,
      systemLists,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="life-insurance-page page container">
        <ProgramBreadcrumbsHeader scheduleId={scheduleId} />

        {!lifeInsurancePolicy && loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          !!lifeInsurancePolicy && (
            <React.Fragment>
              <LifeInsurancePolicyInfo policy={lifeInsurancePolicy} />

              {!loading && (
                <React.Fragment>
                  <div
                    className="life-insurance-details-tabs flex"
                    style={{ marginLeft: "2px" }}
                  >
                    {tabs.map((tab, index) => (
                      <p
                        key={index}
                        className={`large-text ${index > 0 ? "ml-40" : ""} ${
                          tabIndex === index ? "active" : ""
                        }`}
                        onClick={() => this.toTab(index)}
                      >
                        {tab.name}
                      </p>
                    ))}
                  </div>

                  <div className="card life-insurance-card">
                    {React.createElement(tabs[tabIndex].component, {
                      mobileMode,
                      policyId: lifeInsurancePolicy.id,
                      scheduleId: lifeInsurancePolicy.programScheduleID,
                      systemLists,
                    })}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
