import React from "react";
import debounce from "lodash.debounce";
import Autocomplete from "react-autocomplete";

export const typeaheadStyles = {
  typeaheadSuggestions: {
    borderRadius: "3px",
    padding: "2px 0",
    fontSize: "16px",
    position: "absolute",
    overflow: "auto",
    maxHeight: "500px",
    height: "auto",
    minWidth: "10 0px",
    left: "-44px",
    top: "40px",
    width: "400px",
    zIndex: 2,
  },
};

// export interface TypeaheadSearchProps {
//   value?: string;
//   placeholder?: string;
//   maxSuggestions?: number;
//   storeSearchInput: (inputEl) => void;
//   onFocus: () => void;
//   onChange: (value) => void;
//   display: (item) => string;
//   typeaheadDisplay;
//   onSelectTypeaheadSuggestion: (item) => void;
//   fetchTypeaheadSuggestions;
// }

// export interface TypeaheadSearchState {
//   suggestions: Array<any>;
// }

export class TypeaheadSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
    };

    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.requestSuggestions = debounce(this.requestSuggestions.bind(this), 200);
  }

  requestSuggestions(searchQuery) {
    this.props
      .fetchTypeaheadSuggestions(searchQuery, 0, this.props.maxSuggestions)
      .then((response) => {
        this.setState({ suggestions: response.data.results || response.data });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onSearchInputChange(event, value) {
    this.props.onChange(value);
    this.requestSuggestions(event.target.value);
  }

  render() {
    return (
      <Autocomplete
        autoHighlight={false}
        value={this.props.value}
        ref={this.props.storeSearchInput}
        inputProps={{
          style: { borderRadius: "0", fontSize: "16px" },
          className: "form-control",
          placeholder: this.props.placeholder,
          onFocus: this.props.onFocus,
        }}
        getItemValue={this.props.display}
        items={this.props.value === "" ? [] : this.state.suggestions}
        renderItem={(item, isHighlighted) => (
          <div
            key={Math.random()}
            className={
              "typeahead-suggestion " + (isHighlighted ? "highlighted" : "")
            }
          >
            {this.props.typeaheadDisplay(item)}
          </div>
        )}
        menuStyle={typeaheadStyles.typeaheadSuggestions}
        onChange={this.onSearchInputChange}
        onSelect={(value, item) => this.props.onSelectTypeaheadSuggestion(item)}
        wrapperStyle={{}}
      />
    );
  }
}
