import React from "react";
import { browserHistory } from "react-router";

// interface ShluchimLinkProps {
//   contactId: number;
//   firstName: string;
//   lastName: string;
// }

const ShluchimLink = ({ contactId, firstName, lastName }) => (
  <a
    href={`shluchim/${contactId}`}
    onClick={(e) => {
      e.stopPropagation();
      browserHistory.push(`shluchim/${contactId}`);
    }}
  >
    {firstName + " " + lastName}
  </a>
);

export default ShluchimLink;
