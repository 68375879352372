import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import Loader from "../../../common/Loader";
import RegistrationStatusMenu from "./studentRegistration/details/status/RegistrationStatusMenu";
import RegistrationRejectionConfirmationDetails from "./studentRegistration/details/status/RegistrationRejectionConfirmationDetails";

import TripEventsApi from "../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { pluralizeText } from "../../../../lib";
import {
  TripRegistrationStatuses,
  TripRegistrationWorkflowSteps,
} from "../../TripEventConsts";
import { getTravelTripRegistrationStatusWorkflowDisplay } from "../../TripEventLogic";
import axios from "axios";

function StudentsBulkStatusChangeModal(props) {
  const {
    allStatuses,
    allWorkflowSteps,
    close,
    getStudentsSelection,
    isTravelTrip,
    programScheduleName,
    onUpdate,
  } = props;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [students, setStudents] = useState([]);
  const [statusUpdate, setStatusUpdate] = useState();
  const [workflowStepUpdate, setWorkflowStepUpdate] = useState();

  const initialStatus = students[0]?.status;

  const [sendRejectionEmail, setSendRejectionEmail] = useState(true);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const students = await getStudentsSelection();

      if (students) {
        setStudents(students);
      } else {
        setErrorMessage("Failed to retrieve selected students");
      }

      setLoading(false);
    })();
  }, [getStudentsSelection]);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    setErrorMessage("");

    try {
      if (statusUpdate !== initialStatus) {
        await TripEventsApi.bulkUpdateTripRegistrationStatus(
          apiSignalRef.current.token,
          students.map((s) => s.id),
          statusUpdate,
          !sendRejectionEmail
        );
      } else {
        await TripEventsApi.bulkUpdateTripRegistrationWorkflowStep(
          apiSignalRef.current.token,
          students.map((s) => s.id),
          workflowStepUpdate
        );
      }

      onUpdate();
      close();
    } catch (err) {
      setSubmitting(false);
      setErrorMessage(
        ApiCallErrorMessageHandler(err, "Failed to update students status")
      );
    }
  }, [
    close,
    initialStatus,
    onUpdate,
    sendRejectionEmail,
    statusUpdate,
    students,
    workflowStepUpdate,
  ]);

  const statusUpdateDisplay = useMemo(() => {
    if (!statusUpdate && !workflowStepUpdate) {
      return "";
    }

    // display "Processed" for new processing
    if (
      workflowStepUpdate === TripRegistrationWorkflowSteps.Processed &&
      students[0].workflowStep !== TripRegistrationWorkflowSteps.Processed
    ) {
      return "Processed";
    }

    return getTravelTripRegistrationStatusWorkflowDisplay({
      status: statusUpdate,
      statusDisplay: allStatuses.find((s) => s.enumValue === statusUpdate)
        ?.displayValue,
      workflowStep: workflowStepUpdate,
      workflowStepDisplay: allWorkflowSteps.find(
        (s) => s.enumValue === workflowStepUpdate
      )?.displayValue,
    });
  }, [
    allStatuses,
    allWorkflowSteps,
    statusUpdate,
    students,
    workflowStepUpdate,
  ]);

  const submitDisabled = submitting || (!statusUpdate && !workflowStepUpdate);

  return (
    <Modal isOpen={true} className="modal-container">
      <div className="card small-modal trip-modal">
        <p className="large-text mb-8">Update status</p>
        {loading ? (
          <Loader />
        ) : students.length > 0 ? (
          <>
            <p className="accent-text-dark mb-16">
              Please select an updated status for {students.length} selected{" "}
              {pluralizeText("student", students.length)}
            </p>
            <div
              className="mb-24"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              {students.map((s) => (
                <p className="fw-500 mb-4" key={s.id}>
                  {s.studentFirstName} {s.studentLastName}
                </p>
              ))}
            </div>
            <RegistrationStatusMenu
              forBulkUpdate={true}
              onChange={(status, workflowStep) => {
                setStatusUpdate(status);
                setWorkflowStepUpdate(workflowStep);
              }}
              placeholder={statusUpdateDisplay || "Select status"}
              registration={students[0]}
            />
            {workflowStepUpdate === TripRegistrationWorkflowSteps.Processed && (
              <div className="mt-24">
                <div className="flex">
                  <i className="material-icons large-text trip-info-icon mr-4">
                    info
                  </i>{" "}
                  <div>
                    The selected {pluralizeText("student", students.length)}{" "}
                    will become <b>{statusUpdate}</b>
                    {(statusUpdate === TripRegistrationStatuses.Accepted ||
                      statusUpdate === TripRegistrationStatuses.Waitlisted) &&
                      ", and they will be notified via email"}
                    .
                  </div>
                </div>
                {statusUpdate === TripRegistrationStatuses.Rejected && (
                  <RegistrationRejectionConfirmationDetails
                    registrationDetails={{
                      isTravelTrip,
                      programScheduleName,
                    }}
                    sendEmail={sendRejectionEmail}
                    toggleSendEmail={() =>
                      setSendRejectionEmail(!sendRejectionEmail)
                    }
                  />
                )}
              </div>
            )}
            <div className="modal-btns">
              <button
                className="link-text uppercase-text"
                disabled={submitting}
                onClick={close}
              >
                Cancel
              </button>
              <button
                className={`link-text uppercase-text ml-24${
                  submitDisabled ? " disabled" : ""
                }`}
                disabled={submitDisabled}
                onClick={onSubmit}
              >
                {submitting ? "Updating..." : "Update"}
              </button>
            </div>
            {!submitting && errorMessage && (
              <div className="text-right error-text mt-4">{errorMessage}</div>
            )}
          </>
        ) : (
          errorMessage && <div className="error-text mt-4">{errorMessage}</div>
        )}
      </div>
    </Modal>
  );
}

export default React.memo(StudentsBulkStatusChangeModal);
