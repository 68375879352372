import React, { Fragment, useEffect, useRef } from "react";
import RegistrationCancellation from "./payment/RegistrationCancellation";
import RegistrationSplitFeeRefunds from "./payment/RegistrationSplitFeeRefunds";
import RegistrationDepositAndFeeRefunds from "./payment/RegistrationDepositAndFeeRefunds";

import { TripRegistrationStatuses } from "../../../../TripEventConsts";
import { formatCurrency, pluralizeText } from "../../../../../../lib";
import { Link } from "react-router";
import axios from "axios";
import moment from "moment";

const { REACT_APP_COCI_STUDENT_PORTAL_DOMAIN } = process.env;

export const getPaymentDisplay = (registration, overview = false) => {
  const {
    status,
    total,
    tripFee,
    wasDepositRefunded,
    wasPaymentProcessed,
    wasTripFeeRefunded,
  } = registration;

  // overview represents total payment, non-overview represents only the trip fee
  const paymentAmount = overview ? total : tripFee;
  const paymentRefunded = overview
    ? wasTripFeeRefunded || wasDepositRefunded
    : wasTripFeeRefunded;

  const showPaymentStatus =
    (paymentAmount > 0 &&
      (status === TripRegistrationStatuses.Pending || !wasPaymentProcessed)) ||
    paymentRefunded;

  return (
    <div className="trip-student-payment-text">
      <p>${formatCurrency(paymentAmount)}</p>
      {showPaymentStatus && (
        <p style={{ color: paymentAmount > 0 ? "#F3893D" : "#DC313F" }}>
          {paymentAmount > 0
            ? status === TripRegistrationStatuses.Pending
              ? "Payment Pending"
              : !wasPaymentProcessed
              ? "Payment Not Processed"
              : ""
            : paymentRefunded
            ? "Refunded"
            : ""}
        </p>
      )}
    </div>
  );
};

export const getCreditsDisplay = (registration, overview = false) => {
  const {
    rewardRequest,
    student: { id: studentId },
  } = registration;

  // for overview, credit status is not displayed if approved
  const showCreditsStatus = !overview || rewardRequest.status !== "Approved";

  return (
    <Link
      className="trip-student-payment-text link-text-secondary"
      to={`/edu/${rewardRequest.eduProgramScheduleID}/students/${studentId}?tab=${rewardRequest.eduStudentEnrollmentID}&sub=rewards`}
    >
      {overview ? " + " : ""}
      {rewardRequest.creditsToRedeem}{" "}
      {pluralizeText("credit", rewardRequest.creditsToRedeem)} ($
      {formatCurrency(rewardRequest.amount)})
      {showCreditsStatus && (
        <span
          style={{
            color:
              rewardRequest.status === "Rejected"
                ? "#DC313F"
                : rewardRequest.status === "Approved"
                ? "#76E269"
                : "#F3893D",
          }}
        >
          Credits {rewardRequest.status}
        </span>
      )}
    </Link>
  );
};

function RegistrationPayment(props) {
  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const {
    isTravelTrip,
    onRegistrationUpdated,
    programScheduleId,
    readOnlyAccess,
    registration,
    shliachEnrollment: { latestCancellationDate },
    tripType,
  } = props;

  const {
    credCardInfo,
    depositAmount,
    promoCodesApplied,
    refundHistory,
    rewardRequest,
    status,
    wasDepositRefunded,
    wasPaymentProcessed,
  } = registration;

  return (
    <div className="trip-student-detail-tab payment-tab">
      <div className="xl-text fw-700">Payment</div>
      <div className="student-detail-section double-grid-container">
        <div className="two-column-grid">
          <p className="fw-700">Trip fee amount</p>
          {getPaymentDisplay(registration)}
          {isTravelTrip && (
            <>
              <p className="fw-700">Deposit amount</p>
              <div className="trip-student-payment-text">
                <p>${formatCurrency(depositAmount)}</p>
                <p style={{ color: depositAmount > 0 ? "#F3893D" : "#DC313F" }}>
                  {depositAmount > 0
                    ? status === TripRegistrationStatuses.Pending
                      ? "Payment Pending"
                      : !wasPaymentProcessed
                      ? "Payment Not Processed"
                      : ""
                    : wasDepositRefunded
                    ? "Refunded"
                    : ""}
                </p>
              </div>
            </>
          )}
          <p className="fw-700">Payment method</p>
          <p className="flex flex-align-center">
            {credCardInfo && (
              <>
                <img
                  alt=""
                  src={`/img/cc-${credCardInfo.cardBrand
                    .split(" ")
                    .join("-")
                    .toLowerCase()}.png`}
                  height={20}
                  className="mr-8"
                />
                {credCardInfo.cardBrand} ending in {credCardInfo.lastFour}
                {status === TripRegistrationStatuses.Pending && (
                  <span className="tooltip-container">
                    <i className="material-icons large-text ml-8">info</i>
                    <span className="tooltip" style={{ whiteSpace: "pre" }}>
                      Invalid payment method?{"\n"}Payment method can be updated
                      from the student app at{"\n"}
                      {REACT_APP_COCI_STUDENT_PORTAL_DOMAIN}trips/{tripType}/
                      {programScheduleId}?updatePayment=true
                    </span>
                  </span>
                )}
              </>
            )}
          </p>
        </div>
        <div className="two-column-grid">
          <p className="fw-700">Applied promo codes</p>
          <p>{promoCodesApplied?.map(({ code }) => code).join(", ") || "–"}</p>
          {rewardRequest && (
            <>
              <p className="fw-700">Applied credits</p>
              <p>{getCreditsDisplay(registration)}</p>
            </>
          )}
          <p className="fw-700">Latest cancellation</p>
          <p>
            {latestCancellationDate
              ? moment(latestCancellationDate).format("MMMM D, YYYY h:mm A")
              : ""}
          </p>
          <div />
        </div>
      </div>
      <div className="student-detail-section">
        <p className="medium-text fw-700 mb-12">Refunds & Cancellations</p>
        {!readOnlyAccess && (
          <div className="mb-16">
            {isTravelTrip ? (
              <RegistrationDepositAndFeeRefunds
                onRegistrationUpdated={onRegistrationUpdated}
                registration={registration}
              />
            ) : (
              <RegistrationSplitFeeRefunds
                onRegistrationUpdated={onRegistrationUpdated}
                registration={registration}
              />
            )}
          </div>
        )}
        <p className="fw-700 mb-8">Refund history</p>
        {refundHistory.length > 0 ? (
          <div className="two-column-grid">
            {refundHistory.map((h, i) => (
              <Fragment key={i}>
                <p>{h.description}</p>
                <p>${formatCurrency(Math.abs(h.amount))}</p>
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="accent-text">No refunds</div>
        )}
      </div>
      {!readOnlyAccess && status !== TripRegistrationStatuses.Cancelled && (
        <div className="student-detail-section">
          <RegistrationCancellation
            isTravelTrip={isTravelTrip}
            latestCancellationDate={latestCancellationDate}
            onRegistrationUpdated={onRegistrationUpdated}
            registration={registration}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(RegistrationPayment);
