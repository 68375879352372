import React from "react";
import { StyledSelect } from "../../lib/coc-common-components/StyledComponents/StyledSelect";

// interface IsraeLinksSelectionProps {
//   sessions: Array<any>;
//   selectedSessionId: Number;
//   handleProgramSessionChange: (str: string) => void;
// }

const IsraeLinksSelection = ({
  sessions,
  selectedSessionId,
  handleProgramSessionChange,
}) => {
  const displayableSessions = [...sessions];
  if (selectedSessionId === 0) {
    displayableSessions.unshift({
      title: "IsraeLinks Sessions by dates",
      programId: 0,
      id: 0,
    });
  }

  return (
    <StyledSelect>
      <select
        value={selectedSessionId.toString() || ""}
        onChange={(e) => handleProgramSessionChange(e.currentTarget.value)}
      >
        {displayableSessions.map((session) => (
          <option key={session.id} value={session.id}>
            {session.title}
          </option>
        ))}
      </select>
    </StyledSelect>
  );
};

export default IsraeLinksSelection;
