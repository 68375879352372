import React from "react";
import { Link } from "react-router";
import ImpersonateUser from "../shared/ImpersonateUser";
import Loader from "../common/Loader";
import StudentActivityTable from "./StudentActivityTable";
import StudentProfileModal from "./studentProfile/StudentProfileModal";
import FilePreviewModal from "../common/FilePreviewModal";
import Chip from "@material-ui/core/Chip";

import StudentsApi from "../../services/resources/StudentsApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { formatFullName, impersonationUserTypes } from "../../lib";
import axios from "axios";
import moment from "moment";

export default class StudentDetailsPage extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: true,
    showProfileImageModal: false,
    showStudentProfileModal: false,
    studentDetails: null,
    systemLists: {},
    systemListsErrorMessage: "",
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getStudentDetails();
    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getStudentDetails = async () => {
    try {
      const studentDetails = await StudentsApi.getStudent(
        this.apiSignal.token,
        this.props.params.id
      );

      this.setState({
        studentDetails,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve student details. Please try again."
          ),
        });
      }
    }

    this.setState({ loading: false });
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "studentActivityProgramTypes",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  render() {
    const {
      params: { id: studentId },
    } = this.props;

    const {
      errorMessage,
      loading,
      showProfileImageModal,
      showStudentProfileModal,
      studentDetails,
      systemLists,
    } = this.state;

    const {
      campus,
      cell,
      createdBy,
      dateCreated,
      didStudentCreateAccount,
      didStudentVerifyCampus,
      didStudentVerifyCell,
      didStudentVerifyProfile,
      email,
      isArchived,
      isJewish,
      person: { age, dob, gender, firstName, lastName, profileImageURL },
      primaryShliach,
      primaryShlucha,
    } = studentDetails || {
      person: {},
    };

    return (
      <div className="page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !studentDetails ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong and we could not retrieve student details. Please try again."}
            </p>
          </div>
        ) : (
          <div className="container">
            <ol className="breadcrumb">
              <li className="mt-16">Directory</li>
              <li className="mt-16">
                <Link className="link-text" to="/students">
                  Students
                </Link>
              </li>
            </ol>
            <div className="card mb-16">
              <p className="xxl-text fw-700 mb-32">Students</p>
              <hr />
              <div className="student-details-section">
                <div className="flex flex-align-center flex-justify-space mb-16">
                  <div className="flex flex-align-center">
                    {profileImageURL ? (
                      <>
                        <img
                          alt="profile"
                          className="student-profile-img"
                          onClick={() =>
                            this.setState({ showProfileImageModal: true })
                          }
                          src={profileImageURL}
                        />
                        <FilePreviewModal
                          close={() =>
                            this.setState({ showProfileImageModal: false })
                          }
                          previewStyle={{ objectFit: "contain" }}
                          show={showProfileImageModal}
                          title="Student Profile Image"
                          url={profileImageURL}
                          withDownload={true}
                        />
                      </>
                    ) : (
                      <img
                        alt="profile-placeholder"
                        className="student-profile-img placeholder-img"
                        src="/img/profile-placeholder.svg"
                      />
                    )}
                    <p className="xxl-text fw-700 mb-16">
                      {formatFullName(firstName, lastName)}
                    </p>
                    {AuthService.UserHasClaim(
                      PermissionClaims.UserImpersonation
                    ) &&
                      email && (
                        <ImpersonateUser
                          className="ml-16"
                          username={email}
                          userType={impersonationUserTypes.Student}
                        />
                      )}
                    {isArchived && (
                      <Chip
                        className="ml-16"
                        label="Archived"
                        classes={{ label: "small-text" }}
                      />
                    )}
                  </div>
                  <button
                    className="btn btn-primary custom-btn ml-16"
                    onClick={() =>
                      this.setState({ showStudentProfileModal: true })
                    }
                  >
                    Edit
                  </button>
                </div>
                {(!didStudentCreateAccount || !didStudentVerifyProfile) && (
                  <p className="small-text accent-text italic-text line-height-double mt-8">
                    {!didStudentCreateAccount
                      ? "An account has not yet been created by the student."
                      : didStudentVerifyCampus || didStudentVerifyCell
                      ? `Only the ${
                          didStudentVerifyCampus && didStudentVerifyCell
                            ? "Campus and Cell have"
                            : didStudentVerifyCampus
                            ? "Campus has"
                            : "Cell has"
                        } been verified by the student. The rest of the student profile has not yet been verified.`
                      : "Student profile has not yet been verified by the student."}{" "}
                    Note that {!didStudentCreateAccount ? "" : "unverified "}
                    profile fields will not be visible to the student until the
                    profile is verified, and will be overridden by the student
                    upon verification.
                  </p>
                )}
                <div className="student-info-two-column-grid">
                  <div>
                    <p>Email</p>
                    <p>{email}</p>
                  </div>
                  <div>
                    <p>Campus</p>
                    {campus ? (
                      <Link className="link-text" to={`/schools/${campus.id}`}>
                        {campus.name}
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div>
                    <p>Phone</p>
                    <p>{cell}</p>
                  </div>
                  <div>
                    <p>Shliach</p>
                    <p>
                      {primaryShliach ? (
                        <Link
                          className="link-text"
                          to={`/shluchim/${primaryShliach.shliachID}`}
                        >
                          {primaryShliach.fullName}
                        </Link>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div>
                    <p>Age / Birthday</p>
                    <p>
                      {age}{" "}
                      {dob ? `/ ${moment(dob).format("MMMM D, YYYY")}` : ""}
                    </p>
                  </div>
                  <div>
                    <p>Shlucha</p>
                    <p>
                      {primaryShlucha ? (
                        <Link
                          className="link-text"
                          to={`/shluchim/${primaryShlucha.shliachID}`}
                        >
                          {primaryShlucha.fullName}
                        </Link>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div>
                    <p>Gender</p>
                    <p>{gender}</p>
                  </div>
                  <div>
                    <p>Jewish</p>
                    <p>{isJewish ? "Yes" : isJewish === false ? "No" : ""}</p>
                  </div>
                  <div>
                    <p>Date Created</p>
                    <p>
                      {dateCreated
                        ? moment(dateCreated).format("MMMM D, YYYY")
                        : "—"}
                    </p>
                  </div>
                  {createdBy && (
                    <div>
                      <p>Created By</p>
                      <p>{createdBy}</p>
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <div className="student-details-section">
                <StudentActivityTable
                  studentActivityProgramTypes={
                    systemLists.studentActivityProgramTypes
                  }
                  studentId={studentId}
                  studentName={formatFullName(firstName, lastName)}
                />
              </div>
              {showStudentProfileModal && (
                <StudentProfileModal
                  close={() =>
                    this.setState({ showStudentProfileModal: false })
                  }
                  onSubmit={this.getStudentDetails}
                  show={true}
                  studentProfile={studentDetails}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
