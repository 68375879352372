import React from "react";
import debounce from "lodash.debounce";

export default class CoursesTableFilters extends React.PureComponent {
  state = {
    keyword: "",
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => this.props.applyFilters(this.state);

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { keyword } = this.state;

    return (
      <div className="mt-24 mb-24">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search"
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
      </div>
    );
  }
}
