import React from "react";
import { Link, browserHistory } from "react-router";
import FilteredMultiSelect from "react-filtered-multiselect";

import "./Role.css";
import { ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import PermissionsApi from "../../services/resources/PermissionsApi";
import * as formControls from "../common/FormControls";
import {
  ModalWithButtons /* , ModalButton */,
} from "../../lib/coc-common-components";
// import { Claim, Role as IRole } from "../../models/Contact";

const BOOTSTRAP_CLASSES = {
  filter: "form-control",
  select: "form-control",
  button: "btn btn btn-block btn-default",
};

// interface RoleProps {
//   [propertyName: string];
// }

// interface RoleState {
//   role?: IRole;
//   roleOriginal?: IRole;
//   claimsList?: Array<Claim>;
//   loading?: boolean;
//   displayError?: string;
//   displayDelete?: string;
//   displayConfirm?: string;
//   addNewMode?: boolean;
//   isEditingRole?: boolean;
// }

export default class Role extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: this.newEmptyRole(),
      claimsList: [],
      loading: false,
      displayError: "",
      displayDelete: "",
      displayConfirm: "",
      addNewMode: false,
      isEditingRole: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleControlChange = this.handleControlChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.fetchRoleData = this.fetchRoleData.bind(this);
    this.loadClaims = this.loadClaims.bind(this);
    this.newEmptyRole = this.newEmptyRole.bind(this);
  }

  handleSave(domEvent) {
    if (domEvent) {
      domEvent.preventDefault();
    }
    this.setState({ loading: true }, () => {
      const apiCall = this.state.addNewMode
        ? PermissionsApi.createRole
        : PermissionsApi.editRole;
      apiCall(this.state.role)
        .then((response) => {
          /** @type {IRole} */
          const newRole = response.data;
          newRole.claims = this.state.role.claims;
          this.setState(
            {
              role: newRole,
              roleOriginal: undefined,
              isEditingRole: false,
              loading: false,
            },
            () => {
              if (this.state.addNewMode) {
                browserHistory.push(`/config/roles/${newRole.id}`);
                this.setState({ addNewMode: false }, this.componentDidMount);
              }
            },
          );
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        });
    });
  }
  /** @param {Claim} claim
   * @returns {Claim}
   */
  formatedClaim(claim) {
    return {
      ...claim,
      name: claim && claim.claimGroup.name + " - " + claim.name,
    };
  }
  /** @param {number} [id=0] */
  fetchRoleData(id) {
    if (id <= 0) {
      id = Number(this.props.params.id);
    }

    this.setState({ loading: true }, () => {
      PermissionsApi.getRole(id)
        .then((response) => {
          /** @type {IRole} */
          let role = response.data;
          let roleClaims = role.claims.map(this.formatedClaim);
          roleClaims = this.sortedClaims(roleClaims);
          role = { ...role, claims: roleClaims };
          this.setState({ role, loading: false });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        });
    });
  }

  loadClaims() {
    this.setState({ loading: true }, () => {
      PermissionsApi.listClaims()
        .then((response) => {
          const claimsList = response.data.results.map(this.formatedClaim);
          this.setState({
            loading: false,
            claimsList: this.sortedClaims(claimsList),
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        });
    });
  }
  /** @returns {IRole} */
  newEmptyRole() {
    return {
      name: "",
      description: "",
    };
  }

  handleEdit() {
    if (this.state.isEditingRole || this.state.addNewMode) {
      // click on cancel
      if (this.state.addNewMode) {
        this.setState({ role: this.newEmptyRole() });
      } else {
        this.setState({
          isEditingRole: false,
          role: this.state.roleOriginal || this.newEmptyRole(),
          roleOriginal: undefined,
        });
      }
    } else {
      this.setState({
        isEditingRole: true,
        roleOriginal: this.state.role,
      });
    }
  }

  handleDelete() {
    this.setState({ displayDelete: "", loading: true });
    PermissionsApi.deleteRole(this.state.role.id)
      .then((response) => {
        const text = "Role successfully deleted";
        this.setState({ loading: false, displayConfirm: text });
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err);
        this.setState({ loading: false, displayError: errors.join("\n") });
      });
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.RoleManager)) {
      return;
    }

    this.loadClaims();
    if (this.props.location.pathname === "/config/roles/new") {
      this.setState({
        role: this.newEmptyRole(),
        addNewMode: true,
        loading: false,
      });
    } else {
      const id = Number(this.props.params.id);
      if (!Number.isNaN(id)) {
        this.fetchRoleData(id);
      }
    }
  }
  /** @param {Array<Claim>} deselectedOptions */
  handleDeselect(deselectedOptions) {
    const newClaims = this.state.role.claims.slice();
    deselectedOptions.forEach((option) => {
      newClaims.splice(newClaims.indexOf(option), 1);
    });
    this.setState({
      role: Object.assign({}, this.state.role, {
        claims: this.sortedClaims(newClaims),
      }),
    });
  }
  /** @param {Claim} claim
   * @returns {string}
   */
  formatClaimOrder(claim) {
    return (
      (
        "000" + claim &&
        claim.claimGroup &&
        claim.claimGroup.sortOrder.toString()
      ).substr(-3, 3) +
      "-" +
      ("000" + claim && claim.sortOrder.toString()).substr(-3, 3)
    );
  }
  /** @param {Array<Claim>} claims
   * @returns {Array<Claim>}
   */
  sortedClaims(claims) {
    const returnArray = [...claims];
    returnArray.sort((a, b) =>
      this.formatClaimOrder(a) > this.formatClaimOrder(b) ? 1 : -1,
    );
    return returnArray;
  }

  /** @param {Array<Claim>} selectedOptions */
  handleSelect(selectedOptions) {
    this.setState({
      role: Object.assign({}, this.state.role, {
        claims: this.sortedClaims(selectedOptions),
      }),
    });
  }
  /** @param {string} rolePropertyName */
  handleControlChange(rolePropertyName) {
    return (event) => {
      this.setState({
        role: Object.assign({}, this.state.role, {
          [rolePropertyName]: event.currentTarget.value,
        }),
      });
    };
  }

  errorOnSaveButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayError: "" }),
    });
    return buttons;
  }

  confirmDeleteButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "I understand. Delete it now.",
      className: "btn btn-danger",
      onClick: this.handleDelete,
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDelete: "" }),
    });
    return buttons;
  }

  confirmButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({ displayConfirm: "" }, () => {
          browserHistory.push("/config/roles");
        }),
    });
    return buttons;
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.RoleManager)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { role, isEditingRole, addNewMode } = this.state;
    const isAddingOrEditing = isEditingRole || addNewMode || false;
    const currentState = isAddingOrEditing
      ? formControls.EditableControlStates.editable
      : formControls.EditableControlStates.nonEditable;

    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>
            <Link to="/config">Configure</Link>
          </li>
          <li>
            <Link to="/config/roles">Roles</Link>
          </li>
          <li>{role.name}</li>
        </ol>
        <form onSubmit={this.handleSave}>
          <div className="well well-lg">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <dl className="dl-horizontal">
                  <dt>Name</dt>
                  <dd>
                    <formControls.EditableLabel
                      required={true}
                      currentState={currentState}
                      currentValue={role.name}
                      handleChange={this.handleControlChange("name")}
                    />
                  </dd>
                  <dt>Description</dt>
                  <dd>
                    <formControls.EditableTextArea
                      required={true}
                      currentState={currentState}
                      currentValue={role.description}
                      handleChange={this.handleControlChange("description")}
                      className={"shortDescriptionClass"}
                    />
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="well well-lg">
            <div className="row">
              <h4>Permissions</h4>
              <br />
              {isAddingOrEditing ? (
                <div>
                  <div className="col-md-5">
                    <dl>
                      <dt>Current</dt>
                      <dd>
                        <FilteredMultiSelect
                          buttonText="Remove"
                          classNames={{
                            ...BOOTSTRAP_CLASSES,
                            buttonActive: "btn btn btn-block btn-danger",
                          }}
                          onChange={this.handleDeselect}
                          options={this.state.role.claims || []}
                          textProp="name"
                          valueProp="id"
                        />
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-5">
                    <dl>
                      <dt>Available</dt>
                      <dd>
                        <FilteredMultiSelect
                          buttonText="Add"
                          classNames={{
                            ...BOOTSTRAP_CLASSES,
                            buttonActive: "btn btn btn-block btn-primary",
                          }}
                          onChange={this.handleSelect}
                          options={this.state.claimsList}
                          selectedOptions={this.state.role.claims || []}
                          textProp="name"
                          valueProp="id"
                        />
                      </dd>
                    </dl>
                  </div>
                </div>
              ) : (
                <div>
                  {(this.state.role.claims || []).length === 0
                    ? "No permissions assigned yet"
                    : this.state.role.claims.map((claim) => (
                        <div key={claim.id}>{claim.name}</div>
                      ))}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 text-right">
                {isAddingOrEditing && (
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "1em" }}
                  >
                    Save
                  </button>
                )}
                {!addNewMode && (
                  <span
                    className="btn btn-primary btn-sm"
                    onClick={this.handleEdit}
                  >
                    {isEditingRole ? "Cancel" : "Edit"}
                  </span>
                )}
                &nbsp;
                {!addNewMode && (
                  <span
                    className="btn btn-primary btn-sm"
                    onClick={() =>
                      this.setState({
                        displayDelete: "You are about to delete this Role",
                      })
                    }
                  >
                    Delete
                  </span>
                )}
                {this.state.displayError && (
                  <ModalWithButtons
                    buttons={this.errorOnSaveButtons()}
                    text={this.state.displayError}
                  />
                )}
                {this.state.displayDelete && (
                  <ModalWithButtons
                    buttons={this.confirmDeleteButtons()}
                    text={this.state.displayDelete}
                  />
                )}
                {this.state.displayConfirm && (
                  <ModalWithButtons
                    buttons={this.confirmButtons()}
                    text={this.state.displayConfirm}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}
