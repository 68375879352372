import React from "react";
import ShliachDetails from "./ShliachDetails";
import ChabadHouseDetails from "./ChabadHouseDetails";

export default (props) => (
  <div className="card shliach-details-card">
    <ShliachDetails {...props} />
    <ChabadHouseDetails {...props} />
  </div>
);
