import React from "react";

export default class StudentRewardsTableHeader extends React.PureComponent {
  render() {
    const columns = [
      "Name",
      "Credits / Total Value",
      "Date Requested",
      "Status",
      "Approve/Deny",
    ];

    return (
      <div className="students-rewards-history-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
