import React from "react";
import PortalRedirectsTable from "./PortalRedirectsTable";
import FadeOutErrorMessage from "../../shared/FadeOutErrorMessage";
import Loader from "../../common/Loader";
import { Unauthorized } from "../../../lib/coc-common-components";
import { notify } from "react-notify-toast";

import SystemApi from "../../../services/resources/SystemApi";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

export default class PortalRedirectsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,
      errorMessage: "",
      initialPortalRedirects: [],
      loading: false,
      portalDomains: [],
      portalRedirects: [],
      saveErrorMessage: "",
      saveLoading: false,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ConfigsEdit)) {
      this.setState({ authorized: false });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getPortalRedirectsInfo();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { initialPortalRedirects, portalRedirects } = this.state;

    if (!_isEqual(initialPortalRedirects, portalRedirects)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getPortalRedirectsInfo = async () => {
    try {
      this.setState({ loading: true });

      const systemLists = await SystemApi.lists([
        "portalDomains",
        "portalRedirects",
      ]);
      const { portalDomains = [], portalRedirects = [] } = systemLists;
      this.setState({
        initialPortalRedirects: _cloneDeep(portalRedirects),
        loading: false,
        portalDomains,
        portalRedirects,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        });
      }
    }
  };

  updatePortalRedirects = (portalRedirects) =>
    this.setState({
      portalRedirects,
    });

  savePortalRedirects = async () => {
    try {
      this.setState({
        saveErrorMessage: "",
        saveLoading: true,
      });

      const savedPortalRedirects = await SystemApi.updatePortalRedirects(
        this.apiSignal.token,
        this.state.portalRedirects,
      );

      this.setState(
        {
          initialPortalRedirects: _cloneDeep(savedPortalRedirects),
          portalRedirects: savedPortalRedirects,
          saveLoading: false,
        },
        () => {
          notify.show("Portal Redirects have been saved", "success");
        },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          saveErrorMessage: ApiCallErrorMessageHandler(err),
          saveLoading: false,
        });
      }
    }
  };

  render() {
    const {
      authorized,
      errorMessage,
      loading,
      initialPortalRedirects,
      portalDomains,
      portalRedirects,
      saveErrorMessage,
      saveLoading,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return loading ? (
      <div className="full-page-loader">
        <Loader />
      </div>
    ) : errorMessage ? (
      <div className="full-page-error-text error-text">
        <img src="/img/error.svg" alt="error robot" height="240" />
        <p>{errorMessage}</p>
      </div>
    ) : (
      <div className="page redirects-page container">
        <div className="flex flex-align-center flex-justify-space mb-16">
          <p className="xl-text fw-700">Portal URL Redirects</p>
          <div>
            <button
              className="custom-btn btn btn-accent btn-medium uppercase-text fw-400"
              disabled={
                saveLoading || _isEqual(portalRedirects, initialPortalRedirects)
              }
              onClick={this.savePortalRedirects}
            >
              {saveLoading ? "Saving..." : "Save Changes"}
            </button>
            {saveErrorMessage && (
              <p>
                <FadeOutErrorMessage message={saveErrorMessage} />
              </p>
            )}
          </div>
        </div>
        <p className="small-text mb-16">
          Note that these redirects are intended for links to pages within the
          portal. External links can technically be set up here, but those will
          first direct the users to the selected portal and force them to log in
          before re-directing them. If you need to set up an external link
          without that limitation, reach out to the development team to
          configure that.
        </p>
        <PortalRedirectsTable
          portalDomains={portalDomains}
          portalRedirects={portalRedirects}
          updatePortalRedirects={this.updatePortalRedirects}
        />
      </div>
    );
  }
}
