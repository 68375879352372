import React from "react";
import debounce from "lodash.debounce";
import Toggle from "../../../../shared/Toggle";

export default class RegistrationsTableFilters extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showEnrolled: true,
      keyword: props.filters.keyword || "",
    };
  }

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter
    );
  };

  onFilter = () => this.props.applyFilters(this.state);

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { showEnrolledFilter, registrationType } = this.props;
    const { showEnrolled, keyword } = this.state;

    return (
      <div className="flex flex-align-center">
        <div className="search-input">
          <input
            type="text"
            placeholder={
              registrationType === "Student"
                ? "Search Student or Campus Name"
                : "Search Shliach, Chabad House, or Campus Name"
            }
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        {showEnrolledFilter && (
          <div className="ml-24">
            <Toggle
              name="showEnrolled"
              onChange={this.onChange}
              options={[
                {
                  value: true,
                  display: "Enrolled",
                },
                {
                  value: false,
                  display: "Not Enrolled",
                },
              ]}
              value={showEnrolled}
            />
          </div>
        )}
      </div>
    );
  }
}
