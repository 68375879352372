import React from "react";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons /* , ModalButton */,
} from "../../../lib/coc-common-components";

import {
  /* Organization, OrganizationFilter, */ EmptyOrganizationFilter,
} from "../../../models/Organization";
import OrganizationApi from "../../../services/resources/OrganizationsApi";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import OrganizationListPage from "./OrganizationListPage";
import { getSelectValues } from "../../common/Utils";

// interface OrganizationListPageContainerProps {
//   location;
// }

// interface OrganizationListPageContainerState {
//   organizations: Array<Organization>;
//   filteredOrganizations: Array<Organization>;
//   currentFilters: OrganizationFilter;
//   loading: boolean;
//   displayError: string;
// }

class OrganizationListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizations: [],
      filteredOrganizations: [],
      currentFilters: {
        ...EmptyOrganizationFilter(),
        name: props.location.query.q,
      },
      loading: true,
      displayError: "",
    };

    this.handleFiltersChanged = this.handleFiltersChanged.bind(this);
  }
  /** @param {OrganizationFilter} filters */
  handleFiltersChanged(filters) {
    let newFilteredOrganizations = [...this.state.organizations];
    if (filters.name) {
      newFilteredOrganizations = newFilteredOrganizations.filter(
        (o) =>
          o.name.toLowerCase().includes(filters.name.toLowerCase()) ||
          o.contacts.find((c) =>
            (c.firstName + " " + c.lastName)
              .toLowerCase()
              .includes(filters.name.toLowerCase()),
          ),
      );
    }
    if (
      filters.organizationCategoryType &&
      filters.organizationCategoryType > 0
    ) {
      newFilteredOrganizations = newFilteredOrganizations.filter(
        (o) => +o.category.type === +filters.organizationCategoryType,
      );
    }
    if (filters.categoryId) {
      newFilteredOrganizations = newFilteredOrganizations.filter(
        (o) => o.category.id === filters.categoryId,
      );
    }
    if (filters.countries) {
      const countryArray = getSelectValues(filters.countries);
      newFilteredOrganizations = newFilteredOrganizations.filter(
        (o) =>
          o.address && countryArray.indexOf(o.address.countryId || -1) >= 0,
      );
    }
    if (filters.states) {
      const stateArray = getSelectValues(filters.states);
      newFilteredOrganizations = newFilteredOrganizations.filter(
        (o) => o.address && stateArray.indexOf(o.address.stateId || -1) >= 0,
      );
    }
    this.setState({
      filteredOrganizations: newFilteredOrganizations,
      currentFilters: filters,
    });
  }

  messageButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayError: "" }),
    });
    return buttons;
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return;
    }

    this.setState({ loading: true }, () =>
      OrganizationApi.list()
        .then((response) => {
          this.setState(
            {
              organizations: response.data.results,
              filteredOrganizations: response.data.results,
              loading: false,
            },
            () => this.handleFiltersChanged(this.state.currentFilters),
          );
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  componentWillReceiveProps(newProps) {
    const newFilterState = {
      /*...this.state.currentFilters,*/ ...EmptyOrganizationFilter(),
      name: newProps.location.query.q,
    };
    this.handleFiltersChanged(newFilterState);
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { filteredOrganizations, currentFilters } = this.state;

    return (
      <div className="col-sm-12">
        <OrganizationListPage
          organizations={filteredOrganizations}
          handleFiltersChanged={this.handleFiltersChanged}
          currentFilters={currentFilters}
        />

        {this.state.displayError && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayError}
          />
        )}

        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default OrganizationListPageContainer;
