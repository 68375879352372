import React from "react";
import { Link } from "react-router";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import PermissionsApi from "../../services/resources/PermissionsApi";

export default class Roles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: "",
      loading: false,
      roles: [],
      totalResults: 0,
    };
  }

  fetchRoleData() {
    const currentlyLoading = this.state.loading;
    this.setState({ loading: true }, () =>
      PermissionsApi.listRoles()
        .then((response) => {
          this.setState({
            loading: currentlyLoading,
            roles: response.data.results,
            totalResults: response.data.count,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.RoleManager)) {
      return;
    }

    this.fetchRoleData();
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.RoleManager)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const renderTableHeader = () => {
      return (
        <tr>
          <th>Role</th>
          <th>Description</th>
          <th />
        </tr>
      );
    };

    const renderRoles = (role) => {
      const rolePath = `/config/roles/${role.id}`;
      return (
        <tr key={role.id}>
          <td>{role.name}</td>
          <td>{role.description}</td>
          <td>
            <Link to={rolePath} className="btn btn-info btn-sm">
              View
            </Link>
          </td>
        </tr>
      );
    };

    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>
            <Link to="/config">Configure</Link>
          </li>
          <li>Roles</li>
        </ol>

        <div className="content-window">
          <table className="table">
            <thead>{renderTableHeader()}</thead>
            <tbody>{this.state.roles.map(renderRoles)}</tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-sm-10" />
          <div className="col-sm-2">
            <Link to={"/config/roles/new"} className="btn btn-primary btn-sm">
              Add New
            </Link>
          </div>
        </div>

        {this.state.loading && <Loader />}
      </div>
    );
  }
}
