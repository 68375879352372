import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import PaginatedTable from "../../../../shared/PaginatedTable";
import AddGroupPaymentModal from "./AddGroupPaymentModal";
import AddRegistrationModal from "./AddRegistrationModal";
import ImportRegistrationsModal from "./ImportRegistrationsModal";
import RegistrationsTableActions from "./RegistrationsTableActions";
import RegistrationsTableFilters from "./RegistrationsTableFilters";
import RegistrationsTableHeader from "./RegistrationsTableHeader";
import RegistrationsTableRow from "./RegistrationsTableRow";

import ProgramsApi from "../../../../../services/resources/ProgramsApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import { formatFullName } from "../../../../../lib";
import axios from "axios";
import queryString from "query-string";

export default class RegistrationsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const { pg, res, search, sortBy } = props.location.query;

    this.state = {
      errorMessage: "",
      filters: { showEnrolled: true, keyword: search || "" },
      loading: false,
      page: pg || 1,
      registrations: [],
      removeRegistration: null,
      removeRegistrationErrorMessage: null,
      removeRegistrationLoading: false,
      results: res || 12,
      showAddPaymentModal: false,
      showAddRegistrationModal: false,
      showImportRegistrationsModal: false,
      showRemoveRegistrationModal: false,
      sortBy: sortBy || this.sortOptions[0].value,
      success: true,
      totalRegistrations: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterRegistrations = (filters) => {
    this.setState({ filters }, () => {
      this.getRegistrations();
    });
  };

  getScheduleRegistration = async (
    token,
    programID,
    scheduleID,
    page,
    results,
    sortBy,
    filters,
    registrationType,
    hasApplicationProcess,
    forExport = false
  ) => {
    return filters.showEnrolled
      ? ProgramsApi.getScheduleRegistrations(
          token,
          programID,
          scheduleID,
          page,
          results,
          sortBy,
          filters,
          registrationType,
          hasApplicationProcess,
          forExport
        )
      : ProgramsApi.getScheduleUnenrolled(
          token,
          programID,
          scheduleID,
          page,
          results,
          sortBy,
          filters,
          forExport
        );
  };

  getRegistrations = async (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    //update page, sortBy, and search query
    const {
      location: {
        query: {
          pg: prevPageQuery,
          res: prevResultsQuery,
          sortBy: prevSortByQuery,
          search: prevSearchQuery,
          ...query
        },
        pathname,
      },
    } = this.props;
    const pageQuery = page > 1 ? page : undefined;
    const resultsQuery = results?.toString() !== "12" ? results : undefined;
    const sortByQuery = sortBy !== "name" ? sortBy : undefined;
    const searchQuery = this.state.filters.keyword || undefined;
    if (
      pageQuery !== prevPageQuery ||
      resultsQuery !== prevResultsQuery ||
      sortByQuery !== prevSortByQuery ||
      searchQuery !== prevSearchQuery
    ) {
      const queryUpdate = {
        ...query,
        pg: pageQuery,
        res: resultsQuery,
        search: searchQuery,
        sortBy: sortByQuery,
      };
      browserHistory.replace(
        `${pathname}?${queryString.stringify(queryUpdate)}`
      );
    }

    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      async () => {
        const {
          programSchedule: {
            hasApplicationProcess,
            programID,
            registrationType,
            scheduleID,
          },
        } = this.props;
        try {
          const { registrations, totalRegistrations } =
            await this.getScheduleRegistration(
              this.apiSignal.token,
              programID,
              scheduleID,
              page,
              results,
              sortBy,
              this.state.filters,
              registrationType,
              hasApplicationProcess
            );

          this.setState({
            loading: false,
            registrations,
            totalRegistrations,
          });
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              errorMessage: ApiCallErrorMessageHandler(err),
              loading: false,
              registrations: [],
              success: false,
              totalRegistrations: 0,
            });
          }
        }
      }
    );
  };

  getRegistrationsForExport = async () => {
    const { sortBy, filters } = this.state;
    const {
      programSchedule: {
        hasApplicationProcess,
        programID,
        registrationType,
        scheduleID,
      },
    } = this.props;

    const { registrations } = await this.getScheduleRegistration(
      this.apiSignal.token,
      programID,
      scheduleID,
      undefined,
      undefined,
      sortBy,
      filters,
      registrationType,
      hasApplicationProcess,
      true
    );

    return registrations;
  };

  getJotformSubmissionsForExport = async () => {
    const {
      programSchedule: { programID, scheduleID },
    } = this.props;

    const submissionsForExport =
      await ProgramsApi.getScheduleJotformSubmissions(
        this.apiSignal.token,
        programID,
        scheduleID
      );

    return submissionsForExport;
  };

  submitNewRegistration = async (registration, registerSpouse) => {
    const submitRegistrationResponse = {};

    try {
      const {
        programSchedule: { programID, scheduleID },
      } = this.props;
      await ProgramsApi.submitScheduleRegistration(
        this.apiSignal.token,
        programID,
        scheduleID,
        registration,
        registerSpouse
      );
      submitRegistrationResponse.success = true;

      //reload registrations
      this.getRegistrations();
    } catch (err) {
      if (!axios.isCancel(err)) {
        submitRegistrationResponse.error = err;
        submitRegistrationResponse.errorMessage =
          ApiCallErrorMessageHandler(err);
      }
    }

    return submitRegistrationResponse;
  };

  reloadRegistrations = () => {
    const { page, results, sortBy } = this.state;
    this.getRegistrations(page, results, sortBy);
  };

  removeRegistration = async () => {
    this.setState({
      removeRegistrationErrorMessage: "",
      removeRegistrationLoading: true,
    });

    try {
      const {
        programSchedule: { programID, scheduleID },
      } = this.props;
      await ProgramsApi.removeScheduleRegistration(
        this.apiSignal.token,
        programID,
        scheduleID,
        this.state.removeRegistration.id
      );

      this.reloadRegistrations();

      this.setState({
        removeRegistration: null,
        removeRegistrationLoading: false,
        showRemoveRegistrationModal: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          removeRegistrationErrorMessage: ApiCallErrorMessageHandler(err),
          removeRegistrationLoading: false,
        });
      }
    }
  };

  submitGroupPayment = async (paymentDetails) => {
    const submitPaymentResponse = {};

    try {
      const {
        programSchedule: { programID, scheduleID },
      } = this.props;
      await ProgramsApi.submitScheduleRegistrationsGroupPayment(
        this.apiSignal.token,
        programID,
        scheduleID,
        paymentDetails
      );
      submitPaymentResponse.success = true;

      this.reloadRegistrations();
    } catch (err) {
      if (!axios.isCancel(err)) {
        submitPaymentResponse.error = err;
        submitPaymentResponse.errorMessage = ApiCallErrorMessageHandler(err);
      }
    }

    return submitPaymentResponse;
  };

  sortOptions = [
    { id: "name", name: "Name" },
    { id: "date", name: "Newest" },
  ];

  getTableTemplateColumns = (
    showEnrolled,
    registrationType,
    showApplicationDetails,
    showApprovalDetails,
    showPaymentDetails
  ) => {
    const conditions = [
      { condition: !showEnrolled, value: " .75fr" },
      { condition: showEnrolled, value: " 1fr" },
      { condition: registrationType === "Shliach", value: " 1fr" },
      { condition: showEnrolled && showApplicationDetails, value: " .75fr" },
      { condition: showEnrolled && showApprovalDetails, value: " .75fr" },
      {
        condition: showEnrolled && showPaymentDetails,
        value: " .75fr .75fr .75fr",
      },
      { condition: !showEnrolled, value: " .75fr .65fr" },
      { condition: showEnrolled, value: " 60px" },
    ];

    const colTemplate = conditions.reduce(
      (acc, { condition, value }) => (condition ? acc + value : acc),
      "1fr 1fr"
    );

    return colTemplate;
  };

  render() {
    const { mobileMode, programSchedule, title } = this.props;
    const {
      applicationSettings,
      includesAllShluchim,
      hasApplicationProcess,
      registrationType,
    } = programSchedule;
    const {
      filters,
      loading,
      page,
      registrations,
      removeRegistration,
      removeRegistrationErrorMessage,
      removeRegistrationLoading,
      results,
      showAddPaymentModal,
      showAddRegistrationModal,
      showImportRegistrationsModal,
      showRemoveRegistrationModal,
      sortBy,
      success,
      totalRegistrations,
    } = this.state;

    const showApplicationDetails = hasApplicationProcess;
    const showApprovalDetails =
      showApplicationDetails &&
      applicationSettings?.statuses?.includes("Approved");
    const showPaymentDetails =
      showApplicationDetails && !!applicationSettings?.paymentInstallments;
    const tableTemplateColumns = this.getTableTemplateColumns(
      filters?.showEnrolled,
      registrationType,
      showApplicationDetails,
      showApprovalDetails,
      showPaymentDetails
    );

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center mb-24">
          <p className="xxl-text fw-500">{title}</p>
          <RegistrationsTableActions
            getJotformSubmissionsForExport={this.getJotformSubmissionsForExport}
            getRegistrationsForExport={this.getRegistrationsForExport}
            onAddGroupPayment={() =>
              this.setState({ showAddPaymentModal: true })
            }
            onAddRegistration={() =>
              this.setState({ showAddRegistrationModal: true })
            }
            onImportRegistrations={() =>
              this.setState({ showImportRegistrationsModal: true })
            }
            programSchedule={programSchedule}
            showEnrolled={filters.showEnrolled}
          />
        </div>
        <div className="program-registrations-table">
          <PaginatedTable
            filterComponent={
              <RegistrationsTableFilters
                applyFilters={this.filterRegistrations}
                filters={filters}
                registrationType={registrationType}
                showEnrolledFilter={
                  includesAllShluchim && registrationType === "Shliach"
                }
              />
            }
            loading={loading}
            loadData={this.getRegistrations}
            mobileMode={mobileMode}
            page={page}
            records={registrations}
            renderHeader={() => (
              <RegistrationsTableHeader
                showEnrolled={filters.showEnrolled}
                registrationType={registrationType}
                showApplicationDetails={showApplicationDetails}
                showApprovalDetails={showApprovalDetails}
                showPaymentDetails={showPaymentDetails}
                templateColumns={tableTemplateColumns}
              />
            )}
            renderRow={(registration, index) => (
              <RegistrationsTableRow
                key={index}
                showEnrolled={filters.showEnrolled}
                onRemoveRegistration={() =>
                  this.setState({
                    removeRegistration: registration,
                    showRemoveRegistrationModal: true,
                  })
                }
                programSchedule={programSchedule}
                registration={registration}
                showApplicationDetails={showApplicationDetails}
                showApprovalDetails={showApprovalDetails}
                showPaymentDetails={showPaymentDetails}
                templateColumns={tableTemplateColumns}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            sortOptions={this.sortOptions}
            success={success}
            totalCount={totalRegistrations}
          />
        </div>

        <AddRegistrationModal
          close={() => this.setState({ showAddRegistrationModal: false })}
          programSchedule={programSchedule}
          show={showAddRegistrationModal}
          submit={this.submitNewRegistration}
        />

        {showImportRegistrationsModal && (
          <ImportRegistrationsModal
            close={() => this.setState({ showImportRegistrationsModal: false })}
            reloadRegistrations={this.reloadRegistrations}
            programSchedule={programSchedule}
            show={showImportRegistrationsModal}
          />
        )}

        <AddGroupPaymentModal
          close={() => this.setState({ showAddPaymentModal: false })}
          programSchedule={programSchedule}
          show={showAddPaymentModal}
          submit={this.submitGroupPayment}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({
              removeRegistration: null,
              removeRegistrationErrorMessage: "",
              showRemoveRegistrationModal: false,
            })
          }
          confirm={this.removeRegistration}
          errorMessage={removeRegistrationErrorMessage}
          loading={removeRegistrationLoading}
          message={`Are you sure you'd like to remove ${
            removeRegistration && removeRegistration.lastName
              ? `${formatFullName(
                  removeRegistration.firstName,
                  removeRegistration.lastName
                )}'s registration`
              : "this registration"
          }?`}
          show={showRemoveRegistrationModal}
        />
      </div>
    );
  }
}
