import React from "react";
import ExportCSVButton from "../../shared/ExportCSVButton";
import { EduProgramTypes } from "../EduConsts";
import moment from "moment";

export default function StudentsTableActions(props) {
  const {
    eduProgram,
    getStudentsForExport,
    onImportStudents,
    showLatestCourseInfo,
    onAddStudent,
    hideStatus,
    viewOnly,
  } = props;

  return (
    <div className="flex">
      {!viewOnly && (
        <React.Fragment>
          {eduProgram.type === EduProgramTypes.SinaiScholars && (
            <button
              className="btn custom-btn btn-accent flex flex-align-center mr-16"
              onClick={onImportStudents}
            >
              Import Course Enrollments
            </button>
          )}
          <button
            className="btn custom-btn btn-accent flex flex-align-center mr-16"
            onClick={onAddStudent}
          >
            Add
          </button>
        </React.Fragment>
      )}
      <ExportCSVButton
        className="btn custom-btn btn-accent flex flex-align-center"
        fileName={`${eduProgram.name.replace(/ /g, "_")}_Students`}
        getExportData={getStudentsForExport}
        headers={[
          { label: "Student ID", value: "studentID" },
          { label: "Student First Name", value: "studentFirstName" },
          { label: "Student Last Name", value: "studentLastName" },
          { label: "Student Email", value: "studentEmail" },
          { label: "Student Phone", value: "studentCell" },
          { label: "Campus ID", value: "campusID" },
          { label: "Campus Name", value: "campusName" },
          { label: "Shliach ID", value: "shliachID" },
          { label: "Shliach First Name", value: "shliachFirstName" },
          { label: "Shliach Last Name", value: "shliachLastName" },
          ...(showLatestCourseInfo
            ? [
                { label: "Schedule", value: "latestCourseScheduleName" },
                {
                  label: "Classes Completed",
                  value: "latestCourseScheduleNumOfClassesAttended",
                },
              ]
            : [
                {
                  label: "Courses Completed",
                  value: "numOfCoursesCompleted",
                },
                {
                  label: "Member Since",
                  value: ({ memberSinceDate }) =>
                    memberSinceDate
                      ? moment(memberSinceDate).format("MM/DD/YYYY")
                      : null,
                },
              ]),
          ...(!hideStatus ? [{ label: "Status", value: "statusDisplay" }] : []),
          {
            label: "Review Completed",
            value: ({ latestCourseScheduleDidCompleteSurvey }) =>
              latestCourseScheduleDidCompleteSurvey ? "Yes" : "No",
          },
        ]}
      />
    </div>
  );
}
