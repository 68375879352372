import React, { Fragment } from "react";

export default class SuggestionPerson extends React.PureComponent {
  render() {
    const { person, style } = this.props;
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1.3fr 1.3fr",
          ...style,
        }}
      >
        <SuggestionInfo
          value={
            <Fragment>
              {person.firstName} {person.lastName}
              <a
                href={`/students/${person.studentID}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i
                  className="material-icons ml-8 large-text"
                  style={{ verticalAlign: "middle", color: "#53B7E8" }}
                >
                  open_in_new
                </i>
              </a>
            </Fragment>
          }
          length={20}
        />
        <SuggestionInfo value={person.email} length={26} />
        <SuggestionInfo value={person.campus} length={27} />
      </div>
    );
  }
}

function SuggestionInfo({ value, length }) {
  return (
    <p className="tooltip-container">
      {value && value.length > length
        ? value.substring(0, length - 2) + "..."
        : value}
      {value && value.length > length && (
        <span className="tooltip" style={{ left: 100 }}>
          {value}
        </span>
      )}
    </p>
  );
}
