import React from "react";
import { Link } from "react-router";
import { formatNumber } from "../../../lib";

export default class RewardsTableRow extends React.PureComponent {
  render() {
    const {
      eduProgramScheduleId,
      reward: {
        creditValueUSD,
        id,
        isAvailable,
        maxCredits,
        name,
        typeDisplay,
      },
    } = this.props;

    return (
      <Link
        className="courses-rewards-table-row"
        to={`/edu/${eduProgramScheduleId}/rewards/${id}`}
      >
        <p>{name}</p>
        <p>{typeDisplay}</p>
        <p>
          {maxCredits
            ? `${maxCredits} ($${formatNumber(
                creditValueUSD * maxCredits
              )} value)`
            : ""}
        </p>
        <p>{creditValueUSD ? `$${formatNumber(creditValueUSD)}` : ""}</p>
        <p
          className={`relative ${isAvailable ? "active" : "inactive"}`}
          style={{ paddingLeft: "16px" }}
        >
          {isAvailable ? "Active" : "Inactive"}
        </p>
      </Link>
    );
  }
}
