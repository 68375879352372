// export interface CustomPage {
//   id?: number;
//   slug: string;
//   title: string;
//   body: string;
//   mainImagePath: string;
//   mainImageBase64?: string;
//   logoPath?: string;
//   logoBase64?: string;
//   includeApplyButton: boolean;
//   applyUrl?: string;
// }
/** @returns {CustomPage} */
export function EmptyCustomPage() {
  return {
    id: -1,
    slug: "",
    title: "",
    body: "",
    mainImagePath: "",
    logoPath: "",
    includeApplyButton: false,
  };
}
