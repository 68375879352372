import React from "react";
import ProgressStatusBar from "../RaffleComponents/ProgressStatusBar";
import EnrollmentSalesByReferrerChart from "./EnrollmentSalesByReferrerChart";
import EnrollmentSalesOverTimeChart from "./EnrollmentSalesOverTimeChart";
import Loader from "../../common/Loader";
import { formatCurrency, formatNumber } from "../../../lib";
import moment from "moment";
import TicketMetricsCards from "../RaffleDetails/Metrics/TicketMetricsCards";
import YearOverYearComparisonIntervalChart from "../../shared/YearOverYearComparisonIntervalChart";

const ticketQuantityDetails = {
  type: "ticket",
  label: "Show number of tickets sold",
};

export default class RaffleEnrollmentMetrics extends React.PureComponent {
  render() {
    const {
      enrollment: { bonusRaffleGoal, currencyCode, donorGoal, raffleGoal },
      errorMessage,
      getSalesOverTime,
      getSalesOverTimeComparison,
      loading,
      metrics,
      metrics: {
        cociOwes,
        chabadHouseOwes,
        ordersCount,
        referrerSales,
        totalInFees,
        totalRaised,
        totalRaisedByTeams,
        totalProcessingFeeDonorAmountRaised,
        totalTicketAmountRaised,
        totalTicketsSold,
      },
      raffle: {
        daysRemaining,
        endDate,
        isOpenForManualTicketEntry,
        raffleTitle,
        startDate,
      },
      salesOverTime,
      salesOverTimeErrorMessage,
      salesOverTimeLoading,
      salesOverTimeTimeFrame,
      salesOverTimeComparison,
      salesOverTimeComparisonErrorMessage,
      salesOverTimeComparisonLoading,
      salesOverTimeComparisonIncludeAllEvents,
      systemRaffleDaysToEnd,
      toOrdersTab,
    } = this.props;

    let raffleFeesPaid =
      !loading &&
      !errorMessage &&
      moment().isAfter(moment(endDate).add(2, "days"));
    const isInBonusRound = !!bonusRaffleGoal && totalTicketsSold >= raffleGoal;
    const totalRaffleGoal = raffleGoal + (isInBonusRound ? bonusRaffleGoal : 0);

    return (
      <React.Fragment>
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <div>{errorMessage}</div>
        ) : (
          <React.Fragment>
            <div>
              <div className="flex flex-justify-space">
                <p className="medium-text fw-700">Grand Draw Progress</p>
                {!!daysRemaining && (
                  <p className="medium-text fw-700">
                    {formatNumber(daysRemaining)} Day
                    {daysRemaining === 1 ? "" : "s"} Remaining
                  </p>
                )}
              </div>
              <ProgressStatusBar
                className="mt-8"
                daysRemaining={daysRemaining}
                endDate={endDate}
                startDate={startDate}
                type="raffleDays"
              />
            </div>
            {!!raffleGoal && (
              <div>
                <div className="flex flex-justify-space mt-24">
                  <p className="medium-text fw-700">
                    Ticket Goal{isInBonusRound ? " - Bonus Round" : ""}
                  </p>
                  <p className="medium-text fw-700">
                    {formatNumber(
                      totalTicketsSold >= totalRaffleGoal
                        ? 0
                        : totalRaffleGoal - totalTicketsSold
                    )}{" "}
                    Remaining
                  </p>
                </div>
                <ProgressStatusBar
                  className="mt-8"
                  raffleGoal={raffleGoal}
                  totalRaffleGoal={totalRaffleGoal}
                  isInBonusRound={isInBonusRound}
                  ticketsSold={totalTicketsSold}
                  type="raffleGoal"
                />
              </div>
            )}
            {!!donorGoal && (
              <div>
                <div className="flex flex-justify-space mt-24">
                  <p className="medium-text fw-700">Donor Goal</p>
                  <p className="medium-text fw-700">
                    {formatNumber(
                      ordersCount >= donorGoal ? 0 : donorGoal - ordersCount
                    )}{" "}
                    Remaining
                  </p>
                </div>
                <ProgressStatusBar
                  className="mt-8"
                  donorGoal={donorGoal}
                  donors={ordersCount}
                  type="donorGoal"
                />
              </div>
            )}
            <div className="money-status-cards-container">
              <div className="money-status-card relative" onClick={toOrdersTab}>
                <p className="mb-8">
                  {formatCurrency(totalRaised, currencyCode)}
                </p>
                <p>Total Raised</p>
                <div className="money-status-card-substatus">
                  <div>
                    {formatCurrency(totalTicketAmountRaised, currencyCode)}{" "}
                    Ticket Costs
                  </div>
                  <div>
                    {formatCurrency(
                      totalProcessingFeeDonorAmountRaised,
                      currencyCode
                    )}{" "}
                    Donated Processing Fees
                  </div>
                  <div>
                    {formatCurrency(totalRaisedByTeams, currencyCode)} Total
                    Raised by Teams
                  </div>
                </div>
              </div>
              <div className="money-status-card" onClick={toOrdersTab}>
                <p className="mb-8">
                  {formatCurrency(totalInFees, currencyCode)}
                </p>
                <p>Total in Fees</p>
              </div>
              <div className="money-status-card" onClick={toOrdersTab}>
                <p className="mb-8">{formatCurrency(cociOwes, currencyCode)}</p>
                <p>COCI Owe{raffleFeesPaid ? "d" : "s"} Me</p>
              </div>
              <div className="money-status-card" onClick={toOrdersTab}>
                <p className="mb-8">
                  {formatCurrency(chabadHouseOwes, currencyCode)}
                </p>
                <p>I Owe{raffleFeesPaid ? "d" : ""} COCI</p>
              </div>
            </div>
            <div className="mt-32 flex">
              <TicketMetricsCards {...metrics} />
              <EnrollmentSalesByReferrerChart
                currencyCode={currencyCode}
                data={referrerSales}
              />
            </div>

            <EnrollmentSalesOverTimeChart
              allowTimeFrameSelection={isOpenForManualTicketEntry}
              currencyCode={currencyCode}
              data={salesOverTime}
              errorMessage={salesOverTimeErrorMessage}
              getSalesOverTime={getSalesOverTime}
              loading={salesOverTimeLoading}
              timeFrame={salesOverTimeTimeFrame}
            />

            <YearOverYearComparisonIntervalChart
              useCurrency={true}
              chartTitle="Sales Over Time Year to Year Comparison"
              endDateDescription="Grand draw end"
              eventType="raffle"
              eventTitle={raffleTitle}
              currencyCode={currencyCode}
              data={salesOverTimeComparison}
              daysRemaining={daysRemaining}
              errorMessage={salesOverTimeComparisonErrorMessage}
              getData={getSalesOverTimeComparison}
              loading={salesOverTimeComparisonLoading}
              includeAllEvents={salesOverTimeComparisonIncludeAllEvents}
              intervals={systemRaffleDaysToEnd}
              showQuantityOption={true}
              quantityDetails={ticketQuantityDetails}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
