import React from "react";
import { Link } from "react-router";
import { formatFullName } from "../../../lib";

export default class ShliachDetails extends React.PureComponent {
  render() {
    const {
      shliachDetails: {
        categoryDisplay,
        cell,
        email,
        familyImageURL,
        numberOfChildren,
        positionDisplay,
        primaryChabadHouse,
        shliach,
        spouse,
      },
    } = this.props;

    const {
      firstName,
      id: shliachId,
      lastName,
      profileImageURL,
      titleDisplay,
    } = shliach || {};

    const {
      firstName: spouseFirstName,
      id: spouseShliachId,
      lastName: spouseLastName,
      profileImageURL: spouseProfileImageURL,
      titleDisplay: spouseTitleDisplay,
    } = spouse || {};

    const { facebookURL, instagramURL, snapchatURL, twitterURL } =
      primaryChabadHouse || {};

    return (
      <div className="flex flex-align-center flex-justify-space">
        <div className="flex flex-align-center">
          {profileImageURL && (
            <img
              alt="shliach-profile"
              className="mr-16 profile-image"
              src={profileImageURL || "/img/profile-placeholder.svg"}
            />
          )}
          <div>
            <p className="large-text fw-700 mb-4">
              {formatFullName(firstName, lastName, undefined, titleDisplay)}
            </p>
            <div className="flex">
              <div className="mr-16">
                {(positionDisplay || categoryDisplay) && (
                  <div className="flex flex-align-center mb-8">
                    <i className="material-icons medium-text mr-4">person</i>
                    <p style={{ fontSize: "12px" }}>
                      {positionDisplay}
                      {positionDisplay && categoryDisplay ? (
                        <span className="mr-8 ml-8">|</span>
                      ) : (
                        ""
                      )}
                      {categoryDisplay}
                    </p>
                  </div>
                )}
                <div className="flex flex-align-center">
                  {email && (
                    <p className="flex flex-align-center mr-8">
                      <i className="material-icons large-text accent-text-secondary mr-4">
                        email
                      </i>
                      <a
                        className="small-text link-text-secondary"
                        href={`mailto:${email}`}
                      >
                        {email}
                      </a>
                    </p>
                  )}
                  {cell && (
                    <p className="flex flex-align-center">
                      <i className="material-icons large-text accent-text-secondary mr-4">
                        phone
                      </i>
                      <a
                        className="small-text link-text-secondary"
                        href={`tel:${cell}`}
                      >
                        {cell}
                      </a>
                    </p>
                  )}
                </div>
              </div>
              {spouseShliachId && (
                <div className="mr-16">
                  <p className="uppercase-text accent-text small-text mb-4">
                    Spouse
                  </p>
                  <div className="flex flex-align-center">
                    <img
                      alt="spouse-profile"
                      className="mr-16 profile-image-sm"
                      src={
                        spouseProfileImageURL || "/img/profile-placeholder.svg"
                      }
                    />
                    <Link
                      className="link-text"
                      to={`/shluchim/${spouseShliachId}/details`}
                    >
                      {formatFullName(
                        spouseFirstName,
                        spouseLastName,
                        undefined,
                        spouseTitleDisplay,
                      )}
                    </Link>
                  </div>
                </div>
              )}
              {!!numberOfChildren && (
                <div className="mr-16">
                  <p className="uppercase-text accent-text small-text mb-4">
                    Children
                  </p>
                  <div className="flex flex-align-center">
                    <img
                      alt="family-profile"
                      className="mr-16 profile-image-sm"
                      src={familyImageURL || "/img/profile-placeholder.svg"}
                      style={{
                        height: "32px",
                        width: "32px",
                        objectFit: "cover",
                        borderRadius: "10%",
                      }}
                    />
                    <p className="accent-text-secondary">{numberOfChildren}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-right">
          <Link className="btn btn-primary mb-16" to={`/shluchim/${shliachId}`}>
            View / Edit Profile
          </Link>
          <div className="flex flex-align-center flex-justify-end social-links mr-8">
            {facebookURL && (
              <a
                className="link-text"
                href={facebookURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/img/facebook.png" alt="facebook" />
              </a>
            )}
            {instagramURL && (
              <a
                className="link-text ml-8"
                href={instagramURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/img/instagram.png" alt="instagram" />
              </a>
            )}
            {twitterURL && (
              <a
                className="link-text ml-8"
                href={twitterURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/img/twitter.png" alt="twitter" />
              </a>
            )}
            {snapchatURL && (
              <a
                className="link-text ml-8"
                href={snapchatURL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/img/snapchat.png" alt="snapchat" />
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}
