import React from "react";
import Phone from "react-phone-number-input";
import { parse, format } from "libphonenumber-js";

require("./rrui.css");
require("./style.css");

// export interface PhoneNumberProps {
//   value: string;
//   placeholder: string;
//   required?: boolean;
//   allowExtension?: boolean;
//   defaultCountry?: string;
//   disabled: boolean;
//   handleChange: (newValue: string) => void;
// }

// export interface PhoneNumberState {
//   phoneValue: string;
//   extensionValue: string;
// }

export class PhoneNumber extends React.Component {
  /** @param {PhoneNumberProps} */
  constructor(props) {
    super(props);

    this.state = {
      phoneValue: this.getNumber(props.value),
      extensionValue: this.getExtension(props.value),
    };

    this.handleNumberChanged = this.handleNumberChanged.bind(this);
    this.handleExtChangedEvt = this.handleExtChangedEvt.bind(this);
  }

  /** @param {string} */
  handleNumberChanged(newNumber) {
    const parsedNumber = parse(newNumber, { country: { default: "US" } });
    const formatedNumber =
      parsedNumber &&
      format(
        parsedNumber,
        parsedNumber.country === "US" ? "National" : "International",
      );
    const extension =
      this.props.allowExtension && this.state.extensionValue
        ? "x" + this.state.extensionValue
        : "";
    this.props.handleChange((formatedNumber || newNumber) + extension);
  }

  /** @param {string} */
  handleExtensionChanged(newExtension) {
    this.props.handleChange(
      this.state.phoneValue + (newExtension ? "x" + newExtension : ""),
    );
  }
  /** @param {React.SyntheticEvent<HTMLInputElement>} e */
  handleExtChangedEvt(e) {
    this.handleExtensionChanged(e.currentTarget.value);
  }

  /** @param {string} */
  getNumber(fullNumber) {
    // return (fullNumber.includes("x") && fullNumber.split("x")[0]) || fullNumber || "";
    if (!fullNumber.includes("x")) {
      return fullNumber || "";
    }
    return fullNumber.substring(0, fullNumber.indexOf("x")) || "";
  }

  /** @param {string} */
  getExtension(fullNumber) {
    if (!this.props.allowExtension || !fullNumber.includes("x")) {
      return "";
    }
    return fullNumber.substring(fullNumber.indexOf("x") + 1) || "";
  }

  /** @param {PhoneNumberProps} */
  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({
        phoneValue: this.getNumber(nextProps.value),
        extensionValue: this.getExtension(nextProps.value),
      });
    }
  }

  render() {
    const {
      placeholder,
      defaultCountry,
      allowExtension = true,
      required = false,
      disabled = true,
    } = this.props;
    return (
      <div className="react-phone-number-input__row">
        <Phone
          placeholder={placeholder}
          value={this.state.phoneValue}
          country={defaultCountry || ""}
          convertToNational={true}
          required={required}
          disabled={disabled}
          onChange={this.handleNumberChanged}
        />
        {allowExtension && <span className="phone-number-separator"> x </span>}
        {allowExtension && (
          <input
            type="number"
            className="rrui__phone_extension rrui__input"
            name="ext"
            maxLength={4}
            min="0"
            placeholder="ext"
            value={this.state.extensionValue}
            autoComplete={"tel-extension"}
            disabled={disabled}
            onChange={this.handleExtChangedEvt}
          />
        )}
      </div>
    );
  }
}
