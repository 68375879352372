import React from "react";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import TermsAndConditionsTable from "./TermsAndConditionsTable";
import { TripConfigApplicableFor } from "../../TripEventConsts";

const termTypes = {
  long: "LongText",
  short: "ShortText",
};

export default class EventTermsAndConditions extends React.PureComponent {
  state = {
    currentTermsIndex: null,
    newTermsAppliesTo: null,
    showDeleteTermsConfirmationModal: false,
    showTermsAndConditionsModal: false,
  };

  onAddTerms = (appliesTo) => {
    this.setState({
      currentTermsIndex: null,
      newTermsAppliesTo: appliesTo,
      showTermsAndConditionsModal: true,
    });
  };

  onDeleteTerms = (termsIndex) => {
    this.setState({
      currentTermsIndex: termsIndex,
      showDeleteTermsConfirmationModal: true,
    });
  };

  onUpdateTerms = (termsIndex) => {
    this.setState({
      currentTermsIndex: termsIndex,
      showTermsAndConditionsModal: true,
    });
  };

  addTerms = (newTerms) => {
    const {
      event: { termsAndConditions },
      onChange,
    } = this.props;

    const newTermsAndConditions = [
      ...termsAndConditions,
      {
        ...newTerms,
        appliesTo: this.state.newTermsAppliesTo,
      },
    ];
    onChange("termsAndConditions", newTermsAndConditions);

    this.setState({
      currentTermsIndex: null,
      newTermsAppliesTo: null,
      showTermsAndConditionsModal: false,
    });
  };

  deleteTerms = () => {
    const {
      event: { termsAndConditions },
      onChange,
    } = this.props;
    const { currentTermsIndex } = this.state;

    const newTermsAndConditions = [...termsAndConditions];
    newTermsAndConditions.splice(currentTermsIndex, 1);
    onChange("termsAndConditions", newTermsAndConditions);

    this.setState({
      currentTermsIndex: null,
      showDeleteTermsConfirmationModal: false,
    });
  };

  updateTerms = (updatedTerms) => {
    const {
      event: { termsAndConditions },
      onChange,
    } = this.props;
    const { currentTermsIndex } = this.state;

    const newTermsAndConditions = [...termsAndConditions];
    newTermsAndConditions[currentTermsIndex] = updatedTerms;
    onChange("termsAndConditions", newTermsAndConditions);

    this.setState({
      currentTermsIndex: null,
      showTermsAndConditionsModal: false,
    });
  };

  render() {
    const {
      event: { isTravelTrip, termsAndConditions },
    } = this.props;

    const {
      currentTermsIndex,
      showDeleteTermsConfirmationModal,
      showTermsAndConditionsModal,
    } = this.state;

    const termsAndConditionsWithIndexes = termsAndConditions
      ? termsAndConditions.map((terms, index) => ({
          termsIndex: index,
          terms,
        }))
      : [];

    const termsAndConditionsTypes = isTravelTrip
      ? [TripConfigApplicableFor.Students]
      : [
          TripConfigApplicableFor.Shluchim,
          TripConfigApplicableFor.Students,
          TripConfigApplicableFor.Chaperones,
        ];

    return (
      <React.Fragment>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">
            Terms and Conditions
          </p>
        </div>
        {termsAndConditionsTypes.map((type) => (
          <TermsAndConditionsTable
            key={type}
            onAddTerms={() => this.onAddTerms(type)}
            onDeleteTerms={this.onDeleteTerms}
            onUpdateTerms={this.onUpdateTerms}
            termsAndConditions={termsAndConditionsWithIndexes.filter(
              ({ terms }) => terms.appliesTo === type
            )}
            termTypes={termTypes}
            title={type}
          />
        ))}
        <TermsAndConditionsModal
          close={() => this.setState({ showTermsAndConditionsModal: false })}
          save={currentTermsIndex === null ? this.addTerms : this.updateTerms}
          show={showTermsAndConditionsModal}
          terms={termsAndConditions[currentTermsIndex]}
          termTypes={termTypes}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({ showDeleteTermsConfirmationModal: false })
          }
          confirm={this.deleteTerms}
          message="Are you sure you want to delete these terms and conditions?"
          show={showDeleteTermsConfirmationModal}
        />
      </React.Fragment>
    );
  }
}
