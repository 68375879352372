import React from "react";
import Modal from "react-modal";

// import { Country, State } from "../../../models/Common";
import {
  // OrganizationFilter,
  // OrganizationCategory,
  EmptyOrganizationFilter,
  CategoryTypes,
} from "../../../models/Organization";
import { FilterModalStyles, getSelectValues } from "../../common/Utils";
import OrganizationFilterUI from "./OrganizationFilter";
import BasicDataApi from "../../../services/resources/BasicDataApi";
import OrganizationApi from "../../../services/resources/OrganizationsApi";
import axios from "axios";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";

// interface OrganizationFilterButtonProps {
//     currentFilters: OrganizationFilter;
//     handleFiltersChanged: (filters: OrganizationFilter) => void;
// }

// interface OrganizationFilterButtonState {
//     filterState: OrganizationFilter;
//     organizationCategories: Array<OrganizationCategory>;
//     countries: Array<Country>;
//     states: Array<State>;
//     loading: boolean;
//     showingFilters: boolean;
//     displayMessage: string;
//     mobileMode: boolean;
//     mql: MediaQueryList;
// }

class OrganizationFilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationCategories: [],
      filterState: props.currentFilters || EmptyOrganizationFilter(),
      countries: [],
      states: [],
      loading: true,
      showingFilters: false,
      displayMessage: "",
      mobileMode: false,
      mql: window.matchMedia(`(min-width: 800px)`),
    };

    this.handleStateFiltersChanged = this.handleStateFiltersChanged.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  /** @param {OrganizationFilter} newFilterState */
  handleStateFiltersChanged(newFilterState) {
    this.setState({ filterState: newFilterState });
  }

  /** @param {boolean} show */
  showHideFilters(show) {
    this.setState({ showingFilters: show });
  }

  handleCancel() {
    this.showHideFilters(false);
    this.handleStateFiltersChanged(this.props.currentFilters);
  }

  getButtonText() {
    const {
      mobileMode,
      filterState,
      organizationCategories,
      countries,
      states,
    } = this.state;

    const filterText =
      (filterState.name ? " Name: " + filterState.name : "") +
      (!filterState.categoryId && filterState.organizationCategoryType
        ? " Type: " + CategoryTypes[filterState.organizationCategoryType]
        : "") +
      (filterState.categoryId
        ? " Category: " +
          organizationCategories.find((c) => c.id === filterState.categoryId)
            .name
        : "") +
      (filterState.countries
        ? " Countries: " +
          getSelectValues(filterState.countries)
            .map((v) => countries.find((c) => c.id === v).name)
            .join(", ")
        : "") +
      (filterState.states
        ? " States:" +
          getSelectValues(filterState.states)
            .map((v) => states.find((c) => c.id === v).name)
            .join(", ")
        : "");
    return (
      "Filter Results" + (mobileMode || !filterText ? "" : ":" + filterText)
    );
  }

  mediaQueryChanged() {
    this.setState({
      mobileMode: !this.state.mql.matches,
    });
  }

  /** @param {OrganizationFilterButtonProps} newProps */
  componentWillReceiveProps(newProps) {
    this.handleStateFiltersChanged(newProps.currentFilters);
  }

  componentDidMount() {
    const mql = this.state.mql;
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql, mobileMode: !mql.matches });

    const dbAccesses = [
      BasicDataApi.listCountriesAndStates(),
      OrganizationApi.listCategories(),
    ];

    const sortByName = (a, b) => (a.name < b.name ? -1 : 1);

    this.setState({ loading: true }, () => {
      axios
        .all(dbAccesses)
        .then((response) => {
          this.setState({
            countries: response[0].data.results.countries.sort(sortByName),
            states: response[0].data.results.states.sort(sortByName),
            organizationCategories: response[1].data.results.sort(sortByName),
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }

  render() {
    const {
      mobileMode,
      showingFilters,
      filterState,
      organizationCategories,
      countries,
      states,
    } = this.state;

    return (
      <div>
        <div>
          <button
            className="btn btn-primary btn-md"
            onClick={() => this.showHideFilters(true)}
          >
            {this.getButtonText()}
          </button>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Modal
              isOpen={showingFilters}
              onRequestClose={() => this.showHideFilters(false)}
              contentLabel="Result Filter Menu"
              style={
                mobileMode
                  ? FilterModalStyles.modalMobile
                  : FilterModalStyles.modal
              }
            >
              <OrganizationFilterUI
                filterState={filterState}
                organizationCategories={organizationCategories}
                countryList={countries}
                stateList={states}
                handleFiltersChanged={this.handleStateFiltersChanged}
              />
              <div className="text-right">
                <button
                  onClick={() => {
                    this.showHideFilters(false);
                    this.props.handleFiltersChanged(filterState);
                  }}
                  className="btn btn-primary"
                >
                  Filter
                </button>
                &nbsp;&nbsp;
                <button onClick={this.handleCancel} className="btn btn-primary">
                  Cancel
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationFilterButton;
