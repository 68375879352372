import React from "react";
import Modal from "react-modal";
import Loader from "../../common/Loader";

export default class TerminateEnrollmentModal extends React.Component {
  state = {
    terminationReason: "",
  };

  render() {
    const { cancel, confirm, errorMessage, loading, show } = this.props;
    const { terminationReason } = this.state;

    return (
      <Modal isOpen={show} className="center-modal-container">
        <div className="modal-card card publish-modal small-modal">
          <p className="large-text fw-700">Terminate</p>
          <div className="accent-text small-text">
            Please make sure to cancel the subscription for this user on stripe
            so that they don't get billed.
          </div>
          <div>
            <p className="small-text mt-16">Enter termination reason</p>
            <textarea
              className="custom-input full-width"
              onChange={(event) =>
                this.setState({ terminationReason: event.target.value })
              }
              placeholder="Type here..."
              style={{ minHeight: "72px", resize: "none", padding: "8px" }}
              value={terminationReason}
            />
          </div>
          {loading ? (
            <div className="modal-btns-loader">
              <Loader />
            </div>
          ) : (
            <div className="text-right">
              <div className="modal-btns relative">
                <p
                  className="link-text uppercase-text"
                  onClick={() =>
                    this.setState({ terminationReason: "" }, cancel)
                  }
                >
                  Cancel
                </p>
                <p
                  className="link-text uppercase-text ml-40"
                  onClick={() => confirm(terminationReason)}
                >
                  Save
                </p>
              </div>

              {!!errorMessage && (
                <span className="error-text">{errorMessage}</span>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
