import React from "react";

export default class RewardsTableHeader extends React.PureComponent {
  render() {
    const columns = [
      "Name",
      "Reward Type",
      "Max Credits",
      "Credit Value",
      "Status",
    ];

    return (
      <div className="courses-rewards-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
