/** @param { React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement> | React.FormEvent<HTMLTextAreaElement>} evt */
export function UpdateDOMProperty(evt, currentState) {
  return UpdateProperty(
    currentState,
    evt.currentTarget.type === "radio"
      ? evt.currentTarget.name.split("_")[0]
      : evt.currentTarget.name,
    evt.currentTarget.type === "radio"
      ? evt.currentTarget.value === "on"
      : evt.currentTarget.type === "checkbox"
      ? evt.currentTarget.checked
      : evt.currentTarget.value,
  );
}
/** @param {string} property */
export function UpdateProperty(objectToUpdate, property, newValue) {
  return {
    ...objectToUpdate,
    [property]: newValue,
  };
}
