import React from "react";

export default class RewardRequestsTableHeader extends React.PureComponent {
  render() {
    const columns = [
      "Student",
      "Campus / Shliach",
      "Reward",
      "Credits / Total Value",
      "Request Received",
      "Status",
      "Approve / Deny",
    ];

    return (
      <div className="courses-requests-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
