import React from "react";

export function ResultsSummaryItem({
  icon = "icon-results",
  label = "Results",
  paths = 0,
  value = 0,
}) {
  let pathSpans = [];
  let pathCount = paths + 1;
  for (let i = 1; i < pathCount; i++) {
    pathSpans.push(<span key={i} className={`path${i}`}></span>);
  }
  return (
    <div className="results-summary-item">
      <i className={`icon ${icon}`}>{pathSpans}</i>
      <span className="results-summary-desc">
        <label className="control-label">{label}</label>
        {value}
      </span>
    </div>
  );
}
