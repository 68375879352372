import React from "react";

import { EmptyContact } from "../../../models/Contact";
import RelatedContactContactMethodsList from "../../common/RelatedContact/RelatedContactContactMethodsList";
import Radio from "../../shared/Radio";
import Select from "react-select";

const ddStyles = {
  paddingBottom: "3px",
};

// interface OfficeStaffManagerProps {
//   officeStaffContactData: Contact;
//   handleOfficeStaffContactChanged: (newState: Contact) => void;
// }

const OfficeStaffManager = ({
  officeStaffContactData = EmptyContact(),
  handleOfficeStaffContactChanged,
  systemLists: { countries = [], titles = [] } = {},
}) => {
  /**
   *
   * @param {Contact} item
   * @param {string} property
   * @param {any} newValue
   * @returns {Contact}
   */
  const updateProperty = (item, property, newValue) => ({
    ...item,
    [property]: newValue,
  });
  /** @returns {Contact} */
  const getNewState = (evt) =>
    updateProperty(
      officeStaffContactData,
      evt.currentTarget.name,
      evt.currentTarget.value,
    );
  /** @param {ContactMethods} newContactMethods */
  const getNewStateCM = (newContactMethods) =>
    updateProperty(officeStaffContactData, "contactMethods", newContactMethods);

  const updateContactDomElement = (evt) =>
    handleOfficeStaffContactChanged(getNewState(evt));
  const updateContactByNameValue = (name, newValue) =>
    handleOfficeStaffContactChanged(
      updateProperty(officeStaffContactData, name, newValue),
    );
  const handleContactMethodsChange = (newContactMethods) =>
    handleOfficeStaffContactChanged(getNewStateCM(newContactMethods));

  const titleOptions = titles
    ? titles.map((t) => ({
        label: t.displayValue,
        value: t.enumValue,
      }))
    : [];

  return (
    <div className="row">
      <div
        className="col-md-5"
        style={{ minWidth: "350px", marginRight: "20px" }}
      >
        <dl className="dl-horizontal">
          <dt>First Name</dt>
          <dd style={ddStyles}>
            <input
              className="custom-input full-width"
              type="text"
              name="firstName"
              value={officeStaffContactData.firstName || ""}
              onChange={updateContactDomElement}
              required={true}
            />
          </dd>
          <dt>Last Name</dt>
          <dd style={ddStyles}>
            <input
              className="custom-input full-width"
              type="text"
              name="lastName"
              value={officeStaffContactData.lastName || ""}
              onChange={updateContactDomElement}
              required={true}
            />
          </dd>
          <dt>Title</dt>
          <dd style={ddStyles}>
            <Select
              className="custom-select user-title-select"
              isClearable={true}
              name="title"
              onChange={(val) =>
                updateContactByNameValue("title", val ? val.value : "")
              }
              options={titleOptions}
              placeholder="Select Title..."
              value={
                titleOptions.find(
                  (o) => o.value === officeStaffContactData.title,
                ) || ""
              }
            />
          </dd>
          <div className="mt-4">
            <dt>Is COCI Office Staff?</dt>
            <dd style={ddStyles}>
              <Radio
                className="office-staff-user-radio"
                name="isOfficeStaff"
                onChange={updateContactByNameValue}
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
                value={officeStaffContactData.isOfficeStaff || false}
              />
            </dd>
          </div>
          {officeStaffContactData.isOfficeStaff && (
            <React.Fragment>
              <dt>Office Staff Position</dt>
              <dd style={ddStyles}>
                <input
                  className="custom-input full-width"
                  type="text"
                  name="jobDescription"
                  value={officeStaffContactData.jobDescription || ""}
                  onChange={updateContactDomElement}
                />
              </dd>
            </React.Fragment>
          )}
        </dl>
      </div>
      <div className="col-md-5 dl-horizontal">
        <RelatedContactContactMethodsList
          contactMethods={officeStaffContactData.contactMethods || []}
          countries={countries}
          ddStyles={ddStyles}
          handleContactMethodsChange={handleContactMethodsChange}
        />
      </div>
    </div>
  );
};

export default OfficeStaffManager;
