import AuthRequest from "./AuthRequest";

const baseUrl = "/api/lifeinsurance/reporting";

const LifeInsuranceReportingApi = {
  get: () => {
    return AuthRequest.get(baseUrl);
  },
  getDetailed: () => {
    return AuthRequest.get(baseUrl + "/detailed");
  },
};

export default LifeInsuranceReportingApi;
