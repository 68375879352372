import React from "react";
import { Link } from "react-router";
import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import viewHistory from "../../services/ViewHistory";
import { ReportingTile } from "../common/ReportingTile";
import * as ReportingHelpers from "../../assets/scripts/reportingHelpers";
import TefillinReportingApi from "../../services/resources/TefillinReportingApi";
import DatePicker from "../common/DateRangePicker";
import AgeBarChart from "../common/ReportingAgeBarChart";

export class TefillinReporting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment.utc().subtract(2, "years"),
      endDate: moment.utc(),
      loading: true,
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.TefillinSummary)) {
      return;
    }

    viewHistory.add("Program", {
      title: "Tefillin",
      path: "/tefillin",
    });
    this.requestReportData();
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {import('moment').Moment} */
    const startDate = this.state.startDate.clone().startOf("day");
    /** @type {import('moment').Moment} */
    const endDate = this.state.endDate.clone().endOf("day");

    TefillinReportingApi.get(startDate, endDate)
      .then((response) => {
        this.setState({ reportData: response.data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState({ [dateFieldName]: selectedDate }, this.requestReportData);
    };
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.TefillinSummary)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasTefillinDetailedPermission = AuthService.UserHasClaim(
      PermissionClaims.TefillinDetailed,
    );

    const { reportData, startDate, endDate } = this.state;

    const ageArray = ReportingHelpers.GetAgeArray(
      (reportData && reportData.countByAge) || [],
    );
    const averageAgeAsString = ReportingHelpers.GetAverageAgeAsString(ageArray);

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/tefillin">Tefillin</Link>
              </li>
              <li>Reports</li>
            </ol>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label>Date Range</label>
              <div>
                <DatePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  handleDateInputChange={this.handleDateInputChange}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="col-md-3">
              {hasTefillinDetailedPermission ? (
                <Link
                  to={`/tefillin/reporting/bystudents?startDate=${startDate}&endDate=${endDate}`}
                >
                  <ReportingTile
                    label="Subsidies"
                    value={ReportingHelpers.GetReportValue(
                      reportData,
                      "totalCount",
                    )}
                  />
                </Link>
              ) : (
                <ReportingTile
                  label="Subsidies"
                  value={ReportingHelpers.GetReportValue(
                    reportData,
                    "totalCount",
                  )}
                />
              )}
            </div>
            <div className="col-md-3">
              {hasTefillinDetailedPermission ? (
                <Link
                  to={`/tefillin/reporting/byschools?startDate=${startDate}&endDate=${endDate}`}
                >
                  <ReportingTile
                    label="Schools"
                    value={ReportingHelpers.GetReportValue(
                      reportData,
                      "totalSchools",
                    )}
                  />
                </Link>
              ) : (
                <ReportingTile
                  label="Schools"
                  value={ReportingHelpers.GetReportValue(
                    reportData,
                    "totalSchools",
                  )}
                />
              )}
            </div>
            <div className="col-md-3">
              <ReportingTile label="Average Age" value={averageAgeAsString} />
            </div>
            <div className="col-md-6">
              <AgeBarChart ageArray={ageArray} displayName="Subsidies" />
            </div>
          </div>

          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }
}
