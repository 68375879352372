import React from "react";
import { Select } from "../../../../lib";

export default class ScheduleDetails extends React.PureComponent {
  getCoordinatorContactInfo = (officeStaffID, officeStaff) => {
    if (officeStaffID) {
      const { primaryEmail, primaryPhone } =
        officeStaff.filter((i) => i.id === officeStaffID)[0] || {};
      return [primaryEmail, primaryPhone].filter((p) => !!p).join(" | ");
    }
    return "";
  };

  render() {
    const { actions, allowUpdateSchedule, officeStaff, schedule } = this.props;

    const coordinatorContactInfo = this.getCoordinatorContactInfo(
      schedule.officeStaffID,
      officeStaff,
    );

    return (
      <div>
        <div className="medium-text fw-700">
          <p>Coordinator</p>
        </div>
        <div className="program-form-inputs mb-8">
          <div className="flex flex-justify-space flex-align-center relative">
            <label className="form-label form-required" htmlFor="coordinator">
              Program Coordinator
            </label>
            <Select
              id="coordinator"
              disabled={!allowUpdateSchedule}
              options={officeStaff.map((i) => ({
                value: i.id,
                label: i.fullName,
              }))}
              value={schedule.officeStaffID || -1}
              clearable={true}
              data-change="schedule.officeStaffID"
              onChange={actions.changeInput}
              required={false}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <label className="form-label" htmlFor="coordinator_info">
              Email (optional - by default the Coordinator's email will be used)
            </label>
            <input
              id="email"
              type="email"
              autoComplete="off"
              disabled={!allowUpdateSchedule}
              value={schedule.email || ""}
              data-change="schedule.email"
              onChange={actions.changeInput}
            />
          </div>
        </div>
        <div className="program-form-inputs mb-24">
          <div className="flex flex-justify-space flex-align-center relative">
            <label className="form-label" htmlFor="coordinator_info">
              Contact Info
            </label>
            <input
              type="text"
              id="coordinator_info"
              readOnly
              autoComplete="off"
              disabled={!allowUpdateSchedule}
              style={{ border: "none", boxShadow: "none", paddingLeft: 4 }}
              value={coordinatorContactInfo}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
