import React from "react";
import AuthClient from "../../../services/resources/AuthClient";
import { Link } from "react-router";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import { formatValuesForForm } from "../../../lib/utils";

import Loader from "./Loader";
import ShliachForm from "./Shliach/ShliachFormContainer";
import ChabadHouseOptions from "./ChabadHouse/ChabadHouseOptions";
import ChabadHouseForm from "./ChabadHouse/ChabadHouseFormContainer";
import SchoolForm from "./School/SchoolForm";

export class ShliachPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: false,
      profile: {},
      sys: {},
      systemSettingsLoading: true,
      submitted: "",
      submitError: {},
      chabadHousesDropdownOpen: false,
      schoolsDropdownOpen: false,
      saving: "",
    };
  }

  componentDidMount() {
    const { shliachID } = this.props.routeParams;
    if (shliachID) this.getShliach(shliachID);
    this.getSystemSettings();
    this.setState({ width: window.innerWidth });
  }

  componentDidUpdate(prevProps) {
    const { shliachID } = this.props.routeParams;
    let reload =
      prevProps.routeParams.shliachID !== this.props.routeParams.shliachID;
    if (reload) {
      this.updateMenuLists(shliachID);
    }
  }

  shliachDateFields = ["dob", "dateStarted", "dateJoinedCOCI"];

  getShliach = async (id) => {
    let errors = {};
    let data = {
      loading: true,
      shliach: {},
      family: {},
      schools: [],
      outreach: {},
    };
    this.setState({ profile: data });
    await Promise.all([
      AuthClient.get(`/Shluchim/${id}`)
        .then((res) => {
          data.shliach = res.data;
        })
        .catch((e) => (errors.shliach = true)),
      AuthClient.get(`/Shluchim/${id}/Campuses/basic?includeInactive=true`)
        .then((res) => (data.schools = res.data && res.data.payload))
        .catch((e) => (errors.schools = true)),
      AuthClient.get(`Shluchim/${id}/chabadHouses/basic?includeInactive=true`)
        .then((res) => (data.chabadHouses = res.data && res.data.payload))
        .catch((e) => (errors.chabadHouses = true)),
      AuthClient.get(`Shluchim/${id}/outreach`)
        .then((res) => (data.outreach = res.data && res.data.payload))
        .catch((e) => (errors.outreach = true)),
    ]);
    const { person: { familyID } = {} } = data.shliach;
    await AuthClient.get(`/Families/${familyID}`)
      .then((res) => (data.family = res.data))
      .catch((e) => (errors.family = true));
    data.loading = false;
    this.setState({
      profile: data,
      initialOutreachValues: _cloneDeep(data.outreach),
    });
  };

  getSystemSettings = async () => {
    let sys = {};
    // this.setState({systemSettingsLoading: true});
    await AuthClient.get(`/System`)
      .then((res) => (sys = res.data.payload))
      .catch((e) => console.log(e));
    this.setState({ sys: sys, systemSettingsLoading: false });
  };

  updateMenuLists = async (id, updateCampusesOnly = false) => {
    let errors = {};
    let data = this.state.profile;
    await Promise.all([
      !updateCampusesOnly &&
        AuthClient.get(
          `/Shluchim/${id}/ChabadHouses/basic?includeInactive=true`
        )
          .then((res) => {
            data.chabadHouses = res.data && res.data.payload;
          })
          .catch((e) => (errors.chabadHouses = true)),
      AuthClient.get(`/Shluchim/${id}/Campuses/basic?includeInactive=true`)
        .then((res) => (data.schools = res.data && res.data.payload))
        .catch((e) => (errors.schools = true)),
    ]);
    this.setState({
      profile: {
        ...this.state.profile,
        chabadHouses: data.chabadHouses,
        schools: data.schools,
      },
    });
  };

  checkForErrors = (errors) => {
    errors = errors && Object.keys(errors);
    if (errors && errors.length > 0) {
      this.setState({ errorMessage: true });
    }
    return;
  };

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true, errorMessage: false });
    const studentOutreachChanges = !_isEqual(
      this.state.initialOutreachValues,
      values.outreach
    );
    const { family, outreach, ...post1 } = values;
    let errors = {};
    let data = {
      shliach: { ...post1 },
      family: { ...family },
      outreach: { ...outreach },
    };
    post1.person.addresses = [];
    if (post1.spouse) {
      post1.spouse.addresses = [];
    }
    const { data: data1, error: err1 } = await AuthClient.post(
      `Shluchim`,
      post1
    ).catch((error) => {
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 3000);
      return { error };
    });
    if (err1) {
      this.setState({
        submitted: false,
        saving: false,
        submitError: err1.response,
      });
      return;
    } else {
      data.shliach = data1.payload;
    }
    if (!family.familyMemberIDs) family.familyMemberIDs = null;
    if (!family.husband) family.husband = null;
    family.ID = data1.payload.person.familyID;
    if (studentOutreachChanges) {
      if (outreach) {
        //set shliachID for relevant outreach objs if created a new shliach
        if (outreach.shliachOutreach && !outreach.shliachOutreach.shliachID) {
          outreach.shliachOutreach.shliachID = data.shliach.id;
        }
        if (
          outreach.spouseOutreach &&
          !outreach.spouseOutreach.shliachID &&
          data.shliach.spouse
        ) {
          outreach.spouseOutreach.shliachID = data.shliach.spouse.shliachID;
        }
      }
      await Promise.all([
        AuthClient.post(`Families`, family)
          .then((res) => (data.family = res.data))
          .catch((error) => {
            errors.family = true;
            this.setState({
              submitted: false,
              saving: false,
              submitError: error.response,
            });
            return { error };
          }),
        AuthClient.post(`Shluchim/${data.shliach.id}/outreach`, outreach)
          .then((res) => (data.outreach = res.data.payload))
          .catch((error) => {
            errors.outreach = true;
            this.setState({
              submitted: false,
              saving: false,
              submitError: error.response,
            });
            return { error };
          }),
      ]);
    } else {
      AuthClient.post(`Families`, family)
        .then((res) => (data.family = res.data))
        .catch((error) => {
          errors.family = true;
          this.setState({
            submitted: false,
            saving: false,
            submitError: error.response,
          });
          return { error };
        });
    }
    if (Object.keys(errors).length === 0) {
      this.setState({
        profile: {
          ...this.state.profile,
          shliach: data.shliach,
          family: data.family,
          outreach: data.outreach,
        },
        loading: false,
        submitted: true,
      });
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 3000);
      setTimeout(() => {
        let {
          profile: { shliach, family, outreach },
        } = this.state;
        let updatedValues = formatValuesForForm(
          {
            ...shliach,
            family,
            outreach,
          },
          this.shliachDateFields
        );
        resetForm(updatedValues);
        this.setState({ saving: false });
      }, 1500);
    } else {
      setTimeout(() => {
        this.setState({ saving: false });
      });
    }
    if (!this.props.routeParams.shliachID && this.state.profile.shliach) {
      window.history.pushState(
        null,
        "",
        `/shluchim/${this.state.profile.shliach.id}`
      );
    }
  };

  render() {
    const {
      profile: {
        loading,
        shliach = {},
        family = {},
        outreach = {},
        schools = [],
        chabadHouses = [],
      },
      sys,
      systemSettingsLoading,
      chabadHousesDropdownOpen,
      schoolsDropdownOpen,
      width,
    } = this.state;
    const { shliachID } = shliach.person || this.props.routeParams;
    return loading || systemSettingsLoading ? (
      <div style={{ marginTop: "120px" }}>
        <div className="full-page-loader">
          <Loader />
        </div>
      </div>
    ) : (
      <React.Fragment>
        <div className="profile-page-subheader card inline-flex">
          <div className="container flex flex-justify-space flex-align-center">
            <ul className="profile-page-subheader-nav flex">
              <li>
                <Link
                  to={
                    shliachID
                      ? `/shluchim/${shliachID}?tab=general`
                      : `/shluchim/new?tab=general`
                  }
                  activeClassName="active-subheader-nav-link"
                >
                  Shliach
                </Link>
                {(!shliach.isComplete || !outreach.isComplete) && (
                  <div className="tooltip-container profile-tooltip ml-8">
                    <i
                      className="material-icons accent-text-dark"
                      style={{ fontSize: "16px" }}
                    >
                      error
                    </i>
                    <span className="tooltip">Shliach info incomplete</span>
                  </div>
                )}
              </li>
              {chabadHouses ? (
                <li
                  onClick={() => {
                    if (width <= 968) {
                      this.setState({
                        chabadHousesDropdownOpen:
                          !this.state.chabadHousesDropdownOpen,
                        schoolsDropdownOpen: false,
                      });
                    }
                  }}
                >
                  <p
                    className={
                      this.props.route.path.indexOf("chabadHouse") > 1
                        ? "active-subheader-nav-link"
                        : null
                    }
                  >
                    Chabad Houses
                  </p>
                  {!!chabadHouses.find((ch) => !ch.isComplete) && (
                    <div className="tooltip-container profile-tooltip ml-8">
                      <i
                        className="material-icons accent-text-dark"
                        style={{ fontSize: "16px" }}
                      >
                        error
                      </i>
                      <span className="tooltip">
                        Chabad Houses info incomplete
                      </span>
                    </div>
                  )}
                  <div
                    className={
                      chabadHousesDropdownOpen
                        ? "profile-dropdown-box dropdown-box open"
                        : "profile-dropdown-box dropdown-box"
                    }
                  >
                    <ul>
                      {chabadHouses.map(
                        ({
                          id: chabadHouseId,
                          isComplete,
                          name: chabadHouseName,
                        }) => (
                          <li
                            className="flex flex-align-center"
                            key={chabadHouseId}
                          >
                            <Link
                              to={`/shluchim/${shliachID}/chabadHouse/${chabadHouseId}?tab=general`}
                              activeClassName="active-subheader-nav-link"
                            >
                              {chabadHouseName}
                            </Link>
                            {!isComplete && (
                              <div className="tooltip-container profile-tooltip flex flex-align-center ml-8">
                                <i
                                  className="material-icons accent-text-dark"
                                  style={{ fontSize: "16px" }}
                                >
                                  error
                                </i>
                                <span className="tooltip">
                                  Chabad House info incomplete
                                </span>
                              </div>
                            )}
                          </li>
                        )
                      )}
                      <li>
                        <a
                          href={`/shluchim/${shliachID}/chabadHouseOptions`}
                          className="link-text flex flex-align-center mt-8"
                        >
                          <i className="material-icons profile-add-icon mr-8">
                            add_circle
                          </i>
                          Add Chabad House
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                <li>
                  <Link
                    to={`/shluchim/${shliachID}/chabadHouseOptions`}
                    activeClassName="active-subheader-nav-link"
                    className={
                      this.props.route.path.indexOf("/chabadHouse") > 1
                        ? "active-subheader-nav-link"
                        : !shliachID
                        ? "disabled-link"
                        : ""
                    }
                  >
                    Chabad Houses
                  </Link>
                </li>
              )}
              <li
                onClick={() => {
                  if (width <= 968) {
                    this.setState({
                      schoolsDropdownOpen: !this.state.schoolsDropdownOpen,
                      chabadHousesDropdownOpen: false,
                    });
                  }
                }}
              >
                <p
                  className={
                    this.props.route.path.indexOf("/school") > 1
                      ? "active-subheader-nav-link"
                      : this.state.profile && !this.state.profile.schools
                      ? "disabled-link"
                      : this.state.profile.schools &&
                        !this.state.profile.schools.length > 0
                      ? "disabled-link"
                      : ""
                  }
                >
                  Schools
                </p>
                {!!(schools && schools.find((s) => !s.isComplete)) && (
                  <div className="tooltip-container profile-tooltip ml-8">
                    <i
                      className="material-icons accent-text-dark"
                      style={{ fontSize: "16px" }}
                    >
                      error
                    </i>
                    <span className="tooltip">Schools info incomplete</span>
                  </div>
                )}
                <div
                  className={
                    schoolsDropdownOpen
                      ? "profile-dropdown-box dropdown-box open"
                      : "profile-dropdown-box dropdown-box"
                  }
                >
                  <ul>
                    {schools &&
                      schools.map(
                        ({ id: schoolId, isComplete, name: schoolName }) => (
                          <li className="flex flex-align-center" key={schoolId}>
                            <Link
                              to={`/shluchim/${shliachID}/school/${schoolId}`}
                              activeClassName="active-subheader-nav-link"
                            >
                              {schoolName}
                            </Link>
                            {!isComplete && (
                              <div className="tooltip-container profile-tooltip flex flex-align-center ml-8">
                                <i
                                  className="material-icons accent-text-dark"
                                  style={{ fontSize: "16px" }}
                                >
                                  error
                                </i>
                                <span className="tooltip">
                                  School info incomplete
                                </span>
                              </div>
                            )}
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="profile-page page container">
          <div className="profile-cards-wrapper">
            {this.props.route.path.indexOf("chabadHouseOptions") > 1 ? (
              <ChabadHouseOptions
                shliachID={shliachID}
                shliach={shliach}
                updateMenuLists={this.updateMenuLists}
              />
            ) : this.props.route.path.indexOf("chabadHouse") > 1 ? (
              <ChabadHouseForm
                checkForErrors={this.checkForErrors}
                errorMessage={this.state.errorMessage}
                routeParams={this.props.routeParams}
                sys={sys}
                shliach={shliach}
                shliachID={shliachID}
                router={this.props.router}
                route={this.props.route}
                updateMenuLists={this.updateMenuLists}
              />
            ) : this.props.route.path.indexOf("school") > 1 ? (
              <SchoolForm
                checkForErrors={this.checkForErrors}
                errorMessage={this.state.errorMessage}
                routeParams={this.props.routeParams}
                sys={sys}
                router={this.props.router}
                route={this.props.route}
                shliach={shliach}
                shliachID={shliachID}
                updateMenuLists={this.updateMenuLists}
              />
            ) : (
              <ShliachForm
                checkForErrors={this.checkForErrors}
                errorMessage={this.state.errorMessage}
                shliach={shliach}
                family={family}
                outreach={outreach}
                sys={sys}
                router={this.props.router}
                route={this.props.route}
                routeParams={this.props.routeParams}
                onSubmit={this.onSubmit}
                submitted={this.state.submitted}
                submitError={this.state.submitError}
                saving={this.state.saving}
                shliachDateFields={this.shliachDateFields}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
