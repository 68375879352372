import React from "react";
import { browserHistory } from "react-router";
import { Link } from "react-router";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import ContactsApi from "../../services/resources/ContactsApi";
import { EmailLink } from "../../lib/coc-common-components";
import { SearchDirectory } from "./SearchDirectory";
import { ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import {
  ModalWithButtons,
  PhoneNumberLink,
} from "../../lib/coc-common-components";
import DropDownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import ImpersonateUser from "../shared/ImpersonateUser";
import { impersonationUserTypes } from "../../lib";
import ShluchimApi from "../../services/resources/ShluchimApi";

export class Contacts extends React.Component {
  /** @type {SearchDirectory} */
  searchDirectory;

  constructor(props) {
    super(props);
    this.state = {
      contactsToMerge: [],
      selectAll: false,
      displayDeleteRestore: "",
    };
    this.fetchContacts = this.fetchContacts.bind(this);
    this.fetchCsvDownload = this.fetchCsvDownload.bind(this);
    this.fetchContactsList = this.fetchContactsList.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cleanContactsToMerge = this.cleanContactsToMerge.bind(this);
  }

  fetchContacts(searchQuery, startingOffset, maxResults, searchParams) {
    if (searchParams.contactType === "student") {
      return ContactsApi.searchStudents(
        searchQuery,
        startingOffset,
        maxResults,
        searchParams,
      );
    } else {
      return ContactsApi.searchShluchim(
        searchQuery,
        startingOffset,
        maxResults,
        searchParams,
      );
    }
  }

  fetchCsvDownload(searchQuery, csvParams) {
    return ContactsApi.csvDownload(searchQuery, csvParams);
  }

  fetchContactsList(contactList) {
    return ContactsApi.listContacts(contactList);
  }

  mergeContacts(contacts) {
    return ContactsApi.mergeStudent(contacts);
  }

  handleChange(e, contact) {
    e.stopPropagation();
    const checked = e.target.checked;
    let { contactsToMerge } = this.state;

    if (checked && !contactsToMerge.some((c) => c.id === contact.id)) {
      contactsToMerge.push(contact);
    } else {
      contactsToMerge = contactsToMerge.filter((c) => c.id !== contact.id);
    }

    this.setState({
      contactsToMerge,
    });
  }

  cleanContactsToMerge() {
    this.setState({
      contactsToMerge: [],
      selectAll: false,
    });
  }

  selectVisibleResults = (event) => {
    if (!this.state.selectAll) {
      const contactsToMerge = this.searchDirectory.getVisibleResults();

      this.setState((newState) => ({
        contactsToMerge,
        selectAll: !newState.selectAll,
      }));
    } else {
      this.cleanContactsToMerge();
    }
  };

  handleDeleteRestore = () => {
    this.setState(
      {
        displayDeleteRestore: "",
        loading: true,
      },
      async () => {
        const { contact } = this.state;

        if (contact.isActive) {
          this.handleDelete(contact.id);
        } else {
          this.handleRestore(contact.id);
        }
      },
    );
  };

  handleDelete = async (contactId) => {
    await ContactsApi.deleteShliach(contactId)
      .then((response) => {
        const text = "Shliach successfully deleted";
        this.setState({ loading: false, displayConfirm: text });
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err);
        this.setState({ loading: false, displayError: errors.join("\n") });
      });
    this.setState({ loadContacts: true });
    setTimeout(() => {
      this.setState({ loadContacts: false });
    }, 1000);
  };

  handleRestore = (contactId) => {
    const contactPath = `${this.props.contactBasePath}/${contactId}`;
    ShluchimApi.restoreShliach(contactId)
      .then((response) => {
        browserHistory.push(contactPath);
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err);
        this.setState({ loading: false, displayError: errors.join("\n") });
      });
  };

  confirmDeleteRestoreButtons() {
    /** @type {Array<{ text: string; className: string; onClick }>} */
    const buttons = [];
    buttons.push({
      text: `I understand. ${
        this.state.contact.isActive ? "Delete" : "Restore"
      } it now.`,
      className: "btn btn-danger",
      onClick: () => this.handleDeleteRestore(),
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDeleteRestore: "" }),
    });
    return buttons;
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    /** @param {boolean} mobile */
    const getFirstPhoneNumber = (contact, mobile) => {
      const firstPhone = contact.contactMethods.find(
        (cm) => cm.type === (mobile ? "CellPhoneNumber" : "PhoneNumber"),
      );
      return firstPhone ? (
        this.props.mobileMode ? (
          <PhoneNumberLink phoneNumber={firstPhone.value} />
        ) : (
          firstPhone.value
        )
      ) : (
        ""
      );
    };

    const renderContact = (contact) => {
      const emailAddresses = contact.contactMethods.filter(
        (cm) =>
          cm.type === "EmailAddress" &&
          cm.value.indexOf("proxymail.facebook.com") < 0,
      );

      const campuses =
        this.props.searchContactType === "shliach"
          ? contact.contactAsShliach &&
            contact.contactAsShliach.campuses.map((c) => c.name)
          : contact.studentCampuses.map((c) => c.name);

      const campusesIds =
        this.props.searchContactType === "shliach"
          ? contact.contactAsShliach &&
            contact.contactAsShliach.campuses.map((c) => c.id)
          : contact.studentCampuses.map((c) => c.campusAid);

      const contactPath = `${this.props.contactBasePath}/${contact.id}${
        this.props.contactBasePath === "/shluchim" ? "/details" : ""
      }`;

      return (
        <tr
          key={contact.id}
          className={
            this.state.contactsToMerge.some((c) => c.id === contact.id)
              ? "selected-value"
              : ""
          }
        >
          {!this.props.hideContactControls && (
            <td>
              <input
                type="checkbox"
                id={"checkbox-" + contact.id}
                className="styled-checkbox"
                onChange={(e) => this.handleChange(e, contact)}
                value={contact.id}
                checked={this.state.contactsToMerge.some(
                  (c) => c.id === contact.id,
                )}
              />
              <label htmlFor={"checkbox-" + contact.id} />
            </td>
          )}
          <td className="align-center">
            {contact.gender === "Male" ? (
              <span className="icon icon-shliach">
                <span className="path1" />
                <span className="path2" />
              </span>
            ) : (
              <span className="icon icon-shlucha">
                <span className="path1" />
                <span className="path2" />
              </span>
            )}
          </td>
          <td>
            <Link to={contactPath}>
              {contact.firstName} {contact.lastName}
            </Link>
            &nbsp;&nbsp;
            {contact.contactAsShliach &&
              contact.contactAsShliach.isPartTime && (
                <i title="part-time" className="icon icon-clock">
                  <span className="path1" />
                  <span className="path2" />
                </i>
              )}
          </td>
          <td>
            {emailAddresses.length > 0 && (
              <EmailLink emailAddress={emailAddresses[0].value} />
            )}{" "}
          </td>
          <td>{getFirstPhoneNumber(contact, true)}</td>
          <td>{getFirstPhoneNumber(contact, false)}</td>
          <td>
            {campuses && campuses.length > 0 ? (
              <Link to={"/schools/" + campusesIds[0]}>{campuses[0]}</Link>
            ) : (
              ""
            )}
          </td>
          <td>
            <p
              className={`ml-16 relative ${
                !contact.isArchived && contact.isActive ? "active" : "inactive"
              }`}
              style={{ marginBottom: "0" }}
            >
              {contact.isArchived
                ? "Archived"
                : contact.isActive
                ? "Active"
                : "Inactive"}
            </p>
            {AuthService.UserHasClaim(PermissionClaims.UserImpersonation) &&
              contact.isActive &&
              (this.props.searchContactType === "shliach" ||
                this.props.searchContactType === "student") &&
              emailAddresses.length > 0 && (
                <ImpersonateUser
                  className="mt-4"
                  username={emailAddresses[0].value}
                  userType={
                    this.props.searchContactType === "shliach"
                      ? impersonationUserTypes.Shliach
                      : impersonationUserTypes.Student
                  }
                />
              )}
          </td>
          {!this.props.hideContactControls &&
            this.props.searchContactType === "shliach" && (
              <td>
                {contact.isActive ? (
                  <i
                    className="material-icons contacts-delete"
                    onClick={() =>
                      this.setState({
                        displayDeleteRestore:
                          "You are about to delete this Shliach.",
                        contact: { id: contact.id, isActive: contact.isActive },
                      })
                    }
                  >
                    delete
                  </i>
                ) : (
                  <i
                    className="material-icons contacts-delete"
                    onClick={() =>
                      this.setState({
                        displayDeleteRestore: `Are you sure you want to restore this Shliach? By clicking yes the Shliach will be restored and you will be redirected to his profile page.`,
                        contact: { id: contact.id, isActive: contact.isActive },
                      })
                    }
                  >
                    restore_from_trash
                  </i>
                )}
              </td>
            )}
        </tr>
      );
    };

    const renderContactMobile = (contact) => {
      const contactPath = `${this.props.contactBasePath}/${contact.id}`;

      return (
        <tr
          key={contact.id}
          onClick={() => browserHistory.push(contactPath)}
          style={{ cursor: "pointer" }}
        >
          <td className="align-center">
            {contact.gender === "Male" ? (
              <span className="icon icon-shliach">
                <span className="path1" />
                <span className="path2" />
              </span>
            ) : (
              <span className="icon icon-shlucha">
                <span className="path1" />
                <span className="path2" />
              </span>
            )}
          </td>
          <td>
            {contact.firstName} {contact.lastName}&nbsp; &nbsp;
            {contact.contactAsShliach &&
              contact.contactAsShliach.isPartTime && (
                <span className="label label-primary">PT</span>
              )}
          </td>
          <td>{getFirstPhoneNumber(contact, true)}</td>
        </tr>
      );
    };

    const renderTableHeader = () => {
      const { hideContactControls, searchContactType } = this.props;
      const { contactsToMerge } = this.state;
      return (
        <tr>
          {!hideContactControls && (
            <th
              className={
                contactsToMerge.length > 0
                  ? "align-center table-header-selected"
                  : "align-center"
              }
            >
              {contactsToMerge.length > 0 &&
              contactsToMerge.length <
                this.searchDirectory.getResultsPerPage() ? (
                <span
                  className="deselect-checkbox"
                  onClick={this.cleanContactsToMerge}
                />
              ) : (
                <span>
                  <input
                    type="checkbox"
                    id="select-all"
                    className="styled-checkbox"
                    checked={this.state.selectAll}
                    onChange={this.selectVisibleResults}
                  />
                  <label htmlFor="select-all" />
                </span>
              )}
              {contactsToMerge &&
              contactsToMerge.length > 0 &&
              searchContactType !== "student" ? (
                <DropDownButton
                  className="table-dropdown"
                  id="table-dropdown"
                  title=""
                >
                  <MenuItem
                    onClick={() => this.searchDirectory.openEventSelector()}
                  >
                    Add event
                  </MenuItem>
                </DropDownButton>
              ) : (
                ""
              )}
            </th>
          )}
          <th className="align-center">
            <i className="icon icon-shluchim" />
          </th>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>Phone</th>
          <th>School</th>
          <th>Status</th>
          {!this.props.hideContactControls && <th />}
        </tr>
      );
    };

    const renderTableHeaderMobile = () => {
      return (
        <tr>
          <th className="align-center">
            <i className="icon icon-shluchim" />
          </th>
          <th>Name</th>
          <th>Mobile Phone</th>
        </tr>
      );
    };
    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>Directory</li>
          <li>{this.props.breadcrumbRoot}</li>
        </ol>

        <SearchDirectory
          ref={(el) => el && (this.searchDirectory = el)}
          location={this.props.location}
          renderTableHeader={
            this.props.mobileMode ? renderTableHeaderMobile : renderTableHeader
          }
          renderResult={
            this.props.mobileMode ? renderContactMobile : renderContact
          }
          fetchResults={this.fetchContacts}
          fetchResultsList={this.fetchContactsList}
          fetchCsvDownload={this.fetchCsvDownload}
          hideContactControls={this.props.hideContactControls}
          loadContacts={this.state.loadContacts}
          mergeContacts={this.mergeContacts}
          searchContactType={this.props.searchContactType}
          allowDownload={this.props.searchContactType !== "student"}
          contactsToMerge={this.state.contactsToMerge}
          cleanContactsToMerge={this.cleanContactsToMerge}
        />

        {this.state.displayDeleteRestore && (
          <ModalWithButtons
            buttons={this.confirmDeleteRestoreButtons()}
            text={this.state.displayDeleteRestore}
          />
        )}
      </div>
    );
  }
}
