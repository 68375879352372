import React from "react";
import { Link } from "react-router";
import moment from "moment";
import { formatFullName, formatNumber } from "../../../lib";

export default function StudentsTableRow(props) {
  const {
    eduProgramScheduleId,
    showLatestCourseInfo,
    hideStatus,
    student: {
      campusName,
      id: enrollmentId,
      latestCourseScheduleDidCompleteSurvey,
      latestCourseScheduleName,
      latestCourseScheduleNumOfClassesAttended,
      latestCourseScheduleNumOfClassesScheduled,
      memberSinceDate,
      numOfCoursesCompleted,
      shliachFirstName,
      shluchaFirstName,
      shliachLastName,
      statusDisplay,
      studentID,
      studentFirstName,
      studentLastName,
    },
  } = props;

  return (
    <Link
      className={`courses-students-table-row ${
        hideStatus ? "hide-status" : ""
      }`}
      to={{
        pathname: `/edu/${eduProgramScheduleId}/students/${studentID}`,
        query: { tab: enrollmentId },
      }}
    >
      <p>
        {formatFullName(studentFirstName, studentLastName)}
        {statusDisplay === "Rejected" && hideStatus && (
          <span className="rejected-chip">Rejected</span>
        )}
      </p>
      <div>
        <p className="fw-700">{campusName}</p>
        <p>
          {formatFullName(
            shliachFirstName || shluchaFirstName,
            shliachLastName,
          )}
        </p>
      </div>
      {showLatestCourseInfo ? (
        <p>{latestCourseScheduleName}</p>
      ) : (
        <p>{formatNumber(numOfCoursesCompleted)}</p>
      )}
      {showLatestCourseInfo ? (
        <p>
          {formatNumber(latestCourseScheduleNumOfClassesAttended)}/
          {formatNumber(latestCourseScheduleNumOfClassesScheduled)}
        </p>
      ) : (
        <p>
          {memberSinceDate ? moment(memberSinceDate).format("MM/DD/YYYY") : ""}
        </p>
      )}
      {!hideStatus && <p>{statusDisplay}</p>}

      {showLatestCourseInfo && (
        <p>{latestCourseScheduleDidCompleteSurvey ? "Yes" : "No"}</p>
      )}
    </Link>
  );
}
