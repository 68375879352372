import React from "react";
import Modal from "react-modal";

// export interface ModalButton {
//   text: string;
//   className?: string;
//   onClick: React.FormEventHandler<HTMLButtonElement>;
// }

const styles = {
  modal: {
    overlay: {
      zIndex: 5,
      backgroundColor: "rgba(42,63,84, 0.85)",
    },
    content: {
      top: "10%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, 0)",
      overflow: "visible",
      minWidth: "250px",
      whiteSpace: "pre-line",
    },
  },
};

// export interface ModalWithButtonsProps {
//   buttons: Array<ModalButton>;
//   text?: string;
//   textClassName?: string;
//   disableButtons?: boolean;
//   content?;
// }

export const ModalWithButtons = ({
  buttons = [],
  text = "",
  textClassName = "",
  disableButtons = false,
  content,
  className,
}) => {
  return (
    <Modal
      style={styles.modal}
      contentLabel=""
      isOpen={true}
      className={className}
    >
      <p className={textClassName}>{text}</p>
      {content && (
        <div>
          <hr />
          {content}
          <hr />
        </div>
      )}
      <div className="text-center">
        <div className="">
          {buttons.map((button, index) => (
            <button
              key={index}
              disabled={disableButtons}
              style={{ margin: "15px" }}
              className={button.className}
              onClick={button.onClick}
            >
              {button.text}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};
