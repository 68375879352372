import React from "react";
import { Link } from "react-router";
import { formatAddressDisplay, formatFullName } from "../../../lib";

export default class ChabadHouseDetails extends React.PureComponent {
  render() {
    const {
      shliachDetails: {
        primaryChabadHouse,
        shliach: { id: shliachId },
      },
    } = this.props;

    const {
      address,
      campuses,
      id: chabadHouseId,
      name,
      personnel,
      primaryEmail,
      primaryPhoneNumber,
      region,
      shluchim,
      timezoneName,
      websiteURL,
    } = primaryChabadHouse || {};

    return primaryChabadHouse ? (
      <div className="chabad-house-details">
        <div>
          <div className="mb-16">
            <p className="uppercase-text mb-8">Chabad House</p>
            <Link
              className="link-text"
              to={`/shluchim/${shliachId}/chabadHouse/${chabadHouseId}`}
            >
              {name}
            </Link>
          </div>
          <div className="two-column-grid">
            {primaryPhoneNumber && (
              <React.Fragment>
                <p>Phone</p>
                <a
                  className="link-text-secondary"
                  href={`tel:${primaryPhoneNumber}`}
                >
                  {primaryPhoneNumber}
                </a>
              </React.Fragment>
            )}
            {primaryEmail && (
              <React.Fragment>
                <p>Email</p>
                <a
                  className="link-text-secondary"
                  href={`mailto:${primaryEmail}`}
                >
                  {primaryEmail}
                </a>
              </React.Fragment>
            )}
            {address && (
              <React.Fragment>
                <p>Address</p>
                <div>
                  {formatAddressDisplay(
                    address.address1,
                    address.address2,
                    address.city,
                    address.state,
                    address.zip,
                    address.country,
                  )}
                </div>
              </React.Fragment>
            )}
            {websiteURL && (
              <React.Fragment>
                <p>Website</p>
                <a
                  className="link-text"
                  href={websiteURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {websiteURL}
                </a>
              </React.Fragment>
            )}
            {region && (
              <React.Fragment>
                <p>Region</p>
                <p>{region}</p>
              </React.Fragment>
            )}
            {timezoneName && (
              <React.Fragment>
                <p>Timezone</p>
                <p>{timezoneName}</p>
              </React.Fragment>
            )}
          </div>
        </div>
        <div>
          <p className="uppercase-text mb-16">Personnel</p>
          {shluchim &&
            shluchim.map((shliach, index) => (
              <div className="flex flex-align-center mb-16" key={index}>
                <img
                  alt="shliach-profile"
                  className="mr-16 profile-image-sm"
                  src={
                    shliach.profileImageURL || "/img/profile-placeholder.svg"
                  }
                />
                <div>
                  <Link
                    className="link-text"
                    to={`/shluchim/${shliach.shliachID}/details`}
                  >
                    {shliach.titleDisplay ? `${shliach.titleDisplay} ` : ""}
                    {shliach.fullName}
                  </Link>
                  <p className="accent-text mt-4 mb-0">
                    {shliach.shliachPositionDisplay}
                  </p>
                </div>
              </div>
            ))}
          {personnel &&
            personnel.map((person, index) => (
              <div className="flex flex-align-center mb-16" key={index}>
                <div className="profile-image-sm-placeholder" />
                <div>
                  <p className="mb-0">
                    {formatFullName(person.firstName, person.lastName)}
                  </p>
                  <p className="accent-text mt-4 mb-0">
                    {person.positionDisplay}
                  </p>
                  {person.email && (
                    <p className="accent-text mb-0">{person.email}</p>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div>
          <p className="uppercase-text mb-16">Schools</p>
          {campuses &&
            campuses.map((campus, index) => (
              <div
                className="flex flex-align-center flex-justify-space mb-16"
                key={index}
              >
                <Link className="link-text" to={`/schools/${campus.campusID}`}>
                  {campus.campusName}
                </Link>
                {campus.isPrimary && <p className="accent-text">Primary</p>}
              </div>
            ))}
        </div>
      </div>
    ) : null;
  }
}
