import React from "react";

function RegistrationRecommendation(props) {
  const {
    registration: { recommendation },
  } = props;

  const {
    acquaintanceLevelDisplay,
    comments,
    expectedStudentGrowth,
    hadPriorKnowledgeOfStudentRegistration,
    recommendationStatusDisplay,
    relationshipWithStudent,
    shliachFirstName,
    shliachLastName,
    shliachCell,
    shliachEmail,
  } = recommendation || {};

  return (
    <div className="trip-student-detail-tab recommendation-tab">
      <div className="xl-text fw-700">Shliach recommendation</div>
      {recommendation ? (
        <>
          <div className="student-detail-section">
            <p className="medium-text fw-700 mb-8">Shliach information</p>
            <div className="two-column-grid mb-16">
              <p className="fw-700">Name</p>
              <p>
                {shliachFirstName} {shliachLastName}
              </p>
              <p className="fw-700">Phone</p>
              <p>{shliachCell}</p>
              <p className="fw-700">Email</p>
              <p>{shliachEmail}</p>
            </div>
            <p className="medium-text fw-700 mb-8">Do you know this student?</p>
            <p>{acquaintanceLevelDisplay}</p>
          </div>
          <div className="student-detail-section">
            <p className="medium-text fw-700 mb-8">
              Were you aware that this student signed up for this trip?
            </p>
            <p>{hadPriorKnowledgeOfStudentRegistration ? "Yes" : "No"}</p>
          </div>
          <div className="student-detail-section">
            <p className="medium-text fw-700 mb-8">
              Please describe your relationship with this student
            </p>
            <p>{relationshipWithStudent}</p>
          </div>
          <div className="student-detail-section">
            <p className="medium-text fw-700 mb-8">
              What growth do you hope to see in this student as a result of this
              trip?
            </p>
            <p>{expectedStudentGrowth}</p>
          </div>
          <div className="student-detail-section">
            <p className="medium-text fw-700 mb-8">
              Do you recommend this student for this trip?
            </p>
            <p>{recommendationStatusDisplay}</p>
          </div>
          <div className="student-detail-section">
            <p className="medium-text fw-700 mb-8">
              Please share any comments or other information that you feel would
              be helpful in determining if this is the right trip for your
              student.
            </p>
            <p>{comments}</p>
          </div>
        </>
      ) : (
        <div className="accent-text mt-24 mb-24">
          No recommendation submitted
        </div>
      )}
    </div>
  );
}

export default React.memo(RegistrationRecommendation);
