import React from "react";
import { Link } from "react-router";
import ExportCSVButton from "../../shared/ExportCSVButton";

export default class RewardsTableActions extends React.PureComponent {
  render() {
    const {
      eduProgram: { name, programScheduleID },
      getRewardsForExport,
      viewOnly,
    } = this.props;

    return (
      <div className="flex">
        {!viewOnly && (
          <Link
            className="btn custom-btn btn-accent flex flex-align-center mr-16"
            to={`/edu/${programScheduleID}/rewards`}
          >
            Add
          </Link>
        )}
        <ExportCSVButton
          className="btn custom-btn btn-accent flex flex-align-center"
          fileName={`${name.replace(/ /g, "_")}_Rewards`}
          getExportData={getRewardsForExport}
          headers={[
            { label: "Reward Name", value: "name" },
            { label: "Reward Type", value: "typeDisplay" },
            { label: "Credit Value", value: "creditValueUSD" },
            { label: "Max Credits", value: "maxCredits" },
            {
              label: "Status",
              value: ({ isAvailable }) => (isAvailable ? "Active" : "Inactive"),
            },
          ]}
        />
      </div>
    );
  }
}
