import React from "react";
import { Link, browserHistory } from "react-router";
import FilteredMultiSelect from "react-filtered-multiselect";
import axios from "axios";
import { ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons,
} from "../../lib/coc-common-components";

import viewHistory from "../../services/ViewHistory";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import PermissionsApi from "../../services/resources/PermissionsApi";
import ContactsApi from "../../services/resources/ContactsApi";
import SystemApi from "../../services/resources/SystemApi";
import {
  ContactMethodsStrings,
  EmptyUser,
  EmptyContactMethod,
} from "../../models/Contact";
import OfficeStaffManager from "../OfficeStaff/OfficeStaffManager/OfficeStaffManager";

// interface AdminProps {
//   params;
//   location;
// }

// interface AdminState {
//   contact: User;
//   loading: boolean;
//   displayError: string;
//   displayAndRedirectMessage: string;
//   emailWarning?: "none" | "warn" | "save";
//   claimsList: Array<Claim>;
//   rolesList: Array<Role>;
// }

const BOOTSTRAP_CLASSES = {
  filter: "form-control",
  select: "form-control",
  button: "btn btn btn-block btn-default",
};

export default class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: this.newEmptyUser(),
      loading: false,
      displayError: "",
      displayAndRedirectMessage: "",
      emailWarning: "none",
      claimsList: [],
      rolesList: [],
      systemLists: {},
    };

    this.handleContactChanged = this.handleContactChanged.bind(this);
    this.handleDeselectRole = this.handleDeselectRole.bind(this);
    this.handleSelectRole = this.handleSelectRole.bind(this);
    this.handleDeselectClaim = this.handleDeselectClaim.bind(this);
    this.handleSelectClaim = this.handleSelectClaim.bind(this);
  }

  /** @returns {ContactMethods} */
  newEmptyContactMethod() {
    return { ...EmptyContactMethod(), id: -Math.floor(Math.random() * 10000) };
  }
  /** @returns {User}  */
  newEmptyUser() {
    return { ...EmptyUser(), id: -Math.floor(Math.random() * 10000) };
  }
  /** @param {User} contact
   * @returns {User}
   */
  formatedContactClaims(contact) {
    const newContact = Object.assign({}, contact);
    /** @type {Array<Role>} */
    const rolesList = newContact.roles;
    rolesList.forEach((role) => {
      role.claims = this.sortedClaims(role.claims.map(this.formatedClaim));
    });
    newContact.roles = rolesList;

    newContact.claims = newContact.claims.map(this.formatedClaim);

    return newContact;
  }
  /** @param {number} [id=0] */
  fetchContactData(id) {
    if (id <= 0) {
      id = Number(this.props.params.id);
    }

    this.setState({ loading: true }, () => {
      ContactsApi.getAdmin(id)
        .then((response) => {
          const contact = this.formatedContactClaims(response.data);
          this.setState({ contact, loading: false });
          this.writeToViewHistory(contact);
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayError: errors.join("\n") });
        });
    });
  }
  /** @param {string} redirectUrl */
  okAndRedirectButtons(redirectUrl) {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({ displayAndRedirectMessage: "" }, () => {
          browserHistory.push(redirectUrl);
        }),
    });
    return buttons;
  }

  writeToViewHistory(contact) {
    const emailAddresses =
      contact.contactMethods &&
      contact.contactMethods.filter(
        (cm) => cm.type === ContactMethodsStrings.Email,
      );
    const mobilePhone = contact.contactMethods.filter(
      (cm) => cm.type === ContactMethodsStrings.Mobile,
    );
    const campus = contact.studentCampuses && contact.studentCampuses[0];

    viewHistory.add("Contact", {
      path: this.props.location.pathname,
      name: contact.firstName + " " + contact.lastName,
      email: emailAddresses.length > 0 ? emailAddresses[0].value : "",
      mobilePhone: mobilePhone.length > 0 ? mobilePhone[0].value : "",
      campusName: campus ? campus.name : "",
      gender: contact.gender,
      historyType: "user",
    });
  }
  /** @param {User} newState */
  handleContactChanged(newState) {
    this.setState({ contact: newState });
  }
  /** @param {ContactMethods} cm
   * @returns {boolean}
   */
  isContactMethodValidLogin(cm) {
    return (
      cm.primary &&
      cm.type === ContactMethodsStrings.Email &&
      (cm.value.endsWith("@gmail.com") ||
        cm.value.endsWith("@chabad.edu") ||
        cm.value.endsWith("@chabadoncampus.org"))
    );
  }
  /** @param {User} contact */
  prepareContactForSaving(contact) {
    const user = Object.assign({}, contact);
    user.roles = user.roles.map((role) => role.id);
    user.claims = user.claims.map((claim) => claim.id);
    return user;
  }

  handleSave() {
    const showEmailWarning =
      this.state.emailWarning === "none" &&
      !(this.state.contact.contactMethods || []).find(
        this.isContactMethodValidLogin,
      );

    if (showEmailWarning) {
      this.setState({ emailWarning: "warn" });
    } else {
      this.setState({ loading: true }, () => {
        const isNew = !this.state.contact.id || this.state.contact.id < 0;
        const apiCall = isNew ? ContactsApi.createUser : ContactsApi.updateUser;
        const contact = this.prepareContactForSaving(
          this.state.contact || this.newEmptyUser(),
        );

        apiCall(contact)
          .then((response) => {
            const formattedContact = this.formatedContactClaims(response.data);
            this.setState({
              contact: formattedContact,
              displayAndRedirectMessage: "User saved successfully",
              emailWarning: "none",
              loading: false,
            });
          })
          .catch((err) => {
            const errors = ApiCallErrorHandler(err);
            this.setState({ loading: false, displayError: errors.join("\n") });
          });
      });
    }
  }

  errorOnSaveButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayError: "" }),
    });
    return buttons;
  }
  /** @param {boolean} allowSave */
  emailWarningButtons(allowSave) {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    if (allowSave) {
      buttons.push({
        text: "Save Anyway",
        className: "btn btn-primary",
        onClick: () => this.setState({ emailWarning: "save" }, this.handleSave),
      });
    }
    buttons.push({
      text: allowSave ? "Cancel" : "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ emailWarning: "none" }),
    });
    return buttons;
  }
  /** @param {Claim} claim
   * @returns {string}
   */
  formatedClaimOrder(claim) {
    return (
      ("000" + claim.claimGroup.sortOrder.toString()).substr(-3, 3) +
      "-" +
      ("000" + claim.sortOrder.toString()).substr(-3, 3)
    );
  }
  /** @param {Array<Claim>} claims
   * @returns {Array<Claim>}
   */
  sortedClaims(claims) {
    const returnArray = [...claims];
    returnArray.sort((a, b) =>
      this.formatedClaimOrder(a) > this.formatedClaimOrder(b) ? 1 : -1,
    );
    return returnArray;
  }
  /** @param {Claim} claim
   * @returns {Claim}
   */
  formatedClaim(claim) {
    return { ...claim, name: claim.claimGroup.name + " - " + claim.name };
  }

  loadClaimsAndRoles() {
    const that = this;
    this.setState({ loading: true }, () => {
      axios.all([PermissionsApi.listClaims(), PermissionsApi.listRoles()]).then(
        axios.spread(function (claims, roles) {
          const claimsList = claims.data.results.map(that.formatedClaim);
          /** @type {Array<Role>} */
          const rolesList = roles.data.results;
          rolesList.forEach((role) => {
            role.claims = that.sortedClaims(
              role.claims.map(that.formatedClaim),
            );
          });
          that.setState({
            loading: false,
            claimsList: that.sortedClaims(claimsList),
            rolesList,
          });
        }),
      );
    });
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.RoleManager)) {
      return;
    }

    if (this.props.location.pathname === "/users/new") {
      this.setState({ contact: this.newEmptyUser() });
    } else {
      const id = Number(this.props.params.id);
      if (!Number.isNaN(id)) {
        this.fetchContactData(id);
      }
    }
    this.loadClaimsAndRoles();
    this.getSystemLists();
  }
  /** @param {Array<Role>} deselectedOptions */
  handleDeselectRole(deselectedOptions) {
    const newRoles = this.state.contact.roles.slice();
    deselectedOptions.forEach((option) => {
      newRoles.splice(newRoles.indexOf(option), 1);
    });
    this.setState({
      contact: Object.assign({}, this.state.contact, { roles: newRoles }),
    });
  }
  /** @param {Array<Role>} selectedOptions */
  handleSelectRole(selectedOptions) {
    this.setState({
      contact: Object.assign({}, this.state.contact, {
        roles: selectedOptions,
      }),
    });
  }
  /** @param {Array<Role>} deselectedOptions */
  handleDeselectClaim(deselectedOptions) {
    const newClaims = this.state.contact.claims.slice();
    deselectedOptions.forEach((option) => {
      newClaims.splice(newClaims.indexOf(option), 1);
    });
    this.setState({
      contact: Object.assign({}, this.state.contact, { claims: newClaims }),
    });
  }
  /** @param {Array<Role>} selectedOptions */
  handleSelectClaim(selectedOptions) {
    this.setState({
      contact: Object.assign({}, this.state.contact, {
        claims: selectedOptions,
      }),
    });
  }

  getSystemLists = async () => {
    const systemLists = await SystemApi.lists(["countries", "titles"]);
    this.setState({ systemLists });
  };

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.RoleManager)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { contact, claimsList = [] } = this.state;
    const assignedClaims =
      contact &&
      contact.roles
        .reduce(
          (claimList, currentRole) =>
            claimList.concat(currentRole.claims || []),
          [],
        )
        .concat(contact.claims || []);
    const assignedClaimsIds = (assignedClaims || []).map((c) => c.id);
    const nonAssignedClaims = claimsList.filter(
      (c) => assignedClaimsIds.indexOf(c.id) === -1,
    );

    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            {(contact.firstName + " " + contact.lastName).trim() ||
              "[New User]"}
          </li>
        </ol>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSave();
          }}
        >
          <div className="well well-lg">
            <OfficeStaffManager
              officeStaffContactData={contact}
              handleOfficeStaffContactChanged={this.handleContactChanged}
              systemLists={this.state.systemLists}
            />
          </div>

          <div className="well well-lg">
            <div className="row">
              <h4>Roles</h4>
              {AuthService.UserHasClaim(PermissionClaims.RoleManager) && (
                <div>
                  <div className="col-md-5">
                    <dl>
                      <dt>Current</dt>
                      <dd>
                        <FilteredMultiSelect
                          buttonText="Remove"
                          classNames={{
                            ...BOOTSTRAP_CLASSES,
                            buttonActive: "btn btn btn-block btn-danger",
                          }}
                          onChange={this.handleDeselectRole}
                          options={contact.roles || []}
                          textProp="name"
                          valueProp="id"
                        />
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-5">
                    <dl>
                      <dt>Available</dt>
                      <dd>
                        <FilteredMultiSelect
                          buttonText="Add"
                          classNames={{
                            ...BOOTSTRAP_CLASSES,
                            buttonActive: "btn btn btn-block btn-primary",
                          }}
                          onChange={this.handleSelectRole}
                          options={this.state.rolesList}
                          selectedOptions={contact.roles || []}
                          textProp="name"
                          valueProp="id"
                        />
                      </dd>
                    </dl>
                  </div>
                </div>
              )}
            </div>
            <div>
              {(contact.roles || []).length === 0
                ? "No roles assigned yet"
                : contact.roles.map((role) => (
                    <div key={role.id}>
                      <div>
                        <strong>{role.name}</strong>: {role.description}
                      </div>
                      {(role.claims || []).length === 0
                        ? "No permissions in role"
                        : role.claims.map((claim) => (
                            <div key={claim.id}>
                              <em>{claim.name}</em>: {claim.description}
                            </div>
                          ))}
                      <br />
                    </div>
                  ))}
            </div>

            <div className="row">
              <h4>Other Permissions</h4>
              {AuthService.UserHasClaim(PermissionClaims.RoleManager) && (
                <div>
                  <div className="col-md-5">
                    <dl>
                      <dt>Current</dt>
                      <dd>
                        <FilteredMultiSelect
                          buttonText="Remove"
                          classNames={{
                            ...BOOTSTRAP_CLASSES,
                            buttonActive: "btn btn btn-block btn-danger",
                          }}
                          onChange={this.handleDeselectClaim}
                          options={contact.claims || []}
                          textProp="name"
                          valueProp="id"
                        />
                      </dd>
                    </dl>
                  </div>
                  <div className="col-md-5">
                    <dl>
                      <dt>Available</dt>
                      <dd>
                        <FilteredMultiSelect
                          buttonText="Add"
                          classNames={{
                            ...BOOTSTRAP_CLASSES,
                            buttonActive: "btn btn btn-block btn-primary",
                          }}
                          onChange={this.handleSelectClaim}
                          options={nonAssignedClaims}
                          selectedOptions={contact.claims || []}
                          textProp="name"
                          valueProp="id"
                        />
                      </dd>
                    </dl>
                  </div>
                </div>
              )}
            </div>
            <div>
              {(contact.claims || []).length === 0
                ? "No individual claims assigned"
                : contact.claims.map((claim) => (
                    <div key={claim.id}>
                      <em>{claim.name}</em>: {claim.description}
                    </div>
                  ))}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 text-right">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "1em" }}
              >
                Save
              </button>
              {this.state.displayError && (
                <ModalWithButtons
                  buttons={this.errorOnSaveButtons()}
                  text={this.state.displayError}
                />
              )}
            </div>
          </div>
        </form>
        {this.state.emailWarning === "warn" &&
          ((contact.contactMethods || []).filter(
            (cm) => cm.type === ContactMethodsStrings.Email,
          ).length > 0 ? (
            <ModalWithButtons
              buttons={this.emailWarningButtons(true)}
              text={
                "The login email doesn't seem to be a Google account\nand at this point we are not able to verify if it is.\nContinue anyway?"
              }
            />
          ) : (
            <ModalWithButtons
              buttons={this.emailWarningButtons(false)}
              text={
                "Please enter at least one email address to be used as login account"
              }
            />
          ))}
        {this.state.loading && <Loader />}
        {this.state.displayAndRedirectMessage && (
          <ModalWithButtons
            buttons={this.okAndRedirectButtons("/users")}
            text={this.state.displayAndRedirectMessage}
          />
        )}
      </div>
    );
  }
}
