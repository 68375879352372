import React from "react";
import Dropzone from "react-dropzone";
import ImageCropperModal from "./ImageCropperModal";
import ContentApi from "../../services/resources/ContentApi";

export default class ImageUpload extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      droppedImage: null,
      loading: false,
      showCropperModal: false,
      showError: false,
    };

    this.dropzoneRef = React.createRef();
  }

  onDrop = async (acceptedFiles, rejectedFiles) => {
    this.setState({ uploadError: false });

    if (acceptedFiles.length) {
      if (this.props.withCrop) {
        const fileWithPreview = Object.assign(acceptedFiles[0], {
          previewURL: URL.createObjectURL(acceptedFiles[0]),
        });
        this.setState({
          droppedImage: fileWithPreview,
          showCropperModal: true,
        });
      } else {
        this.uploadImage(acceptedFiles[0]);
      }
    }
  };

  onDropRejected = () => {
    this.setState({ uploadError: true });
  };

  onFinishCrop = async (croppedImgData) => {
    const { droppedImage } = this.state;

    const file = croppedImgData
      ? new File([croppedImgData], droppedImage.name, { type: "image/png" })
      : droppedImage;

    this.uploadImage(file);
  };

  uploadImage = async (file) => {
    const { imageType, name, onChange } = this.props;

    this.setState({ loading: true });

    const fileURL = await ContentApi.uploadFile(file, imageType);

    onChange(name, fileURL);

    this.setState({ loading: false, showCropperModal: false });
  };

  onCancelCrop = () => {
    this.setState({ loading: false, showCropperModal: false });
  };
  render() {
    const {
      className,
      cropAspectHeight,
      cropAspectWidth,
      disabled,
      error,
      imageName,
      imageURL,
      height,
      hideImage,
      hideLabel,
      imageIcon,
      displayLoader,
      forceCrop,
    } = this.props;

    const { droppedImage, loading, showCropperModal, uploadError } = this.state;

    return (
      <React.Fragment>
        <Dropzone
          accept={"image/png,image/jpeg,image/bmp,image/jpg"}
          disabled={disabled || loading}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          title="Drag an image here or click to find one on your computer."
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={className}
            >
              <input {...getInputProps()} />
              <span
                className={`link-text uppercase-text${
                  disabled ? " disabled" : !loading && error ? " error" : ""
                }`}
              >
                {!hideImage && (
                  <img
                    alt=""
                    height={height || 64}
                    src={imageURL || "/img/placeholder.svg"}
                    style={{
                      marginRight: 32,
                      background: "#edecec",
                      borderRadius: "3px",
                      objectFit: "cover",
                    }}
                  />
                )}
                {!hideLabel &&
                  (loading ? "Uploading" : imageURL ? "Change" : "Upload")}{" "}
                {imageName}
                {imageIcon}
                {loading && "..."}
              </span>
            </div>
          )}
        </Dropzone>
        {uploadError && (
          <p className="error-text">Something went wrong. Please try again.</p>
        )}
        {droppedImage && (
          <ImageCropperModal
            cropAspectHeight={cropAspectHeight}
            cropAspectWidth={cropAspectWidth}
            imageName={imageName}
            imageURL={droppedImage.previewURL}
            show={showCropperModal}
            submitCrop={this.onFinishCrop}
            loading={loading}
            displayLoader={displayLoader}
            forceCrop={forceCrop}
            cancelCrop={this.onCancelCrop}
          />
        )}
      </React.Fragment>
    );
  }
}
