import React from "react";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";

import Big from "big.js";

import { formatCurrency } from "../../../../../lib/utils";

export default class NewOrderCCInfo extends React.PureComponent {
  state = {
    incompleteCardFields: {
      cardNumber: true,
      cardExpiry: true,
      cardCvc: true,
    },
  };

  componentWillUnmount() {
    this.props.onChange("ccInfoComplete", false);
  }

  onChangeCC = ({ elementType, complete }) => {
    const incompleteCardFields = {
      ...this.state.incompleteCardFields,
      [elementType]: !complete,
    };

    this.setState(
      {
        incompleteCardFields,
      },
      () => {
        this.props.onChange(
          "ccInfoComplete",
          !Object.keys(incompleteCardFields).filter(
            (cardField) => incompleteCardFields[cardField],
          ).length,
        );
      },
    );
  };

  render() {
    const {
      allowDonorToPayProcessingFee,
      cardHolderFullName,
      ccInfoComplete,
      cociCcProcessingFeePercentage,
      currencyCode,
      didDonorPayProcessingFee,
      onChangeEvent,
      submitAttempted,
      ticketPriceAmount,
    } = this.props;

    const { incompleteCardFields } = this.state;

    const processingFeeDonorAmount = didDonorPayProcessingFee
      ? Big(ticketPriceAmount).times(cociCcProcessingFeePercentage).div(100)
      : Big(0);
    const totalAmount = processingFeeDonorAmount.plus(ticketPriceAmount);

    return (
      <div className="cc-info-form">
        <div className="flex flex-align-center mb-12">
          <label className="accent-text small-text mr-24">Card Number</label>
          <CardNumberElement
            className={`custom-input ${
              submitAttempted &&
              !ccInfoComplete &&
              incompleteCardFields.cardNumber
                ? "error"
                : ""
            }`}
            onChange={this.onChangeCC}
            placeholder="Card Number"
          />
        </div>
        <div className="flex flex-align-center mb-12">
          <label className="accent-text small-text mr-24">
            Cardholder Full Name
          </label>
          <input
            type="text"
            className={`custom-input ${
              submitAttempted && !cardHolderFullName ? "error" : ""
            }`}
            placeholder="Full Name"
            name="cardHolderFullName"
            value={cardHolderFullName}
            onChange={onChangeEvent}
          />
        </div>
        <div className="flex flex-align-center mb-12">
          <label className="accent-text small-text mr-24">Exp Date</label>
          <CardExpiryElement
            className={`custom-input ${
              submitAttempted &&
              !ccInfoComplete &&
              incompleteCardFields.cardExpiry
                ? "error"
                : ""
            }`}
            onChange={this.onChangeCC}
          />
        </div>
        <div className="flex flex-align-center mb-12">
          <label className="accent-text small-text mr-24">CVC Code</label>
          <CardCVCElement
            className={`custom-input ${
              submitAttempted && !ccInfoComplete && incompleteCardFields.cardCvc
                ? "error"
                : ""
            }`}
            onChange={this.onChangeCC}
            placeholder="CVC"
          />
        </div>

        {allowDonorToPayProcessingFee && (
          <>
            <div className="flex flex-align-center mb-12 raffle-order-checkbox-container">
              <input
                className="custom-checkbox"
                id="cover-processing-fee-checkbox"
                name="didDonorPayProcessingFee"
                onChange={onChangeEvent}
                type="checkbox"
                value={didDonorPayProcessingFee}
              />
              <label
                className="flex-align-center"
                style={{ fontWeight: "400" }}
                htmlFor="cover-processing-fee-checkbox"
              >
                <span>
                  Add {cociCcProcessingFeePercentage}% to cover the processing
                  fee. Only check this if confirmed with donor.
                </span>
              </label>
            </div>

            <div className="raffle-order-summary">
              <div className="flex flex-align-center flex-justify-space mb-12">
                <p>Ticket Total</p>
                <p>{formatCurrency(ticketPriceAmount, currencyCode)}</p>
              </div>
              <div className="flex flex-align-center flex-justify-space mb-12">
                <p>CC fee</p>
                <p>{formatCurrency(processingFeeDonorAmount, currencyCode)}</p>
              </div>
              <div
                className="flex flex-align-center flex-justify-space mb-12"
                style={{ fontWeight: "600" }}
              >
                <p>Total Due</p>
                <p>{formatCurrency(totalAmount, currencyCode)}</p>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
