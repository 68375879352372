import React from "react";

export default class StudentCoursesTableHeader extends React.PureComponent {
  render() {
    const columns = ["Name", "Start & End Date", "Status", "Students"];

    return (
      <div className="locations-shliach-details-modal-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
