import React from "react";
import Modal from "react-modal";
import Loader from "../common/Loader";

export default class ConfirmationModal extends React.Component {
  render() {
    const {
      cancel,
      cancelText,
      className,
      confirm,
      confirmText,
      errorMessage,
      message,
      noConfirm,
      noTitle,
      loading,
      show,
      title,
    } = this.props;

    return (
      <Modal
        isOpen={show}
        className={`center-modal-container ${className || ""}`}
      >
        <div className="modal-card card small-modal">
          {!noTitle && (
            <p className="large-text fw-700">{title || "Confirm"}</p>
          )}
          <div className="accent-text" style={{ whiteSpace: "pre-wrap" }}>
            {message}
          </div>
          {loading ? (
            <div className="modal-btns-loader">
              <Loader />
            </div>
          ) : (
            <div className="text-right">
              <div className="modal-btns relative">
                <p className="link-text uppercase-text" onClick={cancel}>
                  {cancelText || "Cancel"}
                </p>
                {!noConfirm && (
                  <p
                    className="link-text uppercase-text ml-40"
                    onClick={confirm}
                  >
                    {confirmText || "Confirm"}
                  </p>
                )}
              </div>

              {!!errorMessage && (
                <span className="error-text">{errorMessage}</span>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
