import React from "react";
import { browserHistory, Link } from "react-router";
import Loader from "../Contact/ShliachPages/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import Select from "react-select";

import EduProgramStats from "./EduProgramStats";
import CoursesTable from "./courses/CoursesTable";
import CourseSchedulesTable from "./courseSchedules/CourseSchedulesTable";
import LocationsTable from "./locations/LocationsTable";
import StudentsTable from "./students/StudentsTable";
import RewardRequestsTable from "./rewardRequests/RewardRequestsTable";
import RewardsTable from "./rewards/RewardsTable";

import EduApi from "../../services/resources/EduApi";
import ChabadHousesApi from "../../services/resources/ChabadHousesApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { EduProgramTypes } from "./EduConsts";
import axios from "axios";

export default class EduProgramsPage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    chabadHousesList: [],
    chabadHousesListErrorMessage: "",
    chabadHousesListLoading: false,

    eduChabadHousesList: {},
    eduChabadHousesListErrorMessage: "",
    eduChabadHousesListLoading: false,

    eduProgram: null,
    eduProgramErrorMessage: "",
    eduProgramLoading: false,

    eduProgramsList: [],
    eduProgramsListErrorMessage: "",
    eduProgramsListLoading: false,

    systemLists: {},
    systemListsErrorMessage: "",
    tabs: [],
    tabIndex: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.EduFullView)) {
      this.setState({ authorized: false });
    } else if (!AuthService.UserHasClaim(PermissionClaims.EduFullEdit)) {
      this.setState({ viewOnly: true });
    }

    this.getEduPrograms();

    this.getSystemLists();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { scheduleId },
    } = this.props;
    const {
      params: { scheduleId: prevScheduleId },
    } = prevProps;
    //check for external schedule update that is not in sync with state (update from quick access programs menu)
    if (scheduleId && prevScheduleId && scheduleId !== prevScheduleId) {
      const { programScheduleID } = this.state.eduProgram || {};
      if (
        programScheduleID &&
        programScheduleID.toString() !== scheduleId.toString()
      ) {
        this.getEduProgramDetails(scheduleId);
      }
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getTabs = (eduProgramType) => {
    let tabs = [
      {
        component: CoursesTable,
        id: "courses",
        name: "Courses",
      },
      {
        component: LocationsTable,
        id: "locations",
        name: "Locations",
      },
      {
        component: StudentsTable,
        id: "students",
        name: "Students",
      },
      {
        component: CourseSchedulesTable,
        id: "schedules",
        name: "Schedules",
      },
      {
        component: RewardsTable,
        id: "rewards",
        name: "Rewards",
      },
      {
        component: RewardRequestsTable,
        id: "reward-requests",
        name: "Reward Requests",
      },
    ];

    // limit tabs for YourIsrael and SinaiScholars programs
    if (eduProgramType !== EduProgramTypes.JewishU) {
      tabs = tabs.filter(
        (t) =>
          t.id === "locations" || t.id === "students" || t.id === "schedules"
      );
    }

    return tabs;
  };

  toTab = (index, updatedPathName) => {
    const {
      eduProgram: { id: eduProgramId },
      tabs,
    } = this.state;
    const {
      location: {
        query: { tab: prevTabIndex },
        pathname,
      },
    } = this.props;

    if (
      prevTabIndex !== index ||
      (updatedPathName && updatedPathName !== pathname)
    ) {
      this.setState({ tabIndex: index });
      browserHistory.replace(
        `${updatedPathName || pathname}?tab=${tabs[index].id}`
      );

      const currentTab = tabs[index];
      if (currentTab) {
        if (currentTab.id === "locations") {
          if (!this.state.chabadHousesList.length) {
            //get available locations list for Locations tab
            this.getChabadHousesForLocationsList();
          }
        } else if (
          currentTab.id === "schedules" ||
          currentTab.id === "students" ||
          currentTab.id === "reward-requests"
        ) {
          if (!Object.keys(this.state.eduChabadHousesList).length) {
            //get enrolled locations list for Students/Reward-Requests tabs
            this.getEduChabadHousesForListFilters(eduProgramId);
          }
        }
      }
    }
  };

  getEduPrograms = async () => {
    const {
      params: { scheduleId },
    } = this.props;

    const eduProgramsList = await this.getEduProgramsList();

    const eduProgramScheduleId =
      scheduleId ||
      (eduProgramsList &&
        eduProgramsList.length &&
        eduProgramsList[0].programScheduleID);

    if (eduProgramScheduleId) {
      this.getEduProgramDetails(eduProgramScheduleId);
    }
  };

  getEduProgramsList = async () => {
    try {
      this.setState({
        eduProgramsListErrorMessage: "",
        eduProgramsListLoading: true,
      });

      const eduProgramsList = await EduApi.getEduPrograms(this.apiSignal.token);
      this.setState({
        eduProgramsList,
        eduProgramsListLoading: false,
      });

      return eduProgramsList;
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          eduProgramsListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and Learning Initiative details could not be retrieved. Please try again."
          ),
          eduProgramsListLoading: false,
        });
      }
    }
  };

  getEduProgramDetails = async (eduProgramScheduleId) => {
    try {
      this.setState({
        chabadHousesList: [],
        chabadHousesListErrorMessage: "",

        eduChabadHousesList: {},
        eduChabadHousesListErrorMessage: "",

        eduProgramErrorMessage: "",
        eduProgramLoading: true,
      });

      const eduProgram = await EduApi.getEduProgram(
        this.apiSignal.token,
        eduProgramScheduleId,
        true
      );

      this.setState(
        {
          eduProgram,
          eduProgramLoading: false,
        },
        () => {
          const {
            location: {
              query: { tab },
            },
            params: { scheduleId: prevScheduleId },
          } = this.props;

          const pathnameUpdate =
            eduProgramScheduleId !== prevScheduleId
              ? `/edu/${eduProgramScheduleId}`
              : "";

          //reset tabs based on program type
          const tabs = this.getTabs(this.state.eduProgram.type);
          this.setState({ tabs }, () => {
            const tabIndex = tabs
              .map((t) => t.id)
              .indexOf(decodeURIComponent(tab));
            this.toTab(tabIndex >= 0 ? tabIndex : 0, pathnameUpdate);
          });
        }
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        const is404 = (err.response && err.response.status) === 404;
        this.setState({
          eduProgramErrorMessage: is404
            ? "404"
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong and we could not retrieve Learning Initiative details. Please try again."
              ),
          eduProgramLoading: false,
        });
      }
    }
  };

  getEduChabadHousesForListFilters = async (eduProgramId) => {
    try {
      this.setState({
        eduChabadHousesListErrorMessage: "",
        eduChabadHousesListLoading: true,
      });

      const [eduChabadHouseCampuses, eduChabadHouseShluchim] =
        await Promise.all([
          EduApi.getEduChabadHousesCampuses(this.apiSignal.token, eduProgramId),
          EduApi.getEduChabadHousesShluchim(this.apiSignal.token, eduProgramId),
        ]);

      this.setState({
        eduChabadHousesList: {
          campuses: eduChabadHouseCampuses,
          shluchim: eduChabadHouseShluchim,
        },
        eduChabadHousesListLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          eduChabadHousesListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve shluchim or campuses. Please try again."
          ),
          eduChabadHousesListLoading: false,
        });
      }
    }
  };

  getChabadHousesForLocationsList = async () => {
    try {
      this.setState({
        chabadHousesListErrorMessage: "",
        chabadHousesListLoading: true,
      });

      const eligibleChabadHouses = await ChabadHousesApi.getChabadHousesDetails(
        this.apiSignal.token
      );

      this.setState({
        chabadHousesList: eligibleChabadHouses,
        chabadHousesListLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          chabadHousesListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve available locations. Please try again."
          ),
          chabadHousesListLoading: false,
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "approvalStatuses",
        "eduCourseSemesters",
        "eduRewardRequestStatuses",
        "eduRewardTypes",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  refreshEduProgramDetails = async () => {
    try {
      const { id, programScheduleID } = this.state.eduProgram;

      //refresh lists for filters
      this.getEduChabadHousesForListFilters(id);

      //refresh edu program for stats
      const eduProgram = await EduApi.getEduProgram(
        this.apiSignal.token,
        programScheduleID,
        true
      );

      if (eduProgram) {
        this.setState({ eduProgram });
      }
    } catch (err) {
      //no need to display an error if program stats were not successfully refreshed
      console.log("error refreshing program details");
    }
  };

  render() {
    const {
      params: { scheduleId: eduProgramScheduleId },
    } = this.props;

    const {
      authorized,

      chabadHousesList,
      chabadHousesListErrorMessage,
      chabadHousesListLoading,

      eduChabadHousesList,
      eduChabadHousesListErrorMessage,
      eduChabadHousesListLoading,

      eduProgram,
      eduProgramErrorMessage,
      eduProgramLoading,

      eduProgramsList,
      eduProgramsListErrorMessage,
      eduProgramsListLoading,

      systemLists,
      tabs,
      tabIndex,

      viewOnly,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="courses-page page container">
        <ProgramBreadcrumbsHeader scheduleId={eduProgramScheduleId} />
        {eduProgramsListLoading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : eduProgramsListErrorMessage ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{eduProgramsListErrorMessage}</p>
          </div>
        ) : (
          <React.Fragment>
            <div className="flex flex-align-center flex-justify-space mb-32">
              <div>
                <div className="flex fw-900 xl-text mb-16">
                  <Select
                    clearable={false}
                    disabled={eduProgramLoading}
                    onChange={(eduProgram) =>
                      this.getEduProgramDetails(
                        eduProgram ? eduProgram.value : null
                      )
                    }
                    options={
                      eduProgramsList &&
                      eduProgramsList.map((prog) => ({
                        value: prog.programScheduleID,
                        label: prog.name,
                      }))
                    }
                    placeholder="Select Learning Initiative..."
                    style={{ height: "36px", minWidth: "200px" }}
                    value={eduProgramScheduleId}
                  />
                  {eduProgram &&
                    eduProgram.type !== EduProgramTypes.SinaiScholars && (
                      <Link
                        className="btn custom-btn btn-accent-secondary uppercase-text ml-32"
                        style={{ height: "36px" }}
                        to={`/edu/${eduProgram.programScheduleID}/settings`}
                      >
                        Settings
                      </Link>
                    )}
                </div>
              </div>

              {!!eduProgram && <EduProgramStats eduProgram={eduProgram} />}
            </div>
            {eduProgramLoading ? (
              <div>
                <Loader />
              </div>
            ) : eduProgramErrorMessage ? (
              eduProgramErrorMessage === "404" ? (
                <div className="text-center">
                  No Learning Initiative has been configured for this program schedule.
                </div>
              ) : (
                <div className="text-center error-text">
                  <img src="/img/error.svg" alt="error robot" height="240" />
                  <p>{eduProgramErrorMessage}</p>
                </div>
              )
            ) : (
              !!eduProgram && (
                <React.Fragment>
                  <div
                    className="courses-details-tabs flex"
                    style={{ marginLeft: "2px" }}
                  >
                    {tabs.map((tab, index) => (
                      <p
                        key={index}
                        className={`large-text ${index > 0 ? "ml-40" : ""} ${
                          tabIndex === index ? "active" : ""
                        }`}
                        onClick={() => this.toTab(index)}
                      >
                        {tab.name}
                      </p>
                    ))}
                  </div>
                  <div className="card courses-card">
                    {tabs[tabIndex] &&
                      React.createElement(tabs[tabIndex].component, {
                        eduProgram,
                        refreshEduProgram: this.refreshEduProgramDetails, //refresh stats & filter lists when location is added/removed and SS data is imported
                        systemLists,
                        viewOnly,

                        ...(tabs[tabIndex].id === "locations"
                          ? {
                              chabadHouses: {
                                chabadHousesList,
                                chabadHousesListErrorMessage,
                                chabadHousesListLoading,
                              },
                            }
                          : tabs[tabIndex].id === "schedules" ||
                            tabs[tabIndex].id === "students" ||
                            tabs[tabIndex].id === "reward-requests"
                          ? {
                              eduChabadHouses: {
                                eduChabadHousesList,
                                eduChabadHousesListErrorMessage,
                                eduChabadHousesListLoading,
                              },
                            }
                          : {}),
                      })}
                  </div>
                </React.Fragment>
              )
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
