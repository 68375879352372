import React from "react";
import { Link } from "react-router";
import { formatFullName } from "../../../../lib/utils";

export default class ParticipantsTableRecord extends React.PureComponent {
  render() {
    const {
      onRemoveParticipant,
      participant: {
        campusName,
        chabadHouseName,
        regionName,
        shliachFirstName,
        shliachID,
        shliachLastName,
        shluchaFirstName,
        shluchaID,
        shluchaLastName,
      },
    } = this.props;

    return (
      <div className="event-participants-table-row">
        <p>{chabadHouseName}</p>
        <p>
          <Link to={`/shluchim/${shliachID}`}>
            {formatFullName(shliachFirstName, shliachLastName)}
          </Link>
        </p>
        <p>
          <Link to={`/shluchim/${shluchaID}`}>
            {formatFullName(shluchaFirstName, shluchaLastName)}
          </Link>
        </p>
        <p>{campusName}</p>
        <p>{regionName}</p>
        <p>
          <span className="tooltip-container">
            <i
              className="material-icons large-text ml-8 link-text-secondary"
              onClick={onRemoveParticipant}
            >
              delete
            </i>
          </span>
        </p>
      </div>
    );
  }
}
