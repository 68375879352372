import React, { useCallback, useState } from "react";
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Loader from "../common/Loader";
import { formatCurrency, pluralizeText, formatNumber } from "../../lib/utils";
const strokeColors = [
  "#F3893D",
  "#63C674",
  "#EC5150",
  "#6369D1",
  "#53B7E8",
  "#F6AC77",
  "#91D79D",
  "#F18584",
  "#9196DE",
  "#86CCEE",
  "#AA5F2A",
  "#458A51",
  "#A53838",
  "#454992",
  "#3A80A2",
  "#79441E",
  "#B0E2B9",
  "#F5A8A7",
  "#B0B4E7",
  "#A8DAF3",
  "#F8C49E",
  "#31623A",
  "#762828",
  "#313468",
  "#295B74",
];

const defaultQuantityDetails = {
  type: "",
  label: "",
}

export default function YearOverYearComparisonIntervalChart(props) {
  const {
    useCurrency,
    chartTitle,
    endDateDescription,
    data = [],
    daysRemaining,
    errorMessage,
    getData,
    includeAllEvents,
    loading,
    intervals = [],
    eventType,
    eventTitle,
    currencyCode,
    showQuantityOption = false,
    quantityDetails = defaultQuantityDetails,
  } = props;

  const [quantityOptionChecked, setQuantityOptionChecked] = useState(false);

  const getFormattedQuantity = useCallback(
    (quantity) =>
      `(${formatNumber(Math.round(quantity))} ${pluralizeText(quantityDetails.type, quantity)})`
    , [quantityDetails.type]);

  const formatTooltip = useCallback(
    (data, amount, eventID) => {
      const eventData = data.find((d) => d.eventID === eventID);
      const quantity = eventData?.dataPoints?.find(d => d.amount === amount)?.quantity;
      return useCurrency
        ? [
          `${formatCurrency(amount, currencyCode)}
           ${quantityOptionChecked ? getFormattedQuantity(quantity) : ""}`,
          eventData.gregorianYear,
        ]
        : [amount, eventData.year];
    },
    [currencyCode, useCurrency, quantityOptionChecked, getFormattedQuantity]
  );

  const formatTooltipLabel = useCallback(
    (daysDisplay) =>
      daysDisplay === "Last day"
        ? daysDisplay
        : `${daysDisplay} to ${endDateDescription}`,
    [endDateDescription]
  );

  const formatTickY = useCallback(
    (amount) => {
      return useCurrency ? formatCurrency(amount, currencyCode) : amount;
    },
    [currencyCode, useCurrency]
  );

  const formatTickX = useCallback(
    (daysDisplay) =>
      !daysDisplay
        ? ""
        : daysDisplay === "Last day"
          ? daysDisplay
          : daysDisplay.split(" ")[0],
    []
  );

  const formatLegend = useCallback(
    (data, eventID) => {
      const event = data.find((d) => d.eventID === eventID);
      const dataPoint = event.dataPoints[event.dataPoints.length - 1];
      const eventTotal = dataPoint?.amount || 0;
      const quantity = dataPoint?.quantity || 0;

      return `${event.gregorianYear}: ${useCurrency ? (
        `${formatCurrency(eventTotal, currencyCode)}
        ${quantityOptionChecked ? getFormattedQuantity(quantity) : ""}`
      ) : eventTotal
        }`;
    },
    [useCurrency, currencyCode, quantityOptionChecked, getFormattedQuantity]
  );

  const formatData = useCallback((eventsData, intervals) => {
    const dataByInterval = intervals
      .sort((d1, d2) => d2.intValue - d1.intValue)
      .map(({ displayValue, enumValue }) => ({
        daysToEnd: enumValue,
        daysToEndDisplay: displayValue,
      }));

    eventsData.forEach(({ eventID, dataPoints }) => {
      dataByInterval.forEach((dataByDay) => {
        const byDay = dataPoints.find(
          ({ daysToEnd }) => daysToEnd === dataByDay.daysToEnd
        );
        if (byDay) {
          dataByDay[eventID] = byDay.amount;
        }
      });
    });

    return dataByInterval;
  }, []);

  const getLineStroke = (index) =>
    strokeColors[index < strokeColors.length ? index : index % 5] ||
    "rgba(0,0,0,0.87)";

  const formattedData = formatData(data, intervals);
  return (
    <div className="mt-40 graph-outline" style={{ minHeight: "504px" }}>
      <div className="flex flex-justify-space flex-align-center mb-16">
        <div className="large-text fw-700">
          {chartTitle}
          <p className="small-text accent-text">
            By days to {endDateDescription}
            {daysRemaining > 0 && (
              <span className="block fw-500 italic-text">
                {daysRemaining} {pluralizeText("day", daysRemaining)} remaining
                for {eventTitle}
              </span>
            )}
          </p>
        </div>
        <div>
          <div className="flex flex-align-center custom-checkbox-container raffle-metrics-checkbox">
            <input
              className="custom-checkbox"
              checked={includeAllEvents}
              id="all-events"
              onChange={(e) => getData(e.target.checked)}
              type="checkbox"
            />
            <label className="flex-align-center" htmlFor="all-events">
              Include all {eventType} years
            </label>
          </div>
          {showQuantityOption && (
            <div className="flex flex-align-center custom-checkbox-container raffle-metrics-checkbox mt-12">
              <input
                className="custom-checkbox"
                checked={quantityOptionChecked}
                id="show-quantity"
                onChange={(e) => setQuantityOptionChecked(e.target.checked)}
                type="checkbox"
              />
              <label className="flex-align-center" htmlFor="show-quantity">
                {quantityDetails.label}
              </label>
            </div>
          )}
        </div>
      </div>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : errorMessage ? (
        <p className="error-text mt-8">{errorMessage}</p>
      ) : !data.length ? (
        <span>No data found for comparison</span>
      ) : (
        <LineChart
          data={formattedData}
          height={400}
          width={800}
          margin={{ left: 20, right: 20 }}
        >
          <CartesianGrid stroke="#eee" />

          {data.map(({ eventID }, index) => (
            <Line
              dataKey={eventID}
              key={eventID}
              type="monotone"
              stroke={getLineStroke(index)}
            />
          ))}

          <Tooltip
            formatter={(...passProps) => formatTooltip(data, ...passProps)}
            itemSorter={() => 1} //maintain order of data
            labelFormatter={formatTooltipLabel}
            {...(includeAllEvents
              ? {
                position: { y: data.length * -10 }, //position to align bottom of tooltip with chart bottom
                wrapperStyle: { fontSize: 10, paddingBottom: 16 },
              }
              : {})}
          />

          <Legend
            formatter={(...passProps) => formatLegend(data, ...passProps)}
            verticalAlign="bottom"
            wrapperStyle={{ paddingBottom: 24, bottom: -16 }}
          />

          <XAxis dataKey="daysToEndDisplay" tickFormatter={formatTickX}>
            <Label value="Days left" offset={-2} position="insideBottom" />
          </XAxis>

          <YAxis tickFormatter={formatTickY} type="number" />
        </LineChart>
      )}
    </div>
  );
}
