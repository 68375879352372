import React from "react";
import { Link } from "react-router";

// import { OrganizationCategory } from "../../../../models/Organization";
import OrganizationCategoryList from "./OrganizationCategoryList";

// interface OrganizationCategoryListExtProps {
//   categories: Array<OrganizationCategory>;
// }

const OrganizationCategoryListPage = ({ categories }) => {
  return (
    <div>
      <h3>Organization Categories</h3>
      <OrganizationCategoryList categories={categories} />
      <div className="row">
        <div className="col-sm-10" />
        <div className="col-sm-2">
          <Link
            to={"/config/orgcategories/new"}
            className="btn btn-primary btn-sm"
          >
            Add New
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrganizationCategoryListPage;
