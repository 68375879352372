import { asEnum } from "../lib";
import { EmptyAddress } from "./Contact";

export const CategoryTypes = asEnum({
  Partner: 1,
  Vendor: 2,
  Venue: 3,
});

/** @typedef {object} OrganizationCategory
 * @property {number} [id]
 * @property {string} name
 * @property {number} type CategoryTypes
 */

/** @typedef {object} Organization
 * @property {number} [id]
 * @property {string} name
 * @property {string} [description]
 * @property {OrganizationCategory} category
 * @property {CategoryTypes} [organizationCategoryType]
 * @property {import('./Contact').Address} [address]
 * @property {string} [website]
 * @property {number} [roomSpace]
 * @property {number} [meetingSpace]
 * @property {string} [notes]
 * @property {import('./Contact').Contact[]} contacts
 */

/** @typedef {object} OrganizationFilter
 * @property {string} [name]
 * @property {CategoryTypes} [organizationCategoryType]
 * @property {number} [categoryId]
 * @property {string} countries
 * @property {string} states
 */

/** @returns {OrganizationFilter} */
export function EmptyOrganizationFilter() {
  return { name: "", organizationCategoryType: 0, countries: "", states: "" };
}
/** @returns {OrganizationCategory} */
export function EmptyOrganizationCategory() {
  return { name: "", type: CategoryTypes.Vendor, id: -1 };
}
/** @returns {Organization} */
export function EmptyOrganization() {
  return {
    name: "",
    category: EmptyOrganizationCategory(),
    contacts: [],
    address: EmptyAddress(),
    id: -1,
  };
}
