import AuthClient from "./AuthClient";
import queryString from "query-string";
import { RafflePaymentTypes } from "../../components/Raffle/RaffleConsts";

const RafflesApi = {
  getRaffleEvents: async (cancelToken) => {
    const response = await AuthClient.get("raffleEvents", { cancelToken });
    return response.data.payload || [];
  },
  getHistory: async (cancelToken) => {
    const response = await AuthClient.get("raffleEvents/history", {
      cancelToken,
    });
    return response.data.payload || [];
  },
  getCurrentRaffle: async (cancelToken, includeRaffleStats = false) => {
    const response = await AuthClient.get(
      `raffleEvents/current?onlyRaffleData=${!includeRaffleStats}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getRaffleMetrics: async (cancelToken, raffleEventId) => {
    const response = await AuthClient.get(
      `raffleEvents/metrics/${raffleEventId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getRaffleSalesOverTime: async (cancelToken, raffleEventID, timeFrame) => {
    const params = {
      rangeType: timeFrame,
      raffleEventID,
    };

    const response = await AuthClient.get(
      `raffleEvents/salesOverTime?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getRaffleSalesOverTimeComparison: async (
    cancelToken,
    eventID,
    includeAllEvents
  ) => {
    const params = {
      includeAllEvents,
      eventID,
    };

    const response = await AuthClient.get(
      `raffleEvents/salesOverTime/yearToYearComparison?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getCociEnrollmentId: async (raffleEventId, cancelToken) => {
    const response = await AuthClient.get(
      `raffleEnrollments/coci?raffleEventId=${raffleEventId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getRaffle: async (cancelToken, scheduleId, returnBlank) => {
    const response = await AuthClient.get(
      `raffleEvents/${scheduleId}?returnBlank=${!!returnBlank}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  updateRaffle: async (cancelToken, raffle) => {
    const response = await AuthClient.post("raffleEvents", raffle, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getOrders: async (
    cancelToken,
    raffleEventId,
    page,
    results,
    sortBy,
    filters,
    enrollmentId,
    exportToCSV,
    exportAsTickets
  ) => {
    let cashTypeID;
    let paymentType = filters?.paymentType;
    //The payment method filter combines payment types and cash types into one selection - splitting it out here so it can be passed to the API as 2 separate fields
    if (paymentType?.includes("cash_")) {
      cashTypeID = paymentType.replace("cash_", "");
      paymentType = RafflePaymentTypes.Cash;
    }

    const params = {
      asTickets: exportAsTickets,
      enrollmentId,
      export: exportToCSV,
      page,
      raffleEventId,
      results,
      sortByOption: sortBy,
      text: filters.keyword,
      paymentType,
      cashTypeID,
      referrerID: filters.referrerID,
      association: filters.association,
    };

    const response = await AuthClient.get(
      `raffleOrders?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      orders: response.data.payload.results || [],
      totalOrders: response.data.payload.numberOfRows || 0,
    };
  },
  createOrder: async (cancelToken, order) => {
    order.timezoneOffset = new Date().getTimezoneOffset();

    const response = await AuthClient.post("raffleOrders", order, {
      cancelToken,
    });
    return response.data || {};
  },
  getOrderDetails: async (cancelToken, orderId) => {
    const response = await AuthClient.get(`raffleOrders/${orderId}`, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  updateOrderDetails: async (cancelToken, order) => {
    const response = await AuthClient.post("raffleOrders", order, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  bulkImportOrders: async (
    cancelToken,
    enrollmentId,
    bulkOrders,
    onUploadProgress
  ) => {
    const params = {
      enrollmentId,
      timezoneOffset: new Date().getTimezoneOffset(),
    };

    const formData = new FormData();
    formData.append("file", bulkOrders);

    await AuthClient.post(
      `raffleOrders/bulkImport?${queryString.stringify(params)}`,
      formData,
      {
        cancelToken,
        "Content-Type": "multipart/form-data",
        onUploadProgress,
      }
    );
  },
  refundOrder: async (cancelToken, orderId, quantity) => {
    await AuthClient.post(
      "raffleOrders/issueRefund",
      {
        orderID: orderId,
        ticketsToRefund: quantity,
      },
      {
        cancelToken,
      }
    );
  },
  resendOrderEmail: async (cancelToken, id) => {
    await AuthClient.post(`raffleOrders/${id}/resendEmail`, null, {
      cancelToken,
    });
  },
  getEnrollment: async (cancelToken, enrollmentId) => {
    const response = await AuthClient.get(`raffleEnrollments/${enrollmentId}`, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getEnrollments: async (
    cancelToken,
    raffleEventId,
    page,
    results,
    sortBy,
    filters,
    exportType
  ) => {
    //filters: keyword, enrolled, onlyWithTickets
    const params = {
      exportType,
      onlyWithTickets: filters.onlyWithTickets,
      page,
      raffleEventId,
      results,
      sortByOption: sortBy,
      status: filters.enrolled ? "Enrolled" : "UnEnrolled",
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `raffleEnrollments?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return {
      enrollments: response.data.payload.results || [],
      totalEnrollments: response.data.payload.numberOfRows || 0,
    };
  },
  getEnrollmentsSellerList: async (cancelToken) => {
    const response = await AuthClient.get(
      "raffleEnrollments/public/sellerList",
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getEnrollmentMetrics: async (cancelToken, enrollmentId) => {
    const response = await AuthClient.get(
      `raffleEnrollments/metrics/${enrollmentId}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getEnrollmentSalesOverTime: async (
    cancelToken,
    enrollmentId,
    timeFrame,
    raffleEventID
  ) => {
    const params = {
      rangeType: timeFrame,
      raffleEventID,
    };

    const response = await AuthClient.get(
      `raffleEnrollments/salesOverTime/${enrollmentId}?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getEnrollmentSalesOverTimeComparison: async (
    cancelToken,
    enrollmentId,
    includeAllEvents,
    eventID
  ) => {
    const params = {
      includeAllEvents,
      eventID,
    };

    const response = await AuthClient.get(
      `raffleEnrollments/salesOverTime/yearToYearComparison/${enrollmentId}?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  updateEnrollment: async (cancelToken, enrollment) => {
    const response = await AuthClient.post("raffleEnrollments", enrollment, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getRaffleSellers: async (cancelToken, raffleEventId) => {
    const response = await AuthClient.get(
      `raffleEnrollments?basicList=true&raffleEventID=${raffleEventId}`,
      { cancelToken }
    );
    return response.data.payload ? response.data.payload.results : [];
  },
  getEnrollmentReferrers: async (cancelToken, enrollmentId) => {
    const response = await AuthClient.get(
      `raffleEnrollments/referrers/${enrollmentId}`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  getStudentBasicData: async (studentId) => {
    const response = {
      success: false,
      data: {},
      error: "",
    };
    const { data, status } = await AuthClient.get(
      `/Students/${studentId}/basic`
    ).catch((e) => {
      return e;
    });
    if (status !== 200) {
      response.error = status !== 500 ? data?.messages[0] : "";
    } else {
      response.success = true;
      response.data = data;
    }
    return response;
  },
  getWinners: async (raffleID) => {
    const { status, data } = await AuthClient.get(
      `raffleEvents/${raffleID}/winners`
    ).catch((e) => ({ status: e?.response?.status }));
    return {
      success: status === 200,
      data: data?.payload || [],
    };
  },
  updateWinners: async (cancelToken, raffleID, winners) => {
    const { status } = await AuthClient.post(
      `raffleEvents/${raffleID}/winners`,
      winners,
      { cancelToken }
    ).catch((e) => ({ status: e?.response?.status }));
    return { success: status === 200 };
  },
};

export default RafflesApi;
