import React from "react";
import debounce from "lodash.debounce";

export default class ParticipantsTableSearch extends React.PureComponent {
  state = {
    keyword: "",
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onSearchDebounce : this.onSearch,
    );
  };

  onSearch = () => {
    this.props.applySearch(this.state);
  };

  onSearchDebounce = debounce(this.onSearch, 500);

  render() {
    const { keyword } = this.state;

    return (
      <div className="search-input">
        <input
          type="text"
          placeholder="Search"
          name="keyword"
          onChange={(event) =>
            this.onChange(event.target.name, event.target.value, true)
          }
          value={keyword}
        />
        <i className="material-icons accent-text-secondary flex flex-align-center">
          search
        </i>
      </div>
    );
  }
}
