import React, { useCallback, useEffect, useRef, useState } from "react";
import ConfirmationModal from "../../../../../shared/ConfirmationModal";
import Loader from "../../../../../common/Loader";

import TripEventsApi from "../../../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../../../lib/coc-common-scripts";
import axios from "axios";

function RegistrationNotes(props) {
  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const {
    activeRegistrationNote,
    readOnlyAccess,
    registration: { id: registrationId },
    registrationNotes,
    setActiveRegistrationNote,
    setRegistrationNotes,
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [deleteNoteId, setDeleteNoteId] = useState();

  const retrieveRegistrationNotes = useCallback(async () => {
    setLoading(true);

    try {
      const notes = await TripEventsApi.getTripRegistrationNotes(
        apiSignalRef.current.token,
        registrationId
      );
      setRegistrationNotes(notes || []);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setErrorMessage(ApiCallErrorMessageHandler(err));
      }
    }

    setLoading(false);
  }, [registrationId, setRegistrationNotes]);

  useEffect(() => {
    // retrieve notes on mount only if registrationNotes were not previously retrieved
    if (!registrationNotes) {
      retrieveRegistrationNotes();
    }
    // eslint-disable-next-line
  }, [retrieveRegistrationNotes]);

  const onAddEditNote = useCallback(
    (note) => {
      setActiveRegistrationNote(note || { text: "" });

      if (!note) {
        setTimeout(() => {
          // when adding new, scroll save/cancel buttons into view
          const noteBtns = document.getElementById("note-btns");
          if (noteBtns) noteBtns.scrollIntoView();
        }, 0);
      }
    },
    [setActiveRegistrationNote]
  );

  const onSubmitNoteAction = useCallback(async () => {
    setErrorMessage("");
    setLoading(true);

    try {
      if (deleteNoteId) {
        await TripEventsApi.deleteTripRegistrationNote(
          apiSignalRef.current.token,
          registrationId,
          deleteNoteId
        );

        setDeleteNoteId(undefined);
      } else {
        await TripEventsApi.submitTripRegistrationNote(
          apiSignalRef.current.token,
          registrationId,
          activeRegistrationNote
        );

        setActiveRegistrationNote(undefined);
      }

      // refresh notes
      await retrieveRegistrationNotes();
    } catch (err) {
      if (!axios.isCancel(err)) {
        setErrorMessage(ApiCallErrorMessageHandler(err));
      }
    }

    setLoading(false);
  }, [
    activeRegistrationNote,
    deleteNoteId,
    registrationId,
    retrieveRegistrationNotes,
    setActiveRegistrationNote,
  ]);

  const noteInput = activeRegistrationNote && (
    <div className="full-width">
      <textarea
        className="custom-input full-width"
        style={{ resize: "none", height: "144px" }}
        onChange={(e) =>
          setActiveRegistrationNote({
            ...activeRegistrationNote,
            text: e.target.value,
          })
        }
        value={activeRegistrationNote.text}
      />
      <div
        className="flex flex-align-center flex-justify-end mt-16"
        id="note-btns"
      >
        <button
          className="btn custom-btn btn-light btn-medium mr-16 uppercase-text"
          disabled={loading}
          onClick={() => setActiveRegistrationNote(undefined)}
        >
          Cancel
        </button>
        <button
          className="btn custom-btn btn-accent btn-medium uppercase-text"
          disabled={!activeRegistrationNote.text || loading}
          onClick={onSubmitNoteAction}
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
      {errorMessage && (
        <div className="flex flex-justify-end error-text">{errorMessage}</div>
      )}
    </div>
  );

  return (
    <div className="trip-student-detail-tab">
      <div className="xl-text fw-700 mb-24">Notes</div>
      {!registrationNotes ? (
        loading ? (
          <Loader />
        ) : (
          errorMessage && <p className="error-text">{errorMessage}</p>
        )
      ) : (
        <>
          <div className="student-detail-section" style={{ padding: 0 }}>
            {registrationNotes.map((note) => {
              const isEditing =
                activeRegistrationNote && activeRegistrationNote.id === note.id;
              return (
                <div className="flex flex-justify-space mb-16" key={note.id}>
                  {isEditing ? noteInput : <p>{note.text}</p>}
                  {!readOnlyAccess && !isEditing && (
                    <div className="flex">
                      <i
                        className="material-icons link-text mr-16"
                        onClick={() => onAddEditNote(note)}
                      >
                        edit
                      </i>
                      <i
                        className="material-icons link-text"
                        onClick={() => setDeleteNoteId(note.id)}
                      >
                        delete
                      </i>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          {!readOnlyAccess && (
            <div className="mt-24">
              {activeRegistrationNote && !activeRegistrationNote.id ? (
                noteInput
              ) : (
                <button
                  className="btn custom-btn btn-accent btn-medium uppercase-text flex flex-align-center"
                  onClick={() => onAddEditNote()}
                >
                  <i className="material-icons medium-text mr-4">add</i> note
                </button>
              )}
            </div>
          )}
        </>
      )}
      <ConfirmationModal
        cancel={() => setDeleteNoteId(undefined)}
        confirm={onSubmitNoteAction}
        errorMessage={errorMessage}
        loading={loading}
        message="This action cannot be undone."
        show={!!deleteNoteId}
        title="Delete note?"
      />
    </div>
  );
}

export default React.memo(RegistrationNotes);
