import React from "react";
import { browserHistory } from "react-router";
import debounce from "lodash.debounce";
import queryString from "query-string";

export default class SuggestionsTableFilters extends React.PureComponent {
  state = {
    keyword: "",
    hasActiveGrant: false,
  };

  componentDidMount(props) {
    const {
      location: {
        query: { search, hasActiveGrant },
      },
    } = this.props;
    this.setState({
      keyword: search,
      hasActiveGrant: hasActiveGrant === "true",
    });
  }

  onChange = (name, value, debounce = true) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => {
    const { keyword, hasActiveGrant } = this.state;
    const {
      location: { query, pathname },
    } = this.props;
    browserHistory.push(
      `${pathname}?${queryString.stringify({
        ...query,
        search: keyword || undefined,
        hasActiveGrant,
      })}`,
    );
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { keyword, hasActiveGrant } = this.state;
    return (
      <div className="flex">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search student name, email or campus"
            name="keyword"
            onChange={(e) => this.onChange(e.target.name, e.target.value)}
            value={keyword || ""}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        <div className="flex flex-align-center ml-8">
          <input
            type="checkbox"
            id="hasActiveGrant"
            className="styled-checkbox"
            name="hasActiveGrant"
            value={hasActiveGrant}
            checked={hasActiveGrant}
            onChange={(e) =>
              this.onChange(e.target.name, e.target.checked, false)
            }
          />
          <label htmlFor="hasActiveGrant"> Only Active for Lamplighters</label>
        </div>
      </div>
    );
  }
}
