import React from "react";
import Select from "react-select";

// import { ReactSelectOption, CountryHlp, StateHlp } from "../../../models/Common";
// import { SchoolFilter } from "../../../models/School";
import { UpdateDOMProperty } from "../../../lib/coc-common-components/Scripts";

// interface SchoolFilterProps {
//   filterState: SchoolFilter;
//   countryList: Array<CountryHlp>;
//   stateList: Array<StateHlp>;
//   handleFiltersChanged: (newFilterState: SchoolFilter) => void;
// }

export const SchoolFilterUI = ({
  filterState,
  countryList,
  stateList,
  handleFiltersChanged,
}) => {
  /** @param {React.FormEvent<HTMLInputElement>} evt */
  const handleDOMDataChange = (evt) =>
    handleFiltersChanged(UpdateDOMProperty(evt, filterState));
  /** @param {React.FormEvent<HTMLInputElement>} evt */

  const updateFilterAssociation = (evt) => {
    const newFilterState = {
      ...filterState,
      association: evt.currentTarget.value,
    };
    handleFiltersChanged(newFilterState);
  };

  const updateFilterStatus = (evt) => {
    const newFilterState = {
      ...filterState,
      status: evt.currentTarget.value,
    };

    handleFiltersChanged(newFilterState);
  };

  /** @param {string} countries */
  const updateFilterCountries = (countries) => {
    const newStates =
      countries === "" || countries === "USA" ? filterState.states : "";
    const newFilterState = { ...filterState, countries, states: newStates };
    handleFiltersChanged(newFilterState);
  };
  /** @param {string} states */
  const updateFilterStates = (states) => {
    const newFilterState = { ...filterState, states };
    handleFiltersChanged(newFilterState);
  };
  /** @param {CountryHlp | StateHlp} item
   * @returns {ReactSelectOption}
   */
  const itemToOption = (item) => ({ value: item.id, label: item.name });

  return (
    <div>
      <h3>School Filters</h3>
      <div className="row">
        <fieldset>
          <label className="form-label" htmlFor="schoolName">
            Name
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={filterState.name || ""}
            id="schoolName"
            onChange={handleDOMDataChange}
          />
        </fieldset>
      </div>
      <div className="row">
        <fieldset>
          <label className="form-label" htmlFor="country">
            Country
          </label>
          <Select
            name="country"
            options={countryList.map(itemToOption)}
            onChange={updateFilterCountries}
            multi={true}
            simpleValue={true}
            value={filterState.countries}
            separator={","}
          />
        </fieldset>
      </div>
      <div className="row">
        <fieldset>
          <label className="form-label" htmlFor="state">
            State
          </label>
          <Select
            name="state"
            options={stateList.map(itemToOption)}
            onChange={updateFilterStates}
            multi={true}
            simpleValue={true}
            value={filterState.states}
            closeOnSelect={false}
            disabled={
              filterState.countries !== "" && filterState.countries !== "USA"
            }
          />
        </fieldset>
      </div>
      <div className="row">
        <fieldset>
          <label className="form-label" htmlFor="association">
            Association
          </label>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="association"
                value="All"
                checked={filterState.association === "All"}
                onChange={updateFilterAssociation}
              />
              Full-service and Limited-service
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="association"
                value="PartTime"
                checked={filterState.association === "PartTime"}
                onChange={updateFilterAssociation}
              />
              Limited-service only
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="association"
                value="FullTime"
                checked={filterState.association === "FullTime"}
                onChange={updateFilterAssociation}
              />
              Full-service only
            </label>
          </div>
        </fieldset>
      </div>
      <div className="row">
        <fieldset>
          <label className="form-label" htmlFor="status">
            Status
          </label>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="status"
                value="ActiveOnly"
                checked={filterState.status === "ActiveOnly"}
                onChange={updateFilterStatus}
              />
              Active Only
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                name="status"
                value="All"
                checked={filterState.status === "All"}
                onChange={updateFilterStatus}
              />
              Active and Inactive
            </label>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
