import React from "react";
import PaginatedTable from "../../../shared/PaginatedTable";
import ShluchimActions from "./ShluchimActions";
import ShluchimTableFilters from "./ShluchimTableFilters";
import ShluchimTableHeader from "./ShluchimTableHeader";
import ShluchimTableRecord from "./ShluchimTableRecord";

import TripEventsApi from "../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import { TripTypes } from "../../TripEventConsts";
import axios from "axios";
import moment from "moment";

export default class TripShluchim extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
      enrolled: true,
    },
    loading: false,
    page: 1,
    results: 12,
    selectedShluchim: {
      all: false,
      allExcept: [],
      selection: [],
    },
    shluchim: [],
    sortBy: "shliachLastName",
    success: true,
    totalShluchim: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterShluchim = (filters, sortBy = "shliachLastName") => {
    this.setState({ filters, sortBy }, () => {
      this.getShluchim();

      // clear selection when applying filters
      const { selectedShluchim } = this.state;
      if (selectedShluchim.all || selectedShluchim.selection.length) {
        this.clearSelectedShluchim();
      }
    });
  };

  getShluchim = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        TripEventsApi.getTripEnrollments(
          this.apiSignal.token,
          this.props.tripEvent.id,
          page,
          results,
          sortBy,
          this.state.filters
        )
          .then(({ enrollments, totalEnrollments }) => {
            this.setState({
              loading: false,
              shluchim: enrollments,
              totalShluchim: totalEnrollments,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                shluchim: [],
                success: false,
                totalShluchim: 0,
              });
            }
          });
      }
    );
  };

  getShluchimForExport = async (exportType) => {
    const { sortBy, filters } = this.state;

    const shluchimForExport = await TripEventsApi.getTripEnrollments(
      this.apiSignal.token,
      this.props.tripEvent.id,
      undefined,
      undefined,
      sortBy,
      filters,
      exportType
    );

    return shluchimForExport.enrollments;
  };

  getEnrollmentsReport = async (exportType) => {
    const { filters } = this.state;
    return await TripEventsApi.getTripReport(
      this.apiSignal.token,
      this.props.tripEvent.id,
      exportType,
      filters.keyword
    );
  };

  getShluchimSelection = async () => {
    const { all, allExcept, selection } = this.state.selectedShluchim;

    if (selection.length) {
      return selection;
    }

    if (all) {
      try {
        const { enrollments } = await TripEventsApi.getTripEnrollments(
          this.apiSignal.token,
          this.props.tripEvent.id,
          1,
          this.state.totalShluchim, // get all shluchim results
          "shliachLastName",
          this.state.filters
        );

        if (allExcept) {
          return enrollments.filter(
            (e) => !allExcept.some((s) => s.chabadHouseID === e.chabadHouseID)
          );
        }

        return enrollments;
      } catch (err) {
        return null;
      }
    }
  };

  toggleSelectShliach = (shliach) => {
    const { selectedShluchim } = this.state;
    const { all, allExcept, selection } = selectedShluchim;

    if (all) {
      const _allExcept = allExcept.some(
        (s) => s.chabadHouseID === shliach.chabadHouseID
      )
        ? allExcept.filter((s) => s.chabadHouseID !== shliach.chabadHouseID)
        : [...allExcept, shliach];
      this.setState({
        selectedShluchim: {
          ...selectedShluchim,
          allExcept: _allExcept,
        },
      });
    } else {
      const _selection = selection.some(
        (s) => s.chabadHouseID === shliach.chabadHouseID
      )
        ? selection.filter((s) => s.chabadHouseID !== shliach.chabadHouseID)
        : [...selection, shliach];
      this.setState({
        selectedShluchim: {
          ...selectedShluchim,
          selection: _selection,
        },
      });
    }
  };

  toggleSelectAllShluchim = () => {
    const { all, allExcept } = this.state.selectedShluchim;
    this.setState({
      selectedShluchim: {
        all: !(all && !allExcept.length),
        allExcept: [],
        selection: [],
      },
    });
  };

  clearSelectedShluchim = () => {
    this.setState({
      selectedShluchim: {
        all: false,
        allExcept: [],
        selection: [],
      },
    });
  };

  refreshShluchim = () => {
    this.getShluchim();
    this.clearSelectedShluchim();
  };

  sortOptions = [
    { id: "shliachLastName", name: "Shliach Name" },
    {
      id: "studentsRegistered",
      name: "Students Accepted",
      enrolledOnly: true,
    },
  ];

  render() {
    const { mobileMode, tripEvent } = this.props;

    const {
      filters,
      loading,
      shluchim,
      page,
      results,
      selectedShluchim,
      sortBy,
      success,
      totalShluchim,
    } = this.state;

    const readOnlyAccess = !AuthService.UserHasClaim(
      PermissionClaims.TripDataEdit
    );

    const allowBulkEnrollment =
      !readOnlyAccess && tripEvent.isTravelTrip && !filters.enrolled;

    const eventFeesPaid = moment().isAfter(
      moment(tripEvent.tripEndDate).add(2, "days")
    );

    return (
      <>
        <div className="flex flex-justify-space mb-24">
          <p className="xxl-text fw-500">All Shluchim</p>
          <ShluchimActions
            enrolledView={filters.enrolled}
            eventFeesPaid={eventFeesPaid}
            getEnrollmentsReport={this.getEnrollmentsReport}
            getShluchimForExport={this.getShluchimForExport}
            getShluchimSelection={this.getShluchimSelection}
            refreshShluchim={this.refreshShluchim}
            selectedShluchim={selectedShluchim}
            showBulkEnrollmentButton={allowBulkEnrollment}
            tripEvent={tripEvent}
          />
        </div>

        <div className="trip-shluchim-table">
          <PaginatedTable
            filterComponent={
              <ShluchimTableFilters
                applyFilters={this.filterShluchim}
                isJewishUTrip={tripEvent.type === TripTypes.JewishU}
                isTravelTrip={tripEvent.isTravelTrip}
                sortBy={sortBy}
                sortOptions={this.sortOptions}
              />
            }
            loading={loading}
            loadData={this.getShluchim}
            mobileMode={mobileMode}
            page={page}
            records={shluchim}
            renderHeader={() => (
              <ShluchimTableHeader
                eventFeesPaid={eventFeesPaid}
                isTravelTrip={tripEvent.isTravelTrip}
                selectAllDisabledReason={
                  !tripEvent.isPublished ||
                  !tripEvent.hasStartedShluchimEnrollment
                    ? "Enrollment has not yet opened"
                    : ""
                }
                selectedShluchim={selectedShluchim}
                showSelectAllCheckbox={allowBulkEnrollment}
                toggleSelectAllShluchim={this.toggleSelectAllShluchim}
                totalShluchim={totalShluchim}
              />
            )}
            renderRow={(shliachEnrollment, index) => {
              const selected = selectedShluchim.all
                ? !selectedShluchim.allExcept.some(
                    (s) => s.chabadHouseID === shliachEnrollment.chabadHouseID
                  )
                : selectedShluchim.selection.some(
                    (s) => s.chabadHouseID === shliachEnrollment.chabadHouseID
                  );
              return (
                <ShluchimTableRecord
                  enrolledView={filters.enrolled}
                  key={index}
                  isTravelTrip={tripEvent.isTravelTrip}
                  scheduleId={tripEvent.programScheduleID}
                  selected={selected}
                  selectionDisabledReason={
                    !tripEvent.isPublished ||
                    !tripEvent.hasStartedShluchimEnrollment
                      ? "Enrollment has not yet opened"
                      : ""
                  }
                  shliachEnrollment={shliachEnrollment}
                  showSelectCheckbox={allowBulkEnrollment}
                  reloadTableData={this.getShluchim}
                  toggleSelectShliach={() =>
                    this.toggleSelectShliach(shliachEnrollment)
                  }
                />
              );
            }}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            sortOptions={filters.enrolled ? this.sortOptions : null}
            success={success}
            totalCount={totalShluchim}
          />
        </div>
      </>
    );
  }
}
