import React, { useState } from "react";
import { formatFullName, pluralizeText } from "../../../../lib/utils";
import { Button, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

function CheckInShliachChaperoneDetails(props) {
  const { shluchim, chaperones } = props;

  const [expandShluchim, setExpandShluchim] = useState(false);
  const [expandChaperones, setExpandChaperones] = useState(false);

  return (
    <div className="checkin-shliach-chap-details">
      {Boolean(shluchim.length) && (
        <div className="student-checkin-details">
          <Button
            onClick={() => setExpandShluchim(!expandShluchim)}
            disableRipple
            className="flex"
            size="large"
            variant="text"
          >
            {shluchim.length}{" "}
            {pluralizeText("shliach", shluchim.length, "shluchim")}
            {expandShluchim ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </Button>

          <Collapse
            in={expandShluchim}
            className="expanded-list-wrapper accent-text"
          >
            {shluchim.map((s) => (
              <p key={s.id}>{formatFullName(s.firstName, s.lastName)}</p>
            ))}
          </Collapse>
        </div>
      )}

      {Boolean(chaperones.length) && (
        <div className="student-checkin-details">
          <Button
            onClick={() => setExpandChaperones(!expandChaperones)}
            disableRipple
            className="flex"
            size="large"
            variant="text"
          >
            {chaperones.length}{" "}
            {pluralizeText("chaperone", chaperones.length, "chaperones")}
            {expandChaperones ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </Button>

          <Collapse
            in={expandChaperones}
            className="expanded-list-wrapper accent-text"
          >
            {chaperones.map((c) => (
              <p key={c.id}>{formatFullName(c.firstName, c.lastName)}</p>
            ))}
          </Collapse>
        </div>
      )}
    </div>
  );
}

export default React.memo(CheckInShliachChaperoneDetails);
