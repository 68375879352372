import React from "react";

export default class Conditions extends React.PureComponent {
  render() {
    const {
      getErrorClassName,
      grant,
      grantIndex,
      onChange,
      readOnly,
      submitAttempted,
    } = this.props;
    return (
      <div className="mb-16">
        <p className="small-text fw-700 mb-8">Conditions</p>
        <div className="flex flex-align-center mb-8">
          <p
            className="flex-align-center small-text accent-text mr-16"
            style={{ width: "180px" }}
          >
            Interaction Date Range
          </p>
          <p className="small-text mr-16">At Least</p>
          <input
            className={getErrorClassName(
              "custom-input",
              submitAttempted &&
                ((!grant.minInteractionsRangeWeeks &&
                  grant.minInteractionsRangeWeeks !== 0) ||
                  grant.minInteractionsRangeWeeks < 0),
            )}
            disabled={readOnly}
            min="0"
            name="minInteractionsRangeWeeks"
            onChange={(e) => onChange(e.target.name, e.target.value)}
            style={{ width: "60px" }}
            type="number"
            value={grant.minInteractionsRangeWeeks}
          />
          <p className="small-text ml-16">Week(s)</p>
        </div>
        <div className="flex flex-align-center mb-8">
          <div className="flex custom-checkbox-container mr-16">
            <input
              className="custom-checkbox"
              checked={grant.hasStudentAgeCondition}
              disabled={readOnly}
              id={`student-age-checkbox-${grantIndex}`}
              onChange={() =>
                onChange(
                  "hasStudentAgeCondition",
                  !grant.hasStudentAgeCondition,
                  grant.hasStudentAgeCondition,
                )
              }
              type="checkbox"
            />
            <label
              className="flex-align-center small-text accent-text"
              htmlFor={`student-age-checkbox-${grantIndex}`}
            >
              Student Age Range
            </label>
          </div>
          <p className="small-text mr-16">Between</p>
          <input
            className="custom-input"
            disabled={!grant.hasStudentAgeCondition || readOnly}
            max={grant.maxStudentAge || ""}
            min="0"
            name="minStudentAge"
            onChange={(e) => onChange(e.target.name, e.target.value)}
            style={{ width: "60px" }}
            type="number"
            value={grant.minStudentAge}
          />
          <p className="small-text ml-16 mr-16">and</p>
          <input
            className="custom-input"
            disabled={!grant.hasStudentAgeCondition || readOnly}
            min={grant.minStudentAge || "0"}
            name="maxStudentAge"
            onChange={(e) => onChange(e.target.name, e.target.value)}
            style={{ width: "60px" }}
            type="number"
            value={grant.maxStudentAge}
          />
        </div>
      </div>
    );
  }
}
