export const ArrayOfEnumCodes = (enumType) =>
  Object.keys(enumType).filter((k) => typeof enumType[k] !== "number");
export const ArrayOfEnumNames = (enumType) =>
  Object.keys(enumType).filter((k) => typeof enumType[k] === "number");
/** @param {object} enumType
 * @param {string} keyName
 * @param {string} valueName
 */
export const EnumToArrayOfObjects = (
  enumType,
  keyName = "code",
  valueName = "value",
) =>
  Object.keys(enumType)
    .filter((k) => typeof enumType[k] !== "number")
    .map((k) => ({ [keyName]: k, [valueName]: enumType[k] }));
