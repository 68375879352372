import React from "react";
import { browserHistory } from "react-router";

import Loader from "../common/Loader";
import EnrollmentActions from "./enrollment/EnrollmentActions";
import EnrollmentDetails from "./enrollment/EnrollmentDetails";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";

import LifeInsuranceApi from "../../services/resources/LifeInsuranceApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _set from "lodash.set";

export default class LifeInsuranceEnrollmentPage extends React.PureComponent {
  state = {
    authorized: true,

    enrollment: null,
    errorMessage: "",
    loading: false,
  };

  enrollmentStatuses = {
    Active: "Active",
    FailedRenewal: "FailedRenewal",
    PendingActivation: "PendingActivation",
    PendingTermination: "PendingTermination",
    Terminated: "Terminated",
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.LIFullEdit)) {
      this.setState({ authorized: false });
    }

    this.getLifeInsuranceEnrollmentDetails();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getLifeInsuranceEnrollmentDetails = async () => {
    try {
      this.setState({
        errorMessage: "",
        loading: true,
      });

      const {
        params: { enrollmentId, scheduleId },
      } = this.props;

      const lifeInsurancePolicy = await LifeInsuranceApi.getLifeInsurancePolicy(
        this.apiSignal.token,
        scheduleId,
      );

      const enrollment = await LifeInsuranceApi.getLifeInsuranceEnrollment(
        this.apiSignal.token,
        lifeInsurancePolicy.id,
        enrollmentId,
      );

      this.setState({
        enrollment,
        loading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve Life Insurance Enrollment information. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  updateEnrollment = async (name, value) => {
    let enrollmentUpdate = {};

    const enrollmentForUpdate = _cloneDeep(this.state.enrollment);
    _set(enrollmentForUpdate, name, value);

    try {
      const updatedEnrollment =
        await LifeInsuranceApi.updateLifeInsuranceEnrollment(
          this.apiSignal.token,
          enrollmentForUpdate,
        );

      this.setState({
        enrollment: updatedEnrollment,
      });
      enrollmentUpdate.enrollment = updatedEnrollment;
    } catch (err) {
      if (!axios.isCancel(err)) {
        enrollmentUpdate.error = err;
        enrollmentUpdate.errorMessage = ApiCallErrorMessageHandler(
          err,
          "Sorry, something went wrong and we could not update this enrollment. Please try again.",
        );
      }
    }

    return enrollmentUpdate;
  };

  enrollmentAction = async (enrollmentId, action, terminationReason) => {
    let enrollmentUpdate = {};

    try {
      const updatedEnrollment =
        await LifeInsuranceApi.lifeInsuranceEnrollmentAction(
          this.apiSignal.token,
          enrollmentId,
          action,
          terminationReason,
        );

      this.setState({
        enrollment: updatedEnrollment,
      });
      enrollmentUpdate.enrollment = updatedEnrollment;
    } catch (err) {
      if (!axios.isCancel(err)) {
        enrollmentUpdate.errorMessage = ApiCallErrorMessageHandler(
          err,
          "Sorry, something went wrong and we could not update this enrollment. Please try again.",
        );
      }
    }

    return enrollmentUpdate;
  };

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const { authorized, enrollment, errorMessage, loading } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="life-insurance-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/life-insurance/${sched.scheduleID}?tab=shluchim`,
              title: sched.scheduleName || `${sched.programName} Details`,
            },
          ]}
          pageTitle="Shliach Enrollment"
          scheduleId={scheduleId}
        />
        {!enrollment && loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          !!enrollment && (
            <React.Fragment>
              <p
                onClick={() => browserHistory.goBack()}
                className="mr-24 link-text flex flex-align-center mb-24"
              >
                <i className="material-icons link-text xl-text mr-8">
                  arrow_back
                </i>
                Go back
              </p>

              <EnrollmentDetails
                enrollment={enrollment}
                enrollmentStatuses={this.enrollmentStatuses}
                updateEnrollment={this.updateEnrollment}
              />

              <EnrollmentActions
                enrollment={enrollment}
                enrollmentStatuses={this.enrollmentStatuses}
                enrollmentAction={this.enrollmentAction}
              />
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
