import React from "react";

// import { EventCategories, EventTypes } from "../../../models/EventCategories";
// import { ContactType } from "../../Contact/ContactUtils";
import { GetAsArray /* , MenuOption */ } from "../MenuOptions";

// interface EventTypeSelectorProps {
//   contactType: ContactType;
//   selectedCategory: EventCategories;
//   onEventCategoryChange: React.FormEventHandler<HTMLSelectElement>;
//   selectedType: EventTypes;
//   onEventTypeChange: React.FormEventHandler<HTMLSelectElement>;
//   menuOptions: MenuOption;
// }

const EventTypeSelector = ({
  contactType,
  selectedCategory,
  onEventCategoryChange,
  selectedType,
  onEventTypeChange,
  menuOptions,
}) => {
  return (
    <div>
      <div className="form-group">
        <label className="control-label">Event Category</label>
        <div className="cols-sm-3">
          <select
            className="form-control"
            style={{ display: "inline", width: "auto" }}
            onChange={onEventCategoryChange}
            value={selectedCategory}
          >
            {GetAsArray(menuOptions).map((opt) => (
              <option key={opt.category} value={opt.category}>
                {opt.title}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label className="control-label">Event Type</label>
        <div className="cols-sm-3">
          <select
            className="form-control"
            style={{ display: "inline", width: "auto" }}
            onChange={onEventTypeChange}
            value={selectedType}
          >
            {selectedCategory &&
              Object.keys(menuOptions[selectedCategory].eventTypes).map(
                (key) => (
                  <option key={key} value={key}>
                    {menuOptions[selectedCategory].eventTypes[key]}
                  </option>
                ),
              )}
          </select>
        </div>
      </div>
    </div>
  );
};

export default EventTypeSelector;
