import React from "react";
import Autocomplete from "react-autocomplete";
import Loader from "../../common/Loader";
import Modal from "react-modal";

import axios from "axios";
import debounce from "lodash.debounce";
import _cloneDeep from "lodash.clonedeep";
import EduApi from "../../../services/resources/EduApi";
import StudentsApi from "../../../services/resources/StudentsApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";

export default class AddStudentModal extends React.Component {
  state = {
    errorMessage: "",
    getStudentsError: "",
    loading: false,
    searchText: "",
    suggestions: [],
    selectedStudent: null,
  };

  initialState = _cloneDeep(this.state);

  apiSignal = axios.CancelToken.source();

  onClose = () => {
    this.setState(this.initialState, this.props.close);
  };

  handleSearchStudentChange = (e) => {
    let newValue = e.currentTarget.value;

    this.setState(
      {
        searchText: newValue,
        suggestions: [],
        selectedStudent: null,
      },
      () => {
        this.onSearchDebounce();
      },
    );
  };

  getStudentSuggestions() {
    const { searchText } = this.state;
    if (searchText) {
      StudentsApi.searchStudents(this.apiSignal.token, searchText)
        .then((results) => {
          this.setState({
            suggestions: results,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorMessageHandler(err);
          this.setState({ loading: false, errorMessage: errors });
        });
    }
  }
  onSearchDebounce = debounce(this.getStudentSuggestions, 1000);

  handleStudentSelected(selectedStudent, item) {
    this.setState({
      loading: false,
      selectedStudent,
      searchText: `${item.firstName} ${item.lastName}`,
    });
  }

  onSelectStudent = (selectedStudent) => {
    this.setState({ errorMessage: "", selectedStudent });
  };

  onSubmit = async () => {
    const { selectedStudent } = this.state;
    if (!selectedStudent) {
      return;
    }
    this.setState({
      errorMessage: "",
      loading: true,
    });
    try {
      await EduApi.addEduStudent(this.apiSignal.token, {
        studentID: selectedStudent,
        eduProgramID: this.props.eduProgramID,
        status: "Approved",
      });
      this.onClose();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        });
      }
    }
  };

  render() {
    const { show } = this.props;
    const { errorMessage, loading, searchText, suggestions, selectedStudent } =
      this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card medium-modal">
          <p className="large-text fw-500 mb-16">Choose Student</p>
          <p className="small-text">
            * Note only first 25 results will be shown
          </p>
          <Autocomplete
            autoHighlight={false}
            value={searchText}
            wrapperProps={{ style: { width: "100%" } }}
            inputProps={{
              style: {
                borderRadius: "0",
                height: "36px",
                width: "100%",
                border: "1px solid #edecec",
                fontSize: "14px",
                paddingLeft: 8,
              },
              placeholder: "Search by name",
            }}
            getItemValue={(item) => item.id}
            menuStyle={{
              zIndex: 9,
              borderRadius: 3,
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 12px",
              background: "rgba(255, 255, 255)",
              padding: "2px 0px",
              fontSize: "90%",
              position: "fixed",
              overflow: "auto",
              maxHeight: "50%",
              minWidth: 534,
            }}
            items={suggestions}
            renderItem={(item, isHighlighted) => (
              <div key={item.id} style={{ padding: 8 }}>
                {`${item.firstName} ${item.lastName}${
                  item.email ? ` - ${item.email}` : ""
                }`}
              </div>
            )}
            onChange={this.handleSearchStudentChange}
            onSelect={(value, item) => {
              this.handleStudentSelected(value, item);
            }}
          />
          <div className="text-right mt-24">
            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <div className="mt-40">
                <button
                  className="btn link-text uppercase-text fw-700"
                  onClick={this.onClose}
                  style={{ background: "transparent", fontSize: 14 }}
                >
                  Cancel
                </button>
                <button
                  className="btn link-text uppercase-text ml-8 fw-700"
                  disabled={!selectedStudent}
                  onClick={this.onSubmit}
                  style={{ background: "transparent", fontSize: 14 }}
                >
                  Add
                </button>
              </div>
            )}
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </Modal>
    );
  }
}
