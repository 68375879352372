import React from "react";
import { Link } from "react-router";

// interface NavBarHeaderProps {
//   mobileMode: boolean;
//   handleToggleSidebarOpen: React.FormEventHandler<HTMLElement>;
// }

const NavBarHeader = ({ mobileMode, handleToggleSidebarOpen }) => {
  return (
    <div style={{ overflow: "auto", paddingTop: "24px" }}>
      {mobileMode && (
        <div
          className="text-right"
          style={{ float: "right", fontSize: "1.2em", cursor: "pointer" }}
        >
          <span
            onClick={handleToggleSidebarOpen}
            className="glyphicon glyphicon-remove text-right"
          />
        </div>
      )}
      {!mobileMode && (
        <h2>
          <Link to="/">
            <img
              className="logo img-responsive"
              src="/logo.png"
              alt="COCI Admin"
            />
          </Link>
        </h2>
      )}
    </div>
  );
};

export default NavBarHeader;
