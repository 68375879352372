import React from "react";
import { Link } from "react-router";
import Loader from "../../../common/Loader";
import ValidationModal from "../../../shared/ConfirmationModal";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { formatFullName, impersonationUserTypes } from "../../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import axios from "axios";
import ImpersonateUser from "../../../shared/ImpersonateUser";

export default class LocationsTableRow extends React.PureComponent {
  constructor(props) {
    super();

    const { grants, location } = props;
    const { allocations, initialAllocations } = this.getInitializedAllocations(
      location.allocations,
      grants,
    );

    this.state = {
      allocations,
      editMode: false,
      errorMessage: "",
      initialAllocations,
      loading: false,
      validationMessage: "",
    };
  }

  getInitializedAllocations = (allocations, grants) => {
    allocations = _cloneDeep(allocations || []);
    grants.forEach((grant) => {
      const allocationIndex = allocations.findIndex(
        (a) => a.grantID === grant.id,
      );
      if (allocationIndex < 0) {
        allocations.push({ grantID: grant.id, quantity: "" });
      } else {
        const allocation = allocations[allocationIndex];
        allocations[allocationIndex] = {
          grantID: allocation.grantID,
          id: allocation.id,
          quantity: allocation.quantity.toString(), //parse to string to ensure proper equality comparison after update
        };
      }
    });

    return {
      allocations,
      initialAllocations: _cloneDeep(allocations),
    };
  };

  onEdit = () => {
    this.setState({ editMode: true });
    this.props.setHasUnsavedAllocationsChanges(true);
  };

  onCancel = () => {
    this.setState({
      allocations: _cloneDeep(this.state.initialAllocations),
      editMode: false,
    });
    this.props.setHasUnsavedAllocationsChanges(false);
  };

  onChange = (grantId, quantity) => {
    let allocations = _cloneDeep(this.state.allocations);

    const allocationIndex = allocations.findIndex((a) => a.grantID === grantId);
    if (allocationIndex < 0) {
      return;
    }

    allocations[allocationIndex].quantity = quantity;

    this.setState({ allocations });
  };

  onSave = async () => {
    const { allocations, initialAllocations } = this.state;
    const { setHasUnsavedAllocationsChanges } = this.props;

    this.setState({ errorMessage: "" });

    if (_isEqual(allocations, initialAllocations)) {
      this.setState({ editMode: false });
      setHasUnsavedAllocationsChanges(false);
      return;
    }

    const isValid = this.validateChanges();
    if (!isValid) {
      return;
    }

    this.setState({ loading: true });

    try {
      const { refreshAllocations, submitAllocations } = this.props;

      const savedLocation = await submitAllocations(
        allocations.filter((a) => !!a.quantity),
      );

      refreshAllocations();

      this.setState({
        editMode: false,
        loading: false,
        ...this.getInitializedAllocations(
          savedLocation.allocations,
          this.props.grants,
        ),
      });

      setHasUnsavedAllocationsChanges(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        });
      }
    }
  };

  validateChanges = () => {
    const { allocationsByGrant, grants, location } = this.props;
    const { allocations } = this.state;
    for (var allocation of allocations) {
      let { grantID, quantity } = allocation;
      quantity = parseInt(allocation.quantity || "0");

      const grant = grants.find((g) => g.id === grantID);
      if (!grant) {
        continue;
      }

      // get allocation from location view to validate that qty is not below submitted qty
      const allocationByLocation =
        (location.allocations &&
          location.allocations.find((a) => a.grantID === grantID)) ||
        "";
      if (
        allocationByLocation.submittedQuantity &&
        allocationByLocation.submittedQuantity > quantity
      ) {
        this.setState({
          validationMessage: `Allocation quantity for ${grant.name} is less than the quantity submitted by this location`,
        });
        return false;
      }

      // get allocation by grant details to validate that qty allocated + new qty does not exceed total grant qty
      const allocationByGrant =
        allocationsByGrant &&
        allocationsByGrant.find((a) => a.grantID === grantID);
      const grantQtyAllocated = allocationByGrant
        ? allocationByGrant.quantity - (allocationByLocation.quantity || 0) //subtract original allocation qty from total allocated before adding updated amount for validation
        : 0;
      if (grantQtyAllocated + quantity > grant.quantity) {
        this.setState({
          validationMessage: `Allocation quantity for ${grant.name} exceeds the available quantity for this grant`,
        });
        return false;
      }
    }

    this.setState({ validationMessage: "" });
    return true;
  };

  render() {
    const {
      canUpdateAllocations,
      grants,
      location: {
        campusID,
        campusName,
        chabadHouseID,
        chabadHouseName,
        shliachFirstName,
        shliachID,
        shliachLastName,
        shliachEmail,
        shluchaFirstName,
        shluchaID,
        shluchaEmail,
      },
      onRemoveLocation,
      readOnly,
    } = this.props;
    const { allocations, editMode, errorMessage, loading, validationMessage } =
      this.state;

    const hasAllocations = allocations.some((a) => a.quantity);

    return (
      <div className="lamplighters-locations-table-row">
        <p>
          {!!chabadHouseID && (
            <Link
              className="link-text-secondary"
              to={`/shluchim/${
                shliachID || shluchaID
              }/chabadHouse/${chabadHouseID}`}
            >
              {chabadHouseName}
            </Link>
          )}
        </p>
        <p>
          {!!shliachID && (
            <div style={{ display: "flex", gap: 10 }}>
              <Link
                className="link-text-secondary"
                to={`/shluchim/${shliachID}`}
              >
                {formatFullName(shliachFirstName, shliachLastName)}
              </Link>
              <span className="tooltip-container">
                <ImpersonateUser
                  className="mt-4"
                  username={shliachEmail}
                  userType={impersonationUserTypes.Shliach}
                  showIconWithoutText={true}
                />
                <span className="tooltip" style={{ bottom: "70%" }}>
                  Impersonate
                </span>
              </span>
            </div>
          )}
          <br />
          {!!shluchaID && (
            <div style={{ display: "flex", gap: 10 }}>
              <Link
                className="link-text-secondary"
                to={`/shluchim/${shluchaID}`}
              >
                {formatFullName(shluchaFirstName, shliachLastName)}
              </Link>
              <span className="tooltip-container">
                <ImpersonateUser
                  className="mt-4"
                  username={shluchaEmail}
                  userType={impersonationUserTypes.Shliach}
                  showIconWithoutText={true}
                />
                <span className="tooltip" style={{ bottom: "70%" }}>
                  Impersonate
                </span>
              </span>
            </div>
          )}
        </p>
        <p>
          {!!campusID && (
            <Link
              className="link-text-secondary"
              to={`/shluchim/${shliachID || shluchaID}/school/${campusID}`}
            >
              {campusName}
            </Link>
          )}
        </p>
        <div>
          <div className="flex flex-align-center relative">
            {grants.map((grant, index) => {
              const allocation = allocations.find(
                (a) => a.grantID === grant.id,
              );
              return (
                <div
                  className="lamplighters-locations-table-dynamic-cell"
                  key={index}
                >
                  {editMode ? (
                    <input
                      className="custom-input"
                      disabled={loading}
                      min="0"
                      onChange={(e) =>
                        this.onChange(
                          grant.id,
                          e.target.value > 0 ? e.target.value : "",
                        )
                      }
                      style={{ width: "80px" }}
                      type="number"
                      value={allocation.quantity || ""}
                    />
                  ) : (
                    allocation.quantity || ""
                  )}
                </div>
              );
            })}
          </div>
          {editMode && errorMessage && (
            <p className="error-message xs-text mt-4">{errorMessage}</p>
          )}
        </div>
        <div className="flex flex-align-center">
          {!readOnly && (
            <React.Fragment>
              <div className="tooltip-container mr-4">
                {loading ? (
                  <Loader />
                ) : (
                  <div>
                    <i
                      className={`link-text-secondary material-icons medium-text${
                        !canUpdateAllocations ? " disabled" : ""
                      }`}
                      onClick={
                        !canUpdateAllocations
                          ? null
                          : editMode
                          ? this.onSave
                          : this.onEdit
                      }
                      style={{ padding: "4px" }}
                    >
                      {editMode ? "done" : "edit"}
                    </i>
                    {editMode && (
                      <i
                        className="link-text-secondary material-icons medium-text"
                        onClick={this.onCancel}
                        style={{ padding: "4px" }}
                      >
                        close
                      </i>
                    )}
                  </div>
                )}
                {!canUpdateAllocations && (
                  <span className="tooltip tooltip-right">
                    Allocations cannot be updated after final payment has been
                    created
                  </span>
                )}
              </div>
              <div className="tooltip-container">
                <i
                  className={`link-text-secondary material-icons medium-text${
                    hasAllocations ? " disabled" : ""
                  }`}
                  onClick={hasAllocations ? null : onRemoveLocation}
                  style={{ padding: "8px" }}
                >
                  delete
                </i>
                {hasAllocations && (
                  <span className="tooltip tooltip-right">
                    Locations with grant allocations cannot be removed. Reduce
                    grant allocations to 0 in order to remove this location.
                  </span>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
        <ValidationModal
          cancel={() => this.setState({ validationMessage: "" })}
          cancelText="Ok"
          message={validationMessage}
          noConfirm={true}
          show={!!validationMessage}
          title="Invalid Allocation Quantity"
        />
      </div>
    );
  }
}
