import React from "react";
import { Link } from "react-router";
import { EmailLink, PhoneNumberLink } from "../../../lib/coc-common-components";

import {
  /* ContactMethods, */ GetMainContactMethod,
  ContactMethodsStrings,
} from "../../../models/Contact";
import {
  /* Organization, */ CategoryTypes,
} from "../../../models/Organization";
import DataTable /* , { DataTableColumn } */ from "../../common/DataTable";

// interface OrganizationListProps {
//   organizations: Array<Organization>;
// }

const OrganizationList = ({ organizations = [] }) => {
  /** @returns {string} */
  function getMainContactEmail(contacts) {
    const contactMethods =
      (contacts && contacts.length > 0 && contacts[0].contactMethods) || [];
    return GetMainContactMethod(contactMethods, ContactMethodsStrings.Email);
  }
  /** @param {Array<ContactMethods>} contactMethods
   * @returns {string}
   */
  function getMainPhoneNumber(contactMethods) {
    const phones = (contactMethods || [])
      .filter(
        (cm) =>
          cm.type === ContactMethodsStrings.Mobile ||
          cm.type === ContactMethodsStrings.HomePhone,
      )
      .sort((a, b) => (a.primary ? -1 : b.primary ? 1 : 0));
    return phones.length > 0 ? phones[0].value : "";
  }
  /** @returns {string} */
  function getMainContactPhoneNumber(contacts) {
    const contactMethods =
      (contacts && contacts.length > 0 && contacts[0].contactMethods) || [];
    return getMainPhoneNumber(contactMethods);
  }
  /** @type {Array<DataTableColumn>} */
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (c) => (
        <Link
          to={`/organizations/${c.original.id}`}
          style={{ cursor: "pointer" }}
        >
          {c.original.name}
        </Link>
      ),
    },
    {
      Header: "Type",
      accessor: "category.type",
      Cell: (c) =>
        (c.original.category && CategoryTypes[c.original.category.type]) || "-",
    },
    {
      Header: "Category",
      accessor: "category.name",
      Cell: (c) => (c.original.category && c.original.category.name) || "-",
    },
    {
      Header: "Contact",
      id: "contactName",
      accessor: (r) =>
        r.contacts.length > 0
          ? r.contacts[0].lastName + " " + r.contacts[0].firstName
          : "zzzz",
      Cell: (c) =>
        c.original.contacts.length > 0
          ? c.original.contacts[0].lastName +
            " " +
            c.original.contacts[0].firstName
          : "" || "-",
    },
    {
      Header: "Email",
      id: "email",
      accessor: (r) => getMainContactEmail(r.contacts),
      Cell: (c) => {
        const email = getMainContactEmail(c.original.contacts);
        return email ? <EmailLink emailAddress={email} /> : "";
      },
    },
    {
      Header: "Phone number",
      id: "phoneNumber",
      accessor: (r) => getMainContactPhoneNumber(r.contacts),
      Cell: (c) => {
        const phoneNumber = getMainContactPhoneNumber(c.original.contacts);
        return phoneNumber ? <PhoneNumberLink phoneNumber={phoneNumber} /> : "";
      },
    },
  ];
  /** @param {Organization} a
   * @param {Organization} b
   * @returns {number}
   */
  const sortOrganizations = (a, b) => (a.name < b.name ? -1 : 1);

  return (
    <div className="col-sm-12">
      <DataTable
        fullClientSide={true}
        columns={columns}
        reportData={organizations.sort(sortOrganizations)}
      />
    </div>
  );
};

export default OrganizationList;
