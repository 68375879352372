import React, { useState, useEffect } from "react";
import Checkbox from "../../../shared/Checkbox";
import ValidatedInput from "../../../shared/ValidatedInput";
import Toggle from "../../../shared/Toggle";

function AllergyAndDietaryInfo(props) {
  const {
    allergyTypes,
    requireAllergies,
    onChangeProfile,
    onChangeProfileEvt,
    profile: { hasAllergies, allergies, dietaryNotes } = {},
    showFormValidation,
  } = props;

  const [showGeneralRequiredError, setShowGeneralRequiredError] =
    useState(false);
  const [showSelectionRequiredError, setShowSelectionRequiredError] =
    useState(false);
  const [showAdditionalInfoRequiredError, setShowAdditionalInfoRequiredError] =
    useState(false);

  useEffect(() => {
    if (showFormValidation) {
      setShowGeneralRequiredError(
        requireAllergies && hasAllergies !== true && hasAllergies !== false
      );
      setShowSelectionRequiredError(
        hasAllergies && (!allergies || allergies.length === 0)
      );
      setShowAdditionalInfoRequiredError(
        hasAllergies && allergies.includes("Other") && !dietaryNotes
      );
    }
  }, [
    showFormValidation,
    hasAllergies,
    allergies,
    dietaryNotes,
    requireAllergies,
  ]);

  return (
    <div className="student-profile-form-section">
      <p className="profile-section-title">Allergy and Dietary Restrictions</p>
      <span
        className={`small-text accent-text line-height-double block mb-8
          ${showGeneralRequiredError ? "required-text" : ""}
        `}
      >
        Do you have any allergies or dietary needs?
        {requireAllergies && <span className="required-text">*</span>}
      </span>
      <ValidatedInput
        className="allergy-toggle"
        input={
          <Toggle
            className="toggle-container"
            allowNoOption={true}
            noOptionValue={null}
            options={[
              {
                value: true,
                display: "YES",
              },
              {
                value: false,
                display: "NO",
              },
            ]}
          />
        }
        name="hasAllergies"
        onChange={(name, value) => {
          //clear out allergies list
          onChangeProfile("allergies", []);
          onChangeProfile(name, value);
        }}
        value={hasAllergies}
      />

      {/*when we start with material UI:
         <ToggleButtonGroup
          color={showGeneralRequiredError ? "error" : "primary"}
          value={hasAllergies}
          exclusive
          size="small"
          onChange={(e, value) => onChangeProfile("hasAllergies", value)}
        >
          <ToggleButton style={{ width: 60 }} value={true}>
            YES
          </ToggleButton>
          <ToggleButton style={{ width: 60 }} value={false}>
            NO
          </ToggleButton>
        </ToggleButtonGroup> */}

      {hasAllergies && (
        <div className="small-text accent-text">
          <span
            className={`line-height-double block mt-16 mb-8 uppercase${
              showGeneralRequiredError ? "required-text" : ""
            }`}
          >
            Select all that apply from list below
          </span>
          {showSelectionRequiredError && (
            <div className="required-text mb-16">You must select an option</div>
          )}
          <div className="allergy-grid">
            {allergyTypes &&
              allergyTypes.map((allergyType) => (
                <Checkbox
                  checked={
                    allergies && allergies.indexOf(allergyType.enumValue) >= 0
                  }
                  key={allergyType.enumValue}
                  label={allergyType.displayValue}
                  name={allergyType.enumValue}
                  onChange={(type, value) =>
                    onChangeProfile(
                      "allergies",
                      value
                        ? [...allergies, type]
                        : allergies.filter((a) => a !== type)
                    )
                  }
                />
              ))}
          </div>
        </div>
      )}
      {showAdditionalInfoRequiredError && (
        <span className="required-text mb-16">
          You must complete this field when "Other" is selected
        </span>
      )}
      <div
        className={`input-container mt-8 ${
          showAdditionalInfoRequiredError && "error"
        }`}
        style={{ height: "100%" }}
      >
        <label>
          Additional Dietary Information and/or Severity of Allergies{" "}
        </label>
        <textarea
          className="custom-input"
          name="dietaryNotes"
          onChange={onChangeProfileEvt}
          style={{ background: "transparent", border: "none" }}
          value={dietaryNotes}
        />
      </div>
    </div>
  );
}

export default React.memo(AllergyAndDietaryInfo);
