import AuthRequest from "./AuthRequest";

// import { Organization, OrganizationCategory } from "../../models/Organization";

const baseUrl = "/api/organizations";
const baseUrlCategories = `${baseUrl}/categories`;

const OrganizationApi = {
  /** @param {Organization} organization */
  create: (organization) => {
    return AuthRequest.post(`${baseUrl}/`, {
      ...organization,
      organizationCategoryId: organization.category.id,
    });
  },
  /** @param {Organization} organization */
  update: (organization) => {
    return AuthRequest.post(`${baseUrl}/${organization.id}`, {
      ...organization,
      organizationCategoryId: organization.category.id,
    });
  },
  /** @param {number} id */
  delete: (id) => {
    return AuthRequest.delete(`${baseUrl}/${id}`);
  },
  list: () => {
    return AuthRequest.get(`${baseUrl}`, {});
  },
  /** @param {string} searchQuery
   * @param {number} maxResults
   */
  listNames: (searchQuery, maxResults) => {
    return AuthRequest.get(`${baseUrl}/listnames`, { searchQuery, maxResults });
  },
  /** @param {number} id */
  get: (id) => {
    return AuthRequest.get(`${baseUrl}/${id}`);
  },
  /** @param {OrganizationCategory} category */
  createCategory: (category) => {
    return AuthRequest.post(`${baseUrlCategories}/`, { ...category });
  },
  /** @param {OrganizationCategory} category */
  updateCategory: (category) => {
    return AuthRequest.post(`${baseUrlCategories}/${category.id}`, {
      ...category,
    });
  },
  /** @param {number} id */
  deleteCategory: (id) => {
    return AuthRequest.delete(`${baseUrlCategories}/${id}`);
  },
  listCategories: () => {
    return AuthRequest.get(`${baseUrlCategories}`, {});
  },
  /** @param {number} id */
  getCategory: (id) => {
    return AuthRequest.get(`${baseUrlCategories}/${id}`);
  },
};

export default OrganizationApi;
