import React from "react";
import DatePicker from "react-datepicker";
import "../../assets/stylesheets/components/datepicker.min.css";
import moment from "moment";

export class DatePickerWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: moment.utc().startOf("day") };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({ value: moment.utc(value) });
    this.props.inputChangeHandler(this.props.input.name)({
      target: { value: moment.utc(value) },
    });
  }

  componentWillMount() {
    if (this.props.selectedDate) {
      this.setState({ value: moment.utc(this.props.selectedDate) });
    }
  }

  render() {
    return (
      <DatePicker
        id={this.props.input.name}
        required={true}
        className="form-control"
        selected={this.state.value}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        onChange={this.onChange}
      />
    );
  }
}
