import React from "react";
import { browserHistory } from "react-router";
import EnrollmentGeneralSettings from "./EnrollmentGeneralSettings";
import EnrollmentPricing from "./EnrollmentPricing";
import EnrollmentTours from "./EnrollmentTours";
import Loader from "../../../../common/Loader";
import ProgramBreadcrumbsHeader from "../../../../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../../../../lib/coc-common-components";

import TripEventsApi from "../../../../../services/resources/TripEventsApi";
import AuthService, {
  PermissionClaims,
} from "../../../../../services/AuthService";
import AuthClient from "../../../../../services/resources/AuthClient";
import axios from "axios";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import { getFormattedValuesForForm } from "../../../../../lib";

export default class ShliachEnrollmentPage extends React.PureComponent {
  state = {
    authorized: true,

    errorMessage: "",
    loading: true,

    tripEvent: null,
    shliachEnrollment: null,

    tabIndex: null,
    tabs: [
      { name: "General", id: "general", component: EnrollmentGeneralSettings },
      { name: "Pricing", id: "pricing", component: EnrollmentPricing },
      { name: "Tours", id: "tours", component: EnrollmentTours },
    ],
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (
      !AuthService.UserHasClaim(PermissionClaims.TripDataView)
      // && !AuthService.UserHasClaim(PermissionClaims.TripChabadHouseView)  //implementation previously included allowing page access for users with TripChabadHouseView permission - don't see what role this was used for and t/f removing for now.  will re-insert if necessary.
    ) {
      this.setState({ authorized: false });
    }

    const {
      location: {
        query: { tab },
      },
    } = this.props;

    const currentTabIndex = this.state.tabs
      .map((tab) => tab.id)
      .indexOf(decodeURIComponent(tab));
    this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0);

    this.getTripAndEnrollmentDetails();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getTripAndEnrollmentDetails = async () => {
    try {
      this.setState({ loading: true });

      const [tripEvent, shliachEnrollment] = await Promise.all([
        TripEventsApi.getTripEvent(
          this.apiSignal.token,
          this.props.params.scheduleId
        ),
        TripEventsApi.getTripEnrollment(
          this.apiSignal.token,
          this.props.params.enrollmentId
        ),
      ]);

      //check that enrollment matches this event, in order to avoid issues if user manually enters other event enrollment id into url
      if (tripEvent.id !== shliachEnrollment.tripEventID) {
        this.setState({
          errorMessage:
            "Invalid Enrollment request.  This Shliach Enrollment does not match this event.",
          loading: false,
        });
        return;
      }

      const { data: shliachCampusesData } = await AuthClient.get(
        `/Campuses/basic?shliachId=${shliachEnrollment.enrolledByShliachID}`
      ).catch((err) => {
        shliachEnrollment.shliachCampuses = shliachEnrollment.campuses; //default campuses to selected campuses from enrollment details
      });
      if (shliachCampusesData) {
        shliachEnrollment.shliachCampuses = shliachCampusesData;
      }

      this.setState({
        loading: false,
        tripEvent,
        shliachEnrollment: getFormattedValuesForForm(shliachEnrollment),
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve Shliach Enrollment settings. Please try again."
          ),
          loading: false,
        });
      }
    }
  };

  toTab = (index) => {
    const { tabIndex, tabs } = this.state;
    const {
      location: { pathname },
    } = this.props;

    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      browserHistory.replace(
        `${pathname}?tab=${encodeURIComponent(tabs[index].id)}`
      );
    }
  };

  render() {
    const {
      routeParams: { scheduleId },
    } = this.props;
    const {
      authorized,
      errorMessage,
      loading,
      tripEvent,
      shliachEnrollment,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !shliachEnrollment ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong and we could not retrieve Shliach Enrollment settings. Please try again."}
            </p>
          </div>
        ) : (
          <React.Fragment>
            <div className="trip-page-subheader trip-page-settings-subheader">
              <div className="container flex flex-justify-space flex-align-center">
                <p onClick={() => browserHistory.goBack()} className="mr-24">
                  <i className="material-icons link-text">arrow_back</i>
                </p>
                <ul className="flex">
                  {tabs.map((tab, index) => (
                    <li
                      key={index}
                      className={tabIndex === index ? "active" : ""}
                      onClick={() => this.toTab(index)}
                    >
                      {tab.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="container">
              <ProgramBreadcrumbsHeader
                getPrevPages={(sched) => [
                  {
                    path: `/trips/${sched.scheduleID}?tab=shluchim`,
                    title: sched.scheduleName,
                  },
                ]}
                pageTitle="Shliach Enrollment"
                scheduleId={scheduleId}
              />
              <div className="card mb-32 trip-shliach-enrollment-form">
                {React.createElement(tabs[tabIndex].component, {
                  enrollment: shliachEnrollment,
                  tripEvent,
                })}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
