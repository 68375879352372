import React from "react";

import { EditableControlStates, DataUnavailableJSX, RequiredSign } from ".";

// interface EditableTextAreaProps {
//   currentState: EditableControlStates;
//   currentValue: string;
//   handleChange: React.FormEventHandler<HTMLTextAreaElement>;
//   required?: boolean;
//   nonEditableJSX?;
//   className?: string;
//   placeholder?: string;
// }

const EditableTextArea = ({
  currentState,
  currentValue,
  handleChange,
  required,
  nonEditableJSX = <span>{currentValue || DataUnavailableJSX}</span>,
  className = "",
  placeholder,
}) =>
  currentState === EditableControlStates.nonEditable ? (
    currentValue ? (
      nonEditableJSX
    ) : (
      DataUnavailableJSX
    )
  ) : (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 0 }}>
        <textarea
          required={required || false}
          value={currentValue || ""}
          onChange={handleChange}
          style={{ marginBottom: "2px" }}
          className={className}
          placeholder={placeholder}
        />
      </div>
      <div style={{ flex: 1 }}>{required && RequiredSign}</div>
    </div>
  );

export default EditableTextArea;
