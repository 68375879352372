import React from "react";
import axios from "axios";
import { browserHistory } from "react-router";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons /*, ModalButton */,
} from "../../../lib/coc-common-components";

import { asEnum } from "../../../lib";
import BasicDataApi from "../../../services/resources/BasicDataApi";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import viewHistory from "../../../services/ViewHistory";
import OrganizationApi from "../../../services/resources/OrganizationsApi";
import {
  /* Organization, */ EmptyOrganization /*, OrganizationCategory */,
} from "../../../models/Organization";
import OrganizationManagerPage from "./OrganizationManagerPage";
// import { Country, State } from "../../../models/Common";
import {
  /* Contact, */ EmptyContact,
  ContactMethodsStrings,
} from "../../../models/Contact";

const ItemDeleting = asEnum({
  None: 0,
  Organization: 1,
  Contact: 2,
});

// interface OrganizationManagerContainerProps {
//     location: { [key: string]: any };
//     params: { [key: string]: any };
// }

// interface OrganizationManagerContainerState {
//     loading: boolean;
//     organizationData: Organization;
//     organizationCategories: Array<OrganizationCategory>;
//     countries: Array<Country>;
//     states: Array<State>;
//     displayMessage: string;
//     displayAndRedirectMessage: string;
//     displayDeleteMessage: string;
//     itemDeleting: ItemDeleting;
//     contactIdToDelete?: number;
// }

class OrganizationManagerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      organizationData: EmptyOrganization(),
      countries: [],
      states: [],
      organizationCategories: [],
      displayMessage: "",
      displayAndRedirectMessage: "",
      displayDeleteMessage: "",
      itemDeleting: ItemDeleting.None,
    };

    this.handleOrganizationChanged = this.handleOrganizationChanged.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactSelected = this.handleContactSelected.bind(this);
    this.handleContactAdded = this.handleContactAdded.bind(this);
    this.handleContactRemoved = this.handleContactRemoved.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.delete = this.delete.bind(this);
  }
  /** @param {Organization} newOrganization */
  handleOrganizationChanged(newOrganization) {
    const setNewOrganizationState = () =>
      this.setState({ organizationData: newOrganization });

    if (newOrganization.address) {
      const newStateId = -1;

      if (
        !Number.isInteger(newOrganization.address.stateId) &&
        newOrganization.address.state &&
        newOrganization.address.countryId
      ) {
        const newStateObj = {
          id: newStateId,
          name: newOrganization.address.state,
          countryId: newOrganization.address.countryId,
        };
        newOrganization.address.stateId = newStateId;
        const existsNewState = this.state.states[0].id === newStateId;
        if (existsNewState) {
          this.setState(
            { states: [newStateObj, ...this.state.states.slice(1)] },
            setNewOrganizationState,
          );
        } else {
          this.setState(
            { states: [newStateObj, ...this.state.states] },
            setNewOrganizationState,
          );
        }
      } else {
        setNewOrganizationState();
      }
    } else {
      setNewOrganizationState();
    }
  }
  /** @param {Contact} newContact */
  handleContactChange(newContact) {
    const newContacts = this.state.organizationData.contacts.slice();
    const contactIx = newContacts.findIndex((cm) => cm.id === newContact.id);
    newContacts.splice(contactIx, 1, newContact);
    this.setState({
      organizationData: {
        ...this.state.organizationData,
        contacts: newContacts,
      },
    });
  }
  /** @param {number} id */
  handleContactSelected(id) {
    const newContacts = this.state.organizationData.contacts
      .filter((c) => c.lastName || c.firstName)
      .map((c) => ({
        ...c,
        isBeingEdited: c.id !== id ? false : !c.isBeingEdited,
      }));
    this.setState({
      organizationData: {
        ...this.state.organizationData,
        contacts: newContacts,
      },
    });
  }

  handleContactAdded() {
    const newContacts = this.state.organizationData.contacts
      .filter((c) => c.lastName || c.firstName)
      .map((c) => ({ ...c, isBeingEdited: false }));
    newContacts.push({
      ...EmptyContact(),
      homeAddress: undefined,
      id: -Math.floor(Math.random() * 100000 + 10000),
      isBeingEdited: true,
    });
    this.setState({
      organizationData: {
        ...this.state.organizationData,
        contacts: newContacts,
      },
    });
  }
  /** @param {number} id */
  handleContactRemoved(id) {
    this.setState({
      displayDeleteMessage:
        "Are you sure you want to remove this contact from the organization?\nChanges won't be saved until you press Save.",
      itemDeleting: ItemDeleting.Contact,
      contactIdToDelete: id,
    });
  }
  /** @param {number} id */
  deleteContact(id) {
    const newContacts = this.state.organizationData.contacts.filter(
      (c) => (c.lastName || c.firstName) && c.id !== id,
    );
    this.setState({
      organizationData: {
        ...this.state.organizationData,
        contacts: newContacts,
      },
      displayDeleteMessage: "",
      itemDeleting: ItemDeleting.None,
      contactIdToDelete: undefined,
    });
  }
  /** @param {Organization} organization */
  handleSave(organization) {
    this.setState({ loading: true }, () => {
      const isNew = !organization.id || organization.id < 0;
      const apiCall = isNew ? OrganizationApi.create : OrganizationApi.update;
      apiCall(organization)
        .then((response) => {
          this.setState({
            organizationData: response.data.result.value,
            displayAndRedirectMessage: "Organization saved successfully",
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }
  /** @param {Organization} organization */
  handleDelete(organization) {
    this.setState({
      displayDeleteMessage: "You are about to delete this organization",
      itemDeleting: ItemDeleting.Organization,
    });
  }

  delete() {
    this.setState({ displayDeleteMessage: "", loading: true }, () =>
      OrganizationApi.delete(this.state.organizationData.id)
        .then((response) => {
          this.setState({
            loading: false,
            displayAndRedirectMessage: "Organization successfully deleted",
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        }),
    );
  }

  confirmDeleteButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "I understand. Delete it now.",
      className: "btn btn-danger",
      onClick:
        this.state.itemDeleting === ItemDeleting.Organization
          ? this.delete
          : () => this.deleteContact(this.state.contactIdToDelete),
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDeleteMessage: "" }),
    });
    return buttons;
  }

  messageButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayMessage: "" }),
    });
    return buttons;
  }
  /** @param {string} redirectUrl */
  okAndRedirectButtons(redirectUrl) {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({ displayMessage: "" }, () => {
          browserHistory.push(redirectUrl);
        }),
    });
    return buttons;
  }
  /** @param {Organization} organization */
  writeToViewHistory(organization) {
    const mainContact = organization.contacts && organization.contacts[0];
    const mainContactName =
      (mainContact && mainContact.firstName + " " + mainContact.lastName) || "";
    const mainContactMethod =
      mainContact &&
      mainContact.contactMethods &&
      mainContact.contactMethods.sort((cm1, cm2) =>
        cm1.type === ContactMethodsStrings.Email ? 1 : -1,
      )[0];

    viewHistory.add("Organization", {
      path: this.props.location.pathname,
      id: organization.id,
      name: organization.name,
      category: organization.category.name,
      mainContactName,
      mainContactMethodType: mainContactMethod && mainContactMethod.type,
      mainContactMethod: mainContactMethod && mainContactMethod.value,
      historyType: "organization",
    });
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryEdit)) {
      return;
    }

    const dbAccesses = [
      BasicDataApi.listCountriesAndStates(),
      OrganizationApi.listCategories(),
    ];
    const id = Number(this.props.params.id);
    if (!Number.isNaN(id)) {
      dbAccesses.push(OrganizationApi.get(id));
    }

    const sortByName = (a, b) => (a.name < b.name ? -1 : 1);

    this.setState({ loading: true }, () => {
      axios
        .all(dbAccesses)
        .then((response) => {
          /** @type {Organization} */
          const organizationData =
            (response[2] && response[2].data) || this.state.organizationData;
          this.setState({
            countries: response[0].data.results.countries.sort(sortByName),
            states: response[0].data.results.states.sort(sortByName),
            organizationCategories: response[1].data.results.sort(sortByName),
            organizationData: {
              ...organizationData,
              organizationCategoryType: organizationData.category.type,
            },
            loading: false,
          });
          this.writeToViewHistory(organizationData);
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryEdit)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const {
      organizationData,
      organizationCategories = [],
      countries,
      states,
    } = this.state;
    const filteredStates = states.filter(
      (s) =>
        s.countryId ===
        ((organizationData.address && organizationData.address.countryId) ||
          -1),
    );

    return (
      <div>
        {organizationCategories && (
          <OrganizationManagerPage
            organizationData={organizationData}
            organizationCategories={organizationCategories}
            countries={countries}
            states={filteredStates}
            handleOrganizationChanged={this.handleOrganizationChanged}
            handleContactChange={this.handleContactChange}
            handleContactSelected={this.handleContactSelected}
            handleContactAdded={this.handleContactAdded}
            handleContactRemoved={this.handleContactRemoved}
            handleSave={this.handleSave}
            handleDelete={this.handleDelete}
          />
        )}
        {this.state.displayMessage && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayMessage}
          />
        )}
        {this.state.displayAndRedirectMessage && (
          <ModalWithButtons
            buttons={this.okAndRedirectButtons("/organizations")}
            text={this.state.displayAndRedirectMessage}
          />
        )}
        {this.state.displayDeleteMessage && (
          <ModalWithButtons
            buttons={this.confirmDeleteButtons()}
            text={this.state.displayDeleteMessage}
          />
        )}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default OrganizationManagerContainer;
