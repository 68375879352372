import React from "react";
import DropDownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import moment from "moment";

// import { HistoryEvent } from "../Models/Events";
import {
  EventCategoriesDescriptions,
  EventDescriptor,
  GetCategoryFromType,
} from "../Scripts";
import { EventListGroup } from "./EventListGroup";

const GRP_YEAR = "Year";
const GRP_PROGRAM = "Program";

// export interface EventListContainerProps {
//   groupBy: "Year" | "Program";
//   eventList: Array<HistoryEvent>;
//   disabled: boolean;
// }

// export interface EventListContainerState {
//   groupBy: "Year" | "Program";
// }

// export interface EventGroup {
//   groupText: string;
//   eventList: Array<HistoryEvent>;
// }

export class EventListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupBy: props.groupBy || GRP_YEAR,
    };
  }
  /** @param {"Year" | "Program"} groupBy */
  handleGroupByChange(groupBy) {
    this.setState({ groupBy });
  }
  /** @param {HistoryEvent} ev1
   * @param {HistoryEvent} ev2
   * @returns {number}
   */
  sortEvents(ev1, ev2) {
    return ev2.occurredAt <= ev1.occurredAt ? -1 : 1;
  }
  /** @param {EventGroup} ev1
   * @param {EventGroup} ev2
   * @returns {number}
   */
  sortGroupByYear(ev1, ev2) {
    return ev1.groupText > ev2.groupText ? -1 : 1;
  }
  /** @param {EventGroup} ev1
   * @param {EventGroup} ev2
   * @returns {number}
   */
  sortGroupByProgram(ev1, ev2) {
    return ev1.groupText <= ev2.groupText ? -1 : 1;
  }
  /** @param {Array<EventGroup>} groupedList
   * @param {"Year" | "Program"} groupBy
   * @returns {Array<EventGroup>}
   */
  sortEventGroup(groupedList = [], groupBy) {
    /** @type {Array<EventGroup>} */
    const sortedGroupedList = [];
    groupedList.forEach((evtGrp) => {
      sortedGroupedList.push({
        groupText: evtGrp.groupText,
        eventList: evtGrp.eventList.sort(this.sortEvents),
      });
    });
    return sortedGroupedList.sort(
      groupBy === "Year" ? this.sortGroupByYear : this.sortGroupByProgram,
    );
  }
  /** @param {HistoryEvent} event
   * @param {"Year" | "Program"} groupBy
   */
  getProgramName(event, groupBy) {
    if (groupBy === GRP_YEAR) {
      return moment.utc(event.occurredAt).format("YYYY");
    }
    if (event.customEventId) {
      return event.customEvent || event.type;
    }
    const category = GetCategoryFromType(event.typeId);
    if (!category) {
      return "Not recognized";
    }
    return EventCategoriesDescriptions[category];
  }

  /** @param {HistoryEvent} */
  getEventName(event) {
    return EventDescriptor[event.customEventId || event.typeId].describer(
      event.metadata,
      event.type,
    );
  }
  /** @param {Array<HistoryEvent>} eventList
   * @param {"Year" | "Program"} groupBy
   * @returns {Array<EventGroup>}
   */
  groupEvents(eventList = [], groupBy) {
    /** @type {Array<EventGroup>} */
    const groupedList = [];
    eventList.forEach((event) => {
      const programName = this.getProgramName(event, groupBy);
      const eventName = this.getEventName(event);
      const evtForUI = { ...event, type: eventName };
      const group = groupedList.find((g) => g.groupText === programName);
      if (!group) {
        groupedList.push({ groupText: programName, eventList: [evtForUI] });
      } else {
        group.eventList.push(evtForUI);
      }
    });
    return this.sortEventGroup(groupedList, groupBy);
  }

  render() {
    const { eventList, disabled } = this.props;
    const { groupBy } = this.state;

    const groupedList = this.groupEvents(eventList, groupBy);

    return (
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "66.667% 33.334%",
          }}
        >
          <div>
            <legend>History</legend>
            Group by&nbsp;{groupBy}
          </div>
          <div>
            <legend>&nbsp;</legend>
            Group&nbsp;by{" "}
            <DropDownButton id="groupBy" title={groupBy}>
              <MenuItem
                eventKey="1"
                onClick={(e) => this.handleGroupByChange(GRP_YEAR)}
              >
                Year
              </MenuItem>
              <MenuItem
                eventKey="2"
                onClick={(e) => this.handleGroupByChange(GRP_PROGRAM)}
              >
                Program
              </MenuItem>
            </DropDownButton>
          </div>
        </div>
        {groupedList.map((g) => (
          <EventListGroup
            key={g.groupText}
            headerText={g.groupText}
            eventList={g.eventList}
            disabled={disabled}
            groupBy={this.state.groupBy}
            iconClassName={""}
          />
        ))}
      </div>
    );
  }
}
