import React from "react";
import ExportCSVButton from "../../../../shared/ExportCSVButton";
import { formatExcelNumberString } from "../../../../../lib";
import moment from "moment";

export default class RegistrationsShluchimTableActions extends React.PureComponent {
  render() {
    const {
      getShluchimForExport,
      programSchedule: { programName, scheduleName },
    } = this.props;

    return (
      <ExportCSVButton
        className="btn custom-btn btn-accent uppercase-text flex flex-align-center"
        fileName={`${scheduleName || programName}_Registrations_Shluchim`}
        getExportData={getShluchimForExport}
        headers={[
          { label: "Shliach ID", value: "shliachID" },
          { label: "Shliach First Name", value: "shliachFirstName" },
          { label: "Shliach Last Name", value: "shliachLastName" },
          { label: "Shliach Email", value: "shliachEmail" },
          { label: "Shliach Cell", value: "shliachCell" },
          { label: "Shlucha ID", value: "shluchaID" },
          { label: "Shlucha First Name", value: "shluchaFirstName" },
          { label: "Shlucha Email", value: "shluchaEmail" },
          { label: "Shlucha Cell", value: "shluchaCell" },
          {
            label: "Number of Student Registrations",
            value: "numberStudentRegistrations",
          },
          {
            label: "Latest Student Registration Date",
            value: ({ latestStudentRegistrationDate }) =>
              moment(latestStudentRegistrationDate).format("MM/DD/YYYY"),
          },
          { label: "Chabad House ID", value: "chabadHouseID" },
          { label: "Chabad House", value: "chabadHouseName" },
          { label: "Campus ID", value: "campusID" },
          { label: "Campus", value: "campusName" },
          { label: "Chabad House Address 1", value: "chabadHouseAddress1" },
          { label: "Chabad House Address 2", value: "chabadHouseAddress2" },
          { label: "Chabad House City", value: "chabadHouseCity" },
          { label: "Chabad House State", value: "chabadHouseState" },
          {
            label: "Chabad House Zip",
            value: ({ chabadHouseZip }) =>
              formatExcelNumberString(chabadHouseZip),
          },
        ]}
      />
    );
  }
}
