import React from "react";
import { browserHistory, Link } from "react-router";

import CheckIn from "./details/checkIn/TripCheckIn";
import Loader from "../common/Loader";
import Metrics from "./details/metrics/TripMetrics";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import Students from "./details/students/TripStudents";
import Shluchim from "./details/shluchim/TripShluchim";
import TripEventCard from "./details/TripEventCard";
import { Unauthorized } from "../../lib/coc-common-components";

import TripEventsApi from "../../services/resources/TripEventsApi";
import SystemApi from "../../services/resources/SystemApi";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";

const basicTripTabs = [
  { name: "Students", id: "students", component: Students },
  { name: "Shluchim", id: "shluchim", component: Shluchim },
  { name: "Metrics", id: "metrics", component: Metrics },
];

const fullTripTabs = [
  ...basicTripTabs,
  { name: "Check-in", id: "checkIn", component: CheckIn },
];

export default class TripEventPage extends React.PureComponent {
  state = {
    authorized: true,

    errorMessage: "",
    loading: false,

    systemLists: {
      tripRegistrationStatuses: [],
    },

    tripEvent: null,
    tripEventsList: [],

    tabIndex: 0,
    tabs: basicTripTabs,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.TripDataView)) {
      this.setState({ authorized: false });
    }

    const {
      location: {
        query: { tab },
      },
      params: { scheduleId },
    } = this.props;

    this.getTripDetails(scheduleId, true, (newPathname) => {
      const currentTabIndex = this.state.tabs
        .map((tab) => tab.id)
        .indexOf(decodeURIComponent(tab));
      this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0, newPathname);
    });

    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { scheduleId },
    } = this.props;

    if (scheduleId !== prevProps.params.scheduleId) {
      this.getTripDetails(
        scheduleId,
        !this.state.tripEventsList.some(
          (p) => p.programScheduleID.toString() === scheduleId
        )
      ); // fetch trips list if none for the current schedule
    }
  }

  getTripDetails = async (
    scheduleId,
    fetchTripsList = false,
    onFetchDetails = null
  ) => {
    try {
      this.setState({
        errorMessage: "",
        loading: true,
      });

      let tripEventsList = [...this.state.tripEventsList];
      if (fetchTripsList) {
        tripEventsList = await TripEventsApi.getTripEvents(
          this.apiSignal.token,
          scheduleId
        );
        if (tripEventsList.length) {
          this.setState({ tripEventsList });
        }
      }

      const eventScheduleId =
        scheduleId ||
        (tripEventsList.length && tripEventsList[0].programScheduleID);
      const tripEvent = await TripEventsApi.getTripEvent(
        this.apiSignal.token,
        eventScheduleId
      );

      // reset tabs to add/remove Check-in tab for non-travel vs travel trips
      const tabs = tripEvent.isTravelTrip ? basicTripTabs : fullTripTabs;
      const tabIndex = tabs[this.state.tabIndex] ? this.state.tabIndex : 0;

      this.setState(
        {
          loading: false,
          tripEvent,
          tabs,
          tabIndex,
        },
        () => {
          const {
            location: { search },
            params: { scheduleId: prevScheduleId },
          } = this.props;

          const pathnameUpdate =
            eventScheduleId !== prevScheduleId
              ? `/trips/${eventScheduleId}`
              : "";

          if (onFetchDetails) {
            onFetchDetails(pathnameUpdate);
          } else if (pathnameUpdate) {
            browserHistory.replace(`${pathnameUpdate}${search || ""}`);
          }
        }
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        const is404 = (err.response && err.response.status) === 404;
        this.setState({
          errorMessage: is404
            ? "404"
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong and we could not retrieve trip details. Please try again."
              ),
          loading: false,
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "genders",
        "tripEnrollmentAttendeeStatuses",
        "tripRegistrationStatuses",
        "tripRegistrationWorkflowSteps",
        "yearOverYearChartIntervals",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  toTab = (index, newPathname) => {
    const { tabs } = this.state;
    const {
      location: {
        query: { tab: prevTabIndex },
        pathname,
      },
    } = this.props;

    if (prevTabIndex !== index) {
      this.setState({ tabIndex: index });
      browserHistory.replace(
        `${newPathname || pathname}?tab=${tabs[index].id}`
      );
    }
  };

  render() {
    const {
      mobileMode,
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      errorMessage,
      loading,
      tripEvent,
      tripEventsList,
      systemLists,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const hasSettingsAccess = AuthService.UserHasClaim(
      PermissionClaims.TripFullEdit
    );

    return (
      <div className="trip-page page">
        <ProgramBreadcrumbsHeader scheduleId={scheduleId} />
        {!tripEvent && loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage ? (
          errorMessage === "404" ? (
            <div className="text-center">
              <p>No event has been configured for this program schedule.</p>
              <Link
                className="btn btn-info mt-16"
                to={`/programs/trips/${scheduleId}`}
              >
                Configure Advanced Settings
              </Link>
            </div>
          ) : (
            <div className="full-page-error-text error-text">
              <img src="/img/error.svg" alt="error robot" height="240" />
              <p>{errorMessage}</p>
            </div>
          )
        ) : (
          !!tripEvent && (
            <>
              <TripEventCard
                hasSettingsAccess={hasSettingsAccess}
                loading={loading}
                onChangeTripEvent={(_, scheduleId) =>
                  this.getTripDetails(scheduleId, false)
                }
                tripEvent={tripEvent}
                tripEventsList={tripEventsList}
              />

              {!loading && (
                <>
                  <div
                    className="trip-details-tabs flex"
                    style={{ marginLeft: "2px" }}
                  >
                    {tabs.map((tab, index) => (
                      <p
                        key={index}
                        className={`large-text ${index > 0 ? "ml-40" : ""} ${
                          tabIndex === index ? "active" : ""
                        }`}
                        onClick={() => this.toTab(index)}
                      >
                        {tab.name}
                      </p>
                    ))}
                  </div>

                  <div className="card trip-card">
                    {React.createElement(tabs[tabIndex].component, {
                      mobileMode,
                      tripEvent,
                      systemLists,
                    })}
                  </div>
                </>
              )}
            </>
          )
        )}
      </div>
    );
  }
}
