import React from "react";
import { FieldArray } from "formik";

import FormInput from "../../Form/FormInput";
import FormInputUpdate from "../../Form/FormInputUpdate";

export default class SpouseActivityInfo extends React.PureComponent {
  state = {
    spouseActivityShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        spouseActivityShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      handleChange,
      setFieldValue,
      shliach,
      sys,
      sys: { shliachCategories, demographics },
      touched,
      values: {
        outreach: { spouseOutreach = {} },
      },
    } = this.props;
    const spouseFirstName = shliach.spouse && shliach.spouse.firstName;

    return (
      <div
        className={
          this.state.spouseActivityShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-48 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              spouseActivityShowing: !this.state.spouseActivityShowing,
            });
          }}
        >
          {spouseFirstName || "Spouse"}'s Shlichus Activities
          <i className="material-icons desktop-hidden tablet-hidden">
            {this.state.spouseActivityShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-inputs profile-outreach-form-inputs">
          <div className="mb-24">
            <div className="radio-button-options">
              <FormInput
                label={`Which of these definitions best describes ${
                  spouseFirstName || "this spouse"
                }'s Shlichus?`}
                labelClassNames={"fw-700 accent-text-dark mb-8 block"}
                name="outreach.spouseOutreach.category"
                errors={errors}
                touched={touched}
              >
                {({ field }) =>
                  shliachCategories.map((shliachCategory, index) => (
                    <div
                      className="radio-button-option radio-button-container mb-8"
                      key={shliachCategory.enumValue}
                    >
                      <input
                        type="radio"
                        id={`spouse-shliachCategory-${shliachCategory.enumValue}`}
                        name={field.name}
                        value={shliachCategory.enumValue}
                        onChange={handleChange}
                        checked={field.value === shliachCategory.enumValue}
                      />
                      <label
                        htmlFor={`spouse-shliachCategory-${shliachCategory.enumValue}`}
                      >
                        <span className="fw-700 block">
                          {shliachCategory.displayValue}
                        </span>
                        {shliachCategory.value}
                      </label>
                    </div>
                  ))
                }
              </FormInput>
            </div>
          </div>
          <div className="mb-24">
            {/* TODO: add validation for this field and an error class for the label */}
            <label
              className={`fw-700 accent-text-dark mb-8 block ${
                errors.spouseDemographics &&
                touched.outreach &&
                touched.outreach.spouseOutreach &&
                touched.outreach.spouseOutreach.demographics &&
                touched.outreach.spouseOutreach.demographics.length === 0
                  ? "error-text"
                  : ""
              }`}
            >
              What demographics does {spouseFirstName || "this spouse"} serve?
            </label>
            <FieldArray name="outreach.spouseOutreach.demographics">
              {(arrayHelpers) => (
                <div className="demographics-grid">
                  <div>
                    <p className="small-text accent-text">Demographic</p>
                  </div>
                  {demographics &&
                    demographics.map((demographic, index) => {
                      const demographicIndex = sys.demographics.map((val) =>
                        spouseOutreach?.demographics?.indexOf(val.enumValue)
                      )[index];
                      return (
                        <div key={demographic.enumValue}>
                          <FormInputUpdate name="outreach.spouseOutreach.demographics">
                            {({ field }) => (
                              <div className="flex custom-checkbox-container">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  id={`demographics-spouse-${demographic.enumValue}`}
                                  name={field.name}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(demographic.enumValue);
                                    } else {
                                      const index =
                                        spouseOutreach.demographics.indexOf(
                                          demographic.enumValue
                                        );
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                  value={demographic.enumValue}
                                  checked={spouseOutreach.demographics.includes(
                                    demographic.enumValue
                                  )}
                                />
                                <label
                                  className="small-text flex flex-align-center"
                                  htmlFor={`demographics-spouse-${demographic.enumValue}`}
                                >
                                  {demographic.displayValue}
                                </label>
                              </div>
                            )}
                          </FormInputUpdate>
                        </div>
                      );
                    })}
                </div>
              )}
            </FieldArray>
          </div>
        </div>
      </div>
    );
  }
}
