import React, { useCallback, useState } from "react";
import { Popover } from "@material-ui/core";
import Loader from "../../../../../../common/Loader";

import {
  TripRegistrationStatuses,
  TripRegistrationWorkflowStepNumbers,
  TripRegistrationWorkflowSteps,
} from "../../../../../TripEventConsts";
import {
  getRegistrationStatusColor,
  getTravelTripRegistrationStatusWorkflowDisplay,
} from "../../../../../TripEventLogic";

const AssessmentEnabledWorkflowSteps = [
  TripRegistrationWorkflowSteps.Interviewed,
  TripRegistrationWorkflowSteps.PreAccepted,
  TripRegistrationWorkflowSteps.PreRejected,
  TripRegistrationWorkflowSteps.PreWaitlisted,
  TripRegistrationWorkflowSteps.Eligible,
];

const ProcessingEnabledWorkflowSteps = [
  TripRegistrationWorkflowSteps.PreAccepted,
  TripRegistrationWorkflowSteps.PreRejected,
  TripRegistrationWorkflowSteps.PreWaitlisted,
];

const registrationStatusMenuItems = [
  {
    name: "Applied",
    status: TripRegistrationStatuses.Pending,
    workflowStep: TripRegistrationWorkflowSteps.Applied,
    getDisabled: () => true, // cannot be set manually
    getDisabledTooltip: () => "",
  },
  {
    name: "Recommendation Submitted",
    status: TripRegistrationStatuses.Pending,
    workflowStep: TripRegistrationWorkflowSteps.RecommendationSubmitted,
    getDisabled: () => true, // cannot be set manually
    getDisabledTooltip: () => "Pending Recommendation submission",
  },
  {
    name: "Recommendation Approved",
    status: TripRegistrationStatuses.Pending,
    workflowStep: TripRegistrationWorkflowSteps.RecommendationApproved,
    getDisabled: ({ workflowStep }) =>
      workflowStep !== TripRegistrationWorkflowSteps.RecommendationSubmitted,
    getDisabledTooltip: () => "Pending Shliach recommendation",
  },
  {
    name: "Interviewed",
    status: TripRegistrationStatuses.Pending,
    workflowStep: TripRegistrationWorkflowSteps.Interviewed,
    getDisabled: ({ workflowStep }) =>
      workflowStep !== TripRegistrationWorkflowSteps.RecommendationApproved,
    getDisabledTooltip: ({ workflowStep }) =>
      workflowStep === TripRegistrationWorkflowSteps.RecommendationSubmitted
        ? "Pending Shliach recommendation approval"
        : "Pending Shliach recommendation",
  },
  {
    name: "Assessed",
    getDisabled: () => false, // PreRejected can be set from any prior status
    getDisabledTooltip: () => "",
    getGroupItems: () => [
      {
        name: "Eligible",
        status: TripRegistrationStatuses.Pending,
        workflowStep: TripRegistrationWorkflowSteps.Eligible,
        description: "Not ready for processing",
        getDisabled: ({ workflowStep }) =>
          !AssessmentEnabledWorkflowSteps.includes(workflowStep),
        getDisabledTooltip: () => "Pending Student interview",
      },
      {
        name: "Pre-Accepted",
        status: TripRegistrationStatuses.Pending,
        workflowStep: TripRegistrationWorkflowSteps.PreAccepted,
        getDisabled: ({ workflowStep }) =>
          !AssessmentEnabledWorkflowSteps.includes(workflowStep),
        getDisabledTooltip: () => "Pending Student interview",
      },
      {
        name: "Pre-Rejected",
        status: TripRegistrationStatuses.Pending,
        workflowStep: TripRegistrationWorkflowSteps.PreRejected,
        getDisabled: () => false, // can be set from any prior status
        getDisabledTooltip: () => "Pending Student interview",
      },
      {
        name: "Pre-Waitlisted",
        status: TripRegistrationStatuses.Pending,
        workflowStep: TripRegistrationWorkflowSteps.PreWaitlisted,
        getDisabled: ({ workflowStep }) =>
          !AssessmentEnabledWorkflowSteps.includes(workflowStep),
        getDisabledTooltip: () => "Pending Student interview",
      },
    ],
  },
  {
    name: "Processed",
    workflowStep: TripRegistrationWorkflowSteps.Processed,
    getDisabled: ({ workflowStep }) =>
      !ProcessingEnabledWorkflowSteps.includes(workflowStep),
    getDisabledTooltip: () => "Pending pre-processing assessment",
    getGroupItems: ({ status }) => {
      if (
        status !== TripRegistrationStatuses.Rejected &&
        status !== TripRegistrationStatuses.Waitlisted
      )
        return null;

      const groupItems = [
        {
          name: "Accepted",
          status: TripRegistrationStatuses.Accepted,
          workflowStep: TripRegistrationWorkflowSteps.Processed,
          getDisabled: () => false,
          getDisabledTooltip: () => "",
          color: getRegistrationStatusColor(TripRegistrationStatuses.Accepted),
        },
      ];

      if (status === TripRegistrationStatuses.Rejected) {
        groupItems.push({
          name: "Waitlisted",
          status: TripRegistrationStatuses.Waitlisted,
          workflowStep: TripRegistrationWorkflowSteps.Processed,
          getDisabled: () => false,
          getDisabledTooltip: () => "",
          color: getRegistrationStatusColor(
            TripRegistrationStatuses.Waitlisted
          ),
        });
      } else if (status === TripRegistrationStatuses.Waitlisted) {
        groupItems.push({
          name: "Rejected",
          status: TripRegistrationStatuses.Rejected,
          workflowStep: TripRegistrationWorkflowSteps.Processed,
          getDisabled: () => false,
          getDisabledTooltip: () => "",
          color: getRegistrationStatusColor(TripRegistrationStatuses.Rejected),
        });
      }

      return groupItems;
    },
  },
];

function RegistrationStatusMenuItem(props) {
  const {
    color,
    description,
    getDisabled,
    getDisabledTooltip,
    getGroupItems,
    isGroupItem,
    name,
    onChange,
    registration,
    rightAlignMenu,
    status,
    workflowStep,
  } = props;

  const groupItems = getGroupItems && getGroupItems(registration);

  const stepNumber =
    TripRegistrationWorkflowStepNumbers[
      workflowStep || groupItems[0]?.workflowStep
    ];
  const registrationStepNumber =
    TripRegistrationWorkflowStepNumbers[registration.workflowStep];

  const isPast = registrationStepNumber > stepNumber;
  const isCurrent = status
    ? registration.status === status &&
      registration.workflowStep === workflowStep // determines 'current' standard workflow/status item
    : registrationStepNumber === stepNumber; // determines 'current' grouping

  const isDisabled = isPast || isCurrent || getDisabled(registration);
  const disabledTooltip =
    isDisabled && !isPast && !isCurrent ? getDisabledTooltip(registration) : "";

  const showCompletion = !!registration.workflowHistory;
  const isCompleted =
    showCompletion &&
    registration.workflowHistory.some((h) =>
      workflowStep
        ? h === workflowStep
        : groupItems?.map((i) => i.workflowStep)?.includes(h)
    );

  const [groupAnchorEl, setGroupAnchorEl] = useState(null);
  const groupOpen = Boolean(groupAnchorEl);

  const handleGroupClick = useCallback((event) => {
    setGroupAnchorEl(event.currentTarget);
  }, []);
  const handleGroupClose = useCallback(() => {
    setGroupAnchorEl(null);
  }, []);

  const handleClick = useCallback(() => {
    let statusUpdate = status;

    if (workflowStep === TripRegistrationWorkflowSteps.Processed && !status) {
      switch (registration.workflowStep) {
        case TripRegistrationWorkflowSteps.PreAccepted:
          statusUpdate = TripRegistrationStatuses.Accepted;
          break;
        case TripRegistrationWorkflowSteps.PreRejected:
          statusUpdate = TripRegistrationStatuses.Rejected;
          break;
        case TripRegistrationWorkflowSteps.PreWaitlisted:
          statusUpdate = TripRegistrationStatuses.Waitlisted;
          break;
        default:
          return;
      }
    }

    onChange && onChange(statusUpdate, workflowStep);
  }, [onChange, registration.workflowStep, status, workflowStep]);

  const itemClassName = `status-menu-item tooltip-container${
    isCurrent
      ? groupItems
        ? " link-text-secondary"
        : ""
      : isDisabled
      ? " accent-text"
      : " link-text-secondary"
  }${description ? " descriptive" : ""} `;

  return (
    <div>
      <div
        className={itemClassName}
        onClick={
          groupItems ? handleGroupClick : isDisabled ? undefined : handleClick
        }
        style={{ color: color }}
      >
        <div>
          <div className="flex flex-align-center">
            {!isGroupItem && stepNumber && (
              <>
                {showCompletion && (
                  <span style={{ width: "28px" }}>
                    {isCompleted && (
                      <i className="material-icons xl-text">check</i>
                    )}
                  </span>
                )}
                {stepNumber}.{" "}
              </>
            )}
            {name}
          </div>
          {description && <div className="small-text">{description}</div>}
        </div>
        {groupItems ? (
          <i className="material-icons xl-text ml-8">keyboard_arrow_right</i>
        ) : (
          disabledTooltip && <span className="tooltip">{disabledTooltip}</span>
        )}
      </div>
      {groupItems && (
        <Popover
          anchorEl={groupAnchorEl}
          {...(rightAlignMenu
            ? {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              }
            : {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "right",
                },
              })}
          className={`trip-registration-status-menu sub-menu${
            groupItems.length <= 2 ? "-small" : ""
          }`}
          open={groupOpen}
          onClose={handleGroupClose}
        >
          {groupItems.map((item, i) => (
            <RegistrationStatusMenuItem
              {...item}
              isGroupItem={true}
              key={i}
              onChange={onChange}
              registration={registration}
            />
          ))}
        </Popover>
      )}
    </div>
  );
}

function RegistrationStatusMenu(props) {
  const {
    forBulkUpdate,
    forTableRecord,
    loading,
    onChange,
    placeholder,
    registration,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = useCallback(
    (...changeProps) => {
      if (loading) return;

      setAnchorEl(null);
      onChange(...changeProps);
    },
    [loading, onChange]
  );

  const styleTextColor =
    forTableRecord &&
    registration.workflowStep === TripRegistrationWorkflowSteps.Processed;
  const styleTag = !forBulkUpdate && !forTableRecord;
  const rightAlignMenu = !forBulkUpdate && !forTableRecord;

  return (
    <div>
      <div
        className={`trip-registration-status-menu-button ${
          styleTag ? "registration-tag small-text" : open ? "open" : ""
        }`}
        onClick={handleClick}
        style={{
          ...(styleTag && {
            backgroundColor: getRegistrationStatusColor(registration.status),
          }),
          cursor: "pointer",
        }}
      >
        <div
          style={{
            ...(styleTextColor && {
              color: getRegistrationStatusColor(registration.status),
            }),
          }}
        >
          {placeholder ||
            getTravelTripRegistrationStatusWorkflowDisplay(registration)}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <i className="material-icons medium-text ml-8">keyboard_arrow_down</i>
        )}
      </div>
      <Popover
        anchorEl={anchorEl}
        {...(rightAlignMenu
          ? {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }
          : {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            })}
        className={`trip-registration-status-menu${
          forBulkUpdate ? " bulk" : ""
        }`}
        onClose={handleClose}
        open={open}
      >
        {registrationStatusMenuItems.map((menuItem, index) => (
          <RegistrationStatusMenuItem
            {...menuItem}
            key={index}
            onChange={handleChange}
            registration={registration}
            rightAlignMenu={rightAlignMenu}
          />
        ))}
      </Popover>
    </div>
  );
}

export default React.memo(RegistrationStatusMenu);
