import React from "react";
import { Link } from "react-router";

import ProgramsApi from "../../services/resources/ProgramsApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";

export default class ProgramBreadcrumbsHeader extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    programSchedule: null,
  };

  _mounted = true;

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (this.props.scheduleId) {
      this.getProgramSchedule();
    }
  }

  componentDidUpdate(prevProps) {
    const { scheduleId } = this.props;

    if (prevProps.scheduleId !== scheduleId) {
      this.getProgramSchedule();
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
    this._mounted = false;
  }

  getProgramSchedule = async () => {
    if (!this._mounted) {
      return;
    }

    const { scheduleId } = this.props;
    if (scheduleId) {
      try {
        //For now we are disregarding errorMessage and loading - not going to show anything if this fails
        this.setState({
          errorMessage: "",
          loading: true,
        });

        const programSchedule = await ProgramsApi.getBasicProgramSchedule(
          this.apiSignal.token,
          scheduleId,
        );

        if (this._mounted) {
          this.setState({
            loading: false,
            programSchedule,
          });
        }
      } catch (err) {
        if (!axios.isCancel(err) && this._mounted) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
            programSchedule: null,
          });
        }
      }
    } else {
      if (this._mounted) {
        this.setState({
          errorMessage: "",
          loading: false,
          programSchedule: null,
        });
      }
    }
  };

  render() {
    const {
      className,
      getPageTitle,
      getPrevPages,
      pageTitle,
      prevPages,
      style,
    } = this.props;

    const { programSchedule } = this.state;
    const { programID, programName, scheduleID, scheduleName } =
      programSchedule || {};

    return (
      <div
        className={`accent-text-dark small-text mb-16 breadcrumbs-container ${
          className || ""
        }`}
        style={style}
      >
        {!!programSchedule && (
          <React.Fragment>
            <Link className="link-text" to="/programs">
              Programs
            </Link>
            {!!programID && programName && (
              <React.Fragment>
                {" "}
                >{" "}
                <Link
                  className="link-text"
                  to={`/programs/${programID}${
                    scheduleID ? `?sched=${scheduleID}` : ""
                  }`}
                >
                  {programName}
                </Link>
              </React.Fragment>
            )}
            {((getPrevPages && getPrevPages(programSchedule)) || prevPages) &&
              (
                (getPrevPages && getPrevPages(programSchedule)) ||
                prevPages
              ).map((page, index) => (
                <React.Fragment key={index}>
                  {" "}
                  >{" "}
                  <Link className="link-text" to={page.path}>
                    {page.title}
                  </Link>
                </React.Fragment>
              ))}
            {(getPageTitle || pageTitle || scheduleName) && (
              <React.Fragment>
                {" "}
                >{" "}
                <span>
                  {(getPageTitle && getPageTitle(programSchedule)) ||
                    pageTitle ||
                    scheduleName}
                </span>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}
