import React from "react";
import Select from "react-select";

const RaffleEventsCard = (props) => {
  const {
    rafflesList,
    raffleDetails: { raffleTitle },
    onChangeRaffleEvent,
    scheduleId,
  } = props;

  return scheduleId &&
    rafflesList &&
    rafflesList.length > 0 &&
    rafflesList.map((p) => p.programScheduleID).indexOf(scheduleId) >= 0 ? (
    <Select
      className="xl-text fw-700 flex trip-event-select custom-select full-width"
      clearable={false}
      onChange={({ value }) => onChangeRaffleEvent(value)}
      options={
        rafflesList &&
        rafflesList.map((l) => ({
          className: l.isCurrent ? "fw-700" : "fw-400",
          label: l.title,
          value: l.programScheduleID,
        }))
      }
      searchable={false}
      value={scheduleId}
    />
  ) : (
    <p className="xl-text fw-700">{raffleTitle}</p>
  );
};

export default React.memo(RaffleEventsCard);
