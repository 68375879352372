import axios from "axios";
import AuthService from "../AuthService";
import urlBase from "../UrlBaseUtil";

/** @param {AxiosRequestConfig} config */
function applyAuthHeaders(config) {
  config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
  return config;
}
/** Client to use for new API. Use `AuthRequest` for the legacy API calls. */
export const AuthClient = axios.create({
  baseURL: urlBase.getApiBase(),
});
AuthClient.interceptors.request.use(applyAuthHeaders);

export default AuthClient;
