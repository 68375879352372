import React from "react";
import { formatFullName } from "../../../lib";
import moment from "moment";

export default class CourseSchedulesTableRow extends React.PureComponent {
  render() {
    const {
      courseSchedule: {
        campusName,
        isCompleted,
        isCurrent,
        isUpcoming,
        name: courseName,
        nextClassDate,
        numOfClassesCompleted,
        numOfClassesScheduled,
        numOfStudents,
        semester,
        shliachFirstName,
        shliachLastName,
        shluchaFirstName,
        year,
      },
      onViewDetails,
    } = this.props;

    return (
      <div className="courses-schedules-table-row" onClick={onViewDetails}>
        <p>{courseName}</p>
        <div>
          <p className="fw-700">{campusName}</p>
          {shliachFirstName && (
            <p>{formatFullName(shliachFirstName, shliachLastName)}</p>
          )}
          {shluchaFirstName && (
            <p>{formatFullName(shluchaFirstName, shliachLastName)}</p>
          )}
        </div>
        <p>
          {semester} {year}
        </p>
        {/* if no nextClassDate, it can be bec it's completed, but if it's not completed, then it means it's bec it's TBD */}
        <p>
          {nextClassDate
            ? moment(nextClassDate).format("MM/DD/YYYY")
            : isCompleted
            ? ""
            : "TBD"}
        </p>
        <p>
          {numOfClassesCompleted}/{numOfClassesScheduled}
        </p>
        <p>{numOfStudents}</p>
        <p>
          {isCompleted
            ? "Completed"
            : isCurrent
            ? "Current"
            : isUpcoming
            ? "Upcoming"
            : ""}
        </p>
      </div>
    );
  }
}
