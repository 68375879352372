import React from "react";
import Select from "react-select";
import { EnumToArrayOfObjects } from "../../../lib/coc-common-scripts";

// import { ReactSelectOption, Country, State } from "../../../models/Common";
import {
  /* OrganizationFilter as OrgFilterModel, */ CategoryTypes /* , OrganizationCategory */,
} from "../../../models/Organization";
import { getSelectValues } from "../../common/Utils";

// interface OrganizationFilterProps {
//   filterState: OrgFilterModel;
//   ddStyles?: { [styleName: string]: string };
//   organizationCategories: Array<OrganizationCategory>;
//   countryList: Array<Country>;
//   stateList: Array<State>;
//   handleFiltersChanged: (newFilterState: OrgFilterModel) => void;
// }

const OrganizationFilter = ({
  filterState,
  ddStyles = { paddingBottom: "3px" },
  organizationCategories,
  countryList,
  stateList,
  handleFiltersChanged,
}) => {
  const updateFilterDomElement = (evt) => {
    const catTypeChanged =
      evt.currentTarget.name === "organizationCategoryType";
    const newFilterState = {
      ...filterState,
      categoryId: catTypeChanged ? undefined : filterState.categoryId,
      [evt.currentTarget.name]: evt.currentTarget.value,
    };
    handleFiltersChanged(newFilterState);
  };
  /** @param {ReactSelectOption} category */
  const updateFilterCategory = (category) => {
    const newFilterState = {
      ...filterState,
      categoryId: category && category.value,
    };
    handleFiltersChanged(newFilterState);
  };
  /** @param {Array<ReactSelectOption>} validStates */
  const getValidStatesFromFilters = (validStates) => {
    return getSelectValues(filterState.states)
      .filter((s) => validStates.find((vs) => vs.value === s))
      .join(",");
  };
  /** @returns {ReactSelectOption} */
  const itemToOption = (item) => ({ value: item.id, label: item.name });

  /** @param {string} selectedCountryIds
   * @returns {Array<ReactSelectOption>}
   */
  const getFilteredStates = (selectedCountryIds) => {
    const selectedCountries = getSelectValues(selectedCountryIds);
    const newStateList = stateList.filter(
      (s) => selectedCountries.indexOf(s.countryId) >= 0,
    );
    return newStateList.map(itemToOption);
  };
  /** @param {string} countries */
  const updateFilterCountries = (countries) => {
    const validStatesFilters = getValidStatesFromFilters(
      getFilteredStates(countries),
    );
    const newFilterState = {
      ...filterState,
      countries,
      states: validStatesFilters,
    };
    handleFiltersChanged(newFilterState);
  };
  /** @param {string} states */
  const updateFilterStates = (states) => {
    const newFilterState = { ...filterState, states };
    handleFiltersChanged(newFilterState);
  };
  /** @returns {Array<ReactSelectOption>} */
  const getFilteredCategories = () =>
    organizationCategories
      .filter(
        (oc) =>
          ((filterState.organizationCategoryType &&
            +filterState.organizationCategoryType) ||
            0) === 0 ||
          oc.type === Number(filterState.organizationCategoryType),
      )
      .map(itemToOption);

  return (
    <div>
      <h3>Organization Filters</h3>
      <dl className="dl-horizontal">
        <dt>Name</dt>
        <dd style={ddStyles}>
          <input
            type="text"
            name="name"
            className="form-control"
            value={filterState.name || ""}
            onChange={updateFilterDomElement}
            required={true}
          />
        </dd>
        <dt>Category Type</dt>
        <dd style={ddStyles}>
          <select
            name="organizationCategoryType"
            className="form-control"
            value={filterState.organizationCategoryType || ""}
            onChange={updateFilterDomElement}
          >
            <option key={0} value={0}>
              All
            </option>
            {EnumToArrayOfObjects(CategoryTypes).map((ct) => (
              <option key={ct.code} value={ct.code}>
                {ct.value}
              </option>
            ))}
          </select>
        </dd>
        {filterState.organizationCategoryType !== CategoryTypes.Venue && (
          <div>
            <dt>Category</dt>
            <dd style={ddStyles}>
              <Select
                name="categoryId"
                options={getFilteredCategories()}
                onChange={updateFilterCategory}
                value={filterState.categoryId || ""}
                multi={false}
                required={true}
                style={{ minWidth: "150px" }}
              />
            </dd>
          </div>
        )}

        <dt>Country</dt>
        <dd style={ddStyles}>
          <div style={{ display: "table", width: "100%" }}>
            <div style={{ display: "table-row" }}>
              <div style={{ display: "table-cell", paddingTop: "2px" }}>
                <Select
                  name="country"
                  options={countryList.map(itemToOption)}
                  onChange={updateFilterCountries}
                  multi={true}
                  simpleValue={true}
                  value={getSelectValues(filterState.countries)}
                  separator={","}
                  style={{ minWidth: "150px" }}
                />
              </div>
            </div>
          </div>
        </dd>
        <dt>State</dt>
        <dd style={ddStyles}>
          <div style={{ display: "table", width: "100%" }}>
            <div style={{ display: "table-row" }}>
              <div style={{ display: "table-cell", paddingTop: "2px" }}>
                <Select
                  name="state"
                  options={getFilteredStates(filterState.countries) || []}
                  onChange={updateFilterStates}
                  multi={true}
                  simpleValue={true}
                  value={getSelectValues(filterState.states)}
                  style={{ minWidth: "150px" }}
                  closeOnSelect={false}
                />
              </div>
            </div>
          </div>
        </dd>
      </dl>
    </div>
  );
};

export default OrganizationFilter;
