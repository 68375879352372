import React from "react";
import FormInputUpdate from "../../Form/FormInput";
import PhoneInput from "../../Form/PhoneInput";

export default class ContactInfo extends React.PureComponent {
  state = {
    contactInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        contactInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      touched,
      values,
      readOnly,
      setFieldError,
      setFieldValue,
      sys: { countries, chabadHouseContactMethodTypes },
    } = this.props;
    return (
      <div
        className={
          this.state.contactInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <div className="flex mobile-flex-justify-space flex-align-center mb-8 mt-48">
          <p
            className="accent-text-dark medium-text fw-700"
            onClick={() => {
              this.setState({
                contactInfoShowing: !this.state.contactInfoShowing,
              });
            }}
          >
            Contact Information
          </p>
        </div>
        <div className="profile-form-contact-info ch-form-contact-info">
          <div className="flex flex-align-center mb-16 hover-delete-container mobile-card">
            <div className="relative">
              <FormInputUpdate
                type="Email"
                name={`email.value`}
                label="Email"
                errors={errors}
                touched={touched}
                readOnly={readOnly}
                className="custom-input mr-24"
                style={{ flex: "3" }}
              />
            </div>
            <div className="relative">
              <FormInputUpdate
                name={`phone.value`}
                errors={errors}
                touched={touched}
                className="custom-input mr-12"
                style={{ flex: "2" }}
                label="Phone"
              >
                {({ field }) => (
                  <PhoneInput
                    {...field}
                    countries={countries || []}
                    countryFieldName={`phone.phoneCountryID`}
                    countryId={values.phone?.phoneCountryID || ""}
                    defaultCountryName={
                      values.address && values.address.country
                    }
                    errors={errors}
                    readOnly={readOnly}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    touched={touched}
                  />
                )}
              </FormInputUpdate>
            </div>
            <div className="relative">
              <FormInputUpdate
                label="Ext"
                type="number"
                name={`phone.phoneExtension`}
                errors={errors}
                touched={touched}
                className="custom-input mr-24"
              />
            </div>
            <div className="relative">
              <FormInputUpdate
                name={`smsContactMethod.value`}
                errors={errors}
                touched={touched}
                className="custom-input mr-12"
                style={{ flex: "2", width: "100%" }}
                label="SMS"
              >
                {({ field }) => (
                  <PhoneInput
                    {...field}
                    countries={countries || []}
                    countryFieldName={`smsContactMethod.phoneCountryID`}
                    countryId={values.smsContactMethod?.phoneCountryID}
                    defaultCountryName={
                      values.address && values.address.country
                    }
                    errors={errors}
                    readOnly={readOnly}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    touched={touched}
                  />
                )}
              </FormInputUpdate>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
