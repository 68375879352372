import React from "react";

import CustomSelect from "../../Form/CustomSelect";
import MultiSelect from "../../Form/MultiSelect";
import FormInput from "../../Form/FormInput";
import FormInputUpdate from "../../Form/FormInputUpdate";

import { slugify } from "../../../../lib/utils";

export default class GeneralInfo extends React.PureComponent {
  state = {
    generalInfoShowing: true,
    showSlugUpdateWarning: false,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        generalInfoShowing: false,
      });
    }
  }

  componentDidUpdate() {
    const { initialValues, values: { id, slug } = {} } = this.props;
    if (
      id &&
      (initialValues.slug !== slug) !== this.state.showSlugUpdateWarning
    ) {
      this.setState({ showSlugUpdateWarning: initialValues.slug !== slug });
    }
  }

  addressLabelError = (touched, errors) => {
    return (
      touched &&
      touched.address &&
      touched.address.address1 === true &&
      touched.address.country === true &&
      touched.address.city === true &&
      touched.address.state === true &&
      touched.address.zip === true &&
      errors &&
      errors &&
      errors.address &&
      (errors.address.address1 === true ||
        errors.address.country === true ||
        errors.address.city === true ||
        errors.address.state === true ||
        errors.address.zip === true)
    );
  };

  getYearsOptions = () => {
    let year = new Date().getFullYear();
    let years = [];
    for (let i = 1940; i <= year; i++) {
      years.push(i);
    }
    return years;
  };

  setNameOrSlug = (evt, isBlur) => {
    const {
      setFieldValue,
      values: { id: chabadHouseId },
    } = this.props;
    const {
      target: { name, value },
    } = evt;

    const isNewChabadHouse = !chabadHouseId;

    if (name === "name") {
      setFieldValue(name, value);
    }

    if (name === "slug" || isNewChabadHouse) {
      // for new CH, set slug to slugified CH name when name is entered
      const slugifiedValue = slugify(value, isBlur);
      setFieldValue("slug", slugifiedValue);
    }
  };

  render() {
    const {
      errors,
      require,
      touched,
      readOnly,
      values,
      sys: { demographics, regions },
      setFieldValue,
    } = this.props;

    const { showSlugUpdateWarning } = this.state;

    const isNew = !values.id;
    const years = this.getYearsOptions();

    return (
      <div
        className={
          this.state.generalInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
        style={{ position: "relative" }}
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-16 mb-16"
          onClick={() => {
            this.setState({
              generalInfoShowing: !this.state.generalInfoShowing,
            });
          }}
        >
          {!isNew ? values.name : null}
        </p>
        <p
          className="accent-text-dark desktop-hidden tablet-hidden"
          style={{ padding: "0", marginTop: "-8px" }}
        >
          {this.state.generalInfoShowing ? "" : "General Information"}
        </p>

        <div className="profile-form-inputs">
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInputUpdate
              label="Name"
              type="text"
              name="name"
              validate={require}
              className="form-required"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
              onChange={this.setNameOrSlug}
              onBlur={(e) => this.setNameOrSlug(e, true)}
            />
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInputUpdate
              label="Web Page Slug"
              labelTag={
                <span
                  className="tooltip-container"
                  style={{ position: "absolute", left: "74px" }}
                >
                  <i className="material-icons large-text link-text no-hover">
                    info
                  </i>
                  <span className="tooltip tooltip-medium-width">
                    This is a URL friendly version of the Chabad House Name for
                    use in web page links
                    {isNew
                      ? ". For a new Chabad House, this field is automatically generated based on the Chabad House Name, and can be overridden here as well."
                      : ""}
                  </span>
                </span>
              }
              type="text"
              name="slug"
              className="form-required"
              validate={require}
              errors={errors}
              touched={touched}
              readOnly={readOnly}
              onChange={this.setNameOrSlug}
              onBlur={(e) => this.setNameOrSlug(e, true)}
            />
          </div>
          {showSlugUpdateWarning && (
            <div
              className="error-text small-text"
              style={{ gridColumnStart: "span 2", marginLeft: "104px" }}
            >
              * Any changes made to this field will affect existing web page
              links already generated eg RSVP, Grand Draw, etc
            </div>
          )}
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Region"
              name="regionID"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <CustomSelect
                  placeholder="Select Region..."
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  options={
                    regions &&
                    regions.map((r) => ({
                      key: r.id,
                      value: r.id,
                      label: r.name,
                    }))
                  }
                  setFieldValue={setFieldValue}
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative mr-24">
            <FormInput
              label="Website"
              type="text"
              name="websiteURL"
              errors={errors}
              readOnly={readOnly}
              touched={touched}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInputUpdate
              label="Messaging Service ID"
              labelTag={
                <span
                  className="tooltip-container"
                  style={{ position: "absolute", left: "74px" }}
                >
                  <i className="material-icons large-text link-text no-hover">
                    info
                  </i>
                  <span className="tooltip tooltip-medium-width">
                    The messaging service ID from Twilio for this Chabad House
                    sub-account
                  </span>
                </span>
              }
              type="text"
              name="messagingServiceSid"
              errors={errors}
              readOnly={readOnly}
              touched={touched}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative mr-24">
            <FormInputUpdate
              label="Messaging Service Account SID"
              labelClassNames="accent-text small-text msg-svc-label-lg"
              labelTag={
                <span className="tooltip-container msg-svc-icon">
                  <i className="material-icons large-text link-text no-hover">
                    info
                  </i>
                  <span className="tooltip tooltip-medium-width">
                    The messaging service Account SID from Twilio for this
                    Chabad House sub-account
                  </span>
                </span>
              }
              type="text"
              name="messagingAccountSid"
              errors={errors}
              readOnly={readOnly}
              touched={touched}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInputUpdate
              label="Messaging Service Auth Token"
              labelClassNames="accent-text small-text msg-svc-label-lg"
              labelTag={
                <span className="tooltip-container msg-svc-icon">
                  <i className="material-icons large-text link-text no-hover">
                    info
                  </i>
                  <span className="tooltip tooltip-medium-width">
                    The messaging service Auth Token from Twilio for this Chabad
                    House sub-account
                  </span>
                </span>
              }
              type="text"
              name="messagingAuthToken"
              errors={errors}
              readOnly={readOnly}
              touched={touched}
            />
          </div>
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
                {/* //TODO redo demographics as readonly based on shliach and shlucha data */}
            {/* <FormInput
              label="Demographics Served"
              name="demographics"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
              className="custom-input mr-12"
              style={{ flex: "2" }}
            >
              {({ field }) => (
                <MultiSelect
                  placeholder="Please select all that apply..."
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  setFieldValue={setFieldValue}
                  options={
                    demographics &&
                    demographics.map((d) => ({
                      key: d.enumValue,
                      value: d.enumValue,
                      label: d.displayValue,
                    }))
                  }
                  value={
                    values.demographics
                      ? values.demographics.map((value) =>
                          value.demographic
                            ? {
                                key: value.demographic,
                                value: value.demographic,
                                label: value.demographic,
                              }
                            : value,
                        )
                      : null
                  }
                />
              )}
            </FormInput> */}
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Tax ID"
              type="text"
              name="taxID"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
        </div>
      </div>
    );
  }
}
