import React from "react";
import Loader from "../../common/Loader";
import NewProgramScheduleModal from "./ProgramSchedule/NewProgramScheduleModal";
import ScheduleContent from "./ProgramSchedule/ScheduleContent";
import ScheduleCoordinator from "./ProgramSchedule/ScheduleCoordinator";
import ScheduleDetails from "./ProgramSchedule/ScheduleDetails";
import ScrollMenu from "react-horizontal-scrolling-menu";
import ProgramsApi from "../../../services/resources/ProgramsApi";
import moment from "moment";

export default class ProgramSchedules extends React.PureComponent {
  state = {
    loading: false,
    showNewScheduleModal: false,
  };

  addNewSchedule = async (scheduleToCloneId) => {
    let schedule;
    if (scheduleToCloneId) {
      const scheduleToClone = await ProgramsApi.getSchedule(
        this.props.program.id,
        scheduleToCloneId,
      );
      if (scheduleToClone) {
        schedule = {
          ...scheduleToClone,
          id: 0,
          isPublished: false,
          hasStarted: false,
          hasStartedRegistration: false,
          title: `${scheduleToClone.title || "Untitled"} - Copy`,
        };
      }
    }

    if (!schedule) {
      schedule = {
        // id: 0,
        // title: "",
        // preRegisterStartDate: "",
        // registerStartDate: "",
        // registerEndDate: "",
        // programStartDate: "",
        // programEndDate: "",
        // hebrewProgramStartDate: "",
        // hebrewProgramEndDate: "",
        // preRegisterURL: "",
        // registerURL: "",
        // marketingMaterialURL: "",
        // email: "",
        // programImageURL: "",
        // isPublished: false,
        // isOngoing: false,
        // hasStarted: false,
        includesAllShluchim: true,
        // officeStaffID: undefined,
        // contents: []
      };
    }

    this.props.actions.changeState("schedule", schedule);
    this.props.actions.changeState("loadedState.schedule", {}); //note - setting loadedState.schedule to {} to ensure that once they choose 'add new' they cannot select a dif sched and lose new sched (even prior to updating any fields)

    this.setState({ showNewScheduleModal: false });
  };

  onClickAddNewSchedule = (e) => {
    e.preventDefault();

    const { schedule, scheduleOrig } = this.props;

    if (!schedule.id) {
      return;
    }

    if (scheduleOrig !== schedule) {
      return alert(
        "Unsaved schedule changes.  Please save or publish changes before adding a new schedule.",
      );
    }

    this.setState({ showNewScheduleModal: true });
  };

  onSelectSchedule = async (scheduleId) => {
    const {
      actions: { onSelectSchedule },
      schedule,
      scheduleOrig,
    } = this.props;

    if (
      (scheduleId && scheduleId.toString()) ===
      (schedule.id && schedule.id.toString())
    ) {
      return;
    }

    if (scheduleOrig !== schedule) {
      return alert(
        "Unsaved schedule changes.  Please save or publish changes before viewing other schedules.",
      );
    }

    this.setState({ loading: true });

    await onSelectSchedule(scheduleId);

    this.setState({ loading: false });
  };

  render() {
    const {
      actions: { changeScheduleOngoing },
      program: { schedules },
      restrictedEdit,
      schedule,
      scheduleOrig,
      scrollToSchedule,
    } = this.props;

    const { loading, showNewScheduleModal } = this.state;

    const allowUpdateSchedule =
      !scheduleOrig ||
      !scheduleOrig.programEndDate ||
      moment(scheduleOrig.programEndDate).isSameOrAfter(moment());

    return (
      <div>
        <div className="flex flex-align-center flex-justify-space mb-16 program-schedules-header-bar">
          <p className="large-text">
            <i className="icon icon-calendar mr-8">
              <span className="path3" />
            </i>
            Program Schedules
          </p>
          <div className="flex flex-align-center">
            <label
              className="medium-text mr-24 flex flex-align-center"
              style={{
                fontWeight: "normal",
                cursor: "pointer",
                userSelect: "none",
                marginBottom: 0,
              }}
            >
              <input
                type="checkbox"
                id="schedule_ongoing"
                autoComplete="off"
                disabled={!allowUpdateSchedule || restrictedEdit}
                checked={schedule.isOngoing || false}
                style={{ marginRight: 8, marginTop: 0 }}
                onChange={changeScheduleOngoing}
              />{" "}
              Ongoing
            </label>

            <button
              className="btn btn-accent"
              disabled={!schedule.id || schedule.isOngoing || restrictedEdit}
              onClick={this.onClickAddNewSchedule}
              style={{
                height: 32,
                textTransform: "uppercase",
                fontSize: 14,
                padding: "0 32px",
                lineHeight: "32px",
              }}
            >
              Add New Schedule
            </button>
          </div>
        </div>

        {schedules && schedules.length > 0 && (
          <ScrollMenu
            alignCenter={false}
            arrowLeft={
              <div className="flex flex-justify-center">
                <i className="material-icons medium-text link-text-secondary mr-8">
                  keyboard_arrow_left
                </i>
              </div>
            }
            arrowRight={
              <div className="flex flex-justify-center">
                <i className="material-icons medium-text link-text-secondary ml-8">
                  keyboard_arrow_right
                </i>
              </div>
            }
            data={schedules.map(({ id, programStartDate, title }) => (
              <div
                className={` menu-item link-text-secondary uppercase-text text-center ${
                  (id ? id.toString() : "") ===
                  (schedule.id ? schedule.id.toString() : "")
                    ? "active"
                    : schedule !== scheduleOrig
                    ? "disabled"
                    : ""
                }`}
                key={id || ""}
              >
                {title ||
                  `Untitled ${
                    programStartDate
                      ? moment(programStartDate).format("M/D/YY")
                      : ""
                  }`}
              </div>
            ))}
            hideArrows={true}
            hideSingleArrow={true}
            onSelect={this.onSelectSchedule}
            scrollToSelected={scrollToSchedule}
            selected={schedule.id ? schedule.id.toString() : ""}
            wheel={false} //disable scroll with mouse scroll
          />
        )}

        <div className="relative">
          {loading && (
            <div className="overlay-loader">
              <Loader />
            </div>
          )}

          {schedule && (
            <div>
              {!allowUpdateSchedule && (
                <p className="accent-text small-text">
                  * Schedule and Coordinator information cannot be updated after
                  Program End Date has passed
                </p>
              )}
              <ScheduleDetails
                allowUpdateSchedule={allowUpdateSchedule}
                {...this.props}
              />
              <ScheduleCoordinator
                allowUpdateSchedule={allowUpdateSchedule}
                {...this.props}
              />
              <ScheduleContent {...this.props} />
            </div>
          )}
        </div>

        <NewProgramScheduleModal
          close={() =>
            this.setState({
              showNewScheduleModal: false,
            })
          }
          continue={this.addNewSchedule}
          schedules={schedules}
          show={showNewScheduleModal}
        />
      </div>
    );
  }
}
