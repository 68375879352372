import React from "react";
import DatePicker from "react-datepicker";
import SelectSearch from "react-select";
import debounce from "lodash.debounce";
import { getYearOptions, removeTimezoneFormatFromDate } from "../../../lib";
import moment from "moment";

export default class CourseSchedulesTableFilters extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      campus: null,
      classDateFrom: null,
      classDateTo: null,
      dateFilterType: this.dateFilterTypes.Year,
      keyword: "",
      semester: null,
      status: null,
      year: null,
    };
  }

  dateFilterTypes = {
    Year: "Year",
    Semester: "Semester",
    "Class Dates": "Class Dates",
  };

  scheduleYearOptions = getYearOptions(
    new Date().getFullYear() - 15,
    new Date().getFullYear() + 5,
  );

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onChangeDateFilterType = (dateFilterType) => {
    if (dateFilterType === this.state.dateFilterType) {
      return;
    }

    this.setState({ dateFilterType }, () => {
      // clear out previous date filters
      const { classDateFrom, classDateTo, semester, year } = this.state;
      if (classDateFrom || classDateTo || semester || year) {
        this.setState(
          {
            classDateFrom: null,
            classDateTo: null,
            semester: null,
            year: null,
          },
          this.onFilter,
        );
      }
    });
  };

  onFilter = () => {
    const {
      campus,
      classDateFrom,
      classDateTo,
      keyword,
      semester,
      status,
      year,
    } = this.state;

    this.props.applyFilters({
      campusId: campus ? campus.value : "",
      classDateFrom,
      classDateTo,
      keyword,
      semester: semester ? semester.value : "",
      year: year ? year.value : "",
      ...(status ? { [status.value]: true } : {}),
    });
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const {
      eduChabadHouses: { eduChabadHousesList, eduChabadHousesListLoading },
      eduCourseSemesters,
      eduProgram,
    } = this.props;

    const {
      campus,
      classDateFrom,
      classDateTo,
      dateFilterType,
      keyword,
      semester,
      status,
      year,
    } = this.state;

    return (
      <div className="full-width courses-schedules-table-filters">
        <div className="flex">
          <div className="search-input mr-24">
            <input
              type="text"
              placeholder="Search Course Name"
              name="keyword"
              onChange={(event) =>
                this.onChange(event.target.name, event.target.value, true)
              }
              value={keyword}
            />
            <i className="material-icons accent-text-secondary flex flex-align-center">
              search
            </i>
          </div>
          <div className="mr-24">
            <SelectSearch
              onChange={(val) => this.onChange("campus", val)}
              options={
                eduChabadHousesList &&
                eduChabadHousesList.campuses &&
                eduChabadHousesList.campuses.map((c) => ({
                  value: c.campusID,
                  label: c.campusName,
                }))
              }
              placeholder={
                eduChabadHousesListLoading
                  ? "Loading All Campuses..."
                  : "All Campuses"
              }
              style={{ height: "36px" }}
              value={campus}
            />
          </div>
          <SelectSearch
            className="mr-24"
            onChange={(val) => this.onChange("status", val)}
            options={[
              {
                label: "Current",
                value: "isCurrent",
              },
              {
                label: "Completed",
                value: "isCompleted",
              },
              {
                label: "Upcoming",
                value: "isUpcoming",
              },
            ]}
            placeholder="All Statuses"
            searchable={false}
            style={{ height: "36px" }}
            value={status}
          />
        </div>
        <div className="flex mt-16">
          <div className="flex flex-align-center">
            <div className="flex flex-align-center mr-8">
              <p>Filter by</p>
              <select
                className="no-border-select accent-text-secondary ml-8"
                onChange={(e) => this.onChangeDateFilterType(e.target.value)}
                name="dateFilterType"
                value={dateFilterType}
              >
                {Object.keys(this.dateFilterTypes).reduce((acc, filterType) => {
                  if (
                    filterType === "Semester" &&
                    eduProgram.type === "YourIsrael"
                  ) {
                    return [...acc];
                  }
                  return [
                    ...acc,
                    <option key={filterType} value={filterType}>
                      {filterType}
                    </option>,
                  ];
                }, [])}
              </select>
            </div>
            {dateFilterType === this.dateFilterTypes.Year ||
            dateFilterType === this.dateFilterTypes.Semester ? (
              <>
                {dateFilterType === this.dateFilterTypes.Semester && (
                  <div className="mr-16">
                    <SelectSearch
                      onChange={(val) => this.onChange("semester", val)}
                      options={
                        eduCourseSemesters &&
                        eduCourseSemesters.map((s) => ({
                          value: s.enumValue,
                          label: s.displayValue,
                        }))
                      }
                      placeholder="All Semesters"
                      style={{ height: "36px" }}
                      value={semester}
                    />
                  </div>
                )}
                <div>
                  <SelectSearch
                    onChange={(val) => this.onChange("year", val)}
                    options={
                      this.scheduleYearOptions &&
                      this.scheduleYearOptions.map((yr) => ({
                        value: yr,
                        label: yr,
                      }))
                    }
                    placeholder="All Years"
                    style={{ height: "36px" }}
                    value={year}
                  />
                </div>
              </>
            ) : (
              <div>
                <DatePicker
                  className="custom-input"
                  isClearable={true}
                  onChange={(date) =>
                    this.onChange(
                      "classDateFrom",
                      removeTimezoneFormatFromDate(date),
                    )
                  }
                  placeholderText="Date From"
                  selected={classDateFrom && moment(classDateFrom)}
                  selectsStart
                  startDate={classDateFrom && moment(classDateFrom)}
                  endDate={classDateTo && moment(classDateTo)}
                />
                <DatePicker
                  className="custom-input ml-8"
                  isClearable={true}
                  onChange={(date) =>
                    this.onChange(
                      "classDateTo",
                      removeTimezoneFormatFromDate(date),
                    )
                  }
                  placeholderText="Date To"
                  selected={classDateTo && moment(classDateTo)}
                  selectsEnd
                  startDate={classDateFrom && moment(classDateFrom)}
                  endDate={classDateTo && moment(classDateTo)}
                  minDate={classDateFrom && moment(classDateFrom)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
