import React from "react";
import { Link } from "react-router";
import { CSVLink } from "react-csv";
import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import TefillinReportingApi from "../../services/resources/TefillinReportingApi";
import DataTable /* , { DataTableColumn } */ from "../common/DataTable";
import DatePicker from "../common/DateRangePicker";

export class TefillinReportingByStudent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      startDate: moment.utc(+this.props.location.query.startDate),
      endDate: moment.utc(+this.props.location.query.endDate),
      reportData: [],
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.requestReportData = this.requestReportData.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.TefillinDetailed)) {
      return;
    }

    this.requestReportData();
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState({ [dateFieldName]: selectedDate }, this.requestReportData);
    };
  }

  requestReportData() {
    this.setState({ loading: true });

    TefillinReportingApi.getByStudents(
      this.state.startDate.clone().startOf("day"),
      this.state.endDate.clone().endOf("day"),
    )
      .then((response) => {
        this.setState({
          reportData: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.TefillinDetailed)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasDirectoryPermission = AuthService.UserHasClaim(
      PermissionClaims.DirectoryRead,
    );

    const { reportData, startDate, endDate } = this.state;
    const students = (reportData && reportData.students) || [];

    const currencyFormatterUS = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    /** @type {Array<DataTableColumn>} */
    const columns = [
      {
        Header: "Full Name",
        id: "name",
        accessor: (c) => c.lastName + " " + c.firstName,
        Cell: (c) =>
          hasDirectoryPermission ? (
            <Link to={`/students/${c.original.contactId}`}>
              {c.original.firstName + " " + c.original.lastName}
            </Link>
          ) : (
            c.original.firstName + " " + c.original.lastName
          ),
      },
      {
        Header: "School",
        accessor: "schoolName",
        Cell: (c) =>
          hasDirectoryPermission ? (
            <Link to={`/schools/${c.original.schoolId}`}>
              {c.original.schoolName}
            </Link>
          ) : (
            c.original.schoolName
          ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        maxWidth: 120,
        Cell: (c) => (
          <span>{currencyFormatterUS.format(c.original.amount)}</span>
        ),
        style: { textAlign: "right" },
      },
    ];

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/tefillin">Tefillin</Link>
              </li>
              <li>
                <Link to="/tefillin">Reports</Link>
              </li>
              <li>Students</li>
            </ol>
          </div>
          <div className="col-sm-10">
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              handleDateInputChange={this.handleDateInputChange}
            />
          </div>
          <div className="col-sm-2" style={{ marginBottom: "1em" }}>
            <CSVLink
              className="btn btn-primary btn-md"
              data={students}
              filename={`TefillinStudents_${startDate.format(
                "YYYY-MM-DD",
              )}_${endDate.format("YYYY-MM-DD")}.csv`}
            >
              Download CSV
            </CSVLink>
          </div>
          <div className="col-sm-12">
            <DataTable
              fullClientSide={true}
              columns={columns}
              reportData={students}
            />
          </div>
          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }
}
