import React from "react";
import Select /*, { Creatable }*/ from "react-select";

import { UpdateDOMProperty } from "../Scripts/ComponentState";
// import { ReactSelectOption } from "../Models/LibraryComponents";
import {
  /* Address, */ EmptyAddress /* , CountryHlp, StateHlp */,
} from "../Models/Common";

// export interface AddressUIProps {
//   addressData: Address;
//   // need to have iso code as id to implement this -> defaultCountry?: string;
//   countries: Array<CountryHlp>;
//   states: Array<StateHlp>;
//   required?: boolean;
//   addressGrouper?: string;
//   disabled: boolean;
//   handleChange: (newValue: Address) => void;
// }

export const AddressUI = ({
  addressData = EmptyAddress(),
  countries = [],
  states = [],
  required = false,
  addressGrouper = "address",
  disabled = true,
  handleChange,
}) => {
  /** @param {React.FormEvent<HTMLInputElement>} evt */
  const handleDOMDataChange = (evt) =>
    handleChange(UpdateDOMProperty(evt, addressData));
  /** @param {ReactSelectOption} newCountry */
  const handleCountryChange = (newCountry) => {
    /** @type {Address} */
    const newAddress = {
      ...addressData,
      countryId: newCountry && newCountry.value,
      country: newCountry && newCountry.label,
      stateId: undefined,
      state: "",
    };
    handleChange(newAddress);
  };
  /** @param {ReactSelectOption} newState */
  const handleStateChange = (newState) => {
    /** @type {Address} */
    const newAddress = {
      ...addressData,
      stateId: newState && newState.value,
      state: newState && newState.label,
    };
    handleChange(newAddress);
  };

  const filteredStates = states.filter(
    (s) =>
      !addressData ||
      addressData.country === "United States" ||
      addressData.country === "USA",
  );

  const statesDisabled =
    disabled ||
    (addressData.country !== "United States" && addressData.country !== "USA");

  return (
    <fieldset className="container">
      <div className="row">
        <div className="row">
          <fieldset className="col-sm-4">
            <label
              className={"form-label " + (required ? "form-required" : "")}
              htmlFor="address_line_1"
            >
              Line 1
            </label>
            <input
              type="text"
              name="addressLine1"
              value={addressData.addressLine1 || ""}
              id="address_line_1"
              required={required}
              autoComplete={"section-addr_" + addressGrouper + " address-line1"}
              disabled={disabled}
              onChange={handleDOMDataChange}
            />
          </fieldset>
          <fieldset className="col-sm-4">
            <label className="form-label" htmlFor="address_line_2">
              Line 2
            </label>
            <input
              type="text"
              name="addressLine2"
              value={addressData.addressLine2 || ""}
              id="address_line_2"
              autoComplete={"section-addr_" + addressGrouper + " address-line2"}
              disabled={disabled}
              onChange={handleDOMDataChange}
            />
          </fieldset>
        </div>
        <div className="row">
          <fieldset className="col-sm-4">
            <label
              className={"form-label " + (required ? "form-required" : "")}
              htmlFor="city"
            >
              City
            </label>
            <input
              type="text"
              name="city"
              value={addressData.city || ""}
              id="city"
              required={required}
              autoComplete={
                "section-addr_" + addressGrouper + " address-level2"
              }
              disabled={disabled}
              onChange={handleDOMDataChange}
            />
          </fieldset>
          <fieldset className="col-sm-4">
            <label
              className={"form-label " + (required ? "form-required" : "")}
              htmlFor="postal_code"
            >
              ZIP
            </label>
            <input
              type="text"
              name="postalCode"
              value={addressData.postalCode || ""}
              id="postal_code"
              required={required}
              autoComplete={"section-addr_" + addressGrouper + " postal-code"}
              disabled={disabled}
              onChange={handleDOMDataChange}
            />
          </fieldset>
        </div>
        <div className="row">
          <fieldset className="col-sm-4">
            <label
              className={"form-label " + (required ? "form-required" : "")}
              htmlFor="country"
            >
              Country
            </label>
            <Select
              name="country"
              options={countries.map((c) => ({ value: c.id, label: c.name }))}
              multi={false}
              value={addressData.countryId || addressData.country || ""}
              placeholder={"Select"}
              required={required}
              autoComplete={"section-addr_" + addressGrouper + " country-name"}
              disabled={disabled}
              onChange={handleCountryChange}
            />
          </fieldset>
          <fieldset className="col-sm-4">
            <label
              className={
                "form-label " +
                (required &&
                (addressData.country === "United States" ||
                  addressData.country === "USA")
                  ? "form-required"
                  : "")
              }
              htmlFor="state"
            >
              State
            </label>
            {/* {addressData &&
                            countries &&
                            countries.length > 0 &&
                            (addressData.countryId ===
                                countries.find(c => c.name === "USA" || c.name === "United States").id ||
                                addressData.country === countries.find(c => c.name === "Canada").id ||
                                addressData.country === countries.find(c => c.name === "Mexico").id) ? (
                                <Select
                                    name="state"
                                    options={states.map(c => ({ value: c.id, label: c.name }))}
                                    onChange={handleStateChange}
                                    multi={false}
                                    value={addressData.stateId || addressData.state || ""}
                                    placeholder={"Select"}
                                    required={required}
                                    style={{ minWidth: "150px" }}
                                    autoComplete={"section-addr_" + addressGrouper + " address-level1"}
                                />
                            ) : (
                                <Creatable
                                    name="state"
                                    options={states.map(c => ({ value: c.id, label: c.name }))}
                                    onChange={handleStateChange}
                                    multi={false}
                                    value={addressData.stateId || ""}
                                    placeholder={"Select"}
                                    promptTextCreator={text => "New state: " + text}
                                    required={required}
                                    autoComplete={"section-addr_" + addressGrouper + " address-level1"}
                                />
                            )} */}
            {addressData && countries && countries.length > 0 && (
              <Select
                name="state"
                options={filteredStates.map((c) => ({
                  value: c.id,
                  label: c.name,
                }))}
                multi={false}
                value={addressData.stateId || addressData.state || ""}
                placeholder={statesDisabled ? "" : "Select"}
                required={required}
                style={{ minWidth: "150px" }}
                autoComplete={
                  "section-addr_" + addressGrouper + " address-level1"
                }
                disabled={statesDisabled}
                onChange={handleStateChange}
              />
            )}
          </fieldset>
        </div>
      </div>
    </fieldset>
  );
};
