import AuthRequest from "./AuthRequest";

const baseUrl = "/api/israelinks/reporting";

const IsraeLinksReportingApi = {
  getList: () => {
    return AuthRequest.get(`${baseUrl}/list`, {});
  },
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  getByDates: (startDate, endDate) => {
    return AuthRequest.get(`${baseUrl}/bydates`, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
  /** @param {number} id */
  getSingle: (id) => {
    return AuthRequest.get(`${baseUrl}/${id}`, { id });
  },
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   * @param {boolean} participantsOnly
   * @param {string} orderByField
   * @param {boolean} orderByAscending
   * @param {boolean} retrieveAllRecords
   * @param {number} startingOffset
   * @param {number} maxResults
   */
  getByDatesBreakdownByStudents: (
    startDate,
    endDate,
    participantsOnly,
    orderByField,
    orderByAscending,
    retrieveAllRecords,
    startingOffset,
    maxResults,
  ) => {
    return AuthRequest.get(`${baseUrl}/students`, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      participantsOnly,
      orderByField,
      orderByAscending,
      retrieveAllRecords,
      startingOffset,
      maxResults,
    });
  },
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   * @param {string} orderByField
   * @param {boolean} orderByAscending
   * @param {boolean} retrieveAllRecords
   * @param {number} startingOffset
   * @param {number} maxResults
   */
  getByDatesBreakdownBySchools: (
    startDate,
    endDate,
    orderByField,
    orderByAscending,
    retrieveAllRecords,
    startingOffset,
    maxResults,
  ) => {
    return AuthRequest.get(`${baseUrl}/schools`, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      orderByField,
      orderByAscending,
      retrieveAllRecords,
      startingOffset,
      maxResults,
    });
  },
  /** @param {number} id
   * @param {boolean} participantsOnly
   * @param {string} orderByField
   * @param {boolean} orderByAscending
   * @param {boolean} retrieveAllRecords
   * @param {number} startingOffset
   * @param {number} maxResults
   */
  getBySessionBreakdownByStudents: (
    id,
    participantsOnly,
    orderByField,
    orderByAscending,
    retrieveAllRecords,
    startingOffset,
    maxResults,
  ) => {
    return AuthRequest.get(`${baseUrl}/studentsbysession/${id}`, {
      id,
      participantsOnly,
      orderByField,
      orderByAscending,
      retrieveAllRecords,
      startingOffset,
      maxResults,
    });
  },
  /** @param {number} id
   * @param {string} orderByField
   * @param {boolean} orderByAscending
   * @param {boolean} retrieveAllRecords
   * @param {number} startingOffset
   * @param {number} maxResults
   */
  getBySessionBreakdownBySchools: (
    id,
    orderByField,
    orderByAscending,
    retrieveAllRecords,
    startingOffset,
    maxResults,
  ) => {
    return AuthRequest.get(`${baseUrl}/schoolsbysession/${id}`, {
      id,
      orderByField,
      orderByAscending,
      retrieveAllRecords,
      startingOffset,
      maxResults,
    });
  },
};

export default IsraeLinksReportingApi;
