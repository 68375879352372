import React from "react";
import ExportInteractionsButton from "./ExportInteractionsButton";
import { Link } from "react-router";
import ImpersonateUser from "../../../shared/ImpersonateUser";
import {
  formatFullName,
  formatNumber,
  impersonationUserTypes,
} from "../../../../lib";
import moment from "moment";

export default class ShluchimTableRow extends React.PureComponent {
  render() {
    const {
      getInteractions,
      grants,
      location: {
        activeDataPercentage,
        allocations,
        amountAllocated,
        amountPaidOrPending,
        amountSubmitted,
        campusID,
        campusName,
        chabadHouseID,
        chabadHouseName,
        payments,
        shliachID,
        shliachFirstName,
        shliachLastName,
        shliachEmail,
        shluchaID,
        shluchaFirstName,
        shluchaEmail,
        submittedPercentage,
      },
      paymentSchedules,
      programStartDate,
      view,
      viewStudentsPath,
    } = this.props;

    return (
      <div
        className={`lamplighters-shluchim-table-row lamplighters-shluchim-${view}-table-row`}
      >
        <p>
          {!!chabadHouseID && (
            <Link
              className="link-text-secondary"
              to={`/shluchim/${
                shliachID || shluchaID
              }/chabadHouse/${chabadHouseID}`}
            >
              {chabadHouseName}
            </Link>
          )}
        </p>
        <div>
          {!!shliachID && (
            <div className="flex flex-justify-space flex-align-center mb-8">
              <Link
                className="link-text-secondary"
                to={`/shluchim/${shliachID}`}
              >
                {formatFullName(shliachFirstName, shliachLastName)}
              </Link>
              <span className="tooltip-container">
                <ImpersonateUser
                  username={shliachEmail}
                  userType={impersonationUserTypes.Shliach}
                  showIconWithoutText={true}
                />
                <span
                  className="tooltip"
                  style={{ left: "-40px", bottom: "50%" }}
                >
                  Impersonate
                </span>
              </span>
            </div>
          )}
          {!!shluchaID && (
            <div className="flex flex-justify-space flex-align-center">
              <Link
                className="link-text-secondary"
                to={`/shluchim/${shluchaID}`}
              >
                {formatFullName(shluchaFirstName, shliachLastName)}
              </Link>
              <span className="tooltip-container">
                <ImpersonateUser
                  username={shluchaEmail}
                  userType={impersonationUserTypes.Shliach}
                  showIconWithoutText={true}
                />
                <span
                  className="tooltip"
                  style={{ left: "-40px", bottom: "50%" }}
                >
                  Impersonate
                </span>
              </span>
            </div>
          )}
        </div>
        <p>
          {!!campusID && (
            <Link
              className="link-text-secondary"
              to={`/shluchim/${shliachID || shluchaID}/school/${campusID}`}
            >
              {campusName}
            </Link>
          )}
        </p>
        {view === "payments" ? (
          <React.Fragment>
            <span>${formatNumber(amountAllocated)}</span>
            <span>
              $
              {formatNumber(
                +(amountSubmitted - amountPaidOrPending).toFixed(2),
              )}
            </span>
            {/* +(__).toFixed(2) formatting to accomodate legacy data which may have decimal places - we want to allow showing optional max 2 decimal places */}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>{formatNumber(activeDataPercentage)}%</p>
            <p>{formatNumber(submittedPercentage)}%</p>
          </React.Fragment>
        )}
        <div className="flex flex-align-center">
          {view === "payments"
            ? paymentSchedules.map((paymentSchedule, index) => {
                const payment =
                  (payments &&
                    payments.find(
                      (p) =>
                        p.grantSchedulePaymentScheduleID === paymentSchedule.id,
                    )) ||
                  "";
                return (
                  <p
                    className={`lamplighters-shluchim-table-dynamic-cell ${
                      !payment.isPaid ? "italic-text" : ""
                    }`}
                    key={index}
                  >
                    {payment && (
                      <React.Fragment>
                        ${formatNumber(payment.amount)}
                        <br />
                        <span className="small-text">
                          {Math.round((payment.amount * 100) / amountAllocated)}
                          %
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                );
              })
            : grants.map((grant, index) => {
                const allocation =
                  (allocations &&
                    allocations.find((a) => a.grantID === grant.id)) ||
                  "";
                return (
                  <div
                    className="lamplighters-shluchim-table-dynamic-cell"
                    key={index}
                  >
                    <div className="flex">
                      <p className="no-wrap">
                        {formatNumber(allocation.activeQuantity)} Active
                      </p>
                      {allocation.activeFlaggedQuantity > 0 && (
                        <p className="flagged-text small-text flex flex-align-center ml-8">
                          ({formatNumber(allocation.activeFlaggedQuantity)}
                          <i className="material-icons flag-icon small-text">
                            flag
                          </i>
                          )
                        </p>
                      )}
                    </div>

                    <div className="flex">
                      <p className="no-wrap">
                        {formatNumber(allocation.submittedQuantity)} Submitted
                      </p>
                      {allocation.submittedFlaggedQuantity > 0 && (
                        <p className="flagged-text small-text flex flex-align-center ml-8">
                          ({formatNumber(allocation.submittedFlaggedQuantity)}
                          <i className="material-icons flag-icon small-text">
                            flag
                          </i>
                          )
                        </p>
                      )}
                    </div>
                    <p>
                      {formatNumber(allocation.availableQuantity)} Available
                    </p>
                    <p>{formatNumber(allocation.quantity)} Total</p>
                  </div>
                );
              })}
        </div>
        {view !== "payments" && (
          <div className="flex flex-align-center">
            <span className="tooltip-container">
              <Link
                className="flex flex-align-center flex-justify-center link-text"
                to={`${viewStudentsPath}&shliachId=${shliachID || shluchaID}`}
              >
                <i className="material-icons xl-text">group</i>
              </Link>
              <span className="tooltip">View students</span>
            </span>
            {/* hide export interactions button for imported schedules (prior to year 2021) */}
            {programStartDate && moment(programStartDate).year() >= 2021 && (
              <span className="tooltip-container">
                <ExportInteractionsButton
                  chabadHouseName={chabadHouseName}
                  getInteractions={getInteractions}
                />
                <span className="tooltip">
                  Export lamplighters interactions
                </span>
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}
