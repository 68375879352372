import React from "react";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
// bec this page is no longer in use we've removed the below script from index.html referencing the deprecated cdn, the tinymce editor on this page will not be functional
// <script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
import { asEnum } from "../../../lib";
import { UpdateDOMProperty } from "../../../lib/coc-common-components/Scripts";
import { /* CustomPage, */ EmptyCustomPage } from "../../../models/CustomPage";

const { REACT_APP_TINYMCE_API_KEY } = process.env;

export const ImageType = asEnum({
  MainImage: 0,
  Logo: 1,
});

// interface CustomPageManagerProps {
//   customPageData: CustomPage;
//   linksBase: string;
//   nameChanged: boolean;
//   handleCustomPageDataChange: (newValue: CustomPage) => void;
//   handleImageChange: (image: Blob, url: string, imgType: ImageType) => void;
// }

export function CustomPageManager({
  customPageData = EmptyCustomPage(),
  linksBase,
  nameChanged,
  handleCustomPageDataChange,
  handleImageChange,
}) {
  /** @param {React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>} evt */
  const handleDOMDataChange = (evt) =>
    handleCustomPageDataChange(UpdateDOMProperty(evt, customPageData));

  const handleEditorChange = (content) => {
    const newCustomPage = { ...customPageData, body: content };
    handleCustomPageDataChange(newCustomPage);
  };

  return (
    <div className="form">
      <fieldset className="container">
        <div className="row">
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label form-required" htmlFor="title">
                Title
              </label>
              <input
                type="text"
                id="title"
                name="title"
                value={customPageData.title || ""}
                maxLength={60}
                required={true}
                onChange={handleDOMDataChange}
              />
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label">URL</label>
              {nameChanged || !customPageData.slug ? (
                <label className="form-label">{customPageData.slug}</label>
              ) : (
                <a
                  href={linksBase + customPageData.slug}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {customPageData.slug}{" "}
                  <i
                    className="glyphicon glyphicon-new-window"
                    style={{ fontSize: "10px" }}
                  />
                </a>
              )}
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label form-required" htmlFor="body">
                Body
              </label>
              <TinyMCE
                apiKey={REACT_APP_TINYMCE_API_KEY}
                key={customPageData.id}
                value={customPageData.body}
                init={{
                  plugins: "link lists preview",
                  menubar: "edit format insert view",
                  toolbar:
                    "undo redo | bold italic | alignleft aligncenter alignright | bullist numlist",
                }}
                onEditorChange={handleEditorChange}
              />
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label" htmlFor="mainImg">
                Main Image
              </label>
              <input
                className="btn btn-secondary btn-sm"
                type="file"
                id="mainImg"
                accept="image/*"
                required={true}
                multiple={false}
                onChange={(e) => {
                  e.preventDefault();
                  if (
                    e.currentTarget.files &&
                    e.currentTarget.files.length > 0
                  ) {
                    handleImageChange(
                      e.currentTarget.files[0],
                      e.currentTarget.value,
                      ImageType.MainImage,
                    );
                  }
                }}
              />
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label" htmlFor="mainImg">
                Preview
              </label>
              {customPageData.mainImagePath && (
                <img src={customPageData.mainImagePath} alt="main" />
              )}
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label">Logo (optional)</label>
              <input
                className="btn btn-secondary btn-sm"
                type="file"
                id="logoImg"
                accept="image/*"
                multiple={false}
                onChange={(e) => {
                  e.preventDefault();
                  if (
                    e.currentTarget.files &&
                    e.currentTarget.files.length > 0
                  ) {
                    handleImageChange(
                      e.currentTarget.files[0],
                      e.currentTarget.value,
                      ImageType.Logo,
                    );
                  }
                }}
              />
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label">Preview</label>
              {customPageData.logoPath && (
                <img src={customPageData.logoPath} alt="logo" />
              )}
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label" htmlFor="includeApply">
                Include 'Apply' Button?
              </label>
              <input
                type="checkbox"
                id="includeApply"
                name="includeApplyButton"
                checked={customPageData.includeApplyButton || false}
                required={true}
                onChange={handleDOMDataChange}
              />
            </fieldset>
          </div>
          {customPageData.includeApplyButton && (
            <div className="row">
              <fieldset className="col-sm-6">
                <label className="form-label form-required" htmlFor="applyUrl">
                  Apply Button URL
                </label>
                <input
                  type="text"
                  id="applyUrl"
                  name="applyUrl"
                  value={customPageData.applyUrl || ""}
                  required={true}
                  onChange={handleDOMDataChange}
                />
              </fieldset>
            </div>
          )}
        </div>
      </fieldset>
    </div>
  );
}
