const STORAGE_KEY = "userSettings";

function getStoredData() {
  return JSON.parse(localStorage.getItem(STORAGE_KEY) || "{}");
}

function setStoredData(data) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
}

export function Storage() {
  function storeItem(key, value) {
    const storage = getStoredData();
    storage[key] = value;
    setStoredData(storage);
  }
  /** @returns {string} */
  function getItem(key) {
    return getStoredData()[key];
  }

  function removeItem(key) {
    const storage = getStoredData();
    delete storage[key];
    setStoredData(storage);
  }

  return { storeItem, getItem, removeItem };
}
