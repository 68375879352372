import React from "react";
import Loader from "../../../common/Loader";
import Modal from "react-modal";
import SelectSearch from "react-select";
import moment from "moment";

export default class NewProgramScheduleModal extends React.Component {
  state = {
    error: "",
    loading: false,
    scheduleCloneSelection: null,
  };

  onCancel = () => {
    this.setState(
      {
        error: "",
        loading: false,
        scheduleCloneSelection: null,
      },
      this.props.close,
    );
  };

  onContinue = async () => {
    this.setState({
      error: "",
      loading: true,
    });

    const { scheduleCloneSelection } = this.state;

    let error = "";
    try {
      await this.props.continue(
        scheduleCloneSelection ? scheduleCloneSelection.value : null,
      );
    } catch (err) {
      error =
        "Sorry, we were unable to clone this program schedule.  Please try again.";
    }

    this.setState({
      error,
      loading: false,
      scheduleCloneSelection: error ? scheduleCloneSelection : null,
    });
  };

  onSelectScheduleClone = (scheduleCloneSelection) => {
    this.setState({ scheduleCloneSelection });
  };

  render() {
    const { schedules, show } = this.props;

    const { error, loading, scheduleCloneSelection } = this.state;

    return (
      <Modal isOpen={show} className="event-participants-modal-container">
        <div className="modal-card card small-modal">
          <p className="large-text fw-700 mb-16">New Program Schedule</p>
          <p className="small-text fw-700 mb-16">
            Select an old schedule to clone or just click continue to create a
            new program schedule from scratch.
          </p>
          <SelectSearch
            className="full-width"
            isClearable={true}
            isSearchable={true}
            onChange={this.onSelectScheduleClone}
            options={
              schedules &&
              schedules.map((sched) => ({
                value: sched.id,
                label:
                  sched.title ||
                  `Untitled ${
                    sched.programStartDate
                      ? moment(sched.programStartDate).format("M/D/YY")
                      : ""
                  }`,
              }))
            }
            placeholder={"Select schedule"}
            value={scheduleCloneSelection}
          />

          {loading ? (
            <div className="modal-btns-loader">
              <Loader />
            </div>
          ) : (
            <div className="modal-btns relative">
              <button
                className="link-text uppercase-text"
                onClick={this.onCancel}
              >
                Cancel
              </button>
              <button
                className="link-text uppercase-text ml-40"
                onClick={this.onContinue}
              >
                Continue
              </button>

              {error && (
                <span
                  className="error-text"
                  style={{
                    position: "absolute",
                    whiteSpace: "nowrap",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    bottom: "-20px",
                  }}
                >
                  {error || "Something went wrong.  Please try again."}
                </span>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
