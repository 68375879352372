import Big from "big.js";

export function getRequiredRaffleFields(raffle) {
  const incompleteFields = {
    configuration: [],
    prizes: [],
    legalText: [],
    marketingContent: [],
  };

  //CONFIG INFO
  if (!raffle.manualTicketEntryDeadline)
    incompleteFields.configuration.push("Shluchim Ticket Entry Deadline");
  else if (
    new Date(raffle.manualTicketEntryDeadline) < new Date(raffle.endDate)
  )
    incompleteFields.configuration.push(
      "Shluchim Ticket Entry Deadline is prior to Grand Draw End Date",
    );
  if (!raffle.drawingDate) incompleteFields.configuration.push("Drawing Date");
  if (!raffle.resultsPostingDate)
    incompleteFields.configuration.push("Results Posting Date");
  if (!raffle.usdTicketPrice)
    incompleteFields.configuration.push("USD Ticket Price");
  if (!raffle.cadTicketPrice)
    incompleteFields.configuration.push("CAD Ticket Price");
  if (!raffle.gbpTicketPrice)
    incompleteFields.configuration.push("GBP Ticket Price");
  if (!raffle.numberOfTicketsWithFeeCap)
    incompleteFields.configuration.push("Ticket Fee Cap");
  if (!raffle.ticketFeePercentage)
    incompleteFields.configuration.push("Ticket Fee Percentage");
  if (!raffle.cociCcProcessingFeePercentage)
    incompleteFields.configuration.push("Processing Fee");
  if (raffle.isPublished) {
    if (!raffle.fromCadConversion)
      incompleteFields.configuration.push("CAD to USD");
    if (!raffle.fromGbpConversion)
      incompleteFields.configuration.push("GBP to USD");
  }

  //PRIZES INFO
  if (!raffle.prizes.length) {
    incompleteFields.prizes.push("No Prizes");
  } else {
    raffle.prizes.forEach((prize) => {
      const missingPrizeFields = [];
      if (!prize.usdPrizeTitle) missingPrizeFields.push("USD Prize Title");
      if (!prize.cadPrizeTitle) missingPrizeFields.push("CAD Prize Title");
      if (!prize.gbpPrizeTitle) missingPrizeFields.push("GBP Prize Title");
      if (!prize.place) missingPrizeFields.push("Place");
      if (!prize.numberOfWinners) missingPrizeFields.push("Number of Winners");

      if (missingPrizeFields.length)
        incompleteFields.prizes.push(
          `${
            prize.usdPrizeTitle || prize.cadPrizeTitle || prize.gbpPrizeTitle
          } - ${missingPrizeFields.join(", ")}`,
        );
    });
  }

  //LEGAL INFO
  if (!raffle.shortLegalText) incompleteFields.legalText.push("Short Text");
  if (!raffle.shortUkLegalText)
    incompleteFields.legalText.push("Short UK Text");
  if (!raffle.fullLegalText) incompleteFields.legalText.push("Full Text");

  //MARKETING CONTENT INFO
  if (!raffle.campaignLogoUrl)
    incompleteFields.marketingContent.push("Campaign Logo");
  if (!raffle.campaignMessage)
    incompleteFields.marketingContent.push("Campaign Message");
  if (raffle.defaultCampaignImages?.length < 5)
    incompleteFields.marketingContent.push("Campaign Images");
  if (!raffle.emailMessage)
    incompleteFields.marketingContent.push("Email Message");

  let incompleteFieldsMessage = "";

  if (incompleteFields.configuration.length) {
    incompleteFieldsMessage +=
      "Configuration Info:\n" + incompleteFields.configuration.join("\n");
  }
  if (incompleteFields.prizes.length) {
    incompleteFieldsMessage +=
      (incompleteFieldsMessage.length ? "\n\n" : "") +
      "Prize Info:\n" +
      incompleteFields.prizes.join("\n");
  }

  if (incompleteFields.legalText.length) {
    incompleteFieldsMessage +=
      (incompleteFieldsMessage.length ? "\n\n" : "") +
      "Legal Text:\n" +
      incompleteFields.legalText.join("\n");
  }

  if (incompleteFields.marketingContent.length) {
    incompleteFieldsMessage +=
      (incompleteFieldsMessage.length ? "\n\n" : "") +
      "Marketing Content:\n" +
      incompleteFields.marketingContent.join("\n");
  }

  return {
    isIncomplete: incompleteFieldsMessage.length > 0,
    incompleteFields: incompleteFieldsMessage,
  };
}

// TODO: store refund breakdown in database instead - see: https://bitbean.atlassian.net/browse/COC-2676
export function calculateRefundBreakdown(
  refundAmount,
  cociCcProcessingFeePercentage,
  singleTicketPrice,
) {
  if (!singleTicketPrice) {
    // historical raffles pre-2019 have some bad records, and don't have breakdowns anyways
    return {
      refundTicketPriceAmount: 0,
      refundProcessingFeeDonorAmount: 0,
      refundQuantity: 0,
    };
  }

  const cociCcProcessingFeeAsDecimal = Big(cociCcProcessingFeePercentage).div(
    100,
  );
  const refundAmountBig = Big(refundAmount);
  const wasDonorPaidProcessingFeeRefunded = !refundAmountBig
    .mod(singleTicketPrice)
    .abs()
    .eq(0);

  const refundTicketPriceAmount = wasDonorPaidProcessingFeeRefunded
    ? refundAmountBig.div(cociCcProcessingFeeAsDecimal.plus(1))
    : refundAmountBig;
  const refundProcessingFeeDonorAmount = wasDonorPaidProcessingFeeRefunded
    ? refundAmountBig.minus(refundTicketPriceAmount)
    : 0;
  const refundQuantity = refundTicketPriceAmount.div(singleTicketPrice).abs();

  return {
    refundTicketPriceAmount,
    refundProcessingFeeDonorAmount,
    refundQuantity,
  };
}
