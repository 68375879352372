import React from "react";
import { Link } from "react-router";
import moment from "moment";
import { ResponsiveContainer, BarChart, XAxis, Bar, Text } from "recharts";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import viewHistory from "../../services/ViewHistory";
import { ReportingTile } from "../common/ReportingTile";
import {
  GetReportValue,
  ChartColors,
} from "../../assets/scripts/reportingHelpers";
import MediaAndPRReportingApi from "../../services/resources/MediaAndPRReportingApi";
import DatePicker from "../common/DateRangePicker";

export class MediaAndPRReporting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment.utc().subtract(2, "years"),
      endDate: moment.utc(),
      loading: true,
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.MediaPRSummary)) {
      return;
    }

    viewHistory.add("Program", {
      title: "Media & PR",
      path: "/mediapr",
    });
    this.requestReportData();
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {moment.Moment} */
    const startDate = this.state.startDate.clone().startOf("day");
    /** @type {moment.Moment} */
    const endDate = this.state.endDate.clone().endOf("day");

    MediaAndPRReportingApi.get(startDate, endDate)
      .then((response) => {
        this.setState({ reportData: response.data, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState({ [dateFieldName]: selectedDate }, this.requestReportData);
    };
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.MediaPRSummary)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { reportData } = this.state;

    const countByStateArray = (reportData && reportData.countByState) || [];

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/mediapr">Media & PR</Link>
              </li>
              <li>Reports</li>
            </ol>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label>Date Range</label>
              <div>
                <DatePicker
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  handleDateInputChange={this.handleDateInputChange}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="col-md-3">
              <ReportingTile
                label="Total"
                value={GetReportValue(reportData, "totalCount")}
              />
            </div>
            <div className="col-md-9" />
            <div className="col-md-12">
              <div className="well text-center">
                {reportData && (
                  <ResponsiveContainer width="100%" height={250}>
                    {countByStateArray.length === 0 ? (
                      <Text className="no-participants-message">
                        {"No media in date range"}
                      </Text>
                    ) : (
                      <BarChart data={countByStateArray} margin={{ top: 15 }}>
                        <XAxis dataKey="state" />
                        <Bar
                          dataKey="count"
                          fill={ChartColors[0]}
                          label={true}
                          isAnimationActive={false}
                        />
                      </BarChart>
                    )}
                  </ResponsiveContainer>
                )}
                <p className="reporting-stats-label">Media Articles by State</p>
              </div>
            </div>

            {this.state.loading && <Loader />}
          </div>
        </div>
      </div>
    );
  }
}
