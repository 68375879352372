import React from "react";
import moment from "moment";

import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import { Loader } from "../../../lib/coc-common-components";

import AuthService, { PermissionClaims } from "../../../services/AuthService";
import CustomEventsApi from "../../../services/resources/CustomEventsApi";
import { EmptyDetailedBySchoolCustomReportData } from "../Utils";
import ReportingDetailedTemplateSchools /* , { DetailedTemplateSchoolsReportData } */ from "./DetailedTemplateSchools";

// interface DetailedTemplateSchoolsContainerProps {
//   location: { [key: string] };
//   params: { [key: string] };
// }

// interface DetailedTemplateSchoolsContainerState {
//   loading: boolean;
//   startDate: moment.Moment;
//   endDate: moment.Moment;
//   reportData: DetailedTemplateSchoolsReportData;
//   displayMessage: string;
// }

class DetailedTemplateSchoolsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment.utc(+this.props.location.query.startDate),
      endDate: moment.utc(+this.props.location.query.endDate),
      loading: true,
      reportData: EmptyDetailedBySchoolCustomReportData(),
      displayMessage: "",
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState({ [dateFieldName]: selectedDate }, this.requestReportData);
    };
  }

  requestReportData() {
    this.setState({ loading: true }, () => {
      /** @type {moment.Moment} */
      const startDate = this.state.startDate.clone().startOf("day");
      /** @type {moment.Moment} */
      const endDate = this.state.endDate.clone().endOf("day");
      CustomEventsApi.getDetailedBySchoolReportData(
        +this.props.params.id,
        startDate,
        endDate,
      )
        .then((response) => {
          /** @type {DetailedTemplateSchoolsReportData} */
          const reportData = {
            name: response.data.name,
            type: response.data.type,
            contactType: response.data.contactType,
            schools: response.data.schools,
          };
          this.setState({
            reportData,
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }

  componentDidMount() {
    const id = Number(this.props.params.id);
    if (!Number.isNaN(id)) {
      this.requestReportData();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location.pathname === this.props.location.pathname) {
      return;
    }
    this.requestReportData();
  }

  render() {
    // if (!AuthService.UserHasClaim(PermissionClaims.ccc)) {
    //     return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    // }

    const { startDate, endDate, reportData, loading } = this.state;
    return (
      <div>
        <ReportingDetailedTemplateSchools
          baseUrl={this.props.location.pathname}
          eventText={reportData.name}
          startDate={startDate}
          endDate={endDate}
          reportData={reportData}
          linksToDirectory={AuthService.UserHasClaim(
            PermissionClaims.DirectoryRead,
          )}
          handleDateInputChange={this.handleDateInputChange}
        />
        ;{loading && <Loader />}
      </div>
    );
  }
}

export default DetailedTemplateSchoolsContainer;
