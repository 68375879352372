import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import { flattenArray } from "../../../../lib";
import moment from "moment";

export default class StudentsExportButton extends React.PureComponent {
  getStudentGrants = async () => {
    const students = await this.props.getStudentsForExport();
    const studentGrants = students.map((student) =>
      student.grants.map((studentGrant) => ({
        studentID: student.studentID,
        studentFirstName: student.studentFirstName,
        studentLastName: student.studentLastName,
        studentEmail: student.studentEmail,
        studentCell: student.studentCell,
        studentCity: student.studentCity,
        studentState: student.studentState,
        studentCountry: student.studentCountry,
        studentGender: student.studentGender,
        graduationYear: student.graduationYear,
        campusID: student.campusID,
        campusName: student.campusName,
        chabadHouseID: student.chabadHouseID,
        chabadHouseName: student.chabadHouseName,
        chabadHouseCountry: student.chabadHouseCountry,
        shliachID: student.shliachID,
        shliachFirstName: student.shliachFirstName,
        shliachLastName: student.shliachLastName,
        grantType: studentGrant.grantTypeDisplay,
        grantName:
          studentGrant.grantTypeDisplay +
          (studentGrant.grantDescription
            ? " - " + studentGrant.grantDescription
            : ""),
        submittedDate: studentGrant.submittedDate
          ? moment(studentGrant.submittedDate).format("MM/DD/YYYY")
          : "",
        studentAgeAtSubmission: studentGrant.studentAgeAtSubmission,
        status: studentGrant.isSubmitted ? "Submitted" : "Activated",
        isFlagged:
          studentGrant.isIneligibleFlagged ||
          studentGrant.isMissingDataFlagged ||
          studentGrant.isStudentPotentialDuplicateFlagged
            ? "Flagged"
            : "",
        submittedEngagementInteractionsCount:
          studentGrant.submittedEngagementInteractionsCount,
        submittedLearningInteractionsCount:
          studentGrant.submittedLearningInteractionsCount,
        submittedShabbosMealWithMaxCount:
          studentGrant.submittedShabbosMealWithMaxCount,
        submittedIsraelRelatedWithMinCount:
          studentGrant.submittedIsraelRelatedWithMinCount,
      })),
    );

    return flattenArray(studentGrants);
  };

  render() {
    const { programScheduleName } = this.props;

    return (
      <ExportCSVButton
        className="btn btn-accent students-export-btn"
        fileName={`${programScheduleName || "Lamplighters"}-Students`}
        getExportData={this.getStudentGrants}
        getHeaders={() => [
          { label: "Student ID", value: "studentID" },
          { label: "Student First Name", value: "studentFirstName" },
          { label: "Student Last Name", value: "studentLastName" },
          { label: "Student Email", value: "studentEmail" },
          { label: "Student Phone", value: "studentCell" },
          { label: "Student Gender", value: "studentGender" },
          {
            label: "Student Age at Submission",
            value: "studentAgeAtSubmission",
          },
          { label: "Student City", value: "studentCity" },
          { label: "Student State", value: "studentState" },
          { label: "Student Country", value: "studentCountry" },
          { label: "Graduation Year", value: "graduationYear" },
          { label: "Campus ID", value: "campusID" },
          { label: "Campus Name", value: "campusName" },
          { label: "Chabad House ID", value: "chabadHouseID" },
          { label: "Chabad House Name", value: "chabadHouseName" },
          { label: "Chabad House Country", value: "chabadHouseCountry" },
          { label: "Shliach ID", value: "shliachID" },
          { label: "Shliach First Name", value: "shliachFirstName" },
          { label: "Shliach Last Name", value: "shliachLastName" },
          { label: "Grant Type", value: "grantType" },
          { label: "Grant Name", value: "grantName" },
          { label: "Grant Status", value: "status" },
          { label: "Flagged Grant", value: "isFlagged" },
          { label: "Grant Submitted Date", value: "submittedDate" },
          {
            label: "Learning Count (Submitted Interactions)",
            value: "submittedLearningInteractionsCount",
          },
          {
            label: "Engagement Count (Submitted Interactions)",
            value: "submittedEngagementInteractionsCount",
          },
          {
            label:
              "Shabbos Meal Count (Submitted Interactions with Shabbos Meal max)",
            value: "submittedShabbosMealWithMaxCount",
          },
          {
            label:
              "Israel/Antisemitism Related Count (Submitted Interactions with Israel/Antisemitism Related min)",
            value: "submittedIsraelRelatedWithMinCount",
          },
        ]}
      />
    );
  }
}
