import axios from "axios";
import AuthService from "../AuthService";
import urlBase from "../UrlBaseUtil";

const authedRequester = (method) => {
  return (url, { params = {}, data = {} }) => {
    const headers = { Authorization: `Bearer ${AuthService.getToken()}` };
    return axios({
      method,
      url,
      params,
      headers,
      data,
      baseURL: urlBase.getLegacyApiBase(),
    });
  };
};

const AuthRequest = {
  get: (url, params = {}) => authedRequester("get")(url, { params }),
  post: (url, params) => authedRequester("post")(url, { data: params }),
  delete: (url) => authedRequester("delete")(url, {}),
};

export default AuthRequest;
