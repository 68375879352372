import React from "react";
import Dropzone from "react-dropzone";
import ExportCSVButton from "../../../../shared/ExportCSVButton";
import Modal from "react-modal";

import ShluchimApi from "../../../../../services/resources/ShluchimApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import { notify } from "react-notify-toast";
import ProgramsApi from "../../../../../services/resources/ProgramsApi";

export default class ImportRegistrationsModal extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      fileForUpload: null,
      loading: false,
      submitAttempted: false,
      uploadProgress: 0,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  downloadShluchimIds = async () =>
    await ShluchimApi.getShluchim(this.apiSignal.token);

  selectFile = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      this.setState({
        errorMessage: "",
        fileForUpload: file,
      });
    }
  };

  uploadRecords = async () => {
    const {
      programSchedule: { programID, scheduleID },
      reloadRegistrations,
    } = this.props;

    const { fileForUpload } = this.state;

    this.setState({
      errorMessage: "",
      submitAttempted: true,
    });

    if (!fileForUpload) {
      return;
    }

    this.setState({
      errorMessage: "",
      loading: true,
      uploadProgress: 0,
    });

    try {
      await ProgramsApi.importScheduleRegistrations(
        this.apiSignal.token,
        programID,
        scheduleID,
        fileForUpload,
        (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.setState({
            uploadProgress: progress > 100 ? 100 : progress,
          });
        },
      );

      reloadRegistrations();
      this.onClose();
      notify.show("Registrations have been successfully imported", "success");
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
          uploadProgress: 0,
        });
      }
    }
  };

  render() {
    const { show } = this.props;
    const {
      errorMessage,
      fileForUpload,
      loading,
      submitAttempted,
      uploadProgress,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div
          className="modal-card card import-modal"
          style={{ maxHeight: "96vh", overflow: "auto" }}
        >
          <p className="xl-text fw-700">Import Registrations</p>

          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Use this feature to upload a spreadsheet of shliach registrations
            for this program.
          </p>
          <div className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            <ExportCSVButton
              className="link-text flex flex-align-center mb-0"
              title="Click here to download an import template containing all shluchim IDs."
              fileName="Shliach-Registration-Import-Template"
              getExportData={this.downloadShluchimIds}
              headers={[
                { label: "Shliach ID", value: "shliachID" },
                { label: "Shliach Name", value: "fullName" },
                { label: "Date", value: "date" },
              ]}
            />{" "}
            Remove the shliach IDs that should not be registered. Update the
            registration dates or leave blank to default to the current date.
            Shliach name is not a required field.
          </div>
          <p className="accent-text mt-16" style={{ lineHeight: "1.5" }}>
            File must meet the following requirements before uploading: <br />
            * File must be saved in CSV or Excel format <br />
            * Delete any blank rows in your file (in Excel files, default blank
            template rows must be deleted) <br />
          </p>

          <div className="mt-24">
            <p
              className={`fw-700 uppercase-text small-text accent-text-dark mb-8 ${
                submitAttempted && !fileForUpload ? "error-text" : ""
              }`}
            >
              Select Registration Import file
            </p>
            <div className="flex mb-24 flex-justify-space">
              <Dropzone
                // accept={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onDrop={this.selectFile}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p className="uppercase-text fw-500 link-text">
                      {fileForUpload ? "Change " : "Select"} File
                    </p>
                  </div>
                )}
              </Dropzone>

              {fileForUpload && (
                <div className="uploading">
                  <div className="flex flex-justify-space flex-align-center">
                    <p className="fw-700 mr-8 mb-8 flex flex-align-center">
                      <i className="material-icons accent-text mr-8">
                        description
                      </i>
                      {fileForUpload.name}
                    </p>
                  </div>
                  <div className="percentage-bar relative">
                    <div className="bar" />
                    <div
                      className="filled-in"
                      style={{ width: `${uploadProgress}%` }}
                    />
                    <div className="labels flex flex-justify-space mt-8">
                      <p />
                      <p className="accent-text small-text">
                        {uploadProgress}%
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="raffle-modal-btns flex flex-justify-end flex-align-center mt-24">
            <button className="btn btn-light btn-medium" onClick={this.onClose}>
              Cancel
            </button>
            <button
              className="btn btn-accent btn-medium ml-16"
              disabled={loading}
              onClick={this.uploadRecords}
            >
              {!loading ? "Upload Registrations" : "Uploading..."}
            </button>
          </div>
          {submitAttempted && errorMessage && (
            <p className="small-text error-text text-right mt-4">
              {errorMessage}
            </p>
          )}
        </div>
      </Modal>
    );
  }
}
