import React from "react";

import ShliachActivityInfo from "./ShliachActivityInfo";
import SpouseActivityInfo from "./SpouseActivityInfo";

export default class Personal extends React.PureComponent {
  require = (value) => (!value && value !== false ? true : "");

  render() {
    const {
      errors,
      handleChange,
      setFieldValue,
      sys,
      touched,
      values,
      shliach,
    } = this.props;
    return (
      <div className="profile-form-sections">
        <div className="profile-form-section">
          <p className="accent-text small-text line-height-double">
            Review and edit the scope of your Shlichus activities. This
            information will not be shared publicly.
          </p>
        </div>

        <ShliachActivityInfo
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          shliach={shliach}
          touched={touched}
          require={this.require}
          sys={sys}
          values={values}
        />
        {values.outreach.spouseOutreach && (
          <SpouseActivityInfo
            errors={errors}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            shliach={shliach}
            touched={touched}
            require={this.require}
            sys={sys}
            values={values}
          />
        )}
      </div>
    );
  }
}
