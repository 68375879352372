import React from "react";
import { Link } from "react-router";
import TextField from "@material-ui/core/TextField";
import Toggle from "../../../shared/Toggle";
import moment from "moment";
import { dateTimeWithSecondsFormat } from "../../../../lib";

export default class ScheduleDetails extends React.PureComponent {
  render() {
    const {
      actions,
      allowUpdateSchedule,
      program,
      restrictedEdit,
      schedule,
      scheduleOrig = {}, //instantiating to empty object to avoid need for repeated null reference check if undefined
    } = this.props;
    return (
      <div>
        <div className="flex flex-align-center flex-justify-space program-schedule-section-header mb-24">
          <p style={{ fontStyle: "italic" }}>
            {schedule.isPublished ? "Published" : "Not Published"}
          </p>
          <div>
            {!!program.registrationType && (
              <React.Fragment>
                {!!program.hasApplicationProcess && (
                  <Link
                    className="uppercase-text link-text fw-700"
                    to={`/programs/${program.id}/schedules/${schedule.id}/applicationSettings`}
                  >
                    Application Settings
                  </Link>
                )}
                <Link
                  className="uppercase-text link-text fw-700 ml-16"
                  to={`/programs/${program.id}/schedules/${schedule.id}/registrations`}
                >
                  View Registrations
                </Link>
              </React.Fragment>
            )}
            {!!program.adminSettingsBaseUrl && (
              <Link
                className="uppercase-text link-text fw-700 ml-16"
                to={`${program.adminSettingsBaseUrl}/${schedule.id}`}
              >
                Event Settings
              </Link>
            )}
            {!!program.adminBaseUrl && (
              <Link
                className="uppercase-text link-text fw-700 ml-16"
                to={`${program.adminBaseUrl}/${schedule.id}`}
              >
                View Event
              </Link>
            )}
          </div>
        </div>
        <div className="flex mb-8">
          <div className="flex flex-justify-space flex-align-center relative date-input-wrapper">
            <label className="form-label" htmlFor="sched_title">
              Schedule Title
            </label>
            <input
              className="schedule-name-input"
              type="text"
              id="sched_title"
              autoComplete="off"
              disabled={!allowUpdateSchedule}
              placeholder="ex: Grand Draw 2019"
              value={schedule.title || ""}
              data-change="schedule.title"
              onChange={actions.changeInput}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative date-input-wrapper">
            <label className="form-label" htmlFor="schedule_start">
              Program Start Date
            </label>
            <TextField
              id="schedule_start"
              type="datetime-local"
              variant="outlined"
              size="small"
              disabled={
                schedule.isOngoing ||
                scheduleOrig.hasStarted ||
                !allowUpdateSchedule ||
                restrictedEdit
              }
              value={
                schedule.programStartDate
                  ? moment(schedule.programStartDate).format(
                      dateTimeWithSecondsFormat
                    )
                  : ""
              }
              onChange={(e) => {
                actions.changeDateTime(
                  "schedule.programStartDate",
                  e.target.value
                );
              }}
              inputProps={{
                step: 1,
                style: { fontSize: 13 },
              }}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative date-input-wrapper">
            <label className="form-label" htmlFor="schedule_end">
              Program End Date
            </label>
            <TextField
              id="schedule_end"
              type="datetime-local"
              variant="outlined"
              size="small"
              disabled={
                schedule.isOngoing || !allowUpdateSchedule || restrictedEdit
              }
              value={
                schedule.programEndDate
                  ? moment(schedule.programEndDate).format(
                      dateTimeWithSecondsFormat
                    )
                  : ""
              }
              onChange={(e) => {
                actions.changeDateTime(
                  "schedule.programEndDate",
                  e.target.value
                );
              }}
              inputProps={{
                step: 1,
                style: { fontSize: 13 },
                min:
                  scheduleOrig.hasStarted && scheduleOrig.programEndDate
                    ? moment().format(dateTimeWithSecondsFormat)
                    : null,
              }}
            />
          </div>
        </div>
        <div className="flex mb-8">
          <div className="flex flex-justify-space flex-align-center relative date-input-wrapper">
            <label className="form-label" htmlFor="schedule_prereg_open">
              Pre-Registration Open Date
            </label>
            <TextField
              id="schedule_prereg_open"
              type="datetime-local"
              variant="outlined"
              size="small"
              disabled={
                schedule.isOngoing || !allowUpdateSchedule || restrictedEdit
              }
              value={
                schedule.preRegisterStartDate
                  ? moment(schedule.preRegisterStartDate).format(
                      dateTimeWithSecondsFormat
                    )
                  : ""
              }
              onChange={(e) => {
                actions.changeDateTime(
                  "schedule.preRegisterStartDate",
                  e.target.value
                );
              }}
              inputProps={{
                step: 1,
                style: { fontSize: 13 },
              }}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative date-input-wrapper">
            <label className="form-label" htmlFor="schedule_reg_start">
              {program.registrationType || "Shliach"} Registration Start Date
            </label>
            <TextField
              id="schedule_reg_start"
              type="datetime-local"
              variant="outlined"
              size="small"
              disabled={
                schedule.isOngoing || !allowUpdateSchedule || restrictedEdit
              }
              value={
                schedule.registerStartDate
                  ? moment(schedule.registerStartDate).format(
                      dateTimeWithSecondsFormat
                    )
                  : ""
              }
              onChange={(e) => {
                actions.changeDateTime(
                  "schedule.registerStartDate",
                  e.target.value
                );
              }}
              inputProps={{
                step: 1,
                style: { fontSize: 13 },
                max:
                  scheduleOrig.hasStartedRegistration &&
                  scheduleOrig.registerStartDate &&
                  moment(scheduleOrig.registerStartDate).isSameOrBefore(
                    moment()
                  )
                    ? moment(scheduleOrig.registerStartDate).format(
                        dateTimeWithSecondsFormat
                      )
                    : null,
              }}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative date-input-wrapper">
            <label className="form-label" htmlFor="schedule_reg_end">
              {program.registrationType || "Shliach"} Registration End Date
            </label>
            <TextField
              id="schedule_reg_end"
              type="datetime-local"
              variant="outlined"
              size="small"
              disabled={
                schedule.isOngoing || !allowUpdateSchedule || restrictedEdit
              }
              value={
                schedule.registerEndDate
                  ? moment(schedule.registerEndDate).format(
                      dateTimeWithSecondsFormat
                    )
                  : ""
              }
              onChange={(e) => {
                actions.changeDateTime(
                  "schedule.registerEndDate",
                  e.target.value
                );
              }}
              inputProps={{
                step: 1,
                style: { fontSize: 13 },
                min:
                  scheduleOrig.hasStartedRegistration &&
                  scheduleOrig.registerEndDate
                    ? moment(scheduleOrig.registerEndDate).isSameOrBefore(
                        moment()
                      )
                      ? moment(scheduleOrig.registerEndDate)
                      : moment()
                    : null,
              }}
            />
          </div>
        </div>
        {program.registrationType === "Shliach" && (
          <div className="program-form-inputs mb-8">
            <div
              className="flex flex-align-center relative"
              style={{ gridColumnStart: "span 2" }}
            >
              <label className="form-label mr-8" htmlFor="registration_method">
                Registration Method
              </label>
              <Toggle
                disabled={!allowUpdateSchedule || restrictedEdit}
                id="registration_method"
                name="schedule.registrationMethod"
                onChange={(name, value) => {
                  //clear out zoom meeting id if changed from Zoom
                  if (schedule.zoomMeetingID && value !== "Zoom") {
                    actions.changeState("schedule.zoomMeetingID", null);
                  }
                  actions.changeState(name, value);
                }}
                options={[
                  {
                    value: "Standard",
                    display: "Manual",
                  },
                  {
                    value: "Jotform",
                    display: "Jotform",
                  },
                  {
                    value: "Zoom",
                    display: "Zoom",
                  },
                ]}
                value={schedule.registrationMethod}
              />
            </div>
          </div>
        )}
        <div className="program-form-inputs mb-8">
          <div className="flex flex-justify-space flex-align-center relative">
            <label
              className="form-label flex flex-align-center mr-16"
              htmlFor="prereg_url"
              style={{ whiteSpace: "nowrap" }}
            >
              Pre-Registration Form (URL)
              <span className="tooltip-container">
                <i className="material-icons large-text ml-8 link-text no-hover">
                  info
                </i>
                <span className="tooltip tooltip-medium-width">
                  If the pre-registration page is at an external site, enter
                  that URL here. This link will display in this program schedule
                  card in the Shluchim Portal during Pre-registration.
                </span>
              </span>
            </label>
            <input
              type="text"
              id="prereg_url"
              autoComplete="off"
              disabled={!allowUpdateSchedule || restrictedEdit}
              readOnly={schedule.isOngoing}
              value={schedule.preRegisterURL || ""}
              data-change="schedule.preRegisterURL"
              onChange={actions.changeInput}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <label
              className="form-label flex flex-align-center"
              htmlFor="reg_url"
            >
              Registration{" "}
              {program.registrationType === "Shliach" &&
              schedule.registrationMethod === "Jotform"
                ? "Jotform"
                : "Form"}{" "}
              (URL)
              <span className="tooltip-container">
                <i className="material-icons large-text ml-8 link-text no-hover">
                  info
                </i>
                {schedule.registrationMethod === "Jotform" ? (
                  <span className="tooltip tooltip-medium-width">
                    Enter the Jotform URL and the form will display in an
                    embedded modal within the Shluchim portal.
                  </span>
                ) : (
                  <span className="tooltip tooltip-medium-width">
                    If the registration page is at an external site, or within a
                    jotform in the program schedule contents, enter the URL
                    here, and the link will display in the program schedule card
                    in the Shluchim Portal during Registration.
                    <br />
                    If the registration page is within the Shluchim Portal,
                    leave this field blank.
                    <br />
                    If the registration is via Jotform, select the Jotform
                    Registration Method and enter the Jotform URL here.
                  </span>
                )}
              </span>
            </label>
            <input
              type="text"
              id="reg_url"
              autoComplete="off"
              disabled={!allowUpdateSchedule || restrictedEdit}
              value={schedule.registerURL || ""}
              data-change="schedule.registerURL"
              onChange={actions.changeInput}
            />
          </div>
        </div>
        <div className="program-form-inputs mb-8">
          <div className="flex flex-justify-space flex-align-center relative">
            <label className="form-label" htmlFor="action_button_text">
              Shliach Action Button Text
            </label>
            <input
              type="text"
              id="action_button_text"
              autoComplete="off"
              disabled={!allowUpdateSchedule}
              value={schedule.actionButtonText || ""}
              data-change="schedule.actionButtonText"
              onChange={actions.changeInput}
            />
          </div>
          {schedule.registrationMethod === "Zoom" && (
            <div className="flex flex-justify-space flex-align-center relative">
              <label className="form-label" htmlFor="zoom_meeting_id">
                Zoom Meeting ID
              </label>
              <input
                type="text"
                id="zoom_meeting_id"
                autoComplete="off"
                disabled={!allowUpdateSchedule || restrictedEdit}
                value={schedule.zoomMeetingID || ""}
                data-change="schedule.zoomMeetingID"
                onChange={(e) =>
                  actions.changeState(
                    "schedule.zoomMeetingID",
                    e.target.value?.replace(/[^0-9]/g, "")
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="program-form-inputs">
          <div
            className="flex flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <label
              className="form-label flex flex-align-center mr-8"
              htmlFor="shliach_availability"
            >
              Available to all Shluchim/Restrictions apply
              <span className="tooltip-container">
                <i className="material-icons large-text ml-8 link-text no-hover">
                  info
                </i>
                <span className="tooltip tooltip-medium-width tooltip-left">
                  If Restricted is select, configure participants by clicking
                  Event Settings.
                </span>
              </span>
            </label>
            <Toggle
              disabled={!allowUpdateSchedule || restrictedEdit}
              id="shliach_availability"
              name="schedule.includesAllShluchim"
              onChange={(name, value) => {
                actions.changeState(name, value);
                if (
                  schedule.hasStartedRegistration &&
                  !value &&
                  scheduleOrig.includesAllShluchim
                ) {
                  window.alert(
                    "Restricting Shluchim availability after registration start date will not affect existing registrations."
                  );
                }
              }}
              options={[
                {
                  value: true,
                  display: "All",
                },
                {
                  value: false,
                  display: "Restricted",
                },
              ]}
              value={schedule.includesAllShluchim}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
