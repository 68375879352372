import React from "react";
import ConfirmationModal from "../../shared/ConfirmationModal";
import FadeOutMessage from "../../shared/FadeOutErrorMessage";
import Loader from "../../common/Loader";
import StudentDemographics from "./demographics/StudentDemographics";
import StudentProfile from "./profile/StudentProfile";

import StudentsApi from "../../../services/resources/StudentsApi";
import SystemApi from "../../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import {
  getFormattedValuesForForm,
  replaceValuesInObject,
  TwoWayMap,
} from "../../../lib";
import AuthClient from "../../../services/resources/AuthClient";

export default class StudentProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const tabs = [
      { component: StudentProfile, id: "profile", title: "Student Profile" },
      {
        component: StudentDemographics,
        id: "demographics",
        title: "Demographics",
      },
    ];

    //hard-coded profile settings for shliach's student profile form
    const isSubmittedForLamplighters =
      props.studentProfile?.cannotRemoveDobAndCell;
    const profileSettings = [
      { field: "FirstName", isRequired: true },
      { field: "LastName", isRequired: true },
      { field: "Campus", isRequired: true },
      {
        field: "Dob",
        isRequired:
          isSubmittedForLamplighters && !!props.studentProfile.person.dob,
      },
      {
        field: "Cell",
        isRequired: isSubmittedForLamplighters && !!props.studentProfile.cell,
      },
    ]; //TODO: these?

    this.state = {
      campuses: {
        data: [],
        errorMessage: "",
        loading: false,
      },
      errorMessage: "",
      initialProfile: null,
      loading: false,
      profile: null,
      profileSettings,
      profileValidationErrors: [],
      showFormValidation: false,
      showSubmitSuccessMessage: false,
      showUpdateEmailConfirmationModal: false,
      submitErrorMessage: "",
      submitFormAttempted: false,
      submitLoading: false,
      systemLists: {},
      systemListsErrorMessage: "",
      tab: tabs[0],
      tabs,
      updateEmailConfirmed: false,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getProfileInfo();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getProfileInfo = async () => {
    await this.getSystemLists(); //note: loading system lists before profile info to ensure that countries are available when the phone inputs are mounted
    this.getStudentProfile();
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists(); //TODO: specify lists or get all?
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  getStudentProfile = async () => {
    this.setState({ errorMessage: "", loading: true });

    const { studentId, studentProfile } = this.props; //TODO: pass in props

    //first load campuses if not yet loaded //TODO: confirm it could have been loaded so check is necessary...
    if (!this.state.campuses.data.length) {
      this.getCampuses();
    }

    let profile = {};
    if (studentId) {
      try {
        const retrievedProfile = await StudentsApi.getStudent(
          this.apiSignal.token,
          studentId,
        );
        profile = getFormattedValuesForForm(retrievedProfile, ["person.dob"]);
      } catch (err) {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(
              err,
              "Sorry, something went wrong and student details could not be retrieved. Please try again.",
            ),
            loading: false,
          });
          return;
        }
      }
    } else if (studentProfile) {
      //studentProfile pass in with props from student details page
      profile = getFormattedValuesForForm(studentProfile, ["person.dob"]);
    } else {
      //initialize studentProfile for create student
      profile = {
        address: {
          address1: "",
          address2: "",
          city: "",
          country: "",
          state: "",
          zip: "",
        },
        allergies: "",
        dietaryNotes: "",
        campus: "",
        class: "",
        demographics: {
          childhoodKosherStatus: "",
          childhoodShabbosHolidayStatus: "",
          childhoodSynagogueAttendanceStatus: "",
          childhoodTefillinStatus: "",
          childhoodTorahStudyStatus: "",
          cityOfOrigin: "",
          cityOfOriginJewishCommunityType: "",
          cityOfOriginRegionID: "",
          familyJewishAffiliation: "",
          halachicJewishStatus: "",
          interests: [],
          jewishInterestLevel: "",
          learningEventsParticipation: "",
          notes: "",
          orgInvolvementTypes: [],
          personality: [],
          personalShliachShluchaTimeParticipation: "",
          programParticipationTypes: [],
          referralSourceID: "",
          shabbatDinnerParticipation: "",
          socialEventsParticipation: "",
        },
        graduationYear: "",
        studentIDNumber: "",
        majorID: "",
        doubleMajorID: "",
        fatherBackground: "",
        motherBackground: "",
        hasTakenIsraelCourses: "",
        jewishEducations: [],
        hasNoJewishEducation: null,
        jewishIsraelKnowledgeLevel: "",
        doParentsShareResidence: null,
        father: {
          name: "",
          email: "",
          phoneNumber: "",
          phoneCountryID: "",
        },
        mother: {
          name: "",
          email: "",
          phoneNumber: "",
          phoneCountryID: "",
        },
        cell: "",
        cellCountryID: "",
        email: "",
        isJewish: null,
        person: {
          dob: "",
          firstName: "",
          gender: "Unknown",
          hebrewName: "",
          lastName: "",
          profileImageURL: "",
        },
        tShirtSize: "",
      };
    }

    this.setState(
      {
        initialProfile: profile,
        profile: _cloneDeep(profile),
        loading: false,
      },
      // async () => {
      //   //retrieve profile settings based on student campus or shliach's campus
      //   if (profile.campus) {
      //     await this.getProfileSettings(profile.campus.id);
      //   }
      //   this.setState({ loading: false });
      // }
    );
  };

  getCampuses = async () => {
    this.setState({
      campuses: { data: [], errorMessage: "", loading: true },
    });
    try {
      const campusesResponse = await AuthClient.get(
        "Campuses/basic",
        this.apiSignal.token,
      );
      this.setState({
        campuses: {
          data: campusesResponse.data,
          errorMessage: "",
          loading: false,
        },
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          campuses: {
            data: [],
            errorMessage: ApiCallErrorMessageHandler(
              err,
              "Sorry, something went wrong and student campuses could not be retrieved. Please try again.",
            ),
            loading: false,
          },
        });
      }
    }
  };

  // note: we are removing the 'dynamic profile settings based on admin's (in-db) requirements' for now, we can adjust and reinsert if shliach required fields become dynamic in the future
  // getProfileSettings = async (campusId) => {
  //   await this.props.actions.getStudentProfileSettings(null, null, campusId);
  // };

  isIncompleteProfile = () => {
    const { profile, profileValidationErrors } = this.state;

    if (!profile) return;

    const { profileSettings } = this.state;

    //custom required fields validation
    if (
      profileSettings &&
      profileSettings
        .filter((ps) => ps.isRequired)
        .some((ps) => {
          var profileFieldAccessor = this.profileFieldsMap.revGet(ps.field);
          var profileFieldValue = _get(profile, profileFieldAccessor);
          return (
            (!profileFieldValue &&
              profileFieldValue !== true &&
              profileFieldValue !== false) ||
            (profileFieldAccessor === "person.gender" &&
              profileFieldValue === "Unknown")
          );
        })
    ) {
      return true;
    }

    //other validation
    const {
      jewishEducations,
      hasNoJewishEducation,
      person: { firstName, lastName } = {},
      allergies,
      hasAllergies,
      dietaryNotes,
    } = profile;
    let allergiesComplete =
      !hasAllergies || (allergies && allergies.length > 0);
    if (allergies.includes("Other") && !dietaryNotes) {
      allergiesComplete = false;
    }
    if (!allergiesComplete) {
      return true;
    }
    return (
      //names must be at least 2 characters
      (firstName && firstName.length < 2) ||
      (lastName && lastName.length < 2) ||
      //TODO COC-3699 - require Jewish education fields
      /* (!hasNoJewishEducation && !jewishEducations.length) ||
       */
      //Jewish education - if 'none' no other fields can be selected
      (hasNoJewishEducation && jewishEducations.length) ||
      //jewish education fields must be complete if any are provided
      !!(
        jewishEducations &&
        jewishEducations.length &&
        jewishEducations.find((j) => !j.affiliation || !j.name)
      ) ||
      profileValidationErrors.length
    );
  };

  profileFieldsMap = new TwoWayMap({
    "person.profileImageURL": "ProfileImageURL",
    "person.firstName": "FirstName",
    "person.lastName": "LastName",
    email: "Email",
    cell: "Cell",
    "person.hebrewName": "HebrewName",
    "person.dob": "Dob",
    "person.gender": "Gender",
    isJewish: "IsJewish",
    "address.address1": "Address1",
    "address.address2": "Address2",
    "address.city": "City",
    "address.state": "State",
    "address.zip": "Zip",
    "address.country": "Country",
    campus: "Campus",
    class: "Class",
    graduationYear: "GraduationYear",
    studentIDNumber: "StudentIDNumber",
    majorID: "Major",
    doubleMajorID: "DoubleMajor",
    fatherBackground: "FatherBackground",
    motherBackground: "MotherBackground",
    "father.name": "FatherName",
    "mother.name": "MotherName",
    "father.email": "FatherEmail",
    "mother.email": "MotherEmail",
    "father.phoneNumber": "FatherPhone",
    "mother.phoneNumber": "MotherPhone",
    doParentsShareResidence: "DoParentsShareResidence",
    hebrewLevel: "HebrewLevel",
    hadBarBatMitzvah: "HadBarBatMitzvah",
    hasTakenIsraelCourses: "HasTakenIsraelCourses",
    jewishIsraelKnowledgeLevel: "JewishIsraelKnowledgeLevel",
    tShirtSize: "TShirtSize",
  });

  isProfileFieldRequired = (fieldAccessor) => {
    const { profileSettings } = this.state;

    return !!(
      profileSettings &&
      profileSettings.find(
        (ps) =>
          ps.field === this.profileFieldsMap.get(fieldAccessor) &&
          ps.isRequired,
      )
    );
  };

  getProfileFieldLabel = (label, fieldName) => (
    <React.Fragment>
      <span>{label}</span>
      {this.isProfileFieldRequired(fieldName) && (
        <span className="ml-4 required-text">*</span>
      )}
    </React.Fragment>
  );

  onChangeProfileEvt = (event) => {
    this.onChangeProfile(event.target.name, event.target.value);
  };

  onChangeProfile = (name, value, other) => {
    this.setState(
      // because subsequent setStates are called to update phoneCountryID from the PhoneInput component, use a state updater func to ensure that updates are executed in the order they are called and previous state updates are not overridden(https://duncanleung.com/avoiding-react-setstate-pitfalls/#solution-use-the-updater-code-classlanguage-textfunctioncode-form-to-queue-state-updates)
      (prevState) => {
        let profile = _cloneDeep(prevState.profile);
        _set(profile, name, value);
        if (other) {
          Object.keys(other).forEach((update) =>
            _set(profile, update, other[update]),
          );
        }
        return { profile };
      },
      // () => {
      //   //refresh profile settings on campus change
      //   if (name === "campus" && value) {
      //     this.getProfileSettings(value.id);
      //   }
      // }
    );
  };

  onSaveProfile = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        showFormValidation: true,
        submitErrorMessage: "",
        submitFormAttempted: true,
        showSubmitSuccessMessage: false,
      },
      async () => {
        if (this.isIncompleteProfile()) {
          //go to profile tab (where required fields are) if not currently on the tab
          const { tab, tabs } = this.state;
          if (tab.id !== "profile") {
            this.setState({ tab: tabs.find((t) => t.id === "profile") });
          }
          return;
        }

        const { initialProfile, profile, updateEmailConfirmed } = this.state;

        if (
          initialProfile.email &&
          profile.email !== initialProfile.email &&
          !updateEmailConfirmed
        ) {
          this.setState({
            showUpdateEmailConfirmationModal: true,
          });
          return;
        }

        this.setState({ submitLoading: true });

        const profileForSubmission = _cloneDeep(profile);
        replaceValuesInObject(
          profileForSubmission,
          (val) => val === "",
          () => null,
        );

        try {
          const submittedProfile = await StudentsApi.submitStudent(
            profileForSubmission,
          );

          if (this.props.close) {
            this.props.close();
          } else if (submittedProfile) {
            const formattedProfile = getFormattedValuesForForm(
              submittedProfile,
              ["person.dob"],
            );
            this.setState({
              initialProfile: formattedProfile,
              profile: _cloneDeep(formattedProfile),
              showSubmitSuccessMessage: true,
              submitFormAttempted: false,
              submitLoading: false,
              updateEmailConfirmed: false,
            });
          }

          if (this.props.onSubmit) {
            this.props.onSubmit(submittedProfile);
          }
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              submitErrorMessage: ApiCallErrorMessageHandler(
                err,
                "Something went wrong and student profile could not be saved.",
              ),
              submitLoading: false,
            });
          }
        }
      },
    );
  };

  updateProfileValidation = (name, isValid) => {
    const { profileValidationErrors } = this.state;
    this.setState({
      profileValidationErrors: isValid
        ? profileValidationErrors.filter((err) => err !== name)
        : [...profileValidationErrors, name],
    });
  };

  render() {
    const {
      close,
      // profileSettings: { error: profileSettingsError },
    } = this.props;

    const {
      campuses,
      errorMessage,
      initialProfile,
      loading, // loading profile or initial profile settings
      profile,
      profileValidationErrors,
      showFormValidation,
      showSubmitSuccessMessage,
      showUpdateEmailConfirmationModal,
      submitErrorMessage,
      submitFormAttempted,
      submitLoading,
      systemLists,
      tab,
      tabs,
    } = this.state;

    const isCreateStudent = !this.props.studentId && !this.props.studentProfile;

    return loading ? (
      <div className="full-page-loader">
        <Loader />
      </div>
    ) : errorMessage ? (
      <div>
        Sorry, something went wrong and your profile information could not be
        retrieved.
      </div>
    ) : (
      !!profile && (
        <div
          className={`relative${isCreateStudent ? " new-student-profile" : ""}`}
        >
          <div className="mb-24 mobile-mb-8 container">
            <div className="flex flex-align-center flex-justify-space">
              <p className="xl-text fw-700 mb-16 mt-8">
                {isCreateStudent ? "Create New" : "Edit"} Student
              </p>
              <div className="save-btn-container relative flex flex-align-center mobile-hidden">
                {close && (
                  <button
                    className="btn btn-medium btn-light custom-btn mr-16"
                    disabled={submitLoading}
                    onClick={close}
                    style={{ width: "120px" }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-medium btn-accent custom-btn"
                  disabled={submitLoading || _isEqual(initialProfile, profile)}
                  onClick={this.onSaveProfile}
                  style={{ width: "120px" }}
                  type="submit"
                >
                  {submitLoading ? "Saving..." : "Save"}
                </button>
                {!submitLoading && (
                  <React.Fragment>
                    <FadeOutMessage
                      className="success-text"
                      message={
                        showSubmitSuccessMessage
                          ? "Changes have been saved"
                          : ""
                      }
                      onTimeout={() =>
                        this.setState({
                          showSubmitSuccessMessage: false,
                        })
                      }
                      timeout={20000}
                    />
                    <span className="error-message">
                      {submitFormAttempted &&
                        (showFormValidation && this.isIncompleteProfile()
                          ? "Please complete required/incomplete fields"
                          : submitErrorMessage || "")}
                    </span>
                  </React.Fragment>
                )}
              </div>
            </div>
            {isCreateStudent && (
              <p className="accent-text mt-16" style={{ fontSize: "13px" }}>
                Student will be visible to Shluchim only and will not be aware
                of their account and cannot register for events until they log
                in to the portal and finalize account creation
              </p>
            )}
          </div>

          <ul className="student-profile-modal-tabs">
            {tabs.map((t) => (
              <li
                className={tab && tab.id === t.id ? "active" : ""}
                key={t.id}
                onClick={() => this.setState({ tab: t })}
              >
                {t.title}
              </li>
            ))}
          </ul>
          {tab &&
            React.createElement(tab.component, {
              campuses,
              getProfileFieldLabel: this.getProfileFieldLabel,
              initialProfile,
              isCreateStudent,
              isProfileFieldRequired: this.isProfileFieldRequired,
              onChangeProfile: this.onChangeProfile,
              onChangeProfileEvt: this.onChangeProfileEvt,
              profile,
              profileValidationErrors,
              showFormValidation,
              systemLists,
              updateProfileValidation: this.updateProfileValidation,
            })}
          <div
            className="save-btn-container relative flex flex-align-center desktop-hidden tablet-hidden"
            style={{ position: "sticky", bottom: 0 }}
          >
            <button
              className="btn btn-large btn-accent full-width"
              disabled={
                submitLoading ||
                // profileSettingsError ||
                _isEqual(initialProfile, profile)
              }
              onClick={this.onSaveProfile}
              type="submit"
            >
              {submitLoading ? "Saving..." : "Save"}
            </button>
            {!submitLoading && (
              <React.Fragment>
                <FadeOutMessage
                  className="success-text"
                  message={
                    showSubmitSuccessMessage ? "Changes have been saved" : ""
                  }
                  onTimeout={() =>
                    this.setState({ showSubmitSuccessMessage: false })
                  }
                  timeout={20000}
                />
                <span className="error-message">
                  {/* {profileSettingsError ? "Profile settings could not be retrieved. Please refresh the page to try again." : */}
                  {submitFormAttempted &&
                    (showFormValidation && this.isIncompleteProfile()
                      ? "Please complete required/incomplete fields"
                      : submitErrorMessage || "")}
                </span>
              </React.Fragment>
            )}
          </div>

          <ConfirmationModal
            cancel={() =>
              this.setState({ showUpdateEmailConfirmationModal: false })
            }
            confirm={() => {
              this.setState(
                {
                  showUpdateEmailConfirmationModal: false,
                  updateEmailConfirmed: true,
                },
                this.onSaveProfile,
              );
            }}
            message="You changed the email address for this student. This will change the email used for login. Are you sure you want to make this change?"
            show={showUpdateEmailConfirmationModal}
            title="Confirm Email Change"
          />
        </div>
      )
    );
  }
}
