import React from "react";
import AddToCourseModal from "./AddToCourseModal";
import ConfirmationModal from "../../../../../shared/ConfirmationModal";
import Loader from "../../../../../common/Loader";

import EduApi from "../../../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../../../lib/coc-common-scripts";
import { formatFullName, formatNumber } from "../../../../../../lib";
import axios from "axios";
import moment from "moment";

export default class StudentLatestCourseDetails extends React.PureComponent {
  state = {
    cancelCourseEnrollmentErrorMessage: "",
    cancelCourseEnrollmentLoading: false,
    course: null,
    errorMessage: "",
    loading: false,
    showAddToCourseModal: false,
    showCancelCourseEnrollmentModal: false,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getLatestCourse();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getLatestCourse = () => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
      },
      () => {
        EduApi.getEduStudentCourses(
          this.apiSignal.token,
          this.props.eduStudent.eduStudentEnrollmentID,
          1,
          1,
          "date",
        )
          .then(({ courses }) => {
            this.setState({
              course: courses && courses[0],
              loading: false,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                course: null,
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
              });
            }
          });
      },
    );
  };

  cancelCourseEnrollment = () => {
    this.setState(
      {
        cancelCourseEnrollmentErrorMessage: "",
        cancelCourseEnrollmentLoading: true,
      },
      () => {
        const {
          course: { eduStudentCourseScheduleEnrollmentID },
        } = this.state;
        EduApi.removeEduStudentFromCourse(
          this.apiSignal.token,
          eduStudentCourseScheduleEnrollmentID,
        )
          .then(() => {
            this.setState({
              cancelCourseEnrollmentLoading: false,
              showCancelCourseEnrollmentModal: false,
            });
            this.getLatestCourse();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                cancelCourseEnrollmentErrorMessage:
                  ApiCallErrorMessageHandler(err),
                cancelCourseEnrollmentLoading: false,
              });
            }
          });
      },
    );
  };

  render() {
    const {
      eduStudent: {
        status,
        eduStudentEnrollmentID,
        student: {
          person: { firstName, lastName },
        },
      },
      viewOnly,
    } = this.props;

    const {
      cancelCourseEnrollmentErrorMessage,
      cancelCourseEnrollmentLoading,
      course,
      errorMessage,
      loading,
      showAddToCourseModal,
      showCancelCourseEnrollmentModal,
    } = this.state;

    const {
      courseScheduleName,
      didCompleteSurvey,
      firstClassDate,
      lastClassDate,
      numOfClassesScheduled,
      numOfClassesAttended,
      studentCourseScheduleEnrollmentStatus,
    } = course || {};

    return (
      <div>
        {!viewOnly && (
          <div>
            <p
              className="accent-text-secondary fw-700 uppercase-text link-text"
              onClick={() =>
                this.setState({
                  showAddToCourseModal: !showAddToCourseModal,
                })
              }
            >
              + Add to course
            </p>
            {showAddToCourseModal && (
              <AddToCourseModal
                close={() => this.setState({ showAddToCourseModal: false })}
                getCourses={this.getLatestCourse}
                name={`${firstName} ${lastName}`}
                studentID={eduStudentEnrollmentID}
                show={showAddToCourseModal}
              />
            )}
          </div>
        )}
        <div className="flex flex-align-center mb-16">
          <p className="fw-900 medium-text mr-16">Course History</p>
          {!viewOnly && course && (
            <p className="flex flex-align-center link-text-secondary">
              <i
                className="material-icons medium-text"
                onClick={() => {
                  this.setState({
                    cancelCourseEnrollment: course,
                    showCancelCourseEnrollmentModal: true,
                  });
                }}
                style={{ padding: "8px" }}
              >
                delete
              </i>{" "}
              Remove from course
            </p>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong and course details could not be retrieved."}
            </p>
          </div>
        ) : (
          course && (
            <div
              className="two-column-grid"
              style={{ gridTemplateColumns: "168px 1fr", gridGap: 8 }}
            >
              <p className="fw-700">Schedule</p>
              <p>{courseScheduleName}</p>
              <p className="fw-700">Start & End Date</p>
              <p>
                {!firstClassDate
                  ? "TBD"
                  : `${moment(firstClassDate).format("M/D/YY")} - ${moment(
                      lastClassDate,
                    ).format("M/D/YY")}`}
              </p>
              <p className="fw-700">Classes Completed</p>
              <p>
                {formatNumber(numOfClassesAttended)}/
                {formatNumber(numOfClassesScheduled)}
              </p>
              <p className="fw-700">Review Completed</p>
              <p>{didCompleteSurvey ? "Yes" : "No"}</p>
              <p className="fw-700">Status</p>
              <p>
                {status === "Rejected"
                  ? "N/A"
                  : studentCourseScheduleEnrollmentStatus}
              </p>
            </div>
          )
        )}
        <ConfirmationModal
          cancel={() =>
            this.setState({
              cancelCourseEnrollmentErrorMessage: "",
              cancelCourseEnrollmentLoading: false,
              showCancelCourseEnrollmentModal: false,
            })
          }
          confirm={this.cancelCourseEnrollment}
          errorMessage={cancelCourseEnrollmentErrorMessage}
          loading={cancelCourseEnrollmentLoading}
          message={`Are you sure you'd like to cancel ${formatFullName(
            firstName,
            lastName,
          )}'s enrollment in ${courseScheduleName}?`}
          show={showCancelCourseEnrollmentModal}
        />
      </div>
    );
  }
}
