import React from "react";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { Storage } from "../../lib/coc-common-scripts";

// interface PaginatorProps {
//   totalResults: number;
//   currentPage: number;
//   handleOnChange: (selectedPage: number, resultsPerPage: number) => void;
// }

export class Paginator extends React.Component {
  render() {
    const { currentPage, totalResults, handleOnChange } = this.props;
    /** @type {string} */
    const storedSize = Storage().getItem("pgrPageSize") || "";
    const resultsPerPage = +storedSize || 10;
    /**
     *
     * @param {number} selectedPage
     * @param {number} resPerPage
     */
    const handleOnDisplayChange = (selectedPage, resPerPage) => {
      Storage().storeItem("pgrPageSize", resPerPage.toString());
      if (handleOnChange) {
        handleOnChange(selectedPage, resPerPage);
      }
    };

    return (
      <div className="table-pagination">
        <Pagination
          current={currentPage}
          total={totalResults}
          pageSize={resultsPerPage}
          onChange={handleOnDisplayChange}
        />

        <div className="text-left">
          <span className="results-summary-desc">
            <label className="control-label">Show&nbsp;</label>
          </span>
          <select
            className="form-control"
            style={{ display: "inline", width: "auto" }}
            onChange={(e) => handleOnDisplayChange(1, +e.currentTarget.value)}
            value={resultsPerPage}
          >
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>
      </div>
    );
  }
}
