import React from "react";
import ConfirmationModal from "../../shared/ConfirmationModal";
import Dropzone from "react-dropzone";
import FadeOutErrorMessage from "../../shared/FadeOutErrorMessage";
import FilePreviewModal from "../../common/FilePreviewModal";

import ContentApi from "../../../services/resources/ContentApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class BeneficiaryDesignationDocumentUpload extends React.PureComponent {
  state = {
    docForUpload: null,
    errorMessage: "",
    loading: false,
    showConfirmUploadModal: false,
    showFilePreviewModal: false,
    successMessage: "",
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  closeConfirmUploadModal = () => {
    this.setState({
      docForUpload: null,
      showConfirmUploadModal: false,
    });
  };

  selectDocument = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];

      const acceptedFileTypes = [".pdf"];
      if (
        acceptedFileTypes.some(
          (fileType) => file.name.toLowerCase().indexOf(fileType) > 0,
        )
      ) {
        this.setState({
          docForUpload: file,
          errorMessage: "",
          showConfirmUploadModal: true,
        });
      } else {
        this.setState({
          docForUpload: null,
          errorMessage: "Unsupported file type. Please upload a PDF file.",
        });
      }
    } else {
      this.setState({
        errorMessage: "File could not be uploaded. Please try again.",
      });
    }
  };

  uploadBeneficiaryDesignationDocument = () => {
    const { docForUpload } = this.state;

    this.closeConfirmUploadModal();

    this.setState(
      {
        errorMessage: "",
        loading: true,
        successMessage: "",
      },
      async () => {
        let newState = { loading: false };

        try {
          const documentURL = await ContentApi.uploadFile(
            docForUpload,
            "life_insurance_bdf",
            true,
          );

          const { documentName, updateEnrollment } = this.props;

          const { error } = await updateEnrollment(documentName, documentURL);

          if (error) {
            throw error;
          }

          newState.successMessage =
            "Beneficiary Designation Form has been updated";
        } catch (err) {
          if (!axios.isCancel(err)) {
            newState.errorMessage =
              ApiCallErrorMessageHandler(err) ||
              "Sorry, something went wrong and your form could not be uploaded.  Please try again.";
          }
        }

        this.setState({ ...newState });
      },
    );
  };

  render() {
    const { documentURL, disabled, error, title } = this.props;
    const {
      errorMessage,
      loading,
      showConfirmUploadModal,
      showFilePreviewModal,
      successMessage,
    } = this.state;

    return (
      <div>
        <p className="fw-700 medium-text">{title || ""}</p>

        {loading ? (
          <div className="accent-text">
            Uploading Beneficiary Designation Form...
          </div>
        ) : (
          documentURL && (
            <p
              className="flex flex-align-center accent-text link-text"
              onClick={() => this.setState({ showFilePreviewModal: true })}
            >
              <i className="material-icons mr-8">description</i>
              Beneficiary Designation
            </p>
          )
        )}

        <Dropzone
          accept="application/pdf"
          disabled={disabled || loading}
          onDrop={this.selectDocument}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className={`dropzone${disabled ? " disabled" : ""}`}
            >
              <input {...getInputProps()} />
              <p
                className={`uppercase-text fw-500 link-text ${
                  error ? " error" : ""
                }`}
              >
                Upload {documentURL ? "New Form" : "Form"}
              </p>
            </div>
          )}
        </Dropzone>

        {(errorMessage || successMessage) && (
          <FadeOutErrorMessage
            className={successMessage ? "success-message" : ""}
            message={errorMessage || successMessage}
            onTimeout={() =>
              this.setState({
                errorMessage: "",
                successMessage: "",
              })
            }
          />
        )}

        <FilePreviewModal
          close={() => this.setState({ showFilePreviewModal: false })}
          isSecure={true}
          show={showFilePreviewModal}
          title={"Beneficiary Designation Form Preview"}
          type="application/pdf"
          url={documentURL}
        />

        <ConfirmationModal
          cancel={this.closeConfirmUploadModal}
          confirm={this.uploadBeneficiaryDesignationDocument}
          message="Upload new beneficiary designation form?"
          show={showConfirmUploadModal}
        />
      </div>
    );
  }
}
