import React from "react";
import { Link } from "react-router";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import viewHistory from "../../services/ViewHistory";
import { ReportingTile } from "../common/ReportingTile";
import LifeInsuranceReportingApi from "../../services/resources/LifeInsuranceReportingApi";
import { ArrayToLookup } from "../../lib/coc-common-scripts";

export class LifeInsuranceReporting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    if (
      !AuthService.UserHasClaim(PermissionClaims.LifeInsuranceSummary) &&
      !AuthService.UserHasClaim(PermissionClaims.LifeInsuranceDetailed)
    ) {
      return;
    }

    viewHistory.add("Program", {
      title: "Life Insurance",
      path: "/lifeinsurance",
    });
    this.requestReportData();
  }

  requestReportData() {
    this.setState({ loading: true });

    LifeInsuranceReportingApi.get()
      .then((response) => {
        this.setState({
          reportData: response.data.lifeInsuranceStatuses,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  render() {
    if (
      !AuthService.UserHasClaim(PermissionClaims.LifeInsuranceSummary) &&
      !AuthService.UserHasClaim(PermissionClaims.LifeInsuranceDetailed)
    ) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasLifeInsuranceDetailedPermission = AuthService.UserHasClaim(
      PermissionClaims.LifeInsuranceDetailed,
    );

    const { reportData } = this.state;

    const statusCounts =
      (reportData && ArrayToLookup(reportData, "status")) || {};
    const totalCount =
      reportData &&
      reportData.reduce((accum, status) => accum + status.count, 0);

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/lifeinsurance">Life Insurance</Link>
              </li>
              <li>Reports</li>
            </ol>
          </div>
          <div>
            <div className="col-md-12">
              <h3>Policies</h3>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  {hasLifeInsuranceDetailedPermission ? (
                    <Link to={`/lifeinsurance/reporting/detailed`}>
                      <ReportingTile label="Total" value={totalCount} />
                    </Link>
                  ) : (
                    <ReportingTile label="Total" value={totalCount} />
                  )}
                </div>
              </div>
            </div>
            {statusCounts["Active"] && (
              <div className="col-md-3">
                <ReportingTile
                  label="Active"
                  value={statusCounts["Active"].count}
                />
              </div>
            )}
            {statusCounts["Due"] && (
              <div className="col-md-3">
                <ReportingTile
                  label="Due within a month"
                  value={statusCounts["Due"].count}
                />
              </div>
            )}
            {statusCounts["OverDue"] && (
              <div className="col-md-3">
                <ReportingTile
                  label="Overdue"
                  value={statusCounts["OverDue"].count}
                />
              </div>
            )}
            {statusCounts["Inactive"] && (
              <div className="col-md-3">
                <ReportingTile
                  label="Inactive"
                  value={statusCounts["Inactive"].count}
                />
              </div>
            )}
            {statusCounts["Terminated"] && (
              <div className="col-md-3">
                <ReportingTile
                  label="Terminated"
                  value={statusCounts["Terminated"].count}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.loading && <Loader />}
      </div>
    );
  }
}
