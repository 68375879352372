import React from "react";
import Modal from "react-modal";
import StudentProfileForm from "./StudentProfileForm";

export default class StudentProfileModal extends React.PureComponent {
  render() {
    const { close, show } = this.props;
    return (
      <Modal className="modal-container" isOpen={show}>
        <div className="modal-card card student-profile-modal relative">
          <div
            className="desktop-hidden tablet-hidden mb-8"
            style={{ position: "absolute", top: 24, right: 24, zIndex: 1 }}
          >
            <i className="material-icons" onClick={close}>
              close
            </i>
          </div>
          <div className="mobile-hidden mb-8 text-right">
            <i
              className="material-icons pointer large-text link-text-secondary"
              onClick={close}
            >
              close
            </i>
          </div>
          <StudentProfileForm {...this.props} />
        </div>
      </Modal>
    );
  }
}
