import React from "react";

import CustomSelect from "../Form/CustomSelect";
import MultiSelect from "../Form/MultiSelect";
import FormInput from "../Form/FormInput";
import FormInputUpdate from "../Form/FormInputUpdate";
import RadioContainer from "../Form/RadioContainer";

export default class GeneralInfo extends React.PureComponent {
  state = {
    generalInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        generalInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      require,
      touched,
      values,
      readOnly,
      setFieldValue,
      sys: { prestigeTypes, programLevels },
    } = this.props;
    return (
      <div
        className={
          this.state.generalInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-48 mb-16"
          onClick={() => {
            this.setState({
              generalInfoShowing: !this.state.generalInfoShowing,
            });
          }}
        >
          School Information
        </p>
        <div className="profile-form-inputs">
          <div
            className="flex flex-justify-space flex-align-center relative"
            style={{ gridColumnStart: "span 2" }}
          >
            <FormInput
              label="Name"
              type="text"
              name="name"
              validate={require}
              className="form-required"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative mr-24">
            <FormInput
              label="College Nickname/Initial"
              type="text"
              name="nickname"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div className="flex flex-align-center relative">
            <FormInput
              label="School Type"
              name="prestige"
              errors={errors}
              touched={touched}
              className="custom-input mr-12"
              style={{ flex: "2" }}
            >
              {({ field }) => (
                <CustomSelect
                  placeholder="Select Type..."
                  errors={errors}
                  touched={touched}
                  {...field}
                  options={
                    prestigeTypes &&
                    prestigeTypes.map((p) => ({
                      key: p.enumValue,
                      value: p.enumValue,
                      label: p.displayValue,
                    }))
                  }
                  setFieldValue={setFieldValue}
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInputUpdate
              label="Program Levels"
              name="programLevels"
              errors={errors}
              touched={touched}
              className="custom-input mr-12"
              style={{ flex: "2" }}
            >
              {({ field }) => (
                <MultiSelect
                  placeholder="Select Type..."
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  isMulti={true}
                  setFieldValue={this.props.setFieldValue}
                  options={
                    programLevels &&
                    programLevels.map((p) => ({
                      key: p.enumValue,
                      value: p.enumValue,
                      label: p.displayValue,
                    }))
                  }
                  value={
                    values.programLevels
                      ? values.programLevels.map((value) =>
                          value.programLevel
                            ? {
                                key: value.programLevel,
                                value: value.programLevel,
                                label: value.programLevel,
                              }
                            : value,
                        )
                      : null
                  }
                />
              )}
            </FormInputUpdate>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Annual Tuition"
              type="text"
              name="annualTuition"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div className="flex flex-align-center mr-24 relative">
            <FormInput
              label="Student Residency"
              name="studentResidencyType"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  input1={{
                    id: "commuter",
                    value: "Commuter",
                    checked: "Commuter",
                    label: "Commuter",
                  }}
                  input2={{
                    id: "residential",
                    value: "Residential",
                    checked: "Residential",
                    label: "Residential",
                  }}
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-align-center relative">
            <FormInput
              label="Institution Control"
              name="isInstitutionControlPublic"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  input1={{
                    id: "public",
                    value: true,
                    checked: "true",
                    label: "Public",
                  }}
                  input2={{
                    id: "private",
                    value: false,
                    checked: "false",
                    label: "Private",
                  }}
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-align-center mr-24 relative">
            <FormInput
              label="School Calendar"
              name="schoolCalendarType"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  input1={{
                    id: "quarterly",
                    value: "Quarterly",
                    checked: "Quarterly",
                    label: "Quarterly",
                  }}
                  input2={{
                    id: "semester",
                    value: "Semester",
                    checked: "Semester",
                    label: "Semester",
                  }}
                />
              )}
            </FormInput>
          </div>
        </div>
      </div>
    );
  }
}
