import React from "react";
import Select from "../../../shared/CustomSelect";

export default class GeneralDemographics extends React.PureComponent {
  render() {
    const {
      demographics: {
        cityOfOrigin = "",
        cityOfOriginJewishCommunityType = "",
        cityOfOriginRegionID = "",
        familyJewishAffiliation = "",
        halachicJewishStatus = "",
      },
      halachicJewishStatuses,
      jewishCommunityTypes,
      jewishFamilyAffiliationTypes,
      onChange,
      onChangeEvt,
      regions,
    } = this.props;

    return (
      <div className="student-profile-form-section">
        <p className="profile-section-title">Overall Demographics</p>
        <div className="student-profile-form-grid">
          <div className="input-container">
            <label>City of Origin</label>
            <input
              name="cityOfOrigin"
              onChange={onChangeEvt}
              type="text"
              value={cityOfOrigin}
            />
          </div>
          <div className="input-container">
            <label>City of Origin Jewish Stats</label>
            <Select
              classNamePrefix="input-container-select"
              name="cityOfOriginJewishCommunityType"
              onChange={onChange}
              options={
                jewishCommunityTypes &&
                jewishCommunityTypes.map((t) => ({
                  label: t.displayValue,
                  value: t.enumValue,
                }))
              }
              placeholder="Select Stats"
              searchable={true}
              value={cityOfOriginJewishCommunityType}
            />
          </div>
          <div className="input-container">
            <label>Geography</label>
            <Select
              classNamePrefix="input-container-select"
              name="cityOfOriginRegionID"
              onChange={onChange}
              options={
                regions &&
                regions.map((r) => ({
                  label: r.name,
                  value: r.id,
                }))
              }
              placeholder="Select Region"
              searchable={true}
              value={cityOfOriginRegionID}
            />
          </div>
          <div className="input-container">
            <label>Family Affiliation</label>
            <Select
              classNamePrefix="input-container-select"
              name="familyJewishAffiliation"
              onChange={onChange}
              options={
                jewishFamilyAffiliationTypes &&
                jewishFamilyAffiliationTypes.map((t) => ({
                  label: t.displayValue,
                  value: t.enumValue,
                }))
              }
              placeholder="Select Affiliation"
              searchable={true}
              value={familyJewishAffiliation}
            />
          </div>
          <div className="input-container">
            <label>Halachically Jewish Student</label>
            <Select
              classNamePrefix="input-container-select"
              name="halachicJewishStatus"
              onChange={onChange}
              options={
                halachicJewishStatuses &&
                halachicJewishStatuses.map((s) => ({
                  label: s.displayValue,
                  value: s.enumValue,
                }))
              }
              placeholder="Select Status"
              searchable={true}
              value={halachicJewishStatus}
            />
            {halachicJewishStatus === "Complicated" && (
              <p className="xs-text accent-text">
                Explain in the Notes section below
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
