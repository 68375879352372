const MissingFieldValue = "--";
const colorOrange = "#F0AD4E";
const colorBlue = "#0275D8";
const ChartColors = [colorBlue, colorOrange];

export interface AgeCount {
    age: string;
    count: number;
}

export interface GenderCount {
    gender: string;
    count: number;
}

function GetReportValue(reportData, field: string): string {
    if (reportData === undefined) {
        return MissingFieldValue;
    }

    if (reportData.hasOwnProperty(field)) {
        return reportData[field];
    } else {
        return MissingFieldValue;
    }
}

// TODO: use lodash.range instead?
function GetRangeArray(dataArray: Array<AgeCount>) {
    const ageLimits = dataArray.reduce(
        ({ min, max }, { age }) => ({
            min: Math.min(min, +age),
            max: Math.max(max, +age),
        }),
        { min: 200, max: 0 }
    );
    return Array(ageLimits.max - ageLimits.min + 1)
        .fill(0)
        .map((_, i) => (ageLimits.min + i).toString());
}

function GetAgeArray(dataArray: Array<AgeCount>): Array<AgeCount> {
    if (!dataArray || dataArray.length < 2) {
        return dataArray || [];
    }

    const dataArrayToObject = dataArray.reduce(
        (returnObject, { age, count }) => ({ ...returnObject, [age]: count }),
        {}
    );
    return GetRangeArray(dataArray).map(age => ({ age, count: dataArrayToObject[age] || 0 }));
}

function GetAverageAgeAsString(countByAgeArray: Array<AgeCount>): string {
    /** @type {number} */
    const totalStudentsWithAge =
        countByAgeArray && countByAgeArray.length > 0
            ? countByAgeArray.reduce((acc, group) => acc + group.count, 0)
            : 0;

    /** @type {number} */
    const averageAge =
        totalStudentsWithAge > 0
            ? countByAgeArray.reduce((acc, group) => acc + +group.age * group.count, 0) / totalStudentsWithAge
            : 0;

    return averageAge === 0
        ? MissingFieldValue
        : Math.floor(averageAge).toString() + "y " + Math.floor((averageAge % 1) * 12).toString() + "m";
}

function GetGenderPercentages(countByGenderArray: Array<GenderCount>) {
    if (countByGenderArray.length === 0) {
        countByGenderArray.push({ gender: "Female", count: 0 });
    }
    if (countByGenderArray.length === 1) {
        countByGenderArray.push({ gender: countByGenderArray[0].gender === "Female" ? "Male" : "Female", count: 0 });
    }

    /** @type {number} */
    const numberOfParticipantsWithAGender =
        countByGenderArray[0].count + (countByGenderArray.length > 1 ? countByGenderArray[1].count : 0);

    return countByGenderArray.map(e => {
        return {
            name: e.gender,
            count: e.count,
            value: Math.round(e.count / numberOfParticipantsWithAGender * 100),
        };
    });
}

export { GetReportValue, GetAgeArray, GetAverageAgeAsString, GetGenderPercentages, MissingFieldValue, ChartColors };
