import React from "react";
import {
  TripRegistrationWorkflowStepNumbers,
  TripRegistrationWorkflowSteps,
} from "../../../../../TripEventConsts";
import { getRegistrationStatusColor } from "../../../../../TripEventLogic";

const workflowCategories = [
  {
    title: "Applied",
    workflowSteps: [TripRegistrationWorkflowSteps.Applied],
    getDescription: (registration) => (
      <div
        className="workflow-description italic-text"
        style={{ color: getRegistrationStatusColor(registration.status) }}
      >
        Pending Rec. Submission
      </div>
    ),
  },
  {
    title: "Recommendation Submitted",
    workflowSteps: [TripRegistrationWorkflowSteps.RecommendationSubmitted],
    getDescription: (registration) => (
      <div
        className="workflow-description italic-text"
        style={{ color: getRegistrationStatusColor(registration.status) }}
      >
        Pending Rec. Review
      </div>
    ),
  },
  {
    title: "Recommendation Approved",
    workflowSteps: [TripRegistrationWorkflowSteps.RecommendationApproved],
    getDescription: (registration) => (
      <div
        className="workflow-description italic-text"
        style={{ color: getRegistrationStatusColor(registration.status) }}
      >
        Pending Interview
      </div>
    ),
  },
  {
    title: "Interviewed",
    workflowSteps: [TripRegistrationWorkflowSteps.Interviewed],
    getDescription: (registration) => (
      <div
        className="workflow-description italic-text"
        style={{ color: getRegistrationStatusColor(registration.status) }}
      >
        Pending assessment
      </div>
    ),
  },
  {
    title: "Assessed",
    workflowSteps: [
      TripRegistrationWorkflowSteps.PreAccepted,
      TripRegistrationWorkflowSteps.PreRejected,
      TripRegistrationWorkflowSteps.PreWaitlisted,
      TripRegistrationWorkflowSteps.Eligible,
    ],
    getDescription: (registration) => (
      <div className="workflow-description accent-text-dark">
        {registration.workflowStepDisplay}
        {registration.workflowStep ===
          TripRegistrationWorkflowSteps.Eligible && (
          <p className="accent-text italic-text mt-4">
            Not ready for processing
          </p>
        )}
      </div>
    ),
  },
  {
    title: "Processed",
    workflowSteps: [TripRegistrationWorkflowSteps.Processed],
    getDescription: (registration) => (
      <div
        className="workflow-description"
        style={{ color: getRegistrationStatusColor(registration.status) }}
      >
        {registration.statusDisplay}
      </div>
    ),
  },
];

function RegistrationWorkflowSteps(props) {
  const { registration } = props;

  const registrationStepNumber =
    TripRegistrationWorkflowStepNumbers[registration.workflowStep];

  return (
    <div className="trip-student-workflow-stepper relative">
      {workflowCategories.map((wfCategory, index) => {
        const stepNumber =
          TripRegistrationWorkflowStepNumbers[wfCategory.workflowSteps[0]];

        const isCurrent = registrationStepNumber === stepNumber;
        const isCompleted = registration.workflowHistory.some((step) =>
          wfCategory.workflowSteps.includes(step)
        );

        return (
          <div className="workflow-step" key={index}>
            {index > 0 && <div className="stepper-line" />}
            <div
              className={`workflow-number${isCompleted ? " completed" : ""}`}
            >
              {isCompleted ? (
                <i className="material-icons medium-text">check</i>
              ) : (
                index + 1
              )}
            </div>
            <div
              className={`mb-8 ${isCompleted ? "fw-700" : "accent-text-dark"}`}
            >
              {wfCategory.title}
            </div>
            {isCurrent && wfCategory.getDescription(registration)}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(RegistrationWorkflowSteps);
