import React from "react";
import NewRaffleOrderModal from "./NewRaffleOrder/NewRaffleOrderModal";
import RaffleOrdersBulkImportModal from "./RaffleOrdersBulkImportModal";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import StripeProvider from "../../RaffleComponents/StripeProvider";

import { formatExcelNumberString } from "../../../../lib/utils";
import moment from "moment";

export default class RaffleOrderActions extends React.PureComponent {
  state = {
    showBulkOrderUploadModal: false,
    showNewOrderModal: false,
  };

  exportTypes = {
    Orders: "Orders",
    Tickets: "tickets",
  };

  exportHeaders = [
    { label: "Order Number", value: "orderNumber" },
    { label: "Ticket Number", value: "ticketNumber" },
    { label: "Quantity", value: "quantity" },
    { label: "Amount", value: "amount" },
    { label: "Ticket Price Amount", value: "ticketPriceAmount" },
    { label: "Processing Fee Donor Amount", value: "processingFeeDonorAmount" },
    { label: "Currency", value: "currencyCode" },
    { label: "Payment Method", value: "paymentMethod" },
    { label: "Cash Type", value: "cashType" },
    { label: "Source", value: "source" },
    {
      label: "Date",
      value: (order) => moment(order.dateOrderedOn).format("MM/DD/YYYY"),
    },
    {
      label: "Date Order Created",
      value: (order) => moment(order.createdOn).format("MM/DD/YYYY"),
    },
    { label: "First Name", value: "donorFirstName" },
    { label: "Last Name", value: "donorLastName" },
    { label: "Email", value: "donorEmail" },
    { label: "Phone", value: "donorPhone" },
    { label: "Association", value: "associationDisplay" },
    { label: "Billing Address Line 1", value: "billingAddress1" },
    { label: "Billing Address Line 2", value: "billingAddress2" },
    { label: "Billing Address City", value: "billingCity" },
    { label: "Billing Address State", value: "billingState" },
    { label: "Billing Address Country", value: "billingCountry" },
    {
      label: "Billing Address Zip",
      value: (order) => formatExcelNumberString(order.billingZip),
    },
    { label: "Team", value: "referrerName" },
    { label: "Tribute", value: "tribute" },
    {
      label: "CC - Last 4 Digits",
      value: (order) => (order.lastFour ? `*${order.lastFour}` : null),
    },
    { label: "Private", value: (order) => (order.isPrivate ? "*" : null) },
    {
      label: "UK Gift Aid",
      value: (order) => (order.isUkGiftAid ? "*" : null),
    },
    { label: "Chabad House Name", value: "sellerName" },
    { label: "Chabad House ID", value: "chabadHouseID" },
    {
      label: "Refunded",
      value: (order) =>
        !!order.refundAmount
          ? !order.amount
            ? "Fully Refunded"
            : "Partially Refunded"
          : "",
    },
  ];

  getExportHeaders = (exportType) => {
    return exportType === this.exportTypes.Tickets
      ? this.exportHeaders.filter((field) => field.label !== "Refunded")
      : this.exportHeaders.filter((field) => field.value !== "ticketNumber");
  };

  getOrdersForExport = async (exportType) => {
    return await this.props.getOrdersForExport(
      exportType === this.exportTypes.Tickets,
    );
  };

  render() {
    const {
      cociCcProcessingFeePercentage,
      enrollment,
      raffleIsActive,
      raffleIsOpenForManualTicketEntry,
      raffleTitle,
      readOnlyAccess,
      reloadOrders,
      systemCountries,
      systemRaffleDonorAssociations,
    } = this.props;
    const { showBulkOrderUploadModal, showNewOrderModal } = this.state;
    const {
      allowDonorToPayProcessingFee,
      bulkOrderTemplateURL,
      currencyCode,
      id: enrollmentId,
      referrers,
      stripePublicKey,
      ticketPrice,
    } = { ...enrollment };

    return (
      <div className="flex">
        <div className="dropdown-container mr-8">
          <button className="btn btn-accent flex flex-align-center">
            CSV Export
            <i className="material-icons ml-8">arrow_drop_down</i>
          </button>
          <div className="dropdown btn-dropdown csv-export-dropdown">
            <ExportCSVButton
              className="small-text mb-0 link-text-secondary"
              fileName={`${(raffleTitle || "Grand Draw").replace(
                / /g,
                "_",
              )}_Orders`}
              getExportData={() =>
                this.getOrdersForExport(this.exportTypes.Orders)
              }
              getHeaders={() => this.getExportHeaders(this.exportTypes.Orders)}
              title="Export as Orders"
            />
            <ExportCSVButton
              className="small-text mb-0 mt-16 link-text-secondary"
              fileName={`${(raffleTitle || "Grand Draw").replace(
                / /g,
                "_",
              )}_Tickets`}
              getExportData={() =>
                this.getOrdersForExport(this.exportTypes.Tickets)
              }
              getHeaders={() => this.getExportHeaders(this.exportTypes.Tickets)}
              title="Export as Tickets"
            />
          </div>
        </div>

        {!readOnlyAccess &&
          enrollmentId &&
          raffleIsOpenForManualTicketEntry && (
            <React.Fragment>
              <button
                className="btn btn-accent flex flex-align-center"
                onClick={() =>
                  this.setState({ showBulkOrderUploadModal: true })
                }
              >
                Bulk Import
              </button>

              <button
                className="btn btn-accent flex flex-align-center ml-8"
                onClick={() => this.setState({ showNewOrderModal: true })}
              >
                Add Order
              </button>

              <StripeProvider apiKey={stripePublicKey}>
                <NewRaffleOrderModal
                  allowDonorToPayProcessingFee={allowDonorToPayProcessingFee}
                  close={() => this.setState({ showNewOrderModal: false })}
                  cociCcProcessingFeePercentage={cociCcProcessingFeePercentage}
                  currencyCode={currencyCode}
                  enrollmentId={enrollmentId}
                  raffleIsActive={raffleIsActive}
                  reloadOrders={reloadOrders}
                  referrers={referrers}
                  show={showNewOrderModal}
                  systemCountries={systemCountries}
                  systemRaffleDonorAssociations={systemRaffleDonorAssociations}
                  ticketPrice={ticketPrice}
                />
              </StripeProvider>

              <RaffleOrdersBulkImportModal
                bulkOrderTemplateURL={bulkOrderTemplateURL}
                close={() => this.setState({ showBulkOrderUploadModal: false })}
                enrollmentId={enrollmentId}
                reloadOrders={reloadOrders}
                show={showBulkOrderUploadModal}
              />
            </React.Fragment>
          )}
      </div>
    );
  }
}
