import { asEnum } from "../../";
// import { Contact } from "./Contact";
import { /* Address, */ EmptyAddress } from "./Common";

export const CategoryTypes = asEnum({
  Partner: 1,
  Vendor: 2,
  Venue: 3,
});

// export interface OrganizationCategory {
//     id?: number;
//     name: string;
//     type: CategoryTypes;
// }

// export interface Organization {
//     id?: number;
//     name: string;
//     description?: string;
//     category: OrganizationCategory;
//     organizationCategoryType?: CategoryTypes;
//     address?: Address;
//     website?: string;
//     roomSpace?: number;
//     meetingSpace?: number;
//     notes?: string;
//     contacts: Array<Contact>;
// }

// export interface OrganizationFilter {
//     name?: string;
//     organizationCategoryType?: CategoryTypes;
//     categoryId?: number;
//     countries: string;
//     states: string;
// }
/** @returns {OrganizationFilter} */
export function EmptyOrganizationFilter() {
  return { name: "", organizationCategoryType: 0, countries: "", states: "" };
}
/** @returns {OrganizationCategory} */
export function EmptyOrganizationCategory() {
  return { name: "", type: CategoryTypes.Vendor, id: -1 };
}
/** @returns {Organization} */
export function EmptyOrganization() {
  return {
    name: "",
    category: EmptyOrganizationCategory(),
    contacts: [],
    address: EmptyAddress(),
    id: -1,
  };
}
