import React from "react";
import { Link } from "react-router";
import Check from "@material-ui/icons/Check";
import { formatFullName } from "../../../../lib/utils";

function CheckInTableRecord(props) {
  const {
    readOnlyAccess,
    selected = "",
    scheduleId,
    attendee: {
      campusName,
      id,
      shliachFirstName,
      shliachLastName,
      firstName,
      lastName,
      status,
      attendeeType,
      enrollmentID,
    },
    toggleSelectAttendee,
  } = props;

  const attendeeLink =
    attendeeType === "Student"
      ? `/trips/${scheduleId}/students/${id}`
      : `/trips/${scheduleId}/enrollments/${enrollmentID}`;

  const disabled = status === "CheckedIn";

  return (
    <div
      className={`checkin-table-columns trip-students-table-row${readOnlyAccess ? "read-only" : ""
        }
        ${disabled ? " disabled" : ""}`}
    >
      {!readOnlyAccess && (
        <div className="flex custom-checkbox-container">
          {disabled ? (
            <Check />
          ) : (
            <input
              className="custom-checkbox"
              checked={selected}
              id={`${id}-${attendeeType}`}
              onChange={toggleSelectAttendee}
              type="checkbox"
            />
          )}
          <label className="flex-align-center" htmlFor={`${id}-${attendeeType}`} />
        </div>
      )}
      <p>
        <Link className="link-text-secondary" to={attendeeLink} target="_blank">
          {formatFullName(firstName, lastName)}
        </Link>
      </p>
      <div>
        <p>{campusName}</p>
        <p className="fw-400 accent-text mb-4">
          {formatFullName(shliachFirstName, shliachLastName)}
        </p>
      </div>
      <p>{attendeeType}</p>
      <p>{status}</p>
    </div>
  );
}
export default React.memo(CheckInTableRecord);
