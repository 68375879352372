const getHistory = () => {
  return JSON.parse(localStorage.getItem("userHistory") || "{}");
};

const setHistory = (history) => {
  localStorage.setItem("userHistory", JSON.stringify(history));
};

const getRecentlyViewedItems = (types) => {
  // console.log(types);
  const userHistory = getHistory();
  // console.log(userHistory);
  const historyItems = Object.keys(userHistory).map((key) => userHistory[key]);
  const historyItemsOfType = historyItems.filter(
    (h) => types.indexOf(h.type) > -1,
  );

  return historyItemsOfType.sort((a, b) => b.time - a.time);
};

const viewHistory = {
  add: (type, data) => {
    const historyItemPath = window.location.pathname;
    if (historyItemPath !== "/") {
      const userHistory = getHistory();

      userHistory[historyItemPath] = {
        time: Date.now(),
        type,
        data,
      };

      setHistory(userHistory);
    }
  },
  recentlyViewedProfiles: () => {
    return getRecentlyViewedItems(["School", "Shliach"]);
  },
  recentlyViewedPrograms: () => {
    return getRecentlyViewedItems(["Program"]);
  },
};

export default viewHistory;
