import React from "react";
import {
  StyledGroupContainer,
  StyledGroupButton,
} from "../StyledComponents/StyledGroupContainer";

// export interface GroupContainerProps {
//   headerText: string;
//   iconClassName: string;
//   handleRemove?: React.FormEventHandler<HTMLButtonElement>;
// }

export const GroupContainer = ({
  headerText,
  iconClassName,
  handleRemove,
  children,
}) => {
  return (
    <div>
      <StyledGroupContainer>
        <i className={"icon " + iconClassName}>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
          <span className="path6" />
          <span className="path7" />
          <span className="path8" />
          <span className="path9" />
          <span className="path10" />
          <span className="path11" />
          <span className="path12" />
          <span className="path13" />
          <span className="path14" />
          <span className="path15" />
          <span className="path16" />
          <span className="path17" />
          <span className="path18" />
          <span className="path19" />
          <span className="path20" />
        </i>
        <span>{headerText}</span>
        {handleRemove && (
          <StyledGroupButton key="remove" onClick={handleRemove}>
            <i className="glyphicon glyphicon-trash" />
          </StyledGroupButton>
        )}
      </StyledGroupContainer>
      {children}
    </div>
  );
};
