import React from "react";
import { StyledSelect } from "../../lib/coc-common-components/StyledComponents/StyledSelect";

// interface LamplightersSelectionProps {
//   sessions: Array<any>;
//   selectedSessionId: Number;
//   handleSessionChange: (str: string) => void;
// }

const LamplightersSelection = ({
  sessions,
  selectedSessionId,
  handleSessionChange,
}) => {
  const displayableSessions = [...sessions];

  return (
    <StyledSelect>
      <select
        value={selectedSessionId.toString() || ""}
        onChange={(e) => handleSessionChange(e.currentTarget.value)}
      >
        {displayableSessions.map((year) => (
          <option key={year} value={year}>
            {"Year " + year.toString()}
          </option>
        ))}
      </select>
    </StyledSelect>
  );
};

export default LamplightersSelection;
