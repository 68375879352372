import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import TripEventsApi from "../../../../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../../../../lib/coc-common-scripts";
import { formatCurrency } from "../../../../../../../lib";
import { notify } from "react-notify-toast";
import axios from "axios";

function RegistrationDepositAndFeeRefunds(props) {
  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const {
    onRegistrationUpdated,
    registration: {
      depositAmount,
      id: registrationId,
      total,
      tripFee,
      wasPaymentProcessed,
      wasSurveySubmitted,
    },
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [depositRefundAmount, setDepositRefundAmount] = useState("");
  const [feeRefundAmount, setFeeRefundAmount] = useState("");

  const [depositRefundSelected, setDepositRefundSelected] = useState(false);
  const [feeRefundSelected, setFeeRefundSelected] = useState(false);

  const [showRefundModal, setShowRefundModal] = useState(false);

  const onConfirmRefund = useCallback(async () => {
    let validationMessage = "";

    if (!depositRefundAmount && !feeRefundAmount) {
      validationMessage = "Please enter a refund amount.";
    } else if (depositRefundSelected && !depositRefundAmount) {
      validationMessage =
        "Please enter a deposit refund amount, or uncheck the deposit refund option.";
    } else if (feeRefundSelected && !feeRefundAmount) {
      validationMessage =
        "Please enter a trip fee refund amount, or uncheck the trip fee refund option.";
    }

    if (validationMessage) {
      setErrorMessage(validationMessage);
      return;
    }

    setErrorMessage("");
    setLoading(true);

    try {
      const updatedRegistration = await TripEventsApi.issuePartialRefund(
        apiSignalRef.current.token,
        registrationId,
        {
          cociRefundAmount: feeRefundAmount || 0,
          depositRefundAmount: depositRefundAmount || 0,
        }
      );

      onRegistrationUpdated(updatedRegistration);

      setShowRefundModal(false);

      setFeeRefundSelected(false);
      setDepositRefundSelected(false);
      setDepositRefundAmount("");
      setFeeRefundAmount("");

      const successMessage = `Your refund of $${formatCurrency(
        depositRefundAmount || 0 + feeRefundAmount || 0
      )} has been issued`;
      notify.show(successMessage, "success");
    } catch (e) {
      setErrorMessage(ApiCallErrorMessageHandler(e));
    }

    setLoading(false);
  }, [
    depositRefundAmount,
    depositRefundSelected,
    feeRefundAmount,
    feeRefundSelected,
    onRegistrationUpdated,
    registrationId,
  ]);

  const onCancelRefund = useCallback(() => {
    setErrorMessage("");
    setFeeRefundSelected(false);
    setDepositRefundSelected(false);
    setDepositRefundAmount("");
    setFeeRefundAmount("");
    setShowRefundModal(false);
  }, []);

  const disabled = !(wasPaymentProcessed && total > 0);

  return (
    <>
      <p className="fw-700 mb-8">Deposits & Other Refunds</p>
      <p className="mb-16">
        • Refund the deposit post-trip, in full or partially.
        <br />• Refund any amount of the trip fee without cancelling the
        registration.
      </p>
      <div className="tooltip-container">
        <button
          className="btn custom-btn btn-accent btn-medium uppercase-text"
          disabled={disabled}
          onClick={() => setShowRefundModal(true)}
        >
          Issue Refund
        </button>
        {disabled && (
          <span className="tooltip">
            {!wasPaymentProcessed
              ? "Payment was not processed"
              : "Total paid is $0"}
          </span>
        )}
      </div>
      <Modal isOpen={showRefundModal} className="modal-container">
        <div className="card small-modal trip-refund-modal">
          <div className="mb-24">
            <div className="large-text mb-4">Issue a refund</div>
            <div className="accent-text">
              Trip Fee ${tripFee} | Deposit ${depositAmount}
            </div>
          </div>
          <div className="mb-16">
            Select all the payments you would like to refund. Leave blank and
            unchecked if you do not want to refund any of the funds from that
            type.
          </div>
          <div className="tooltip-container flex mb-16">
            <FormControlLabel
              className="trip-checkbox"
              label={
                <>
                  Deposit{" "}
                  <span className="accent-text">(max ${depositAmount})</span>
                </>
              }
              control={
                <Checkbox
                  checked={depositRefundSelected}
                  disabled={!depositAmount || !wasSurveySubmitted}
                  name="depositRefundSelected"
                  onChange={() => {
                    setDepositRefundSelected(!depositRefundSelected);
                    if (depositRefundSelected) {
                      setDepositRefundAmount("");
                    }
                  }}
                />
              }
            />
            <NumberFormat
              allowNegative={false}
              className="custom-input dollar-input"
              decimalScale={2}
              disabled={
                !depositRefundSelected || !depositAmount || !wasSurveySubmitted
              }
              isAllowed={({ floatValue }) => !(floatValue > depositAmount)}
              name="depositRefundAmount"
              onValueChange={({ floatValue }) =>
                setDepositRefundAmount(floatValue || "")
              }
              thousandSeparator={true}
              value={depositRefundAmount}
            />
            {!depositAmount ? (
              <span className="tooltip">Amount paid for the deposit is $0</span>
            ) : !wasSurveySubmitted ? (
              <span className="tooltip">
                Cannot refund deposit because survey is not marked as submitted
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="tooltip-container flex mb-24">
            <FormControlLabel
              className="trip-checkbox"
              label={
                <>
                  Trip Fee <span className="accent-text">(max ${tripFee})</span>
                </>
              }
              control={
                <Checkbox
                  checked={feeRefundSelected}
                  disabled={!tripFee}
                  name="feeRefundSelected"
                  onChange={() => {
                    setFeeRefundSelected(!feeRefundSelected);
                    if (feeRefundSelected) {
                      setFeeRefundAmount("");
                    }
                  }}
                />
              }
            />
            <NumberFormat
              allowNegative={false}
              className="custom-input dollar-input"
              decimalScale={2}
              disabled={!feeRefundSelected || !tripFee}
              isAllowed={({ floatValue }) => !(floatValue > tripFee)}
              name="feeRefundAmount"
              onValueChange={({ floatValue }) =>
                setFeeRefundAmount(floatValue || "")
              }
              thousandSeparator={true}
              value={feeRefundAmount}
            />
            {!tripFee && (
              <span className="tooltip">
                Amount paid for the trip fee is $0
              </span>
            )}
          </div>
          <div className="flex">
            <i className="material-icons large-text trip-info-icon mr-4">
              info
            </i>{" "}
            {depositRefundAmount
              ? "The deposit will be marked as refunded and the"
              : "The"}{" "}
            student will be notified via email.
          </div>
          <div className="modal-btns">
            <button
              className="link-text uppercase-text"
              disabled={loading}
              onClick={onCancelRefund}
            >
              Cancel
            </button>
            <button
              className={`link-text uppercase-text ml-24${
                loading ? " disabled" : ""
              }`}
              onClick={onConfirmRefund}
            >
              {loading ? "Issuing Refund..." : "Issue Refund"}
            </button>
          </div>
          {!loading && errorMessage && (
            <div className="text-right error-text mt-4">{errorMessage}</div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default React.memo(RegistrationDepositAndFeeRefunds);
