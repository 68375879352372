import React from "react";
import { browserHistory, Link } from "react-router";
import Loader from "../../../common/Loader";
import ProgramBreadcrumbsHeader from "../../../common/ProgramBreadcrumbsHeader";
import ProgramSchedulesCard from "./registrations/ProgramSchedulesCard";
import RegistrationsTable from "./registrations/RegistrationsTable";
import RegistrationsShluchimTable from "./registrations/RegistrationsShluchimTable";
import { Unauthorized } from "../../../../lib/coc-common-components";

import ProgramsApi from "../../../../services/resources/ProgramsApi";
// import SystemApi from "../../../../services/resources/SystemApi";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import queryString from "query-string";

export default class ProgramScheduleRegistrationsPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,

      configureApplicationSettingsError: false,

      errorMessage: "",
      loading: true, //for programSchedulesList loading

      programSchedule: null,
      programSchedulesList: [],

      // systemLists: {
      //   appliationStatuses: []
      // },
      // systemListsErrorMessage: "",

      tabIndex: 0,
      tabs: [{ name: "Shluchim", id: "shliach" }],
    };
    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ProgramRead)) {
      this.setState({ authorized: false });
    }

    const {
      params: { scheduleId },
    } = this.props;

    this.getProgramSchedule(scheduleId, true);
    // this.getSystemLists();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { programId, scheduleId },
      location: {
        query: { tab },
      },
    } = this.props;

    //handle program param update (via selection from sidebar menu) - reset state and fetch schedule with schedules list and all details from scratch
    if (programId !== prevProps.params.programId) {
      this.setState(this.state.initialState, () =>
        this.getProgramSchedule(scheduleId, true)
      );
    }
    //handle schedule param update (via selection from sidebar menu OR selection in ProgramsScheduleCard widget)
    else if (scheduleId !== prevProps.params.scheduleId) {
      this.getProgramSchedule(
        scheduleId,
        !this.state.programSchedulesList.length //fetch schedules only if the schedules list is empty (for whatever reason - possibly an error)
      );
    }
    //handle tab update via router link
    else if (tab !== prevProps.location.query.tab) {
      const { tabs, tabIndex } = this.state;
      const index = tabs.findIndex((t) => t.id === tab);
      if (index >= 0 && index !== tabIndex) {
        this.setState({ tabIndex: index });
      }
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getProgramSchedule = async (scheduleId, fetchSchedulesList = false) => {
    try {
      this.setState({ errorMessage: "" });

      //update route imed (so that schedules dropdown is updated)
      const {
        location: {
          query: { tab },
          search,
        },
        params: { programId, scheduleId: prevScheduleId },
      } = this.props;
      if (scheduleId && scheduleId.toString() !== prevScheduleId) {
        const queryUpdate = tab ? `?tab=${tab}` : ""; //clear out filters from search query on schedule change
        browserHistory.replace(
          `/programs/${programId}/schedules/${scheduleId}/registrations${queryUpdate}`
        );
      }

      let programSchedulesList = [...this.state.programSchedulesList];
      if (fetchSchedulesList) {
        this.setState({ loading: true });

        const {
          params: { programId },
        } = this.props;
        programSchedulesList = await ProgramsApi.getBasicProgramSchedules(
          this.apiSignal.token,
          programId
        );
        if (programSchedulesList.length) {
          this.setState({ programSchedulesList });
        }
      }

      if (!programSchedulesList.length) {
        this.setState({
          errorMessage: "Sorry, no schedules can be found for this program.",
          loading: false,
        });
        return;
      }

      //when no program sched is specified default to retrieve first program schedule and update url
      if (!scheduleId) {
        scheduleId = programSchedulesList[0].scheduleID;
        browserHistory.replace(
          `/programs/${programId}/schedules/${scheduleId}${search || ""}`
        );
      }

      const programSchedule = programSchedulesList.find(
        (ps) => ps.scheduleID.toString() === scheduleId.toString()
      );
      if (!programSchedule) {
        this.setState({
          errorMessage: "Sorry, this program schedule cannot be found.",
          loading: false,
        });
        return;
      } else {
        this.setState({
          programSchedule,
        });
      }

      //retrieve schedule application settings if has application process
      let configureApplicationSettingsError = false;
      if (programSchedule.hasApplicationProcess) {
        try {
          programSchedule.applicationSettings =
            await ProgramsApi.getScheduleApplicationSettings(
              this.apiSignal.token,
              programSchedule.programID,
              programSchedule.scheduleID
            );
        } catch (err) {
          if (err.response.status === 404) {
            configureApplicationSettingsError = true;
          } else {
            throw err;
          }
        }
      }

      const tabs = [...this.state.tabs];
      if (
        programSchedule.registrationType === "Student" &&
        !tabs.find((s) => s.id === "student")
      ) {
        tabs.unshift({ name: "Students", id: "student" });
      }
      const tabIndex = tab ? tabs.map((tab) => tab.id).indexOf(tab) : 0;

      this.setState({
        configureApplicationSettingsError,
        loading: false,
        programSchedule,
        tabs,
        tabIndex,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve program schedule details. Please try again."
          ),
          loading: false,
        });
      }
    }
  };

  // getSystemLists = async () => {
  //   try {
  //     const systemLists = await SystemApi.lists(["applicationStatuses"]);
  //     this.setState({ systemLists });
  //   } catch (err) {
  //     if (!axios.isCancel(err)) {
  //       this.setState({
  //         systemListsErrorMessage: ApiCallErrorMessageHandler(err)
  //       });
  //     }
  //   }
  // };

  toTab = (index, preserveQuery) => {
    const {
      location: { pathname, query },
    } = this.props;
    const { tabIndex, tabs } = this.state;

    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      const queryParams = {
        ...(preserveQuery ? query : {}),
        tab: tabs[index].id,
      };
      browserHistory.replace(
        `${pathname}?${queryString.stringify(queryParams)}`
      );
    }
  };

  render() {
    const {
      location,
      mobileMode,
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      configureApplicationSettingsError,
      errorMessage,
      loading,
      programSchedule,
      programSchedulesList,
      systemLists,
      tabIndex,
      tabs,
    } = this.state;
    const tab = tabs[tabIndex] || tabs[0];

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="program-registrations-page page container">
        <ProgramBreadcrumbsHeader
          pageTitle="Registrations"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          !!programSchedule && (
            <React.Fragment>
              <ProgramSchedulesCard
                onChangeProgramSchedule={(schedId) =>
                  this.getProgramSchedule(schedId, false)
                }
                programSchedule={programSchedule}
                programSchedulesList={programSchedulesList}
                scheduleId={scheduleId}
              />
              {!programSchedule.registrationType ? (
                <div className="text-center mt-32">
                  <p>
                    A Registration Type has not been configured for this
                    program.
                    <br />
                    Choose a program registration type in order to view and
                    manage schedule registrations.
                  </p>
                  <Link
                    className="btn btn-info mt-16"
                    to={`/programs/${programSchedule.programID}?sched=${scheduleId}`}
                  >
                    Set Registration Type
                  </Link>
                </div>
              ) : configureApplicationSettingsError ? (
                <div className="text-center mt-32">
                  <p>
                    Application Settings have not been configured for this
                    program schedule
                  </p>
                  <Link
                    className="btn btn-info mt-16"
                    to={`/programs/${programSchedule.programID}/schedules/${scheduleId}/applicationSettings`}
                  >
                    Configure Application Settings
                  </Link>
                </div>
              ) : (
                <React.Fragment>
                  {tabs.length > 1 && (
                    <div
                      className="program-schedule-details-tabs flex"
                      style={{ marginLeft: "2px" }}
                    >
                      {tabs.map((t, index) => (
                        <p
                          key={index}
                          className={`large-text ${index > 0 ? "ml-40" : ""} ${
                            tabIndex === index ? "active" : ""
                          }`}
                          onClick={() => this.toTab(index)}
                        >
                          {t.name}
                        </p>
                      ))}
                    </div>
                  )}
                  <div className="card program-schedule-card">
                    {React.createElement(
                      programSchedule.registrationType === "Student" &&
                        tab.id === "shliach"
                        ? RegistrationsShluchimTable // on 'shliach' tab for student registration program, render Registrations Shluchim 'derived' table
                        : RegistrationsTable,
                      {
                        key: `${scheduleId}-${tab.id}`,
                        location,
                        mobileMode,
                        programSchedule,
                        systemLists,
                        title: tab.name,
                      }
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
