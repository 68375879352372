import React from "react";
import get from "lodash.get";

import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export default class PhoneInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      isValid: "",
    };
    this._phoneInput = React.createRef();
  }

  componentDidMount() {
    this._ismounted = true;
    if (!this.props.countryId) {
      const country = this.getDefaultCountry();
      this.props.setFieldValue(
        this.props.countryFieldName,
        this.getCountryIdByCode(country)
      );
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentDidUpdate(prevProps) {
    let value = this.props.value;
    if (prevProps.value !== value) {
      this.setState({ value });
    }

    //formik creates a behavior, where when a collection (in account pages this would be of contact methods) is added to, the components are 'recycled' and 'bumped down' - they get the data of the component previously above them (and a new one is added at the bottom)
    //when this happens the phoneInput gets a new countryId (of the phone # previously above it) but bec of react-intl-tel-input limitation here https://github.com/patw0929/react-intl-tel-input/issues/233 the phone country flag selection does not get updated in the UI
    //we t/f created a ref to the phoneInput and manually call setFlag in this case here (as per the response on the above-mentioned issue) to update the flag appropriately.
    if (prevProps.countryId !== this.props.countryId && this._phoneInput) {
      this._phoneInput.setFlag(this.getDefaultCountry());
    }
  }

  validPhoneInput = (status, value, countryData, number, id) => {
    const name = this.props.name;
    this.props.touched[name] = true;
    let inputContainers = document.getElementsByClassName("phone-input");
    for (let i = 0; i < inputContainers.length; i++) {
      if (
        inputContainers[i]
          .getElementsByTagName("INPUT")[0]
          .getAttribute("value") === this.state.value
      ) {
        let input = inputContainers[i].getElementsByTagName("INPUT")[0];
        const setValidationAttribute =
          input.getAttribute("value") === this.state.value;
        //we are assuming here that phone number is not required
        if (setValidationAttribute) {
          if (this.state.value && !status) {
            input.setAttribute("invalid", true);
            this.setState({ isValid: false });
          } else if (!this.state.value || status) {
            input.removeAttribute("invalid");
            this.setState({ isValid: true });
          }
        }
      }
    }
  };

  checkNumber = (status, val, countryData) => {
    const re = /^[0-9 -]*$/;
    if (re.test(val.slice(-1))) {
      this.setState({ value: val });
    }
    this.props.setFieldValue(
      this.props.name,
      val.replace("(", "").replace(") ", "-")
    );
    let inputContainers = document.getElementsByClassName("phone-input");
    for (let i = 0; i < inputContainers.length; i++) {
      if (
        inputContainers[i]
          .getElementsByTagName("INPUT")[0]
          .getAttribute("value") === this.state.value
      ) {
        let input = inputContainers[i].getElementsByTagName("INPUT")[0];
        const setValidationAttribute =
          input.getAttribute("value") === this.state.value;
        //we are assuming here that phone number is not required
        if (setValidationAttribute) {
          if (this.state.value && !status) {
            input.setAttribute("invalid", true);
          } else if (!this.state.value || status) {
            input.removeAttribute("invalid");
          }
        }
      }
    }
  };

  updateCountry = (country) => {
    this.props.setFieldValue(
      this.props.countryFieldName,
      country && country.iso2 && this.getCountryIdByCode(country.iso2)
    );
  };

  usCountryCode = "us";

  getDefaultCountry = () => {
    const { countryId, defaultCountryName } = this.props;
    return countryId
      ? this.getCountryCodeById(countryId)
      : defaultCountryName
      ? this.getCountryCodeByName(defaultCountryName)
      : this.usCountryCode;
  };

  getCountryCodes = () => this.props.countries.map((c) => c.code.toLowerCase());

  getCountryIdByCode = (countryCode) => {
    const country = this.props.countries.find(
      (c) => c.code.toLowerCase() === countryCode.toLowerCase()
    );
    return country ? country.id : null;
  };

  getCountryCodeById = (countryId) => {
    const country = this.props.countries.find((c) => c.id === countryId);
    return country ? country.code.toLowerCase() : null;
  };

  getCountryCodeByName = (countryName) => {
    if (countryName === "Scotland") countryName = "United Kingdom"; //note: no country support for scotland, use UK instead
    const country = this.props.countries.find((c) => c.name === countryName);
    return country ? country.code.toLowerCase() : null;
  };

  render() {
    const { countryFieldName, errors, id, name, touched, readOnly } =
      this.props;
    const error = get(errors, "invalidPhoneNumber") && get(touched, name);
    let { isValid, value } = this.state;

    return (
      <div
        className={`phone-input ${error && !isValid ? "error" : ""} ${
          readOnly ? "read-only" : ""
        }`}
        ref="phoneInputContainer"
      >
        <IntlTelInput
          ref={(phRef) => (this._phoneInput = phRef)}
          css={["intl-tel-input"]}
          disabled={readOnly}
          fieldName={name}
          fieldId={id}
          utilsScript={this._ismounted ? "libphonenumber.js" : ""}
          format={true}
          formatOnInit={false} //note: setting this to false to ensure that phone num is not formatted on init (where format changes would lead to invoking onPhoneNumberChange and the subsequent updates) causing there to be unsaved changes within the containing form
          onPhoneNumberBlur={this.validPhoneInput}
          onPhoneNumberChange={(s, val, data) => this.checkNumber(s, val, data)}
          separateDialCode={true}
          customPlaceholder={(selectedCountryPlaceholder) => {
            return selectedCountryPlaceholder.replace(
              new RegExp("[0-9]", "g"),
              "0"
            );
          }}
          value={this.state.value}
          //note: this is how this lib allows 'setting' country.  once the component is initialized with a default country, country can only be changed by user input
          //(or by calling setFlag on ref to this input as documented in this issue)
          //see https://github.com/patw0929/react-intl-tel-input/issues/233
          defaultCountry={this.getDefaultCountry()}
          onlyCountries={this.getCountryCodes()}
          onSelectFlag={
            countryFieldName && ((s, value) => this.updateCountry(value))
          }
          preferredCountries={[this.usCountryCode]}
          useMobileFullscreenDropdown={false}
        />
        {error && value !== "" && !isValid ? (
          <span className="error-message">Invalid Phone Number</span>
        ) : null}
      </div>
    );
  }
}
