import React from "react";
import { pluralizeText } from "../../../../lib";

export default function ({ titleText, studentCount, chapsCount, busSize }) {
  const busCount = Math.ceil((studentCount + chapsCount) / busSize);
  return (
    <div className="mt-16">
      <p className="fw-700">Transportation {titleText}</p>
      {studentCount > 0 ? (
        <>
          <p className="accent-text-dark mb-0">
            {studentCount} {pluralizeText("Student", studentCount)} +{" "}
            {chapsCount} {pluralizeText("Chaperone", chapsCount)}
            /Shluchim ={" "}
            <span className="fw-700">{studentCount + chapsCount}</span>
          </p>

          {busSize != null ? (
            <p className="accent-text-dark mb-0">
              {studentCount + chapsCount} Attendees ÷ {busSize} seats ={" "}
              <span className="fw-700">
                {busCount || 0} {pluralizeText("Bus", busCount, "Busses")}
              </span>
            </p>
          ) : (
            <p className="accent-text-dark italic-text mb-0">
              Bus size not set.
            </p>
          )}
        </>
      ) : (
        <p className="accent-text-dark italic-text mb-0">
          There are no students registered.
        </p>
      )}
    </div>
  );
}
