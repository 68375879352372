import React from "react";
import { browserHistory } from "react-router";
import { formatNumber, getCurrencySymbol } from "../../../../../../lib";
import moment from "moment";

export default class StudentRewardsTableRow extends React.PureComponent {
  render() {
    const { onApproveRejectRewardRequest, rewardRequest, viewOnly } =
      this.props;
    const {
      createdOn,
      creditsToRedeem,
      currencyCode,
      creditValue,
      rewardName,
      status,
      statusDisplay,
      tripProgramScheduleID,
      tripStudentRegistrationID,
    } = rewardRequest;

    return (
      <div className="students-rewards-history-table-row">
        <p>{rewardName}</p>
        <div>
          <p className="fw-700">{formatNumber(creditsToRedeem)}</p>
          <p>
            {getCurrencySymbol(currencyCode)}
            {formatNumber(creditValue * creditsToRedeem)}
          </p>
        </div>
        <p>{moment(createdOn).format("MM/DD/YYYY")}</p>
        <p>{statusDisplay}</p>
        <p>
          {!viewOnly &&
            status === "Pending" &&
            (tripStudentRegistrationID ? (
              <span
                className="link-text"
                onClick={() =>
                  browserHistory.push(
                    `/trips/${tripProgramScheduleID}/students/${tripStudentRegistrationID}`
                  )
                }
              >
                Manage trip application
              </span>
            ) : (
              <>
                <i
                  className="material-icons ml-8 link-text-secondary"
                  onClick={() =>
                    onApproveRejectRewardRequest(rewardRequest, true)
                  }
                  style={{ color: "#76E269" }}
                >
                  thumb_up
                </i>
                <i
                  className="material-icons ml-8 link-text-secondary"
                  onClick={() =>
                    onApproveRejectRewardRequest(rewardRequest, false)
                  }
                  style={{ color: "#DC313F" }}
                >
                  thumb_down
                </i>
              </>
            ))}
        </p>
      </div>
    );
  }
}
