import React from "react";
import { Link } from "react-router";

// import { Country, State } from "../../../models/Common";
import {
  /* Organization, */ EmptyOrganization /* , OrganizationCategory */,
} from "../../../models/Organization";
import RelatedContactList from "../../common/RelatedContact/RelatedContactList";
// import { Contact } from "../../../models/Contact";
import OrganizationManager from "./OrganizationManager";

// interface OrganizationManagerProps {
//   organizationData: Organization;
//   organizationCategories: Array<OrganizationCategory>;
//   countries: Array<Country>;
//   states: Array<State>;
//   handleOrganizationChanged: (newAddressState: Organization) => void;
//   handleContactChange: (newContact: Contact) => void;
//   handleContactSelected: (id: number) => void;
//   handleContactAdded: () => void;
//   handleContactRemoved: (id: number) => void;
//   handleSave: (organizationData: Organization) => void;
//   handleDelete: (organizationData: Organization) => void;
// }

const OrganizationManagerPage = ({
  organizationData = EmptyOrganization(),
  organizationCategories = [],
  countries = [],
  states = [],
  handleOrganizationChanged,
  handleContactChange,
  handleContactAdded,
  handleContactRemoved,
  handleContactSelected,
  handleSave,
  handleDelete,
}) => {
  return (
    <div className="col-sm-12">
      <ol className="breadcrumb">
        <li>
          <Link to="/organizations">Organizations</Link>
        </li>
        <li>{organizationData.name || "[new organization]"}</li>
      </ol>
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          handleSave(organizationData);
        }}
      >
        <div className="well well-lg">
          <div className="row">
            <div className="col-xs-12">
              <OrganizationManager
                organizationData={organizationData}
                countries={countries}
                states={states}
                organizationCategories={organizationCategories}
                handleOrganizationChanged={handleOrganizationChanged}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <RelatedContactList
                contacts={organizationData.contacts}
                handleContactSelected={handleContactSelected}
                handleContactChange={handleContactChange}
                handleContactAdded={handleContactAdded}
                handleContactRemoved={handleContactRemoved}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-9" />
            <div className="col-sm-3">
              <button type="submit" className="btn btn-primary btn-sm">
                Save
              </button>{" "}
              {organizationData.id && organizationData.id > 0 && (
                <span
                  className="btn btn-primary btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(organizationData);
                  }}
                >
                  Delete
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrganizationManagerPage;
