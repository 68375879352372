import React from "react";
import { Link } from "react-router";
import { EduProgramTypes } from "../Edu/EduConsts";
import { formatFullName, formatNumber, pluralizeText } from "../../lib";
import moment from "moment";

export default class StudentActivityTableRow extends React.PureComponent {
  getCategoryRating = (rating) => (
    <span className="interaction-category-rating ml-8">
      {Array(5)
        .fill()
        .map((_, i) => (
          <img
            alt=""
            className="mr-4"
            key={i}
            src={`/img/flame-${rating > i ? "solid" : "fade"}.png`}
          />
        ))}
    </span>
  );

  getProgramActivityUrl = () => {
    const {
      activity: {
        adminBaseUrl,
        programID,
        programRegistrationID,
        programScheduleID,
        programType,
        studentID,
      },
      studentName,
    } = this.props;

    if (adminBaseUrl) {
      let programBaseUrl = `${adminBaseUrl}/${programScheduleID}`;
      //trips: link to / registration id
      if (programType === "Trip") {
        return `${programBaseUrl}/students/${programRegistrationID}`;
      }
      //lamplighters: link to students tab with student name keyword search
      else if (programType === "Lamplighters") {
        return `${programBaseUrl}?tab=students&keyword=${encodeURIComponent(
          studentName,
        )}`;
      }
      //edu: link to / student id with registration id in the 'tab' query param
      else if (EduProgramTypes[programType]) {
        return `${programBaseUrl}/students/${studentID}?tab=${programRegistrationID}`;
      }
    }

    if (programID && programScheduleID) {
      return `/programs/${programID}/schedules/${programScheduleID}/registrations/${programRegistrationID}`;
    }

    return "";
  };

  render() {
    const { activity, studentId, isLamplightersEligibleInteraction } =
      this.props;
    const {
      activityTypeDisplay,
      categoryRating,
      classification,
      dateTime,
      educators,
      interactionID,
      numberOfStudents,
      programName,
      programScheduleName,
      programType,
      students,
      studentHachlata,
    } = activity;
    //because this table pulls student activity list both by student and by shliach, the activity object formats are different, so we configure the following specific properties:
    const interactionName = studentId
      ? activity.interactionName
      : activity.name;
    const interactionType = studentId
      ? activity.interactionType
      : activity.type;
    const isProgramActivity = !!studentId && !interactionID;
    const programActivityDescription = isProgramActivity
      ? `${activityTypeDisplay} ${programScheduleName || programName}`
      : "";

    return (
      <div className="student-activity-table-row card">
        <div className="flex flex-align-center full-width">
          <div>
            {programType && programType !== "Rsvp" ? (
              <img
                alt=""
                src={`/img/${programType.toLowerCase()}-logo.jpg`}
                style={{ width: "24px", height: "40px", objectFit: "contain" }}
              />
            ) : (
              <i
                className="material-icons"
                style={{ opacity: "24%", fontSize: "24px", width: "24px" }}
              >
                groups
              </i>
            )}
          </div>
          <div className="ml-16">
            {isProgramActivity ? (
              <Link
                className="medium-text link-text-secondary fw-700"
                to={this.getProgramActivityUrl()}
              >
                {programActivityDescription}
              </Link>
            ) : (
              <div className="medium-text fw-700">
                {[interactionName, interactionType]
                  .filter((n) => !!n)
                  .join(" - ")}
              </div>
            )}
            {isLamplightersEligibleInteraction &&
              isLamplightersEligibleInteraction(activity) && (
                <div className="small-text flex flex-align-center">
                  <i
                    className="material-icons small-text mr-4"
                    style={{ color: "#FEC609" }}
                  >
                    check_circle
                  </i>
                  <span className="accent-text italic-text">
                    Lamplighters Eligible
                  </span>
                </div>
              )}
            {studentId ? (
              !!studentHachlata && (
                <p className="small-text flex flex-align-center italic-text mt-4">
                  <span className="fw-700 mr-4">Hachlata:</span>
                  {studentHachlata}
                </p>
              )
            ) : (
              <React.Fragment>
                {educators && (
                  <div className="small-text flex flex-align-center italic-text mt-4">
                    <span className="fw-700 mr-4">
                      {pluralizeText("Educator", educators.length)}:
                    </span>
                    {educators
                      .map((e) => formatFullName(e.firstName, e.lastName))
                      .join(", ")}
                  </div>
                )}
                {numberOfStudents === 1 ? (
                  <div className="small-text flex flex-align-center italic-text mt-4">
                    <span className="fw-700 mr-4">Student:</span>
                    {formatFullName(
                      students[0].firstName,
                      students[0].lastName,
                    )}
                  </div>
                ) : (
                  <div className="small-text italic-text fw-700">
                    {formatNumber(numberOfStudents)}{" "}
                    {pluralizeText("Student", numberOfStudents)}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="text-right small-text full-width">
          <div>{dateTime ? moment(dateTime).format("MMM D, YYYY") : ""}</div>
          {!isProgramActivity && (
            <div className="flex flex-align-center flex-justify-end italic-text mb-0 mt-8">
              {classification} Interaction{" "}
              {this.getCategoryRating(categoryRating)}
            </div>
          )}
        </div>
      </div>
    );
  }
}
