import React from "react";
import Select, { Creatable } from "react-select";

// import { Country, State, ReactSelectOption } from "../../../models/Common";
import { /* Address, */ EmptyAddress } from "../../../models/Contact";

// interface AddressUIProps {
//   addressData?: Address;
//   countries: Array<Country>;
//   states: Array<State>;
//   required: boolean;
//   addressGrouper?: string;
//   ddStyles?: { [styleName: string]: string };
//   handleAddressChanged: (newAddressState: Address) => void;
// }

const AddressUI = ({
  addressData = EmptyAddress(),
  countries,
  states,
  required,
  addressGrouper,
  ddStyles = {},
  handleAddressChanged,
}) => {
  const updateFilterDomElement = (evt) => {
    const newAddress = {
      ...addressData,
      [evt.currentTarget.name]: evt.currentTarget.value,
    };
    handleAddressChanged(newAddress);
  };
  /** @param {ReactSelectOption} newCountry */
  const handleCountryChange = (newCountry) => {
    const newAddress = {
      ...addressData,
      countryId: newCountry && newCountry.value,
      stateId: undefined,
      state: "",
    };
    handleAddressChanged(newAddress);
  };
  /** @param {ReactSelectOption} newState */
  const handleStateChange = (newState) => {
    const newAddress = {
      ...addressData,
      stateId: newState && newState.value,
      state: newState && newState.label,
    };
    handleAddressChanged(newAddress);
  };

  return (
    <dl className="dl-horizontal">
      <dt>Address Line 1</dt>
      <dd style={ddStyles}>
        <input
          type="text"
          name="addressLine1"
          value={addressData.addressLine1 || ""}
          required={required}
          onChange={updateFilterDomElement}
          autoComplete={"section-addr_" + addressGrouper + " address-line1"}
        />
      </dd>
      <dt>Line 2</dt>
      <dd style={ddStyles}>
        <input
          type="text"
          name="addressLine2"
          value={addressData.addressLine2 || ""}
          onChange={updateFilterDomElement}
          autoComplete={"section-addr_" + addressGrouper + " address-line2"}
        />
      </dd>
      <dt>City</dt>
      <dd style={ddStyles}>
        <input
          type="text"
          name="city"
          value={addressData.city || ""}
          required={required}
          onChange={updateFilterDomElement}
          autoComplete={"section-addr_" + addressGrouper + " address-level2"}
        />
      </dd>
      <dt>Postal Code</dt>
      <dd style={ddStyles}>
        <input
          type="text"
          name="postalCode"
          value={addressData.postalCode || ""}
          onChange={updateFilterDomElement}
          autoComplete={"section-addr_" + addressGrouper + " postal-code"}
        />
      </dd>
      <dt>Country</dt>
      <dd style={ddStyles}>
        <div style={{ display: "table", width: "100%" }}>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", paddingTop: "2px" }}>
              <Select
                name="country"
                options={countries.map((c) => ({ value: c.id, label: c.name }))}
                onChange={handleCountryChange}
                multi={false}
                value={addressData.countryId || ""}
                promptTextCreator={(text) => "New country: " + text}
                required={required}
                style={{ minWidth: "150px" }}
                autoComplete={
                  "section-addr_" + addressGrouper + " country-name"
                }
              />
            </div>
          </div>
        </div>
      </dd>
      <dt>State</dt>
      <dd style={ddStyles}>
        <div style={{ display: "table", width: "100%" }}>
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell", paddingTop: "2px" }}>
              {addressData &&
              countries &&
              countries.length > 0 &&
              (addressData.countryId ===
                countries.find((c) => c.name === "USA").id ||
                addressData.country ===
                  countries.find((c) => c.name === "Canada").id ||
                addressData.country ===
                  countries.find((c) => c.name === "Mexico").id) ? (
                <Select
                  name="state"
                  options={states.map((c) => ({ value: c.id, label: c.name }))}
                  onChange={handleStateChange}
                  multi={false}
                  value={addressData.stateId || ""}
                  required={required}
                  style={{ minWidth: "150px" }}
                  autoComplete={
                    "section-addr_" + addressGrouper + " address-level1"
                  }
                />
              ) : (
                <Creatable
                  name="state"
                  options={states.map((c) => ({ value: c.id, label: c.name }))}
                  onChange={handleStateChange}
                  multi={false}
                  value={addressData.stateId || ""}
                  placeholder={"Select"}
                  promptTextCreator={(text) => "New state: " + text}
                  required={required}
                  style={{ minWidth: "150px" }}
                  autoComplete={
                    "section-addr_" + addressGrouper + " address-level1"
                  }
                />
              )}
            </div>
          </div>
        </div>
      </dd>
    </dl>
  );
};

export default AddressUI;
