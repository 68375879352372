import React from "react";
import { Link } from "react-router";
import { CSVLink } from "react-csv";
import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import IsraeLinksReportingApi from "../../services/resources/IsraeLinksReportingApi";
import IsraeLinksSelection from "./IsraeLinksSelection";
import DataTable /* , { DataTableColumn } */ from "../common/DataTable";
import { EmailLink } from "../../lib/coc-common-components";
import { ArrayToLookup } from "../../lib/coc-common-scripts";
import DatePicker from "../common/DateRangePicker";
import CsvGetAndDownload from "../common/CSVGetAndDownload";

export class IsraeLinksReportingByStudent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedSessionId: this.props.params.id || 0,
      startDate: moment.utc(+this.props.location.query.startDate),
      endDate: moment.utc(+this.props.location.query.endDate),
      participantsOnly: this.props.location.query.participantsOnly || false,
      reportData: [],
      pageCount: 0,
      sortingId: "name",
      sortingDesc: false,
      startingOffset: 0,
      maxResults: 20,
    };

    this.handleTableDataRequest = this.handleTableDataRequest.bind(this);
    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleProgramSessionChange =
      this.handleProgramSessionChange.bind(this);
    this.requestSessionList = this.requestSessionList.bind(this);
    this.requestReportData = this.requestReportData.bind(this);
    this.requestDataForDownload = this.requestDataForDownload.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.IsraeLinksDetailed)) {
      return;
    }

    this.requestSessionList();
  }

  handleTableDataRequest(tableState, instance) {
    const sorting = tableState.sorted || [];
    if (sorting.length === 0) {
      sorting.push({ id: this.state.sortingId, desc: this.state.sortingDesc });
    }

    this.setState(
      {
        sortingId: sorting[0].id,
        sortingDesc: !sorting[0].desc,
        startingOffset: tableState.page * tableState.pageSize,
        maxResults: tableState.pageSize,
      },
      this.requestReportData,
    );
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState(
        {
          [dateFieldName]: selectedDate,
          selectedSessionId: 0,
          startingOffset: 0,
        },
        this.requestReportData,
      );
    };
  }

  handleProgramSessionChange(selectedSessionId) {
    this.setState(
      { selectedSessionId, startingOffset: 0 },
      this.requestReportData,
    );
  }

  requestSessionList() {
    this.setState({ loading: true });

    IsraeLinksReportingApi.getList()
      .then((response) => {
        this.setState(
          { sessions: response.data.allSessions },
          this.requestReportData,
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {AxiosPromise} */
    let apiCall;

    if (this.state.selectedSessionId) {
      apiCall = IsraeLinksReportingApi.getBySessionBreakdownByStudents(
        this.state.selectedSessionId,
        this.state.participantsOnly,
        "name",
        false,
        true,
        0,
        0,
      );
    } else {
      apiCall = IsraeLinksReportingApi.getByDatesBreakdownByStudents(
        this.state.startDate.clone().startOf("day"),
        this.state.endDate.clone().endOf("day"),
        this.state.participantsOnly,
        this.state.sortingId,
        this.state.sortingDesc,
        false,
        this.state.startingOffset,
        this.state.maxResults,
      );
    }

    apiCall
      .then((response) => {
        this.setState({
          reportData: response.data,
          startDate:
            response.data.programInfo && response.data.programInfo.id > 0
              ? moment.utc(response.data.programInfo.startDate)
              : this.state.startDate,
          endDate:
            response.data.programInfo && response.data.programInfo.id > 0
              ? moment.utc(response.data.programInfo.endDate)
              : this.state.endDate,
          pageCount: Math.ceil(response.data.count / this.state.maxResults),
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }
  /** @returns {AxiosPromise} */
  requestDataForDownload() {
    // if (!this.state.selectedSessionId) {
    /** @type {moment.Moment} */
    const startDateUtc = this.state.startDate.clone().startOf("day");
    /** @type {moment.Moment} */
    const endDateUtc = this.state.endDate.clone().endOf("day");
    return IsraeLinksReportingApi.getByDatesBreakdownByStudents(
      startDateUtc,
      endDateUtc,
      this.state.participantsOnly,
      "name",
      true,
      true,
      0,
      0,
    );
    // }
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.IsraeLinksDetailed)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasDirectoryPermission = AuthService.UserHasClaim(
      PermissionClaims.DirectoryRead,
    );

    const {
      reportData,
      selectedSessionId,
      startDate,
      endDate,
      participantsOnly,
      pageCount,
    } = this.state;
    const students = (reportData && reportData.students) || [];
    /** @type {Array<any>} */
    const sessions = (this.state.sessions && this.state.sessions) || [];
    const selectedSession =
      sessions.length && ArrayToLookup(sessions, "id")[selectedSessionId];
    const selectedSessionName = selectedSession ? selectedSession.title : "";
    /** @type {Array<DataTableColumn>} */
    const columns = [
      {
        Header: "Full Name",
        id: "name",
        accessor: (c) => c.lastName + " " + c.firstName,
        Cell: (c) =>
          hasDirectoryPermission ? (
            <Link to={`/students/${c.original.contactId}`}>
              {c.original.firstName + " " + c.original.lastName}
            </Link>
          ) : (
            c.original.firstName + " " + c.original.lastName
          ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (c) =>
          c.original.email !== null ? (
            <EmailLink emailAddress={c.original.email} />
          ) : (
            ""
          ),
      },
      {
        Header: "School",
        accessor: "schoolName",
        Cell: (c) =>
          hasDirectoryPermission ? (
            <Link to={`/schools/${c.original.schoolId}`}>
              {c.original.schoolName}
            </Link>
          ) : (
            c.original.schoolName
          ),
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 120,
      },
      {
        Header: "Event Date",
        id: "eventDate",
        accessor: (c) => c.eventDate,
        maxWidth: 120,
        Cell: (c) => (
          <span>
            {c.original.eventDate &&
              moment(c.original.eventDate).format("MM/DD/YYYY")}
          </span>
        ),
        style: { textAlign: "center" },
      },
      {
        Header: "Gender",
        accessor: "gender",
        maxWidth: 120,
      },
    ];
    if (!selectedSessionId) {
      columns.push({
        Header: "IsraeLinks Session",
        accessor: "programName",
      });
    }

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/israelinks">IsraeLinks</Link>
              </li>
              <li>
                <Link to="/israelinks">Reports</Link>
              </li>
              <li>
                {this.state.participantsOnly ? "Participants" : "Applicants"}
              </li>
            </ol>
          </div>
          <div className="col-sm-5">
            <IsraeLinksSelection
              sessions={sessions}
              selectedSessionId={selectedSessionId}
              handleProgramSessionChange={this.handleProgramSessionChange}
            />
          </div>
          <div className="col-sm-5">
            <DatePicker
              startDate={startDate}
              endDate={endDate}
              handleDateInputChange={this.handleDateInputChange}
            />
          </div>
          <div className="col-sm-2" style={{ marginBottom: "1em" }}>
            {selectedSessionId > 0 ? (
              <CSVLink
                className="btn btn-primary btn-md"
                data={students}
                filename={`IsraeLinks${
                  participantsOnly ? "Participants" : "Applicants"
                }_${selectedSessionName}.csv`}
              >
                Download CSV
              </CSVLink>
            ) : (
              <CsvGetAndDownload
                dataRetrieveFunction={this.requestDataForDownload}
                dataPath="students"
                fileName={`IsraeLinks${
                  participantsOnly ? "Participants" : "Applicants"
                }_${startDate.format("YYYY-MM-DD")}_${endDate.format(
                  "YYYY-MM-DD",
                )}.csv`}
              />
            )}
          </div>
          <div className="col-sm-12">
            <DataTable
              fullClientSide={selectedSessionId > 0}
              totalPageCount={pageCount}
              columns={columns}
              reportData={students}
              onDataRequest={this.handleTableDataRequest}
            />
          </div>
          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }
}
