import React from "react";
import ConfirmationModal from "./ConfirmationModal";
import AuthApi from "../../services/resources/AuthApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import { impersonationUserTypes, setImpersonationAuthCookie } from "../../lib";
import axios from "axios";

const {
  REACT_APP_COCI_SHLUCHIM_PORTAL_DOMAIN,
  REACT_APP_COCI_STUDENT_PORTAL_DOMAIN,
  // REACT_APP_COCI_JU_SITE_DOMAIN
} = process.env;

export default class ImpersonateUser extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    showConfirmationModal: false,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getAuthTypeAndRedirectUrl = (userType) => {
    switch (userType) {
      // case impersonationUserTypes.JewishU:
      //   return {
      //     authType: "student",
      //     redirectUrl: REACT_APP_COCI_JU_SITE_DOMAIN
      //   };
      case impersonationUserTypes.Admin:
        return {
          authType: "admin",
          redirectUrl: "/",
        };
      case impersonationUserTypes.Student:
        return {
          authType: "student",
          redirectUrl: REACT_APP_COCI_STUDENT_PORTAL_DOMAIN,
        };
      case impersonationUserTypes.Shliach:
      default:
        return {
          authType: "shliach",
          redirectUrl: REACT_APP_COCI_SHLUCHIM_PORTAL_DOMAIN,
        };
    }
  };

  impersonate = async () => {
    const { redirectUrlEnd = "", username, userType } = this.props;

    this.setState({ loading: true, showConfirmationModal: false });

    try {
      const { authType, redirectUrl } =
        this.getAuthTypeAndRedirectUrl(userType);

      const authResponse = await AuthApi.impersonateUser(
        authType,
        username,
        this.apiSignal.token,
      );

      if (userType === impersonationUserTypes.Admin) {
        const token = authResponse.data.token;
        if (token) {
          localStorage.setItem("token", token);
          window.location.reload();
        }
      } else {
        setImpersonationAuthCookie(userType, authResponse.data);
      }

      window.location.href = redirectUrl + redirectUrlEnd;
    } catch (err) {
      if (!axios.isCancel(err)) {
        const errorMessage =
          err &&
          err.response &&
          err.response.data &&
          err.response.data.defaultMessage;
        this.setState({
          errorMessage: errorMessage || ApiCallErrorMessageHandler(err),
          loading: false,
        });
      }
    }
  };

  render() {
    const {
      className,
      username,
      userType,
      showIconWithoutText = false,
      preventClickDefault = false,
    } = this.props;
    const { errorMessage, loading, showConfirmationModal } = this.state;

    return (
      <div>
        <p
          className={`link-text flex flex-align-center mb-0 ${className || ""}`}
          onClick={(e) => {
            if (preventClickDefault) {
              e.preventDefault();
            }
            e.stopPropagation();
            if (loading) {
              return;
            }
            this.setState({ showConfirmationModal: true });
          }}
          style={{ fontSize: "14px", fontWeight: "normal" }}
        >
          <i className="material-icons large-text mr-4">account_circle</i>
          {!showIconWithoutText && (loading ? "Impersonating" : "Impersonate")}
        </p>
        {errorMessage && (
          <p className="error-text xs-text mt-4">{errorMessage}</p>
        )}
        <ConfirmationModal
          cancel={() => this.setState({ showConfirmationModal: false })}
          confirm={(e) => {
            e.stopPropagation();
            this.impersonate();
          }}
          message={`Please confirm that you would like to impersonate user ${username}. \n\nPlease note that you will be impersonating this user until you ${
            userType === impersonationUserTypes.Admin
              ? "log out of"
              : "return to"
          } the Admin portal or your browser session expires. \n\nPlease exercise great caution when using impersonation and be sure to properly end the session when you are finished to avoid security risks.`}
          show={showConfirmationModal}
          title="Impersonate User"
        />
      </div>
    );
  }
}
