import React from "react";
import { formatNumber } from "../../../lib";

export default class CourseSchedulesTableHeader extends React.PureComponent {
  render() {
    const { totalStudentsEnrolled } = this.props;
    const columns = [
      "Course Name",
      "Campus / Shliach and Shlucha",
      "Semester",
      "Date of Next Class",
      "Classes Completed",
      <span>
        Students Enrolled
        {totalStudentsEnrolled > 0 && (
          <span className="block fw-500">
            Total: {formatNumber(totalStudentsEnrolled)}
          </span>
        )}
      </span>,
      "Status",
    ];

    return (
      <div className="courses-schedules-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
