import React from "react";

// import { School, SchoolFilter } from "../../../models/School";
import CsvGetAndDownload /* , { ColumnConfig } */ from "../../common/CSVGetAndDownload";
import { mainContactMethods } from "../../Contact/ContactUtils";
import { MainShluchim, MainMosad } from "../Utils";
import { SchoolFilterButton } from "../SchoolFilter/SchoolFilterButton";
import { SchoolList } from "./SchoolList";

// interface SchoolListPageProps {
//   schools: Array<School>;
//   currentFilters: SchoolFilter;
//   isAdvancedSearch: boolean;
//   hasEditPermission: boolean;
//   handleFiltersChanged: (filters: SchoolFilter) => void;
// }

export const SchoolListPage = ({
  schools,
  currentFilters,
  isAdvancedSearch,
  hasEditPermission,
  handleFiltersChanged,
}) => {
  /** @returns {Array<ColumnConfig>} */
  const getDownloadHeaders = () => {
    return [
      {
        columnId: "schoolId",
        header: "School ID",
        dataField: "id",
      },
      {
        columnId: "name",
        header: "School",
        dataField: "name",
      },
      {
        columnId: "schoolNickname",
        header: "School Nickname/Initial",
        dataField: "nickname",
        visible: false,
      },
      {
        columnId: "schoolUrl",
        header: "School Website",
        dataField: "url",
        visible: false,
      },
      {
        columnId: "schoolProgramLevels",
        header: "School Program Levels",
        dataField: "programLevels",
        visible: false,
        transform: (programLevels) => {
          return (programLevels || []).join();
        },
      },
      {
        columnId: "schoolAnnualTuition",
        header: "Annual Tuition",
        dataField: "annualTuition",
        visible: false,
      },
      {
        columnId: "schoolIsInstitutionControlPublic",
        header: "Institution Control",
        dataField: "isInstitutionControlPublic",
        visible: false,
        transform: (c) => (c ? "Public" : "Private"),
      },
      {
        columnId: "schoolCalendarType",
        header: "Calendar Type",
        dataField: "schoolCalendarType",
        visible: false,
      },
      {
        columnId: "schoolGraduatePopulation",
        header: "Graduate Population",
        dataField: "graduatePopulation",
        visible: false,
      },
      {
        columnId: "schoolUndergradPopulation",
        header: "Undergrad Population",
        dataField: "undergradPopulation",
        visible: false,
      },
      {
        columnId: "schoolJewishGraduatePopulation",
        header: "Jewish Graduate Population",
        dataField: "jewishGraduatePopulation",
        visible: false,
      },
      {
        columnId: "schoolJewishUndergradPopulation",
        header: "Jewish Undergrad Population",
        dataField: "jewishUndergradPopulation",
        visible: false,
      },
      {
        columnId: "schoolStudyAreas",
        header: "Study Areas",
        dataField: "campusStudyAreas",
        visible: false,
        transform: (sa) => sa.map((sa) => sa.studyArea).join(", "),
      },
      {
        columnId: "schoolPrestige",
        header: "Prestige",
        dataField: "prestige",
        visible: false,
      },
      {
        columnId: "shliachId",
        header: "Shliach - ID",
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          return shluchim.shliach ? shluchim.shliach.id : "";
        },
      },
      {
        labelGroup: "Shliach - Name",
        columnId: "shliachFName",
        header: "Shliach - First Name",
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          return shluchim.shliach ? shluchim.shliach.firstName : "";
        },
      },
      {
        labelGroup: "Shliach - Name",
        columnId: "shliachLName",
        header: "Shliach - Last Name",
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          return shluchim.shliach ? shluchim.shliach.lastName : "";
        },
      },
      {
        columnId: "shliachEmail",
        header: "Shliach - Email",
        visible: false,
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          const cm =
            (shluchim && shluchim.shliach && shluchim.shliach.contactMethod) ||
            null;
          const mainEmail = mainContactMethods(cm).email;
          return mainEmail && mainEmail.value ? mainEmail.value : "";
        },
      },
      {
        columnId: "shliachHomePhone",
        header: "Shliach - Phone",
        visible: false,
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          const cm =
            (shluchim && shluchim.shliach && shluchim.shliach.contactMethod) ||
            null;
          const mainPhone = mainContactMethods(cm).phoneNumber;
          return mainPhone && mainPhone.value ? mainPhone.value : "";
        },
      },
      {
        columnId: "shliachCellPhone",
        header: "Shliach - Cell Phone",
        visible: false,
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          const cm =
            (shluchim && shluchim.shliach && shluchim.shliach.contactMethod) ||
            null;
          const mainMobile = mainContactMethods(cm).cellNumber;
          return mainMobile && mainMobile.value ? mainMobile.value : "";
        },
      },
      {
        columnId: "shluchaId",
        header: "Shlucha - ID",
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          return shluchim.shlucha ? shluchim.shlucha.id : "";
        },
      },
      {
        labelGroup: "Shlucha - Name",
        columnId: "shluchaFName",
        header: "Shlucha - First Name",
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          return shluchim.shlucha ? shluchim.shlucha.firstName : "";
        },
      },
      {
        labelGroup: "Shlucha - Name",
        columnId: "shluchaLName",
        header: "Shlucha - Last Name",
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          return shluchim.shlucha ? shluchim.shlucha.lastName : "";
        },
      },
      {
        columnId: "shluchaEmail",
        header: "Shlucha - Email",
        visible: false,
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          const cm =
            (shluchim && shluchim.shlucha && shluchim.shlucha.contactMethod) ||
            null;
          const mainEmail = mainContactMethods(cm).email;
          return mainEmail && mainEmail.value ? mainEmail.value : "";
        },
      },
      {
        columnId: "shluchaHomePhone",
        header: "Shlucha - Phone",
        visible: false,
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          const cm =
            (shluchim && shluchim.shlucha && shluchim.shlucha.contactMethod) ||
            null;
          const mainPhone = mainContactMethods(cm).phoneNumber;
          return mainPhone && mainPhone.value ? mainPhone.value : "";
        },
      },
      {
        columnId: "shluchaCellPhone",
        header: "Shlucha - Cell Phone",
        visible: false,
        getData: (school) => {
          const shluchim = MainShluchim(school) || {};
          const cm =
            (shluchim && shluchim.shlucha && shluchim.shlucha.contactMethod) ||
            null;
          const mainMobile = mainContactMethods(cm).cellNumber;
          return mainMobile && mainMobile.value ? mainMobile.value : "";
        },
      },
      {
        columnId: "mosadId",
        header: "Mosad ID",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.id) || "";
        },
      },
      {
        columnId: "mosadName",
        header: "Mosad Name",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.name) || "";
        },
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad Address Line 1",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.address && mosad.address.addressLine1) || "";
        },
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad Line 2",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.address && mosad.address.addressLine2) || "";
        },
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad City",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.address && mosad.address.city) || "";
        },
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad ZIP",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.address && mosad.address.postalCode) || "";
        },
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad State",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.address && mosad.address.state) || "";
        },
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad Country",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.address && mosad.address.country) || "";
        },
      },
      {
        columnId: "mosadPhone",
        header: "Mosad Phone",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (
            (mosad && mosad.phone) +
              (mosad && mosad.ext ? "x" + mosad.ext : "") || ""
          );
        },
      },
      {
        columnId: "mosadUrl",
        header: "Mosad Website",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.url) || "";
        },
      },
      {
        columnId: "mosadYearStarted",
        header: "Mosad Year Started",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          return (mosad && mosad.yearStarted) || "";
        },
      },
      {
        columnId: "mosadInvolvementLevel",
        header: "Full or Limited Service",
        visible: false,
        getData: (school) => {
          const mosad = MainMosad(school);
          if (!mosad) {
            return "";
          }
          return mosad.isFullTime ? "Full Service" : "Limited Service";
        },
      },
    ];
  };

  const getFileName = () => {
    return (
      "Schools" +
      /*(this.props.location.query.q ? "_" + this.props.location.query.q : "") + */ ".csv"
    ); // eslint-disable-line
  };

  return (
    <div>
      <ol className="breadcrumb">
        <li>Directory</li>
        <li>Schools</li>
      </ol>
      <div className="list-container">
        <div className="flex flex-align-center mb-16">
          <div className="xxl-text">Schools</div>
          <span className="tooltip-container">
            <i className="material-icons large-text ml-8 link-text no-hover">
              info
            </i>
            <span className="tooltip tooltip-medium-width">
              To add a new campus, go to the associated Chabad House and add the
              campus in the Associated Schools section.
            </span>
          </span>
        </div>
        {!isAdvancedSearch && (
          <div
            className="row"
            style={{ marginBottom: "1em", position: "relative" }}
          >
            <div className="form-group">
              <div className="col-sm-12 text-left lfloatmb1">
                <div className="results-summary results-summary-small">
                  <div className="results-summary-item">
                    <i className="icon icon-results">
                      <span className="path1" />
                      <span className="path2" />
                    </i>
                    <span className="results-summary-desc">
                      <label className="control-label">Results</label>
                      {schools.length}
                    </span>
                  </div>
                </div>
                {/* {hasEditPermission && !isAdvancedSearch && (
                  <Link
                    to={"/schools/new"}
                    className="btn btn-primary btn-sm rfloat"
                  >
                    Add New
                  </Link>
                )} */}

                <div className="rfloat">
                  <CsvGetAndDownload
                    dataToDownload={schools}
                    dataPath="results"
                    fileName={getFileName()}
                    columnDefinitions={getDownloadHeaders()}
                  />
                </div>
                <div className="filter-field">
                  <SchoolFilterButton
                    handleFiltersChanged={handleFiltersChanged}
                    currentFilters={currentFilters}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <SchoolList
            schools={schools}
            handleFiltersChanged={handleFiltersChanged}
            currentFilters={currentFilters}
          />
        </div>
      </div>
    </div>
  );
};
