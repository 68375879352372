import React from "react";
import { browserHistory } from "react-router";
import CopyToClipboardTextbox from "../shared/CopyToClipboardTextbox";
import ProgressStatusBar from "./RaffleComponents/ProgressStatusBar";
import PublishConfirmationModal from "../shared/ConfirmationModal";
import { notify } from "react-notify-toast";

import RafflesApi from "../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import { getRequiredRaffleFields } from "./RaffleLogic";
import { formatCurrency, formatNumber } from "../../lib";
import _cloneDeep from "lodash.clonedeep";
import moment from "moment";

export default class CurrentRaffleCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      publishRaffleErrorMessage: "",
      publishRaffleLoading: false,

      requiredRaffleFields: {},
      showPublishConfirmationModal: false,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onPublishRaffle = () => {
    const requiredRaffleFields = getRequiredRaffleFields(this.props.raffle);
    this.setState({
      requiredRaffleFields,
      showPublishConfirmationModal: true,
    });
  };

  publishRaffle = () => {
    const { raffle, reloadRaffles } = this.props;

    const publishedRaffle = _cloneDeep({
      ...raffle,
      isPublished: true,
    });

    this.setState(
      {
        publishRaffleErrorMessage: "",
        publishRaffleLoading: true,
      },
      () => {
        RafflesApi.updateRaffle(this.apiSignal.token, publishedRaffle)
          .then(() => {
            notify.show("Your Grand Draw has been published", "success");
            reloadRaffles();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                publishRaffleErrorMessage: ApiCallErrorMessageHandler(err),
                publishRaffleLoading: false,
              });
            }
          });
      },
    );
  };

  render() {
    const {
      hasRaffleSettingsAccess,
      raffle: {
        chabadHouseCount,
        chabadHouseWithTicketsCount,
        createdOn,
        daysRemaining,
        endDate,
        firstPlaceFundRaiser,
        isActive,
        isOpenForManualTicketEntry,
        isPublished,
        manualTicketEntryDeadline,
        programScheduleID,
        raffleTitle,
        raffleURL,
        startDate,
        ticketsSold,
        totalCcProcessingFeesCollected,
        totalCociFeesCollected,
        totalProcessingFeeDonorAmountRaised,
        totalRaised,
        totalTicketAmountRaised,
      },
    } = this.props;
    const {
      publishRaffleErrorMessage,
      publishRaffleLoading,
      requiredRaffleFields,
      showPublishConfirmationModal,
    } = this.state;

    return isPublished ? (
      <div className="card raffles-card">
        <div className="flex flex-justify-space">
          <div>
            <p className="xl-text fw-700 flex mb-16">
              {raffleTitle}
              {isActive ? (
                <span className="tag active-tag ml-16">Active</span>
              ) : daysRemaining ? (
                <span className="tag scheduled-tag ml-16">Scheduled</span>
              ) : (
                <React.Fragment>
                  <span className="tag closed-tag ml-16">Closed</span>
                  {isOpenForManualTicketEntry && (
                    <span className="small-text accent-text fw-500 ml-16">
                      * Open for manual ticket entry through{" "}
                      {moment(manualTicketEntryDeadline).format(
                        "MMMM DD h:mm a",
                      )}
                    </span>
                  )}
                </React.Fragment>
              )}
            </p>
            {(isActive || isOpenForManualTicketEntry) && (
              <div className="flex">
                <div>
                  <p>
                    <span
                      className="fw-700 inline-block"
                      style={{ width: "180px" }}
                    >
                      Total Raised
                    </span>
                    {formatCurrency(totalRaised, "USD")}
                  </p>
                  <p>
                    <span
                      className="inline-block"
                      style={{ width: "180px", marginLeft: "12px" }}
                    >
                      Total Ticket Cost
                    </span>
                    {formatCurrency(totalTicketAmountRaised, "USD")}
                  </p>
                  <p>
                    <span
                      className="inline-block"
                      style={{ width: "180px", marginLeft: "12px" }}
                    >
                      Total Processing Fees Donated
                    </span>
                    {formatCurrency(totalProcessingFeeDonorAmountRaised, "USD")}
                  </p>
                  <p>
                    <span
                      className="fw-700 inline-block"
                      style={{ width: "180px" }}
                    >
                      Tickets Sold
                    </span>
                    {formatNumber(ticketsSold)}
                  </p>
                </div>
                <div className="divider" />
                <div>
                  <p>
                    <span
                      className="fw-700 inline-block"
                      style={{ width: "180px" }}
                    >
                      Chabad Houses Enrolled
                    </span>
                    {formatNumber(chabadHouseCount)}
                  </p>
                  <p>
                    <span
                      className="fw-700 inline-block"
                      style={{ width: "180px" }}
                    >
                      Chabad Houses with Tickets Sold
                    </span>
                    {formatNumber(chabadHouseWithTicketsCount)}
                  </p>
                  <p>
                    <span
                      className="fw-700 inline-block"
                      style={{ width: "180px" }}
                    >
                      Total COCI Ticket Fees
                    </span>
                    {formatCurrency(totalCociFeesCollected, "USD")}
                  </p>
                  <p>
                    <span
                      className="fw-700 inline-block"
                      style={{ width: "180px" }}
                    >
                      Total Processing Fees
                      <span className="tooltip-container">
                        <i className="material-icons medium-text ml-4">info</i>
                        <span className="tooltip">
                          For CC and PayPal purchases
                        </span>
                      </span>
                    </span>
                    {formatCurrency(totalCcProcessingFeesCollected, "USD")}
                  </p>
                  {firstPlaceFundRaiser && (
                    <p>
                      <span
                        className="fw-700 inline-block"
                        style={{ width: "180px" }}
                      >
                        1st Place Fundraiser
                      </span>
                      {firstPlaceFundRaiser}
                    </p>
                  )}
                </div>
              </div>
            )}
            <ProgressStatusBar
              className="mt-24"
              daysRemaining={daysRemaining}
              endDate={endDate}
              startDate={startDate}
              type="raffleDays"
            />
          </div>
          <div className="raffles-active-right">
            {isActive && raffleURL && (
              <CopyToClipboardTextbox
                className="raffles-link"
                id="raffle-url"
                label="Copy Link"
                text={raffleURL}
                style={{ height: "18px", whiteSpace: "nowrap" }}
              />
            )}
            <p
              className="link-text uppercase-text fw-700"
              onClick={() =>
                browserHistory.push(`/grand-draw/${programScheduleID}`)
              }
            >
              View Details
            </p>
          </div>
        </div>
      </div>
    ) : (
      <div className="card raffles-card">
        <div className="flex flex-justify-space flex-align-center">
          <div className="flex flex-align-center">
            <p className="xl-text fw-700 flex">
              {raffleTitle}
              <span className="tag draft-tag ml-16">Draft</span>
            </p>
            <p className="accent-text medium-text ml-24">
              Created on {moment(createdOn).format("MM/DD/YYYY")}
            </p>
          </div>
          {hasRaffleSettingsAccess && (
            <div className="raffles-draft-actions flex">
              <p
                className="link-text uppercase-text"
                onClick={this.onPublishRaffle}
              >
                Publish
              </p>
              <p
                className="link-text uppercase-text ml-24"
                onClick={() =>
                  browserHistory.push(`/grand-draw/new/${programScheduleID}`)
                }
              >
                Open
              </p>
            </div>
          )}
        </div>

        <PublishConfirmationModal
          cancel={() => this.setState({ showPublishConfirmationModal: false })}
          cancelText={requiredRaffleFields.isIncomplete ? "OK" : "Cancel"}
          confirm={this.publishRaffle}
          confirmText="Publish"
          errorMessage={publishRaffleErrorMessage}
          message={
            requiredRaffleFields.isIncomplete
              ? `Please update the following required or invalid fields before publishing this Grand Draw:
                            \n${requiredRaffleFields.incompleteFields}`
              : "You are about to publish this Grand Draw. This can't be undone. Would you like to proceed?"
          }
          noConfirm={requiredRaffleFields.isIncomplete}
          loading={publishRaffleLoading}
          show={showPublishConfirmationModal}
        />
      </div>
    );
  }
}
