import React from "react";
import { Link } from "react-router";

import { ContactType } from "../../Contact/ContactUtils";
// import { CustomEvent } from "../Utils";
import EventsList from "./EventsList";

// interface EventsListExtProps {
//   contactType: ContactType;
//   events: Array<CustomEvent>;
// }

const EventsListExt = ({ contactType, events }) => {
  return (
    <div>
      <h3>{contactType === ContactType.shluchim ? "Shluchim" : "Students"}</h3>
      <EventsList
        events={events.filter((e) => e.contactType === contactType)}
      />
      <div className="row">
        <div className="col-sm-10" />
        <div className="col-sm-2">
          <Link
            to={`/config/customevents/new?type=${
              contactType === ContactType.shluchim ? "shliach" : "student"
            }`}
            className="btn btn-primary btn-sm"
          >
            Add New
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EventsListExt;
