import React from "react";

import { formatFullName, partition } from "../../../../../lib";

function Attendees(props) {
  const { attendees } = props;

  const [attendingShluchim, chaperones] = partition(attendees, (a) => !a.isChaperone);

  return (
    <>
      <div className="trip-page-form-section shluchim-section">
        <p
          className="medium-text fw-700 mb-16"
        >
          Shluchim
        </p>
        {!!attendingShluchim.length ? (
          <div>
            {attendingShluchim.map((shliach, index) => (
              <div key={index} className="fw-700">
                {formatFullName(shliach.firstName, shliach.lastName)}
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-16">
            No Shluchim were chosen
          </div>
        )}
      </div>
      <div className="trip-page-form-section">
        <div className="flex flex-align-center">
          <p className="medium-text fw-700">Additional Chaperones</p>
        </div>
        {!!chaperones.length ? (
          <div className="mt-24">
            {chaperones.map((chaperone, i) => (
              <div key={i} className="flex flex-align-center">
                <div className="chaperone three-column-grid">
                  <p className="flex flex-align-center fw-500 ml-16">
                    {formatFullName(chaperone.firstName, chaperone.lastName)}
                  </p>
                  <p className="flex flex-align-center fw-300">
                    {chaperone.relationship}
                  </p>
                  <p className="flex flex-align-center fw-300">
                    {chaperone.phoneNumber}
                  </p>
                </div>
              </div>
            ))}
          </div>
        )
          : (
            <div className="mt-16">
              No additional Chaperones
            </div>
          )}
      </div>
    </>
  );
}
export default React.memo(Attendees);
