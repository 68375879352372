import React from "react";

export default function StudentsTableHeader(props) {
  const { showLatestCourseInfo, hideStatus } = props;

  const columns = [
    "Name",
    "Campus/Shliach",
    showLatestCourseInfo ? "Schedule" : "Courses Completed",
    showLatestCourseInfo ? "Classes Completed" : "Member Since",
  ];

  if (!hideStatus) columns.push("Status");
  if (showLatestCourseInfo) columns.push("Review Completed");

  return (
    <div
      className={`courses-students-table-header ${
        hideStatus ? "hide-status" : ""
      }`}
    >
      {columns.map((column, index) => (
        <p className="flex flex-align-center" key={index}>
          {column}
        </p>
      ))}
    </div>
  );
}
