import React from "react";
import { browserHistory } from "react-router";
import ImpersonateUser from "../../../../shared/ImpersonateUser";
import Loader from "../../../../common/Loader";

import StudentCoursesTable from "./courses/StudentCoursesTable";
import StudentLatestCourseDetails from "./courses/StudentLatestCourseDetails";
import StudentRewardsTable from "./rewards/StudentRewardsTable";

import EduApi from "../../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import AuthService, {
  PermissionClaims,
} from "../../../../../services/AuthService";
import { impersonationUserTypes } from "../../../../../lib";
import { EduProgramTypes } from "../../../EduConsts";
import queryString from "query-string";
import axios from "axios";

export default class StudentEnrollmentDetails extends React.PureComponent {
  state = {
    eduStudent: null,
    errorMessage: "",
    loading: false,

    tabs: [
      {
        component: StudentCoursesTable,
        id: "courses",
        name: "Course History",
      },
      {
        component: StudentRewardsTable,
        id: "rewards",
        name: "Reward History",
      },
    ],
    tabIndex: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    const {
      location: {
        query: { sub },
      },
      studentEnrollment,
    } = this.props;

    const currentTabIndex = this.state.tabs
      .map((t) => t.id)
      .indexOf(decodeURIComponent(sub));
    this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0);

    if (studentEnrollment) {
      this.getEduStudentDetails(studentEnrollment.id);
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidUpdate(prevProps) {
    const { studentEnrollment: { id: prevStudentEnrollmentId } = {} } =
      prevProps;
    const { studentEnrollment: { id: studentEnrollmentId } = {} } = this.props;
    if (
      prevStudentEnrollmentId &&
      studentEnrollmentId !== prevStudentEnrollmentId
    ) {
      this.getEduStudentDetails(studentEnrollmentId);
    }
  }

  getEduStudentDetails = async (eduStudentEnrollmentId, setLoading = true) => {
    if (setLoading) {
      this.setState({
        loading: true,
      });
    }

    try {
      const eduStudent = await EduApi.getEduStudent(
        this.apiSignal.token,
        eduStudentEnrollmentId,
      );

      this.setState({
        eduStudent,
        loading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and student details could not be retrieved. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  toTab = (index) => {
    const { tabs } = this.state;
    const {
      location: { query, pathname },
    } = this.props;

    if (index !== this.state.tabIndex) {
      this.setState({ tabIndex: index });

      query.sub = tabs[index].id;
      browserHistory.replace(`${pathname}?${queryString.stringify(query)}`);
    }
  };

  render() {
    const { viewOnly } = this.props;
    const { eduStudent, errorMessage, loading, tabs, tabIndex } = this.state;
    const {
      eduProgramName,
      eduProgramType,
      status,
      student: { email } = {},
    } = eduStudent || {};

    return (
      <div>
        {" "}
        {loading ? (
          <Loader />
        ) : errorMessage || !eduStudent ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                `Sorry, something went wrong and enrollment details could not be retrieved.`}
            </p>
          </div>
        ) : (
          eduStudent && (
            <React.Fragment>
              {status === "Rejected" && (
                <div className="flex flex-justify-end mb-8">
                  <p
                    className="medium-text rejected-chip"
                    style={{ padding: 14 }}
                  >
                    Rejected from {eduProgramName}
                  </p>
                </div>
              )}
              {eduProgramType === EduProgramTypes.YourIsrael &&
                AuthService.UserHasClaim(PermissionClaims.UserImpersonation) &&
                email && (
                  <div className="flex flex-justify-end">
                    <ImpersonateUser
                      redirectUrlEnd="?after=yourisrael"
                      username={email}
                      userType={impersonationUserTypes.Student}
                    />
                  </div>
                )}
              {eduProgramType !== EduProgramTypes.JewishU ? (
                <StudentLatestCourseDetails
                  eduStudent={eduStudent}
                  viewOnly={viewOnly}
                />
              ) : (
                <div
                  className="flex mt-16"
                  style={{ alignItems: "flex-start" }}
                >
                  <div className="student-details-edu-tabs-card mb-16">
                    {tabs.map((tab, index) => (
                      <p
                        key={index}
                        className={`large-text link-text-secondary ${
                          index > 0 ? "ml-40" : ""
                        } ${tabIndex === index ? "active" : ""}`}
                        onClick={() => this.toTab(index)}
                      >
                        {tab.name}
                      </p>
                    ))}
                  </div>
                  <div className="full-width">
                    {React.createElement(tabs[tabIndex].component, {
                      eduStudent,
                      refreshEduStudent: () =>
                        this.getEduStudentDetails(
                          eduStudent.eduStudentEnrollmentID,
                          false,
                        ),
                      viewOnly,
                    })}
                  </div>
                </div>
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
