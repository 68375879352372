import React from "react";

export default class StudentsTableHeader extends React.PureComponent {
  render() {
    const {
      readOnlyAccess,
      selectAllDisabledReason,
      selectedStudents,
      showMoreTableColumns,
      showSelectAllCheckbox,
      toggleSelectAllStudents,
      tours,
      totalStudents,
    } = this.props;

    const columns = [
      "Name",
      "Campus / Shliach",
      "Status",
      "Status Last Changed",
    ];
    if (showMoreTableColumns) {
      const tourScheduleColumns = [];
      tours.forEach(({ schedules, ...tour }, tourIndex) => {
        if (schedules?.length) {
          tourScheduleColumns.push(
            ...schedules.map((schedule, schedIndex) => (
              <span
                key={`${tourIndex}-${schedIndex}`}
                className="students-table-header-tour text-center"
                style={{ width: "160px", fontSize: "12px", whiteSpace: "pre" }}
              >{`${tour.name} -\n${schedule.name}`}</span>
            ))
          );
        }
      });

      columns.push(...["Track", tourScheduleColumns]);
    }
    columns.push(readOnlyAccess ? "" : "Actions");

    return (
      <div
        className={`trip-students-table-header${
          readOnlyAccess ? " read-only" : ""
        }`}
      >
        {columns.map((column, index) => (
          <div
            className={
              index !== columns.length - 1
                ? "flex flex-align-center relative"
                : ""
            }
            key={index}
          >
            {index === 0 && showSelectAllCheckbox && (
              <div className="tooltip-container mr-8">
                <div className="flex flex-align-center custom-checkbox-container">
                  <input
                    className="custom-checkbox"
                    checked={
                      selectedStudents.all && !selectedStudents.allExcept.length
                    }
                    disabled={!!selectAllDisabledReason || !totalStudents}
                    id="all-students"
                    onChange={toggleSelectAllStudents}
                    type="checkbox"
                  />
                  <label className="flex-align-center" htmlFor="all-students" />
                </div>
                {selectAllDisabledReason && (
                  <span className="tooltip">{selectAllDisabledReason}</span>
                )}
              </div>
            )}
            {column}
          </div>
        ))}
      </div>
    );
  }
}
