import AuthClient from "./AuthClient";

/** @type {{[x:string]:EnumType[]}} */
let lists;

async function fetchLists() {
  const {
    data: { payload },
  } = await AuthClient.get("System");
  lists = payload;
}

async function getLists(names, defaultValue = []) {
  if (!lists) {
    await fetchLists();
  }
  const listsObj = {};
  names.forEach((name) => (listsObj[name] = lists[name] || defaultValue));
  return listsObj;
}

async function getList(name, defaultValue = []) {
  if (!lists) {
    await fetchLists();
  }
  return lists[name] || defaultValue;
}

const SystemApi = {
  /** @returns {{[x:string]:EnumType[]}} */
  async lists(listNames) {
    if (listNames) {
      return await getLists(listNames);
    }

    if (!lists) {
      await fetchLists();
    }
    return lists;
  },
  async list(listName) {
    return getList(listName);
  },

  /** @returns {StaffMember[]} */
  async officeStaff() {
    const {
      data: { payload },
    } = await AuthClient.get("OfficeStaff");
    return payload;
  },

  async updatePortalRedirects(cancelToken, portalRedirects) {
    const response = await AuthClient.post(
      "System/PortalRedirects",
      portalRedirects,
      { cancelToken },
    );
    const updatedPortalRedirects = response.data.payload;

    if (updatedPortalRedirects) {
      //update portalRedirects in system lists
      lists.portalRedirects = updatedPortalRedirects;
    }

    return updatedPortalRedirects || [];
  },
  async submitCashTypes(cancelToken, cashTypes) {
    const response = await AuthClient.post("System/cashTypes", cashTypes, {
      cancelToken,
    });
    const { payload } = response && response.data;
    return payload;
  },
};

export default SystemApi;

// #region Typedefs
/** @typedef {object} EnumType
 * @property {number} intValue The C# integer value of the item.
 * @property {string} enumValue The C# variable name of the item.
 * @property {string} displayValue The display name of the item.
 */
/** @typedef {object} StaffMember
 * @property {number} id
 * @property {string} fullName
 * @property {string} [primaryEmail]
 * @property {string} [primaryPhone]
 * @property {string} [primaryPhoneExt]
 */
// #endregion
