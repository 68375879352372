import React from "react";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons /* , ModalButton */,
} from "../../../lib/coc-common-components";

import {
  /* School, SchoolFilter, */ EmptySchoolFilter,
} from "../../../models/School";
import SchoolApi from "../../../services/resources/SchoolsApi";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import { SchoolListPage } from "./SchoolListPage";

// interface SchoolListPageContainerProps {
//   location;
// }

// interface SchoolListPageContainerState {
//   schools: Array<School>;
//   filteredSchools: Array<School>;
//   currentFilters: SchoolFilter;
//   loading: boolean;
//   displayError: string;
// }

export class SchoolListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schools: [],
      filteredSchools: [],
      currentFilters: { ...EmptySchoolFilter(), name: props.location.query.q },
      loading: true,
      displayError: "",
    };

    this.handleFiltersChanged = this.handleFiltersChanged.bind(this);
  }
  /** @param {SchoolFilter} filters */
  handleFiltersChanged(filters) {
    let newFilteredSchools = [...this.state.schools];
    if (filters.name) {
      newFilteredSchools = newFilteredSchools.filter((s) =>
        s.name.toLowerCase().includes(filters.name.toLowerCase()),
      );
    }
    if (filters.countries) {
      const countryArray = filters.countries.split(",");
      newFilteredSchools = newFilteredSchools.filter((s) =>
        s.mosads.find(
          (m) =>
            m.address && countryArray.indexOf(m.address.country || -1) >= 0,
        ),
      );
    }
    if (filters.states) {
      const stateArray = filters.states.split(",");
      newFilteredSchools = newFilteredSchools.filter((s) =>
        s.mosads.find(
          (m) => m.address && stateArray.indexOf(m.address.state || -1) >= 0,
        ),
      );
    }
    if (filters.association !== "All") {
      const fullTimeOnly = filters.association === "FullTime";
      if (fullTimeOnly) {
        newFilteredSchools = newFilteredSchools.filter((s) =>
          s.mosads.find((m) => m.isFullTime),
        );
      } else {
        newFilteredSchools = newFilteredSchools.filter((s) =>
          s.mosads.every((m) => !m.isFullTime),
        );
      }
    }

    if (filters.status === "ActiveOnly") {
      newFilteredSchools = newFilteredSchools.filter(
        (s) => s.isActive === "True",
      );
    }

    this.setState({
      filteredSchools: newFilteredSchools,
      currentFilters: filters,
    });
  }

  messageButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayError: "" }),
    });
    return buttons;
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return;
    }

    this.setState({ loading: true }, () =>
      SchoolApi.list()
        .then((response) => {
          this.setState(
            {
              schools: response.data.results,
              filteredSchools: response.data.results,
              loading: false,
            },
            () => this.handleFiltersChanged(this.state.currentFilters),
          );
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  componentWillReceiveProps(newProps) {
    const newFilterState = {
      /*...this.state.currentFilters,*/ ...EmptySchoolFilter(),
      name: newProps.location.query.q,
    };
    this.handleFiltersChanged(newFilterState);
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasEditPermission = AuthService.UserHasClaim(
      PermissionClaims.DirectoryEdit,
    );
    const { filteredSchools, currentFilters } = this.state;

    return (
      <div className="col-sm-12">
        <SchoolListPage
          schools={filteredSchools}
          isAdvancedSearch={false}
          currentFilters={currentFilters}
          hasEditPermission={hasEditPermission}
          handleFiltersChanged={this.handleFiltersChanged}
        />

        {this.state.displayError && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayError}
          />
        )}

        {this.state.loading && <Loader />}
      </div>
    );
  }
}
