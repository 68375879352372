import React from "react";
import CheckboxList from "../../../shared/CheckboxList";

export default class ProgramOrgInvolvementDemographics extends React.PureComponent {
  onChangeProgramParticipationCheckbox = (name, value, checked) => {
    const {
      demographics: { programParticipationTypes = [] },
      onChange,
      studentProgramParticipationTypes,
    } = this.props;

    let updatedProgramParticipationTypes = [...programParticipationTypes];
    if (checked) {
      const checkedType = studentProgramParticipationTypes.find(
        (t) => t.id.toString() === value.toString(),
      );
      updatedProgramParticipationTypes.push(checkedType);
    } else {
      updatedProgramParticipationTypes =
        updatedProgramParticipationTypes.filter(
          (t) => t.id.toString() !== value.toString(),
        );
    }

    onChange(name, updatedProgramParticipationTypes);
  };

  onChangeOrgInvolvementCheckbox = (name, value, checked) => {
    const {
      demographics: { orgInvolvementTypes = [] },
      onChange,
      studentOrgInvolvementTypes,
    } = this.props;

    let updatedOrgInvolvementTypes = [...orgInvolvementTypes];
    if (checked) {
      const checkedType = studentOrgInvolvementTypes.find(
        (t) => t.id.toString() === value.toString(),
      );
      updatedOrgInvolvementTypes.push(checkedType);
    } else {
      updatedOrgInvolvementTypes = updatedOrgInvolvementTypes.filter(
        (t) => t.id.toString() !== value.toString(),
      );
    }

    onChange(name, updatedOrgInvolvementTypes);
  };

  render() {
    const {
      demographics: {
        orgInvolvementTypes = [],
        programParticipationTypes = [],
      },
      studentOrgInvolvementTypes,
      studentProgramParticipationTypes,
    } = this.props;

    return (
      <React.Fragment>
        <div className="student-profile-form-section">
          <div className="profile-section-title">
            Other Program Participation
            <p className="small-text accent-text mt-8">
              Check all that apply; all programs in the Chabad on Campus portal
              are automatically imported
            </p>
          </div>
          <CheckboxList
            name="programParticipationTypes"
            onChange={this.onChangeProgramParticipationCheckbox}
            options={
              studentProgramParticipationTypes &&
              studentProgramParticipationTypes.map((t) => ({
                display: t.description,
                value: t.id,
              }))
            }
            values={
              programParticipationTypes
                ? programParticipationTypes.map((t) => t.id)
                : []
            }
          />
        </div>
        <div className="student-profile-form-section">
          <div className="profile-section-title">
            Org Involvement
            <p className="small-text accent-text mt-8">Check all that apply</p>
          </div>
          <CheckboxList
            name="orgInvolvementTypes"
            onChange={this.onChangeOrgInvolvementCheckbox}
            options={
              studentOrgInvolvementTypes &&
              studentOrgInvolvementTypes.map((t) => ({
                display: t.description,
                value: t.id,
              }))
            }
            values={
              orgInvolvementTypes ? orgInvolvementTypes.map((t) => t.id) : []
            }
          />
        </div>
      </React.Fragment>
    );
  }
}
