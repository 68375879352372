const countries = [
  { value: "USA", label: "United States" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belarus", label: "Belarus" },
  { value: "Brazil", label: "Brazil" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "China", label: "China" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Estonia", label: "Estonia" },
  { value: "France", label: "France" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Grenada", label: "Grenada" },
  { value: "Hungary", label: "Hungary" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Netherlands Antilles", label: "Netherlands Antilles" },
  { value: "Poland", label: "Poland" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Scotland", label: "Scotland" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "South Africa", label: "South Africa" },
  { value: "Spain", label: "Spain" },
];

const CountriesApi = {
  getList: () => {
    return countries;
  },
};

export default CountriesApi;
