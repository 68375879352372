import React from "react";
import USStatesApi from "../../../../../services/resources/USStatesApi";
import Select from "../../../../shared/Select";
import _cloneDeep from "lodash.clonedeep";

export default class RaffleOrderAddress extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      states: [],
      countries: [],
    };
    state.initialState = _cloneDeep(state);
    this.state = state;
  }
  componentDidMount() {
    this.setState({
      states: USStatesApi.getList().map((c) => ({
        id: c.value,
        name: c.label,
      })),
    });
  }
  render() {
    const {
      address: { address1, address2, city, state, zip, country },
      onChangeEventOrder,
      onChangeOrder,
      countries,
    } = this.props;

    return (
      <div>
        <p className="medium-text mt-24">Billing Address</p>
        <div className="new-order-modal-grid mb-24">
          <div className="flex flex-justify-space flex-align-center mr-8 relative">
            <label className="accent-text small-text">Street Address</label>
            <input
              type="text"
              className="custom-input"
              name="address.address1"
              onChange={onChangeEventOrder}
              value={address1}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center ml-8 relative">
            <label className="accent-text small-text">Street Address 2</label>
            <input
              type="text"
              className="custom-input"
              name="address.address2"
              onChange={onChangeEventOrder}
              value={address2}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center mr-8 relative">
            <label className="accent-text small-text">City</label>
            <input
              type="text"
              className="custom-input"
              name="address.city"
              onChange={onChangeEventOrder}
              value={city}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center ml-8 relative">
            <label className="accent-text small-text">State</label>
            <Select
              className="custom-input"
              name="address.state"
              clearable={true}
              onChange={(name, value) => onChangeOrder(name, value)}
              options={this.state.states.map((item) => ({
                value: item.id,
                display: item.name,
              }))}
              placeholder="Select state"
              value={state}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center mr-8 relative">
            <label className="accent-text small-text">Zip</label>
            <input
              type="text"
              className="custom-input"
              name="address.zip"
              onChange={onChangeEventOrder}
              value={zip}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center ml-8 relative">
            <label className="accent-text small-text">Country</label>
            <Select
              className="custom-input"
              name="address.country"
              clearable={true}
              onChange={(name, value) => onChangeOrder(name, value)}
              options={countries.map((item) => ({
                value: item.name,
                display: item.name,
              }))}
              placeholder="Select country"
              value={country}
            />
          </div>
        </div>
      </div>
    );
  }
}
