import React, { memo } from "react";

function RegistrationsTableHeader({
  registrationType,
  showApplicationDetails,
  showApprovalDetails,
  showEnrolled,
  showPaymentDetails,
  templateColumns,
}) {
  const generateColumns = () => {
    const conditionsToDisplay = [
      {
        condition: registrationType !== "Student",
        name: "Shliach/Shlucha",
      },
      {
        condition: registrationType !== "Student" && showEnrolled,
        name: "Chabad House",
      },
      {
        condition: registrationType !== "Student" && !showEnrolled,
        name: "Shliach Email/Shlucha Email",
      },
      {
        condition: registrationType !== "Student" && !showEnrolled,
        name: "Shliach Cell/Shlucha Cell",
      },
      {
        condition: registrationType === "Student",
        name: "Student",
      },
      {
        condition: true,
        name: "Campus",
      },
      {
        condition: !showEnrolled,
        name: "City/State/Country",
      },
      {
        condition: !showEnrolled || showApplicationDetails,
        name: !showEnrolled ? "Shliach/Shlucha Status" : "Status",
      },
      {
        condition: showEnrolled && showApplicationDetails,
        name: "Date of Application",
      },
      {
        condition:
          showEnrolled && showApplicationDetails && showApprovalDetails,
        name: "Date of Approval",
      },
      {
        condition: showEnrolled && showApplicationDetails && showPaymentDetails,
        name: "Installments",
      },
      {
        condition: showEnrolled && showApplicationDetails && showPaymentDetails,
        name: "Last Payment Date",
      },
      {
        condition: showEnrolled && showApplicationDetails && showPaymentDetails,
        name: "Total Paid",
      },
      {
        condition: showEnrolled && !showApplicationDetails,
        name: "Registered On",
      },
      {
        condition: showEnrolled,
        name: "",
      },
    ];

    const columnsToAdd = conditionsToDisplay
      .filter(({ condition }) => condition)
      .map(({ name }) => name);

    return columnsToAdd;
  };

  const columns = generateColumns();

  return (
    <div
      className="program-registrations-table-header"
      style={{ gridTemplateColumns: templateColumns }}
    >
      {columns.map((column, index) => (
        <p className="flex flex-align-center" key={index}>
          {column}
        </p>
      ))}
    </div>
  );
}

export default memo(RegistrationsTableHeader);
