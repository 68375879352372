import React, { memo } from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import { flattenArray } from "../../../../lib";

function LocationsTableActions(props) {
  const {
    canAddLocation,
    getLocationsForExport,
    grants,
    onAddLocation,
    onImportLocations,
    programScheduleName,
    readOnly,
  } = props;

  const getExportHeaders = (data) => {
    const maxEmailRecipientsCount = Math.max(
      ...data.map((d) => d.emailRecipients?.length || 0)
    );
    return [
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shlucha ID", value: "shluchaID" },
      { label: "Shlucha First Name", value: "shluchaFirstName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Cell", value: "shliachCell" },
      { label: "Shlucha Email", value: "shluchaEmail" },
      { label: "Shlucha Cell", value: "shluchaCell" },
      ...grants.map((g) => ({
        label: g.name,
        value: ({ allocations }) =>
          ((allocations && allocations.find((a) => a.grantID === g.id)) || "")
            .quantity || 0,
      })),
      ...flattenArray(
        Array.from({ length: maxEmailRecipientsCount }, (_, index) => [
          {
            label: `Recipient ${index + 1} First Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.firstName) || "",
          },
          {
            label: `Recipient ${index + 1} Last Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.lastName) || "",
          },
          {
            label: `Recipient ${index + 1} Email`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.email) || "",
          },
        ])
      ),
    ];
  };

  return (
    <div className="flex">
      {!readOnly && (
        <React.Fragment>
          <div
            className="tooltip-container flex flex-align-center"
            style={{ height: "auto" }}
          >
            <button
              className="btn custom-btn btn-accent flex flex-align-center mr-16"
              disabled={!canAddLocation}
              onClick={onImportLocations}
            >
              Import Locations
            </button>
            <button
              className="btn custom-btn btn-accent flex flex-align-center mr-16"
              disabled={!canAddLocation}
              onClick={onAddLocation}
            >
              Add
            </button>
            {!canAddLocation && (
              <span className="tooltip tooltip-right">
                Locations cannot be added before the schedule start date or
                after final grant payment has been created
              </span>
            )}
          </div>
        </React.Fragment>
      )}
      <ExportCSVButton
        className="btn custom-btn btn-accent flex flex-align-center"
        fileName={`${(programScheduleName || "Lamplighters").replace(
          / /g,
          "_"
        )}_Locations`}
        getExportData={getLocationsForExport}
        getHeaders={getExportHeaders}
      />
    </div>
  );
}

export default memo(LocationsTableActions);
