import React from "react";
import { Link } from "react-router";
import {
  formatCurrency,
  formatDateTimeForInput,
  formatFullName,
  formatNumber,
} from "../../../../../lib";
import moment from "moment";

export default class EnrollmentPricing extends React.PureComponent {
  render() {
    const {
      enrollment: {
        chabadHouseName,
        enrolledByShliachID,
        shliachFirstName,
        shliachLastName,
        latestCancellationDateOverride,
        promoCodes,
        specialInstructions,
        studentRegistrationEndDateOverride,
        tracks,
      },
      tripEvent: {
        latestCancellationDate,
        studentRegistrationEndDate,
        tracks: eventTracks,
      },
    } = this.props;

    return (
      <div>
        <div>
          <Link
            className="xl-text link-text-secondary fw-700 mb-0"
            to={`/shluchim/${enrolledByShliachID}`}
          >
            {formatFullName(shliachFirstName, shliachLastName)}
          </Link>
          <p className="medium-text mb-32">{chabadHouseName}</p>
        </div>
        <div className="trip-page-form-section">
          <p className="medium-text fw-700 mb-16">Pricing</p>
          <p className="accent-text-dark mb-24">
            You can keep the default student registration prices and continue or
            customize your own student registration pricing.
          </p>
          <div className="trip-form-pricing-inputs">
            {tracks.map((track) => {
              const eventTrack = eventTracks.find(
                ({ id }) => id === track.trackID,
              );
              return (
                <React.Fragment key={track.id}>
                  <div className="flex flex-align-center mobile-block mb-16">
                    <label className="accent-text">
                      {track.trackName} Early Bird
                    </label>
                    <input
                      className="custom-input dollar-input"
                      readOnly
                      type="number"
                      value={
                        track.earlyBirdPriceOverride !== ""
                          ? track.earlyBirdPriceOverride
                          : eventTrack.earlyBirdStudentPrice || ""
                      }
                    />
                    {track.earlyBirdPriceOverride !== "" &&
                      (track.earlyBirdPriceOverride <
                        eventTrack.earlyBirdStudentPrice ||
                        track.earlyBirdPriceOverride >
                          eventTrack.earlyBirdStudentPrice) && (
                        <span>
                          * You set the price{" "}
                          {track.earlyBirdPriceOverride <
                          eventTrack.earlyBirdStudentPrice
                            ? "lower"
                            : "higher"}{" "}
                          than COCI's price of $
                          {formatCurrency(eventTrack.earlyBirdStudentPrice)}
                        </span>
                      )}
                  </div>
                  <div className="flex flex-align-center mobile-block mb-16">
                    <label className="accent-text">
                      {track.trackName} Regular
                    </label>
                    <input
                      className="custom-input dollar-input"
                      readOnly
                      type="number"
                      value={
                        track.regularPriceOverride !== ""
                          ? track.regularPriceOverride
                          : eventTrack.regularStudentPrice || ""
                      }
                    />
                    {track.regularPriceOverride !== "" &&
                      (track.regularPriceOverride <
                        eventTrack.regularStudentPrice ||
                        track.regularPriceOverride >
                          eventTrack.regularStudentPrice) && (
                        <span>
                          * You set the price{" "}
                          {track.regularPriceOverride <
                          eventTrack.regularStudentPrice
                            ? "lower"
                            : "higher"}{" "}
                          than COCI's trip fee of $
                          {formatCurrency(eventTrack.regularStudentPrice)}
                        </span>
                      )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <div className="trip-page-form-section">
          <p className="medium-text fw-700 mb-16 flex flex-align-center">
            Student Promo Codes
          </p>
          <p className="accent-text-dark mb-24">
            If you add any promo codes that will set the price lower than the
            trip fee, you will be required to pay the difference.
          </p>
          <div className="promo-codes-grid">
            {promoCodes.length > 0 ? (
              promoCodes.map((promoCode, index) => (
                <div
                  className="hover-delete-container flex flex-align-center full-width"
                  key={index}
                >
                  <div className="promo-codes-grid-row full-width">
                    <p className="fw-700">{promoCode.code}</p>
                    <p>{promoCode.appliesTo}</p>
                    <p>${formatCurrency(promoCode.discountAmount)} Off</p>
                    <p>
                      {promoCode.expirationDate
                        ? `Expires ${moment(promoCode.expirationDate).format(
                            "MM/DD/YYYY",
                          )}`
                        : ""}
                    </p>
                    <p>
                      {promoCode.threshold
                        ? `${formatNumber(
                            promoCode.threshold - promoCode.counter,
                          )} Avail`
                        : ""}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <span>No promo codes</span>
            )}
          </div>
        </div>
        <div className="trip-page-form-section">
          <p className="medium-text fw-700 mb-16">Special Instructions</p>
          <p className="accent-text-dark mb-16">
            Enter any special instructions that you have for your students
            (custom travel arrangements and public promo codes, etc) that you
            want them to see on the registration form.
          </p>
          <textarea
            className="custom-input"
            readOnly
            value={specialInstructions}
          />
        </div>
        <div className="trip-page-form-section">
          <p className="medium-text fw-700 mb-16">Dates</p>
          <p className="accent-text-dark mb-16">
            These are the dates set by admin. You can make them earlier, not
            later.
          </p>
          <div className="trip-form-dates-inputs">
            <div className="flex flex-align-center mobile-block mb-16">
              <label className="accent-text">
                Registration End
                <span className="small-text ml-8">(EST)</span>
              </label>
              <input
                className="custom-input"
                readOnly
                type="datetime-local"
                defaultValue={formatDateTimeForInput(
                  studentRegistrationEndDateOverride ||
                    studentRegistrationEndDate,
                )}
              />
            </div>
            <div className="flex flex-align-center mobile-block mb-16">
              <label className="accent-text">
                Latest Cancellation
                <span className="small-text ml-8">(EST)</span>
              </label>
              <input
                className="custom-input"
                readOnly
                type="datetime-local"
                defaultValue={formatDateTimeForInput(
                  latestCancellationDateOverride || latestCancellationDate,
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
