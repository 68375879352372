import React from "react";

import FormInput from "../../Form/FormInput";

export default class SocialInfo extends React.PureComponent {
  state = {
    socialInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        socialInfoShowing: false,
      });
    }
  }

  render() {
    const { errors, touched, readOnly } = this.props;
    return (
      <div
        className={
          this.state.socialInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-48 mb-16 flex flex-align-center"
          onClick={() => {
            this.setState({
              socialInfoShowing: !this.state.socialInfoShowing,
            });
          }}
        >
          Social Media
          <span className="accent-text small-text ml-8">(optional)</span>
        </p>
        <div className="profile-form-social-info profile-form-inputs">
          <div>
            <FormInput
              label="Instagram"
              type="text"
              name="instagramURL"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div>
            <FormInput
              label="Twitter"
              type="text"
              name="twitterURL"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div>
            <FormInput
              label="Facebook"
              type="text"
              name="facebookURL"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          {/* <div>
            <FormInput
              label="Snapchat"
              type="text"
              name="snapchatURL"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div> */}
        </div>
      </div>
    );
  }
}
