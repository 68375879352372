import React from "react";
import AddToCourseModal from "./AddToCourseModal";
import ConfirmationModal from "../../../../../shared/ConfirmationModal";
import PaginatedTable from "../../../../../shared/PaginatedTable";
import StudentCoursesTableHeader from "./StudentCoursesTableHeader";
import StudentCoursesTableRow from "./StudentCoursesTableRow";

import EduApi from "../../../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../../../lib/coc-common-scripts";
import axios from "axios";
import { formatFullName, formatNumber } from "../../../../../../lib";

export default class StudentCoursesTable extends React.PureComponent {
  state = {
    cancelCourseEnrollment: null,
    cancelCourseEnrollmentErrorMessage: "",
    cancelCourseEnrollmentLoading: false,
    courses: [],
    errorMessage: "",
    loading: false,
    page: 1,
    results: 12,
    showAddToCourseModal: false,
    showCancelCourseEnrollmentModal: false,
    sortBy: "date",
    success: true,
    totalCourses: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getCourses = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        success: true,
      },
      () => {
        EduApi.getEduStudentCourses(
          this.apiSignal.token,
          this.props.eduStudent.eduStudentEnrollmentID,
          page,
          results,
          sortBy,
        )
          .then(({ courses, totalCourses }) => {
            this.setState({
              courses,
              loading: false,
              totalCourses,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                courses: [],
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
                totalCourses: 0,
              });
            }
          });
      },
    );
  };

  getCoursesProgressWidth = (numCompleted, numEnrolled) => {
    const width = (numCompleted / numEnrolled) * 100;
    return width + "%";
  };

  cancelCourseEnrollment = () => {
    this.setState(
      {
        cancelCourseEnrollmentErrorMessage: "",
        cancelCourseEnrollmentLoading: true,
      },
      () => {
        const {
          cancelCourseEnrollment: { eduStudentCourseScheduleEnrollmentID },
        } = this.state;
        EduApi.removeEduStudentFromCourse(
          this.apiSignal.token,
          eduStudentCourseScheduleEnrollmentID,
        )
          .then(() => {
            this.setState({
              cancelCourseEnrollment: null,
              cancelCourseEnrollmentLoading: false,
              showCancelCourseEnrollmentModal: false,
            });
            this.getCourses();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                cancelCourseEnrollmentErrorMessage:
                  ApiCallErrorMessageHandler(err),
                cancelCourseEnrollmentLoading: false,
              });
            }
          });
      },
    );
  };

  render() {
    const {
      eduStudent: { numOfCoursesCompleted, student, status },
      viewOnly,
    } = this.props;

    const { firstName, lastName } = student.person;

    const {
      cancelCourseEnrollment,
      cancelCourseEnrollmentErrorMessage,
      cancelCourseEnrollmentLoading,
      courses,
      loading,
      page,
      results,
      showCancelCourseEnrollmentModal,
      success,
      totalCourses,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space mb-24">
          <p className="fw-500 xxl-text mb-24">Course History</p>
          <div className="flex flex-align-center">
            <div className="student-profile-courses-progress">
              <div className="flex flex-justify-space flex-align-center">
                <p className="fw-700">Courses Completed</p>
                <p>{formatNumber(numOfCoursesCompleted)}/8</p>
              </div>
              <div
                className="student-courses-progress-bar relative full-width mt-8"
                style={{ height: "12px" }}
              >
                <div className="progress-bar-outer absolute full-width" />
                <div
                  className="progress-bar-inner absolute"
                  style={{
                    width: this.getCoursesProgressWidth(
                      numOfCoursesCompleted > 8 ? 8 : numOfCoursesCompleted,
                      8,
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {!viewOnly && (
          <div className="flex flex-justify-end">
            <p
              className="accent-text-secondary fw-700 uppercase-text link-text"
              onClick={() =>
                this.setState({
                  showAddToCourseModal: !this.state.showAddToCourseModal,
                })
              }
            >
              + Add to course
            </p>
            {this.state.showAddToCourseModal && (
              <AddToCourseModal
                close={() => this.setState({ showAddToCourseModal: false })}
                getCourses={this.getCourses}
                name={`${firstName} ${lastName}`}
                studentID={this.props.eduStudent.eduStudentEnrollmentID}
                show={this.state.showAddToCourseModal}
              />
            )}
          </div>
        )}
        <PaginatedTable
          loading={loading}
          loadData={this.getCourses}
          page={page}
          records={courses}
          renderHeader={() => <StudentCoursesTableHeader />}
          renderRow={(course, index) => (
            <StudentCoursesTableRow
              studentEnrollmentStatus={status}
              course={course}
              key={index}
              removeCourseEnrollment={() => {
                this.setState({
                  cancelCourseEnrollment: course,
                  showCancelCourseEnrollmentModal: true,
                });
              }}
              viewOnly={viewOnly}
            />
          )}
          results={results}
          showResultsCount={true}
          success={success}
          totalCount={totalCourses}
        />
        <ConfirmationModal
          cancel={() =>
            this.setState({
              cancelCourseEnrollment: null,
              cancelCourseEnrollmentErrorMessage: "",
              cancelCourseEnrollmentLoading: false,
              showCancelCourseEnrollmentModal: false,
            })
          }
          confirm={this.cancelCourseEnrollment}
          errorMessage={cancelCourseEnrollmentErrorMessage}
          loading={cancelCourseEnrollmentLoading}
          message={`Are you sure you'd like to cancel ${formatFullName(
            firstName,
            lastName,
          )}'s enrollment in ${
            cancelCourseEnrollment
              ? cancelCourseEnrollment.courseScheduleName
              : "this course schedule"
          }?`}
          show={showCancelCourseEnrollmentModal}
        />
      </div>
    );
  }
}
