import React from "react";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import TourScheduleModal from "./TourScheduleModal";
import moment from "moment";

export default class TourSchedulesTable extends React.PureComponent {
  state = {
    currentScheduleIndex: null,
    showDeleteScheduleConfirmationModal: false,
    showTourScheduleModal: false,
  };

  onAddSchedule = () => {
    this.setState({
      currentScheduleIndex: null,
      showTourScheduleModal: true,
    });
  };

  onDeleteSchedule = (index) => {
    this.setState({
      currentScheduleIndex: index,
      showDeleteScheduleConfirmationModal: true,
    });
  };

  onUpdateSchedule = (index) => {
    this.setState({
      currentScheduleIndex: index,
      showTourScheduleModal: true,
    });
  };

  addSchedule = (newSchedule) => {
    const { onChange, schedules } = this.props;

    const newSchedules = [...schedules, newSchedule];
    onChange("schedules", newSchedules);

    this.setState({
      currentScheduleIndex: null,
      showTourScheduleModal: false,
    });
  };

  deleteSchedule = () => {
    const { onChange, schedules } = this.props;
    const { currentScheduleIndex } = this.state;

    const newSchedules = [...schedules];
    newSchedules.splice(currentScheduleIndex, 1);
    onChange("schedules", newSchedules);

    this.setState({
      currentScheduleIndex: null,
      showDeleteScheduleConfirmationModal: false,
    });
  };

  updateSchedule = (updatedSchedule) => {
    const { onChange, schedules } = this.props;
    const { currentScheduleIndex } = this.state;

    const newSchedules = [...schedules];
    newSchedules[currentScheduleIndex] = updatedSchedule;
    onChange("schedules", newSchedules);

    this.setState({
      currentScheduleIndex: null,
      showTourScheduleModal: false,
    });
  };

  render() {
    const {
      hasStartedShluchimEnrollment,
      schedules,
      tripEndDate,
      tripStartDate,
    } = this.props;

    const {
      currentScheduleIndex,
      showDeleteScheduleConfirmationModal,
      showTourScheduleModal,
      submitAttempted,
    } = this.state;

    return (
      <div className="tour-details-form-bottom">
        <p className="medium-text fw-700 flex flex-align-center mb-24">
          Tour Schedules
          <i
            className="material-icons link-text ml-8"
            onClick={this.onAddSchedule}
          >
            add_circle
          </i>
        </p>
        <div className="tour-schedules-grid">
          <div className="tour-schedules-grid-header">
            <p>Name</p>
            <p>Max Capacity</p>
            <p>Group Size</p>
            <p>Date</p>
            <p>Time</p>
            <p />
          </div>
          {!!(schedules && schedules.length) ? (
            schedules.map((schedule, index) => (
              <div className="tour-schedules-grid-row" key={index}>
                <p>{schedule.name}</p>
                <p>
                  {schedule.maximumParticipants > 0 &&
                    schedule.maximumParticipants}
                </p>
                <p>
                  {schedule.groupSize > 0 &&
                    schedule.groupSize}
                </p>
                <p>{moment(schedule.time).format("MM/D")}</p>
                <p>{moment(schedule.time).format("h:mm A")}</p>
                <p className="flex flex-align-center flex-justify-end">
                  <i
                    className="material-icons large-text link-text-secondary"
                    onClick={() => this.onUpdateSchedule(index)}
                  >
                    edit
                  </i>
                  <i
                    className={`material-icons large-text ml-8 link-text-secondary ${hasStartedShluchimEnrollment && schedule.id
                      ? "disabled"
                      : ""
                      }`}
                    onClick={() => {
                      if (!hasStartedShluchimEnrollment || !schedule.id) {
                        this.onDeleteSchedule(index);
                      }
                    }}
                    title={
                      hasStartedShluchimEnrollment
                        ? "Schedules cannot be deleted once event registration has started"
                        : ""
                    }
                  >
                    delete
                  </i>
                </p>
              </div>
            ))
          ) : (
            <React.Fragment>
              <span>No schedules</span>
              {submitAttempted && (
                <span className="error-text">
                  At least one schedule is required
                </span>
              )}
            </React.Fragment>
          )}
        </div>

        <TourScheduleModal
          close={() => this.setState({ showTourScheduleModal: false })}
          hasStartedShluchimEnrollment={hasStartedShluchimEnrollment}
          save={
            currentScheduleIndex === null
              ? this.addSchedule
              : this.updateSchedule
          }
          schedule={schedules[currentScheduleIndex]}
          show={showTourScheduleModal}
          tripEndDate={tripEndDate}
          tripStartDate={tripStartDate}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({ showDeleteScheduleConfirmationModal: false })
          }
          confirm={this.deleteSchedule}
          message={`Are you sure you want to delete ${(schedules[currentScheduleIndex] &&
            schedules[currentScheduleIndex].name) ||
            "this"
            } schedule?`}
          show={showDeleteScheduleConfirmationModal}
        />
      </div>
    );
  }
}
