import React from "react";
import { Link } from "react-router";

import { /* CustomEvent, */ CustomEventTypes } from "../Utils";

// interface EventsListProps {
//   events: Array<CustomEvent>;
// }

const EventsList = ({ events }) => {
  const renderTableHeader = () => {
    return (
      <tr>
        <th>Name</th>
        <th>Event Type</th>
      </tr>
    );
  };
  /** @param {CustomEvent} event */
  const renderTableBody = (event) => {
    return (
      <tr key={event.id}>
        <td>
          <Link
            to={`/config/customevents/${event.id}`}
            style={{ cursor: "pointer" }}
          >
            {event.name}
          </Link>
        </td>
        <td>{CustomEventTypes[event.type] || "-"}</td>
      </tr>
    );
  };

  return (
    <table className="table">
      <thead>{renderTableHeader()}</thead>
      <tbody>{events.map(renderTableBody)}</tbody>
    </table>
  );
};

export default EventsList;
