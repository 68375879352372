import React from "react";
import Loader from "../../common/Loader";
import Modal from "react-modal";
import SelectSearch from "react-select";

export default class AddLocationModal extends React.Component {
  state = {
    errorMessage: "",
    loading: false,
    newLocation: null,
  };

  onClose = () => {
    this.setState(
      {
        errorMessage: "",
        loading: false,
        newLocation: null,
      },
      this.props.close,
    );
  };

  onSelectChabadHouse = (newLocation) => {
    this.setState({ errorMessage: "", newLocation });
  };

  onSubmit = async () => {
    const { newLocation } = this.state;

    if (!newLocation) {
      return;
    }

    this.setState({
      errorMessage: "",
      loading: true,
    });

    const { error, errorMessage } = await this.props.submit(newLocation.value);
    if (error) {
      this.setState({
        errorMessage,
        loading: false,
      });
      return;
    }

    this.onClose();
  };

  render() {
    const {
      chabadHousesList,
      chabadHousesListErrorMessage,
      chabadHousesListLoading,
    } = this.props;

    const { errorMessage, loading, newLocation } = this.state;

    return (
      <Modal isOpen={true} className="modal-container">
        <div className="modal-card card add-shliach-modal medium-modal">
          <p className="large-text fw-500 mb-16">Add Location</p>
          <SelectSearch
            disabled={loading || chabadHousesListLoading}
            isClearable={true}
            isSearchable={true}
            onChange={this.onSelectChabadHouse}
            options={chabadHousesList.map((ch) => ({
              value: ch.chabadHouseID,
              label: `${ch.chabadHouseName} - ${ch.campusName} - ${[
                ch.shliachFirstName,
                ch.shluchaFirstName,
              ]
                .filter((n) => n)
                .join(" & ")} ${ch.shliachLastName}`,
            }))}
            placeholder={
              chabadHousesListLoading
                ? "Loading Locations..."
                : "Select Chabad House"
            }
            style={{
              height: "36px",
              width: "100%",
              border: "1px solid #edecec",
              fontSize: "14px",
            }}
            value={newLocation}
          />
          {chabadHousesListErrorMessage && (
            <p className="error-text">{chabadHousesListErrorMessage}</p>
          )}
          <div className="text-right mt-24">
            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <>
                <button
                  className="btn link-text uppercase-text"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn link-text uppercase-text ml-24"
                  disabled={!newLocation}
                  onClick={this.onSubmit}
                >
                  Add
                </button>
              </>
            )}
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </Modal>
    );
  }
}
