import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import Loader from "../../../common/Loader";
import moment from "moment";

export default class ExportInteractionsButton extends React.Component {
  render() {
    const { chabadHouseName, getInteractions } = this.props;

    return (
      <ExportCSVButton
        className="export-interactions-button ml-4"
        errorMessageClassName="export-interactions-button-error-message"
        busyTitle={<Loader />}
        fileName={`Student_Activity_${chabadHouseName.replace(/ /g, "_")}`}
        getExportData={getInteractions}
        headers={[
          { label: "Type", value: "type" },
          { label: "Category", value: "category" },
          { label: "Classification", value: "classification" },
          { label: "Name", value: "name" },
          {
            label: "Date",
            value: ({ dateTime }) =>
              dateTime ? moment(dateTime).format("MM/DD/YYYY") : "",
          },
          {
            label: "Israel/Antisemitism Related",
            value: ({ isIsraelRelated }) => (isIsraelRelated ? "Yes" : "No"),
          },
          { label: "Location", value: "locationDisplay" },
          { label: "Duration", value: "durationDisplay" },
          { label: "Notes", value: "notes" },
          {
            label: "Mezuzah from MyZuzah",
            value: ({ isMezuzahFromMyZuzah, type }) =>
              type === "Affix Mezuzah"
                ? isMezuzahFromMyZuzah
                  ? "Yes"
                  : "No"
                : "",
          },
          {
            label: "Educators",
            value: ({ educators }) =>
              educators
                ? educators
                    .map((e) => `${e.firstName} ${e.lastName}`)
                    .join(", ")
                : "",
          },
          {
            label: "Students",
            value: ({ students }) =>
              students
                ? students.map((s) => `${s.firstName} ${s.lastName}`).join(", ")
                : "",
          },
          { label: "Chabad House ID", value: "chabadHouseID" },
          { label: "Chabad House Name", value: "chabadHouseName" },
          { label: "Campus ID", value: "campusID" },
          { label: "Campus Name", value: "campusName" },
          { label: "Shliach ID", value: "shliachID" },
          { label: "Shliach First Name", value: "shliachFirstName" },
          { label: "Shliach Last Name", value: "shliachLastName" },
          { label: "Shlucha ID", value: "shluchaID" },
          { label: "Shlucha First Name", value: "shluchaFirstName" },
        ]}
        title={<i className="material-icons xl-text link-text">download</i>}
      />
    );
  }
}
