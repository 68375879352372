import React from "react";
import Select from "react-select";
import { EnumToArrayOfObjects } from "../../../lib/coc-common-scripts";

// import { Country, State, ReactSelectOption } from "../../../models/Common";
import { /* Address, */ EmptyAddress } from "../../../models/Contact";
import {
  /* Organization, OrganizationCategory, */ EmptyOrganization,
  CategoryTypes,
} from "../../../models/Organization";
import AddressUI from "../../common/Address/Address";

const ddStyles = {
  paddingBottom: "3px",
};

// interface OrganizationManagerProps {
//   organizationData: Organization;
//   organizationCategories: Array<OrganizationCategory>;
//   countries: Array<Country>;
//   states: Array<State>;
//   handleOrganizationChanged: (newAddressState: Organization) => void;
// }

const OrganizationManager = ({
  organizationData = EmptyOrganization(),
  organizationCategories = [],
  countries,
  states,
  handleOrganizationChanged,
}) => {
  const categoryTypeId =
    (organizationData &&
      organizationData.organizationCategoryType &&
      +organizationData.organizationCategoryType) ||
    organizationData.category.type;
  const orgCategoriesFiltered = organizationCategories
    .filter((oc) => oc.type === categoryTypeId)
    .map((oc) => ({ value: oc.id, label: oc.name }));

  const updateOrganizationDomElement = (evt) => {
    const category =
      evt.currentTarget.name === "organizationCategoryType"
        ? organizationCategories.find(
            (oc) => oc.type === +evt.currentTarget.value,
          )
        : organizationData.category;
    const newOrganization = {
      ...organizationData,
      category,
      [evt.currentTarget.name]: evt.currentTarget.value,
    };
    handleOrganizationChanged(newOrganization);
  };
  /** @param {Address} newAddress */
  const handleAddressChanged = (newAddress) => {
    const newOrganization = { ...organizationData, address: newAddress };
    handleOrganizationChanged(newOrganization);
  };
  /** @param {ReactSelectOption} newCategory */
  const handleCategoryChange = (newCategory) => {
    const newOrganization = {
      ...organizationData,
      category: organizationCategories.find(
        (oc) => oc.id === newCategory.value,
      ),
    };
    handleOrganizationChanged(newOrganization);
  };

  return (
    <div className="row">
      <div
        className="col-md-5"
        style={{ minWidth: "350px", marginRight: "20px" }}
      >
        <dl className="dl-horizontal">
          <dt>Name</dt>
          <dd style={ddStyles}>
            <input
              type="text"
              name="name"
              value={organizationData.name || ""}
              onChange={updateOrganizationDomElement}
              required={true}
            />
          </dd>
          <dt>Description</dt>
          <dd style={ddStyles}>
            <input
              type="text"
              name="description"
              value={organizationData.description || ""}
              onChange={updateOrganizationDomElement}
            />
          </dd>
          <dt>Category Type</dt>
          <dd style={ddStyles}>
            <select
              name="organizationCategoryType"
              value={categoryTypeId || ""}
              onChange={updateOrganizationDomElement}
            >
              {EnumToArrayOfObjects(CategoryTypes).map((ct) => (
                <option key={ct.code} value={ct.code}>
                  {ct.value}
                </option>
              ))}
            </select>
          </dd>
          {categoryTypeId !== CategoryTypes.Venue && (
            <div>
              <dt>Category</dt>
              <dd style={ddStyles}>
                <Select
                  name="categoryId"
                  options={orgCategoriesFiltered}
                  onChange={handleCategoryChange}
                  value={
                    (organizationData.category &&
                      organizationData.category.id) ||
                    ""
                  }
                  multi={false}
                  placeholder={""}
                  required={true}
                  style={{ minWidth: "150px" }}
                />
              </dd>
            </div>
          )}
          <dt>Website</dt>
          <dd style={ddStyles}>
            <input
              type="text"
              name="website"
              value={organizationData.website || ""}
              onChange={updateOrganizationDomElement}
            />
          </dd>
          {categoryTypeId === CategoryTypes.Venue && (
            <div>
              <dt>Rooms</dt>
              <dd style={ddStyles}>
                <input
                  type="number"
                  name="roomSpace"
                  value={organizationData.roomSpace || ""}
                  onChange={updateOrganizationDomElement}
                />
              </dd>
              <dt>Meeting space</dt>
              <dd style={{ ...ddStyles }}>
                <input
                  style={{ maxWidth: "70%" }}
                  type="number"
                  name="meetingSpace"
                  value={organizationData.meetingSpace || ""}
                  onChange={updateOrganizationDomElement}
                  step="1000"
                />{" "}
                sq ft
              </dd>
            </div>
          )}
          <dt>Notes</dt>
          <dd style={ddStyles}>
            <textarea
              name="notes"
              value={organizationData.notes || ""}
              onChange={updateOrganizationDomElement}
            />
          </dd>
        </dl>
      </div>
      <div className="col-md-5">
        <AddressUI
          addressData={organizationData.address || EmptyAddress()}
          required={false}
          countries={countries}
          states={states}
          ddStyles={ddStyles}
          handleAddressChanged={handleAddressChanged}
        />
      </div>
    </div>
  );
};

export default OrganizationManager;
