import React from "react";

export default class EnrollmentsTableHeader extends React.PureComponent {
  render() {
    const columns = ["Last Name", "Shliach", "Shlucha", "Phone", "Email"];

    return (
      <div className="life-insurance-not-enrolled-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
