import React from "react";

import { /* ContactMethod, */ EmptyContactMethod } from "../Models/Contact";
import { ContactMethodUI } from "./ContactMethod";
import { StyledContactMethod } from "../StyledComponents/StyledContactMethod";

// export interface ContactMethodListProps {
//   contactMethods: Array<ContactMethod>;
//   groupName: string;
//   defaultCountry?: string;
//   disabled: boolean;
//   handleContactMethodsChange: (newContactMethods: Array<ContactMethod>) => void;
// }

export const ContactMethodList = ({
  contactMethods = [],
  groupName = "defgrp",
  defaultCountry,
  disabled,
  handleContactMethodsChange,
}) => {
  /** @param {ContactMethod} updatedContactMethod */
  const handleDataChange = (updatedContactMethod) => {
    const newContactMethods = contactMethods.slice();
    const cmIx = newContactMethods.findIndex(
      (cm) => cm.id === updatedContactMethod.id,
    );
    newContactMethods.splice(cmIx, 1, updatedContactMethod);
    handleContactMethodsChange(
      ensurePrimaryExistForAllTypes(
        ensureSinglePrimary(updatedContactMethod, newContactMethods),
      ),
    );
  };
  /** @param {number} id */
  const handleRemoveContactMethod = (id) => {
    const newContactMethods = contactMethods.filter((cm) => cm.id !== id);
    handleContactMethodsChange(
      ensurePrimaryExistForAllTypes(newContactMethods),
    );
  };

  const handleAddContactMethod = (evt) => {
    evt.preventDefault();
    const newContactMethods = contactMethods.slice();
    newContactMethods.push({
      ...EmptyContactMethod(),
      id: -Math.floor(Math.random() * 100000 + 10000),
    });
    handleContactMethodsChange(
      ensurePrimaryExistForAllTypes(newContactMethods),
    );
  };
  /** @param {Array<ContactMethod>} contactMethodList
   * @returns {Array<ContactMethod>}
   */
  const ensurePrimaryExistForAllTypes = (contactMethodList) => {
    const newContactMethods = contactMethodList.slice();
    ensurePrimaryExistByType(newContactMethods, "EmailAddress");
    ensurePrimaryExistByType(newContactMethods, "CellPhoneNumber");
    ensurePrimaryExistByType(newContactMethods, "PhoneNumber");
    return newContactMethods;
  };
  /** @param {Array<ContactMethod>} contactMethodList
   * @param {"EmailAddress" | "CellPhoneNumber" | "PhoneNumber"} type
   * @returns {Array<ContactMethod>}
   */
  const ensurePrimaryExistByType = (contactMethodList, type) => {
    const newContactMethods = contactMethodList.filter(
      (cm) => cm.type === type,
    );
    const primaryCM = newContactMethods.find((cm) => cm.primary);
    if (!primaryCM && newContactMethods.length > 0) {
      contactMethodList.find((cm) => cm.type === type).primary = true;
    }
    return newContactMethods;
  };
  /** @param {ContactMethod} updatedContactMethod
   * @param {Array<ContactMethod>} contactMethodList
   * @returns {Array<ContactMethod>}
   */
  const ensureSinglePrimary = (updatedContactMethod, contactMethodList) => {
    if (!updatedContactMethod.primary) {
      return contactMethodList;
    }
    return contactMethodList.map((cm) =>
      cm.id === updatedContactMethod.id ||
      !cm.primary ||
      cm.type !== updatedContactMethod.type
        ? cm
        : { ...cm, primary: false },
    );
  };

  return (
    <div className="styled-list">
      <StyledContactMethod.Header>
        <li>Contact</li>
        <li>Type</li>
        <li>Primary</li>
        <li />
      </StyledContactMethod.Header>
      {contactMethods.map((contactMethod) => (
        <ContactMethodUI
          key={contactMethod.id}
          contactMethod={contactMethod}
          groupName={groupName}
          required={true}
          defaultCountry={defaultCountry}
          disabled={disabled}
          handleChange={handleDataChange}
          handleRemove={(evt) => {
            evt.preventDefault();
            handleRemoveContactMethod(contactMethod.id);
          }}
        />
      ))}
      {!disabled && (
        <StyledContactMethod.AddButton
          style={{ marginBottom: "0.5em" }}
          onClick={handleAddContactMethod}
        >
          + Add New
        </StyledContactMethod.AddButton>
      )}
    </div>
  );
};
