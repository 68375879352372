import React from "react";

export default class CopyToClipboardTextbox extends React.PureComponent {
  state = {
    copySuccessAlert: false,
  };
  mounted = false;

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  copyToClipboard = () => {
    var textarea = document.getElementById(this.props.id);
    if (textarea) {
      textarea.select();
      document.execCommand("copy");
      this.setState({ copySuccessAlert: true }, () => {
        setTimeout(() => {
          if (this.mounted) {
            this.setState({ copySuccessAlert: false });
          }
        }, 1000);
      });
    }
  };

  render() {
    const { className, id, label, style, text } = this.props;
    const { copySuccessAlert } = this.state;

    return (
      <div className={`copy-code-container ${className}`}>
        <textarea id={id} defaultValue={text} readOnly style={style} />
        {!copySuccessAlert ? (
          <p
            className="link-text flex flex-align-center flex-justify-end mb-0"
            style={{ whiteSpace: "nowrap", width: "104px" }}
          >
            <i className="material-icons" style={{ fontSize: "14px" }}>
              file_copy
            </i>
            <span className="small-text ml-4" onClick={this.copyToClipboard}>
              {label}
            </span>
          </p>
        ) : (
          <p
            className="accent-text small-text text-right mb-0"
            style={{ width: "104px" }}
          >
            Copied!
          </p>
        )}
      </div>
    );
  }
}
