import React from "react";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import _cloneDeep from "lodash.clonedeep";

import StudentsApi from "../../services/resources/StudentsApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import { notify } from "react-notify-toast";

export default class StudentsImportModal extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      fileForUpload: null,
      loading: false,
      submitAttempted: false,
      uploadProgress: 0,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  importStudentsTemplateURL =
    "https://cocistorage.blob.core.windows.net/templates/Student-Import-Template.xlsx";

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  selectFile = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      this.setState({
        errorMessage: "",
        fileForUpload: file,
      });
    }
  };

  uploadStudents = async () => {
    const { fileForUpload } = this.state;

    this.setState({
      errorMessage: "",
      submitAttempted: true,
    });

    if (!fileForUpload) {
      return;
    }

    this.setState({
      errorMessage: "",
      loading: true,
      uploadProgress: 0,
    });

    try {
      await StudentsApi.importStudents(
        this.apiSignal.token,
        fileForUpload,
        (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.setState({
            uploadProgress: progress > 100 ? 100 : progress,
          });
        },
      );

      this.props.reloadStudents();
      this.onClose();
      notify.show("Students have been successfully imported", "success");
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
          uploadProgress: 0,
        });
      }
    }
  };

  render() {
    const { show } = this.props;
    const {
      errorMessage,
      fileForUpload,
      loading,
      submitAttempted,
      uploadProgress,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div
          className="modal-card card import-modal"
          style={{ maxHeight: "96vh", overflow: "auto" }}
        >
          <p className="xl-text fw-700">Import Students</p>

          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Use this feature to upload a spreadsheet of multiple students.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            For this import only campus id, first name and last name are
            required, the rest of the information is optional.
          </p>
          <p className="accent-text-dark" style={{ lineHeight: "1.5" }}>
            Although not required for the import, we strongly encourage you to
            put in an email and phone number for data accuracy purposes.
          </p>
          <p className="error-text" style={{ lineHeight: "1.5" }}>
            Any student account imported here will be visible to Shluchim only
            and student will not be aware of their account and cannot register
            for events until they log in to the portal and finalize account
            creation.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Do you have the student import template? If not, you can{" "}
            <a
              download
              href={this.importStudentsTemplateURL}
              className="link-text"
            >
              download the template here
            </a>
            . When you’re ready, upload your file below.
          </p>
          <p className="accent-text mt-16" style={{ lineHeight: "1.5" }}>
            File must meet the following requirements before uploading: <br />
            * File must be saved in CSV or Excel format <br />
            * Delete any blank rows in your file <br />
            * Dates must be formatted with valid date formatting <br />* Please
            take notice of column header notes outlining column validation rules
          </p>

          <div className="mt-24">
            <p
              className={`fw-700 uppercase-text small-text accent-text-dark mb-8 ${
                submitAttempted && !fileForUpload ? "error-text" : ""
              }`}
            >
              Select Student Import file
            </p>
            <div className="flex mb-24 flex-justify-space">
              <Dropzone
                // accept={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onDrop={this.selectFile}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p className="uppercase-text fw-500 link-text">
                      {fileForUpload ? "Change " : "Select"} File
                    </p>
                  </div>
                )}
              </Dropzone>

              {fileForUpload && (
                <div className="uploading">
                  <div className="flex flex-justify-space flex-align-center">
                    <p className="fw-700 mr-8 mb-8 flex flex-align-center">
                      <i className="material-icons accent-text mr-8">
                        description
                      </i>
                      {fileForUpload.name}
                    </p>
                  </div>
                  <div className="percentage-bar relative">
                    <div className="bar" />
                    <div
                      className="filled-in"
                      style={{ width: `${uploadProgress}%` }}
                    />
                    <div className="labels flex flex-justify-space mt-8">
                      <p />
                      <p className="accent-text small-text">
                        {uploadProgress}%
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="raffle-modal-btns flex flex-justify-end flex-align-center mt-24">
            <button className="btn btn-light btn-medium" onClick={this.onClose}>
              Cancel
            </button>
            <button
              className="btn btn-accent btn-medium ml-16"
              disabled={loading}
              onClick={this.uploadStudents}
            >
              {!loading ? "Upload Students" : "Uploading..."}
            </button>
          </div>
          {submitAttempted && errorMessage && (
            <p className="small-text error-text text-right mt-4">
              {errorMessage}
            </p>
          )}
        </div>
      </Modal>
    );
  }
}
