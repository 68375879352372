import React from "react";
// import { Prompt } from "react-router";
import { formatValuesForForm } from "../../../lib";
import AuthService, { PermissionClaims } from "../../../services/AuthService";

import { Formik, Form } from "formik";

import Loader from "../Loader";

import GeneralInfo from "./GeneralInfo";
import StudiesInfo from "./StudiesInfo";
import PopulationInfo from "./PopulationInfo";
import JewishStudentLifeInfo from "./JewishStudentLifeInfo";

export default class School extends React.PureComponent {
  state = {
    campus: this.props.campus,
    loading: this.props.loading,
    submitError: {},
  };

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  validate = (values) => {
    const errors = {};
    //validate
    return errors;
  };

  require = (value) => (!value && value !== false ? true : "");

  render() {
    const { sys, saving, submitted } = this.props;
    const { loading, campus, submitError } = this.state;
    let setNew = !this.props.routeParams.schoolID;
    let newCampus = {
      name: "",
      nickname: "",
      programLevels: [],
      annualTuition: "",
      studentResidencyType: "Unknown",
      isInstitutionControlPublic: "",
      schoolCalendarType: "Unknown",
      studyAreas: [],
      graduatePopulation: "",
      undergradPopulation: "",
      jewishGraduatePopulation: "",
      jewishUndergradPopulation: "",
    };
    let readOnly = false;
    if (!AuthService.UserHasClaim(PermissionClaims.CampusEdit)) {
      readOnly = true;
    }
    return loading ? (
      <div className="card profile-form-card mr-24">
        <div>
          <Loader />
        </div>
      </div>
    ) : (
      <Formik
        initialValues={setNew ? newCampus : formatValuesForForm({ ...campus })}
        onSubmit={this.props.onSubmit}
      >
        {({ values, dirty, errors, touched, handleChange, setFieldValue }) => {
          return (
            <Form
              style={{ display: "flex", width: "100%" }}
              className={`school-form ${dirty ? "form-dirty" : ""} ${
                readOnly ? "read-only" : ""
              }`}
            >
              <div className="card profile-form-card mr-24">
                <div className="profile-form-sections">
                  <div className="profile-form-section">
                    <p className="accent-text small-text">
                      Review and edit school information. All information aside
                      from School recognition of Chabad will be displayed on the
                      public directory @ chabadoncampus.org/directory
                    </p>
                  </div>

                  <GeneralInfo
                    errors={errors}
                    handleChange={handleChange}
                    require={this.require}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    readOnly={readOnly}
                  />

                  <StudiesInfo
                    errors={errors}
                    require={this.require}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    readOnly={readOnly}
                  />

                  <PopulationInfo
                    errors={errors}
                    require={this.require}
                    touched={touched}
                    values={values}
                    sys={sys}
                    readOnly={readOnly}
                  />

                  <JewishStudentLifeInfo
                    handleChange={handleChange}
                    readOnly={readOnly}
                    sys={sys}
                    values={values}
                  />
                </div>
              </div>

              {readOnly ? null : (
                <div style={{ position: "relative" }}>
                  <div className="profile-save-btn-container">
                    <button
                      className="btn btn-accent profile-save-btn"
                      type="submit"
                      disabled={!dirty ? true : false}
                    >
                      {saving ? "Saving..." : "Save"}
                    </button>
                    {submitted === false ? (
                      <p className="error-message">
                        {submitError &&
                        submitError.data &&
                        submitError.status.toString()[0] === "4"
                          ? Object.values(submitError.data).map((e, index) => {
                              return <span key={index}>{e.toString()}</span>;
                            })
                          : "Something went wrong. Please try again"}
                      </p>
                    ) : submitted && !dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">check</i> All changes
                        saved
                      </p>
                    ) : dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">save</i>
                        Unsaved changes
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}
