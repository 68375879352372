import AuthClient from "./AuthClient";
import queryString from "query-string";

const EngagementApi = {
  getEngagementEnrollments: async (
    cancelToken,
    page,
    numResults,
    sortByOption,
    filters = {},
    exportTypeOption
  ) => {
    const params = {
      exportTypeOption,
      page,
      numResults,
      sortByOption,
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `EngagementEnrollments?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return {
      chabadHouses: response.data.payload.results || [],
      totalChabadHouses: response.data.payload.numberOfRows || 0,
    };
  },

  addEngagementEnrollment: async (chabadHouseId, cancelToken) => {
    const response = await AuthClient.post(
      `EngagementEnrollments?${queryString.stringify({ chabadHouseId })}`,
      { cancelToken },
    );
    return response.data.payload || {};
  },

  removeEngagementEnrollment: async (chabadHouseId, cancelToken) => {
    const response = await AuthClient.delete(
      `EngagementEnrollments?${queryString.stringify({ chabadHouseId })}`,
      { cancelToken },
    );
    return response.data.payload || {};
  },

  getJewishInterestAlgorithmRunDates: async () => {
    const response = await AuthClient.get(
      "Engagement/jewishInterestAlgorithmRunDates"
    );
    return response.data.payload || [];
  },
};

export default EngagementApi;
