import React from "react";
import Dropzone from "react-dropzone";
import ImageUpload from "../../common/ImageUpload";
import ContentApi from "../../../services/resources/ContentApi";

export default class MarketingContent extends React.PureComponent {
  uploadImage = async (name, acceptedFiles) => {
    if (acceptedFiles.length) {
      const fileURL = await ContentApi.uploadFile(
        acceptedFiles[0],
        "raffle_campaign",
      );

      this.props.onChange(name, fileURL);
    }
  };

  render() {
    const {
      getErrorClassName,
      onChangeEvent,
      onImageUpload,
      onImageDelete,
      raffle: {
        defaultCampaignImages = [],
        campaignLogoUrl,
        campaignMessage,
        emailMessage,
      },
      submitAttempted,
    } = this.props;

    const maxCampaignMessageLength = 40;

    return (
      <div className="new-raffle-step">
        <div className="new-raffle-step-section">
          <p className="xl-text">Marketing Content</p>
        </div>

        <div className="new-raffle-step-section">
          <p className="medium-text fw-700 mb-16">Campaign Info</p>
          <div className="flex">
            <div className="flex flex-align-center image-upload mb-24">
              <Dropzone
                accept={
                  "image/png,image/jpeg,image/bmp,image/jpg,image/svg+xml"
                }
                onDrop={(files) => this.uploadImage("campaignLogoUrl", files)}
                tabIndex={10}
                title="Drag an image here or click to find one on your computer."
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className={getErrorClassName(
                      `dropzone ${!campaignLogoUrl ? "no-image" : ""}`,
                      submitAttempted && !campaignLogoUrl,
                    )}
                  >
                    <input {...getInputProps()} />
                    <img
                      src={
                        campaignLogoUrl
                          ? campaignLogoUrl
                          : "/img/placeholder.svg"
                      }
                      alt="logo"
                    />
                    <p className="link-text uppercase-text fw-700 ml-16">
                      {campaignLogoUrl ? "Change " : "Upload "} Logo
                    </p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div>
            <div className="flex flex-align-center mb-12">
              <p className="medium-text fw-700">Campaign Images </p>
              <p className="accent-text-dark ml-8">max 10 images</p>
            </div>

            <p className="accent-text-dark mb-16 line-height-double">
              The campaign images will be displayed in a revolving carousel at
              the top section of the Grand Draw web page. Shluchim can upload
              their own pictures or select from the default ones. We recommend a
              total of 7-8 images - a minimum of 5 and a maximum of 10.
            </p>

            <div className="mb-24">
              {defaultCampaignImages.length < 5 && (
                <p
                  className={`mb-12 ${
                    submitAttempted ? "img-error-message" : "warning-message"
                  }`}
                >
                  Please upload at least 5 images
                </p>
              )}
              <div className="flex flex-align-center image-upload">
                {defaultCampaignImages.map((img) => (
                  <div key={img.url} className="campaign-image mr-16 mb-12">
                    <img src={img.url} alt={img.url} />
                    <i
                      className="pointer material-icons raffle-close-img"
                      onClick={() =>
                        onImageDelete("defaultCampaignImages", img.url)
                      }
                    >
                      close
                    </i>
                  </div>
                ))}
                <div className="mt-12">
                  {defaultCampaignImages.length < 10 && (
                    <ImageUpload
                      name="defaultCampaignImages"
                      onChange={(name, value) => onImageUpload(name, value)}
                      imageType="raffle_banner"
                      cropAspectHeight={2}
                      cropAspectWidth={2}
                      withCrop
                      hideImage
                      forceCrop
                      imageName="Image"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex flex-align-center">
              <p className="fw-700">Campaign Message</p>
              <p className="accent-text small-text ml-8">
                max {maxCampaignMessageLength} characters
              </p>
            </div>
            <div className="relative campaign-message">
              <textarea
                className={getErrorClassName(
                  "custom-input mt-8",
                  submitAttempted && !campaignMessage,
                )}
                name="campaignMessage"
                onChange={onChangeEvent}
                tabIndex={20}
                value={campaignMessage}
                maxLength={maxCampaignMessageLength}
              />
              <p className="accent-text small-text ml-8">
                {campaignMessage.length} / {maxCampaignMessageLength}
              </p>
            </div>
          </div>
          <div>
            <p className="fw-700">Email Message</p>
            <textarea
              className={getErrorClassName(
                "custom-input mt-8",
                submitAttempted && !emailMessage,
              )}
              name="emailMessage"
              onChange={onChangeEvent}
              style={{ height: "180px" }}
              tabIndex={22}
              value={emailMessage}
            />
          </div>
        </div>
      </div>
    );
  }
}
