import React from "react";
import moment from "moment";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import {
  ModalWithButtons /* , ModalButton */,
} from "../../../lib/coc-common-components";

import CustomEventsApi from "../../../services/resources/CustomEventsApi";
import { EventTypes, EventCategories } from "../../../models/EventCategories";
// import { EventModel } from "../../../models/EventDescriptor";
import { ContactType } from "../../Contact/ContactUtils";
import {
  ShliachMenuOptions,
  StudentMenuOptions /* , MenuOption */,
} from "../MenuOptions";
import { /* CustomEvent, */ GetCustomEventsAsMenuOptions } from "../Utils";
import EventTypeSelector from "./EventTypeSelector";
import EventLayout from "./EventLayout";

// interface EventSelectorProps {
//   contactType: ContactType;
//   cancelCallback: () => void;
//   confirmCallback: (eventData) => void;
// }

// interface EventSelectorState {
//   eventData: EventModel;
//   inputData: { [propName: string] };
//   menuOptions: MenuOption;
//   displayMessage: string;
// }

class EventSelector extends React.Component {
  constructor(props) {
    super(props);

    const menuOptions =
      props.contactType === ContactType.shluchim
        ? ShliachMenuOptions
        : StudentMenuOptions;
    const defaultEventData = this.getDefaultEventData(props, menuOptions);

    this.state = {
      eventData: defaultEventData,
      inputData: {},
      menuOptions:
        this.props.contactType === ContactType.shluchim
          ? ShliachMenuOptions
          : StudentMenuOptions,
      displayMessage: "",
    };

    this.handleEventCategoryChange = this.handleEventCategoryChange.bind(this);
    this.handleEventTypeChange = this.handleEventTypeChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }
  /** @param {EventSelectorProps} props
   * @param {MenuOption} menuOptions
   * @returns {EventModel}
   */
  getDefaultEventData(props, menuOptions) {
    const defaultCategory =
      props.contactType === ContactType.shluchim
        ? EventCategories.FundraisingBootcamp
        : EventCategories.Tefillin;
    const defaultType =
      props.contactType === ContactType.shluchim
        ? EventTypes.SubmittedMonthlySnapshot
        : EventTypes.ReceivedTefillinBankSubsidy;
    return {
      category: defaultCategory,
      categoryName: menuOptions[defaultCategory].title,
      type: defaultType,
      typeName:
        menuOptions[defaultCategory].eventTypes[
          Object.keys(menuOptions[defaultCategory].eventTypes)[0]
        ],
      occurredAt: moment.utc().startOf("day"),
      metadata: {},
    };
  }

  handleEventCategoryChange(event) {
    this.setState({
      eventData: Object.assign({}, this.state.eventData, {
        category: event.currentTarget.value,
        categoryName:
          event.currentTarget.options[event.currentTarget.selectedIndex].text,
        type: Object.keys(
          this.state.menuOptions[event.currentTarget.value].eventTypes,
        )[0],
        typeName:
          this.state.menuOptions[event.currentTarget.value].eventTypes[
            Object.keys(
              this.state.menuOptions[event.currentTarget.value].eventTypes,
            )[0]
          ],
        customType:
          +event.currentTarget.value >= 1000 ? event.currentTarget.value : null,
      }),
    });
  }

  handleEventTypeChange(event) {
    this.setState({
      eventData: Object.assign({}, this.state.eventData, {
        type: event.currentTarget.value,
        typeName:
          event.currentTarget.options[event.currentTarget.selectedIndex].text,
      }),
    });
  }
  /** @param {string} inputName */
  handleInputChange(inputName) {
    return (event) => {
      const newValue /*event.currentTarget.type === "checkbox"
                            ? event.currentTarget.checked
                            : event.currentTarget.value,*/ =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      this.setState({
        inputData: {
          ...this.state.inputData,
          [inputName]: newValue,
        },
        eventData: {
          ...this.state.eventData,
          metadata: {
            ...this.state.eventData.metadata,
            [inputName]: newValue,
          },
        },
      });
    };
  }

  handleConfirm(event) {
    event.preventDefault();
    this.props.confirmCallback(this.state.eventData);
  }

  messageButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayMessage: "" }),
    });
    return buttons;
  }

  componentDidMount() {
    CustomEventsApi.list()
      .then((response) => {
        const eventsByContactType = response.data.results.filter(
          (ce) => ce.contactType === this.props.contactType,
        );
        const customMenuOptions =
          GetCustomEventsAsMenuOptions(eventsByContactType);
        const menuOptions = { ...this.state.menuOptions, ...customMenuOptions };
        this.setState({ menuOptions });
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err, true);
        this.setState({ displayMessage: errors.join("\n") });
      });
  }

  render() {
    const { eventData } = this.state;

    return (
      <div style={{ minWidth: "220px" }}>
        <EventTypeSelector
          contactType={this.props.contactType}
          selectedCategory={eventData.category}
          onEventCategoryChange={this.handleEventCategoryChange}
          selectedType={eventData.type}
          onEventTypeChange={this.handleEventTypeChange}
          menuOptions={this.state.menuOptions}
        />
        <hr />
        <form onSubmit={this.handleConfirm}>
          <EventLayout
            eventType={eventData.type}
            customEventCategory={eventData.category}
            onInputChange={this.handleInputChange}
            inputValues={Object.assign(
              {},
              { occurredAt: moment.utc().startOf("day") },
              this.state.inputData,
            )}
          />
          <hr />
          <div className="row">
            <div className="col-sm-4" />
            <div className="col-sm-2">
              <input
                type="button"
                className="btn btn-primary"
                onClick={this.props.cancelCallback}
                value="Cancel"
              />
            </div>
            <div className="col-sm-1" />
            <div className="col-sm-2 text-left">
              <input type="submit" className="btn btn-primary" value="Apply" />
            </div>
          </div>
        </form>
        {this.state.displayMessage && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayMessage}
          />
        )}
      </div>
    );
  }
}

export default EventSelector;
