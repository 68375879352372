import React from "react";
// import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import AuthClient from "../../../services/resources/AuthClient";
// import CustomEventsApi from "../../../services/resources/CustomEventsApi";
// import { NavMenuItem } from "../../../models/Menu";
// import { CustomEvent } from "../../Events/Utils";
import { ContactType } from "../../Contact/ContactUtils";
import {
  getMainMenuItems,
  sortMenuSubItemsAlphabetically,
  SHLUCHIM_MENU_TEXT,
  STUDENT_MENU_TEXT,
} from "./Utils";
import NavBarHeader from "./NavBarHeader";
import NavMenu from "./NavMenu";

// interface NavMenuContainerProps {
//   mobileMode: boolean;
//   toggleSidebarOpen: React.FormEventHandler<HTMLElement>;
// }

// interface NavMenuContainerState {
//   loading: boolean;
//   displayMessage: string;
//   navMenuItems: Array<NavMenuItem>;
// }
export class NavMenuContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navMenuItems: getMainMenuItems(),
      loading: false,
      displayMessage: "",
      count: "",
    };
  }

  componentDidMount() {
    if (AuthService.UserHasClaim(PermissionClaims.ProgramRead)) {
      this.getDynamicProgramRoutes(); //TODO - correct permission?
    }
  }

  getDynamicProgramRoutes = async () => {
    const { data } = await AuthClient.get(
      `/Programs/routes?routeGroup=Admin`
    ).catch((err) => {
      console.log(err);
      return {};
    });

    if (data && data.payload && data.payload.length > 0) {
      const currentPrograms = {
        text: "Current Programs",
        items: data.payload
          .filter((prog) => prog.types.indexOf("Current") >= 0)
          .map((prog) => ({
            ...prog,
            name: prog.programScheduleName || prog.programName,
          }))
          .sort((p1, p2) => (p1.name > p2.name ? 1 : -1)),
      };
      const managedPrograms = {
        text: "Quick Access Programs",
        items: data.payload
          .filter((prog) => prog.types.indexOf("Managed") >= 0)
          .map((prog) => ({
            ...prog,
            name: prog.programName,
          }))
          .sort((p1, p2) => (p1.name > p2.name ? 1 : -1)),
      };

      const { navMenuItems } = this.state;
      const programMenuItems = [currentPrograms, managedPrograms]
        .filter((item) => item.items.length)
        .map((item, index) => ({
          ...item,
          order: navMenuItems.length + index + 1,
          items: !item.items
            ? null
            : item.items.map((prog, index) => ({
                id: prog.scheduleID,
                text: prog.name,
                order: index,
                urlTo: prog.baseUrl
                  ? `${prog.baseUrl}/${prog.scheduleID}`
                  : prog.isRegistrationProgram
                  ? `/programs/${prog.programID}/schedules/${prog.scheduleID}/registrations`
                  : "",
              })),
        }));

      this.setState({
        navMenuItems: [...navMenuItems, ...programMenuItems],
      });
    }
  };

  /** @param {NavMenuItem[]} list
   * @param {CustomEvent[]} customEvents
   * @param {ContactType} contactType
   * @returns {NavMenuItem}
   */
  mergeContactTypeMenus(list, customEvents, contactType) {
    const contactTypeMenu = list.find(
      (mi) =>
        mi.text ===
        (contactType === ContactType.shluchim
          ? SHLUCHIM_MENU_TEXT
          : STUDENT_MENU_TEXT)
    );
    /** @type {Array<NavMenuItem>} */
    const newMenuItems = customEvents
      .filter((ce) => ce.contactType === contactType)
      .map((ce) => ({
        text: ce.name,
        order: 1,
        urlTo: `/evtreports/${ce.id}`,
      }));
    if (!contactTypeMenu) {
      return {
        text:
          contactType === ContactType.shluchim
            ? SHLUCHIM_MENU_TEXT
            : STUDENT_MENU_TEXT,
        order: contactType === ContactType.shluchim ? 2 : 3,
        items: newMenuItems,
      };
    }
    return Object.assign({}, contactTypeMenu, {
      items: [...contactTypeMenu.items, ...newMenuItems],
    });
  }
  /** @param {Array<NavMenuItem>} list
   * @param {Array<CustomEvent>} newItems
   */
  addMenuItemsToList(list, newItems) {
    const newShluchimMenu = this.mergeContactTypeMenus(
      list,
      newItems,
      ContactType.shluchim
    );
    const newStudentMenu = this.mergeContactTypeMenus(
      list,
      newItems,
      ContactType.students
    );
    const newList = list.filter(
      (mi) => mi.text !== SHLUCHIM_MENU_TEXT && mi.text !== STUDENT_MENU_TEXT
    );
    newList.push(sortMenuSubItemsAlphabetically(newShluchimMenu));
    newList.push(sortMenuSubItemsAlphabetically(newStudentMenu));
    return newList;
  }

  // componentDidMount() {
  //   this.setState({ loading: true }, () =>
  //     CustomEventsApi.list()
  //       .then(response => {
  //         this.setState({
  //           navMenuItems: this.addMenuItemsToList(this.state.navMenuItems, response.data.results),
  //           loading: false,
  //         });
  //       })
  //       .catch(err => {
  //         const errors = ApiCallErrorHandler(err, true);
  //         this.setState({ loading: false, displayMessage: errors.join("\n") });
  //       })
  //   );
  // }

  render() {
    return (
      <div className="sidebar-menu col-sm-12">
        <NavBarHeader
          mobileMode={this.props.mobileMode}
          handleToggleSidebarOpen={this.props.toggleSidebarOpen}
        />
        <NavMenu items={this.state.navMenuItems} count={this.state.count} />
        {this.props.mobileMode ? (
          <button
            onClick={AuthService.logout}
            className="btn btn-primary btn-logout"
          >
            Logout
          </button>
        ) : (
          ""
        )}
      </div>
    );
  }
}
