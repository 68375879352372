import React from "react";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import { notify } from "react-notify-toast";

import RafflesApi from "../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";

export default class RaffleOrdersBulkImportModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      loading: false,
      fileForUpload: null,
      uploadProgress: 0,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  selectFile = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];

      const acceptedFileTypes = [".csv", ".xls", ".xlsx"];
      if (
        acceptedFileTypes.some(
          (fileType) => file.name.toLowerCase().indexOf(fileType) > 0,
        )
      ) {
        this.setState({
          errorMessage: "",
          fileForUpload: file,
        });
      } else {
        this.setState({
          errorMessage:
            "Unsupported file type. Please upload a CSV, XLS, or XLSX file.",
          fileForUpload: null,
        });
      }
    }
  };

  uploadBulkOrders = () => {
    const { enrollmentId, reloadOrders } = this.props;
    const { fileForUpload } = this.state;

    this.setState(
      {
        errorMessage: "",
        loading: true,
        uploadProgress: 0,
      },
      () => {
        RafflesApi.bulkImportOrders(
          this.apiSignal.token,
          enrollmentId,
          fileForUpload,
          (progressEvent) => {
            var progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            this.setState({
              uploadProgress: progress > 100 ? 100 : progress,
            });
          },
        )
          .then(() => {
            notify.show("Your orders have been added", "success");
            reloadOrders();
            this.onClose();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                uploadProgress: 0,
              });
            }
          });
      },
    );
  };

  render() {
    const { bulkOrderTemplateURL, show } = this.props;
    const { errorMessage, fileForUpload, loading, uploadProgress } = this.state;

    return (
      <Modal isOpen={show} className="center-modal-container">
        <div className="modal-card card import-modal">
          <p className="xl-text fw-700">Bulk Import</p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Please refrain from using this feature unless completely necessary.
            There will be a fee for each ticket that is uploaded and there is no
            way to delete a transaction once uploaded. You can also enter
            transactions by clicking the Add Order button.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Do you have the bulk import template? If not, you can{" "}
            <a download href={bulkOrderTemplateURL} className="link-text">
              download the template here
            </a>
            . When you’re ready, upload your file below.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Note that first name, last name, email, phone, quantity, and payment
            method are required. The rest of the information is optional;
            include it if you'll want it for future reference.
          </p>
          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Furthermore, emails will not be sent out for these orders.
          </p>

          <div className="flex mt-24 mb-24 flex-justify-space">
            <Dropzone
              // accept={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
              // there is a bug with the dropzone library causing mime type to be blank for csv/xls/xlsx files on windows.
              // therefore removing the above preselection-level validation and validting in onDrop after file selection.
              onDrop={this.selectFile}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} />
                  <p className="uppercase-text fw-500 link-text small-text">
                    {fileForUpload ? "Change " : "Select"} File
                  </p>
                </div>
              )}
            </Dropzone>

            {fileForUpload && (
              <div className="uploading">
                <div className="flex flex-justify-space flex-align-center">
                  <p className="fw-700 mr-8 flex flex-align-center">
                    <i className="material-icons accent-text mr-8">
                      description
                    </i>
                    Upload orders from {fileForUpload.name}?
                  </p>
                </div>
                <div className="percentage-bar relative">
                  <div className="bar" />
                  <div
                    className="filled-in"
                    style={{ width: `${uploadProgress}%` }}
                  />
                  <div className="labels flex flex-justify-space mt-8">
                    <p />
                    <p className="accent-text small-text">{uploadProgress}%</p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!loading && (
            <>
              <div className="modal-btns flex flex-justify-end flex-align-center mt-24">
                <button
                  className="btn btn-light btn-medium"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-accent btn-medium ml-16"
                  disabled={!fileForUpload}
                  onClick={this.uploadBulkOrders}
                >
                  Upload Orders
                </button>
              </div>
              {errorMessage && (
                <p className="error-text text-right mt-8">{errorMessage}</p>
              )}
            </>
          )}
        </div>
      </Modal>
    );
  }
}
