import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../shared/ConfirmationModal";
import FadeOutErrorMessage from "../../shared/FadeOutErrorMessage";
import ImageUpload from "../../common/ImageUpload";
import Loader from "../../common/Loader";
import ProgramBreadcrumbsHeader from "../../common/ProgramBreadcrumbsHeader";
import Select from "react-select";
import Toggle from "../../shared/Toggle";

import EduApi from "../../../services/resources/EduApi";
import SystemApi from "../../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import { Unauthorized } from "../../../lib/coc-common-components";
import { notify } from "react-notify-toast";
import { getFormattedValuesForForm, removeEmptyFromObj } from "../../../lib";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

const { REACT_APP_HOST_ENV = "development" } = process.env;

export default class CourseDetailsPage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    eduChabadHousesList: [],
    eduChabadHousesListErrorMessage: "",
    eduChabadHousesListLoading: false,

    eduProgram: null,
    course: null,
    initialCourse: null,

    errorMessage: "",
    loading: true,

    isRequestedPageLeave: false,
    showCancelChangesConfirmationModal: false,
    showSubmitConfirmationModal: false,
    submitAttempted: false,
    submitCourseErrorMessage: "",
    submitCourseLoading: false,

    systemLists: {},
    systemListsErrorMessage: "",
  };

  apiSignal = axios.CancelToken.source();
  incompleteSubmissionErrorMessage = "Please complete required fields";

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.EduFullView)) {
      this.setState({ authorized: false });
    } else if (!AuthService.UserHasClaim(PermissionClaims.EduFullEdit)) {
      this.setState({ viewOnly: true });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getCourseDetails();

    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { course, initialCourse, isRequestedPageLeave } = this.state;

    if (!isRequestedPageLeave && !_isEqual(course, initialCourse)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getCourseDetails = async () => {
    const {
      params: { courseId, scheduleId },
    } = this.props;

    this.setState({
      loading: true,
    });

    try {
      const eduProgram = await EduApi.getEduProgram(
        this.apiSignal.token,
        scheduleId,
      );

      let course;
      if (courseId) {
        const courseDetails = await EduApi.getEduProgramCourse(
          this.apiSignal.token,
          eduProgram.id,
          courseId,
        );
        course = getFormattedValuesForForm(courseDetails);
      } else {
        course = {
          category: "None",
          classes: Array(eduProgram.configs.DefaultClasses || 0)
            .fill()
            .map((_, i) => ({
              title: "",
              description: "",
              sortOrder: i + 1,
            })),
          courseProvider: "",
          description: "",
          includesAllShluchim: true,
          isIsraelRelated: false,
          isJli: false,
          isOpen: true,
          imageURL: "",
          level: "",
          name: "",
          notes: "",
          participants: [],
          resourceURL: "",
          studentSurveyURL: "",
        };
      }

      //get edu program locations for participants list
      this.getEduChabadHousesForParticipantsList(eduProgram.id);

      this.setState({
        course,
        eduProgram,
        initialCourse: _cloneDeep(course),
        loading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and course details could not be retrieved. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  getEduChabadHousesForParticipantsList = async (eduProgramId) => {
    try {
      this.setState({
        eduChabadHousesListErrorMessage: "",
        eduChabadHousesListLoading: true,
      });

      const eduChabadHouses = await EduApi.getEduChabadHousesBasic(
        this.apiSignal.token,
        eduProgramId,
      );

      this.setState({
        eduChabadHousesList: eduChabadHouses,
        eduChabadHousesListLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          eduChabadHousesListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve chabad houses. Please try again.",
          ),
          eduChabadHousesListLoading: false,
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "eduLevels",
        "eduCourseCategories",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  onChangeCourse = (name, value, otherUpdates) => {
    let course = _cloneDeep(this.state.course);
    _set(course, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(course, update, otherUpdates[update]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ course }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { submitCourseErrorMessage } = this.state;
        if (
          submitCourseErrorMessage &&
          submitCourseErrorMessage === this.incompleteSubmissionErrorMessage
        ) {
          const isValid = this.validateCourse();
          if (isValid) {
            this.setState({
              submitCourseErrorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeCourseEvt = ({ target: { checked, name, type, value } }) => {
    return this.onChangeCourse(name, type === "checkbox" ? checked : value);
  };

  onAddClass = () => {
    const { classes } = this.state.course;
    this.onChangeCourse("classes", [
      ...classes,
      {
        title: "",
        description: "",
        sortOrder: classes.length
          ? (Math.max(...classes.map((c) => c.sortOrder)) || 0) + 1
          : 1,
      },
    ]);
  };

  onDeleteClass = (classIndex) => {
    const { classes } = this.state.course;
    this.onChangeCourse(
      "classes",
      classes
        .map((c, index) =>
          index > classIndex && c.sortOrder
            ? { ...c, sortOrder: c.sortOrder - 1 }
            : c,
        ) //adjust class sort orders
        .filter((c, index) => index !== classIndex),
    );
  };

  onCancelCourseChanges = () => {
    const { course, initialCourse } = this.state;
    if (_isEqual(course, initialCourse)) {
      browserHistory.push(`/edu/${this.props.params.scheduleId}?tab=courses`);
      return;
    }

    this.setState({ showCancelChangesConfirmationModal: true });
  };

  cancelCourseChanges = () => {
    this.setState(
      {
        course: _cloneDeep(this.state.initialCourse),
        isRequestedPageLeave: true,
        showCancelChangesConfirmationModal: false,
        submitAttempted: false,
        submitCourseErrorMessage: "",
      },
      () =>
        browserHistory.push(`/edu/${this.props.params.scheduleId}?tab=courses`),
    );
  };

  onSubmitCourse = () => {
    this.setState({
      submitAttempted: true,
      submitCourseErrorMessage: "",
    });

    const isValid = this.validateCourse();
    if (!isValid) {
      this.setState({
        submitCourseErrorMessage: this.incompleteSubmissionErrorMessage,
      });
      return;
    }

    this.setState({
      showSubmitConfirmationModal: true,
    });
  };

  submitCourse = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitCourseErrorMessage: "",
      submitCourseLoading: true,
    });

    try {
      const courseForSubmission = _cloneDeep(this.state.course);
      removeEmptyFromObj(courseForSubmission);
      await EduApi.submitEduProgramCourse(
        this.apiSignal.token,
        this.state.eduProgram.id,
        courseForSubmission,
      );

      this.setState(
        {
          isRequestedPageLeave: true,
        },
        () => {
          browserHistory.push(
            `/edu/${this.props.params.scheduleId}?tab=courses`,
          );
          notify.show("Your course has been saved", "success");
        },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitCourseErrorMessage: ApiCallErrorMessageHandler(err),
          submitCourseLoading: false,
        });
      }
    }
  };

  validateCourse = () => {
    const {
      course: { imageURL, isOpen, name, studentSurveyURL },
    } = this.state;

    if (!name || (isOpen && (!imageURL || !studentSurveyURL))) {
      return false;
    }

    return true;
  };

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      course,
      eduChabadHousesList,
      eduChabadHousesListErrorMessage,
      eduChabadHousesListLoading,
      eduProgram,
      errorMessage,
      initialCourse,
      loading,
      showCancelChangesConfirmationModal,
      showSubmitConfirmationModal,
      submitAttempted,
      submitCourseErrorMessage,
      submitCourseLoading,
      systemLists: { eduCourseCategories, eduLevels },
      systemListsErrorMessage,
      viewOnly,
    } = this.state;

    const { name: eduProgramName } = eduProgram || {};
    const {
      category,
      classes,
      description,
      id: courseId,
      imageURL,
      includesAllShluchim,
      isIsraelRelated,
      isJli,
      isOpen,
      level,
      name,
      notes,
      participants,
      resourceURL,
      studentSurveyURL,
    } = course || {};

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const saveButton = !viewOnly && (
      <React.Fragment>
        <button
          className="custom-btn btn-accent uppercase-text"
          disabled={_isEqual(course, initialCourse)}
          onClick={this.onSubmitCourse}
        >
          Save
        </button>
        {!!submitCourseErrorMessage && (
          <FadeOutErrorMessage
            className="error-text text-right block"
            message={submitCourseErrorMessage}
            onTimeout={() =>
              this.setState({
                submitCourseErrorMessage: "",
              })
            }
          />
        )}
      </React.Fragment>
    );

    return (
      <div className="course-form-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/edu/${sched.scheduleID}?tab=courses`,
              title: `Manage ${sched.scheduleName || sched.programName}`,
            },
          ]}
          pageTitle="Courses"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !course ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <div className="card full-width">
            <div className="flex flex-align-center flex-justify-space mb-16 course-form-header">
              <p className="flex flex-align-center xxl-text">
                <i
                  className="material-icons link-text mr-16"
                  onClick={() => browserHistory.goBack()}
                >
                  arrow_back
                </i>{" "}
                {courseId ? "Edit" : "Create New"} Course
              </p>
              <div>
                <Toggle
                  className="active-toggle mr-16"
                  disabled={viewOnly}
                  name="isOpen"
                  options={[
                    {
                      value: true,
                      display: "Active",
                    },
                    { value: false, display: "Inactive" },
                  ]}
                  onChange={this.onChangeCourse}
                  value={isOpen}
                />
                {saveButton}
              </div>
            </div>
            <div className="course-form">
              <div className="course-form-section">
                <p className="course-form-section-title">Course Details</p>
                <div className="mb-16">
                  <ImageUpload
                    disabled={viewOnly}
                    error={submitAttempted && isOpen && !imageURL}
                    imageName="Course Image"
                    imageType="edu_program_course"
                    imageURL={imageURL}
                    name="imageURL"
                    onChange={this.onChangeCourse}
                  />
                  <p className="mt-8 small-text accent-text line-height-double">
                    *In order for the image to display nicely, we suggest you
                    use a high resolution image. Also keep in mind that the
                    image will be displayed in a wide rectangle, so choose an
                    image that would look right when zoomed into that shape.
                  </p>
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">Initiative</label>
                  <p>{eduProgramName}</p>
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">Course Name</label>
                  <input
                    className={`custom-input${
                      submitAttempted && !name ? " error" : ""
                    }`}
                    dir="auto"
                    disabled={viewOnly}
                    name="name"
                    onChange={this.onChangeCourseEvt}
                    type="text"
                    value={name}
                  />
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">Available To</label>
                  <Toggle
                    disabled={viewOnly}
                    name="includesAllShluchim"
                    onChange={(name, val) =>
                      this.onChangeCourse(
                        name,
                        val,
                        !val && participants.length
                          ? { participants: [] }
                          : null,
                      )
                    }
                    options={[
                      {
                        value: true,
                        display: "All Shluchim",
                      },
                      { value: false, display: "Specific Shluchim" },
                    ]}
                    value={includesAllShluchim}
                  />
                </div>
                {!includesAllShluchim && (
                  <div className="mb-16">
                    <label
                      className="accent-text-dark mb-8"
                      style={{ width: "100%" }}
                    >
                      Which shluchim is this course available to
                    </label>
                    <Select
                      disabled={viewOnly}
                      multi={true}
                      onChange={(vals) =>
                        this.onChangeCourse(
                          "participants",
                          vals
                            ? vals.map(({ label, value }) => ({
                                chabadHouseID: value,
                                chabadHouseName: label,
                              }))
                            : [],
                        )
                      }
                      options={
                        eduChabadHousesList &&
                        eduChabadHousesList.map((ch) => ({
                          label: `${ch.chabadHouseName} - ${[
                            ch.shliachFirstName,
                            ch.shluchaFirstName,
                          ]
                            .filter((n) => n)
                            .join(" & ")} ${ch.shliachLastName}`,
                          value: ch.chabadHouseID,
                        }))
                      }
                      placeholder={
                        eduChabadHousesListLoading
                          ? "Loading Shluchim..."
                          : "Add Participants"
                      }
                      style={{
                        height: "32px",
                        border: "1px solid #edecec",
                      }}
                      value={
                        participants
                          ? participants.map((p) => ({
                              label: p.chabadHouseName,
                              value: p.chabadHouseID,
                            }))
                          : []
                      }
                    />
                    {eduChabadHousesListErrorMessage && (
                      <p className="error-text">
                        Something went wrong and we could not retrieve shluchim
                        available for participation.
                      </p>
                    )}
                  </div>
                )}
                <div className="mb-24">
                  <div className="flex flex-align-center mb-8">
                    <label className="accent-text-dark">
                      Course Description
                    </label>
                    <div className="tooltip-container">
                      <i className="material-icons large-text ml-8">info</i>
                      <span className="tooltip">
                        This will be displayed on the student and public
                        websites
                      </span>
                    </div>
                  </div>
                  <textarea
                    className="custom-input full-width"
                    dir="auto"
                    disabled={viewOnly}
                    name="description"
                    onChange={this.onChangeCourseEvt}
                    style={{ resize: "none", height: "72px" }}
                    value={description}
                  />
                </div>
                <div className="mb-24">
                  <div className="flex flex-align-center mb-8">
                    <label className="accent-text-dark">Course Notes</label>
                    <div className="tooltip-container">
                      <i className="material-icons large-text ml-8">info</i>
                      <span className="tooltip">
                        This will be displayed on the shluchim website
                      </span>
                    </div>
                  </div>
                  <textarea
                    className="custom-input full-width"
                    dir="auto"
                    disabled={viewOnly}
                    name="notes"
                    onChange={this.onChangeCourseEvt}
                    style={{ resize: "none", height: "72px" }}
                    value={notes}
                  />
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">Select Category</label>
                  <Select
                    disabled={viewOnly}
                    onChange={(ctgry) =>
                      this.onChangeCourse(
                        "category",
                        ctgry ? ctgry.value : null,
                      )
                    }
                    options={
                      eduCourseCategories &&
                      eduCourseCategories
                        .sort((c1, c2) =>
                          c1.displayValue.localeCompare(c2.displayValue),
                        )
                        .map((ctgry) => ({
                          label: ctgry.displayValue,
                          value: ctgry.enumValue,
                        }))
                    }
                    style={{
                      height: "32px",
                      minWidth: "200px",
                      border: "1px solid #edecec",
                    }}
                    value={category}
                  />
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">Select Level</label>
                  <Select
                    disabled={viewOnly}
                    onChange={(lvl) =>
                      this.onChangeCourse("level", lvl ? lvl.value : null)
                    }
                    options={
                      eduLevels &&
                      eduLevels.map((lvl) => ({
                        label: lvl.displayValue,
                        value: lvl.enumValue,
                      }))
                    }
                    style={{
                      height: "32px",
                      minWidth: "200px",
                      border: "1px solid #edecec",
                    }}
                    value={level}
                  />
                </div>
                {systemListsErrorMessage && (
                  <FadeOutErrorMessage message="Something went wrong and we could not retrieve course categories or levels.  Please try again." />
                )}
                <div className="flex custom-checkbox-container mb-24">
                  <input
                    className="custom-checkbox"
                    checked={isIsraelRelated}
                    disabled={viewOnly}
                    id="israel-related-checkbox"
                    name="isIsraelRelated"
                    onChange={this.onChangeCourseEvt}
                    type="checkbox"
                    value={isIsraelRelated}
                  />
                  <label
                    className="flex-align-center no-wrap"
                    htmlFor="israel-related-checkbox"
                  >
                    <span>Israel/Antisemitism Related</span>
                  </label>
                </div>
                <div className="flex custom-checkbox-container mb-16">
                  <input
                    className="custom-checkbox"
                    checked={isJli}
                    disabled={viewOnly}
                    id="jli-checkbox"
                    name="isJli"
                    onChange={this.onChangeCourseEvt}
                    type="checkbox"
                    value={isJli}
                  />
                  <label className="flex-align-center" htmlFor="jli-checkbox">
                    <span>JLI curriculum</span>
                  </label>
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="accent-text-dark">
                    Resource Library URL
                  </label>
                  <input
                    className="custom-input full-width"
                    disabled={viewOnly}
                    name="resourceURL"
                    onChange={this.onChangeCourseEvt}
                    value={resourceURL}
                    type="text"
                  />
                </div>
                <div className="flex flex-align-center mb-16">
                  <label className="flex flex-align-center accent-text-dark">
                    Course Review URL
                    {REACT_APP_HOST_ENV !== "production" && (
                      <div className="tooltip-container">
                        <i className="material-icons large-text ml-8">info</i>
                        <span className="tooltip">
                          Enter only Test Survey URLs in this test environment;
                          survey URLs that are in use on production may not be
                          used here.
                          <br />
                          Note that in test environments survey responses are
                          refreshed once daily.
                        </span>
                      </div>
                    )}
                  </label>
                  <input
                    className={`custom-input full-width${
                      submitAttempted && isOpen && !studentSurveyURL
                        ? " error"
                        : ""
                    }`}
                    disabled={viewOnly}
                    name="studentSurveyURL"
                    onChange={this.onChangeCourseEvt}
                    value={studentSurveyURL}
                    type="text"
                  />
                </div>
              </div>
              <div className="course-form-section">
                <div className="course-form-section-title flex flex-align-center flex-justify-space">
                  <p>Classes</p>
                  {!viewOnly && (
                    <button
                      className="custom-btn btn-small btn-accent uppercase-text"
                      onClick={this.onAddClass}
                      style={{ fontWeight: "normal" }}
                    >
                      Add Additional Class
                    </button>
                  )}
                </div>
                {classes && (
                  <React.Fragment>
                    {classes.map((clss, index) => (
                      <div
                        className="course-form-class-section mb-16"
                        key={index}
                      >
                        <div className="flex flex-align-center flex-justify-space mb-16">
                          <p className="fw-700 mb-0">Class {index + 1}</p>
                          {!viewOnly && (
                            <p>
                              <i
                                className="material-icons xl-text link-text-secondary"
                                onClick={() => this.onDeleteClass(index)}
                              >
                                delete
                              </i>
                            </p>
                          )}
                        </div>
                        <div className="mb-16 flex flex-align-center">
                          <label className="accent-text-dark">Title</label>
                          <input
                            className="custom-input full-width"
                            dir="auto"
                            disabled={viewOnly}
                            name={`classes[${index}].title`}
                            onChange={this.onChangeCourseEvt}
                            type="text"
                            value={clss.title}
                          />
                        </div>
                        <div className="mb-16">
                          <label className="accent-text-dark block">
                            Description
                          </label>
                          <textarea
                            className="custom-input full-width"
                            dir="auto"
                            disabled={viewOnly}
                            name={`classes[${index}].description`}
                            onChange={this.onChangeCourseEvt}
                            style={{ resize: "none", height: "72px" }}
                            value={clss.description}
                          />
                        </div>
                      </div>
                    ))}
                    {!viewOnly && !!classes.length && (
                      <div className="flex flex-justify-end mb-16">
                        <button
                          className="custom-btn btn-small btn-accent uppercase-text"
                          onClick={this.onAddClass}
                          style={{ fontWeight: "normal" }}
                        >
                          Add Additional Class
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="flex flex-justify-end mt-16">
              <div>
                <Toggle
                  className="active-toggle"
                  disabled={viewOnly}
                  name="isOpen2" //second toggle on page - name cannot match first, t/f ignoring name in change handler below
                  options={[
                    {
                      value: true,
                      display: "Active",
                    },
                    { value: false, display: "Inactive" },
                  ]}
                  onChange={(name, value) =>
                    this.onChangeCourse("isOpen", value)
                  }
                  value={isOpen}
                />
              </div>
            </div>
            {!viewOnly && (
              <div className="course-form-btns flex flex-justify-space mt-16 relative">
                <button
                  className="custom-btn btn-light uppercase-text"
                  disabled={submitCourseLoading}
                  onClick={this.onCancelCourseChanges}
                >
                  Cancel
                </button>
                {submitCourseLoading ? (
                  <div className="medium-loader">
                    <Loader />
                  </div>
                ) : (
                  saveButton
                )}
              </div>
            )}
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showCancelChangesConfirmationModal: false,
                  showSubmitConfirmationModal: false,
                })
              }
              cancelText={showCancelChangesConfirmationModal && "No"}
              confirm={
                showCancelChangesConfirmationModal
                  ? this.cancelCourseChanges
                  : this.submitCourse
              }
              confirmText={showCancelChangesConfirmationModal && "Yes"}
              message={`Are you sure you want to ${
                showCancelChangesConfirmationModal
                  ? "cancel your changes for this course?"
                  : `save ${courseId ? "changes to " : ""}this course?`
              }`}
              show={
                showCancelChangesConfirmationModal ||
                showSubmitConfirmationModal
              }
            />
          </div>
        )}
      </div>
    );
  }
}
