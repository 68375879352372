import React from "react";

export default class StudentCoursesTableHeader extends React.PureComponent {
  render() {
    const columns = [
      "Name",
      "Start Date",
      "Attendance",
      "Status",
      "Review Completed",
      "Review End Date",
      "",
    ];

    return (
      <div className="students-courses-history-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
