import React from "react";
import { EnumToArrayOfObjects } from "../../../lib/coc-common-scripts";

import { ContactType } from "../../Contact/ContactUtils";
import { /* CustomEvent, */ CustomEventTypes } from "../Utils";

const ddStyles = {
  paddingBottom: "3px",
};

// interface CustomEventManagerProps {
//   eventData: CustomEvent;
//   handleEventDataChange: React.FormEventHandler<HTMLElement>;
// }

const CustomEventManager = ({ eventData, handleEventDataChange }) => {
  return (
    <div>
      <dl className="dl-horizontal">
        <dt>Contact type</dt>
        <dd style={ddStyles}>
          {eventData.contactType === ContactType.shluchim
            ? "Shluchim"
            : "Students"}
        </dd>
        <dt>Event Type</dt>
        <dd style={ddStyles}>
          <select
            name="type"
            value={eventData.type || ""}
            onChange={handleEventDataChange}
          >
            {EnumToArrayOfObjects(CustomEventTypes).map((ce) => (
              <option key={ce.code} value={ce.code}>
                {ce.value}
              </option>
            ))}
          </select>
        </dd>
        <dt>Event Name</dt>
        <dd style={ddStyles}>
          <input
            type="text"
            name="name"
            value={eventData.name || ""}
            onChange={handleEventDataChange}
          />
        </dd>
      </dl>
      <div>
        A {CustomEventTypes[eventData.type]} field will be required when
        creating an event of this type.
      </div>
    </div>
  );
};

export default CustomEventManager;
