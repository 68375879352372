import React from "react";
import { browserHistory } from "react-router";
import { ApiCallErrorHandler, Slugify } from "../../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons /* , ModalButton */,
} from "../../../lib/coc-common-components";

import AuthService, { PermissionClaims } from "../../../services/AuthService";
import CustomPagesApi from "../../../services/resources/CustomPagesApi";
import { EmptyCustomPage /* , CustomPage */ } from "../../../models/CustomPage";
import { CustomPageManagerPage } from "./CustomPageManagerPage";
import { ImageType } from "./CustomPageManager";
import UrlBaseUtil from "../../../services/UrlBaseUtil";

// interface CustomPageManagerPageContainerProps {
//   location: { [key: string] };
//   params: { [key: string] };
// }

// interface CustomPageManagerPageContainerState {
//   loading: boolean;
//   customPageData: CustomPage;
//   originalTitle: string;
//   displayMessage: string;
//   displayAndRedirectMessage: string;
//   displayDeleteMessage: string;
// }

export class CustomPageManagerPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customPageData: EmptyCustomPage(),
      originalTitle: "",
      displayMessage: "",
      displayAndRedirectMessage: "",
      displayDeleteMessage: "",
    };
  }
  /** @param {CustomPage} newCustomPageData */
  handleCustomPageDataChange = (newCustomPageData) => {
    const newSlug = Slugify(newCustomPageData.title);
    this.setState({ customPageData: { ...newCustomPageData, slug: newSlug } });
  };
  /** @param {Blob} image
   * @param {string} url
   * @param {ImageType} imgType
   */
  handleImageChange = (image, url, imgType) => {
    const reader = new FileReader();
    const newCustomPageData = { ...this.state.customPageData };
    const classThis = this;

    reader.readAsDataURL(image);
    reader.onload = function () {
      if (imgType === ImageType.MainImage) {
        newCustomPageData.mainImageBase64 = reader.result;
        newCustomPageData.mainImagePath = url;
      } else {
        newCustomPageData.logoBase64 = reader.result;
        newCustomPageData.logoPath = url;
      }
    };
    reader.onerror = function (error) {
      classThis.setState({ displayMessage: "Error: " + error });
    };
    this.setState({
      customPageData: newCustomPageData,
    });
  };

  handleSave = () => {
    this.setState({ loading: true }, () => {
      const isNew = (this.state.customPageData.id || 0) <= 0;
      const apiCall = isNew ? CustomPagesApi.create : CustomPagesApi.update;
      apiCall(this.state.customPageData)
        .then((response) => {
          this.setState({
            customPageData: response.data.result.value,
            originalTitle: response.data.result.value.title,
            displayAndRedirectMessage: "Custom Page saved successfully",
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  };

  handleDelete = () => {
    this.setState({
      displayDeleteMessage: "You are about to delete this custom page",
    });
  };

  deleteCustomPage = () => {
    this.setState({ displayDeleteMessage: "", loading: true }, () =>
      CustomPagesApi.delete(this.state.customPageData.id)
        .then((response) => {
          this.setState({
            loading: false,
            displayAndRedirectMessage: "Custom Page successfully deleted",
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        }),
    );
  };

  confirmDeleteButtons = () => {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "I understand. Delete it now.",
      className: "btn btn-danger",
      onClick: this.deleteCustomPage,
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDeleteMessage: "" }),
    });
    return buttons;
  };

  messageButtons = () => {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayMessage: "" }),
    });
    return buttons;
  };
  /** @param {string} redirectUrl */
  okAndRedirectButtons = (redirectUrl) => {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({ displayMessage: "" }, () => {
          browserHistory.push(redirectUrl);
        }),
    });
    return buttons;
  };

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryEdit)) {
      return;
    }

    const id = Number(this.props.params.id);
    if (!Number.isNaN(id)) {
      this.setState({ loading: true }, () =>
        CustomPagesApi.get(id)
          .then((response) => {
            this.setState({
              customPageData: response.data,
              originalTitle: response.data.title,
              loading: false,
            });
          })
          .catch((err) => {
            const errors = ApiCallErrorHandler(err, true);
            this.setState({
              loading: false,
              displayMessage: errors.join("\n"),
            });
          }),
      );
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryEdit)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { customPageData, originalTitle } = this.state;

    const linksBase = UrlBaseUtil.getAppDomain() + "/post/";

    const nameChanged = customPageData.title !== originalTitle;

    return (
      <div>
        <CustomPageManagerPage
          customPageData={customPageData}
          linksBase={linksBase}
          nameChanged={nameChanged}
          handleCustomPageDataChange={this.handleCustomPageDataChange}
          handleImageChange={this.handleImageChange}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
        />
        {this.state.displayMessage && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayMessage}
          />
        )}
        {this.state.displayAndRedirectMessage && (
          <ModalWithButtons
            buttons={this.okAndRedirectButtons("/config/custompages")}
            text={this.state.displayAndRedirectMessage}
          />
        )}
        {this.state.displayDeleteMessage && (
          <ModalWithButtons
            buttons={this.confirmDeleteButtons()}
            text={this.state.displayDeleteMessage}
          />
        )}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}
