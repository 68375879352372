import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";

import ShluchimApi from "../../../services/resources/ShluchimApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";
import moment from "moment";

const ResourcesActivityTableRow = ({ activity }) => {
  const { createdOn, fileName, fileWebViewLink, programName, viewType } =
    activity;
  return (
    <div className="resources-activity-table-row">
      <p>{programName}</p>
      <p>{viewType}</p>
      <p>{moment(createdOn).format("MMM D, YYYY")}</p>
      <a href={fileWebViewLink} target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>
    </div>
  );
};

export default class ResourcesActivityTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
      errorMessage: "",
      loading: false,
      page: 1,
      results: 12,
      success: true,
      total: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getActivity();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getActivity = (page = 1, results = this.state.results) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        success: true,
      },
      async () => {
        try {
          const resourcesActivity =
            await ShluchimApi.getShliachResourcesActivity(
              this.apiSignal.token,
              this.props.shliachId,
              page,
              results,
            );

          this.setState({
            activity: resourcesActivity.results || [],
            total: resourcesActivity.numberOfRows || 0,
          });
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              activity: [],
              errorMessage: ApiCallErrorMessageHandler(err),
              success: false,
              total: 0,
            });
          }
        }

        this.setState({ loading: false });
      },
    );
  };

  render() {
    const { activity, loading, page, results, success, total } = this.state;

    return (
      <PaginatedTable
        loading={loading}
        loadData={this.getActivity}
        page={page}
        preHeaderMargin={4}
        records={activity}
        renderHeader={() => ""}
        renderRow={(activity, index) => (
          <ResourcesActivityTableRow activity={activity} key={index} />
        )}
        results={results}
        success={success}
        totalCount={total}
      />
    );
  }
}
