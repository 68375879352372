import React, { Fragment } from "react";

import { saveAs } from "file-saver";
import { SplitButton, MenuItem } from "react-bootstrap";

import { CsvGeneration } from "../../lib/coc-common-scripts";
import {
  ModalWithButtons /* , ModalButton */,
} from "../../lib/coc-common-components";

// interface ReportObject {
//   [propertyName: string];
// }
// interface CsvGetAndDownloadProps {
//   fileName: string;
//   dataRetrieveFunction?: () => AxiosPromise;
//   dataPath?: string;
//   dataToDownload?: Array<ReportObject>;
//   columnDefinitions?: Array<ColumnConfig>;
//   onAfterDownload?: () => any;
//   twitterButtonTypeName?: string;
//   text?: string;
//   dataTransformation?: (data: Array<ReportObject>) => any;
// }

// interface CsvGetAndDownloadState {
//   downloading: boolean;
//   selectedColumnConfig: Array<ColumnConfig>;
//   selectingColumns: boolean;
// }

// export interface ColumnConfig {
//   labelGroup?: string;
//   columnId: string;
//   dataField?: string;
//   header: string;
//   visible?: boolean;
//   transform?: (value) => any;
// }

class CsvGetAndDownload extends React.Component {
  /** @type {Partial<CsvGetAndDownloadProps>} */
  static defaultProps = {
    columnDefinitions: [],
    text: "Download CSV",
    // className: 'btn btn-primary btn-md clear',
    twitterButtonTypeName: "primary",
  };
  /** @type {Array<ModalButton>} */
  DownloadButtons = [
    {
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.downloadWithConfig(),
    },
    {
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.closeColumnConfigModal(),
    },
  ];

  /** @param {CsvGetAndDownloadProps} */
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
      selectedColumnConfig: this.columnsPropToState(props.columnDefinitions),
      selectingColumns: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleObject = this.handleObject.bind(this);
    this.handleColumnSelection = this.handleColumnSelection.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.downloadWithConfig = this.downloadWithConfig.bind(this);
  }
  /** @param {Array<ColumnConfig>}
   * @returns {Array<ColumnConfig>}
   */
  columnsPropToState(columnDefinitions = []) {
    return columnDefinitions.map((c) => ({
      ...c,
      visible: "visible" in c ? c.visible : true,
    }));
  }

  closeColumnConfigModal() {
    this.setState({ selectingColumns: false });
  }

  downloadWithConfig() {
    this.handleClick();
    this.closeColumnConfigModal();
  }

  /** @returns {ReportObject} */
  handleObject(obj) {
    const returnObj = {};
    (this.state.selectedColumnConfig || []).forEach(
      (column) =>
        column.visible &&
        Object.assign(returnObj, {
          [column.header]: column.transform
            ? column.transform(obj[column.dataField || column.columnId])
            : column.getData
            ? column.getData(obj)
            : obj[column.dataField || column.columnId] || "",
        }),
    );
    return returnObj;
  }

  /** @param {string} */
  stringToArrayOfCharacters(str) {
    const theArray = [];
    for (let i = 0; i < str.length; i++) {
      theArray.push(str[i]);
    }
    return theArray;
  }
  /** @param {Array<ReportObject>} data
   * @returns {Array<ReportObject>}
   */
  addHeadersIfMissing(data = []) {
    const returnData = data.slice();
    const colConfig = this.state.selectedColumnConfig || [];
    if (colConfig.length === 0 && returnData.length > 0) {
      Object.keys(returnData[0]).forEach((k) =>
        (colConfig || []).push({
          columnId: k,
          visible: true,
          header:
            k.length <= 1
              ? k
              : k[0].toUpperCase() +
                this.stringToArrayOfCharacters(k.substr(1))
                  .map((c) => (c === c.toUpperCase() ? " " + c : c))
                  .join(""),
        }),
      );
      this.setState({ selectedColumnConfig: colConfig });
    }
    return returnData;
  }
  /** @param {Array<ReportObject>} dataToDownload */
  downloadData(dataToDownload) {
    saveAs(
      new Blob(
        [CsvGeneration(dataToDownload.map(this.handleObject), null, ",")],
        {
          type: "text/csv;charset=utf-8",
        },
      ),
      this.props.fileName,
      true /*disableAutoBOM*/,
    );
    if (this.props.onAfterDownload) {
      this.props.onAfterDownload();
    }
    this.setState({ downloading: false });
  }

  handleClick() {
    this.setState({ downloading: true }, () => {
      /** @type {Array<ReportObject>} */
      let dataToDownload = [];
      if (this.props.dataRetrieveFunction) {
        this.props
          .dataRetrieveFunction()
          .then((response) => {
            console.log(response);
            dataToDownload = this.addHeadersIfMissing(
              response.data[this.props.dataPath || ""],
            );
            this.downloadData(dataToDownload);
          })
          .catch((err) => {
            this.setState({ downloading: false });
            console.error(err);
          });
      } else if (this.props.dataToDownload) {
        const dataToUse = this.props.dataTransformation
          ? this.props.dataTransformation(this.props.dataToDownload)
          : this.props.dataToDownload;
        dataToDownload = this.addHeadersIfMissing(dataToUse);
        this.downloadData(dataToDownload);
      }
    });
  }

  handleColumnSelection() {
    this.setState({ selectingColumns: true });
  }
  /** @param {string} columnId
   * @param {boolean} newValue
   */
  changeVisibilityStatus(columnId, newValue) {
    const newColumnConfig = (this.state.selectedColumnConfig || []).map((cc) =>
      cc.columnId !== columnId && cc.labelGroup !== columnId
        ? cc
        : { ...cc, visible: newValue },
    );
    this.setState({ selectedColumnConfig: newColumnConfig });
  }
  /** @param {React.FormEvent<HTMLInputElement>} e */
  handleSelectAll(e) {
    const newColumnConfig = (this.state.selectedColumnConfig || []).map(
      (col) => ({
        ...col,
        visible: e.currentTarget.checked,
      }),
    );
    this.setState({ selectedColumnConfig: newColumnConfig });
  }
  /** @param {Array<ColumnConfig>} columns
   * @returns {Array<ColumnConfig>}
   */
  mergeButtons(columns) {
    /** @type {Array<ColumnConfig>} */
    const newColumns = [];
    columns.forEach((c) => {
      const existentColumn = newColumns.find(
        (nc) =>
          nc.columnId === c.columnId ||
          (!!nc.labelGroup && nc.labelGroup === c.labelGroup),
      );
      if (!existentColumn) {
        newColumns.push({ ...c });
      } else {
        existentColumn.labelGroup = existentColumn.labelGroup || c.labelGroup;
      }
    });
    return newColumns;
  }
  /** @param {Array<ColumnConfig>} columns */
  buttonSelectionJSX(columns) {
    return (
      <div>
        <div style={{ display: "grid", gridTemplateColumns: "20px auto" }}>
          <div>
            <input
              type="checkbox"
              id="chkSelectAll"
              checked={
                !(this.state.selectedColumnConfig || []).some(
                  (col) => !col.visible,
                )
              }
              onChange={this.handleSelectAll}
            />
          </div>
          <div>
            <label htmlFor="chkSelectAll">Select All</label>
          </div>
        </div>
        <br />
        <div style={{ maxHeight: "calc(80vh - 210px)", overflow: "auto" }}>
          {this.mergeButtons(columns).map((col) => (
            <Fragment key={col.columnId}>
              {/* display a category label for the first column in the category */}
              {col.category && columns.find(c => c.category === col.category)?.columnId === col.columnId ? (
                <label style={{ width: "100%", borderBottom: "2px solid #ebebeb", margin: "4px 0px" }}>{col.category}</label>
              ) : ""}
              <div style={{ display: "grid", gridTemplateColumns: "20px auto" }} >
                <div>
                  <input
                    type="checkbox"
                    id={"col" + col.columnId}
                    checked={col.visible || false}
                    onChange={(e) =>
                      this.changeVisibilityStatus(
                        col.labelGroup || col.columnId,
                        e.currentTarget.checked,
                      )
                    }
                  />
                </div>
                <div>
                  <label htmlFor={"col" + col.columnId}>
                    {col.labelGroup || col.header || col.columnId}
                  </label>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    const currentColumns = this.props.columnDefinitions || [];
    const newColumns = nextProps.columnDefinitions || [];
    let updateColumns = currentColumns.length !== newColumns.length;
    if (!updateColumns) {
      for (let ix = 0; ix < currentColumns.length; ix++) {
        if (currentColumns[ix].visible !== newColumns[ix].visible) {
          updateColumns = true;
          break;
        }
      }
    }
    if (updateColumns) {
      this.setState({
        selectedColumnConfig: this.columnsPropToState(
          nextProps.columnDefinitions,
        ),
      });
    }
  }

  render() {
    return (
      <div>
        <SplitButton
          bsStyle={this.props.twitterButtonTypeName}
          id="downloadCSV"
          title={
            this.state.downloading ? (
              "Generating..."
            ) : (
              <span>
                <i className="icon icon-csv-blue">
                  <span className="path1" />
                  <span className="path2" />
                </i>{" "}
                {this.props.text}
              </span>
            )
          }
          onClick={this.handleClick}
          disabled={this.state.downloading}
        >
          <MenuItem eventKey="1" onClick={this.handleClick}>
            {this.props.text}
          </MenuItem>
          <MenuItem eventKey="2" onClick={this.handleColumnSelection}>
            Choose columns
          </MenuItem>
        </SplitButton>
        {this.state.selectingColumns && (
          <ModalWithButtons
            buttons={this.DownloadButtons}
            className={"columns-to-export-modal"}
            text={"Select columns to include"}
            content={
              <div className="column-selection">
                {this.buttonSelectionJSX(this.state.selectedColumnConfig || [])}
              </div>
            }
          />
        )}
      </div>
    );
  }
}

export default CsvGetAndDownload;
