import React from "react";
import Autocomplete from "react-autocomplete";
import DatePicker from "react-datepicker";
import Loader from "../../../../common/Loader";
import Modal from "react-modal";
import StudentProfileModal from "../../../../Student/studentProfile/StudentProfileModal";

import AuthClient from "../../../../../services/resources/AuthClient";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import {
  formatFullName,
  removeTimezoneFormatFromDate,
  removeTimezoneFormatFromDateStartOfDay,
} from "../../../../../lib";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";
import _cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";

export default class AddRegistrationModal extends React.Component {
  state = {
    errorMessage: "",
    loading: false,

    registerSpouse: false,
    registrationDate: removeTimezoneFormatFromDate(moment()), //default date to today
    registrationPersonID: "",

    searchSuggestions: [],
    searchSuggestionsErrorMessage: "",
    searchSuggestionsLoading: false,
    searchText: "",

    showCreateStudentModal: false,
  };

  initialState = _cloneDeep(this.state);

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onClose = () => {
    this.setState(this.initialState, this.props.close);
  };

  onSearch = (e) => {
    this.setState(
      {
        registrationPersonID: null,
        searchSuggestions: [],
        searchText: e.currentTarget.value,
      },
      () => {
        this.onSearchDebounce();
      },
    );
  };

  onSearchDebounce = debounce(this.getSearchSuggestions, 500);
  async getSearchSuggestions() {
    const {
      programSchedule: { registrationType },
    } = this.props;
    const { searchText } = this.state;
    if (searchText) {
      try {
        this.setState({
          searchSuggestionsErrorMessage: "",
          searchSuggestionsLoading: true,
        });

        const searchEndpoint =
          registrationType === "Student" ? "Students/basic" : "Shluchim";
        const response = await AuthClient.get(
          `${searchEndpoint}?${queryString.stringify({
            text: searchText,
            results: 25,
          })}`,
          {
            cancelToken: this.apiSignal.token,
          },
        );
        const results = response.data.payload || [];
        this.setState({
          searchSuggestions: results.map((r) => ({
            ...r,
            fullName:
              registrationType === "Student"
                ? [
                    formatFullName(r.firstName, r.lastName),
                    r.campusName,
                    formatFullName(r.shliachFirstName, r.shliachLastName),
                  ]
                    .filter((n) => n)
                    .join(" - ")
                : r.fullName,
          })),
          searchSuggestionsLoading: false,
        });
      } catch (err) {
        this.setState({
          searchSuggestionsErrorMessage: ApiCallErrorMessageHandler(err),
          searchSuggestionsLoading: false,
        });
      }
    }
  }

  onSubmit = async () => {
    const { registerSpouse, registrationDate, registrationPersonID } =
      this.state;

    if (!registrationPersonID) {
      return;
    }

    this.setState({
      errorMessage: "",
      loading: true,
    });

    const { error, errorMessage } = await this.props.submit(
      {
        registrationPersonID,
        registrationDate: registrationDate,
      },
      registerSpouse,
    );
    if (error) {
      this.setState({
        errorMessage,
        loading: false,
      });
      return;
    }

    this.onClose();
  };

  render() {
    const {
      programSchedule: { registrationType },
      show,
    } = this.props;

    const {
      errorMessage,
      loading,
      registerSpouse,
      registrationDate,
      registrationPersonID,
      searchSuggestions,
      searchSuggestionsErrorMessage,
      searchSuggestionsLoading,
      searchText,
      showCreateStudentModal,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card add-shliach-modal medium-modal">
          <p className="large-text fw-500 mb-16">Add {registrationType}</p>
          <Autocomplete
            autoHighlight={false}
            getItemValue={(s) => s.personID}
            items={searchSuggestions}
            onChange={this.onSearch}
            onSelect={(value, item) => {
              this.setState({
                errorMessage: "",
                registrationPersonID: value,
                searchText: item.fullName,
              });
            }}
            renderInput={(passProps) => (
              <div className="search-input full-width">
                <input
                  name="se"
                  placeholder={`Search ${registrationType} name`}
                  type="text"
                  style={{ fontSize: "14px" }}
                  {...passProps}
                />
              </div>
            )}
            renderItem={(item, isHighlighted) => (
              <div
                key={item.personID}
                style={{
                  background: isHighlighted ? "#f5f5f5" : "white",
                  cursor: "pointer",
                  padding: "8px 16px",
                }}
              >
                {item.fullName} - {item.chabadHouseName} - {item.campusName}
              </div>
            )}
            renderMenu={(items) => (
              <div className="autocomplete-menu full-width">{items}</div>
            )}
            wrapperProps={{
              className: "search-input-container relative",
              style: { width: "100%" },
            }}
            value={searchText}
          />
          <p className="accent-text small-text mt-4">
            {searchSuggestionsLoading && "Searching..."}* Note only first 25
            results will be shown
          </p>
          {searchSuggestionsErrorMessage && (
            <p className="error-text">{searchSuggestionsErrorMessage}</p>
          )}
          <div className="flex flex-align-center mt-16">
            <p className="accent-text small-text mr-8">Date Participated</p>
            <DatePicker
              className="custom-input"
              clearable={false}
              onChange={(date) => {
                this.setState({
                  registrationDate:
                    removeTimezoneFormatFromDateStartOfDay(date),
                });
              }}
              selected={registrationDate ? moment(registrationDate) : null}
            />
          </div>
          {registrationType === "Student" ? (
            <div>
              <p className="fw-700 accent-text small-text mt-24">
                Can't find the student you're looking for?{" "}
                <span
                  className="link-text"
                  onClick={() =>
                    this.setState({ showCreateStudentModal: true })
                  }
                >
                  Create new student here
                </span>
              </p>
              <StudentProfileModal
                close={() => this.setState({ showCreateStudentModal: false })}
                show={showCreateStudentModal}
              />
            </div>
          ) : (
            <div className="flex flex-align-center custom-checkbox-container registration-spouse-checkbox-container mt-16">
              <input
                className="custom-checkbox"
                id="register-spouse-checkbox"
                onChange={(e) =>
                  this.setState({ registerSpouse: e.target.checked })
                }
                type="checkbox"
                value={registerSpouse}
              />
              <label
                className="flex-align-center"
                htmlFor="register-spouse-checkbox"
              >
                Include Spouse in registration
              </label>
            </div>
          )}
          <div className="text-right mt-24">
            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <button
                  className="btn link-text uppercase-text"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn link-text uppercase-text ml-24"
                  disabled={!registrationPersonID}
                  onClick={this.onSubmit}
                >
                  Add
                </button>
              </React.Fragment>
            )}
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </Modal>
    );
  }
}
