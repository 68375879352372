import AuthRequest from "./AuthRequest";
import AuthClient from "./AuthClient";

const baseUrl = "/api/schools";

// interface SchoolSearchParams {
//     basicListData?: boolean;
//     filterCountries?: string;
//     filterStates?: string;
//     filterAssociation?: string;
// }

const SchoolsApi = {
  get: (id) => {
    return AuthRequest.get(`${baseUrl}/${id}`);
  },
  /** @param {string} searchQuery
   * @param {number} maxResults
   */
  getSchoolsNames: (searchQuery, maxResults) => {
    return AuthRequest.get(`${baseUrl}/schoolsnames`, {
      searchQuery,
      maxResults,
    });
  },
  /** @param {SchoolSearchParams} searchParams */
  search: (searchQuery, searchParams) => {
    return AuthRequest.get(baseUrl, { searchQuery, ...searchParams });
  },
  list: () => {
    return AuthRequest.get(baseUrl, {});
  },
  create: (school) => {
    return AuthRequest.post(`${baseUrl}`, { ...school });
  },
  updateStatus: (school) => {
    return AuthClient.post(`Campuses/${school.id}/restore`); // TO DO Refactor to get rid of hard-coding
  },
  /** @param {number} id */
  delete: (id) => {
    return AuthRequest.delete(`${baseUrl}/${id}`);
  },
};

export default SchoolsApi;
