import React from "react";

// import { Contact } from "../../../models/Contact";
import ContactUtils from "../../../components/Contact/ContactUtils";
import RelatedContactManager from "./RelatedContactManager";

// interface RelatedContactListProps {
//   contacts?: Array<Contact>;
//   handleContactSelected: (id: number) => void;
//   handleContactChange: (contact: Contact) => void;
//   handleContactAdded: () => void;
//   handleContactRemoved: (id: number) => void;
// }

const RelatedContactList = ({
  contacts = [],
  handleContactSelected,
  handleContactChange,
  handleContactAdded,
  handleContactRemoved,
}) => {
  return (
    <div>
      <table style={{ width: "100%" }}>
        <colgroup>
          <col style={{ width: "30%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "15%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Email</th>
            <th>Mobile</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => [
            <tr key={contact.id}>
              <td>
                <span
                  onClick={(evt) => handleContactSelected(contact.id)}
                  style={{ color: "#337ab7", cursor: "pointer" }}
                >
                  {contact.firstName + " " + contact.lastName}
                </span>
              </td>
              <td>{contact.title}</td>
              <td>
                {
                  ContactUtils.displayableContactMethods(contact.contactMethods)
                    .email
                }
              </td>
              <td>
                {
                  ContactUtils.displayableContactMethods(contact.contactMethods)
                    .cellNumber
                }
              </td>
              <td>
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginLeft: "0.5em" }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    handleContactRemoved(contact.id);
                  }}
                >
                  <i className="glyphicon glyphicon-trash" />
                </button>
              </td>
            </tr>,
            <tr
              key={"mgr" + contact.id}
              style={{ padding: "5px", borderBottom: "1px solid lightGray" }}
            >
              <td colSpan={5}>
                {contact.isBeingEdited && (
                  <RelatedContactManager
                    contactData={contact}
                    handleContactChange={handleContactChange}
                    ddStyles={{ paddingBottom: "3px" }}
                  />
                )}{" "}
              </td>
            </tr>,
          ])}
        </tbody>
      </table>
      <button
        className="btn btn-primary btn-sm"
        onClick={(evt) => {
          evt.preventDefault();
          handleContactAdded();
        }}
      >
        Add Contact
      </button>
    </div>
  );
};

export default RelatedContactList;
