import React from "react";
import ExportCSVButton from "../../../../shared/ExportCSVButton";
import { formatExcelNumberString } from "../../../../../lib";
import moment from "moment";

export default class RegistrationsTableActions extends React.PureComponent {
  isShliachRegistration = () =>
    this.props.programSchedule.registrationType === "Shliach";

  getUnenrolledExportHeaders = () => {
    return [
      { label: "Shliach ID", value: "shliachID" },
      { label: "First Name", value: "firstName" },
      { label: "Last Name", value: "lastName" },
      { label: "Email", value: "email" },
      { label: "Cell", value: "cell" },
      { label: "Spouse Shliach ID", value: "spouseShliachID" },
      { label: "Spouse First Name", value: "spouseFirstName" },
      { label: "Spouse Last Name", value: "spouseLastName" },
      { label: "Spouse Email", value: "spouseEmail" },
      { label: "Spouse Cell", value: "spouseCell" },
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      { label: "Chabad House City", value: "chabadHouseCity" },
      { label: "Chabad House State", value: "chabadHouseState" },
      { label: "Chabad House Country", value: "chabadHouseCountry" },
      { label: "Category", value: "category" },
    ];
  };

  getRegistrationsExportHeaders = (
    applicationSettings,
    isJotformRegistration
  ) => {
    const headers = [
      this.isShliachRegistration()
        ? { label: "Shliach ID", value: "shliachID" }
        : { label: "Student ID", value: "studentID" },
      { label: "First Name", value: "firstName" },
      { label: "Last Name", value: "lastName" },
      { label: "Email", value: "email" },
      { label: "Cell", value: "cell" },
      { label: "Address 1", value: "address1" },
      { label: "Address 2", value: "address2" },
      { label: "City", value: "city" },
      { label: "State", value: "state" },
      { label: "Zip", value: ({ zip }) => formatExcelNumberString(zip) },
      ...(this.isShliachRegistration()
        ? [
            { label: "Spouse Shliach ID", value: "spouseShliachID" },
            { label: "Spouse First Name", value: "spouseFirstName" },
            { label: "Spouse Last Name", value: "spouseLastName" },
            { label: "Spouse Email", value: "spouseEmail" },
            { label: "Spouse Cell", value: "spouseCell" },
            { label: "Year Started Shlichus", value: "yearStartedShlichus" },
            { label: "Chabad House ID", value: "chabadHouseID" },
            { label: "Chabad House", value: "chabadHouseName" },
          ]
        : []),
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
    ];

    if (isJotformRegistration) {
      headers.push({
        label: "Jotform Submission ID",
        value: "joformSubmissionID",
      });
    }

    if (applicationSettings) {
      headers.push(
        { label: "Status", value: "applicationStatusDisplay" },
        {
          label: "Applied On",
          value: ({ registrationDate }) =>
            moment(registrationDate).format("MM/DD/YYYY"),
        }
      );

      const { paymentInstallments, statusesWithDisplay, items } =
        applicationSettings;

      if (statusesWithDisplay) {
        statusesWithDisplay
          .filter((s) => s.enumValue !== "Applied")
          .forEach((s) =>
            headers.push({
              label: `${s.displayValue} on`,
              value: ({ applicationHistory }) => {
                const statusDateTime =
                  applicationHistory &&
                  applicationHistory.find((h) => h.status === s.enumValue)
                    ?.dateTime;
                return statusDateTime
                  ? moment(statusDateTime).format("MM/DD/YYYY")
                  : null;
              },
            })
          );
      }

      if (paymentInstallments) {
        headers.push(
          { label: "Payment Installments", value: "totalInstallments" },
          { label: "Total Paid", value: "totalPaid" }
        );
        Array(paymentInstallments)
          .fill()
          .forEach((_, i) =>
            headers.push(
              {
                label: `Payment #${i + 1} Date`,
                value: ({ payments }) => {
                  const paymentDate = payments && payments[i]?.paymentDate;
                  return paymentDate
                    ? moment(paymentDate).format("MM/DD/YYYY")
                    : null;
                },
              },
              {
                label: `Payment #${i + 1} Amount`,
                value: ({ payments }) => payments && payments[i]?.amount,
              }
            )
          );
      }
      if (items) {
        items.map((item) =>
          headers.push({
            label: item.name,
            value: ({ itemQuantities }) =>
              itemQuantities &&
              itemQuantities.find((iq) => iq.name === item.name)?.quantity,
          })
        );
      }
    } else {
      headers.push({
        label: "Registered On",
        value: ({ registrationDate }) =>
          moment(registrationDate).format("MM/DD/YYYY"),
      });
    }

    return headers;
  };

  getJotformSubmissionsExportHeaders = (data) => {
    //build form questions list with all questions within data records
    const jotformQuestions = [];
    data &&
      data.forEach((s) =>
        s.jotformAnswers?.forEach(({ questionText }) => {
          if (jotformQuestions.indexOf(questionText) < 0) {
            jotformQuestions.push(questionText);
          }
        })
      );

    return [
      { label: "Shliach ID", value: "registration.shliachID" },
      { label: "First Name", value: "registration.firstName" },
      { label: "Last Name", value: "registration.lastName" },
      { label: "Email", value: "registration.email" },
      { label: "Cell", value: "registration.cell" },
      {
        label: "Spouse Shliach ID",
        value: "registration.spouseShliachID",
      },
      {
        label: "Spouse First Name",
        value: "registration.spouseFirstName",
      },
      { label: "Spouse Last Name", value: "registration.spouseLastName" },
      { label: "Spouse Email", value: "registration.spouseEmail" },
      { label: "Spouse Cell", value: "registration.spouseCell" },
      { label: "Position", value: "registration.positionDisplay" },
      {
        label: "Year Started Shlichus",
        value: "registration.yearStartedShlichus",
      },
      { label: "Category", value: "registration.outreach" },
      { label: "Number of Children", value: "registration.numberOfChildren" },
      { label: "Campus ID", value: "registration.campusID" },
      { label: "Campus", value: "registration.campusName" },
      { label: "Chabad House ID", value: "registration.chabadHouseID" },
      { label: "Chabad House", value: "registration.chabadHouseName" },
      { label: "Chabad House Phone", value: "registration.chabadHousePhone" },
      {
        label: "Chabad House Address 1",
        value: "registration.chabadHouseAddress1",
      },
      {
        label: "Chabad House Address 2",
        value: "registration.chabadHouseAddress2",
      },
      { label: "Chabad House City", value: "registration.chabadHouseCity" },
      { label: "Chabad House State", value: "registration.chabadHouseState" },
      {
        label: "Chabad House Zip",
        value: ({ registration: { chabadHouseZip } }) =>
          formatExcelNumberString(chabadHouseZip),
      },
      {
        label: "Chabad House Country",
        value: "registration.chabadHouseCountry",
      },
      {
        label: "Chabad House Region",
        value: "registration.chabadHouseRegionName",
      },
      {
        label: "Chabad House Timezone",
        value: "registration.chabadHouseTimezoneName",
      },
      {
        label: "Chabad House Date Established",
        value: ({ registration: { chabadHouseDateEstablished } }) =>
          chabadHouseDateEstablished
            ? moment(chabadHouseDateEstablished).format("MM/DD/YYYY")
            : "",
      },
      {
        label: "Chabad House Website URL",
        value: "registration.chabadHouseWebsiteURL",
      },
      {
        label: "Registration Date",
        value: ({ registration: { registrationDate } }) =>
          moment(registrationDate).format("MM/DD/YYYY"),
      },
      {
        label: "Jotform Submission ID",
        value: "registration.jotformSubmissionID",
      },
      ...jotformQuestions.map((question, qIndex) => ({
        key: `question-${qIndex}`,
        label: question,
        value: ({ jotformAnswers }) => {
          const answer = jotformAnswers?.find(
            (a) => a.questionText === question
          );
          if (answer) {
            return answer.answerText;
          }
          return "";
        },
      })),
    ];
  };

  exportRegistrationsButton = (isJotformRegistration) => {
    const {
      getRegistrationsForExport,
      programSchedule: {
        applicationSettings,
        hasApplicationProcess,
        programName,
        scheduleName,
      },
      showEnrolled,
    } = this.props;

    const className = isJotformRegistration // ie, export buttons are grouped
      ? "small-text link-text-secondary"
      : "btn custom-btn btn-accent uppercase-text flex flex-align-center";
    return (
      <ExportCSVButton
        className={className}
        fileName={`${scheduleName || programName}_Registrations`}
        getExportData={getRegistrationsForExport}
        headers={
          !showEnrolled
            ? this.getUnenrolledExportHeaders()
            : this.getRegistrationsExportHeaders(
                hasApplicationProcess && applicationSettings,
                isJotformRegistration
              )
        }
      />
    );
  };

  exportJotformDataButton = () => {
    const {
      getJotformSubmissionsForExport,
      programSchedule: { programName, scheduleName },
    } = this.props;

    return (
      <ExportCSVButton
        className="small-text mb-0 link-text-secondary"
        fileName={`${scheduleName || programName}_Jotform_Submissions`}
        getExportData={getJotformSubmissionsForExport}
        getHeaders={this.getJotformSubmissionsExportHeaders}
        title="Export Jotform Data"
      />
    );
  };

  render() {
    const {
      onAddGroupPayment,
      onAddRegistration,
      onImportRegistrations,
      programSchedule: {
        applicationSettings,
        hasApplicationProcess,
        registrationMethod,
      },
    } = this.props;

    const isJotformRegistration = registrationMethod === "Jotform";

    return (
      <div className="flex">
        <button
          className="btn custom-btn btn-accent uppercase-text flex flex-align-center mr-16"
          onClick={onAddRegistration}
        >
          Add
        </button>
        {this.isShliachRegistration() && (
          <button
            className="btn custom-btn btn-accent uppercase-text flex flex-align-center mr-16"
            onClick={onImportRegistrations}
          >
            Import
          </button>
        )}
        {hasApplicationProcess &&
          applicationSettings?.paymentInstallments > 0 && (
            <button
              className="btn custom-btn btn-accent uppercase-text flex flex-align-center mr-16"
              onClick={onAddGroupPayment}
            >
              Add Group Payment
            </button>
          )}
        {isJotformRegistration ? (
          <div className="dropdown-container mr-8">
            <button className="btn custom-btn btn-accent uppercase-text flex flex-align-center mr-16">
              Export
              <i className="material-icons ml-8">arrow_drop_down</i>
            </button>
            <div className="dropdown btn-dropdown csv-export-dropdown">
              {this.exportRegistrationsButton(isJotformRegistration)}
              {this.exportJotformDataButton(isJotformRegistration)}
            </div>
          </div>
        ) : (
          this.exportRegistrationsButton(isJotformRegistration)
        )}
      </div>
    );
  }
}
