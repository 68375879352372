import React from "react";
import { Link } from "react-router";
import Radio from "../../../../shared/Radio";
import { formatFullName } from "../../../../../lib/utils";
import EnrollmentAttendees from "./EnrollmentAttendees";

function EnrollmentGeneralSettings(props) {
  const {
    enrollment: {
      acceptancePolicyOverride,
      attendees,
      campuses,
      chabadHouseName,
      enrolledByShliachID,
      shliachCampuses,
      shliachFirstName,
      shliachLastName,
    },
  } = props;

  return (
    <div>
      <div>
        <Link
          className="xl-text link-text-secondary fw-700 mb-0"
          to={`/shluchim/${enrolledByShliachID}`}
        >
          {formatFullName(shliachFirstName, shliachLastName)}
        </Link>
        <p className="medium-text mb-32">{chabadHouseName}</p>
      </div>
      <div className="trip-page-form-section">
        <p className="medium-text fw-700 mb-8">Participating Schools</p>
        <p className="accent-text-dark mb-16">
          Select all schools that will be participating
        </p>
        <div>
          {shliachCampuses && shliachCampuses.length > 0 ? (
            shliachCampuses.map((campus) => (
              <div
                className="flex custom-checkbox-container mb-12"
                key={campus.name}
              >
                <input
                  className="custom-checkbox"
                  checked={campuses.map((c) => c.id).indexOf(campus.id) >= 0}
                  id={`school-${campus.name}`}
                  name="participatingSchools"
                  readOnly
                  type="checkbox"
                />
                <label
                  className="flex-align-center fw-400"
                  htmlFor={`school-${campus.name}`}
                >
                  {campus.name}
                </label>
              </div>
            ))
          ) : (
            <span>No Schools for your Chabad House</span>
          )}
        </div>
      </div>
      <EnrollmentAttendees
        attendees={attendees}
      />
      <div className="trip-page-form-section">
        <p className="medium-text fw-700 mb-16">Acceptance Process</p>
        <p className="accent-text-dark mb-24">
          Opt in or opt out of having an acceptance process where students
          registration is not complete until you accept or reject them.
        </p>
        <Radio
          name="acceptancePolicyOverride"
          options={[
            {
              value: "OptIn",
              display: "Yes, I'm in",
            },
            {
              value: "OptOut",
              display: "No thanks",
            },
          ]}
          readOnly={true}
          value={acceptancePolicyOverride}
        />
      </div>
    </div>
  );
}
export default React.memo(EnrollmentGeneralSettings);
