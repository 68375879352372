import React from "react";
import DatePicker from "react-datepicker";
import Loader from "../../../../common/Loader";
import Modal from "react-modal";
import NumberFormat from "react-number-format";

import {
  removeTimezoneFormatFromDate,
  removeTimezoneFormatFromDateStartOfDay,
} from "../../../../../lib";
import axios from "axios";
import moment from "moment";
import _cloneDeep from "lodash.clonedeep";

export default class AddGroupPaymentModal extends React.Component {
  state = {
    errorMessage: "",
    loading: false,
    paymentAmount: "",
    paymentDate: removeTimezoneFormatFromDate(moment()), //default to today
    submitAttempted: false,
  };

  initialState = _cloneDeep(this.state);

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onClose = () => {
    this.setState(this.initialState, this.props.close);
  };

  onSubmit = async () => {
    const { paymentAmount, paymentDate } = this.state;

    this.setState({ submitAttempted: true });

    if (!paymentAmount || !paymentDate) {
      return;
    }

    this.setState({
      errorMessage: "",
      loading: true,
    });

    const { error, errorMessage } = await this.props.submit({
      amount: paymentAmount,
      paymentDate,
    });
    if (error) {
      this.setState({
        errorMessage,
        loading: false,
      });
      return;
    }

    this.onClose();
  };

  render() {
    const { show } = this.props;

    const {
      errorMessage,
      loading,
      paymentAmount,
      paymentDate,
      submitAttempted,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card add-shliach-modal medium-modal">
          <p className="large-text fw-500 mb-16">Add Payment</p>
          <div className="flex flex-align-center mb-16">
            <p
              className="accent-text small-text mr-8"
              style={{ width: "144px" }}
            >
              Amount
            </p>
            <NumberFormat
              allowNegative={false}
              className={`custom-input dollar-input ${
                submitAttempted && !paymentAmount ? "error" : ""
              }`}
              onValueChange={({ floatValue }) =>
                this.setState({
                  paymentAmount: floatValue >= 0 ? floatValue : "",
                })
              }
              decimalScale={2}
              thousandSeparator={true}
              value={paymentAmount}
            />
          </div>
          <div className="flex flex-align-center">
            <p
              className="accent-text small-text mr-8"
              style={{ width: "144px" }}
            >
              Payment Date
            </p>
            <DatePicker
              className={`custom-input ${
                submitAttempted && !paymentDate ? "error" : ""
              }`}
              clearable={false}
              onChange={(date) => {
                this.setState({
                  paymentDate: removeTimezoneFormatFromDateStartOfDay(date),
                });
              }}
              selected={paymentDate ? moment(paymentDate) : null}
            />
          </div>
          <div className="text-right mt-24">
            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <button
                  className="btn link-text uppercase-text"
                  onClick={this.onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn link-text uppercase-text ml-24"
                  onClick={this.onSubmit}
                >
                  Add
                </button>
              </React.Fragment>
            )}
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </Modal>
    );
  }
}
