import React from "react";

export default ({
  allowNoOption,
  className,
  disabled,
  error,
  name,
  noOptionValue,
  onChange,
  options,
  readOnly,
  tabIndex,
  value,
}) => (
  <div
    className={`radio-container ${className ? className : ""} ${
      disabled ? "disabled" : readOnly ? "readonly" : ""
    } ${error ? "error" : ""}`}
  >
    {options &&
      options.map((option) => (
        <React.Fragment key={option.value}>
          <input
            type="radio"
            disabled={disabled || option.disabled || readOnly}
            id={`${name}-${option.value}`}
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={() => onChange(name, option.value)} //to avoid default toString value
            onClick={
              allowNoOption && value === option.value //'deselect' handler when no option is allowed
                ? () => onChange(name, noOptionValue || "")
                : undefined
            }
            tabIndex={tabIndex}
          />
          <label
            className={disabled || option.disabled ? "disabled" : ""}
            htmlFor={`${name}-${option.value}`}
            style={{ minWidth: "96px", padding: "0 16px" }}
          >
            {option.display}
          </label>
        </React.Fragment>
      ))}
  </div>
);
