import * as React from "react";
import "./ReportingTile.css";

export function ReportingTile({ label, value }) {
  return (
    <div className="well reporting-stats text-center">
      <p className="reporting-stats-value">{value}</p>
      <p className="reporting-stats-label">{label}</p>
    </div>
  );
}

export function ReportingTileStyled({
  label,
  value,
  labelStyle = {},
  valueStyle = {},
}) {
  return (
    <div className="well reporting-stats text-center">
      <div style={valueStyle} className="reporting-stats-value">
        {value}
      </div>
      <p style={labelStyle} className="reporting-stats-label">
        {label}
      </p>
    </div>
  );
}
