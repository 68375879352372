import React from "react";
import { EnumToArrayOfObjects } from "../../../../lib/coc-common-scripts";

import {
  /* OrganizationCategory, */ CategoryTypes,
} from "../../../../models/Organization";

const ddStyles = {
  paddingBottom: "3px",
};

// interface OrganizationCategoryManagerProps {
//   organizationCategoryData: OrganizationCategory;
//   handleDataChange: React.FormEventHandler<HTMLElement>;
// }

const OrganizationCategoryManager = ({
  organizationCategoryData,
  handleDataChange,
}) => {
  return (
    <div>
      <dl className="dl-horizontal">
        <dt>Name</dt>
        <dd style={ddStyles}>
          <input
            type="text"
            name="name"
            value={organizationCategoryData.name || ""}
            onChange={handleDataChange}
          />
        </dd>
        <dt>Type</dt>
        <dd style={ddStyles}>
          <select
            name="type"
            value={organizationCategoryData.type || ""}
            onChange={handleDataChange}
          >
            {EnumToArrayOfObjects(CategoryTypes).map((ct) => (
              <option key={ct.code} value={ct.code}>
                {ct.value}
              </option>
            ))}
          </select>
        </dd>
      </dl>
    </div>
  );
};

export default OrganizationCategoryManager;
