import React from "react";
import ConfirmationModal from "../../shared/ConfirmationModal";
import PaginatedTable from "../../shared/PaginatedTable";
import AddLocationModal from "./AddLocationModal";
import LocationsTableActions from "./LocationsTableActions";
import LocationsTableFilters from "./LocationsTableFilters";
import LocationsTableHeader from "./LocationsTableHeader";
import LocationsTableRow from "./LocationsTableRow";

import RsvpApi from "../../../services/resources/RsvpApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class LocationsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      filters: {
        keyword: "",
      },
      loading: false,
      locations: [],
      page: 1,
      removeLocation: null,
      removeLocationErrorMessage: null,
      removeLocationLoading: false,
      results: 12,
      showRemoveLocationModal: false,
      showAddLocationModal: false,
      sortBy: this.sortOptions[0].value,
      success: true,
      totalLocations: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterLocations = (filters) => {
    this.setState({ filters }, () => {
      this.getLocations();
    });
  };

  getLocations = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        RsvpApi.getRsvpEnrollments(
          this.apiSignal.token,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ chabadHouses, totalChabadHouses }) => {
            this.setState({
              loading: false,
              locations: chabadHouses,
              totalLocations: totalChabadHouses,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                locations: [],
                success: false,
                totalLocations: 0,
              });
            }
          });
      },
    );
  };

  getLocationsForExport = async () => {
    const { sortBy, filters } = this.state;

    const locationsForExport = await RsvpApi.getRsvpEnrollments(
      this.apiSignal.token,
      undefined,
      undefined,
      sortBy,
      filters,
      true,
    );

    return locationsForExport.chabadHouses;
  };

  submitNewLocation = async (chabadHouseId) => {
    const submitLocationResponse = {};

    try {
      await RsvpApi.submitRsvpEnrollment(this.apiSignal.token, chabadHouseId);
      submitLocationResponse.success = true;

      //reload locations
      this.getLocations();
    } catch (err) {
      if (!axios.isCancel(err)) {
        submitLocationResponse.error = err;
        submitLocationResponse.errorMessage = ApiCallErrorMessageHandler(err);
      }
    }

    return submitLocationResponse;
  };

  removeLocation = async () => {
    this.setState({
      removeLocationErrorMessage: "",
      removeLocationLoading: true,
    });

    try {
      await RsvpApi.removeRsvpEnrollment(
        this.apiSignal.token,
        this.state.removeLocation.chabadHouseID,
      );

      //reload locations:
      const { page, results, sortBy } = this.state;
      this.getLocations(page, results, sortBy);

      this.setState({
        removeLocation: null,
        removeLocationLoading: false,
        showRemoveLocationModal: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          removeLocationErrorMessage: ApiCallErrorMessageHandler(err),
          removeLocationLoading: false,
        });
      }
    }
  };

  sortOptions = [
    { id: "shliachLastName", name: "Shliach Name" },
    { id: "shluchaLastName", name: "Shlucha Name" },
    { id: "chabadHouseName", name: "Chabad House Name" },
    { id: "campusName", name: "Campus Name" },
    { id: "memberSince", name: "Member Since" },
  ];

  render() {
    const { chabadHouses, mobileMode, rsvpSystem } = this.props;

    const {
      loading,
      locations,
      page,
      removeLocation,
      removeLocationErrorMessage,
      removeLocationLoading,
      results,
      showAddLocationModal,
      showRemoveLocationModal,
      sortBy,
      success,
      totalLocations,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Locations</p>
          <LocationsTableActions
            getLocationsForExport={this.getLocationsForExport}
            onAddLocation={() => this.setState({ showAddLocationModal: true })}
            rsvpSystem={rsvpSystem}
          />
        </div>
        <div className="rsvp-locations-table">
          <PaginatedTable
            filterComponent={
              <LocationsTableFilters applyFilters={this.filterLocations} />
            }
            loading={loading}
            loadData={this.getLocations}
            mobileMode={mobileMode}
            page={page}
            records={locations}
            renderHeader={() => <LocationsTableHeader />}
            renderRow={(location, index) => (
              <LocationsTableRow
                rsvpSystemScheduleId={rsvpSystem.programScheduleID}
                key={index}
                location={location}
                onRemoveLocation={() =>
                  this.setState({
                    removeLocation: location,
                    showRemoveLocationModal: true,
                  })
                }
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            sortOptions={this.sortOptions}
            success={success}
            totalCount={totalLocations}
          />
        </div>

        <AddLocationModal
          chabadHouses={chabadHouses}
          close={() => this.setState({ showAddLocationModal: false })}
          show={showAddLocationModal}
          submit={this.submitNewLocation}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({
              removeLocation: null,
              removeLocationErrorMessage: "",
              showRemoveLocationModal: false,
            })
          }
          confirm={this.removeLocation}
          errorMessage={removeLocationErrorMessage}
          loading={removeLocationLoading}
          message={`Are you sure you'd like to remove ${
            removeLocation ? removeLocation.chabadHouseName : "this location"
          }'s access to RSVP System?`}
          show={showRemoveLocationModal}
        />
      </div>
    );
  }
}
