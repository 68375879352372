import React from "react";
import Select from "../../../shared/CustomSelect";
import ValidatedInput from "../../../shared/ValidatedInput";

export default class OtherInfo extends React.PureComponent {
  render() {
    const {
      getProfileFieldLabel,
      isProfileFieldRequired,
      onChangeProfile,
      profile: { tShirtSize } = {},
      showFormValidation,
      tShirtSizes,
    } = this.props;

    return (
      <div className="student-profile-form-section">
        <p className="profile-section-title">Other information</p>
        <div className="student-profile-form-grid">
          <ValidatedInput
            className="input-container"
            input={
              <Select
                classNamePrefix="input-container-select"
                options={
                  tShirtSizes &&
                  tShirtSizes.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Size"
              />
            }
            label={getProfileFieldLabel("Preferred Shirt Size", "tShirtSize")}
            name="tShirtSize"
            onChange={onChangeProfile}
            required={isProfileFieldRequired("tShirtSize")}
            showRequired={showFormValidation}
            value={tShirtSize}
          />
        </div>
      </div>
    );
  }
}
