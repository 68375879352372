import React from "react";
import { FieldArray } from "formik";

import FormInput from "../../Form/FormInput";
import FormInputUpdate from "../../Form/FormInputUpdate";

export default class ShliachActivityInfo extends React.PureComponent {
  state = {
    shliachActivityInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        shliachActivityInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      handleChange,
      shliach: { person = {} },
      sys: { shliachCategories = [], demographics },
      touched,
      values: {
        outreach: { shliachOutreach = {} },
      },
    } = this.props;
    const shliachFirstName = person.firstName;

    return (
      <div
        className={
          this.state.shliachActivityInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              shliachActivityInfoShowing:
                !this.state.shliachActivityInfoShowing,
            });
          }}
        >
          {shliachFirstName || "Shliach"}'s Shlichus Activities
          <i className="material-icons desktop-hidden tablet-hidden">
            {this.state.shliachActivityInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <div className="profile-form-inputs profile-outreach-form-inputs">
          <div className="mb-24">
            <div className="radio-button-options">
              <FormInput
                label={`Which of these definitions best describes ${
                  shliachFirstName || "this Shliach"
                }'s Shlichus?`}
                labelClassNames={"fw-700 accent-text-dark mb-8 block"}
                name="outreach.shliachOutreach.category"
                errors={errors}
                touched={touched}
              >
                {({ field }) =>
                  shliachCategories.map((shliachCategory) => (
                    <div
                      className="radio-button-option radio-button-container mb-8"
                      key={shliachCategory.enumValue}
                    >
                      <input
                        type="radio"
                        id={`shliachCategory-${shliachCategory.enumValue}`}
                        name={field.name}
                        value={shliachCategory.enumValue}
                        onChange={handleChange}
                        checked={field.value === shliachCategory.enumValue}
                      />
                      <label
                        htmlFor={`shliachCategory-${shliachCategory.enumValue}`}
                      >
                        <span className="fw-700 block">
                          {shliachCategory.displayValue}
                        </span>
                        {shliachCategory.value}
                      </label>
                    </div>
                  ))
                }
              </FormInput>
            </div>
          </div>
          <div className="mb-24">
            {/* TODO: add validation for this field and an error class for the label */}
            <label className="fw-700 accent-text-dark mb-8 block">
              What demographics does {shliachFirstName || "this Shliach"} serve?
            </label>
            <FieldArray name="outreach.shliachOutreach.demographics">
              {(arrayHelpers) => (
                <div className="demographics-grid">
                  <div>
                    <p className="small-text accent-text">Demographic</p>
                  </div>
                  {demographics &&
                    demographics.map((demographic) => {
                      return (
                        <div key={demographic.enumValue}>
                          <FormInputUpdate name="outreach.shliachOutreach.demographics">
                            {({ field }) => (
                              <div className="flex custom-checkbox-container">
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  id={`demographics-${demographic.enumValue}`}
                                  name={field.name}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      arrayHelpers.push(demographic.enumValue);
                                    } else {
                                      const index =
                                        shliachOutreach.demographics.indexOf(
                                          demographic.enumValue
                                        );
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                  value={demographic.enumValue}
                                  checked={shliachOutreach.demographics.includes(
                                    demographic.enumValue
                                  )}
                                />
                                <label
                                  className="small-text flex flex-align-center"
                                  htmlFor={`demographics-${demographic.enumValue}`}
                                >
                                  {demographic.displayValue}
                                </label>
                              </div>
                            )}
                          </FormInputUpdate>
                        </div>
                      );
                    })}
                </div>
              )}
            </FieldArray>
          </div>
        </div>
      </div>
    );
  }
}
