import React from "react";
import { Link } from "react-router";
import Loader from "../../common/Loader";

import LamplightersApi from "../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";
import { formatNumber, sum } from "../../../lib";

export default class Dashboard extends React.PureComponent {
  state = {
    allocations: null,
    errorMessage: "",
    loading: true,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getGrantAllocations();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getGrantAllocations = () => {
    const {
      schedule: { id },
    } = this.props;

    LamplightersApi.getGrantAllocations(this.apiSignal.token, id)
      .then((allocations) => {
        this.setState({ allocations, errorMessage: "", loading: false });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          });
        }
      });
  };

  render() {
    const {
      schedule: { grants, programScheduleID, programScheduleName, totalPaid },
    } = this.props;
    const { allocations, errorMessage, loading } = this.state;

    return (
      <div className="lamplighters-dashboard">
        <div className="flex flex-align-center flex-justify-space mb-24">
          {loading ? (
            <div style={{ height: "84px" }} />
          ) : (
            <div className="lamplighters-totals-card mr-32">
              <div className="mr-40">
                <p className="fw-700">Total Allocated</p>
                <div className="flex flex-align-center flex-justify-space">
                  <p className="mr-16">
                    {formatNumber(
                      sum(
                        allocations ? allocations.map((a) => a.quantity) : [],
                      ),
                    )}
                  </p>
                  <p>
                    $
                    {formatNumber(
                      sum(allocations ? allocations.map((a) => a.amount) : []),
                    )}
                  </p>
                </div>
              </div>
              <div>
                <p className="fw-700">Total Paid</p>
                <p className="mb-0">${formatNumber(totalPaid)}</p>
              </div>
            </div>
          )}
          <div className="flex flex-align-center">
            <Link
              className="btn btn-medium btn-accent mr-16"
              to={`/lamplighters/settings/${programScheduleID}?tab=locations`}
            >
              Edit Allocations
            </Link>
            <Link
              className="btn btn-medium btn-accent mr-16"
              to={`/lamplighters/${programScheduleID}?tab=students&grantStatus=Flagged`}
            >
              Flagged Grants
            </Link>
            <Link
              className="btn btn-medium btn-accent mr-16"
              to={`/lamplighters/${programScheduleID}?tab=shluchim&view=payments`}
            >
              Manage Payments
            </Link>
          </div>
        </div>
        <div className="lamplighters-allocation-cards-grid">
          {loading ? (
            <Loader />
          ) : errorMessage ? (
            <p className="error-text">
              Something went wrong and {programScheduleName || "Lamplighters"}{" "}
              details could not be retrieved. Please refresh the page to try
              again.
            </p>
          ) : (
            grants &&
            grants
              .map((grant) => ({
                grantName: grant.name,
                ...(allocations?.find((a) => a.grantID === grant.id) || {}),
              }))
              .map((alloc, index) => (
                <div className="lamplighters-allocation-card" key={index}>
                  <p
                    className="large-text fw-700 mb-16"
                    style={{ minHeight: 56 }}
                  >
                    {alloc.grantName}
                  </p>
                  <div>
                    <p className="small-text mb-8">Total Allocations</p>
                    <div className="flex flex-align-center flex-justify-space large-text">
                      <p className="fw-700">{formatNumber(alloc.quantity)}</p>
                      <p>${formatNumber(alloc.amount)}</p>
                    </div>
                  </div>
                  <div>
                    <p className="small-text mb-8">Available for Allocation</p>
                    <div className="flex flex-align-center flex-justify-space medium-text">
                      <p className="fw-700">
                        {formatNumber(alloc.availableQuantity)}
                      </p>
                      <p>${formatNumber(alloc.availableAmount)}</p>
                    </div>
                  </div>
                  <div className="accent-text-secondary">
                    <p className="small-text mb-8 flex flex-align-center">
                      Active
                      <span className="tooltip-container">
                        <i className="material-icons medium-text accent-text ml-4">
                          info
                        </i>
                        <span className="tooltip">
                          Submitted grants are not included in active total
                        </span>
                      </span>
                    </p>
                    <div className="flex flex-align-center flex-justify-space medium-text">
                      <div className="flex">
                        <p className="fw-700">
                          {formatNumber(alloc.activeQuantity)}
                        </p>
                        {alloc.activeFlaggedQuantity > 0 && (
                          <p className="flagged-text small-text flex flex-align-center ml-8">
                            ({formatNumber(alloc.activeFlaggedQuantity)}
                            <i className="material-icons flag-icon small-text">
                              flag
                            </i>
                            )
                          </p>
                        )}
                      </div>
                      <p>${formatNumber(alloc.activeAmount)}</p>
                    </div>
                  </div>
                  <p className="small-text mb-8">Submitted</p>
                  <div className="flex flex-align-center flex-justify-space medium-text">
                    <div className="flex">
                      <p className="fw-700">
                        {formatNumber(alloc.submittedQuantity)}
                      </p>
                      {alloc.submittedFlaggedQuantity > 0 && (
                        <p className="flagged-text small-text flex flex-align-center ml-8">
                          ({formatNumber(alloc.submittedFlaggedQuantity)}
                          <i className="material-icons flag-icon small-text">
                            flag
                          </i>
                          )
                        </p>
                      )}
                    </div>
                    <p>${formatNumber(alloc.submittedAmount)}</p>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    );
  }
}
