import React, { useCallback, useEffect, useState, useRef } from "react";
import { notify } from "react-notify-toast";
import PaginatedTable from "../../../shared/PaginatedTable";
import CheckInModal from "./CheckInModal";
import CheckInActions from "./CheckInActions";
import CheckInTableFilters from "./CheckInTableFilters";
import CheckInTableHeader from "./CheckInTableHeader";
import CheckInTableRecord from "./CheckInTableRecord";

import TripEventsApi from "../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { partition, pluralizeText } from "../../../../lib";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import axios from "axios";

function CheckIn(props) {
  const {
    mobileMode,
    tripEvent,
    tripEvent: { id },
  } = props;

  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(12);
  const [showAttendeeCheckinModal, setShowAttendeeCheckinModal] =
    useState(false);
  const [attendees, setAttendees] = useState([]);
  const [totalAttendees, setTotalAttendees] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState({
    all: false,
    allExcept: [],
    selection: [],
  });
  const [enrolledShluchim, setEnrolledShluchim] = useState({
    shluchim: [],
    loading: false,
  });
  const [filters, setFilters] = useState({
    campusId: "",
    keyword: "",
    shliachId: "",
    statuses: ["Registered"],
    tourIds: [],
    trackIds: [],
  });

  const apiSignalRef = useRef(axios.CancelToken.source());

  const getEnrolledShluchim = useCallback(() => {
    setEnrolledShluchim({
      shluchim: [],
      loading: true,
    });
    TripEventsApi.getAllTripEnrollments(apiSignalRef.current.token, id)
      .then((shluchim) => {
        setEnrolledShluchim({
          shluchim,
          loading: false,
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setEnrolledShluchim({
            shluchim: [],
            loading: false,
          });
        }
      });
  }, [apiSignalRef, id]);

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    getEnrolledShluchim();
    return () => _apiSignal.cancel();
  }, [getEnrolledShluchim]);

  const getAttendees = useCallback(
    (_page = page, _results = results, _filters = filters) => {
      setPage(_page);
      setResults(_results);
      setLoading(true);
      setSuccess(true);

      TripEventsApi.getTripAttendees(
        apiSignalRef.current.token,
        id,
        _page,
        _results,
        undefined,
        _filters
      )
        .then(({ results, numberOfRows }) => {
          setLoading(false);
          setAttendees(results);
          setTotalAttendees(numberOfRows);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setLoading(false);
            setAttendees([]);
            setSuccess(false);
            setTotalAttendees(0);
          }
        });
    },
    [page, results, filters, apiSignalRef, id]
  );

  const getAttendeesForExport = useCallback(async () => {
    const attendees = await TripEventsApi.getTripAttendees(
      apiSignalRef.current.token,
      id,
      page,
      results,
      undefined,
      filters,
      true
    );

    return attendees.results;
  }, [apiSignalRef, id, page, results, filters]);

  const filterAttendees = useCallback(
    (_filters) => {
      setFilters(_filters);
      setSelectedAttendees({
        all: false,
        allExcept: [],
        selection: [],
      });
      getAttendees(1, results, _filters);
    },
    [getAttendees, results]
  );

  const attendeeApiParams = useCallback(() => {
    const { all, allExcept, selection } = selectedAttendees;

    const [selectedStudents, selectedChaperones] = partition(
      selection,
      (a) => a.attendeeType === "Student"
    );
    const [deselectedStudents, deselectedChaperones] = partition(
      allExcept,
      (a) => a.attendeeType === "Student"
    );

    return [
      apiSignalRef.current.token,
      id,
      !all ? selectedStudents.map((reg) => reg.id) : undefined,
      !all ? selectedChaperones.map((reg) => reg.id) : undefined,
      all ? deselectedStudents.map((reg) => reg.id) : undefined,
      all ? deselectedChaperones.map((reg) => reg.id) : undefined,
      all ? filters : undefined,
    ];
  }, [selectedAttendees, id, filters]);

  const getAttendeesDetailsForCheckin = useCallback(async () => {
    return await TripEventsApi.getTripAttendeeDetails(...attendeeApiParams());
  }, [attendeeApiParams]);

  const toggleSelectAttendee = useCallback(
    (attendee) => {
      const { all, allExcept, selection } = selectedAttendees;

      if (all) {
        const _allExcept =
          allExcept.map((s) => s.id).indexOf(attendee.id) >= 0
            ? allExcept.filter((s) => s.id !== attendee.id)
            : [...allExcept, attendee];
        setSelectedAttendees({
          ...selectedAttendees,
          allExcept: _allExcept,
        });
      } else {
        const _selection =
          selection.map((s) => s.id).indexOf(attendee.id) >= 0
            ? selection.filter((s) => s.id !== attendee.id)
            : [...selection, attendee];
        setSelectedAttendees({
          ...selectedAttendees,
          selection: _selection,
        });
      }
    },
    [selectedAttendees]
  );

  const toggleSelectAllAttendees = useCallback(() => {
    const { all, allExcept } = selectedAttendees;
    setSelectedAttendees({
      all: all && !allExcept.length ? false : true,
      allExcept: [],
      selection: [],
    });
  }, [selectedAttendees]);

  const showResultMessage = useCallback(
    ({ numberOfEnrollmentAttendeesCheckedIn, numberOfStudentsCheckedIn }) => {
      const enrollAttendeesResult = `${numberOfEnrollmentAttendeesCheckedIn} ${pluralizeText(
        "chaperone",
        numberOfEnrollmentAttendeesCheckedIn
      )}/${pluralizeText(
        "shliach",
        numberOfEnrollmentAttendeesCheckedIn,
        "shluchim"
      )}`;
      const studentResults = `${numberOfStudentsCheckedIn} ${pluralizeText(
        "student",
        numberOfStudentsCheckedIn
      )}`;

      const results =
        numberOfEnrollmentAttendeesCheckedIn && numberOfStudentsCheckedIn
          ? `${enrollAttendeesResult} and ${studentResults} checked in.`
          : numberOfEnrollmentAttendeesCheckedIn
          ? `${enrollAttendeesResult} checked in.`
          : numberOfStudentsCheckedIn
          ? `${studentResults} checked in.`
          : "";

      notify.show(
        results,
        numberOfEnrollmentAttendeesCheckedIn || numberOfStudentsCheckedIn
          ? "success"
          : "error"
      );
    },
    []
  );

  const checkInAttendees = useCallback(() => {
    setErrorMessage("");
    setSubmissionLoading(true);

    TripEventsApi.checkinTripAttendees(...attendeeApiParams())
      .then((response) => {
        setShowAttendeeCheckinModal(false);
        setSubmissionLoading(false);
        setSelectedAttendees({
          all: false,
          allExcept: [],
          selection: [],
        });

        getAttendees(1);
        showResultMessage(response);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setErrorMessage(ApiCallErrorMessageHandler(err));
          setSubmissionLoading(false);
        }
      });
  }, [attendeeApiParams, getAttendees, showResultMessage]);

  const readOnlyAccess = !AuthService.UserHasClaim(
    PermissionClaims.TripDataEdit
  );

  return (
    <>
      <div className="flex flex-justify-space mb-24">
        <p className="xxl-text fw-500">All Attendees</p>
        <CheckInActions
          tours={tripEvent.tours}
          getAttendeesForExport={getAttendeesForExport}
          hideCheckin={readOnlyAccess}
          onCheckInAttendees={() => setShowAttendeeCheckinModal(true)}
          tripEvent={tripEvent}
          selectedAttendees={selectedAttendees}
        />
      </div>

      <CheckInTableFilters
        allEnrolledShluchim={enrolledShluchim.shluchim}
        allEnrolledShluchimLoading={enrolledShluchim.loading}
        allTours={tripEvent.tours}
        allTracks={tripEvent.tracks}
        applyFilters={filterAttendees}
      />

      <div className="trip-students-table">
        <PaginatedTable
          loading={loading}
          loadData={getAttendees}
          mobileMode={mobileMode}
          page={page}
          records={attendees}
          showResultsView={true}
          renderHeader={() => (
            <CheckInTableHeader
              canSelectAttendees={attendees.find(
                (a) => a.status === "Registered"
              )}
              readOnlyAccess={readOnlyAccess}
              selectedAttendees={selectedAttendees}
              toggleSelectAllAttendees={toggleSelectAllAttendees}
              tours={tripEvent.tours}
            />
          )}
          renderRow={(attendee) => (
            <CheckInTableRecord
              key={`${attendee.id}-${attendee.attendeeType}`}
              readOnlyAccess={readOnlyAccess}
              selected={
                selectedAttendees.all
                  ? !selectedAttendees.allExcept.find(
                      (a) =>
                        a.id === attendee.id &&
                        a.attendeeType === attendee.attendeeType
                    )
                  : selectedAttendees.selection.find(
                      (a) =>
                        a.id === attendee.id &&
                        a.attendeeType === attendee.attendeeType
                    )
              }
              scheduleId={tripEvent.programScheduleID}
              attendee={attendee}
              toggleSelectAttendee={() => toggleSelectAttendee(attendee)}
            />
          )}
          results={results}
          success={success}
          totalCount={totalAttendees}
          preHeaderMargin={12}
        />
      </div>
      {!loading && success && attendees.length > 0 && (
        <p className="accent-text-dark mt-24">
          {totalAttendees} Result{totalAttendees === 1 ? "" : "s"}
        </p>
      )}

      {showAttendeeCheckinModal && (
        <CheckInModal
          onCancel={() => {
            setShowAttendeeCheckinModal(false);
            setErrorMessage("");
          }}
          onConfirm={checkInAttendees}
          errorMessage={errorMessage}
          getAttendeesDetailsForCheckin={getAttendeesDetailsForCheckin}
          submissionLoading={submissionLoading}
          selectedAttendees={selectedAttendees}
          tours={tripEvent.tours}
          tracks={tripEvent.tracks}
          show={showAttendeeCheckinModal}
        />
      )}
    </>
  );
}
export default React.memo(CheckIn);
