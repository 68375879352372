import React from "react";
import { browserHistory, Link } from "react-router";
import { formatFullName, formatNumber, getCurrencySymbol } from "../../../lib";
import moment from "moment";

export default class RewardRequestsTableRow extends React.PureComponent {
  render() {
    const {
      eduProgramScheduleId,
      request: {
        campusName,
        createdOn,
        creditsToRedeem,
        currencyCode,
        creditValue,
        eduStudentEnrollmentID,
        rewardName,
        rewardType,
        shliachFirstName,
        shliachLastName,
        statusDisplay,
        status,
        studentFirstName,
        studentID,
        studentLastName,
        tripProgramScheduleID,
        tripStudentRegistrationID,
      },
      onApproveRejectRewardRequest,
      viewOnly,
    } = this.props;

    return (
      <Link
        className="courses-requests-table-row"
        to={{
          pathname: `/edu/${eduProgramScheduleId}/students/${studentID}`,
          query: { tab: eduStudentEnrollmentID, sub: "rewards" },
        }}
      >
        <p>{formatFullName(studentFirstName, studentLastName)}</p>
        <div>
          <p className="fw-700">{campusName}</p>
          <p>{formatFullName(shliachFirstName, shliachLastName)}</p>
        </div>
        <div>
          <p>{rewardName}</p>
          <p className="italic-text xs-text">{rewardType}</p>
        </div>
        <div>
          <p className="fw-700">{formatNumber(creditsToRedeem)}</p>
          <p>
            {getCurrencySymbol(currencyCode)}
            {formatNumber(creditValue * creditsToRedeem)}
          </p>
        </div>
        <p>{moment(createdOn).format("MM/DD/YYYY")}</p>
        <p>{statusDisplay}</p>
        <p>
          {!viewOnly &&
            status === "Pending" &&
            (tripStudentRegistrationID ? (
              <span
                className="link-text"
                onClick={(e) => {
                  e.preventDefault();
                  browserHistory.push(
                    `/trips/${tripProgramScheduleID}/students/${tripStudentRegistrationID}`
                  );
                }}
              >
                Manage trip application
              </span>
            ) : (
              <>
                <i
                  className="material-icons ml-8 link-text-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    onApproveRejectRewardRequest(this.props.request, true);
                  }}
                  style={{ color: "#76E269" }}
                >
                  thumb_up
                </i>
                <i
                  className="material-icons ml-8 link-text-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    onApproveRejectRewardRequest(this.props.request, false);
                  }}
                  style={{ color: "#DC313F" }}
                >
                  thumb_down
                </i>
              </>
            ))}
        </p>
      </Link>
    );
  }
}
