import React from "react";
import DatePicker from "react-datepicker";
import { removeTimezoneFormatFromDate } from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import moment from "moment";

export default class Timeline extends React.PureComponent {
  onAddDeadline = () => {
    const {
      onChange,
      schedule: { deadlines },
    } = this.props;

    onChange("deadlines", [...deadlines, { name: "", date: "" }]);

    //scroll new deadline into view
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    setTimeout(() => {
      const scrollWindow = document.getElementById("scroll-window");
      if (scrollWindow) {
        var scrollHeight = scrollWindow.scrollHeight;
        scrollWindow.scrollTo({
          left: 0,
          top: scrollHeight,
          behavior: "smooth",
        });
      }
    });
  };

  onChangeDeadline = (index, name, value) => {
    const {
      onChange,
      schedule: { deadlines },
    } = this.props;

    const changedDeadlines = _cloneDeep(deadlines);
    changedDeadlines[index][name] = value;
    onChange("deadlines", changedDeadlines);
  };

  onRemoveDeadline = (removeIndex) => {
    const {
      onChange,
      schedule: { deadlines },
    } = this.props;

    onChange(
      "deadlines",
      deadlines.filter((_, i) => i !== removeIndex),
    );
  };

  render() {
    const {
      getErrorClassName,
      readOnly,
      saveButtons,
      schedule: {
        deadlines,
        programEndDate,
        programStartDate,
        registerEndDate,
        registerStartDate,
        isFinalized,
      },
      onChange,
      submitAttempted,
    } = this.props;

    return (
      <div className="lamplighters-settings-step">
        <div className="lamplighters-settings-step-section section-title">
          <p className="xl-text">Timeline</p>
          {saveButtons}
        </div>
        <div className="lamplighters-settings-step-section">
          <p className="medium-text mb-24">
            Use below screen to set timeline deadlines for schedule
          </p>
          <div className="lamplighters-schedule-timeline-grid">
            <div className="flex flex-align-center">
              <p className="accent-text small-text lamplighters-label">
                Registration Start
              </p>
              <p className="small-text">
                {registerStartDate
                  ? moment(registerStartDate).format("MMMM D, YYYY h:mm a")
                  : ""}
              </p>
            </div>
            <div className="flex flex-align-center ml-40">
              <p className="accent-text small-text lamplighters-label">
                Registration End
              </p>
              <p className="small-text">
                {registerEndDate
                  ? moment(registerEndDate).format("MMMM D, YYYY h:mm a")
                  : ""}
              </p>
            </div>
            <div className="flex flex-align-center">
              <p className="accent-text small-text lamplighters-label">
                Program Start
              </p>
              <p className="small-text">
                {programStartDate
                  ? moment(programStartDate).format("MMMM D, YYYY h:mm a")
                  : ""}
              </p>
            </div>
            <div className="flex flex-align-center ml-40">
              <p className="accent-text small-text lamplighters-label">
                Program End
              </p>
              <p className="small-text">
                {programEndDate
                  ? moment(programEndDate).format("MMMM D, YYYY h:mm a")
                  : ""}
              </p>
            </div>
          </div>
          <div className="block lamplighters-schedule-timeline">
            <div className="flex custom-checkbox-container mr-16">
              <input
                className="custom-checkbox"
                checked={isFinalized}
                disabled={readOnly}
                id={"isFinalized"}
                onChange={(e) => onChange("isFinalized", e.target.checked)}
                type="checkbox"
              />
              <label
                className="flex-align-center small-text accent-text mt-24"
                htmlFor={"isFinalized"}
              >
                Grant schedule is finalized
              </label>
            </div>
            <p className="accent-text xs-text">
              All available grants must be allocated, and all allocations
              submitted (and unflagged) at least 24 hours before finalizing the
              schedule
            </p>
          </div>
        </div>
        <div className="lamplighters-settings-step-section">
          <div className="flex flex-align-center flex-justify-space">
            <div>
              <p className="medium-text fw-700">Deadlines</p>
              {submitAttempted &&
                deadlines.some(
                  ({ date }) =>
                    date &&
                    (moment(date) < moment(programStartDate) ||
                      moment(date) > moment(programEndDate)),
                ) && (
                  <p className="error-message">
                    Please review dates to ensure that they are within the
                    program start and end dates
                  </p>
                )}
            </div>
            {!readOnly && (
              <div
                className="flex flex-align-center link-text uppercase-text"
                onClick={this.onAddDeadline}
              >
                <i className="material-icons medium-text mr-4">add</i>
                Add
              </div>
            )}
          </div>
          <div>
            {deadlines.map((deadline, index) => (
              <div className="lamplighters-schedule-deadline-card" key={index}>
                <div className="full-width">
                  <div className="flex flex-align-center mb-16">
                    <p className="accent-text small-text lamplighters-label">
                      Deadline Name
                    </p>
                    <input
                      className={getErrorClassName(
                        "custom-input full-width",
                        submitAttempted && !deadline.name,
                      )}
                      disabled={readOnly}
                      name="name"
                      onChange={(e) =>
                        this.onChangeDeadline(
                          index,
                          e.target.name,
                          e.target.value,
                        )
                      }
                      value={deadline.name}
                    />
                  </div>
                  <div className="flex flex-align-center">
                    <p className="accent-text small-text lamplighters-label">
                      Deadline Date
                    </p>
                    <DatePicker
                      className={getErrorClassName(
                        "custom-input",
                        submitAttempted && !deadline.date,
                      )}
                      disabled={readOnly}
                      maxDate={moment(programEndDate)}
                      minDate={moment(programStartDate)}
                      onChange={(date) =>
                        this.onChangeDeadline(
                          index,
                          "date",
                          date
                            ? removeTimezoneFormatFromDate(date.startOf("day"))
                            : null,
                        )
                      }
                      selected={deadline.date && moment(deadline.date)}
                    />
                  </div>
                </div>
                {!readOnly && (
                  <i
                    className="material-icons xl-text link-text ml-16"
                    onClick={() => this.onRemoveDeadline(index)}
                  >
                    delete
                  </i>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
