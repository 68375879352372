import React from "react";
import NumberFormat from "react-number-format";

import FormInput from "../Form/FormInput";

export default class PersonalInfo extends React.PureComponent {
  state = {
    populationInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        populationInfoShowing: false,
      });
    }
  }

  render() {
    const { errors, touched, readOnly } = this.props;
    return (
      <div
        className={
          this.state.populationInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-48 mb-16"
          onClick={() => {
            this.setState({
              populationInfoShowing: !this.state.populationInfoShowing,
            });
          }}
        >
          School Population
        </p>
        <div className="profile-form-inputs">
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Graduate Population"
              type="text"
              name="graduatePopulation"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalSeparator={""}
                  readOnly={readOnly}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Undergrad Population"
              type="text"
              name="undergradPopulation"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  decimalSeparator={""}
                  readOnly={readOnly}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Jewish Graduate Population"
              type="text"
              name="jewishGraduatePopulation"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  readOnly={readOnly}
                  decimalSeparator={""}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Jewish Undergrad Population"
              type="text"
              name="jewishUndergradPopulation"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <NumberFormat
                  thousandSeparator={true}
                  readOnly={readOnly}
                  decimalSeparator={""}
                  {...field}
                  className="custom-input"
                />
              )}
            </FormInput>
          </div>
        </div>
      </div>
    );
  }
}
