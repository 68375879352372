import React from "react";
import Loader from "../../common/Loader";

import ShluchimApi from "../../../services/resources/ShluchimApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";
import moment from "moment";

export default class AccountActivity extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activity: {},
      errorMessage: "",
      loading: false,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getActivity();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onFilterActivity = (name, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => this.getActivity()
    );
  };

  getActivity = () => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
      },
      async () => {
        try {
          const accountActivity = await ShluchimApi.getShliachAccountActivity(
            this.apiSignal.token,
            this.props.shliachId
          );

          this.setState({
            activity: accountActivity,
          });
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              activity: {},
              errorMessage: ApiCallErrorMessageHandler(err),
            });
          }
        }

        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { activity, errorMessage, loading } = this.state;

    const {
      login: { lastLoginAt } = {},
      programViews,
      shliachViews,
    } = activity;

    return loading ? (
      <Loader />
    ) : errorMessage ? (
      <div className="empty-state">
        <img src="/img/error.svg" alt="error-robot" height="200" />
        <p className="error-text mt-32">
          Sorry, something went wrong and account activity could not be
          retrieved.
        </p>
      </div>
    ) : !lastLoginAt && !programViews?.length && !shliachViews?.length ? (
      <p className="accent-text text-center">No Account activity</p>
    ) : (
      <div>
        {lastLoginAt && (
          <p>
            <span className="fw-700">Last login</span> on{" "}
            {moment(lastLoginAt).format("MMM D, YYYY h:mm A")}
          </p>
        )}
        {programViews?.length > 0 && (
          <div className="mt-24">
            <p className="fw-700 xxl-text">Recently Viewed Programs</p>
            {programViews.map((p, i) => (
              <div
                className="flex flex-align-center flex-justify-space mb-8"
                key={i}
              >
                <p className="mr-8">{p.name}</p>
                <p className="accent-text">
                  {moment(p.viewedAt).format("MMM D, YYYY h:mm A")}
                </p>
              </div>
            ))}
          </div>
        )}
        {shliachViews?.length > 0 && (
          <div className="mt-24">
            <p className="fw-700 mb-16">Recently Viewed Profiles</p>
            {shliachViews.map((s, i) => (
              <div
                className="flex flex-align-center flex-justify-space mb-8"
                key={i}
              >
                <p className="mr-8">{s.name}</p>
                <p className="accent-text">
                  {moment(s.viewedAt).format("MMM D, YYYY h:mm A")}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
