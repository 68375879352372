import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import ValidatedInput from "../../shared/ValidatedInput";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

export default class PortalRedirectModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      portalRedirect: {
        domain: "",
        pathFrom: "",
        pathTo: "",
      },
      submitAttempted: false,
    };
    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    const { portalRedirect, show } = this.props;

    if (show && !prevProps.show) {
      const newState = {
        portalRedirect: portalRedirect || {
          domain: "",
          pathFrom: "",
          pathTo: "",
        },
        submitAttempted: false,
      };
      newState.initialState = _cloneDeep(newState);

      this.setState(newState);
    }
  }

  onChangeRedirect = (name, value) => {
    this.setState({
      portalRedirect: {
        ...this.state.portalRedirect,
        [name]: value,
      },
    });
  };

  onClose = () => {
    this.props.close();
    this.setState(this.state.initialState);
  };

  onSave = () => {
    this.setState(
      {
        submitAttempted: true,
      },
      () => {
        const { portalRedirect } = this.state;

        if (
          !portalRedirect.domain ||
          !portalRedirect.pathFrom ||
          !portalRedirect.pathTo
        ) {
          return;
        }

        this.props.save(portalRedirect);

        this.onClose();
      },
    );
  };

  render() {
    const { portalDomains, portalRedirect: portalRedirectForEdit } = this.props;

    const {
      initialState: { portalRedirect: initialPortalRedirect },
      portalRedirect,
      submitAttempted,
    } = this.state;

    return (
      <Modal isOpen={this.props.show} className="modal-container">
        <div className="modal-card card new-redirect-modal">
          <p className="large-text fw-500 mb-16">
            {!portalRedirectForEdit ? "New" : "Edit"} Portal Redirect
          </p>
          <div className="flex flex-align-center mb-24">
            <label
              className="accent-text-dark"
              style={{ width: "96px", minWidth: "96px" }}
            >
              Domain
            </label>
            <Select
              className={`custom-select ${
                submitAttempted && !portalRedirect.domain ? "error" : ""
              }`}
              clearable={false}
              onChange={(domain) =>
                this.onChangeRedirect("domain", domain.value)
              }
              options={portalDomains
                .filter((pd) => pd.key !== "AdminPortalURL") //no redirects implementation in admin portal
                .map((pd) => ({
                  label: pd.value,
                  value: pd.value,
                }))}
              value={portalRedirect.domain}
            />
          </div>
          <div className="flex flex-align-center mb-24">
            <label
              className="accent-text-dark"
              style={{ width: "96px", minWidth: "96px" }}
            >
              Path From
            </label>
            <ValidatedInput
              input={<input type="text" className="full-width" />}
              name="pathFrom"
              onChange={(e) =>
                this.onChangeRedirect(e.target.name, e.target.value)
              }
              required={true}
              showRequired={submitAttempted}
              value={portalRedirect.pathFrom}
            />
          </div>
          <div className="flex flex-align-center mb-24">
            <label
              className="accent-text-dark"
              style={{ width: "96px", minWidth: "96px" }}
            >
              Path To
            </label>
            <ValidatedInput
              input={<input type="text" className="full-width" />}
              name="pathTo"
              onChange={(e) =>
                this.onChangeRedirect(e.target.name, e.target.value)
              }
              required={true}
              showRequired={submitAttempted}
              value={portalRedirect.pathTo}
            />
          </div>
          <div className="modal-btns flex flex-align-center flex-justify-end mt-16">
            <button
              className="btn link-text uppercase-text fw-500"
              onClick={this.onClose}
            >
              Cancel
            </button>
            <button
              className="btn link-text uppercase-text fw-500 ml-24"
              disabled={_isEqual(initialPortalRedirect, portalRedirect)}
              onClick={this.onSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
