import AuthRequest from "./AuthRequest";

// import { CustomEvent } from "../../components/Events/Utils";

const baseUrl = "/api/customevents";

const CustomEventsApi = {
  /** @param {CustomEvent} event */
  create: (event) => {
    return AuthRequest.post(`${baseUrl}`, { ...event });
  },
  /** @param {CustomEvent} event */
  update: (event) => {
    return AuthRequest.post(`${baseUrl}/${event.id}`, { ...event });
  },
  /** @param {number} id */
  delete: (id) => {
    return AuthRequest.delete(`${baseUrl}/${id}`);
  },
  list: () => {
    return AuthRequest.get(`${baseUrl}`, {});
  },
  /** @param {number} id */
  get: (id) => {
    return AuthRequest.get(`${baseUrl}/${id}`);
  },
  /** @param {number} id
   * @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  getSummaryReportData: (id, startDate, endDate) => {
    return AuthRequest.get(`${baseUrl}/summary/${id}`, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
  /** @param {number} id
   * @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  getDetailedReportData: (id, startDate, endDate) => {
    return AuthRequest.get(`${baseUrl}/detailed/${id}`, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
  /** @param {number} id
   * @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  getDetailedBySchoolReportData: (id, startDate, endDate) => {
    return AuthRequest.get(`${baseUrl}/detailedbyschool/${id}`, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
};

export default CustomEventsApi;
