import AuthClient from "./AuthClient";
import queryString from "query-string";

const RsvpApi = {
  getRsvpSystem: async (cancelToken, scheduleId) => {
    const response = await AuthClient.get(
      !scheduleId
        ? "RsvpSystems"
        : `RsvpSystems/byProgramSchedule/${scheduleId}`,
      { cancelToken },
    );
    return response.data.payload || {};
  },
  submitRsvpSystem: async (cancelToken, rsvpSystem) => {
    const response = await AuthClient.post("RsvpSystems", rsvpSystem, {
      cancelToken,
    });
    return response.data.payload || {};
  },

  getRsvpEnrollments: async (
    cancelToken,
    page,
    results,
    sortByOption,
    filters = {},
    isExport,
  ) => {
    const params = {
      export: isExport,
      page,
      results,
      sortByOption,
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `RsvpSystems/enrolledChabadHouses?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return {
      chabadHouses: response.data.payload.results || [],
      totalChabadHouses: response.data.payload.numberOfRows || 0,
    };
  },
  submitRsvpEnrollment: async (cancelToken, chabadHouseId) => {
    const response = await AuthClient.post(
      "RsvpSystems/enrollChabadHouse",
      [chabadHouseId],
      {
        cancelToken,
      },
    );
    return response.data.payload || {};
  },
  removeRsvpEnrollment: async (cancelToken, chabadHouseId) => {
    const response = await AuthClient.post(
      "RsvpSystems/unenrollChabadHouse",
      [chabadHouseId],
      {
        cancelToken,
      },
    );
    return response.data.payload || {};
  },
};

export default RsvpApi;
