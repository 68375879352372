import AuthRequest from "./AuthRequest";

const baseUrl = "/api/lamplighters/reporting";

const LamplightersReportingApi = {
  getList: () => {
    return AuthRequest.get(baseUrl + "/list", {});
  },
  /** @param {number} startSession
   * @param {number} endSession
   */
  getByRange: (startSession, endSession) => {
    return AuthRequest.get(baseUrl + "/byrange", { startSession, endSession });
  },
  /** @param {number} year */
  getSingle: (year) => {
    return AuthRequest.get(baseUrl + `/${year}`, { year });
  },
};

export default LamplightersReportingApi;
