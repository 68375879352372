import React from "react";
import MergeDuplicateRow from "./MergeDuplicateRow";
import _isEqual from "lodash.isequal";

export default class MergeDuplicateForm extends React.PureComponent {
  isEqual = (values) => {
    return values.every((item) => _isEqual(item, values[0]));
  };

  render() {
    const { fields, ids, removeStudent, resolveField, students } = this.props;

    return (
      <div className="merge-duplicates-form mb-16">
        <div
          className="field-row"
          style={{
            gridTemplateColumns: `180px repeat(${students.length}, 280px)`,
          }}
        >
          <p className="mb-16" />
          {students.map((val, index) => (
            <div className="exclude-student" key={index}>
              <div>
                {val.firstName} {val.lastName}
                <a
                  href={`/students/${val.studentID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className="material-icons ml-8 large-text"
                    style={{
                      verticalAlign: "middle",
                      color: "#53B7E8",
                    }}
                  >
                    open_in_new
                  </i>
                </a>
                <p
                  className={`ml-16 relative ${
                    val.isActive ? "active" : "inactive"
                  }`}
                  style={{ marginBottom: "0", fontSize: 12 }}
                >
                  {val.isActive ? "Active" : "Inactive"}
                </p>
                <p
                  className={"ml-16 relative"}
                  style={{ marginBottom: "0", fontSize: 12 }}
                >
                  Student ID: {val.studentID}
                </p>
                <div className="small-text flex flex-align-center">
                  <i
                    className="material-icons medium-text mr-4"
                    style={{
                      color: val.didStudentCreateAccount
                        ? "#228b22"
                        : "#e94746",
                    }}
                  >
                    {val.didStudentCreateAccount ? "check_circle" : "cancel"}
                  </i>
                  {!val.didStudentCreateAccount ? "No " : ""}User Account
                  <div className="tooltip-container merge-duplicates-tooltip-container ml-4">
                    <i className="material-icons link-text-secondary small-text">
                      info
                    </i>
                    <span className="tooltip line-height-double">
                      The merged student will receive the user account and
                      profile verification of the student with the selected
                      email
                    </span>
                  </div>
                </div>
                <div className="small-text flex flex-align-center">
                  <i
                    className="material-icons medium-text mr-4"
                    style={{
                      color: val.didStudentVerifyProfile
                        ? "#228b22"
                        : "#e94746",
                    }}
                  >
                    {val.didStudentVerifyProfile ? "check_circle" : "cancel"}
                  </i>
                  Profile {!val.didStudentVerifyProfile ? "Not " : ""}Verified
                </div>
              </div>
              <i
                className="material-icons"
                style={{
                  opacity: 0.55,
                  cursor: "pointer",
                  fontSize: 18,
                  lineHeight: "25px",
                }}
                onClick={() => {
                  removeStudent(index);
                }}
              >
                close
              </i>
            </div>
          ))}
        </div>
        {fields.map((props, index) => (
          <MergeDuplicateRow
            {...props}
            key={index}
            students={students}
            isEqual={(values) => this.isEqual(values, props.name)}
            ids={ids}
            resolveField={(val, name) => {
              resolveField(val, name);
            }}
          />
        ))}
      </div>
    );
  }
}
