import React from "react";
import NumberFormat from "react-number-format";
import Radio from "../../../shared/Radio";
import Toggle from "../../../shared/Toggle";
import TourSchedulesTable from "./TourSchedulesTable";
import ValidatedInput from "../../../shared/ValidatedInput";

export default class TourDetailsCard extends React.PureComponent {
  render() {
    const {
      hasStartedShluchimEnrollment,
      ohelTour,
      onChange,
      onChangeEvt,
      onClose,
      submitAttempted,
      tour: {
        autoEnrollStudents,
        durationHours,
        durationMinutes,
        hasTransportation,
        busSize,
        isPriceIncludedInTripPrice,
        name,
        price,
        schedules,
        shluchimDescription,
        studentDescription,
      },
      tripEndDate,
      tripStartDate,
    } = this.props;

    return (
      <div className="program-settings-tour-card">
        <div className="flex flex-justify-space mb-24">
          <p className="xxl-text">Tour Info</p>
          <i
            className="material-icons large-text link-text-secondary accent-text"
            onClick={onClose}
          >
            close
          </i>
        </div>
        <div className="tour-details-form">
          <div className="tour-details-form-top">
            <div className="flex flex-align-center mb-16 relative">
              <label>Tour Name</label>
              <ValidatedInput
                name="name"
                onChange={onChangeEvt}
                required={true}
                showRequired={submitAttempted}
                value={name}
                showValidation={!!ohelTour}
                validate={(_) => !name || !name.includes("ohel")}
                validationMessage={`Please note that the previously configured ${ohelTour ? ohelTour.name : "ohel tour"
                  } will be considered the official Ohel Tour for this event.`}
              />
            </div>
            <div className="flex mb-16 relative">
              <label className="mt-4">Shluchim Description</label>
              <textarea
                className="custom-input"
                name="shluchimDescription"
                onChange={onChangeEvt}
                style={{ padding: "8px" }}
                value={shluchimDescription}
              />
            </div>
            <div className="flex mb-16 relative">
              <label className="mt-4">
                Student Description
                {name && name.toLowerCase().includes("ohel") && (
                  <span className="tooltip-container">
                    <i className="material-icons large-text ml-8">info</i>
                    <span className="tooltip">
                      Note that this Ohel visit is not visible in student
                      registration form
                    </span>
                  </span>
                )}
              </label>
              <textarea
                className="custom-input"
                name="studentDescription"
                onChange={onChangeEvt}
                style={{ padding: "8px" }}
                value={studentDescription}
              />
            </div>
            <div className="flex flex-align-center mb-16 relative">
              <label className="flex flex-align-center">
                Price
                {hasStartedShluchimEnrollment && (
                  <span className="tooltip-container">
                    <i className="material-icons large-text ml-8">info</i>
                    <span className="tooltip">
                      Tour price changes will not affect existing registrations
                    </span>
                  </span>
                )}
              </label>
              <Toggle
                name="isPriceIncludedInTripPrice"
                onChange={async (name, value) => {
                  await onChange(name, value);

                  if (value && price) {
                    onChange("price", "");
                  }
                }}
                options={[
                  {
                    value: true,
                    display: "Included",
                  },
                  {
                    value: false,
                    display: "Extra Fee",
                  },
                ]}
                value={isPriceIncludedInTripPrice}
              />
              {isPriceIncludedInTripPrice === false && (
                <ValidatedInput
                  className="ml-8"
                  input={
                    <NumberFormat
                      className="dollar-input"
                      onValueChange={({ floatValue, value }) =>
                        onChange("price", floatValue || value)
                      }
                      decimalScale={2}
                      thousandSeparator={true}
                    />
                  }
                  name="price"
                  required={true}
                  showRequired={submitAttempted}
                  value={price}
                />
              )}
            </div>
            <div className="flex flex-align-center mb-16 relative">
              <label className="flex flex-align-center">
                Duration
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8">info</i>
                  <span className="tooltip">
                    From when tour leaves premises until it returns
                  </span>
                </span>
              </label>
              <div className="two-column-grid">
                <div className="duration-input relative">
                  <ValidatedInput
                    input={
                      <input
                        min="0"
                        onKeyPress={(e) => {
                          if (e.charCode < 48 || e.charCode > 57)
                            e.preventDefault(); //prevent negative and decimal input
                        }}
                        style={{ maxWidth: "96px" }}
                        type="number"
                      />
                    }
                    name="durationHours"
                    onChange={onChangeEvt}
                    required={!durationMinutes}
                    showRequired={submitAttempted}
                    value={durationHours}
                  />
                  <p>hr</p>
                </div>
                <div className="duration-input relative">
                  <ValidatedInput
                    input={
                      <input
                        min="0"
                        max="59"
                        onKeyPress={(e) => {
                          if (e.charCode < 48 || e.charCode > 57)
                            e.preventDefault(); //prevent negative and decimal input
                        }}
                        style={{ maxWidth: "96px" }}
                        type="number"
                      />
                    }
                    name="durationMinutes"
                    onChange={onChangeEvt}
                    required={!durationHours}
                    showRequired={submitAttempted}
                    value={durationMinutes}
                  />
                  <p>min</p>
                </div>
              </div>
            </div>
            <div className="flex flex-align-center mb-16 relative">
              <label>Transportation</label>
              <Toggle
                name="hasTransportation"
                onChange={async (name, value) => {
                  await onChange(name, value);
                  if (!value) onChange("busSize", "");
                }
                }
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
                value={hasTransportation}
              />
              {hasTransportation && (
                <NumberFormat
                  className="custom-input small-input ml-16"
                  name="busSize"
                  placeholder="Bus size"
                  onChange={(e) =>
                    onChange(e.target.name, e.target.value)
                  }
                  value={busSize}
                  allowNegative={false}
                  decimalScale={0}
                />
              )}
            </div>
            <div className="flex relative">
              <label className="flex">
                Auto-enroll
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8">info</i>
                  <span
                    className="tooltip"
                    style={{ whiteSpace: "unset", width: "400px" }}
                  >
                    If price (above) is set to Included, and you choose to
                    auto-enroll students, it will hide this tour from the
                    student registration and it will only allow shliach to
                    choose one tour time
                  </span>
                </span>
              </label>

              <Radio
                name="autoEnrollStudents"
                onChange={onChange}
                options={[
                  {
                    value: true,
                    display:
                      "Yes, auto-enroll students with shliach enrollment",
                  },
                  {
                    value: false,
                    display: "Give the shluchim the option",
                  },
                ]}
                value={autoEnrollStudents}
              />
            </div>
          </div>

          <TourSchedulesTable
            hasStartedShluchimEnrollment={hasStartedShluchimEnrollment}
            onChange={onChange}
            schedules={schedules}
            tripEndDate={tripEndDate}
            tripStartDate={tripStartDate}
          />
        </div>
      </div>
    );
  }
}
