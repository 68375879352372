import React from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export default class PhoneInput extends React.PureComponent {
  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  usCountryCode = "us";

  getCountryCodes = () => this.props.countries.map((c) => c.code.toLowerCase());

  getDefaultCountry = () => {
    const { countryId, defaultCountryName } = this.props;
    return countryId
      ? this.getCountryCodeById(countryId)
      : defaultCountryName
      ? this.getCountryCodeByName(defaultCountryName)
      : this.usCountryCode;
  };

  getCountryIdByCode = (countryCode) => {
    const country = this.props.countries.find(
      (c) => c.code.toLowerCase() === countryCode.toLowerCase(),
    );
    return country ? country.id : null;
  };

  getCountryCodeById = (countryId) => {
    const country = this.props.countries.find((c) => c.id === countryId);
    return country ? country.code.toLowerCase() : null;
  };

  getCountryCodeByName = (countryName) => {
    if (countryName === "Scotland") countryName = "United Kingdom"; //note: no country support for scotland, use UK instead
    const country = this.props.countries.find((c) => c.name === countryName);
    return country ? country.code.toLowerCase() : null;
  };

  render() {
    const {
      className,
      disabled,
      error,
      name,
      onBlur,
      onChange,
      onChangeCountry,
      style,
      tabIndex,
      value,
    } = this.props;

    return (
      <div
        className={`phone-input
                    ${error ? "error" : ""}
                    ${disabled ? "read-only" : ""}
                    ${className || ""}
                `}
        style={style}
      >
        <IntlTelInput
          css={["intl-tel-input"]}
          customPlaceholder={(selectedCountryPlaceholder) =>
            selectedCountryPlaceholder.replace(new RegExp("[0-9]", "g"), "0")
          }
          disabled={disabled}
          fieldName={name}
          format={true}
          formatOnInit={false} //note: setting this to false to ensure that phone num is not formatted on init (where format changes would lead to invoking onPhoneNumberChange and the subsequent updates) causing there to be unsaved changes within the containing form
          //note: this is how this lib allows 'setting' country.  once the component is initialized with a default country, country can only be changed by user input
          //see https://github.com/patw0929/react-intl-tel-input/issues/233
          defaultCountry={this.getDefaultCountry()}
          onlyCountries={this.getCountryCodes()}
          onPhoneNumberBlur={
            onBlur ? (status) => onBlur(name, !value || status) : null
          }
          onPhoneNumberChange={(s, value) => onChange(name, value)}
          onSelectFlag={
            onChangeCountry &&
            ((s, value) =>
              onChangeCountry(
                value && value.iso2 && this.getCountryIdByCode(value.iso2),
              ))
          }
          preferredCountries={[this.usCountryCode]}
          separateDialCode={true}
          tabIndex={tabIndex}
          useMobileFullscreenDropdown={false}
          utilsScript={this._ismounted ? "libphonenumber.js" : ""}
          value={value}
        />
        {error && value && (
          <span className="error-message">Invalid Phone Number</span>
        )}
      </div>
    );
  }
}
