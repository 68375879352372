import { asEnum } from "../../";

import { /* Address, */ EmptyAddress } from "./Common";
// import { Mosad } from "./Mosad";
// import { HistoryEvent } from "./Events";

export const Gender = asEnum({
  unknown: 0,
  male: 1,
  female: 2,
});

export const ContactMethodTypes = asEnum({
  email: 0,
  phone: 1,
  mobile: 2,
});

// export interface Contact {
//     id?: number;
//     firstName: string;
//     lastName: string;
//     gender: Gender;
//     dob?: Date;
//     hebrewName: string;
//     isJewish: boolean;
//     homeAddress?: Address;
//     contactMethods?: Array<ContactMethods>;
//     isAdmin?: boolean;
//     title?: string;
//     [propertyName: string]: any;
// }

// export interface ContactBasicData {
//     id?: number;
//     contactId?: number;
//     firstName: string;
//     lastName: string;
//     hebrewName?: string;
//     gender?: Gender;
//     dob?: Date;
//     fbid?: string;
//     contactMethods: Array<ContactMethod>;
// }
/** @returns {ContactBasicData} */
export function EmptyContact() {
  return {
    contactId: -1,
    firstName: "",
    lastName: "",
    gender: Gender.unknown,
    hebrewName: "",
    contactMethods: [],
  };
}

// export interface Shliach extends ContactBasicData {
//     shliachId: number;
//     title?: string;
//     yearStarted?: number;
//     spouse?: Shliach;
//     address?: Address;
//     mosads?: Array<Mosad>;
//     events?: Array<HistoryEvent>;
//     notes?: Array<HistoryEvent>;
// }
/** @returns {Shliach} */
export function EmptyShliach() {
  return {
    ...EmptyContact(),
    shliachId: -1,
    address: EmptyAddress(),
    /*mosad: EmptyMosad(), */ mosads: [],
    title: "",
  };
}

export const ContactMethodsStrings = {
  email: "EmailAddress",
  phone: "PhoneNumber",
  mobile: "CellPhoneNumber",
};

// export interface ContactMethod {
//     id: number;
//     type: string; // "EmailAddress" | "CellPhoneNumber" | "PhoneNumber";
//     primary: boolean;
//     value: string;
// }

// export interface ContactMethods {
//     id: number;
//     type: string; // "EmailAddress" | "CellPhoneNumber" | "PhoneNumber";
//     primary: boolean;
//     value: string;
// }
/** @returns {ContactMethod} */
export function EmptyContactMethod() {
  return {
    value: "",
    type: ContactMethodsStrings.email,
    primary: false,
    id: -1,
  };
}
