import React from "react";

import "./Toast.css";

export interface ToastProps {
    timeout?: number;
    buttonText?: string;
    handleButtonClick?: Function;
}

export interface ToastState {
    active: boolean;
}

export class Toast extends React.Component {
    state = {
        active: true,
    };

    timeoutFn;

    close = e => {
        e.preventDefault();
        clearTimeout(this.timeoutFn);
        this.setState({
            active: false,
        });
        if (this.props.handleButtonClick) {
            this.props.handleButtonClick();
        }
    };

    componentDidMount() {
        this.timeoutFn = setTimeout(() => this.setState({ active: false }), this.props.timeout || 3000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutFn);
    }

    render() {
        return (
            <div className={`toast ${this.state.active ? "active" : "inactive"}`}>
                <span className="toast-text">{this.props.children}</span>
                <button className="toast-button btn btn-secondary" onClick={this.close}>
                    {this.props.buttonText}
                </button>
            </div>
        );
    }
}
