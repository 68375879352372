import React from "react";
import { asEnum } from "../../../lib";

export const EditableControlStates = asEnum({
  nonEditable: 0,
  editable: 1,
});

export const InputProperties = asEnum({
  IsEmail: 0,
  IsPhone: 1,
  IsNumber: 2,
  IsPassword: 3,
  IsUrl: 4,
});

// export interface SelectOptions {
//     value: string;
//     displayText: string;
// }

export const RequiredSign = (
  <span style={{ color: "red", paddingLeft: "3px" }}>*</span>
);

export const DataUnavailableJSX = (
  <em className="text-muted">Data Unavailable</em>
);
