import React, { memo } from "react";
import ExportCSVButton from "../../shared/ExportCSVButton";
import { flattenArray } from "../../../lib";

function LocationsTableActions(props) {
  const { getLocationsForExport, onAddLocation } = props;

  const getExportHeaders = (data) => {
    const maxEmailRecipientsCount = Math.max(
      ...data.map((d) => d.emailRecipients?.length || 0)
    );
    return [
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      { label: "Email", value: "chabadHouseEmail" },
      { label: "Phone", value: "chabadHousePhone" },
      { label: "Address 1", value: "chabadHouseAddress1" },
      { label: "Address 2", value: "chabadHouseAddress2" },
      { label: "City", value: "chabadHouseCity" },
      { label: "State", value: "chabadHouseState" },
      { label: "Zip", value: "chabadHouseZip" },
      { label: "Country", value: "chabadHouseCountry" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shlucha ID", value: "shluchaID" },
      { label: "Shlucha First Name", value: "shluchaFirstName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Cell", value: "shliachCell" },
      { label: "Shlucha Email", value: "shluchaEmail" },
      { label: "Total RSVP Registrations", value: "registrationsCount" },
      ...flattenArray(
        Array.from({ length: maxEmailRecipientsCount }, (_, index) => [
          {
            label: `Recipient ${index + 1} First Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.firstName) || "",
          },
          {
            label: `Recipient ${index + 1} Last Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.lastName) || "",
          },
          {
            label: `Recipient ${index + 1} Email`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.email) || "",
          },
        ])
      ),
    ];
  };

  return (
    <div className="flex">
      <button
        className="btn custom-btn btn-accent flex flex-align-center mr-16"
        onClick={onAddLocation}
      >
        Add
      </button>
      <ExportCSVButton
        className="btn custom-btn btn-accent flex flex-align-center"
        fileName="RSVP_System_Locations"
        getExportData={getLocationsForExport}
        getHeaders={getExportHeaders}
      />
    </div>
  );
}

export default memo(LocationsTableActions);
