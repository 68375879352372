import React from "react";

import { UpdateDOMProperty } from "../Scripts";

import {
  AddressUI,
  ContactMethodUI,
  ShliachRoleList,
  StyledSelect,
  DataContainer,
} from "..";
import {
  // Address,
  // MosadShliachRole,
  EmptyAddress,
  // CountryHlp,
  // StateHlp,
  EmptyContactMethod,
  ContactMethodsStrings,
  EmptySchool,
  // ContactMethod,
  EmptyMosad,
  // Mosad,
} from "../Models";
import { StyledList } from "../StyledComponents/StyledList";

// export interface MosadDataProps {
//   shliachId?: number;
//   shluchaId?: number;
//   defaultCountry?: string;
//   mosadData: Mosad;
//   countries: Array<CountryHlp>;
//   states: Array<StateHlp>;
//   disabled: boolean;
//   handleChange: (newValue: Mosad) => void;
//   handleSelectMosadClick?: React.FormEventHandler<HTMLElement>;
//   renderSchool: Function;
//   showAdvancedOptions?: boolean;
// }

export const MosadData = ({
  shliachId,
  shluchaId,
  mosadData = EmptyMosad(),
  defaultCountry,
  countries,
  states,
  disabled = true,
  handleChange,
  handleSelectMosadClick,
  renderSchool,
  showAdvancedOptions = false,
}) => {
  /** @param {React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>} evt */
  const handleDOMDataChange = (evt) =>
    handleChange(UpdateDOMProperty(evt, mosadData));
  /** @param {Address} newAddress */
  const handleAddressChanged = (newAddress) => {
    const newMosad = { ...mosadData, address: newAddress };
    handleChange(newMosad);
  };

  const handleSchoolAdded = (e) => {
    e.preventDefault();
    const newSchools = (mosadData.schools || []).slice();
    newSchools.push({
      ...EmptySchool(),
      id: -Math.floor(Math.random() * 100000),
    });
    const newMosad = { ...mosadData, schools: newSchools };
    handleChange(newMosad);
  };
  /** @param {number} schoolId */
  const handleSchoolChangedCreator = (schoolId) => {
    /** @param {Mosad} newSchool */
    return function handleSchoolChanged(newSchool) {
      const newSchools = (mosadData.schools || []).slice();
      const sIx = newSchools.findIndex((s) => s.id === schoolId);
      if (sIx > -1) {
        newSchools.splice(sIx, 1, newSchool);
        const newMosad = { ...mosadData, schools: newSchools };
        handleChange(newMosad);
      }
    };
  };
  /** @param {number} schoolId */
  const handleSchoolRemovedCreator = (schoolId) => {
    /** @param {Mosad} newSchool */
    return function handleSchoolRemoved(newSchool) {
      const newSchools = (mosadData.schools || []).slice();
      const sIx = newSchools.findIndex((s) => s.id === schoolId);
      if (sIx > -1) {
        newSchools.splice(sIx, 1);
        const newMosad = { ...mosadData, schools: newSchools };
        handleChange(newMosad);
      }
    };
  };
  /** @param {ContactMethod} newPhone */
  const handlePhoneChanged = (newPhone) => {
    const phoneSplit = newPhone.value.split("x");
    const newMosad = {
      ...mosadData,
      phone: phoneSplit[0],
      ext: phoneSplit.length > 1 ? phoneSplit[1] : "",
    };
    handleChange(newMosad);
  };
  /** @param {Array<MosadShliachRole>} newRoles */
  const handleRolesChanged = (newRoles) => {
    const newMosad = { ...mosadData, shluchimRoles: newRoles };
    handleChange(newMosad);
  };

  // const handleWhitelistChange = (evt: React.FormEvent<HTMLInputElement>) => {
  //     const isInWhiteList =
  //         evt.currentTarget.id === "Yes" ? true : evt.currentTarget.id === "No" ? false : mosadData.isInWhiteList;
  //     const newSchool = { ...mosadData, isInWhiteList };
  //     return handleChange(newSchool);
  // };

  /** @param {string} [phone]
   * @param {string} [ext]
   * @returns {ContactMethod}
   */
  const mosadPhoneAsCM = (phone, ext) => {
    const phoneCM = {
      id: -1,
      type: ContactMethodsStrings.phone,
      primary: true,
      value: (phone || "") + (ext && "x" + ext),
    };
    return phoneCM;
  };

  return (
    <div>
      <fieldset className="container">
        <div className="row">
          <div className="row">
            <fieldset className="col-sm-6">
              <label className="form-label form-required" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                name="name"
                value={mosadData.name || ""}
                required={true}
                id="name"
                disabled={disabled}
                onChange={handleDOMDataChange}
              />
            </fieldset>
            {!disabled && showAdvancedOptions && handleSelectMosadClick && (
              <fieldset className="col-sm-4">
                <br />
                <br />
                <span
                  onClick={handleSelectMosadClick}
                  style={{ cursor: "pointer" }}
                >
                  or Select from existing
                </span>
              </fieldset>
            )}
          </div>
          <div className="row">
            <fieldset className="col-sm-8">
              <label className="form-label" htmlFor="phone">
                Phone
              </label>
              <ContactMethodUI
                contactMethod={
                  mosadPhoneAsCM(mosadData.phone, mosadData.ext) ||
                  EmptyContactMethod()
                }
                groupName={"mosadPhone"}
                required={false}
                defaultCountry={defaultCountry}
                displayTextInputOnly={true}
                disabled={disabled}
                handleChange={handlePhoneChanged}
                handleRemove={() => ""}
              />
            </fieldset>
          </div>
          <div className="row">
            <fieldset className="col-sm-4">
              <label className="form-label" htmlFor="website">
                Website
              </label>
              <input
                type="text"
                name="url"
                value={mosadData.url || ""}
                id="website"
                disabled={disabled}
                onChange={handleDOMDataChange}
              />
            </fieldset>
          </div>
          {/* {showAdvancedOptions && [
                        <div className="row" key="whitelistLabel">
                            <fieldset className="col-sm-4">Show on public website?</fieldset>
                        </div>,
                        <div className="row" key="whitelistInput">
                            <fieldset className="col-sm-4">
                                <input
                                    id="Yes"
                                    type="radio"
                                    name={"isInWhiteList_" + mosadData.id}
                                    checked={mosadData.isInWhiteList || false}
                                    disabled={disabled}
                                    onChange={handleWhitelistChange}
                                />
                                &nbsp;&nbsp;Yes &nbsp;&nbsp;
                                <input
                                    id="No"
                                    type="radio"
                                    name={"isInWhiteList_" + mosadData.id}
                                    checked={!mosadData.isInWhiteList}
                                    disabled={disabled}
                                    onChange={handleWhitelistChange}
                                />
                                &nbsp;&nbsp;No
                            </fieldset>
                        </div>,
                    ]} */}
        </div>
      </fieldset>
      <DataContainer headerText="Address">
        <AddressUI
          addressData={mosadData.address || EmptyAddress()}
          handleChange={handleAddressChanged}
          countries={countries}
          states={states}
          disabled={disabled}
          required={true}
        />
      </DataContainer>
      <ShliachRoleList
        shluchimRoles={mosadData.shluchimRoles || []}
        shliachId={shliachId}
        shluchaId={shluchaId}
        disabled={disabled}
        handleChange={handleRolesChanged}
      />
      <DataContainer headerText="Schools">
        {(mosadData.schools || []).map((school) => (
          <div key={school.id}>
            {renderSchool(
              school,
              disabled,
              handleSchoolChangedCreator(school.id),
              showAdvancedOptions
                ? handleSchoolRemovedCreator(school.id)
                : () => {}
            )}
          </div>
        ))}
        {!disabled && showAdvancedOptions && (
          <StyledList.AddButton
            style={{ marginBottom: "0.5em" }}
            onClick={handleSchoolAdded}
          >
            + Add New School
          </StyledList.AddButton>
        )}
      </DataContainer>
    </div>
  );
};
