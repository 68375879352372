import React, { useCallback } from "react";
import Radio from "../../../../../shared/Radio";
import ValidatedInput from "../../../../../shared/ValidatedInput";

import { formatCurrency } from "../../../../../../lib";
import moment from "moment";

function RegistrationTours(props) {
  const {
    allowUpdate,
    initialRegistration,
    onChangeRegistration,
    registration,
    shliachEnrollment: { tours },
  } = props;

  const onToggleTourSchedules = useCallback(
    (id, tour) => {
      const scheduleId = parseInt(id);

      let registrationTours = [...registration.tours];
      const selectedTour = registrationTours.find((st) => st.id === tour.id);

      //remove
      if (selectedTour) {
        registrationTours = registrationTours.filter((t) => t.id !== tour.id);
      }

      //add
      const deselectTourSchedule =
        !tour.autoEnrollStudents &&
        selectedTour &&
        selectedTour.schedules[0].id === scheduleId;
      if (!deselectTourSchedule) {
        const tourSchedule = tour.schedules.find(
          (sched) => sched.id === scheduleId
        );
        registrationTours.push({
          ...tour,
          schedules: [tourSchedule],
        });
      }

      onChangeRegistration("tours", registrationTours);
    },
    [onChangeRegistration, registration.tours]
  );

  return (
    <div className="trip-student-detail-tab tours-tab">
      <div className="xl-text fw-700 mb-16">Tours</div>
      {tours?.map((t) => {
        const initialTour = initialRegistration.tours.find(
          ({ id }) => id === t.id
        );
        const tour = {
          ...t,
          price: initialTour ? initialTour.price : t.price,
        }; //Lock in tour info (spec price) for intially registered tour
        const { autoEnrollStudents, name, id, price, schedules } = tour;

        let selectedTourSchedule;
        const selectedTour = registration.tours.find((st) => st.id === id);
        if (selectedTour) {
          selectedTourSchedule = selectedTour.schedules[0];
        }

        return (
          <div key={id} className="student-registration-tour">
            <div className="flex flex-align-center mb-16">
              <p className="fw-700 medium-text">
                {name} - {price ? `$${formatCurrency(price)}` : "Free"}
              </p>
              {autoEnrollStudents && (
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8 accent-text-dark">
                    info
                  </i>
                  <span className="tooltip">
                    This tour is mandatory for this student's group
                  </span>
                </span>
              )}
            </div>
            <ValidatedInput
              input={
                <Radio
                  checkboxBehavior={true}
                  disabled={!allowUpdate}
                  options={schedules.map((schedule) => ({
                    display: (
                      <div className="flex">
                        <p className="fw-700">{schedule.name}</p>
                        <p className="ml-8 mr-8">|</p>
                        <p>{`${moment(schedule.time).format(
                          "dddd, MMM D, h:mm A"
                        )} - ${moment(schedule.calculatedEndTime).format(
                          "h:mm A"
                        )}`}</p>
                      </div>
                    ),
                    tag: schedule.isFullToCapacity && (
                      <p className="isFullError">Tour is full</p>
                    ),
                    value: schedule.id,
                  }))}
                />
              }
              inputClassName=" " // blank inputClassName to override default custom-input class
              name={`tour-schedule-${id}`}
              onChange={(_, value) => onToggleTourSchedules(value, tour)}
              value={selectedTour ? selectedTourSchedule.id : null}
            />
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(RegistrationTours);
