import React from "react";
import { Link } from "react-router";

import { ContactType } from "../../Contact/ContactUtils";
// import { CustomEvent } from "../Utils";
import EventsListExt from "./EventsListExt";

// interface EventsListPageProps {
//   events: Array<CustomEvent>;
// }

const EventsListPage = ({ events }) => {
  return (
    <div className="col-sm-12">
      <ol className="breadcrumb">
        <li>
          <Link to="/config">Configure</Link>
        </li>
        <li>Custom Events</li>
      </ol>
      <EventsListExt contactType={ContactType.shluchim} events={events} />
      <br />
      <EventsListExt contactType={ContactType.students} events={events} />
    </div>
  );
};

export default EventsListPage;
