import React from "react";
import { Cell, PieChart, Pie, Tooltip } from "recharts";

const colors = [
  "#0B425D",
  "#53B7E8",
  "#007AB5",
  "#F3893D",
  "#6369D1",
  "#72D798",
  "#F36464",
];

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{ padding: "6px", backgroundColor: "black" }}
      >
        <p className="label">{payload[0].payload.percentageValue}%</p>
      </div>
    );
  }

  return null;
};

export default class MetricsPieChart extends React.PureComponent {
  render() {
    const data = this.props.data || [];
    const noDataValues = data.every((d) => !d.value);

    return (
      <div className="flex flex-align-center">
        {noDataValues ? (
          <div
            className="recharts-wrapper"
            style={{ width: "200px", height: "168px", position: "relative" }}
          >
            <img
              alt="pie chart placeholder"
              height="100"
              src="/img/pie-chart.svg"
            />
          </div>
        ) : (
          <PieChart width={200} height={168}>
            <Pie
              data={data}
              dataKey="value"
              label
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={52}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        )}

        <div className="metrics-pie-chart-labels ml-24">
          {data.map((data, index) => (
            <div className="flex flex-align-center" key={index}>
              <div
                className="metrics-pie-chart-label-square"
                style={{ background: colors[index % colors.length] }}
              />
              <p>{data.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
