import React from "react";
// import PropTypes from "prop-types";

const truncateString = (str, length) => {
  return str.length > length ? str.slice(0, length) + "..." : str;
};

// const propTypes = {
//     emailAddress: PropTypes.string.isRequired,
// };

export const EmailLink = ({ emailAddress = "" }) => {
  return (
    <a onClick={(e) => e.stopPropagation()} href={`mailto:${emailAddress}`}>
      {truncateString(emailAddress, 40)}
    </a>
  );
};
// EmailLink.propTypes = propTypes;
