import React from "react";
import { Link } from "react-router";
import { CSVLink } from "react-csv";

import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import ShabbatonReportingApi from "../../services/resources/ShabbatonReportingApi";
import ShabbatonSelection from "./ShabbatonSelection";
import DataTable /* , { DataTableColumn } */ from "../common/DataTable";
import DateRangePicker from "../common/DateRangePicker";
import { ArrayToLookup } from "../../lib/coc-common-scripts";

export class ShabbatonReportingBreakdownBySchool extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedSessionId: this.props.params.id || 0,
      startDate: moment.utc(+this.props.location.query.startDate),
      endDate: moment.utc(+this.props.location.query.endDate),
      reportData: [],
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleProgramSessionChange =
      this.handleProgramSessionChange.bind(this);
    this.requestSessionList = this.requestSessionList.bind(this);
    this.requestReportData = this.requestReportData.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ShabbatonDetailed)) {
      return;
    }

    this.requestSessionList();
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState(
        { [dateFieldName]: selectedDate, selectedSessionId: 0 },
        this.requestReportData,
      );
    };
  }

  handleProgramSessionChange(selectedSessionId) {
    this.setState({ selectedSessionId }, this.requestReportData);
  }

  requestSessionList() {
    this.setState({ loading: true });

    ShabbatonReportingApi.getList()
      .then((response) => {
        this.setState(
          { sessions: response.data.allSessions },
          this.requestReportData,
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {AxiosPromise} */
    let apiCall;

    if (this.state.selectedSessionId) {
      apiCall = ShabbatonReportingApi.getBySessionBreakdownBySchools(
        this.state.selectedSessionId,
        "name",
        false,
        true,
        0,
        0,
      );
    } else {
      apiCall = ShabbatonReportingApi.getByDatesBreakdownBySchools(
        this.state.startDate.clone().startOf("day"),
        this.state.endDate.clone().endOf("day"),
        "name",
        false,
        true,
        0,
        0,
      );
    }

    apiCall
      .then((response) => {
        this.setState({
          reportData: response.data,
          startDate:
            response.data.programInfo && response.data.programInfo.id > 0
              ? moment.utc(response.data.programInfo.startDate)
              : this.state.startDate,
          endDate:
            response.data.programInfo && response.data.programInfo.id > 0
              ? moment.utc(response.data.programInfo.endDate)
              : this.state.endDate,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.ShabbatonDetailed)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasDirectoryPermission = AuthService.UserHasClaim(
      PermissionClaims.DirectoryRead,
    );

    const { reportData, selectedSessionId, startDate, endDate } = this.state;
    const schools = (reportData && reportData.schools) || [];
    /** @type {Array<any>} */
    const sessions = (this.state.sessions && this.state.sessions) || [];
    const selectedSession =
      sessions.length && ArrayToLookup(sessions, "id")[selectedSessionId];
    /** @type {string} */
    const selectedSessionName = selectedSession ? selectedSession.title : "";
    /** @type {Array<DataTableColumn>} */
    const columns = [
      {
        Header: "School Name",
        accessor: "name",
        Cell: (c) =>
          hasDirectoryPermission ? (
            <Link to={`/schools/${c.original.id}`}>{c.original.name}</Link>
          ) : (
            c.original.name
          ),
        maxWidth: 400,
      },
      {
        Header: "Count",
        accessor: "count",
        maxWidth: 120,
        style: { textAlign: "center" },
      },
    ];

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/shabbaton">Pegisha</Link>
              </li>
              <li>
                <Link to="/shabbaton">Reports</Link>
              </li>
              <li>Schools</li>
            </ol>
          </div>
          <div className="col-sm-5">
            <ShabbatonSelection
              shabbatons={sessions}
              selectedShabbatonId={selectedSessionId}
              handleProgramSessionChange={this.handleProgramSessionChange}
            />
          </div>
          <div className="col-sm-5">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              handleDateInputChange={this.handleDateInputChange}
            />
          </div>
          <div className="col-sm-2" style={{ marginBottom: "1em" }}>
            <CSVLink
              className="btn btn-primary btn-md"
              data={schools}
              filename={`ShabbatonSchools_${
                selectedSessionId > 0
                  ? selectedSessionName
                  : startDate.format("YYYY-MM-DD") +
                    "_" +
                    endDate.format("YYYY-MM-DD")
              }.csv`}
            >
              Download CSV
            </CSVLink>
          </div>
          <div className="col-sm-12">
            <DataTable
              fullClientSide={true}
              columns={columns}
              reportData={schools}
            />
          </div>
          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }
}
