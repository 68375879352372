import React from "react";

export const Unauthorized = ({ userName }) => (
  <div className="container">
    <div className="row">
      <h1>Unauthorized</h1>
      User {userName} does not have permissions to access this feature. If you
      are not {userName} or have recently been granted access, try logging in
      again. Otherwise please contact an Administrator for assistance.
    </div>
  </div>
);
