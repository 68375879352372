import React from "react";
import PaginatedTable from "../shared/PaginatedTable";
import Select from "react-select";
import StudentActivityTableRow from "./StudentActivityTableRow";

import ShluchimApi from "../../services/resources/ShluchimApi";
import StudentsApi from "../../services/resources/StudentsApi";
import LamplightersApi from "../../services/resources/LamplightersApi";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import moment from "moment";

export default class StudentActivityTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
      errorMessage: "",
      filters: {
        educatorId: "",
        programType: this.activityProgramTypeDefaultOption,
        timeframe: this.activityTimeFrameOptions[0],
      },
      lamplighterSchedules: [],
      loading: false,
      page: 1,
      results: 12,
      success: true,
      total: 0,
    };
  }

  activityTimeFrameOptions = [
    { label: "All Time", value: "all" },
    { label: "Last 6 months", value: "last6Months" },
    { label: "Last year", value: "lastYear" },
    { label: "Last 2 years", value: "last2Years" },
  ];

  activityProgramTypeDefaultOption = {
    label: "All Programs",
    value: "",
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getStudentActivity();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onFilterActivity = (name, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => this.getStudentActivity(),
    );
  };

  getStudentActivity = (page = 1, results = this.state.results) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        success: true,
      },
      async () => {
        try {
          const { studentId, shliachId } = this.props;
          const studentActivityAction = this.props.studentId
            ? StudentsApi.getStudentActivity
            : ShluchimApi.getShliachStudentActivity;
          const studentActivity = await studentActivityAction(
            this.apiSignal.token,
            studentId || shliachId,
            page,
            results,
            this.state.filters.timeframe.value,
            this.state.filters.programType.value,
            this.state.filters.educatorId,
          );
          const hasLamplightersPermission = AuthService.UserHasClaim(
            PermissionClaims.GrantsChabadHouseView,
          );

          const lamplighterSchedules = hasLamplightersPermission
            ? await LamplightersApi.getLamplightersSchedules()
            : [];

          this.setState({
            activity: studentActivity.results || [],
            total: studentActivity.numberOfRows || 0,
            lamplighterSchedules: lamplighterSchedules,
          });
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              activity: [],
              errorMessage: ApiCallErrorMessageHandler(err),
              success: false,
              total: 0,
            });
          }
        }

        this.setState({ loading: false });
      },
    );
  };

  getCategorizedActivity = (activity) => {
    const categorizedActivity = {};

    activity.forEach((activity) => {
      const category = moment(activity.dateTime).format("MMM YYYY");
      if (!categorizedActivity.hasOwnProperty(category)) {
        categorizedActivity[category] = [];
      }
      categorizedActivity[category].push(activity);
    });

    return categorizedActivity;
  };

  renderCategorizedActivity = (activity, currentLamplightersSchedule) => {
    const categorizedActivity = this.getCategorizedActivity(activity);

    const { shliachId, studentId, studentName } = this.props;
    return Object.keys(categorizedActivity).map(
      (categoryKey, categoryIndex) => (
        <React.Fragment key={categoryIndex}>
          <p className="medium-text fw-700 mb-16">{categoryKey}</p>
          {categorizedActivity[categoryKey].map((activity, index) => (
            <StudentActivityTableRow
              activity={activity}
              isLamplightersEligibleInteraction={({ grantScheduleID }) =>
                grantScheduleID === currentLamplightersSchedule?.id
              }
              key={index}
              shliachId={shliachId}
              studentId={studentId}
              studentName={studentName}
            />
          ))}
        </React.Fragment>
      ),
    );
  };

  render() {
    const {
      activity,
      filters,
      loading,
      page,
      results,
      success,
      total,
      lamplighterSchedules,
    } = this.state;
    const { shliachId, studentActivityProgramTypes } = this.props;
    const currentLamplighterSchedule =
      lamplighterSchedules && lamplighterSchedules.find((s) => s.isCurrent);

    return (
      <React.Fragment>
        <div className="flex flex-justify-space">
          <p className="xxl-text fw-700">Activity</p>
          <div className="flex flex-align-center">
            {shliachId && (
              <div className="mr-8" style={{ width: "200px" }}>
                <Select
                  className="custom-select"
                  clearable={false}
                  onChange={(val) =>
                    this.onFilterActivity(
                      "educatorId",
                      val.value ? shliachId : "",
                    )
                  }
                  options={[
                    {
                      label: "Chabad House",
                      value: "",
                    },
                    {
                      label: "Shliach",
                      value: shliachId,
                    },
                  ]}
                  value={filters.educatorId || ""}
                />
              </div>
            )}
            <div className="mr-8" style={{ width: "200px" }}>
              <Select
                className="custom-select"
                clearable={false}
                onChange={(val) => this.onFilterActivity("programType", val)}
                options={[
                  this.activityProgramTypeDefaultOption,
                  ...(studentActivityProgramTypes
                    ? studentActivityProgramTypes.map((t) => ({
                        label: t.displayValue,
                        value: t.enumValue,
                      }))
                    : []),
                ]}
                value={filters.programType}
              />
            </div>
            <div style={{ width: "200px" }}>
              <Select
                className="custom-select"
                clearable={false}
                onChange={(val) => this.onFilterActivity("timeframe", val)}
                options={this.activityTimeFrameOptions}
                value={filters.timeframe}
              />
            </div>
          </div>
        </div>

        <PaginatedTable
          loading={loading}
          loadData={this.getStudentActivity}
          page={page}
          records={activity}
          renderHeader={() => ""}
          renderRecords={(activity) =>
            this.renderCategorizedActivity(activity, currentLamplighterSchedule)
          }
          results={results}
          success={success}
          totalCount={total}
        />
      </React.Fragment>
    );
  }
}
