import React from "react";
import Modal from "react-modal";
import Radio from "../../../shared/Radio";
import SelectSearch from "react-select";

export default class AddParticipantsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addByType: this.addByTypes.Region,
      newChabadHouses: [],
      newRegion: null,
    };
  }

  addByTypes = {
    ChabadHouse: "ChabadHouse",
    Region: "Region",
  };

  onCancel = () => {
    this.setState(
      {
        newChabadHouses: [],
        newRegion: null,
      },
      this.props.close,
    );
  };

  onSelectChabadHouses = (newChabadHouses) => {
    this.setState({ newChabadHouses });
  };

  onSelectRegion = (newRegion) => {
    this.setState({ newRegion });
  };

  onSubmit = () => {
    const { newChabadHouses, newRegion } = this.state;

    if (!newChabadHouses.length && !newRegion) {
      return;
    }

    const availableChabadHouses = this.getAvailableChabadHouses();
    const participants = [];

    if (newRegion) {
      availableChabadHouses
        .filter((ch) => ch.regionName === newRegion.value)
        .forEach((chabadHouse) => {
          participants.push(chabadHouse);
        });
    } else {
      newChabadHouses.forEach(({ value }) => {
        const chabadHouse = availableChabadHouses.find(
          (ch) => ch.chabadHouseID === value,
        );
        if (chabadHouse) {
          participants.push(chabadHouse);
        }
      });
    }

    this.props.submit(participants);

    this.setState({
      newChabadHouses: [],
      newRegion: null,
    });
  };

  onToggleAddByType = (name, value) => {
    this.setState({
      [name]: value,
      newChabadHouses: [],
      newRegion: null,
    });
  };

  getAvailableChabadHouses = () => {
    const { chabadHouses, currentParticipants } = this.props;

    const availableChabadHouses = chabadHouses.filter(
      (ch) =>
        !currentParticipants.find((p) => p.chabadHouseID === ch.chabadHouseID),
    );
    return availableChabadHouses;
  };

  getAvailableRegions = (availableChabadHouses) => {
    const availableRegions = [];
    availableChabadHouses.forEach((ch) => {
      if (ch.regionName && !availableRegions.find((r) => r === ch.regionName)) {
        availableRegions.push(ch.regionName);
      }
    });

    return availableRegions;
  };

  render() {
    const { show } = this.props;

    const { addByType, newChabadHouses, newRegion } = this.state;

    const availableChabadHouses = this.getAvailableChabadHouses();
    const availableRegions = this.getAvailableRegions(availableChabadHouses);

    return (
      <Modal isOpen={show} className="event-participants-modal-container">
        <div
          className="modal-card card medium-modal"
          style={{ maxHeight: "96vh", overflow: "scroll" }}
        >
          <p className="large-text fw-700 mb-32">Add Participants</p>
          <div className="mr-16">
            <Radio
              name="addByType"
              onChange={this.onToggleAddByType}
              options={[
                {
                  value: this.addByTypes.Region,
                  display: "By Region",
                  tag: (
                    <div
                      onClick={
                        addByType !== this.addByTypes.Region
                          ? () =>
                              this.onToggleAddByType(
                                "addByType",
                                this.addByTypes.Region,
                              )
                          : null
                      }
                    >
                      <SelectSearch
                        className="full-width ml-24"
                        disabled={addByType !== this.addByTypes.Region}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.onSelectRegion}
                        options={availableRegions.map((regionName) => ({
                          value: regionName,
                          label: regionName,
                        }))}
                        placeholder={"Select Region"}
                        value={newRegion}
                      />
                    </div>
                  ),
                },
                {
                  value: this.addByTypes.ChabadHouse,
                  display: "By Chabad House",
                  tag: (
                    <div
                      onClick={
                        addByType !== this.addByTypes.ChabadHouse
                          ? () =>
                              this.onToggleAddByType(
                                "addByType",
                                this.addByTypes.ChabadHouse,
                              )
                          : null
                      }
                    >
                      <SelectSearch
                        className="full-width ml-24"
                        disabled={addByType !== this.addByTypes.ChabadHouse}
                        isClearable={true}
                        isSearchable={true}
                        multi={true}
                        onChange={this.onSelectChabadHouses}
                        options={availableChabadHouses.map((ch) => ({
                          value: ch.chabadHouseID,
                          label: `${ch.chabadHouseName} - ${ch.campusName} - ${[
                            ch.shliachFirstName,
                            ch.shluchaFirstName,
                          ]
                            .filter((n) => n)
                            .join(" & ")} ${ch.shliachLastName}`,
                        }))}
                        placeholder={"Select Chabad Houses"}
                        value={newChabadHouses}
                      />
                    </div>
                  ),
                },
              ]}
              value={addByType}
            />
          </div>

          <div className="modal-btns relative">
            <button
              className="link-text uppercase-text"
              onClick={this.onCancel}
            >
              Cancel
            </button>
            <button
              className="link-text uppercase-text ml-40"
              disabled={!newChabadHouses.length && !newRegion}
              onClick={this.onSubmit}
            >
              Add
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
