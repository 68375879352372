import React from "react";
import Select from "react-select";
import _cloneDeep from "lodash.clonedeep";

export default class ClassificationRequirements extends React.PureComponent {
  onAdd = () => {
    const { onChange, requirements } = this.props;

    onChange([
      ...requirements,
      {
        classifications: [],
        hasIsraelRelatedCondition: false,
        hasShabbosMealCondition: false,
        israelRelatedMin: "",
        numRequiredInteractions: "",
        minDuration: "",
        shabbosMealMax: "",
      },
    ]);
  };

  onChange = (index, name, value, updateFlags = false) => {
    const { onChange, requirements } = this.props;

    const changedRequirements = _cloneDeep(requirements);
    changedRequirements[index][name] = value;

    if (updateFlags) {
      if (name === "hasIsraelRelatedCondition") {
        changedRequirements[index].israelRelatedMin = "";
      } else if (name === "hasShabbosMealCondition") {
        changedRequirements[index].shabbosMealMax = "";
      }
    }

    onChange(changedRequirements);
  };

  onRemove = (removeIndex) => {
    const { onChange, requirements } = this.props;

    onChange(requirements.filter((_, i) => i !== removeIndex));
  };

  render() {
    const {
      getErrorClassName,
      grantIndex,
      interactionClassifications,
      interactionDurations,
      readOnly,
      requirements,
      submitAttempted,
    } = this.props;

    const classificationOptions = interactionClassifications.map((c) => ({
      value: c.enumValue,
      label: c.displayValue,
    }));

    const durationOptions = interactionDurations.map((d) => ({
      value: d.enumValue,
      label: d.displayValue,
    }));

    return (
      <div>
        <div className="flex flex-align-center mb-8">
          <p className="small-text fw-700 mr-8">Requirements</p>
          {!readOnly && (
            <div
              className="flex flex-align-center link-text uppercase-text"
              onClick={this.onAdd}
            >
              <i className="material-icons">add_circle</i>
            </div>
          )}
        </div>
        <div>
          {requirements.map((requirement, index) => (
            <div className="classification-requirement" key={index}>
              <div>
                <div>
                  <div className="flex flex-align-center">
                    <p className="accent-text small-text mr-8">
                      Interaction Classification
                    </p>
                    <Select
                      className={getErrorClassName(
                        "custom-select mr-8",
                        submitAttempted &&
                          !(requirement.classifications?.length > 0),
                      )}
                      multi={true}
                      clearable={false}
                      disabled={readOnly}
                      onChange={(value) => {
                        this.onChange(
                          index,
                          "classifications",
                          value?.map((v) => v.value),
                        );
                      }}
                      options={classificationOptions}
                      value={classificationOptions.filter((o) =>
                        requirement.classifications?.includes(o.value),
                      )}
                    />
                    <p className="accent-text small-text mr-8">
                      Required Interactions
                    </p>
                    <input
                      className={getErrorClassName(
                        "custom-input mr-8",
                        submitAttempted && !requirement.numRequiredInteractions,
                      )}
                      disabled={readOnly}
                      min="0"
                      name="numRequiredInteractions"
                      onChange={(e) =>
                        this.onChange(index, e.target.name, e.target.value)
                      }
                      style={{ width: "60px" }}
                      type="number"
                      value={requirement.numRequiredInteractions}
                    />
                    <p className="accent-text small-text mr-8">Min Duration</p>
                    <Select
                      className={getErrorClassName(
                        "custom-select",
                        submitAttempted && !requirement.minDuration,
                      )}
                      clearable={false}
                      disabled={readOnly}
                      onChange={({ value }) =>
                        this.onChange(index, "minDuration", value)
                      }
                      options={durationOptions}
                      value={durationOptions.find(
                        (o) => o.value === requirement.minDuration,
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-align-center flex-justify-space mt-8">
                  <div className="flex flex-align-center full-width mr-16">
                    <div className="flex custom-checkbox-container mr-16">
                      <input
                        className="custom-checkbox"
                        checked={requirement.hasShabbosMealCondition}
                        disabled={readOnly}
                        id={`shabbos-meal-checkbox-${grantIndex}-${index}`}
                        onChange={() =>
                          this.onChange(
                            index,
                            "hasShabbosMealCondition",
                            !requirement.hasShabbosMealCondition,
                            requirement.hasShabbosMealCondition,
                          )
                        }
                        type="checkbox"
                      />
                      <label
                        className="flex-align-center small-text accent-text"
                        htmlFor={`shabbos-meal-checkbox-${grantIndex}-${index}`}
                      >
                        Shabbos Meal Restriction -
                      </label>
                    </div>
                    <p className="small-text mr-16">At Most</p>
                    <input
                      className="custom-input"
                      disabled={
                        !requirement.hasShabbosMealCondition || readOnly
                      }
                      min="0"
                      name="shabbosMealMax"
                      onChange={(e) =>
                        this.onChange(index, e.target.name, e.target.value)
                      }
                      style={{ width: "60px" }}
                      type="number"
                      value={requirement.shabbosMealMax}
                    />
                  </div>
                  <div className="flex flex-align-center full-width">
                    <div className="flex custom-checkbox-container mr-16">
                      <input
                        className="custom-checkbox"
                        checked={requirement.hasIsraelRelatedCondition}
                        disabled={readOnly}
                        id={`israel-related-checkbox-${grantIndex}-${index}`}
                        onChange={() =>
                          this.onChange(
                            index,
                            "hasIsraelRelatedCondition",
                            !requirement.hasIsraelRelatedCondition,
                            requirement.hasIsraelRelatedCondition,
                          )
                        }
                        type="checkbox"
                      />
                      <label
                        className="flex-align-center small-text accent-text"
                        htmlFor={`israel-related-checkbox-${grantIndex}-${index}`}
                      >
                        Israel/Antisemitism Related Qualification -
                      </label>
                    </div>
                    <p className="small-text mr-16">At Least</p>
                    <input
                      className="custom-input"
                      disabled={
                        !requirement.hasIsraelRelatedCondition || readOnly
                      }
                      min="0"
                      name="israelRelatedMin"
                      onChange={(e) =>
                        this.onChange(index, e.target.name, e.target.value)
                      }
                      style={{ width: "60px" }}
                      type="number"
                      value={requirement.israelRelatedMin}
                    />
                  </div>
                </div>
              </div>
              {!readOnly && (
                <i
                  className="material-icons xl-text link-text ml-16"
                  onClick={() => this.onRemove(index)}
                >
                  delete
                </i>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
