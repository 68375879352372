import decode from "jwt-decode";
import AuthApi from "./resources/AuthApi";
import {
  setSentryUser,
  clearSentryUser,
  removeImpersonationAuthCookies,
  loadScript,
} from "../lib";

export const PermissionClaims = {
  RoleManager: "RoleManager",
  DirectoryRead: "DirectoryRead",
  DirectoryEdit: "DirectoryEdit",
  IsraeLinksSummary: "IsraeLinksSummary",
  IsraeLinksDetailed: "IsraeLinksDetailed",
  ShabbatonSummary: "ShabbatonSummary",
  ShabbatonDetailed: "ShabbatonDetailed",
  KinusSummary: "KinusSummary",
  MediaPRSummary: "MediaPRSummary",
  RaffleChabadHouseEdit: "RaffleChabadHouseEdit",
  RaffleChabadHouseView: "RaffleChabadHouseView",
  RaffleDataEdit: "RaffleDataEdit",
  RaffleDataView: "RaffleDataView",
  RaffleFullEdit: "RaffleFullEdit",
  LampligthersSummary: "LampligthersSummary",
  TefillinSummary: "TefillinSummary",
  TefillinDetailed: "TefillinDetailed",
  ChayenuSummary: "ChayenuSummary",
  ChayenuDetailed: "ChayenuDetailed",
  LifeInsuranceSummary: "LifeInsuranceSummary",
  LifeInsuranceDetailed: "LifeInsuranceDetailed",
  CustomEventManager: "CustomEventManager",
  CampusEdit: "CampusEdit",
  CampusRead: "CampusRead",
  ChabadHouseEdit: "ChabadHouseEdit",
  ChabadHouseRead: "ChabadHouseRead",
  ProgramRead: "ProgramRead",
  ProgramEdit: "ProgramEdit",
  ShliachRead: "ShliachRead",
  ShliachEdit: "ShliachEdit",
  TripFullEdit: "TripFullEdit",
  TripDataEdit: "TripDataEdit",
  TripDataView: "TripDataView",
  TripChabadHouseEdit: "TripChabadHouseEdit",
  TripChabadHouseView: "TripChabadHouseView",
  LIFullEdit: "LIFullEdit",
  LIChabadHouseEdit: "LIChabadHouseEdit",
  LIChabadHouseView: "LIChabadHouseView",
  EduFullEdit: "EduFullEdit",
  EduFullView: "EduFullView",
  EduChabadHouseEdit: "EduChabadHouseEdit",
  EduChabadHouseView: "EduChabadHouseView",
  ConfigsEdit: "ConfigsEdit",
  RsvpAdminSettingsRead: "RsvpAdminSettingsRead",
  RsvpAdminSettingsEdit: "RsvpAdminSettingsEdit",
  UserImpersonation: "UserImpersonation",
  GrantsFullView: "GrantsFullView",
  GrantsFullEdit: "GrantsFullEdit",
  GrantsChabadHouseView: "GrantsChabadHouseView",
  GrantsChabadHouseEdit: "GrantsChabadHouseEdit",
  StudentEdit: "StudentEdit",
  StudentMerge: "StudentMerge",
  EngagementAdminView: "EngagementAdminView",
  EngagementAdminEdit: "EngagementAdminEdit",
};

const AuthService = {
  getToken: () => localStorage.getItem("token"),

  getCurrentUser() {
    const decodedToken = decode(this.getToken());

    //handle impersonation jwt signature: impersonation login occurs via the api (vs standard login via the legacy api) so the jwt signature is different
    const isImpersonatedUser = !decodedToken.id;
    if (isImpersonatedUser) {
      return {
        name: decodedToken.FullNameClaim,
        id: decodedToken.PersonIdClaim,
        role: decodedToken.LoggedInAsClaim,
        // signature: decodedToken.signature, // no signature present in impersonation jwt, not used in the system for now anyway so ignoring
        claims: decodedToken.PermissionClaim,
      };
    }

    return {
      name: `${decodedToken.firstName} ${decodedToken.lastName}`,
      id: decodedToken.id,
      role: decodedToken.role,
      signature: decodedToken.signature,
      claims: decodedToken.PermissionClaim,
    };
  },

  getCurrentUserClaims() {
    const user = this.getCurrentUser();
    const claims = (user && user.claims) || [];
    if (claims.push) {
      // it's an array
      return claims;
    }
    if (typeof claims === "string") {
      return [claims];
    }
    return [];
  },

  UserHasClaim(claim) {
    return !!this.getCurrentUserClaims().find(
      (c) => c === PermissionClaims[claim],
    );
  },

  login: ({ formData, failureHandler, successHandler }) => {
    AuthApi.login(formData)
      .then((response) => {
        localStorage.setItem("token", response.data.token);

        if (AuthService.isAuthenticated()) {
          const user = AuthService.getCurrentUser();
          setSentryUser(user.id, user.name);
        }

        successHandler(response.data);
      })
      .catch((err) => {
        failureHandler(err.response.data);
      });
  },

  isAuthenticated() {
    const token = this.getToken();

    if (!token) {
      return false;
    }

    const decodedToken = decode(token);

    const tokenExpirationTimestamp = decodedToken.exp;
    const currentTimestamp = Date.now() / 1000;

    if (tokenExpirationTimestamp < currentTimestamp) {
      localStorage.removeItem("token");
      clearSentryUser();
      return false;
    }

    return true;
  },

  logout: async () => {
    // disable google identity signin auto-select on logout
    // see https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out#sign-out
    await loadScript("https://accounts.google.com/gsi/client");
    const googleAccountId = window.google?.accounts?.id;
    if (googleAccountId) {
      googleAccountId.disableAutoSelect();
    }

    window.location.pathname = "/";

    localStorage.removeItem("token");
    clearSentryUser();
    removeImpersonationAuthCookies();
  },
};

export default AuthService;
