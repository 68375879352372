import React from "react";
import { Contacts } from "./Contacts";

export class Students extends React.Component {
  render() {
    return (
      <Contacts
        {...this.props}
        searchContactType="student"
        contactBasePath="/students"
        breadcrumbRoot="Students"
        // hideContactControls={true}
      />
    );
  }
}
