import React from "react";
import FormInput from "../../Form/FormInput";

export default class HebrewBirthdayField extends React.PureComponent {
  render() {
    return (
      <div className="flex flex-justify-space flex-align-center mr-24 relative">
        <FormInput
          label={
            <div className="line-height-double accent-text small-text flex flex-justify-space flex-align-center">
              Hebrew Birthday
              <span className="tooltip-container">
                <i className="material-icons link-text no-hover profile-add-icon">
                  info
                </i>
                <span className="tooltip">
                  Hebrew Birthday is calculated based on Birthday and
                  Nightfall/Sunset. It will update on save.
                </span>
              </span>
            </div>
          }
          type="text"
          readOnly={true}
          name="person.hebrewDOB"
          value={this.props.hebrewDOB}
          labelClassNames="line-height-double accent-text small-text flex flex-justify-space flex-align-center"
          className="custom-input ml-8"
        />
      </div>
    );
  }
}
