import React from "react";
import { browserHistory, Link } from "react-router";
// import classNames from "classnames";
import moment from "moment";

import ProgramActions from "./ProgramActions";
import ProgramOverview from "./ProgramOverview";
import ProgramSchedules from "./ProgramSchedules";
import Loader from "../../common/Loader";

import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import { Unauthorized } from "../../../lib/coc-common-components";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import ProgramsApi from "../../../services/resources/ProgramsApi";
import SystemApi from "../../../services/resources/SystemApi";
import {
  dateTimeWithSecondsFormat,
  inputStateMap,
  isNewIdParam,
  setStateOf,
  setStateOfAsync,
  validateEmail,
} from "../../../lib";
import Toggle from "../../shared/Toggle";

function ProgramFormContainer({
  actions,
  isActive,
  isNewProgram,
  children,
  programName,
}) {
  return (
    <div className="list-container program-form">
      <div className="rfloat">
        <ProgramActiveToggle isActive={isActive} actions={actions} />
      </div>
      <ProgramFormTitle isNewProgram={isNewProgram} programName={programName} />
      <hr style={{ marginLeft: -25, marginRight: -25 }} />
      <form className="form">{children}</form>
    </div>
  );
}

function ProgramActiveToggle({ isActive, actions }) {
  return (
    <Toggle
      className="active-toggle mr-16"
      name="isOpen"
      options={[
        {
          value: true,
          display: "Active",
        },
        { value: false, display: "Inactive" },
      ]}
      onChange={() => actions.changeState("program.isActive", !isActive)}
      value={isActive}
    />
  );
}

function ProgramFormTitle({ isNewProgram, programName }) {
  return isNewProgram && !programName ? (
    <h1>Add New Program</h1>
  ) : (
    <h1>{programName}</h1>
  );
}

function BreadcrumbsHeader({ isNewProgram, programName }) {
  return (
    <ol className="breadcrumb">
      <li>
        <Link to="/programs"> Programs</Link>
      </li>
      {isNewProgram && !programName ? <li>Add New</li> : <li>{programName}</li>}
    </ol>
  );
}

export default class ProgramDetailsPage extends React.PureComponent {
  /** True if the user pressed the Cancel button. */
  cancelled = false;

  state = {
    displayError: "",
    displaySuccess: "",
    lists: {} /** officeStaff, programAudiences, programCategories */,
    loadedState:
      {} /** State data as originally loaded from the server, used to detect changes and reset values. */,
    loading: true,
    program: {},
    schedule: {},
    scrollToSchedule: true,
    activeShluchim: [],
  };

  async componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return;
    }

    await this.getSystemLists();

    const {
      props: {
        params: { id },
      },
    } = this;
    const isNewProgram = isNewIdParam(id);
    await this.getActiveShluchim(isNewProgram ? 0 : id);
    if (!isNewProgram) {
      await this.getProgramDetails(id);
    } else {
      //set loaded state with defaults
      const loadedState = {
        program: { includesAllShluchim: true },
        schedule: { includesAllShluchim: true },
      };
      this.setState({
        loadedState,
        ...loadedState,
      });
    }

    this.setOnLeavePrompt();

    this.setState(
      {
        loading: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            scrollToSchedule: false,
          });
        }, 0);
      },
    );
  }

  getProgramDetails = async (programId) => {
    const program = await ProgramsApi.getProgram(programId).catch(
      this.setLoadingError(),
    );

    if (!program) {
      return;
    }

    if (program.schedules && program.schedules[0]) {
      const { sched } = this.props.location.query;
      const schedule =
        (sched &&
          program.schedules.find(
            (s) => s.id.toString() === sched.toString(),
          )) ||
        program.schedules[0];
      await this.getScheduleDetails(programId, schedule.id);
    }

    this.setLoadedState("program", program);
    this.setState({
      program,
    });
  };

  getScheduleDetails = async (programId, scheduleId) => {
    const schedule = await ProgramsApi.getSchedule(programId, scheduleId).catch(
      this.setLoadingError(),
    );

    if (!schedule) {
      return;
    }

    this.setLoadedState("schedule", schedule);
    this.setState({
      schedule,
    });

    browserHistory.replace(
      `${this.props.location.pathname}?sched=${schedule.id}`,
    );

    return schedule;
  };

  getActiveShluchim = async (programId) => {
    const activeShluchimResponse = await ProgramsApi.getProgramAvailShluchim(
      programId,
    ).catch(this.setLoadingError());

    if (!activeShluchimResponse) {
      return;
    }
    this.setState({ activeShluchim: activeShluchimResponse || [] });
  };

  getSystemLists = async () => {
    const [lists, officeStaff] = await Promise.all([
      SystemApi.lists(),
      SystemApi.officeStaff(),
    ]).catch(this.setLoadingError([undefined, undefined]));

    if (!lists) {
      return;
    }

    this.setState({
      lists: {
        ...lists,
        officeStaff,
      },
    });
  };

  setLoadedState = (name, value) =>
    this.setState({
      loadedState: {
        ...this.state.loadedState,
        [name]: value,
      },
    });

  changeDateTime = (name, date) =>
    setStateOf(
      this,
      name,
      date ? moment(date).format(dateTimeWithSecondsFormat) : null,
    ); //formatting to avoid EST -> UTC conversion for program schedule dates only
  changeInput = inputStateMap(this);
  changeState = (name, value) => setStateOf(this, name, value);

  changeScheduleOngoing = async (e) => {
    const checked = e.target.checked;
    if (checked) {
      const confirmed = await this.confirmClearScheduleDates();
      if (!confirmed) {
        return;
      }
    }
    this.setState((state) => {
      // Clear dates if isOngoing gets checked.
      const scheduleDateProps = checked
        ? {
            programStartDate: undefined,
            programEndDate: undefined,
            preRegisterStartDate: undefined,
            registerStartDate: undefined,
            registerEndDate: undefined,
            preRegisterURL: undefined,
            hasStarted: false,
          }
        : {};
      return {
        ...state,
        schedule: {
          ...state.schedule,
          isOngoing: checked,
          ...scheduleDateProps,
        },
      };
    });
  };
  confirmClearScheduleDates = () => {
    const {
      schedule: {
        preRegisterURL,
        programStartDate,
        programEndDate,
        preRegisterStartDate,
        registerStartDate,
        registerEndDate,
      },
    } = this.state;
    const hasScheduleDatesDate = !!(
      preRegisterURL ||
      programStartDate ||
      programEndDate ||
      preRegisterStartDate ||
      registerStartDate ||
      registerEndDate
    );
    if (!hasScheduleDatesDate) {
      return Promise.resolve(true);
    }
    return new Promise((resolve, reject) => {
      window.setTimeout(() => {
        const result = window.confirm(
          "Clear scheduled dates and pre-registration URL " +
            "to make ongoing schedule?",
        );
        resolve(result);
      }, 0);
    });
  };

  detectChanges() {
    const {
      loadedState: { program: programOrig, schedule: scheduleOrig },
      program,
      schedule,
    } = this.state;
    // DEBUG:
    // if (details !== detailsOrig) {
    //   console.log("CHANGED: details");
    //   return true;
    // } else if (program !== programOrig) {
    //   console.log("CHANGED: program");
    //   return true;
    // } else if (schedule !== scheduleOrig) {
    //   console.log("CHANGED: schedule");
    //   return true;
    // } else {
    //   console.log("NO-CHANGES");
    //   return false;
    // }
    return program !== programOrig || schedule !== scheduleOrig;
  }

  onCancel = () => {
    const { router: history } = this.props;
    if (!this.detectChanges() || (history && window.confirm("Are you sure?"))) {
      this.cancelled = true;
      history.goBack();
    }
  };
  /** Router hook. See https://github.com/ReactTraining/react-router/blob/v3/docs/guides/ConfirmingNavigation.md */
  onNavigate = (/* nextLocation */) => {
    if (this.cancelled) {
      return;
    }
    if (this.detectChanges()) {
      return "Unsaved changes will be lost. Are you sure you want to leave?";
    }
  };

  onPublish = async () => {
    this.save(true);
  };

  onSave = () => {
    this.save();
  };

  onSelectSchedule = async (scheduleId) => {
    return await this.getScheduleDetails(this.state.program.id, scheduleId);
  };

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.ProgramEdit)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    let {
      props: {
        params: { id },
      },
      state: {
        displayError,
        displaySuccess,
        lists,
        loadedState: { program: programOrig, schedule: scheduleOrig },
        loading,
        program,
        program: { isActive = false, name = "" },
        schedule,
        scrollToSchedule,
        activeShluchim,
      },
    } = this;

    const isNewProgram = isNewIdParam(id);
    const hasChanges = isNewProgram || this.detectChanges();

    const restrictedProgramEdit  = !isNewProgram && !!program.adminBaseUrl;
    const restrictedProgramScheduleEdit = restrictedProgramEdit && !program.adminSettingsBaseUrl;

    return !isNewProgram && !program.id && loading ? ( //initial load hide empty screen
      <div className="full-page-loader">
        <Loader />
      </div>
    ) : (
      <div className="col-md-12 program-form-page">
        {loading && (
          <div className="full-page-overlay-loader">
            <Loader />
          </div>
        )}
        <BreadcrumbsHeader isNewProgram={isNewProgram} programName={name} />
        <ProgramFormContainer
          actions={this}
          isActive={isActive}
          isNewProgram={isNewProgram}
          programName={name}
        >
          <ProgramOverview
            actions={this}
            activeShluchim={activeShluchim || []}
            isNewProgram={isNewProgram}
            program={program}
            programAudiences={lists.programAudiences || []}
            programCategories={lists.programCategories || []}
            programOrig={programOrig}
            restrictedEdit={restrictedProgramEdit}
            route={this.props.route}
            router={this.props.router}
            schedule={schedule}
          />
          <ProgramSchedules
            actions={this}
            officeStaff={lists.officeStaff || []}
            program={program}
            restrictedEdit={restrictedProgramScheduleEdit}
            schedule={schedule}
            scheduleOrig={scheduleOrig}
            scrollToSchedule={scrollToSchedule}
          />
        </ProgramFormContainer>
        <ProgramActions
          actions={this}
          hasChanges={hasChanges}
          schedule={schedule}
          errMessage={displayError}
          okMessage={displaySuccess}
        />
      </div>
    );
  }

  setLoadingError(defaultValue) {
    return (err) => {
      const errors = ApiCallErrorHandler(err, true);
      this.setState({
        loading: false,
        displayError: errors.join("\n"),
        displaySuccess: "",
      });
      return defaultValue;
    };
  }

  setOnLeavePrompt = () => {
    const {
      props: { route, router },
    } = this;
    router.setRouteLeaveHook(route, this.onNavigate);
  };

  async save(publish) {
    if ((!this.detectChanges() && !publish) || !this.validateSave()) {
      return;
    }

    const isPublished = this.state.schedule.isPublished;
    if ((publish || isPublished) && !this.validatePublish()) {
      return;
    }

    if (publish && !isPublished) {
      await setStateOfAsync(this, "schedule.isPublished", true);
      if (!this.state.program.isActive) {
        await setStateOfAsync(this, "program.isActive", true);
      }
    }

    await setStateOfAsync(this, "loading", true);

    const { program: programSrc, schedule: scheduleSrc } = this.state;

    const programData = {
      id: programSrc.id,
      name: programSrc.name,
      description: programSrc.description,
      url: programSrc.url,
      audience: { intValue: programSrc.audienceId },
      category: { intValue: programSrc.categoryId },
      status: programSrc.isActive ? "Active" : "Inactive",
      includesAllShluchim: programSrc.includesAllShluchim,
      resourcesFolderID: programSrc.resourcesFolderID,
      registrationType: programSrc.registrationType,
      allowMultipleRegistrations: programSrc.allowMultipleRegistrations,
      hasApplicationProcess: programSrc.hasApplicationProcess,
      schedule: {
        ...scheduleSrc,
        id: scheduleSrc.id <= 0 ? undefined : scheduleSrc.id,
        contents:
          scheduleSrc.contents &&
          scheduleSrc.contents.map((content) => ({
            ...content,
            id: content.id <= 0 ? undefined : content.id,
          })),
      },
    };

    const savedProgram = await ProgramsApi.saveProgram(programData).catch(
      this.setLoadingError(),
    );

    if (!savedProgram) {
      return;
    }

    const { schedule, ...program } = savedProgram;

    const loadedState = {
      program,
      schedule,
    };
    this.setState(
      {
        displaySuccess: "Changes saved.",
        loadedState,
        loading: false,
        program,
        schedule,
        scrollToSchedule: true,
      },
      () => {
        if (scheduleSrc.id !== schedule.id) {
          //new schedule, set sched query param
          browserHistory.replace(
            `${this.props.location.pathname}?sched=${schedule.id}`,
          );
        }

        this.setState({ scrollToSchedule: false });
      },
    );

    const {
      router: history,
      params: { id },
    } = this.props;
    const isNewProgram = isNewIdParam(id);
    if (isNewProgram) {
      history.replace(`/programs/${program.id}`);
    }
  }

  validatePublish() {
    const {
      schedule: {
        isOngoing,
        officeStaffID,
        programStartDate,
        programEndDate,
        preRegisterStartDate,
        registerStartDate,
        registerEndDate,
      },
      displayError,
      displaySuccess,
    } = this.state;

    const missing = [];

    if (!officeStaffID) missing.push("Coordinator");
    if (missing.length > 0) {
      this.setState({
        displayError: `Please fill in the missing fields: ${missing.join(
          ", ",
        )}.`,
        displaySuccess: "",
      });
      return false;
    }
    if (!isOngoing && programStartDate && programEndDate) {
      if (!moment(programEndDate).isSameOrAfter(moment(programStartDate))) {
        this.setState({
          displayError:
            "Program End Date must be later than Program Start Date.",
          displaySuccess: "",
        });
        return false;
      }
    }
    if (registerStartDate && registerEndDate) {
      if (!moment(registerEndDate).isAfter(moment(registerStartDate))) {
        this.setState({
          displayError:
            "Shluchim Registration End Date must be later than Registration Start Date.",
          displaySuccess: "",
        });
        return false;
      }
    }
    if (preRegisterStartDate && registerStartDate) {
      if (!moment(registerStartDate).isAfter(moment(preRegisterStartDate))) {
        this.setState({
          displayError:
            "Shluchim Registration Start Date must be later than Pre-Registration Date.",
          displaySuccess: "",
        });
        return false;
      }
    }
    if (displayError || displaySuccess) {
      this.setState({ displayError: "", displaySuccess: "" });
    }
    return true;
  }

  validateSave() {
    const {
      program: { name, audienceId, categoryId, description },
      schedule,
      displayError,
      displaySuccess,
    } = this.state;

    const missing = [];

    if (!name) missing.push("Name");
    if (!audienceId) missing.push("Audience");
    if (!categoryId) missing.push("Category");
    if (!description) missing.push("Description");
    if (missing.length > 0) {
      this.setState({
        displayError: `Please fill in the missing fields: ${missing.join(
          ", ",
        )}.`,
        displaySuccess: "",
      });
      return false;
    }
    const invalid = [];
    if (schedule.email && !validateEmail(schedule.email)) {
      invalid.push("Email");
    }
    if (schedule.contents && Object.keys(schedule.contents).length > 1) {
      const sectionNames = Object.keys(schedule.contents).map((sectionKey) =>
        schedule.contents[sectionKey].name
          ? schedule.contents[sectionKey].name.trim().toLowerCase()
          : "",
      );
      const duplicateNames = sectionNames.filter(
        (name, index) => sectionNames.indexOf(name) !== index,
      );
      if (duplicateNames.length) {
        invalid.push(
          `Duplicate program section${
            duplicateNames.length > 1 ? "s" : ""
          }.  Section names must be unique`,
        );
      }
    }
    if (invalid.length > 0) {
      this.setState({
        displayError: `Please correct invalid fields: ${invalid.join(", ")}.`,
        displaySuccess: "",
      });
      return false;
    }
    if (displayError || displaySuccess) {
      this.setState({ displayError: "", displaySuccess: "" });
    }
    return true;
  }
}
