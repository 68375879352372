import React from "react";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import TourDetailsCard from "./TourDetailsCard";

export default class EventTours extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTourIndex: props.event.tours.length ? 0 : null,
      deleteTourIndex: null,
      showDeleteTourConfirmationModal: false,
    };
  }

  onAddTour = () => {
    const {
      onChange,
      event: { tours },
    } = this.props;

    const newTourIndex = tours.length;

    onChange(`tours[${newTourIndex}]`, {
      id: 0,
      name: "",
      price: "",
      isPriceIncludedInTripPrice: false,
      durationHours: "",
      durationMinutes: "",
      hasTransportation: false,
      autoEnrollStudents: false,
      schedules: [],
      shluchimDescription: "",
      studentDescription: "",
    });

    this.setState({
      currentTourIndex: newTourIndex,
    });
  };

  onChangeTour = async (name, value) => {
    const { onChange } = this.props;
    const { currentTourIndex } = this.state;

    await onChange(`tours[${currentTourIndex}].[${name}]`, value);
  };

  onChangeTourEvt = async (e) =>
    await this.onChangeTour(e.target.name, e.target.value);

  onDeleteTour = (index) => {
    this.setState({
      deleteTourIndex: index,
      showDeleteTourConfirmationModal: true,
    });
  };

  deleteTour = () => {
    const {
      onChange,
      event: { tours },
    } = this.props;
    const { deleteTourIndex } = this.state;

    const updatedTours = [...tours];
    updatedTours.splice(deleteTourIndex, 1);
    onChange("tours", updatedTours);

    this.setState({
      deleteTourIndex: null,
      showDeleteTourConfirmationModal: false,
    });
  };

  render() {
    const {
      event: {
        hasStartedShluchimEnrollment,
        tours,
        tripEndDate,
        tripStartDate,
      },
      submitAttempted,
    } = this.props;

    const {
      currentTourIndex,
      deleteTourIndex,
      showDeleteTourConfirmationModal,
    } = this.state;

    return (
      <React.Fragment>
        <div>
          <div className="flex">
            <div className="program-settings-tour-card mr-16">
              <div className="flex flex-justify-space flex-align-center mb-24">
                <p className="xxl-text">Tours</p>
                <button
                  className="btn custom-btn btn-accent btn-small uppercase-text small-text"
                  onClick={this.onAddTour}
                >
                  Add
                </button>
              </div>
              <div className="tours">
                {!!(tours && tours.length) ? (
                  tours.map((tour, index) => (
                    <div
                      className={`tour flex flex-align-center flex-justify-space ${
                        currentTourIndex === index ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => this.setState({ currentTourIndex: index })}
                    >
                      <p className="tour-name medium-text accent-text-dark">
                        {tour.name || "[Untitled Tour]"}
                      </p>
                      <div className="tour-actions flex">
                        <i
                          className={`material-icons large-text ${
                            hasStartedShluchimEnrollment && tour.id
                              ? "disabled"
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!hasStartedShluchimEnrollment || !tour.id) {
                              this.onDeleteTour(index);
                            }
                          }}
                          title={
                            hasStartedShluchimEnrollment
                              ? "Tours cannot be deleted once event registration has started"
                              : ""
                          }
                        >
                          delete
                        </i>
                      </div>
                    </div>
                  ))
                ) : (
                  <span>No tours</span>
                )}
              </div>
            </div>

            {currentTourIndex >= 0 && tours[currentTourIndex] && (
              <TourDetailsCard
                hasStartedShluchimEnrollment={hasStartedShluchimEnrollment}
                ohelTour={tours
                  .slice(0, currentTourIndex)
                  .find(
                    (tour) =>
                      tour.name && tour.name.toLowerCase().includes("ohel"),
                  )}
                onChange={this.onChangeTour}
                onChangeEvt={this.onChangeTourEvt}
                onClose={() => this.setState({ currentTourIndex: null })}
                submitAttempted={submitAttempted}
                tour={tours[currentTourIndex]}
                tripEndDate={tripEndDate}
                tripStartDate={tripStartDate}
              />
            )}
          </div>

          <ConfirmationModal
            cancel={() =>
              this.setState({ showDeleteTourConfirmationModal: false })
            }
            confirm={this.deleteTour}
            message={`Are you sure you want to delete ${
              (tours[deleteTourIndex] && tours[deleteTourIndex].title) || "this"
            } tour?`}
            show={showDeleteTourConfirmationModal}
          />
        </div>
      </React.Fragment>
    );
  }
}
