import React from "react";

import { FieldArray } from "formik";
import FormInput from "../../Form/FormInput";
import CustomSelect from "../../Form/CustomSelect";

export default class PersonnelInfo extends React.PureComponent {
  state = {
    personnelInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        personnelInfoShowing: false,
      });
    }
  }

  render() {
    const {
      errors,
      require,
      setFieldValue,
      touched,
      values,
      readOnly,
      sys: { chabadHousePersonnelPositions },
    } = this.props;
    return (
      <div
        className={
          this.state.personnelInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="personnel">
          {(arrayHelpers) => (
            <React.Fragment>
              <div className="flex mobile-flex-justify-space flex-align-center mb-16 mt-48">
                <p
                  className="accent-text-dark medium-text fw-700"
                  onClick={() => {
                    this.setState({
                      personnelInfoShowing: !this.state.personnelInfoShowing,
                    });
                  }}
                >
                  Personnel
                </p>
                {readOnly ? null : (
                  <i
                    className={`material-icons link-text profile-add-icon ml-16 ${
                      !this.state.personnelInfoShowing ? "hidden" : ""
                    }`}
                    onClick={() =>
                      arrayHelpers.unshift({
                        firstName: "",
                        lastName: "",
                        position: "",
                        email: "",
                      })
                    }
                  >
                    add_circle
                  </i>
                )}
              </div>
              <div className="profile-form-personnel-info profile-form-inputs">
                {values.personnel &&
                  values.personnel.map((personnel, index) => (
                    <div
                      key={index}
                      className="flex flex-justify-space flex-align-center personnel-row-container hover-delete-container"
                    >
                      <div className="personnel-row">
                        <div>
                          <FormInput
                            label="First Name"
                            type="text"
                            name={`personnel[${index}].firstName`}
                            validate={require}
                            className="form-required"
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Last Name"
                            type="text"
                            name={`personnel[${index}].lastName`}
                            validate={require}
                            className="form-required"
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Position"
                            name={`personnel[${index}].position`}
                            validate={require}
                            className="form-required custom-input mr-12"
                            errors={errors}
                            touched={touched}
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                readOnly={readOnly}
                                options={
                                  chabadHousePersonnelPositions &&
                                  chabadHousePersonnelPositions.map((p) => ({
                                    key: p.enumValue,
                                    value: p.enumValue,
                                    label: p.displayValue,
                                  }))
                                }
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>

                        <div>
                          <FormInput
                            label="Email"
                            type="email"
                            name={`personnel[${index}].email`}
                            validate={require}
                            className="form-required"
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                      {readOnly ? null : (
                        <i
                          className="material-icons link-text ml-16 hover-delete"
                          style={{ fontSize: "16px" }}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          delete
                        </i>
                      )}
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </FieldArray>
      </div>
    );
  }
}
