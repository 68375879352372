import React from "react";
import { browserHistory } from "react-router";
import AccountActivity from "./AccountActivity";
import ProgramActivity from "./ProgramActivity";
import ResourcesActivity from "./ResourcesActivity";
import StudentActivity from "./StudentActivity";
import queryString from "query-string";

export default class ShliachActivityTables extends React.PureComponent {
  constructor(props) {
    super(props);

    const tabs = [
      { name: "Program", id: "program", component: ProgramActivity },
      { name: "Student", id: "student", component: StudentActivity },
      { name: "Account", id: "account", component: AccountActivity },
      { name: "Resources", id: "resources", component: ResourcesActivity },
    ];
    const tabIndex = tabs.findIndex((t) => t.id === props.location.query.tab);

    this.state = {
      tabIndex: tabIndex > 0 ? tabIndex : 0,
      tabs,
    };
  }

  toTab = (index) => {
    const { tabIndex, tabs } = this.state;
    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      const query = {
        tab: tabs[index].id,
      };
      const {
        location: { pathname },
      } = this.props;
      browserHistory.replace(`${pathname}?${queryString.stringify(query)}`);
    }
  };

  render() {
    const { tabIndex, tabs } = this.state;
    const tab = tabs[tabIndex] || tabs[0];

    return (
      <div className="card mt-40">
        <p className="xl-text fw-700 mb-16">Activity</p>
        <div className="program-schedule-details-tabs flex mb-24">
          {tabs.map((t, index) => (
            <p
              key={index}
              className={`large-text ${index > 0 ? "ml-40" : ""} ${
                tabIndex === index ? "active" : ""
              }`}
              onClick={() => this.toTab(index)}
            >
              {t.name}
            </p>
          ))}
        </div>
        {tab && React.createElement(tab.component, this.props)}
      </div>
    );
  }
}
