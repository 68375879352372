import React from "react";
import { Link } from "react-router";

import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import viewHistory from "../../services/ViewHistory";
import { ReportingTile } from "../common/ReportingTile";
import * as ReportingHelpers from "../../assets/scripts/reportingHelpers";
import ShabbatonReportingApi from "../../services/resources/ShabbatonReportingApi";
import ShabbatonSelection from "./ShabbatonSelection";
import DateRangePicker from "../common/DateRangePicker";
import AgeBarChart from "../common/ReportingAgeBarChart";
import GenderPieChart from "../common/ReportingGenderPieChart";

export class ShabbatonReporting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedSessionId: 0,
      startDate: moment.utc().subtract(2, "years"),
      endDate: moment.utc(),
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
    this.handleProgramSessionChange =
      this.handleProgramSessionChange.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ShabbatonSummary)) {
      return;
    }

    viewHistory.add("Program", {
      title: "Pegisha",
      path: "/shabbaton",
    });
    this.requestSessionList();
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState(
        { [dateFieldName]: selectedDate, selectedSessionId: 0 },
        this.requestReportData,
      );
    };
  }

  handleProgramSessionChange(selectedSessionId) {
    this.setState({ selectedSessionId }, this.requestReportData);
  }

  requestSessionList() {
    this.setState({ loading: true });

    ShabbatonReportingApi.getList()
      .then((response) => {
        const today = moment.utc();
        const activeSession = response.data.allSessions
          ? response.data.allSessions.find(
              (s) =>
                moment.utc(s.startDate) <= today &&
                moment.utc(s.endDate).endOf("day") >= today,
            )
          : {};
        const selectedSessionId =
          (activeSession && activeSession.id) ||
          (response.data.allSessions && response.data.allSessions[0].id) ||
          0;
        this.setState(
          { sessions: response.data.allSessions, selectedSessionId },
          this.requestReportData,
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {AxiosPromise} */
    let apiCall;

    if (this.state.selectedSessionId) {
      apiCall = ShabbatonReportingApi.getSingle(this.state.selectedSessionId);
    } else {
      apiCall = ShabbatonReportingApi.getByDates(
        this.state.startDate.clone().startOf("day"),
        this.state.endDate.clone().endOf("day"),
      );
    }

    apiCall
      .then((response) => {
        this.setState({
          reportData: response.data,
          startDate:
            response.data.programInfo && response.data.programInfo.id > 0
              ? moment.utc(response.data.programInfo.startDate)
              : this.state.startDate,
          endDate:
            response.data.programInfo && response.data.programInfo.id > 0
              ? moment.utc(response.data.programInfo.endDate)
              : this.state.endDate,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.ShabbatonSummary)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const hasShabbatonDetailedPermission = AuthService.UserHasClaim(
      PermissionClaims.ShabbatonDetailed,
    );

    const { reportData, selectedSessionId, startDate, endDate } = this.state;

    const programInfo = reportData ? reportData.programInfo : {};
    const genderArray = ReportingHelpers.GetGenderPercentages(
      (reportData && reportData.countByGender) || [],
    );
    const ageArray = ReportingHelpers.GetAgeArray(
      (reportData && reportData.countByAge) || [],
    );
    const averageAgeAsString = ReportingHelpers.GetAverageAgeAsString(ageArray);
    /** @type {Array<any>} */
    const sessions = (this.state.sessions && this.state.sessions) || [];

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/shabbaton">Pegisha</Link>
              </li>
              <li>Reports</li>
            </ol>
          </div>
          <div className="col-sm-6">
            <ShabbatonSelection
              shabbatons={sessions}
              selectedShabbatonId={selectedSessionId}
              handleProgramSessionChange={this.handleProgramSessionChange}
            />
          </div>
          <div className="col-sm-6" style={{ marginBottom: "1em" }}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              handleDateInputChange={this.handleDateInputChange}
            />
          </div>
          {selectedSessionId > 0 && (
            <div>
              <div className="col-sm-12">
                <h2>{programInfo.title}</h2>
              </div>
              <div>
                <div className="col-md-6">
                  <ReportingTile
                    label="Start Date"
                    value={moment
                      .utc(programInfo.startDate)
                      .format("MMMM D, YYYY")}
                  />
                </div>
                <div className="col-md-6">
                  <ReportingTile
                    label="End Date"
                    value={moment
                      .utc(programInfo.endDate)
                      .format("MMMM D, YYYY")}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-md-3">
            {hasShabbatonDetailedPermission ? (
              <Link
                to={`/shabbaton/reporting/bystudent/${
                  selectedSessionId > 0
                    ? selectedSessionId
                    : `?startDate=${startDate}&endDate=${endDate}`
                }`}
              >
                <ReportingTile
                  label="Participants"
                  value={ReportingHelpers.GetReportValue(
                    reportData,
                    "totalCount",
                  )}
                />
              </Link>
            ) : (
              <ReportingTile
                label="Participants"
                value={ReportingHelpers.GetReportValue(
                  reportData,
                  "totalCount",
                )}
              />
            )}{" "}
          </div>
          <div className="col-md-3">
            {hasShabbatonDetailedPermission ? (
              <Link
                to={`/shabbaton/reporting/byschool/${
                  selectedSessionId > 0
                    ? selectedSessionId
                    : `?startDate=${startDate}&endDate=${endDate}`
                }`}
              >
                <ReportingTile
                  label="Schools"
                  value={ReportingHelpers.GetReportValue(
                    reportData,
                    "totalSchools",
                  )}
                />
              </Link>
            ) : (
              <ReportingTile
                label="Schools"
                value={ReportingHelpers.GetReportValue(
                  reportData,
                  "totalSchools",
                )}
              />
            )}
          </div>
          <div className="col-md-3">
            <ReportingTile label="Average Age" value={averageAgeAsString} />
          </div>
          <div className="col-md-6">
            <GenderPieChart
              genderArray={genderArray}
              displayName="Participants"
            />
          </div>
          <div className="col-md-6">
            <AgeBarChart ageArray={ageArray} displayName="Participants" />
          </div>

          {this.state.loading && <Loader />}
        </div>
      </div>
    );
  }
}
