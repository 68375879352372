import AuthRequest from "./AuthRequest";
// import { Country } from '../../models/Common';

const baseUrl = "/api/basicdata";

const BasicDataApi = {
  // createCountry: (country: Country) => {
  //     return AuthRequest.post(`${baseUrl}`, { ...event });
  // },
  listCountries: () => {
    return AuthRequest.get(`${baseUrl}/countries`, {});
  },
  listStates: () => {
    return AuthRequest.get(`${baseUrl}/states`, {});
  },
  listCountriesAndStates: () => {
    return AuthRequest.get(`${baseUrl}/countriesandstates`, {});
  },
};

export default BasicDataApi;
