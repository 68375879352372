import React from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";

import ClassificationRequirements from "./configuration/ClassificationRequirements";
import Conditions from "./configuration/Conditions";

import { formatNumber, parseNumber, sum } from "../../../lib";
import _cloneDeep from "lodash.clonedeep";

export default class Configuration extends React.PureComponent {
  getGrantTotals = (grants) => {
    const grantTotalsByType = [];
    let allGrantsTotal = 0;

    grants
      .sort((t1, t2) => (t1.grantTypeDisplay < t2.grantTypeDisplay ? -1 : 1))
      .forEach(
        ({
          type: grantType,
          id,
          typeDisplay: grantTypeDisplay,
          description: grantDescription,
        }) => {
          const grantsById = grants.filter(
            (g) => g.id === id && g.quantity && g.amount,
          );

          if (grantsById.length) {
            const grantTypeTotal = sum(
              grantsById.map((g) => g.quantity * g.amount),
            );

            grantTotalsByType.push({
              grantType,
              grantTypeDisplay,
              grantDescription,
              grants: grantsById,
              total: grantTypeTotal,
            });
            allGrantsTotal += grantTypeTotal;
          }
        },
      );

    return {
      allGrantsTotal,
      grantTotalsByType,
    };
  };

  onAddGrant = () => {
    const {
      onChange,
      schedule: { grants },
    } = this.props;

    onChange("grants", [
      ...grants,
      {
        amount: "",
        description: "",
        quantity: "",
        type: "",
        //grant configurations
        classificationRequirements: [],
        minInteractionsRangeWeeks: "",
        hasStudentAgeCondition: false,
        maxStudentAge: "",
        minStudentAge: "",
        israelRelatedMin: "",
        shabbosMealMax: "",
      },
    ]);

    //scroll new grant into view
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    setTimeout(() => {
      const scrollWindow = document.getElementById("scroll-window");
      if (scrollWindow) {
        const scrollHeight = scrollWindow.scrollHeight;
        scrollWindow.scrollTo({
          left: 0,
          top: scrollHeight,
          behavior: "smooth",
        });
      }
    });
  };

  onChangeGrant = (index, name, value, updateFlags = false) => {
    const {
      onChange,
      schedule: { grants },
    } = this.props;

    const changedGrants = _cloneDeep(grants);
    changedGrants[index][name] = value;

    if (updateFlags) {
      if (name === "hasStudentAgeCondition") {
        changedGrants[index].maxStudentAge = "";
        changedGrants[index].minStudentAge = "";
      }
    }

    onChange("grants", changedGrants);
  };

  onRemoveGrant = (removeIndex) => {
    const {
      onChange,
      schedule: { grants },
    } = this.props;

    onChange(
      "grants",
      grants.filter((_, i) => i !== removeIndex),
    );
  };

  render() {
    const {
      getErrorClassName,
      grantTypes = [],
      interactionClassifications = [],
      interactionDurations = [],
      readOnly,
      saveButtons,
      schedule: { didScheduleStart, grants },
      submitAttempted,
    } = this.props;
    const configsReadOnly = readOnly || didScheduleStart;
    const quantityReadOnly = readOnly;

    const grantTypeOptions = grantTypes.map((type) => ({
      value: type.enumValue,
      label: type.displayValue,
    }));

    const { allGrantsTotal, grantTotalsByType } = this.getGrantTotals(grants);

    return (
      <div className="lamplighters-settings-step">
        <div className="lamplighters-settings-step-section section-title">
          <p className="xl-text">Configuration</p>
          {saveButtons}
        </div>
        <div className="lamplighters-settings-step-section">
          <div className="flex flex-align-center">
            <p className="medium-text fw-700 mr-16">Grant Configuration</p>
            {!configsReadOnly && (
              <div
                className="flex flex-align-center btn btn-accent btn-small custom-btn uppercase-text"
                onClick={this.onAddGrant}
              >
                <i className="material-icons medium-text mr-4">add</i>
                Add
              </div>
            )}
          </div>
          <p className="small-text accent-text mt-4">
            All grant configurations other than quantity cannot be updated past
            schedule start date
          </p>
          <div className="lamplighters-grant-totals accent-text-secondary">
            {grantTotalsByType.map((totals, i) => (
              <div className="mb-4" key={i}>
                <span className="fw-700">
                  {totals.grantTypeDisplay}
                  {totals.grantDescription
                    ? ` ${totals.grantDescription}`
                    : ""}{" "}
                  Grants:{" "}
                </span>
                <span>
                  {totals.grants
                    .map(
                      (g) =>
                        `${formatNumber(g.quantity)} * $${formatNumber(
                          g.amount,
                        )}`,
                    )
                    .join(", ")}
                </span>
                <span className="fw-700"> = ${formatNumber(totals.total)}</span>
              </div>
            ))}
            {allGrantsTotal > 0 && (
              <p className="fw-700">
                Lamplighters Total: ${formatNumber(allGrantsTotal)}
              </p>
            )}
          </div>
          <div className="mb-40">
            {grants.map((grant, index) => (
              <div
                className="lamplighters-grant-configuration-card"
                key={index}
              >
                <div>
                  <div className="mb-16">
                    <div className="configuration-grid mb-16">
                      <div className="flex flex-align-center">
                        <p className="accent-text small-text lamplighters-label">
                          Grant Type
                        </p>
                        <Select
                          className={getErrorClassName(
                            "custom-select",
                            submitAttempted && !grant.type,
                          )}
                          clearable={false}
                          disabled={configsReadOnly}
                          onChange={({ value }) =>
                            this.onChangeGrant(index, "type", value)
                          }
                          options={grantTypeOptions}
                          value={grantTypeOptions.find(
                            (o) => o.value === grant.type,
                          )}
                        />
                      </div>
                      <div className="flex flex-align-center">
                        <p className="accent-text small-text lamplighters-label">
                          Amount per Grant
                        </p>
                        <NumberFormat
                          className={getErrorClassName(
                            "custom-input dollar-input",
                            submitAttempted && !grant.amount,
                          )}
                          decimalScale={0}
                          disabled={configsReadOnly}
                          onChange={(e) =>
                            this.onChangeGrant(
                              index,
                              "amount",
                              parseNumber(e.target.value),
                            )
                          }
                          thousandSeparator={true}
                          value={grant.amount}
                        />
                      </div>
                      <div className="flex flex-align-center">
                        <p className="accent-text small-text lamplighters-label">
                          Qty of Grants
                        </p>
                        <NumberFormat
                          className={getErrorClassName(
                            "custom-input",
                            submitAttempted && !grant.quantity,
                          )}
                          decimalScale={0}
                          disabled={quantityReadOnly}
                          onChange={(e) =>
                            this.onChangeGrant(
                              index,
                              "quantity",
                              parseNumber(e.target.value),
                            )
                          }
                          thousandSeparator={true}
                          value={grant.quantity}
                        />
                      </div>
                    </div>
                    <div className="flex flex-align-center full-width">
                      <p className="accent-text small-text lamplighters-label">
                        Grant Description{" "}
                        <span className="fw-500">(optional)</span>
                      </p>
                      <input
                        className="custom-input full-width"
                        disabled={configsReadOnly}
                        name="description"
                        onChange={(e) =>
                          this.onChangeGrant(
                            index,
                            e.target.name,
                            e.target.value,
                          )
                        }
                        value={grant.description}
                      />
                    </div>
                  </div>
                  <div className="small-text mb-16">
                    All students participating in Grant must be Halachically
                    Jewish
                    {grant.type === "Learning" &&
                      " and cannot be enrolled in a current or past Sinai Scholars Program, or plan to enroll in the future"}
                    <br />
                    Students can participate in each grant type once
                  </div>
                  <Conditions
                    getErrorClassName={getErrorClassName}
                    grant={grant}
                    grantIndex={index}
                    onChange={(...changeProps) =>
                      this.onChangeGrant(index, ...changeProps)
                    }
                    readOnly={configsReadOnly}
                    submitAttempted={submitAttempted}
                  />

                  <ClassificationRequirements
                    getErrorClassName={getErrorClassName}
                    grantIndex={index}
                    interactionClassifications={interactionClassifications}
                    interactionDurations={interactionDurations}
                    onChange={(requirements) =>
                      this.onChangeGrant(
                        index,
                        "classificationRequirements",
                        requirements,
                      )
                    }
                    readOnly={configsReadOnly}
                    requirements={grant.classificationRequirements}
                    submitAttempted={submitAttempted}
                  />
                </div>
                {!configsReadOnly && (
                  <i
                    className="material-icons xl-text link-text ml-16"
                    onClick={() => this.onRemoveGrant(index)}
                  >
                    delete
                  </i>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
