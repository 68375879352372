import React from "react";
import StudentActivityTable from "../../Student/StudentActivityTable";

export default ({
  shliachId,
  systemLists: { studentActivityProgramTypes },
}) => (
  <StudentActivityTable
    shliachId={shliachId}
    studentActivityProgramTypes={studentActivityProgramTypes}
  />
);
