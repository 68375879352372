import React from "react";

import {
  EditableControlStates,
  InputProperties,
  DataUnavailableJSX,
  RequiredSign,
} from ".";

// interface EditableTextProps {
//   currentState: EditableControlStates;
//   currentValue: string;
//   handleChange: React.FormEventHandler<HTMLInputElement>;
//   required?: boolean;
//   inputProperties?: Array<InputProperties>;
//   nonEditableJSX?;
//   className?: string;
//   placeholder?: string;
// }
/**
 *
 * @param {Array<InputProperties>} inputProperties
 * @returns {string}
 */
const getType = (inputProperties) => {
  if (inputProperties.indexOf(InputProperties.IsEmail) > -1) {
    return "email";
  } else if (inputProperties.indexOf(InputProperties.IsPhone) > -1) {
    return "tel";
  } else if (inputProperties.indexOf(InputProperties.IsPassword) > -1) {
    return "password";
  } else if (inputProperties.indexOf(InputProperties.IsNumber) > -1) {
    return "number";
  } else if (inputProperties.indexOf(InputProperties.IsUrl) > -1) {
    return "url";
  } else {
    return "text";
  }
};

const EditableText = ({
  currentState,
  currentValue,
  handleChange,
  required,
  inputProperties = [],
  nonEditableJSX = <span>{currentValue || DataUnavailableJSX}</span>,
  className = "",
  placeholder,
}) =>
  currentState === EditableControlStates.nonEditable ? (
    currentValue ? (
      nonEditableJSX
    ) : (
      DataUnavailableJSX
    )
  ) : (
    <span>
      <input
        type={getType(inputProperties)}
        required={required || false}
        value={currentValue || ""}
        onChange={handleChange}
        style={{ marginBottom: "2px" }}
        className={className}
        placeholder={placeholder}
      />
      {required && RequiredSign}
    </span>
  );

export default EditableText;
