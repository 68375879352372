import React from "react";

export default ({ enrollmentStatuses, status, statusDisplay }) => {
  let btnType = "";
  if (status === enrollmentStatuses.Active) {
    btnType = "active";
  } else if (status === enrollmentStatuses.Terminated) {
    btnType = "terminated";
  } else if (status) {
    btnType = "pending";
  }

  return (
    <div className={`life-insurance-tag ${btnType}-tag`}>
      <span className="flex flex-align-center">
        {btnType === "pending" && (
          <i className="material-icons large-text mr-8">info_outline</i>
        )}
        {statusDisplay}
      </span>
    </div>
  );
};
