export const TripTypes = {
  InternationalPegisha: "InternationalPegisha",
  RegionalPegisha: "RegionalPegisha",
  JewishU: "JewishU",
  LivingLinks: "LivingLinks",
};

export const TravelTripTypes = [TripTypes.JewishU, TripTypes.LivingLinks];

export const TripConfigApplicableFor = {
  Shluchim: "Shluchim",
  Students: "Students",
  Chaperones: "Chaperones",
};

export const TripAcceptancePolicyOptions = {
  YesAcceptance: "YesAcceptance",
  NoAcceptance: "NoAcceptance",
  ShluchimCanChoose: "ShluchimCanChoose",
};

export const TripRegistrationStatuses = {
  Pending: "Pending",
  Accepted: "Accepted",
  Rejected: "Rejected",
  Waitlisted: "Waitlisted",
  CheckedIn: "CheckedIn",
  Cancelled: "Cancelled",
};

export const TripRegistrationWorkflowSteps = {
  Applied: "Applied",
  RecommendationSubmitted: "RecommendationSubmitted",
  RecommendationApproved: "RecommendationApproved",
  Interviewed: "Interviewed",
  PreAccepted: "PreAccepted",
  PreRejected: "PreRejected",
  PreWaitlisted: "PreWaitlisted",
  Eligible: "Eligible",
  Processed: "Processed",
};

export const TripRegistrationWorkflowStepNumbers = {
  Applied: 1,
  RecommendationSubmitted: 2,
  RecommendationApproved: 3,
  Interviewed: 4,
  PreRejected: 5,
  PreAccepted: 5,
  PreWaitlisted: 5,
  Eligible: 5,
  Processed: 6,
};

export const TripRegistrationRefundTypes = {
  None: "None",
  TripFee: "TripFee",
  Full: "Full",
};

export const TripRegistrationSurveyStatuses = {
  AwaitingSurvey: "AwaitingSurvey",
  SubmittedPendingRefund: "SubmittedPendingRefund",
};
