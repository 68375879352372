import React from "react";
import { Link } from "react-router";
import moment from "moment";

export default class CoursesTableRow extends React.PureComponent {
  render() {
    const {
      course: {
        categoryDisplay,
        createdOn,
        isOpen,
        id,
        name,
        programScheduleID,
      },
    } = this.props;

    return (
      <Link
        className="courses-table-row"
        to={`/edu/${programScheduleID}/courses/${id}`}
      >
        <p dir="auto">{name}</p>
        <p>{categoryDisplay}</p>
        <p>{moment(createdOn).format("MM/DD/YYYY")}</p>
        <p className={`relative ${isOpen ? "active" : "inactive"}`}>
          {isOpen ? "Active" : "Inactive"}
        </p>
      </Link>
    );
  }
}
