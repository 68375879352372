import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import RewardRequestsTableActions from "./RewardRequestsTableActions";
import RewardRequestsTableFilters from "./RewardRequestsTableFilters";
import RewardRequestsTableHeader from "./RewardRequestsTableHeader";
import RewardRequestsTableRow from "./RewardRequestsTableRow";
import ConfirmationModal from "../../shared/ConfirmationModal";

import EduApi from "../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class RewardRequestsTable extends React.PureComponent {
  state = {
    approveRejectRewardRequest: {
      action: null,
      errorMessage: "",
      loading: false,
      rewardRequest: null,
    },
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    rewardRequests: [],
    results: 12,
    sortBy: "createdOn",
    success: true,
    totalRewardRequests: 0,
    showApproveRejectRewardRequestConfirmationModal: false,
  };

  actionTypes = {
    Approve: "approve",
    Reject: "reject",
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterRewardRequests = (filters) => {
    this.setState({ filters }, () => {
      this.getRewardRequests();
    });
  };

  getRewardRequests = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        EduApi.getEduProgramRewardRequests(
          this.apiSignal.token,
          this.props.eduProgram.id,
          page,
          results,
          sortBy,
          this.state.filters
        )
          .then(({ rewardRequests, totalRewardRequests }) => {
            this.setState({
              loading: false,
              rewardRequests,
              totalRewardRequests,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                rewardRequests: [],
                success: false,
                totalRewardRequests: 0,
              });
            }
          });
      }
    );
  };

  getRequestsForExport = async () => {
    const { sortBy, filters } = this.state;

    const requestsForExport = await EduApi.getEduProgramRewardRequests(
      this.apiSignal.token,
      this.props.eduProgram.id,
      undefined,
      undefined,
      sortBy,
      filters,
      true
    );

    return requestsForExport.rewardRequests;
  };

  toggleApproveRejectRewardRequest = (rewardRequest, approve) => {
    this.setState({
      approveRejectRewardRequest: {
        action:
          approve === true
            ? this.actionTypes.Approve
            : approve === false
            ? this.actionTypes.Reject
            : null,
        errorMessage: "",
        loading: false,
        rewardRequest: rewardRequest || null,
      },
      showApproveRejectRewardRequestConfirmationModal:
        !this.state.showApproveRejectRewardRequestConfirmationModal,
    });
  };

  approveRejectRewardRequest = async () => {
    const { approveRejectRewardRequest } = this.state;

    this.setState({
      approveRejectRewardRequest: {
        ...approveRejectRewardRequest,
        errorMessage: "",
        loading: true,
      },
    });

    try {
      const { rewardRequest } = approveRejectRewardRequest;
      const updatedRewardRequest = {
        id: rewardRequest.id,
        eduRewardID: rewardRequest.eduRewardID,
        eduStudentEnrollmentID: rewardRequest.eduStudentEnrollmentID,
        //  BE only updates status field, no need to set other irrelevant fields for post
        status:
          approveRejectRewardRequest.action === this.actionTypes.Approve
            ? "Approved"
            : "Rejected",
      };

      await EduApi.submitEduStudentRewardRequest(
        this.apiSignal.token,
        rewardRequest.eduProgramID,
        updatedRewardRequest
      );
    } catch (err) {
      this.setState({
        approveRejectRewardRequest: {
          ...approveRejectRewardRequest,
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        },
      });
      return;
    }

    this.getRewardRequests(this.state.page, this.state.results);
    this.toggleApproveRejectRewardRequest();
  };

  render() {
    const {
      eduChabadHouses,
      eduProgram,
      mobileMode,
      systemLists: { eduRewardRequestStatuses = [], eduRewardTypes = [] } = {},
      viewOnly,
    } = this.props;

    const {
      approveRejectRewardRequest = {},
      loading,
      page,
      rewardRequests,
      showApproveRejectRewardRequestConfirmationModal,
      results,
      sortBy,
      success,
      totalRewardRequests,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center mb-8">
          <p className="xxl-text fw-500">Reward Requests</p>
          <RewardRequestsTableActions
            eduProgram={eduProgram}
            getRequestsForExport={this.getRequestsForExport}
          />
        </div>
        <div className="courses-table">
          <PaginatedTable
            filterComponent={
              <RewardRequestsTableFilters
                applyFilters={this.filterRewardRequests}
                applySort={(sortVal) =>
                  this.getRewardRequests(1, results, sortVal)
                }
                eduChabadHouses={eduChabadHouses}
                eduRewardRequestStatuses={eduRewardRequestStatuses}
                eduRewardTypes={eduRewardTypes}
                sortBy={sortBy}
                sortOptions={[
                  { id: "createdOn", name: "Request Rec'vd" },
                  { id: "studentName", name: "Student Name" },
                ]}
              />
            }
            loading={loading}
            loadData={this.getRewardRequests}
            mobileMode={mobileMode}
            page={page}
            records={rewardRequests}
            renderHeader={() => <RewardRequestsTableHeader />}
            renderRow={(request, index) => (
              <RewardRequestsTableRow
                eduProgramScheduleId={eduProgram.programScheduleID}
                key={index}
                request={request}
                onApproveRejectRewardRequest={
                  this.toggleApproveRejectRewardRequest
                }
                viewOnly={viewOnly}
              />
            )}
            results={results}
            showResultsCount={true}
            success={success}
            totalCount={totalRewardRequests}
          />
        </div>

        <ConfirmationModal
          cancel={this.toggleApproveRejectRewardRequest}
          confirm={this.approveRejectRewardRequest}
          errorMessage={approveRejectRewardRequest.errorMessage}
          loading={approveRejectRewardRequest.loading}
          message={<>
            Are you sure you want to {
              approveRejectRewardRequest.action
            } this request{
              approveRejectRewardRequest.rewardRequest &&
              approveRejectRewardRequest.rewardRequest.rewardName
                ? ` for ${approveRejectRewardRequest.rewardRequest.rewardName} reward`
                : ""
            }?
            <div style={{ fontSize: "14px", marginTop: "16px" }}>
              {approveRejectRewardRequest.action === this.actionTypes.Approve
                ? "Please note that students are notified of reward approvals."
                : "Please note that students are not notified of reward rejections."}
            </div>
          </>}
          show={showApproveRejectRewardRequestConfirmationModal}
        />
      </div>
    );
  }
}
