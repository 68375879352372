import React from "react";
import Select from "../../../shared/CustomSelect";
import ValidatedInput from "../../../shared/ValidatedInput";

export default class AddressInfo extends React.PureComponent {
  render() {
    const {
      countries,
      getProfileFieldLabel,
      isProfileFieldRequired,
      onChangeProfile,
      onChangeProfileEvt,
      profile: {
        address: {
          address1 = "",
          address2 = "",
          city = "",
          country = "",
          state = "",
          zip = "",
        } = {},
      } = { address: {} },
      showFormValidation,
    } = this.props;

    return (
      <div className="student-profile-form-section">
        <p className="profile-section-title">Home / permanent address</p>
        <div className="student-profile-form-grid">
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Street Address", "address.address1")}
            name="address.address1"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("address.address1")}
            showRequired={showFormValidation}
            value={address1}
          />
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("Street Address 2", "address.address2")}
            name="address.address2"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("address.address2")}
            showRequired={showFormValidation}
            value={address2}
          />
          <div className="relative">
            <ValidatedInput
              className="input-container"
              input={
                <Select
                  classNamePrefix="input-container-select"
                  options={
                    countries &&
                    countries.map((c) => ({
                      label: c.name,
                      value: c.name,
                    }))
                  }
                  placeholder="Select Country"
                  searchable={true}
                />
              }
              label={getProfileFieldLabel("Country", "address.country")}
              name="address.country"
              onChange={onChangeProfile}
              required={isProfileFieldRequired("address.country")}
              showRequired={showFormValidation}
              value={country}
            />
          </div>
          <ValidatedInput
            className="input-container"
            label={getProfileFieldLabel("City", "address.city")}
            name="address.city"
            onChange={onChangeProfileEvt}
            required={isProfileFieldRequired("address.city")}
            showRequired={showFormValidation}
            value={city}
          />
          {country === "USA" || country === "Canada" || country === "Mexico" ? (
            <div className="relative">
              <ValidatedInput
                className="input-container"
                input={
                  <Select
                    classNamePrefix="input-container-select"
                    options={
                      country &&
                      (
                        (countries.find((c) => c.name === country) || {})
                          .states || []
                      ).map((s) => ({
                        label: s.name,
                        value: s.name,
                      }))
                    }
                    placeholder="Select State"
                    searchable={true}
                  />
                }
                label={getProfileFieldLabel("State", "address.state")}
                name="address.state"
                onChange={onChangeProfile}
                required={isProfileFieldRequired("address.state")}
                showRequired={showFormValidation}
                value={state}
              />
            </div>
          ) : (
            <ValidatedInput
              className="input-container"
              name="address.state"
              onChange={onChangeProfileEvt}
              label={getProfileFieldLabel("State", "address.state")}
              required={isProfileFieldRequired("address.state")}
              showRequired={showFormValidation}
              value={state}
            />
          )}
          <ValidatedInput
            className="input-container"
            name="address.zip"
            onChange={onChangeProfileEvt}
            label={getProfileFieldLabel("Zip", "address.zip")}
            required={isProfileFieldRequired("address.zip")}
            showRequired={showFormValidation}
            value={zip}
          />
        </div>
      </div>
    );
  }
}
