import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../shared/ConfirmationModal";
import Dropzone from "react-dropzone";
import FadeOutErrorMessage from "../shared/FadeOutErrorMessage";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";

import EduApi from "../../services/resources/EduApi";
import ContentApi from "../../services/resources/ContentApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { notify } from "react-notify-toast";
import { getFormattedValuesForForm, removeEmptyFromObj } from "../../lib";
import { EduProgramTypes } from "./EduConsts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

const { REACT_APP_HOST_ENV = "development" } = process.env;

export default class EduProgramSettingsPage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    eduProgram: null,
    initialEduProgram: null,
    eduProgramCourse: null,
    initialEduProgramCourse: null,

    errorMessage: "",
    loading: true,

    isRequestedPageLeave: false,
    showCancelChangesConfirmationModal: false,
    showSubmitConfirmationModal: false,
    submitAttempted: false,
    submitEduProgramErrorMessage: "",
    submitEduProgramLoading: false,
  };

  apiSignal = axios.CancelToken.source();
  incompleteSubmissionErrorMessage = "Please complete required fields";

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.EduFullView)) {
      this.setState({ authorized: false });
    } else if (!AuthService.UserHasClaim(PermissionClaims.EduFullEdit)) {
      this.setState({ viewOnly: true });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getEduProgramDetails();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { eduProgram, initialEduProgram, isRequestedPageLeave } = this.state;

    if (!isRequestedPageLeave && !_isEqual(eduProgram, initialEduProgram)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getEduProgramDetails = async () => {
    const {
      params: { scheduleId },
    } = this.props;

    this.setState({
      loading: true,
    });

    try {
      let eduProgram = await EduApi.getEduProgram(
        this.apiSignal.token,
        scheduleId,
      );
      eduProgram = getFormattedValuesForForm(eduProgram);
      const newState = {
        eduProgram,
        initialEduProgram: _cloneDeep(eduProgram),
        loading: false,
      };

      if (eduProgram.type === EduProgramTypes.YourIsrael) {
        const eduProgramCourses = await EduApi.getEduProgramCourses(
          this.apiSignal.token,
          eduProgram.id,
          1,
          1,
        );
        const eduProgramCourseId =
          eduProgramCourses &&
          eduProgramCourses.courses &&
          eduProgramCourses.courses[0] &&
          eduProgramCourses.courses[0].id;

        if (eduProgramCourseId) {
          let eduProgramCourse = await EduApi.getEduProgramCourse(
            this.apiSignal.token,
            eduProgram.id,
            eduProgramCourseId,
          );
          eduProgramCourse = getFormattedValuesForForm(eduProgramCourse);
          newState.eduProgramCourse = eduProgramCourse;
          newState.initialEduProgramCourse = _cloneDeep(eduProgramCourse);
        }
      }

      this.setState(newState);
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and settings could not be retrieved. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  onChangeEduProgram = (name, value, otherUpdates) => {
    let eduProgram = _cloneDeep(this.state.eduProgram);
    _set(eduProgram, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(eduProgram, update, otherUpdates[update]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ eduProgram }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { submitEduProgramErrorMessage } = this.state;
        if (
          submitEduProgramErrorMessage &&
          submitEduProgramErrorMessage === this.incompleteSubmissionErrorMessage
        ) {
          const isValid = this.validateEduProgram();
          if (isValid) {
            this.setState({
              submitEduProgramErrorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeEduProgramEvt = ({ target: { name, value } }) => {
    return this.onChangeEduProgram(name, value);
  };

  onChangeEduProgramCourseEvt = ({ target: { name, value } }) => {
    let eduProgramCourse = _cloneDeep(this.state.eduProgramCourse);
    _set(eduProgramCourse, name, value);
    return new Promise((resolve, reject) => {
      this.setState({ eduProgramCourse }, () => {
        resolve();
      });
    });
  };

  onUploadEduProgramImage = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const fileURL = await ContentApi.uploadFile(
        acceptedFiles[0],
        "edu_program_banner",
      );

      this.onChangeEduProgram("imageURL", fileURL);
    }
  };

  onCancelEduProgramChanges = () => {
    const { eduProgram, initialEduProgram } = this.state;
    if (_isEqual(eduProgram, initialEduProgram)) {
      browserHistory.push(`/edu/${eduProgram.programScheduleID}`);
      return;
    }

    this.setState({ showCancelChangesConfirmationModal: true });
  };

  cancelEduProgramChanges = () => {
    this.setState({ isRequestedPageLeave: true }, () =>
      browserHistory.push(`/edu/${this.state.eduProgram.programScheduleID}`),
    );
  };

  onSubmitEduProgramSettings = () => {
    this.setState({
      submitAttempted: true,
      submitEduProgramErrorMessage: "",
    });

    const isValid = this.validateEduProgram();
    if (!isValid) {
      this.setState({
        submitEduProgramErrorMessage: this.incompleteSubmissionErrorMessage,
      });
      return;
    }

    this.setState({
      showSubmitConfirmationModal: true,
    });
  };

  submitEduProgramSettings = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitEduProgramErrorMessage: "",
      submitEduProgramLoading: true,
    });

    try {
      const eduProgramForSubmission = _cloneDeep(this.state.eduProgram);
      removeEmptyFromObj(eduProgramForSubmission);
      const savedProgram = await EduApi.submitEduProgram(
        this.apiSignal.token,
        eduProgramForSubmission,
      );

      const eduProgram = getFormattedValuesForForm(savedProgram);
      this.setState(
        {
          eduProgram: _cloneDeep(eduProgram),
          initialEduProgram: eduProgram,
          submitEduProgramLoading: false,
          submitAttempted: false,
        },
        () => {
          notify.show("Your settings have been saved", "success");
        },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitEduProgramErrorMessage: ApiCallErrorMessageHandler(err),
          submitEduProgramLoading: false,
        });
      }
    }
  };

  submitEduProgramCourseSettings = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitEduProgramErrorMessage: "",
      submitEduProgramLoading: true,
    });

    try {
      const eduProgramCourseForSubmission = _cloneDeep(
        this.state.eduProgramCourse,
      );
      removeEmptyFromObj(eduProgramCourseForSubmission);
      const savedProgramCourse = await EduApi.submitEduProgramCourse(
        this.apiSignal.token,
        this.state.eduProgram.id,
        eduProgramCourseForSubmission,
      );

      const eduProgramCourse = getFormattedValuesForForm(savedProgramCourse);
      this.setState(
        {
          eduProgramCourse: _cloneDeep(eduProgramCourse),
          initialEduProgramCourse: eduProgramCourse,
          submitEduProgramLoading: false,
          submitAttempted: false,
        },
        () => {
          notify.show("Your settings have been saved", "success");
        },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitEduProgramErrorMessage: ApiCallErrorMessageHandler(err),
          submitEduProgramLoading: false,
        });
      }
    }
  };

  validateEduProgram = () => {
    const { eduProgram, eduProgramCourse } = this.state;

    if (
      eduProgram.type === EduProgramTypes.YourIsrael &&
      !eduProgramCourse.studentSurveyURL
    ) {
      return false;
    }

    return true;
  };

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      eduProgram,
      eduProgramCourse,
      errorMessage,
      initialEduProgram,
      initialEduProgramCourse,
      loading,
      showCancelChangesConfirmationModal,
      showSubmitConfirmationModal,
      submitAttempted,
      submitEduProgramErrorMessage,
      submitEduProgramLoading,
      viewOnly,
    } = this.state;

    const {
      imageURL,
      message,
      name,
      orderBooksURL,
      surveyDeadlineNumberOfDays,
    } = eduProgram || {};
    const { resourceURL, studentSurveyURL } = eduProgramCourse || {};

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="course-form-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/edu/${sched.scheduleID}`,
              title: `Manage ${sched.scheduleName || sched.programName}`,
            },
          ]}
          pageTitle="Settings"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !eduProgram ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <div className="card full-width">
            <p className="flex flex-align-center mb-16 xxl-text">
              <i
                className="material-icons link-text mr-16"
                onClick={() => browserHistory.goBack()}
              >
                arrow_back
              </i>
              {name} Settings
            </p>
            {eduProgramCourse ? (
              <div className="course-form">
                <div className="course-form-section">
                  <p className="fw-700 mt-16 mb-16">Course Settings</p>
                  <div className="mb-24 flex flex-align-center">
                    <label
                      className="accent-text-dark"
                      style={{ width: "200px" }}
                    >
                      Resource Library URL
                    </label>
                    <input
                      className="custom-input full-width"
                      disabled={viewOnly}
                      name="resourceURL"
                      onChange={this.onChangeEduProgramCourseEvt}
                      placeholder="Enter the resource library url"
                      value={resourceURL}
                    />
                  </div>
                  <div className="mb-24 flex flex-align-center">
                    <label
                      className="flex flex-align-center accent-text-dark"
                      style={{ width: "200px" }}
                    >
                      Course Review URL
                      {REACT_APP_HOST_ENV !== "production" && (
                        <div className="tooltip-container">
                          <i className="material-icons large-text ml-8">info</i>
                          <span className="tooltip">
                            Enter only Test Survey URLs in this test
                            environment; survey URLs that are in use on
                            production may not be used here.
                            <br />
                            Note that in test environments survey responses are
                            refreshed once daily.
                          </span>
                        </div>
                      )}
                    </label>
                    <input
                      className={`custom-input full-width${
                        submitAttempted && !studentSurveyURL ? " error" : ""
                      }`}
                      disabled={viewOnly}
                      name="studentSurveyURL"
                      onChange={this.onChangeEduProgramCourseEvt}
                      placeholder="Enter the student course review url"
                      value={studentSurveyURL}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="course-form">
                <div className="course-form-section">
                  <p className="course-form-section-title">
                    Default Image and Message for Shluchim
                  </p>
                  <div className="mb-24">
                    <Dropzone
                      accept={"image/png,image/jpeg,image/bmp,image/jpg"}
                      disabled={viewOnly}
                      onDrop={this.onUploadEduProgramImage}
                      title="Drag an image here or click to find one on your computer."
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <span
                            className={`link-text uppercase-text${
                              viewOnly ? " disabled" : ""
                            }`}
                          >
                            <img
                              alt="banner"
                              height={64}
                              width={64}
                              src={imageURL || "/img/placeholder.svg"}
                              style={{
                                marginRight: 32,
                                background: "#edecec",
                                borderRadius: "3px",
                                objectFit: "cover",
                              }}
                            />
                            {imageURL ? "Change" : "Upload"} Default Banner
                            Image
                          </span>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  <div className="mb-24">
                    <div className="flex flex-align-center mb-8">
                      <label className="accent-text-dark">
                        Default Message
                      </label>
                      <div className="tooltip-container">
                        <i className="material-icons large-text ml-8">info</i>
                        <span className="tooltip">
                          This will be displayed on the student and public
                          websites.
                        </span>
                      </div>
                    </div>
                    <textarea
                      className="custom-input full-width"
                      disabled={viewOnly}
                      name="message"
                      onChange={this.onChangeEduProgramEvt}
                      style={{ resize: "none", height: "72px" }}
                      value={message}
                    />
                  </div>
                  <div className="mb-24 flex flex-align-center">
                    <label className="accent-text-dark">Order Books URL</label>
                    <input
                      className="custom-input full-width"
                      disabled={viewOnly}
                      name="orderBooksURL"
                      onChange={this.onChangeEduProgramEvt}
                      value={orderBooksURL}
                    />
                  </div>
                  <label className="accent-text-dark"> Survey</label>

                  <div className="mb-24 flex flex-align-center">
                    <label className="accent-text-dark">Survey Deadline</label>
                    <input
                      className="ml-16 mr-16 custom-input"
                      disabled={viewOnly}
                      type="number"
                      name="surveyDeadlineNumberOfDays"
                      value={surveyDeadlineNumberOfDays}
                      onChange={this.onChangeEduProgramEvt}
                      maxLength={4}
                      min="0"
                    />{" "}
                    days after last class
                  </div>
                </div>
              </div>
            )}
            {!viewOnly && (
              <div className="course-form-btns flex flex-justify-space mt-16">
                <button
                  className="custom-btn btn-light uppercase-text"
                  disabled={submitEduProgramLoading}
                  onClick={this.onCancelEduProgramChanges}
                >
                  Cancel
                </button>
                {submitEduProgramLoading ? (
                  <div className="medium-loader">
                    <Loader />
                  </div>
                ) : (
                  <React.Fragment>
                    <button
                      className="custom-btn btn-accent uppercase-text"
                      disabled={
                        _isEqual(eduProgram, initialEduProgram) &&
                        _isEqual(eduProgramCourse, initialEduProgramCourse)
                      }
                      onClick={this.onSubmitEduProgramSettings}
                    >
                      Save
                    </button>
                    {!!submitEduProgramErrorMessage && (
                      <FadeOutErrorMessage
                        message={submitEduProgramErrorMessage}
                        onTimeout={() =>
                          this.setState({
                            submitEduProgramErrorMessage: "",
                          })
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showCancelChangesConfirmationModal: false,
                  showSubmitConfirmationModal: false,
                })
              }
              cancelText={showCancelChangesConfirmationModal && "No"}
              confirm={
                showCancelChangesConfirmationModal
                  ? this.cancelEduProgramChanges
                  : eduProgramCourse
                  ? this.submitEduProgramCourseSettings
                  : this.submitEduProgramSettings
              }
              confirmText={showCancelChangesConfirmationModal && "Yes"}
              message={`Are you sure you want to ${
                showCancelChangesConfirmationModal
                  ? `cancel your changes to ${eduProgram.name} settings?`
                  : `save changes to ${eduProgram.name} settings?`
              }
                  `}
              show={
                showCancelChangesConfirmationModal ||
                showSubmitConfirmationModal
              }
            />
          </div>
        )}
      </div>
    );
  }
}
