import AuthClient from "./AuthClient";
import queryString from "query-string";

const baseUrl = "programs";

const ProgramsApi = {
  /** @param {ProgramInfo} data */
  create(data) {
    return AuthClient.post(`${baseUrl}`, data);
  },
  /** @param {ProgramInfo} data */
  update(data) {
    return AuthClient.post(`${baseUrl}/${data.id}`, data);
  },
  /** @param {number} id */
  delete(id) {
    return AuthClient.delete(`${baseUrl}/${id}`);
  },
  /** @returns {{activeCount:number, inactiveCount:number, items:object[]}} */
  async list() {
    const {
      data: { payload: items = [] },
    } = await AuthClient.get(`${baseUrl}?onlyActiveAndPublished=false`);
    let activeCount = 0;
    items.forEach((program) => {
      normalizeProgramListing(program);
      if (program.isActive) {
        activeCount++;
      }
    });
    const inactiveCount = items.length - activeCount;
    return {
      activeCount,
      inactiveCount,
      items,
    };
  },
  async getProgram(id) {
    const {
      data: { payload: program },
    } = await AuthClient.get(`${baseUrl}/${id}?onlyActiveAndPublished=false`);

    normalizeProgram(program);
    return program;
  },
  async getProgramAvailShluchim(id) {
    const {
      data: { payload: shluchim },
    } = await AuthClient.get(`${baseUrl}/${id}/availableShluchim`);

    return shluchim;
  },
  async getProgramParticipants(id) {
    const {
      data: { payload: participants },
    } = await AuthClient.get(`${baseUrl}/${id}/participants`);

    return participants;
  },
  async saveProgramParticipants(id, participantsToSave) {
    const {
      data: { payload: participants },
    } = await AuthClient.post(
      `${baseUrl}/${id}/participants`,
      participantsToSave
    );

    return participants;
  },
  async getSchedule(programId, scheduleId) {
    const {
      data: { payload: schedule },
    } = await AuthClient.get(`${baseUrl}/${programId}/schedules/${scheduleId}`);

    normalizeSchedule(schedule);
    return schedule;
  },
  async saveProgram(programToSave) {
    const {
      data: { payload: program },
    } = await AuthClient.post(`${baseUrl}`, programToSave);

    normalizeProgram(program);
    return program;
  },
  async getBasicProgramSchedule(cancelToken, scheduleId) {
    const {
      data: { payload: schedule },
    } = await AuthClient.get(`${baseUrl}/schedules/${scheduleId}/basic`, {
      cancelToken,
    });

    return schedule;
  },
  async getBasicProgramSchedules(cancelToken, programId) {
    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/basic`,
      {
        cancelToken,
      }
    );
    return response.data.payload || [];
  },
  async getScheduleApplicationSettings(
    cancelToken,
    programId,
    scheduleId,
    returnBlank = false
  ) {
    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/${scheduleId}/applicationSettings?returnBlank=${returnBlank}`,
      {
        cancelToken,
      }
    );
    return response.data.payload || {};
  },
  async submitScheduleApplicationSettings(
    cancelToken,
    programId,
    scheduleId,
    settings
  ) {
    const response = await AuthClient.post(
      `${baseUrl}/${programId}/schedules/${scheduleId}/applicationSettings`,
      settings,
      {
        cancelToken,
      }
    );
    return response.data.payload || {};
  },
  async getScheduleUnenrolled(
    cancelToken,
    programId,
    scheduleId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) {
    const params = {
      isExport,
      page,
      results,
      sortByOption,
      text: filters?.keyword,
    };

    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/${scheduleId}/unenrolledShluchim?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return {
      registrations: response.data.payload.results || [],
      totalRegistrations: response.data.payload.numberOfRows || 0,
    };
  },
  async getScheduleRegistrations(
    cancelToken,
    programId,
    scheduleId,
    page,
    results,
    sortByOption,
    filters = {},
    registrationType,
    includeApplicationDetails,
    isExport
  ) {
    const params = {
      includeApplicationDetails,
      isExport,
      page,
      registrationType,
      results,
      sortByOption,
      text: filters?.keyword,
    };

    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/${scheduleId}/registrations?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return {
      registrations: response.data.payload.results || [],
      totalRegistrations: response.data.payload.numberOfRows || 0,
    };
  },
  async getScheduleRegistrationsShluchim(
    cancelToken,
    programId,
    scheduleId,
    page,
    results,
    sortByOption,
    filters = {},
    isExport
  ) {
    const params = {
      isExport,
      page,
      results,
      sortByOption,
      text: filters?.keyword,
    };

    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/${scheduleId}/registrations/shluchim?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return {
      shluchim: response.data.payload.results || [],
      totalShluchim: response.data.payload.numberOfRows || 0,
    };
  },
  async getScheduleJotformSubmissions(cancelToken, programId, scheduleId) {
    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/${scheduleId}/jotformSubmissions`,
      { cancelToken }
    );
    return response.data.payload || [];
  },
  async getScheduleRegistration(
    cancelToken,
    programId,
    scheduleId,
    registrationId
  ) {
    const response = await AuthClient.get(
      `${baseUrl}/${programId}/schedules/${scheduleId}/registrations/${registrationId}`,
      {
        cancelToken,
      }
    );
    return response.data.payload || {};
  },
  async submitScheduleRegistration(
    cancelToken,
    programId,
    scheduleId,
    registration,
    registerSpouse = false
  ) {
    const response = await AuthClient.post(
      `${baseUrl}/${programId}/schedules/${scheduleId}/registrations?registerSpouse=${registerSpouse}`,
      registration,
      {
        cancelToken,
      }
    );
    return response.data.payload || {};
  },
  async importScheduleRegistrations(
    cancelToken,
    programId,
    scheduleId,
    importFile,
    onUploadProgress
  ) {
    const formData = new FormData();
    formData.append("file", importFile);

    const response = await AuthClient.post(
      `${baseUrl}/${programId}/schedules/${scheduleId}/importRegistrations`,
      formData,
      {
        cancelToken,
        "Content-Type": "multipart/form-data",
        onUploadProgress,
      }
    );
    return response.data.payload || {};
  },
  async removeScheduleRegistration(
    cancelToken,
    programId,
    scheduleId,
    registrationId
  ) {
    return await AuthClient.delete(
      `${baseUrl}/${programId}/schedules/${scheduleId}/registrations/${registrationId}`,
      {
        cancelToken,
      }
    );
  },
  async submitScheduleRegistrationsGroupPayment(
    cancelToken,
    programId,
    scheduleId,
    payment
  ) {
    return await AuthClient.post(
      `${baseUrl}/${programId}/schedules/${scheduleId}/registrations/groupPayment`,
      payment,
      {
        cancelToken,
      }
    );
  },
};
export default ProgramsApi;

function isProgramActive(program) {
  return program.status === "Active";
}

function normalizeProgram(program) {
  let { audience, category } = program;

  program.isActive = isProgramActive(program);
  delete program.status;

  if (audience) {
    program.audienceId = audience.intValue;
    delete program.audience;
  }

  if (category) {
    program.categoryId = category.intValue;
    delete program.category;
  }

  if (program.schedule) {
    normalizeSchedule(program.schedule);
  }
}
function normalizeSchedule(schedule) {
  let { officeStaff } = schedule;

  if (officeStaff) {
    delete schedule.officeStaff;
  }
}

function normalizeProgramListing(program) {
  const {
    audience,
    category,
    isPublished,
    latestScheduleID,
    // The list command used to return `id` now it returns `programID`...
    programID,
  } = program;
  program.id = programID;
  program.isActive = isProgramActive(program);
  program.hasChanges = !!latestScheduleID && !isPublished;
  if (audience) {
    program.audienceId = audience.intValue;
    program.audienceName = audience.displayValue;
  }
  if (category) {
    program.categoryId = category.intValue;
    program.categoryName = category.displayValue;
  }
  delete program.audience;
  delete program.category;
  delete program.programID;
}
