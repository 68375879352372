import React from "react";
import ExportCSVButton from "../../shared/ExportCSVButton";
import moment from "moment";
import { formatCurrency } from "../../../lib";

export default class RewardRequestsTableActions extends React.PureComponent {
  render() {
    const { eduProgram, getRequestsForExport } = this.props;

    return (
      <div className="flex">
        <ExportCSVButton
          className="btn custom-btn btn-accent flex flex-align-center"
          fileName={`${eduProgram.name.replace(/ /g, "_")}_Reward_Requests`}
          getExportData={getRequestsForExport}
          headers={[
            { label: "Student ID", value: "studentID" },
            { label: "Student First Name", value: "studentFirstName" },
            { label: "Student Last Name", value: "studentLastName" },
            { label: "Student Email", value: "studentEmail" },
            { label: "Campus ID", value: "campusID" },
            { label: "Campus Name", value: "campusName" },
            { label: "Shliach ID", value: "shliachID" },
            { label: "Shliach First Name", value: "shliachFirstName" },
            { label: "Shliach Last Name", value: "shliachLastName" },
            { label: "Reward Name", value: "rewardName" },
            { label: "Reward Type", value: "rewardTypeDisplay" },
            { label: "Credits", value: "creditsToRedeem" },
            {
              label: "Total Value",
              value: ({ creditValue, creditsToRedeem }) =>
                creditValue * creditsToRedeem,
            },
            {
              label: "Shliach's Percentage",
              value: ({ creditValue, creditsToRedeem }) =>
                formatCurrency(creditValue * creditsToRedeem * 0.2),
            },
            { label: "Currency", value: "currencyCode" },
            {
              label: "Request Received",
              value: ({ createdOn }) =>
                createdOn ? moment(createdOn).format("MM/DD/YYYY") : null,
            },
            { label: "Status", value: "statusDisplay" },
          ]}
        />
      </div>
    );
  }
}
