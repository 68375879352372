import React from "react";

import ConfirmationModal from "../../../shared/ConfirmationModal";
import RafflesApi from "../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import { notify } from "react-notify-toast";

export default class RaffleOrderEmailModal extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onClose = () => {
    this.setState(
      {
        errorMessage: "",
        loading: false,
      },
      this.props.close,
    );
  };

  resendOrderEmail = () => {
    const {
      order: { orderNumber, purchaseID },
    } = this.props;

    this.setState(
      {
        errorMessage: "",
        loading: true,
      },
      () => {
        RafflesApi.resendOrderEmail(this.apiSignal.token, purchaseID)
          .then(() => {
            notify.show(`Email sent for order #${orderNumber}`, "success");
            this.onClose();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
              });
            }
          });
      },
    );
  };

  render() {
    const {
      order: { donorEmail, donorFirstName, donorLastName },
      show,
    } = this.props;
    const { errorMessage, loading } = this.state;

    return (
      <ConfirmationModal
        cancel={this.onClose}
        confirm={this.resendOrderEmail}
        confirmText="Send email"
        errorMessage={errorMessage}
        loading={loading}
        message={`Resend order email to ${donorFirstName} ${donorLastName} at ${donorEmail}?`}
        show={show}
      />
    );
  }
}
