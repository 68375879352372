import React from "react";
import { formatNumber, pluralizeText } from "../../../../lib";
import { Person, LocalActivity } from "@material-ui/icons";

export default function TicketMetricsCards(props) {
  const {
    offlineTicketsSold,
    onlineTicketsSold,
    ticketsSoldToday,
    totalTicketsSold,
    ordersCount,
    ordersToday,
  } = props;

  return (
    <div className="ticket-status-cards">
      <div className="ticket-status-card">
        <div>
          <div className="icon-wrapper person-icon">
            <Person htmlColor={"#63C674"} fontSize="large" />
          </div>
          <p className="large-text fw-700 relative mb-4">
            {formatNumber(ordersCount)} {pluralizeText("Donor", ordersCount)}
          </p>
          <p className="fs-14 fw-300">Total</p>
        </div>
        <div>
          <p className="accent-text small-text mb-0">
            {formatNumber(ordersToday)} {pluralizeText("donor", ordersToday)}{" "}
            today
          </p>
        </div>
      </div>

      <div className="ticket-status-card">
        <div>
          <div className="icon-wrapper ticket-icon">
            <LocalActivity htmlColor={"#F3893D"} fontSize="large" />
          </div>
          <p className="large-text fw-700 relative mb-4">
            {formatNumber(totalTicketsSold)}{" "}
            {pluralizeText("Ticket", totalTicketsSold)}
          </p>
          <p className="fs-14 fw-300">Total sold</p>
        </div>

        <div>
          <p className="accent-text small-text mb-4">
            {formatNumber(ticketsSoldToday)}{" "}
            {pluralizeText("ticket", ticketsSoldToday)} sold today
          </p>
          <p className="accent-text small-text mb-4">
            {formatNumber(onlineTicketsSold)}{" "}
            {pluralizeText("ticket", onlineTicketsSold)} sold online
          </p>
          <p className="accent-text small-text mb-0">
            {formatNumber(offlineTicketsSold)}{" "}
            {pluralizeText("ticket", offlineTicketsSold)} sold offline
          </p>
        </div>
      </div>
    </div>
  );
}
