import React from "react";
import { Link } from "react-router";

import { /* CustomEvent, */ EmptyCustomEvent } from "../Utils";
import CustomEventManager from "./CustomEventManager";

// interface CustomEventManagerProps {
//   eventData: CustomEvent;
//   handleEventDataChange: React.FormEventHandler<HTMLElement>;
//   handleSave: (ce: CustomEvent) => void;
//   handleDelete: (ce: CustomEvent) => void;
// }

const CustomEventManagerPage = ({
  eventData = EmptyCustomEvent(),
  handleEventDataChange,
  handleSave,
  handleDelete,
}) => {
  return (
    <div className="col-sm-12">
      <ol className="breadcrumb">
        <li>
          <Link to="/config">Configure</Link>
        </li>
        <li>
          <Link to="/config/customevents">Custom Events</Link>
        </li>
        <li>{eventData.name || "[new custom event]"}</li>
      </ol>
      <div className="well well-lg">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <CustomEventManager
              eventData={eventData}
              handleEventDataChange={handleEventDataChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9" />
          <div className="col-sm-3">
            <span
              className="btn btn-primary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                handleSave(eventData);
              }}
            >
              Save
            </span>{" "}
            {eventData.id > 0 && (
              <span
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(eventData);
                }}
              >
                Delete
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomEventManagerPage;
