import React from "react";

export default class LocationsTableHeader extends React.PureComponent {
  render() {
    const { grants } = this.props;

    const columns = [
      "Chabad House",
      "Primary Shliach \nShlucha",
      "Campus",
      grants.map((grant, index) => (
        <span className="lamplighters-locations-table-dynamic-cell" key={index}>
          {grant.name}
        </span>
      )),
      "",
    ];

    return (
      <div className="lamplighters-locations-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
