import { asEnum } from "../../lib";
import {
  EventCategoriesDescriptions,
  EventCategories,
} from "../../models/EventCategories";
import { ContactType } from "../Contact/ContactUtils";
// import { MenuOption } from "./MenuOptions";
// import { SummaryTemplateReportData } from "./ReportingTemplates/SummaryTemplate";
// import { DetailedTemplateContactsReportData } from "./ReportingTemplates/DetailedTemplateContacts";
// import { DetailedTemplateSchoolsReportData } from "./ReportingTemplates/DetailedTemplateSchools";

export const CustomEventTypes = asEnum({
  Date: 1001,
  Currency: 1002,
  String: 1003,
});

// export interface CustomEvent {
//     id: number;
//     contactType: ContactType;
//     name: string;
//     type: CustomEventTypes;
// }

/** @param {ContactType} contactType
 * @returns {CustomEvent}
 */
export function EmptyCustomEvent(contactType = ContactType.shluchim) {
  return {
    contactType: contactType,
    name: "",
    type: CustomEventTypes.Date,
    id: -1,
  };
}
/** @returns {SummaryTemplateReportData} */
export function EmptySummaryCustomReportData() {
  return {
    name: "...",
    type: CustomEventTypes.Date,
    contactType: ContactType.shluchim,
    participantCount: 0,
    schoolOrMosadCount: 0,
    ageArray: [],
    genderArray: [],
  };
}
/** @returns {DetailedTemplateContactsReportData} */
export function EmptyDetailedCustomReportData() {
  return {
    name: "...",
    type: CustomEventTypes.Date,
    contactType: ContactType.shluchim,
    contacts: [],
  };
}
/** @returns {DetailedTemplateSchoolsReportData} */
export function EmptyDetailedBySchoolCustomReportData() {
  return {
    name: "...",
    type: CustomEventTypes.Date,
    contactType: ContactType.shluchim,
    schools: [],
  };
}
/** @param {CustomEvent} ce
 * @returns {MenuOption}
 */
function customEventToMenuOption(ce) {
  const customEventEnumId = ce.type;
  return {
    [customEventEnumId]: {
      title: EventCategoriesDescriptions[customEventEnumId],
      order: 1000,
      eventTypes: { [ce.id]: ce.name },
    },
  };
}
/** @param {Array<MenuOption>} menuOptions
 * @param {EventCategories} category
 * @returns {MenuOption}
 */
function getCategoryItems(menuOptions, category) {
  const optionOfCategory = menuOptions.filter((mo) =>
    mo.hasOwnProperty(category),
  );
  let returnValue = {};
  if (optionOfCategory.length === 0) {
    return returnValue;
  }
  returnValue[category] = {
    order: category,
    title: EventCategoriesDescriptions[category],
    eventTypes: {
      ...menuOptions.filter((mo) => mo[category]).map((cat) => cat),
    },
  };
  returnValue[category].eventTypes = optionOfCategory.reduce(
    (prev, curr) => Object.assign({}, prev, curr[category].eventTypes),
    {},
  );
  return returnValue;
}
/** @param {Array<MenuOption>} menuOptions
 * @returns {MenuOption}
 */
function customEventsAsArrayOfOptions(menuOptions) {
  const returnOptions = {};
  Object.assign(
    returnOptions,
    getCategoryItems(menuOptions, EventCategories.DateCustomEvent),
  );
  Object.assign(
    returnOptions,
    getCategoryItems(menuOptions, EventCategories.StringCustomEvent),
  );
  Object.assign(
    returnOptions,
    getCategoryItems(menuOptions, EventCategories.CurrencyCustomEvent),
  );
  return returnOptions;
}
/** @param {Array<CustomEvent>} customEvents
 * @returns {MenuOption}
 */
export function GetCustomEventsAsMenuOptions(customEvents) {
  /** @type {Array<MenuOption>} */
  const customEventsMO = customEvents.map(customEventToMenuOption);
  return customEventsAsArrayOfOptions(customEventsMO);
}
