import React, { useState } from "react";
import Modal from "react-modal";
import RegistrationRejectionConfirmationDetails from "./RegistrationRejectionConfirmationDetails";
import { formatCurrency } from "../../../../../../../lib";
import { TripRegistrationStatuses } from "../../../../../TripEventConsts";
import { getRegistrationStatusColor } from "../../../../../TripEventLogic";

const statusActionText = {
  [TripRegistrationStatuses.Accepted]: "Accept",
  [TripRegistrationStatuses.Rejected]: "Reject",
  [TripRegistrationStatuses.Waitlisted]: "Waitlist",
};

function RegistrationStatusConfirmationModal(props) {
  const {
    cancel,
    confirm,
    errorMessage,
    loading,
    registrationDetails: { total, ...registrationDetails } = {},
    show,
    status,
  } = props;

  const [sendRejectionEmail, setSendRejectionEmail] = useState(true);

  const actionText = statusActionText[status] || status;

  return (
    <Modal isOpen={show} className="modal-container">
      <div className="card small-modal trip-modal">
        <div className="large-text mb-8">Confirm</div>
        <div className="accent-text-dark mb-8">
          Are you sure you want to <span className="fw-700">{actionText}</span>{" "}
          this student?
        </div>
        <div className="accent-text-dark">
          {status === TripRegistrationStatuses.Accepted ? (
            `The student will be notified via email${
              total > 0
                ? ` and their card will be charged $${formatCurrency(total)}`
                : ""
            }.`
          ) : status === TripRegistrationStatuses.Waitlisted ? (
            "The student will be notified via email."
          ) : status === TripRegistrationStatuses.Rejected ? (
            <RegistrationRejectionConfirmationDetails
              registrationDetails={registrationDetails}
              sendEmail={sendRejectionEmail}
              toggleSendEmail={() => setSendRejectionEmail(!sendRejectionEmail)}
            />
          ) : (
            ""
          )}
        </div>
        {registrationDetails.status === TripRegistrationStatuses.Rejected && (
          <div className="accent-text-dark fw-700 mt-16">
            <div className="flex">
              <i className="material-icons large-text trip-warning-icon mr-8">
                warning
              </i>{" "}
              This registration was previously rejected.
            </div>
            Confirm that you'd like to {actionText?.toLowerCase()} this student
            {registrationDetails.isTravelTrip
              ? " and that all preliminary steps and requirements have been met"
              : ""}
            .
          </div>
        )}
        <div className="modal-btns">
          <button
            className="link-text-secondary uppercase-text"
            disabled={loading}
            onClick={cancel}
          >
            Cancel
          </button>
          <button
            className={`link-text-secondary uppercase-text ml-24${
              loading ? " disabled" : ""
            }`}
            onClick={() => confirm(!sendRejectionEmail)}
            style={{
              color: getRegistrationStatusColor(
                status === TripRegistrationStatuses.Rejected
                  ? status
                  : TripRegistrationStatuses.Accepted
              ),
            }}
          >
            {loading ? `${actionText}ing...` : actionText}
          </button>
        </div>
        {!loading && errorMessage && (
          <div className="text-right error-text mt-4">{errorMessage}</div>
        )}
      </div>
    </Modal>
  );
}

export default React.memo(RegistrationStatusConfirmationModal);
