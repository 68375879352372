import React from "react";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import PaginatedTable from "../../../shared/PaginatedTable";
import AddLocationModal from "./AddLocationModal";
import ImportLocationsModal from "./ImportLocationsModal";
import LocationsTableActions from "./LocationsTableActions";
import LocationsTableHeader from "./LocationsTableHeader";
import LocationsTableRow from "./LocationsTableRow";
import TableFilters from "../../../shared/TableFilters";

import LamplightersApi from "../../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";

export default class LocationsTable extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    locations: [],
    page: 1,
    removeLocationErrorMessage: null,
    removeLocationLoading: false,
    results: 12,
    selectedLocation: null,
    showAddLocationModal: false,
    showImportLocationsModal: false,
    showRemoveLocationModal: false,
    sortBy: "shliachLastName",
    success: true,
    totalLocations: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterLocations = (filters) => {
    this.setState({ filters }, () => {
      this.getLocations();
    });
  };

  getLocations = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
    onRefresh,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: onRefresh ? false : true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        LamplightersApi.getLocations(
          this.apiSignal.token,
          this.props.schedule.id,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ locations, totalLocations }) => {
            this.setState({
              loading: false,
              locations,
              totalLocations,
            });
            if (onRefresh) {
              onRefresh();
            }
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                locations: [],
                success: false,
                totalLocations: 0,
              });
            }
          });
      },
    );
  };

  getLocationsForExport = async () => {
    const { sortBy, filters } = this.state;

    const locationsForExport = await LamplightersApi.getLocations(
      this.apiSignal.token,
      this.props.schedule.id,
      undefined,
      undefined,
      sortBy,
      filters,
      undefined,
      true,
    );

    return locationsForExport.locations;
  };

  baseSortOptions = [
    { id: "shliachLastName", name: "Shliach Name" },
    { id: "chabadHouseName", name: "Chabad House Name" },
    { id: "campusName", name: "Campus Name" },
  ];

  getSortOptions = (grants) => {
    const sortOptions = [...this.baseSortOptions];

    const grantSortOptions = [
      {
        getSortName: (grantName) => `${grantName} - Ascending`,
        sortValue: "allocatedGrantsAsc",
      },
      {
        getSortName: (grantName) => `${grantName} - Descending`,
        sortValue: "allocatedGrantsDesc",
      },
    ];

    grants.forEach((grant) => {
      sortOptions.push(
        ...grantSortOptions.map((st) => ({
          id: st.sortValue + "-" + grant.id,
          name: st.getSortName(grant.name),
          grantId: grant.id,
        })),
      );
    });

    return sortOptions;
  };

  addLocation = async (chabadHouseId) => {
    const submitLocationResponse = {};

    try {
      await LamplightersApi.submitLocation(this.apiSignal.token, {
        grantScheduleID: this.props.schedule.id,
        chabadHouseID: chabadHouseId,
      });
      submitLocationResponse.success = true;

      //reload locations
      this.getLocations();
      //refresh stats
      this.props.refreshAllocations();
    } catch (err) {
      if (!axios.isCancel(err)) {
        submitLocationResponse.error = err;
        submitLocationResponse.errorMessage = ApiCallErrorMessageHandler(err);
      }
    }

    return submitLocationResponse;
  };

  removeLocation = async () => {
    this.setState({
      removeLocationErrorMessage: "",
      removeLocationLoading: true,
    });

    try {
      //deactivate location
      await LamplightersApi.removeLocation(
        this.apiSignal.token,
        this.state.selectedLocation.id,
      );

      //reload locations:
      const { page, results, sortBy } = this.state;
      this.getLocations(page, results, sortBy, () => {
        this.setState({
          removeLocationLoading: false,
          selectedLocation: null,
          showRemoveLocationModal: false,
        });
      });
      //refresh stats
      this.props.refreshAllocations();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          removeLocationErrorMessage: ApiCallErrorMessageHandler(err),
          removeLocationLoading: false,
        });
      }
    }
  };

  render() {
    const {
      allocations,
      chabadHouses,
      mobileMode,
      readOnly,
      refreshAllocations,
      setHasUnsavedAllocationsChanges,
      schedule: {
        didScheduleStart,
        grants,
        id: scheduleId,
        paymentSchedules,
        programScheduleName,
      },
    } = this.props;

    const {
      filters,
      loading,
      locations,
      page,
      removeLocationErrorMessage,
      removeLocationLoading,
      results,
      selectedLocation,
      showAddLocationModal,
      showImportLocationsModal,
      showRemoveLocationModal,
      sortBy,
      success,
      totalLocations,
    } = this.state;

    const hasFinalPaymentSchedule = paymentSchedules.some(
      (ps) => ps.useRemainingBalance,
    );

    const sortOptions = this.getSortOptions(grants);

    return (
      <div>
        <p className="xxl-text fw-500">Locations</p>
        <div className="flex flex-align-center flex-justify-space">
          <div className="flex flex-align-center flex-justify-space mr-20">
            <TableFilters
              applyFilters={this.filterLocations}
              applySort={(so) => this.getLocations(1, results, so)}
              filters={filters}
              sortBy={sortBy}
              sortOptions={sortOptions}
            />
          </div>
          <LocationsTableActions
            canAddLocation={didScheduleStart && !hasFinalPaymentSchedule}
            getLocationsForExport={this.getLocationsForExport}
            grants={grants}
            onAddLocation={() => this.setState({ showAddLocationModal: true })}
            onImportLocations={() =>
              this.setState({ showImportLocationsModal: true })
            }
            programScheduleName={programScheduleName}
            readOnly={readOnly}
          />
        </div>
        <div className="lamplighters-locations-table">
          <PaginatedTable
            loading={loading}
            loadData={this.getLocations}
            mobileMode={mobileMode}
            page={page}
            records={locations}
            renderHeader={() => <LocationsTableHeader grants={grants} />}
            renderRow={(location) => (
              <LocationsTableRow
                allocationsByGrant={allocations}
                canUpdateAllocations={!hasFinalPaymentSchedule}
                grants={grants}
                key={location.id}
                location={location}
                onRemoveLocation={() =>
                  this.setState({
                    selectedLocation: location,
                    showRemoveLocationModal: true,
                  })
                }
                readOnly={readOnly}
                refreshAllocations={refreshAllocations}
                setHasUnsavedAllocationsChanges={
                  setHasUnsavedAllocationsChanges
                }
                submitAllocations={async (allocations) =>
                  await LamplightersApi.submitLocation(this.apiSignal.token, {
                    allocations,
                    chabadHouseID: location.chabadHouseID,
                    grantScheduleID: location.grantScheduleID,
                    id: location.id,
                  })
                }
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            success={success}
            totalCount={totalLocations}
          />
        </div>

        <AddLocationModal
          chabadHouses={chabadHouses}
          close={() => this.setState({ showAddLocationModal: false })}
          show={showAddLocationModal}
          submit={this.addLocation}
        />
        <ConfirmationModal
          cancel={() =>
            this.setState({
              removeLocationErrorMessage: "",
              selectedLocation: null,
              showRemoveLocationModal: false,
            })
          }
          confirm={this.removeLocation}
          errorMessage={removeLocationErrorMessage}
          loading={removeLocationLoading}
          message={`Are you sure you'd like to remove ${
            selectedLocation
              ? selectedLocation.chabadHouseName
              : "this location"
          } from ${programScheduleName || "Lamplighters"}?`}
          show={showRemoveLocationModal}
        />
        <ImportLocationsModal
          chabadHouses={chabadHouses}
          close={() => this.setState({ showImportLocationsModal: false })}
          grants={grants}
          reloadLocations={() => {
            //reload locations
            this.getLocations();
            //refresh stats
            refreshAllocations();
          }}
          scheduleId={scheduleId}
          show={showImportLocationsModal}
        />
      </div>
    );
  }
}
