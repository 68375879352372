import React from "react";
import Select from "react-select";

import { ModalWithButtons /* , ModalButton */ } from "..";
// import { ReactSelectOption } from "../Models";

// export interface ModalListSelectProps<T> {
//   headerText: string;
//   options: Array<ReactSelectOption>;
//   handleOk: (selectedValues: T) => void;
//   handleCancel: () => void;
// }
// export interface ModalListSelectState<T> {
//   selectedValue: T;
// }

export class ModalListSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: {},
    };
  }

  getButtons = () => {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-secondary",
      onClick: () => this.props.handleOk(this.state.selectedValue),
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: this.props.handleCancel,
    });
    return buttons;
  };

  handleChange = (newSelectedValue) => {
    this.setState({ selectedValue: newSelectedValue });
  };

  render() {
    const { headerText, options } = this.props;
    const { selectedValue } = this.state;
    const content = (
      <div style={{ minWidth: "300px", maxWidth: "500px", minHeight: "300px" }}>
        <Select
          name="listSelector"
          options={options}
          onChange={this.handleChange}
          value={selectedValue || ""}
          required={true}
          style={{ minWidth: "150px" }}
        />
      </div>
    );
    return (
      <ModalWithButtons
        text={headerText}
        buttons={this.getButtons()}
        content={content}
      />
    );
  }
}
