import React from "react";
import ExportCSVButton from "../../shared/ExportCSVButton";
import moment from "moment";

export default class CourseSchedulesTableActions extends React.PureComponent {
  render() {
    const { eduProgram, getCourseSchedulesForExport } = this.props;

    return (
      <div className="flex mb-8">
        <ExportCSVButton
          className="btn custom-btn btn-accent flex flex-align-center"
          fileName={`${eduProgram.name.replace(/ /g, "_")}_Course_Schedules`}
          getExportData={getCourseSchedulesForExport}
          headers={[
            { label: "Campus ID", value: "campusID" },
            { label: "Campus", value: "campusName" },
            { label: "Shliach Last Name", value: "shliachLastName" },
            { label: "Shliach ID", value: "shliachID" },
            { label: "Shliach First Name", value: "shliachFirstName" },
            { label: "Shlucha ID", value: "shluchaID" },
            { label: "Shlucha First Name", value: "shluchaFirstName" },
            { label: "Course Schedule Name", value: "courseScheduleName" },
            { label: "Course Name", value: "courseName" },
            { label: "Semester", value: "semester" },
            { label: "Year", value: "year" },
            {
              label: "Registration Start Date",
              value: ({ registrationStartDate }) =>
                registrationStartDate
                  ? moment(registrationStartDate).format("MM/DD/YYYY hh:mm A")
                  : "",
            },
            {
              label: "Registration End Date",
              value: ({ registrationEndDate }) =>
                registrationEndDate
                  ? moment(registrationEndDate).format("MM/DD/YYYY hh:mm A")
                  : "",
            },
            {
              label: "First Class Date",
              value: ({ firstClassDate }) =>
                firstClassDate
                  ? moment(firstClassDate).format("MM/DD/YYYY hh:mm A")
                  : "",
            },
            {
              label: "Last Class Date",
              value: ({ lastClassDate }) =>
                lastClassDate
                  ? moment(lastClassDate).format("MM/DD/YYYY hh:mm A")
                  : "",
            },
            //if no nextClassDate, it can be bec it's completed, but if it's not completed, then it means it's bec it's TBD
            {
              label: "Next Class Date",
              value: ({ nextClassDate, isCompleted }) =>
                nextClassDate
                  ? moment(nextClassDate).format("MM/DD/YYYY hh:mm A")
                  : isCompleted
                  ? ""
                  : "TBD",
            },
            { label: "Classes Scheduled", value: "numOfClassesScheduled" },
            { label: "Classes Completed", value: "numOfClassesCompleted" },
            {
              label: "Students Enrolled",
              value: ({ numOfStudents }) => numOfStudents || 0,
            },
            {
              label: "Status",
              value: ({ isCompleted, isCurrent, isUpcoming }) =>
                isCompleted
                  ? "Completed"
                  : isCurrent
                  ? "Current"
                  : isUpcoming
                  ? "Upcoming"
                  : "",
            },
          ]}
        />
      </div>
    );
  }
}
