import React from "react";
import PaginatedTable from "../../../shared/PaginatedTable";
import StudentsActions from "./StudentsActions";
import StudentsTableFilters from "./StudentsTableFilters";
import StudentsTableHeader from "./StudentsTableHeader";
import StudentsTableRecord from "./StudentsTableRecord";

import TripEventsApi from "../../../../services/resources/TripEventsApi";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { notify } from "react-notify-toast";
import axios from "axios";

export default class TripStudents extends React.PureComponent {
  state = {
    enrolledShluchim: {
      shluchim: [],
      loading: false,
    },
    errorMessage: "",
    filters: {
      campusId: "",
      gender: null,
      keyword: "",
      shliachId: "",
      statuses: [],
      tourIds: [],
      trackIds: [],
      wasDepositRefunded: null,
      wasParentLetterSubmitted: null,
      wasSurveySubmitted: null,
      workflowSteps: [],
    },
    loading: false,
    page: 1,
    results: 12,
    selectedStudents: {
      all: false,
      allExcept: [],
      selection: [],
    },
    showMoreTableColumns: false,
    sortBy: "studentLastName",
    students: [],
    success: true,
    totalStudents: 0,
  };

  apiSignal = axios.CancelToken.source();

  sortOptions = [
    { id: "studentLastName", name: "Student Name" },
    {
      id: "registrationDateAsc",
      name: "Date Applied - Asc",
    },
    {
      id: "registrationDateDesc",
      name: "Date Applied - Desc",
    },
    { id: "statusWorkflowUpdatedOnAsc", name: "Status Last Changed - Asc" },
    { id: "statusWorkflowUpdatedOnDesc", name: "Status Last Changed - Desc" },
  ];

  componentDidMount() {
    this.getEnrolledShluchim();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterStudents = (filters) => {
    this.setState({ filters }, () => {
      this.getStudents();

      // clear selection when applying filters
      const { selectedStudents } = this.state;
      if (selectedStudents.all || selectedStudents.selection.length) {
        this.clearSelectedStudents();
      }
    });
  };

  getEnrolledShluchim = () => {
    this.setState(
      {
        enrolledShluchim: {
          shluchim: [],
          loading: true,
        },
      },
      () => {
        TripEventsApi.getAllTripEnrollments(
          this.apiSignal.token,
          this.props.tripEvent.id
        )
          .then((shluchim) => {
            this.setState({
              enrolledShluchim: {
                shluchim,
                loading: false,
              },
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                enrolledShluchim: {
                  shluchim: [],
                  loading: false,
                },
              });
            }
          });
      }
    );
  };

  getStudents = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        TripEventsApi.getTripRegistrations(
          this.apiSignal.token,
          this.props.tripEvent.id,
          page,
          results,
          sortBy,
          this.state.filters,
          this.state.showMoreTableColumns
        )
          .then(({ registrations, totalRegistrations }) => {
            this.setState({
              loading: false,
              students: registrations,
              totalStudents: totalRegistrations,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                students: [],
                success: false,
                totalStudents: 0,
              });
            }
          });
      }
    );
  };

  getStudentsForExport = async (exportType) => {
    const { sortBy, filters } = this.state;

    const studentsForExport = await TripEventsApi.getTripRegistrations(
      this.apiSignal.token,
      this.props.tripEvent.id,
      undefined,
      undefined,
      sortBy,
      filters,
      undefined,
      exportType
    );

    return studentsForExport.registrations;
  };

  getStudentsSelection = async () => {
    const { all, allExcept, selection } = this.state.selectedStudents;

    if (selection.length) {
      return selection;
    }

    if (all) {
      try {
        const { registrations } = await TripEventsApi.getTripRegistrations(
          this.apiSignal.token,
          this.props.tripEvent.id,
          1,
          this.state.totalStudents, // get all student results
          "studentLastName",
          this.state.filters
        );

        if (allExcept) {
          return registrations.filter(
            (r) => !allExcept.some((s) => s.id === r.id)
          );
        }

        return registrations;
      } catch (err) {
        return null;
      }
    }
  };

  toggleShowMore = () => {
    this.setState(
      {
        showMoreTableColumns: !this.state.showMoreTableColumns,
      },
      () => {
        if (this.state.showMoreTableColumns) {
          this.getStudents();
        }
      }
    );
  };

  toggleSelectStudent = (student) => {
    const { selectedStudents } = this.state;
    const { all, allExcept, selection } = selectedStudents;

    if (all) {
      const _allExcept = allExcept.some((s) => s.id === student.id)
        ? allExcept.filter((s) => s.id !== student.id)
        : [...allExcept, student];
      this.setState({
        selectedStudents: {
          ...selectedStudents,
          allExcept: _allExcept,
        },
      });
    } else {
      const _selection = selection.some((s) => s.id === student.id)
        ? selection.filter((s) => s.id !== student.id)
        : [...selection, student];
      this.setState({
        selectedStudents: {
          ...selectedStudents,
          selection: _selection,
        },
      });
    }
  };

  toggleSelectAllStudents = () => {
    const { all, allExcept } = this.state.selectedStudents;
    this.setState({
      selectedStudents: {
        all: !(all && !allExcept.length),
        allExcept: [],
        selection: [],
      },
    });
  };

  clearSelectedStudents = () => {
    this.setState({
      selectedStudents: {
        all: false,
        allExcept: [],
        selection: [],
      },
    });
  };

  onStatusUpdate = (bulk = false) => {
    const { page, results, sortBy } = this.state;
    this.getStudents(page, results, sortBy);

    this.clearSelectedStudents();

    notify.show(
      `${bulk ? "Registrations have" : "Registration has"} been updated`,
      "success"
    );
  };

  render() {
    const { mobileMode, tripEvent, systemLists } = this.props;

    const {
      enrolledShluchim,
      filters,
      loading,
      students,
      page,
      results,
      selectedStudents,
      showMoreTableColumns,
      sortBy,
      success,
      totalStudents,
    } = this.state;

    const readOnlyAccess = !AuthService.UserHasClaim(
      PermissionClaims.TripDataEdit
    );

    const allowBulkStatusUpdate = !readOnlyAccess && tripEvent.isTravelTrip;

    return (
      <>
        <div className="flex flex-justify-space mb-24">
          <p className="xxl-text fw-500">All Students</p>
          <StudentsActions
            allStatuses={systemLists.tripRegistrationStatuses}
            allWorkflowSteps={systemLists.tripRegistrationWorkflowSteps}
            getStudentsForExport={this.getStudentsForExport}
            getStudentsSelection={this.getStudentsSelection}
            onStatusUpdate={() => this.onStatusUpdate(true)}
            selectedStudents={selectedStudents}
            showBulkStatusUpdateButton={allowBulkStatusUpdate}
            showMoreTableColumns={showMoreTableColumns}
            toggleShowMoreTableColumns={this.toggleShowMore}
            tripEvent={tripEvent}
          />
        </div>

        <div className="relative">
          <StudentsTableFilters
            allEnrolledShluchim={enrolledShluchim.shluchim}
            allEnrolledShluchimLoading={enrolledShluchim.loading}
            allGenders={systemLists.genders}
            allStatuses={systemLists.tripRegistrationStatuses}
            allWorkflowSteps={systemLists.tripRegistrationWorkflowSteps}
            applyFilters={this.filterStudents}
            tripEvent={tripEvent}
          />

          <div
            className={`trip-students-table ${
              showMoreTableColumns ? "overflow" : ""
            }`}
          >
            <PaginatedTable
              loading={loading}
              loadData={this.getStudents}
              mobileMode={mobileMode}
              page={page}
              records={students}
              renderHeader={() => (
                <StudentsTableHeader
                  readOnlyAccess={readOnlyAccess}
                  selectAllDisabledReason={
                    filters.statuses.length + filters.workflowSteps.length !== 1
                      ? "Select a single status filter to select all students for bulk status change"
                      : ""
                  }
                  selectedStudents={selectedStudents}
                  showMoreTableColumns={showMoreTableColumns}
                  showSelectAllCheckbox={allowBulkStatusUpdate}
                  toggleSelectAllStudents={this.toggleSelectAllStudents}
                  tours={tripEvent.tours}
                  totalStudents={totalStudents}
                />
              )}
              renderRow={(studentRegistration) => {
                const selected = selectedStudents.all
                  ? !selectedStudents.allExcept.some(
                      (s) => s.id === studentRegistration.id
                    )
                  : selectedStudents.selection.some(
                      (s) => s.id === studentRegistration.id
                    );
                const selectionDisabled = selectedStudents.selection.some(
                  (s) =>
                    s.status !== studentRegistration.status ||
                    s.workflowStep !== studentRegistration.workflowStep
                );
                return (
                  <StudentsTableRecord
                    key={studentRegistration.id}
                    onStatusUpdate={() => this.onStatusUpdate(false)}
                    readOnlyAccess={readOnlyAccess}
                    scheduleId={tripEvent.programScheduleID}
                    selected={selected}
                    selectionDisabledReason={
                      selectionDisabled
                        ? "Statuses must match for all selected students"
                        : ""
                    }
                    showSelectCheckbox={allowBulkStatusUpdate}
                    showMoreTableColumns={showMoreTableColumns}
                    studentRegistration={studentRegistration}
                    toggleSelectStudent={() =>
                      this.toggleSelectStudent(studentRegistration)
                    }
                    tripEvent={tripEvent}
                  />
                );
              }}
              results={results}
              showResultsView={true}
              sortBy={sortBy}
              sortControlClassName="trip-students-table-sort"
              sortOptions={this.sortOptions}
              success={success}
              totalCount={totalStudents}
            />
          </div>
          {!loading && success && students.length > 0 && (
            <p className="accent-text-dark mt-24">
              {totalStudents} Result{totalStudents === 1 ? "" : "s"}
            </p>
          )}
        </div>
      </>
    );
  }
}
