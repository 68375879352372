import React from "react";
import ReactSelect from "react-select";

export class Select extends React.PureComponent {
  handleChange = (e) => {
    // e is null when clearing the select...
    if (!e) {
      e = {
        label: undefined,
        value: undefined,
      };
    }
    const { props } = this;
    if (!props.onChange) {
      return;
    }
    // Create a fake target with fake attributes based on data- props...
    const attributes = {};
    for (let key in props) {
      if (key.startsWith("data-")) {
        attributes[key] = {
          value: props[key],
        };
      }
    }
    props.onChange({
      target: {
        attributes,
        value: e.value,
      },
      ...e,
    });
  };

  render() {
    return <ReactSelect {...this.props} onChange={this.handleChange} />;
  }
}
