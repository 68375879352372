import React from "react";
import { FastField } from "formik";
import OnScreenHebrewKeyboard from "./OnScreenHebrewKeyboard";
import get from "lodash.get";

export default class FormInputHebrew extends React.PureComponent {
  state = {
    isFocused: false,
  };

  render() {
    const {
      children,
      className,
      component,
      disabled,
      errors,
      label,
      name,
      readOnly,
      touched,
      type,
      validate,
      setFieldValue,
      style,
      ...props
    } = this.props;
    const error = get(errors, name) && get(touched, name);
    const errorText = get(errors, name);

    return (
      <React.Fragment>
        {label && (
          <label
            className={`accent-text small-text ${
              className === "form-required" ? "form-required" : ""
            } ${error ? "error" : ""}`}
          >
            {label}
          </label>
        )}
        <FastField
          autoComplete="off"
          name={name}
          className={`custom-input ${error ? "error" : ""} ${
            className ? className : ""
          }`}
          component={component}
          type={type}
          validate={validate}
          {...props}
          disabled={disabled}
          readOnly={readOnly}
          onFocus={(e) => {
            const inputValue = e.target.value;
            this.setState({ isFocused: true }, () => {
              if (this.keyboardRef) {
                this.keyboardRef.keyboard.setInput(inputValue);
              }
            });
          }}
          onBlur={() => this.setState({ isFocused: false })}
          onChange={(e) => {
            setFieldValue(name, e.target.value, validate);
            if (this.keyboardRef) {
              this.keyboardRef.keyboard.setInput(e.target.value);
            }
          }}
        >
          {children ? children : null}
        </FastField>
        {error && <p className="error-message">{errorText}</p>}

        {this.state.isFocused && (
          <OnScreenHebrewKeyboard
            createRef={(ref) => (this.keyboardRef = ref)}
            inputName={name}
            onChange={(value) => setFieldValue(name, value, validate)}
          />
        )}
      </React.Fragment>
    );
  }
}
