import AuthRequest from "./AuthRequest";

const baseUrl = "/api/permissions";

const PermissionsApi = {
  getRole: (id) => {
    return AuthRequest.get(`${baseUrl}/roles/${id}`);
  },
  createRole: (role) => {
    return AuthRequest.post(`${baseUrl}/roles`, { ...role });
  },
  editRole: (role) => {
    return AuthRequest.post(`${baseUrl}/roles/${role.id}`, { ...role });
  },
  deleteRole: (id) => {
    return AuthRequest.delete(`${baseUrl}/roles/${id}`);
  },
  listClaims: () => {
    return AuthRequest.get(`${baseUrl}/claims`);
  },
  listRoles: () => {
    return AuthRequest.get(`${baseUrl}/roles`);
  },
};

export default PermissionsApi;
