import React from "react";
import Modal from "react-modal";

const styles = {
  modal: {
    overlay: {
      zIndex: 5,
      backgroundColor: "rgba(42,63,84, 0.85)",
    },
    content: {
      top: "5%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, 0)",
      overflow: "auto",
      minWidth: "600px",
      maxHeight: "92vh",
    },
  },
};

// interface AdvancedSearchModalProps {
//   showAdvancedSearch: boolean;
//   errorMessage?: string;
//   advancedSearch: React.FormEventHandler<HTMLElement>;
//   changeHandler: React.FormEventHandler<HTMLElement>;
//   closeAdvancedSearch: React.FormEventHandler<HTMLElement> | any;
//   handleSelectAll: React.FormEventHandler<HTMLElement>;
//   handleChkChange: (e, col) => void;
//   firstName: string;
//   lastName: string;
//   phone: string;
//   school: string;
//   chabadHouse: string;
//   email: string;
//   city: string;
//   searchOptions: Array<any>;
//   searchTypes: Array<any>;
// }

const AdvancedSearchModal = ({
  showAdvancedSearch,
  closeAdvancedSearch,
  errorMessage,
  advancedSearch,
  changeHandler,
  handleSelectAll,
  handleChkChange,
  firstName,
  lastName,
  phone,
  email,
  school,
  chabadHouse,
  city,
  includeInactive,
  searchOptions,
  searchTypes,
}) => {
  const searchTypesToInclude = searchTypes.filter(
    (col) => col.includeInAdvancedSearch,
  );
  return (
    <div>
      {/* PREPARE COMPONENT */}
      <Modal
        isOpen={showAdvancedSearch}
        onRequestClose={closeAdvancedSearch}
        contentLabel="History Filter Menu"
        style={styles.modal}
      >
        <h3>Advanced Search</h3>
        <div>
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              <strong>Validation:</strong> {errorMessage}
            </div>
          )}
          <form onSubmit={advancedSearch}>
            <div className="text-left">
              <br />
              {/* <strong>Use "%" to do a wildcard search</strong>
                            <br/><br/> */}
              <table className="table">
                <tbody>
                  <tr>
                    <td>First Name:</td>
                    <td>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={changeHandler}
                        value={firstName || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Last Name:</td>
                    <td>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={changeHandler}
                        value={lastName || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={changeHandler}
                        value={email || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Phone:</td>
                    <td>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={changeHandler}
                        value={phone || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>School:</td>
                    <td>
                      <input
                        type="text"
                        id="school"
                        name="school"
                        onChange={changeHandler}
                        value={school || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Chabad House:</td>
                    <td>
                      <input
                        type="text"
                        id="chabadHouse"
                        name="chabadHouse"
                        onChange={changeHandler}
                        value={chabadHouse || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>City:</td>
                    <td>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        onChange={changeHandler}
                        value={city || ""}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            id="status"
                            name="includeInactive"
                            value="false"
                            checked={includeInactive === "false"}
                            onChange={changeHandler}
                          />
                          Active Only
                        </label>
                      </div>
                      <div className="radio">
                        <label>
                          <input
                            type="radio"
                            id="status"
                            name="includeInactive"
                            value="true"
                            checked={includeInactive === "true"}
                            onChange={changeHandler}
                          />
                          Active and Inactive
                        </label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Search for:</td>
                    <td>
                      {searchTypesToInclude.map((col) => (
                        <div
                          key={col.label}
                          style={{
                            display: "grid",
                            gridTemplateColumns: "20px auto",
                          }}
                        >
                          <div>
                            <input
                              type="checkbox"
                              id={"col" + col.label}
                              checked={searchOptions.some(
                                (c) => c.label === col.label,
                              )}
                              onChange={(e) => handleChkChange(e, col)}
                            />
                          </div>
                          <div>
                            <label htmlFor={"col" + col.label}>
                              {col.label}
                            </label>
                          </div>
                        </div>
                      ))}
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "20px auto",
                        }}
                      >
                        <div>
                          <input
                            type="checkbox"
                            id="chkSelectAll"
                            checked={
                              searchOptions &&
                              searchOptions.length ===
                                searchTypesToInclude.length
                            }
                            onChange={handleSelectAll}
                          />
                        </div>
                        <div>
                          <label htmlFor="chkSelectAll">All</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div className="text-right">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
              &nbsp;&nbsp;
              <button onClick={closeAdvancedSearch} className="btn btn-primary">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AdvancedSearchModal;
