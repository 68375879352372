import React from "react";
import { formatCurrency, formatNumber } from "../../lib";

export default class LifeInsuranceStats extends React.PureComponent {
  render() {
    const {
      policy: {
        activeEnrollmentsCount,
        attentionRequiredEnrollmentsCount,
        price,
        programName,
        terminatedEnrollmentsCount,
      },
    } = this.props;

    return (
      <div className="flex flex-align-center flex-justify-space mb-32">
        <div>
          <p className="fw-900 xl-text mb-16">
            {programName || "Life Insurance"}
          </p>

          <div className="two-column-grid">
            <p className="fw-700">Price</p>
            <p>{price ? `$${formatCurrency(price)}` : ""}</p>
          </div>
        </div>

        <div className="flex flex-align-center life-insurance-header-cards">
          <div className="card text-center mr-16">
            <p className="fw-900 xxl-text">
              {formatNumber(activeEnrollmentsCount)}
            </p>
            Active
          </div>

          <div className="card text-center mr-16">
            <p className="fw-900 xxl-text">
              {formatNumber(attentionRequiredEnrollmentsCount)}
            </p>
            Attention Required
          </div>

          <div className="card text-center">
            <p className="fw-900 xxl-text">
              {formatNumber(terminatedEnrollmentsCount)}
            </p>
            Terminated
          </div>
        </div>
      </div>
    );
  }
}
