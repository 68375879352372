import React from "react";
import Loader from "../../common/Loader";
import Modal from "react-modal";
import SelectSearch from "react-select";

import TripEventsApi from "../../../services/resources/TripEventsApi";
import axios from "axios";

export default class ConfirmationModal extends React.Component {
  state = {
    event: null,
    eventErrorMessage: "",
    eventLoading: false,
    eventsList: [],
    eventsListErrorMessage: "",
    eventsListLoading: false,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getEventsList();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getEventsList = async () => {
    this.setState({ eventsListLoading: true });

    let eventsList = [],
      eventsListErrorMessage = "";
    try {
      eventsList = await TripEventsApi.getTripEvents(this.apiSignal.token);
    } catch (err) {
      eventsListErrorMessage =
        "Something went wrong and eventsList could not be retrieved";
    }

    this.setState({
      eventsList,
      eventsListErrorMessage,
      eventsListLoading: false,
    });
  };

  onCloneEvent = async () => {
    this.setState({ eventLoading: true });

    let eventErrorMessage = "";
    try {
      const {
        event: { value: eventProgramScheduleId },
      } = this.state;
      const event = await TripEventsApi.getTripEvent(
        this.apiSignal.token,
        eventProgramScheduleId,
      );

      this.props.onCloneEvent(event);
    } catch (err) {
      eventErrorMessage =
        "Something went wrong and event settings could not be retrieved for cloning";
    }

    this.setState({ eventErrorMessage, eventLoading: false });
  };

  onClose = () => {
    this.props.close();
    this.setState({
      event: null,
      eventErrorMessage: "",
      eventLoading: false,
    });
  };

  render() {
    const { show } = this.props;
    const {
      event,
      eventErrorMessage,
      eventLoading,
      eventsList,
      eventsListErrorMessage,
      eventsListLoading,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card medium-modal">
          <p className="large-text fw-500 mb-8">Clone Event Settings</p>
          <p className="accent-text mb-16">
            Select an existing trip event to clone settings, or Skip to start
            with a blank event template.
          </p>
          <SelectSearch
            disabled={eventsListLoading || eventLoading}
            isClearable={true}
            isSearchable={true}
            onChange={(ev) => this.setState({ event: ev })}
            options={eventsList.map((ev) => ({
              value: ev.programScheduleID,
              label: ev.name,
            }))}
            placeholder={eventsListLoading ? "Loading Trips..." : "Select Trip"}
            style={{
              height: "36px",
              width: "100%",
              border: "1px solid #edecec",
              fontSize: "14px",
            }}
            value={event}
          />
          {eventsListErrorMessage && (
            <p className="error-text">{eventsListErrorMessage}</p>
          )}
          <div className="text-right mt-24">
            {eventLoading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <button
                  className="btn link-text uppercase-text"
                  onClick={this.onClose}
                >
                  Skip
                </button>
                <button
                  className="btn link-text uppercase-text ml-24"
                  disabled={!event}
                  onClick={this.onCloneEvent}
                >
                  Clone
                </button>
              </React.Fragment>
            )}
            {eventErrorMessage && (
              <p className="error-text">{eventErrorMessage}</p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
