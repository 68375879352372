import React from "react";
import moment from "moment";
import { formatNumber } from "../../../../lib";

export default class LocationCoursesTableRow extends React.PureComponent {
  render() {
    const {
      course: {
        courseScheduleID,
        courseScheduleName,
        firstClassDate,
        isCompleted,
        isCurrentlyOfferedByChabadHouse,
        isUpcomingByChabadHouse,
        lastClassDate,
        numOfStudents,
      },
    } = this.props;

    return (
      <div
        className="locations-shliach-details-modal-table-row"
        key={courseScheduleID}
      >
        <p>{courseScheduleName}</p>
        <p>
          {!firstClassDate
            ? "TBD"
            : `${moment(firstClassDate).format("M/D/YY")} - ${moment(
                lastClassDate,
              ).format("M/D/YY")}`}
        </p>
        <p>
          {isCurrentlyOfferedByChabadHouse
            ? "Current"
            : isUpcomingByChabadHouse
            ? "Upcoming"
            : isCompleted
            ? "Completed"
            : ""}
        </p>
        <p>{formatNumber(numOfStudents)}</p>
      </div>
    );
  }
}
