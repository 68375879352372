import React from "react";
import FormInputUpdate from "../../Form/FormInputUpdate";
import { FieldArray } from "formik";

export default class PrimaryChabadHouseInfo extends React.PureComponent {
  state = {
    primaryChabadHouseInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        primaryChabadHouseInfoShowing: false,
      });
    }
  }

  chabadHousesTouched = (touched) => {
    return (
      touched &&
      touched.chabadHouses &&
      touched.chabadHouses.filter((ch) => ch && ch.chabadHouseID === true)
        .length > 0
    );
  };

  render() {
    const { errors, handleChange, touched, values, readOnly } = this.props;

    return (
      <div
        className={
          this.state.primaryChabadHouseInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="chabadHouses">
          <>
            <div className="flex mobile-flex-justify-space flex-align-center mt-48 mb-8">
              <p
                className="accent-text-dark medium-text fw-700"
                onClick={() => {
                  this.setState({
                    primaryChabadHouseInfoShowing:
                      !this.state.primaryChabadHouseInfoShowing,
                  });
                }}
              >
                My Chabad Houses
              </p>
            </div>
            <div className="profile-form-primary-ch-info">
              <p className="small-text accent-text mb-16">
                <span
                  className={
                    errors.noPrimaryChabadHouse &&
                    this.chabadHousesTouched(touched)
                      ? "primary-ch-error-message"
                      : ""
                  }
                >
                  One primary chabad house is required
                </span>
              </p>
              {values.chabadHouses &&
                values.chabadHouses.map((chabadHouse, index) => (
                  <div
                    key={index}
                    className="flex flex-align-center mb-16 mobile-card"
                  >
                    <p>{chabadHouse.chabadHouseName}</p>
                    <div
                      className="relative flex flex-align-center ml-16"
                      style={{ minHeight: "32px" }}
                    >
                      <FormInputUpdate
                        name={`chabadHouses[${index}].isPrimary`}
                        errors={errors}
                        touched={touched}
                        readOnly={readOnly}
                      >
                        {({ field }) => (
                          <div className="radio-button-container">
                            <div className="flex">
                              <input
                                type="checkbox"
                                id={`chabad-house-primary-${index}`}
                                {...field}
                                value="isPrimary"
                                checked={chabadHouse.isPrimary}
                                disabled={readOnly}
                                onChange={(e) => {
                                  let currentPrimaryChabadHouseIndex =
                                    values.chabadHouses
                                      .map((cm) => cm.isPrimary)
                                      .indexOf(true);

                                  if (currentPrimaryChabadHouseIndex >= 0)
                                    handleChange({
                                      target: {
                                        name: `chabadHouses[${currentPrimaryChabadHouseIndex}].isPrimary`,
                                        value: false,
                                      },
                                    });

                                  field.onChange(e);
                                }}
                              />
                              <label
                                htmlFor={`chabad-house-primary-${index}`}
                              />
                              <span>primary</span>
                            </div>
                          </div>
                        )}
                      </FormInputUpdate>
                    </div>
                  </div>
                ))}
            </div>
          </>
        </FieldArray>
      </div>
    );
  }
}
