import React from "react";
import { Link } from "react-router";
import Select from "react-select";

export default class LamplightersCard extends React.PureComponent {
  render() {
    const {
      lamplightersList,
      lamplightersSchedule: { programScheduleName },
      onChangeLamplightersSchedule,
      scheduleId,
    } = this.props;

    return (
      <div className="card trip-card flex flex-justify-space flex-align-center mb-40">
        {scheduleId &&
        lamplightersList &&
        lamplightersList.length > 0 &&
        lamplightersList
          .map((p) => p.programScheduleID.toString())
          .indexOf(scheduleId) >= 0 ? (
          <Select
            className="xl-text fw-700 flex trip-event-select custom-select full-width"
            clearable={false}
            onChange={({ value }) => onChangeLamplightersSchedule(value)}
            options={
              lamplightersList &&
              lamplightersList.map((l) => ({
                className: l.isCurrent ? "fw-700" : "fw-400",
                label: l.programScheduleName,
                value: l.programScheduleID,
              }))
            }
            searchable={false}
            value={scheduleId}
          />
        ) : (
          <p className="xl-text fw-700">{programScheduleName}</p>
        )}
        <Link
          className="btn custom-btn btn-accent-secondary uppercase-text btn-medium ml-32"
          to={`/lamplighters/settings/${scheduleId}`}
        >
          View Settings
        </Link>
      </div>
    );
  }
}
