import React, { useCallback, useState } from "react";
import { Link, browserHistory } from "react-router";
import Select from "../../../../../shared/Select";
import Checkbox from "../../../../../shared/Checkbox";
import RegistrationStatusActions from "./status/RegistrationStatusActions";
import RegistrationWorkflowSteps from "./overview/RegistrationWorkflowSteps";

import { TripRegistrationStatuses } from "../../../../TripEventConsts";
import { getCreditsDisplay, getPaymentDisplay } from "./RegistrationPayment";
import {
  formatFullName,
  formatCurrency,
  pluralizeText,
} from "../../../../../../lib";
import moment from "moment";
import FilePreviewModal from "../../../../../common/FilePreviewModal";

function RegistrationOverview(props) {
  const {
    allowUpdate,
    hasTripEnded,
    initialRegistration,
    isTravelTrip,
    onChangeRegistration,
    onRegistrationUpdated,
    programScheduleName,
    readOnlyAccess,
    registration,
    rewardCreditValue,
    shliachEnrollment: { tracks },
    saveCancelControl,
  } = props;

  const {
    acceptedRejectedDate,
    busNumber,
    cancelledDate,
    dateCheckedIn,
    dateRegistered,
    flightDetails,
    rewardRequest,
    shliachFullName,
    shluchaFullName,
    status,
    statusWorkflowUpdatedOn,
    student: {
      ageAtTrip,
      campus: { name: campusName },
      cell,
      email,
      fatherBackground,
      id: studentId,
      isJewish,
      motherBackground,
      person: { dob, gender, firstName, lastName, profileImageURL },
    },
    trackID,
    trackName,
    wasEarlyBird,
    wasDepositRefunded,
    wasParentLetterSubmitted,
    wasSurveySubmitted,
  } = registration;

  const [showProfileImagePreview, setShowProfileImagePreview] = useState(false);

  const onChangeTrack = useCallback(
    (id) => {
      const trackId = parseInt(id);

      const track = tracks.find((t) => t.id === trackId);
      const { earlyBirdPrice, regularPrice, trackName } = track;

      onChangeRegistration("trackID", trackId, {
        trackName,
        trackPrice: registration.wasEarlyBird ? earlyBirdPrice : regularPrice,
      });
    },
    [onChangeRegistration, registration.wasEarlyBird, tracks]
  );

  const getTrackPriceDisplay = useCallback(
    (trackName, price, isEarlyBird) => {
      let display = `${trackName} - ${isEarlyBird ? "(Early Bird) " : ""}`;

      if (rewardCreditValue) {
        const credits = price / rewardCreditValue;
        display += `${credits} ${pluralizeText(
          "Credit",
          credits
        )} ($${formatCurrency(price)})`;
      } else {
        display += `$${formatCurrency(price)}`;
      }

      return display;
    },
    [rewardCreditValue]
  );

  const showAdditionalDetails =
    isTravelTrip &&
    (registration.status === TripRegistrationStatuses.Accepted ||
      registration.status === TripRegistrationStatuses.CheckedIn ||
      registration.status === TripRegistrationStatuses.Cancelled);

  const disabledTooltip =
    !allowUpdate && !readOnlyAccess ? (
      <span className="tooltip">
        Cannot update for {registration.statusDisplay} registrations
      </span>
    ) : (
      ""
    );

  return (
    <div className="trip-student-overview-card">
      <div>
        <div className="flex flex-align-center">
          <p
            onClick={() => browserHistory.goBack()}
            className="link-text flex flex-align-center mr-8"
          >
            <i className="material-icons link-text xxl-text mr-8">arrow_back</i>
          </p>
          <Link
            className="xxl-text fw-700 link-text-secondary"
            to={`/students/${studentId}`}
          >
            {formatFullName(firstName, lastName)}
          </Link>
          {profileImageURL && (
            <>
              <img
                alt="profile"
                className="student-profile-img"
                onClick={() => setShowProfileImagePreview(true)}
                src={profileImageURL}
              />
              <FilePreviewModal
                close={() => setShowProfileImagePreview(false)}
                previewStyle={{ objectFit: "contain" }}
                show={showProfileImagePreview}
                title="Student Profile Image"
                url={profileImageURL}
                withDownload={true}
              />
            </>
          )}
        </div>
        <div className="flex flex-align-center">
          {saveCancelControl}
          <RegistrationStatusActions
            isTravelTrip={isTravelTrip}
            programScheduleName={programScheduleName}
            onUpdate={onRegistrationUpdated}
            registration={registration}
          />
        </div>
      </div>
      {isTravelTrip && (
        <RegistrationWorkflowSteps registration={registration} />
      )}
      <div
        className={`student-overview-info-grid${
          showAdditionalDetails ? " more-info" : ""
        }`}
      >
        <div>
          <p className="fs-12 mb-16">STUDENT INFO</p>
          <div className="two-column-grid">
            {!isTravelTrip && (
              <>
                <p className="fw-700">Email</p>
                <p>{email}</p>
                <p className="fw-700">Phone</p>
                <p>{cell}</p>
              </>
            )}
            <p className="fw-700">Age / Birthday</p>
            <p>
              {ageAtTrip} / {moment(dob).format("MMMM D, YYYY")}
            </p>
            <p className="fw-700">Gender</p>
            <p>{gender}</p>
            <p className="fw-700">Campus</p>
            <p>{campusName}</p>
            <p className="fw-700">Shliach</p>
            <p>{shliachFullName}</p>
            <p className="fw-700">Shlucha</p>
            <p>{shluchaFullName}</p>
            {isTravelTrip ? (
              <>
                <p className="fw-700">Mother's background</p>
                <p>{motherBackground}</p>
                <p className="fw-700">Father's background</p>
                <p>{fatherBackground}</p>
              </>
            ) : (
              <>
                <p className="fw-700">Jewish</p>
                <p>{isJewish ? "Yes" : isJewish === false ? "No" : ""}</p>
              </>
            )}
          </div>
        </div>
        <div>
          <p className="fs-12 mb-16">REGISTRATION INFO</p>
          {tracks.length > 1 ? (
            <div className="registration-input tooltip-container mb-16">
              <label>Track</label>
              <Select
                disabled={!allowUpdate}
                name="trackID"
                onChange={(_, value) => onChangeTrack(value)}
                options={
                  tracks &&
                  tracks.map((track) => {
                    const {
                      earlyBirdPrice,
                      isFullToCapacity,
                      regularPrice,
                      trackName,
                    } = track;
                    const isInitialTrack =
                      initialRegistration.trackID === track.id; //Lock in track price if is initially registered track
                    const trackPriceDisplay = getTrackPriceDisplay(
                      trackName,
                      isInitialTrack
                        ? initialRegistration.trackPrice
                        : (wasEarlyBird && earlyBirdPrice) || regularPrice,
                      isInitialTrack
                        ? wasEarlyBird
                        : wasEarlyBird && earlyBirdPrice
                    );
                    return {
                      display: `${trackPriceDisplay}${
                        isFullToCapacity ? " (Track is Full)" : ""
                      }`,
                      value: track.id,
                    };
                  })
                }
                value={trackID}
              />
              {disabledTooltip}
            </div>
          ) : (
            <div className="two-column-grid">
              <p className="fw-700">Track</p>
              <p>
                {getTrackPriceDisplay(
                  trackName,
                  initialRegistration.trackPrice,
                  wasEarlyBird
                )}
              </p>
            </div>
          )}
          <div className="two-column-grid">
            <p className="fw-700">Date Applied</p>
            <p>
              {dateRegistered
                ? moment(dateRegistered).format("MMMM D, YYYY")
                : ""}
            </p>
            <p className="fw-700">
              Date{" "}
              {status === TripRegistrationStatuses.Rejected
                ? "Rejected"
                : "Accepted"}
            </p>
            <p>
              {acceptedRejectedDate
                ? moment(acceptedRejectedDate).format("MMMM D, YYYY")
                : "–"}
            </p>
            <p className="fw-700">Status last changed</p>
            <p>
              {statusWorkflowUpdatedOn
                ? moment(statusWorkflowUpdatedOn).format("MM/DD/YYYY")
                : "–"}
            </p>
            <p className="fw-700">Total paid</p>
            <div className="trip-student-payment-text">
              {getPaymentDisplay(registration, true)}
              {rewardRequest?.creditsToRedeem
                ? getCreditsDisplay(registration, true)
                : ""}
            </div>
            {cancelledDate && (
              <>
                <p className="fw-700">Date Cancelled</p>
                <p>{moment(cancelledDate).format("MMMM D, YYYY")}</p>
              </>
            )}
            {dateCheckedIn && (
              <>
                <p className="fw-700">Checked In</p>
                <p>
                  {dateCheckedIn
                    ? moment(dateCheckedIn).format("dddd, MMM D, h:mm A")
                    : "–"}
                </p>
              </>
            )}
          </div>
        </div>
        {showAdditionalDetails && (
          <div>
            <div className="divider-section tooltip-container">
              <p className="fs-12 mb-16 uppercase-text">Parent letters</p>
              <Checkbox
                checked={wasParentLetterSubmitted}
                disabled={!allowUpdate}
                label="Parent letter(s) submitted"
                name="wasParentLetterSubmitted"
                onChange={onChangeRegistration}
              />
              {disabledTooltip}
            </div>
            <div className="divider-section">
              <p className="fs-12 mb-16 uppercase-text">Transportation</p>
              <div className="registration-input tooltip-container mb-16">
                <label>Flight</label>
                <textarea
                  className="custom-input"
                  disabled={!allowUpdate}
                  name="flightDetails"
                  onChange={(e) =>
                    onChangeRegistration(e.target.name, e.target.value)
                  }
                  value={flightDetails}
                />
                {disabledTooltip}
              </div>
              <div className="registration-input tooltip-container">
                <label>Bus</label>
                <input
                  className="custom-input"
                  disabled={!allowUpdate}
                  min="1"
                  name="busNumber"
                  onChange={(e) =>
                    onChangeRegistration(e.target.name, e.target.value)
                  }
                  style={{ width: "60px" }}
                  type="number"
                  value={busNumber}
                />
                {disabledTooltip}
              </div>
            </div>
            <div className="tooltip-container mb-16">
              <p className="fs-12 mb-16 uppercase-text">Post trip</p>
              <Checkbox
                checked={wasSurveySubmitted}
                disabled={
                  !allowUpdate ||
                  !hasTripEnded ||
                  status !== TripRegistrationStatuses.Accepted
                }
                label="Survey submitted"
                name="wasSurveySubmitted"
                onChange={onChangeRegistration}
              />
              {disabledTooltip}
            </div>
            <div>
              <span className="fw-700 mr-16">Deposit status</span>
              {wasDepositRefunded ? "Refunded" : "–"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(RegistrationOverview);
