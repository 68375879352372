import React from "react";

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.clear = this.clear.bind(this);
  }

  clear() {
    this.props.onChange(this.props.name, "");
  }

  render() {
    const {
      className,
      clearable,
      disabled,
      name,
      onChange,
      options,
      placeholder,
      style,
      tabIndex,
      value,
    } = this.props;

    return (
      <div className={className || "custom-select"} style={style}>
        <select
          disabled={disabled || !options || !options.length}
          name={name}
          onChange={(event) => onChange(event.target.name, event.target.value)}
          tabIndex={tabIndex}
          value={value || ""}
        >
          {(placeholder || (options && options.length > 0)) && (
            <option hidden defaultValue>
              {placeholder || "Select"}
            </option>
          )}
          {options &&
            options.map((option) => {
              return option.optionGroup ? (
                <optgroup key={option.label} label={option.label}></optgroup>
              ) : (
                <option
                  key={option.value}
                  value={option.value}
                  title={option.details}
                >
                  {option.display}
                </option>
              );
            })}
        </select>
        {clearable && !!value && (
          <span className="clear-select input" onClick={this.clear}>
            &times;
          </span>
        )}
      </div>
    );
  }
}
