import React from "react";

import { validateEmail } from "../../../../../lib/utils";

import ShliachInfo from "./ShliachInfo";
import ContactInfo from "./ContactInfo";
import SpouseInfo from "./SpouseInfo";
import SpouseContactInfo from "./SpouseContactInfo";
import FamilyInfo from "./FamilyInfo";
import PrimaryChabadHouseInfo from "./PrimaryChabadHouseInfo";

export default class GeneralForm extends React.PureComponent {
  require = (value) => (!value && value !== false ? true : "");

  hasUSAddress = (family) =>
    family && family.address && family.address.country === "USA";

  validateContactMethod = (value, contactMethod) => {
    if (!value) return true;
    if (contactMethod.type === "Email") {
      return !validateEmail(value) ? "Invalid Email" : "";
    }
  };

  render() {
    const {
      errors,
      handleChange,
      readOnly,
      setFieldError,
      setFieldValue,
      sys,
      touched,
      values,
    } = this.props;

    const { person: { gender } = {}, spouse: { gender: spouseGender } = {} } =
      values || {};
    const shliachType = gender === "Female" ? "Shlucha" : "Shliach";
    const spouseShliachType = spouseGender === "Female" ? "Shlucha" : "Shliach";

    return (
      <div className="profile-form-sections">
        <div className="profile-form-section">
          <p className="accent-text small-text">
            Information will not be shared publicly except for names. Contact
            information will be shared within the Shluchim Portal, which will
            only be available to other Campus Shluchim.
          </p>
        </div>

        <ShliachInfo
          errors={errors}
          handleChange={handleChange}
          require={this.require}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          values={values}
          actions={this.props.actions}
          readOnly={readOnly}
          shliachType={shliachType}
        />

        <ContactInfo
          // TODO: look into this
          // contactMethodsError={this.state.contactMethodsError}
          errors={errors}
          handleChange={handleChange}
          require={this.require}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          sys={sys}
          tab={this.props.tab}
          touched={touched}
          validateContactMethod={this.validateContactMethod}
          values={values}
          readOnly={readOnly}
          shliachType={shliachType}
        />

        <SpouseInfo
          errors={errors}
          hasUSAddress={this.hasUSAddress}
          require={this.require}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          values={values}
          readOnly={readOnly}
          shliachType={spouseShliachType}
        />

        <SpouseContactInfo
          // TODO: look into this
          // contactMethodsError={this.state.contactMethodsError}
          errors={errors}
          handleChange={handleChange}
          require={this.require}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          sys={sys}
          tab={this.props.tab}
          touched={touched}
          validateContactMethod={this.validateContactMethod}
          values={values}
          readOnly={readOnly}
          shliachType={spouseShliachType}
        />

        <FamilyInfo
          errors={errors}
          handleChange={handleChange}
          require={this.require}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          sys={sys}
          readOnly={readOnly}
        />

        {values.chabadHouses && values.chabadHouses.length > 0 && (
          <PrimaryChabadHouseInfo
            errors={errors}
            handleChange={handleChange}
            require={this.require}
            setFieldValue={setFieldValue}
            touched={touched}
            values={values}
            sys={sys}
            readOnly={readOnly}
          />
        )}
      </div>
    );
  }
}
