import React from "react";
import Modal from "react-modal";

export default ({ close, isOpen, url }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      contentLabel="Program Banner Image Preview"
      className="image-preview-modal"
    >
      <img src={url} alt="" />
      <div
        className="image-preview-close"
        title="Close preview. (ESC)"
        onClick={close}
      >
        x
      </div>
    </Modal>
  );
};
