import React from "react";

export default ({
  checkboxBehavior,
  className,
  disabled,
  error,
  name,
  onChange,
  options,
  readOnly,
  tabIndex,
  value,
}) => (
  <div
    className={`radio-button-container mb-8 ${className || ""} ${
      disabled ? "disabled" : readOnly ? "readonly" : ""
    } ${error ? "error" : ""}`}
  >
    {options.map((option) => (
      <React.Fragment key={option.value}>
        <div className="radio-button-container mb-8">
          <div className="flex">
            <input
              type={checkboxBehavior ? "checkbox" : "radio"}
              disabled={disabled || option.disabled}
              readOnly={readOnly}
              id={`${name}-${option.value}`}
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={readOnly ? null : () => onChange(name, option.value)} //to avoid default toString value
              tabIndex={tabIndex}
            />
            <label
              className={disabled || option.disabled ? "disabled" : ""}
              htmlFor={`${name}-${option.value}`}
              style={{ paddingLeft: "32px" }}
            >
              {option.displayLabel || option.display}
            </label>
          </div>
          {!!option.tag && option.tag}
        </div>
      </React.Fragment>
    ))}
  </div>
);
