import React from "react";
import ImageUpload from "../../../common/ImageUpload";

export default class EventWebPageSettings extends React.PureComponent {
  render() {
    const maxItineraryDescriptionLength = 200;
    const {
      event: {
        videoURL,
        imageURL,
        tripItineraryHighlights = [],
        tripGalleryImages = [],
      },
      onChange,
      onChangeEvt,
    } = this.props;
    const onItineraryChange = (name, index, value) => {
      const newHighlights = tripItineraryHighlights.map((h, i) =>
        i === index ? { ...h, [name]: value } : h
      );
      onChange("tripItineraryHighlights", newHighlights);
    };

    return (
      <>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">
            Web Page Settings
          </p>
        </div>
        <div className="program-settings-page-section">
          <div className="mb-24">
            <div className="flex flex-align-center mb-8">
              <label className="accent-text-dark">Main Image</label>
              <div className="tooltip-container">
                <i className="material-icons accent-text-dark large-text ml-8">
                  info
                </i>
                <span className="tooltip">
                  This will be displayed on the public website.
                </span>
              </div>
            </div>
            <ImageUpload
              name="imageURL"
              label="Upload Logo"
              uploadedLabel="Upload Image"
              onChange={onChange}
              imageName="Image"
              imageType="trip_main_image"
              imageURL={imageURL}
              cropAspectHeight={1.5}
              cropAspectWidth={3}
              height={100}
              withCrop={true}
              displayLoader={true}
            />
          </div>

          <div className="event-settings-inputs">
            <div className="flex flex-align-center">
              <label className="input-label">
                <div className="flex flex-align-center">Video link</div>
              </label>
              <input
                className="custom-input full-width"
                name="videoURL"
                onChange={onChangeEvt}
                value={videoURL}
              />
            </div>
          </div>
        </div>
        <div className="program-application-form-section mb-24">
          <div className="mb-16">
            <div className="flex mobile-flex-justify-space flex-align-center mt-16">
              <span className="medium-text fw-700 ">Itinerary Highlights</span>
            </div>
            <div
              className="flex flex-align-center mt-8 link-text mb-16"
              onClick={() => {
                const newHighlights = [
                  ...tripItineraryHighlights,
                  { dayNumber: "", title: "", description: "" },
                ];
                onChange("tripItineraryHighlights", newHighlights);
              }}
            >
              <i className="material-icons profile-add-icon mr-8">add_circle</i>
              Add highlight
            </div>
            <div className="program-settings-page-section">
              {tripItineraryHighlights?.map((highlight, index) => (
                <tr className="flex mobile-flex-justify-space flex-align-center mb-24">
                  <div className="full-width itinerary-settings">
                    <div className="flex flex-align-center event-settings-inputs">
                      <label className="input-label">Day</label>
                      <input
                        min="1"
                        type="number"
                        value={highlight.dayNumber}
                        name={`tripItineraryHighlights[${index}].dayNumber`}
                        className="custom-input mt-16"
                        onChange={(e) => {
                          onItineraryChange("dayNumber", index, e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex flex-align-center event-settings-inputs">
                      <label className="input-label">Title</label>
                      <input
                        type="text"
                        value={highlight.title}
                        name={`tripItineraryHighlights[${index}].title`}
                        className="custom-input mt-16"
                        onChange={(e) => {
                          onItineraryChange("title", index, e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex flex-align-center full-width">
                      <label className="input-label">Description</label>
                      <textarea
                        maxLength={maxItineraryDescriptionLength}
                        value={highlight.description}
                        name={`tripItineraryHighlights[${index}].description`}
                        className="custom-input mt-16"
                        onChange={(e) => {
                          onItineraryChange(
                            "description",
                            index,
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <td>
                    <i
                      className="material-icons link-text hover-delete ml-16 mt-16"
                      style={{ fontSize: 20 }}
                      onClick={() => {
                        const newHighlights = [...tripItineraryHighlights];
                        newHighlights.splice(index, 1);
                        onChange("tripItineraryHighlights", newHighlights);
                      }}
                    >
                      delete
                    </i>
                  </td>
                </tr>
              ))}
            </div>
          </div>
          <div className="event-settings-inputs mb-16">
            <div className="flex mobile-flex-justify-space flex-align-center mt-16">
              <span className="medium-text fw-700 mb-8">Image Gallery</span>
              <ImageUpload
                name="tripGalleryImages"
                onChange={(name, value) => {
                  onChange(
                    name,
                    tripGalleryImages
                      ? [...tripGalleryImages, { url: value }]
                      : [{ url: value }]
                  );
                }}
                imageType="trip_gallery"
                cropAspectHeight={1}
                cropAspectWidth={1.5}
                withCrop={true}
                hideImage={true}
                hideLabel={true}
                displayLoader={true}
                imageIcon={
                  <i className="material-icons profile-add-icon ml-8">
                    add_circle
                  </i>
                }
              />
            </div>
            <div className="flex flex-align-center image-upload">
              {tripGalleryImages?.map((img) => (
                <div
                  key={img.url}
                  className="trip-image mr-16 mb-12"
                >
                  <img
                    src={img.url}
                    alt="trip-gallery-item"
                  />
                  <p
                    className="close-img pointer"
                    onClick={() => {
                      onChange(
                        "tripGalleryImages",
                        tripGalleryImages.filter((i) => i.url !== img.url)
                      );
                    }}
                  >
                    x
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}
