import { asEnum } from "../lib";

export const EventTypes = asEnum({
  IsraeLinksAttendance: 0,
  IsraeLinksRegistration: 1,
  SecuritySurvey: 2,
  ShabbatonAttendance: 3,
  RelatedNewsStory: 4,
  ReceivedTefillinBankSubsidy: 5,
  ReceivedChayenuSubsidy: 6,
  SubmittedMonthlySnapshot: 7,
  SubmittedProspectProfileAndEngagementForm: 8,
  SubmittedCampaignSuspectsForm: 9,
  SubmittedTopTenDonorList: 10,
  LamplightersParticipation: 11,
  KinusAttendance: 12,
  EstablishedRelationshipWithLocalLawEnforcement: 13,
  SecurityRelatedIncident: 14,
  ReceivedDepartmentOfHomelandSecurityGrant: 15,
  SecurityInfrastructureSurvey: 16,
  SecurityTrainingSurvey: 17,
  RaffleParticipation: 18,
  ReceivedMezuzah: 19,
  ReceivedLamdeiniTravelGrant: 20,
  ManagingLamplighters: 21,
  ManagingShabbaton: 22,
  ManagingTefillin: 23,
  ManagingChayenu: 24,
  ManagingRaffle: 25,
  ManagingIsraeLinks: 26,
  ManagingKinus: 27,
  ManagingSeedBuildingGrant: 28,
  ManagingLamdeini: 29,
  ManagingSSS: 30,
  AddNote: 31,
  CustomEvent: 1000,
  DateCustomEvent: 1001,
  CurrencyCustomEvent: 1002,
  StringCustomEvent: 1003,
});

export const EventCategories = asEnum({
  Shabbaton: 0,
  IsraeLinks: 1,
  Tefillin: 2,
  Chayenu: 3,
  SSS: 4,
  Lamdeini: 5,
  YourIsrael: 6,
  Lamplighters: 7,
  AIPAC: 8,
  FundraisingBootcamp: 9,
  SeedBuildingGrant: 10,
  MediaAndPr: 11,
  Raffle: 12,
  Kinus: 13,
  LifeInsurance: 14,
  Notes: 15,
  Security: 16,
  Mezuzah: 17,
  CustomEvent: 1000,
  DateCustomEvent: 1001,
  CurrencyCustomEvent: 1002,
  StringCustomEvent: 1003,
});

export const EventCategoriesDescriptions = {
  [EventCategories.Shabbaton]: "Shabbaton",
  [EventCategories.IsraeLinks]: "IsraeLinks",
  [EventCategories.Tefillin]: "Tefillin",
  [EventCategories.Chayenu]: "Chayenu",
  [EventCategories.SSS]: "SSS",
  [EventCategories.Lamdeini]: "Lamdeini",
  [EventCategories.YourIsrael]: "Your Israel",
  [EventCategories.Lamplighters]: "Lamplighters",
  [EventCategories.AIPAC]: "AIPAC",
  [EventCategories.FundraisingBootcamp]: "Fundraising Bootcamp",
  [EventCategories.SeedBuildingGrant]: "Seed/Building Grant",
  [EventCategories.MediaAndPr]: "Media & PR",
  [EventCategories.Raffle]: "Raffle",
  [EventCategories.Kinus]: "Kinus",
  [EventCategories.LifeInsurance]: "Life Insurance",
  [EventCategories.Notes]: "Notes",
  [EventCategories.Security]: "Security",
  [EventCategories.Mezuzah]: "Mezuzah",
  [EventCategories.CustomEvent]: "Custom Event",
  [EventCategories.DateCustomEvent]: "Date Custom Event",
  [EventCategories.StringCustomEvent]: "String Custom Event",
  [EventCategories.CurrencyCustomEvent]: "Currency Custom Event",
};

// export const ArrayOfEventTypes = Object.keys(EventTypes).map(et => EventTypes[et]);
// export const ArrayOfEventCategories = Object.keys(EventCategories).map(ec => EventCategories[ec]);
