import React from "react";
import PaginatedTable from "../../../shared/PaginatedTable";
import RaffleEnrollmentActions from "./RaffleEnrollmentActions";
import RaffleEnrollmentFilters from "./RaffleEnrollmentFilters";
import RaffleEnrollmentRecord from "./RaffleEnrollmentRecord";

import RafflesApi from "../../../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import moment from "moment";

export default class RaffleEnrollments extends React.PureComponent {
  state = {
    enrollments: [],
    errorMessage: "",
    filters: {
      enrolled: true,
      keyword: "",
      onlyWithTickets: false,
    },
    loading: false,
    page: 1,
    results: 12,
    sortBy: "shliachLastName",
    success: true,
    totalEnrollments: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterEnrollments = (filters, sortBy = "shliachLastName") => {
    this.setState(
      {
        filters,
        sortBy,
      },
      () => {
        this.getEnrollments();
      },
    );
  };

  getEnrollments = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        RafflesApi.getEnrollments(
          this.apiSignal.token,
          this.props.raffleId,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ enrollments, totalEnrollments }) => {
            this.setState({
              enrollments,
              loading: false,
              totalEnrollments,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                enrollments: [],
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
                totalEnrollments: 0,
              });
            }
          });
      },
    );
  };

  getEnrollmentsForExport = async (exportType) => {
    const { sortBy, filters } = this.state;

    const enrollmentsForExport = await RafflesApi.getEnrollments(
      this.apiSignal.token,
      this.props.raffleId,
      undefined,
      undefined,
      sortBy,
      filters,
      exportType,
    );

    return enrollmentsForExport.enrollments;
  };

  getSortOptions = (raffleFeesPaid) => [
    { id: "campusName", name: "Campus" },
    { id: "chabadHouseName", name: "Chabad House" },
    {
      id: "cociOwes",
      name: `COCI Owe${raffleFeesPaid ? "d" : "s"}`,
      enrolledOnly: true,
    },
    { id: "shliachLastName", name: "Shliach Name" },
    { id: "shluchaLastName", name: "Shlucha Name" },
    {
      id: "chabadHouseOwes",
      name: `Shliach Owe${raffleFeesPaid ? "d" : "s"}`,
      enrolledOnly: true,
    },
    { id: "totalTicketsSold", name: "Tickets", enrolledOnly: true },
  ];

  render() {
    const { mobileMode, raffleEndDate, raffleProgramScheduleId, raffleTitle } =
      this.props;

    const {
      enrollments,
      filters,
      loading,
      page,
      results,
      sortBy,
      success,
      totalEnrollments,
    } = this.state;

    const raffleFeesPaid = moment().isAfter(
      moment(raffleEndDate).add(2, "days"),
    );
    const sortOptions = this.getSortOptions(raffleFeesPaid);

    return (
      <div className="card raffle-details-card">
        <div className="flex flex-justify-space flex-align-center mb-24 all-shluchim-header">
          <p className="xxl-text fw-500">All Shluchim</p>
          <RaffleEnrollmentActions
            filters={filters}
            getEnrollmentsForExport={this.getEnrollmentsForExport}
            raffleFeesPaid={raffleFeesPaid}
            raffleTitle={raffleTitle}
          />
        </div>

        <PaginatedTable
          filterComponent={
            <RaffleEnrollmentFilters
              filterEnrollments={this.filterEnrollments}
              sortBy={sortBy}
              sortOptions={sortOptions}
            />
          }
          loading={loading}
          loadData={this.getEnrollments}
          mobileMode={mobileMode}
          page={page}
          records={enrollments}
          renderHeader={() => (
            <div className="raffle-details-table-header raffle-details-shluchim-table-row">
              <p>Shliach</p>
              <p>Shlucha</p>
              <p>Chabad House</p>
              <p>Campus</p>
              <p>Tickets</p>
              <p>COCI Owe{raffleFeesPaid ? "d" : "s"}</p>
              <p>Shliach Owe{raffleFeesPaid ? "d" : "s"}</p>
            </div>
          )}
          renderRow={(enrollment, index) => (
            <RaffleEnrollmentRecord
              enrolled={filters.enrolled}
              enrollment={enrollment}
              key={index}
              raffleProgramScheduleId={raffleProgramScheduleId}
            />
          )}
          results={results}
          sortBy={sortBy}
          sortOptions={
            filters.enrolled
              ? sortOptions
              : sortOptions.filter((option) => !option.enrolledOnly)
          }
          success={success}
          totalCount={totalEnrollments}
        />
      </div>
    );
  }
}
