import React from "react";
import SelectSearch from "react-select";
import debounce from "lodash.debounce";

export default class EnrollmentsTableFilters extends React.PureComponent {
  state = {
    keyword: "",
    statuses: [],
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => {
    const { keyword, statuses } = this.state;

    this.props.applyFilters({
      keyword,
      statuses: statuses.map((s) => s.value),
    });
  };

  onFilterDebounce = debounce(this.onFilter, 1000);

  render() {
    const { allStatuses } = this.props;

    const { keyword, statuses } = this.state;

    return (
      <div className="flex flex-align-center flex-justify-space">
        <div className="flex flex-align-center life-insurance-enrollment-table-filter-inputs">
          <div className="search-input">
            <input
              type="text"
              placeholder="Search by Shliach or Shlucha"
              name="keyword"
              onChange={(event) =>
                this.onChange(event.target.name, event.target.value, true)
              }
              value={keyword}
            />
            <i className="material-icons accent-text-secondary flex flex-align-center">
              search
            </i>
          </div>
          <div className="ml-24 flex flex-align-center">
            <p>Filter by</p>
            <SelectSearch
              className="filter-by-select"
              isClearable={true}
              multi={true}
              isSearchable={true}
              onChange={(val) => this.onChange("statuses", val)}
              options={
                allStatuses &&
                allStatuses.map((status) => ({
                  value: status.enumValue,
                  label: status.displayValue,
                }))
              }
              placeholder="All Statuses"
              value={statuses}
            />
          </div>
        </div>
      </div>
    );
  }
}
