import React from "react";
import ConfirmationModal from "../../../../../shared/ConfirmationModal";
import PaginatedTable from "../../../../../shared/PaginatedTable";
import StudentRewardsTableHeader from "./StudentRewardsTableHeader";
import StudentRewardsTableRow from "./StudentRewardsTableRow";

import EduApi from "../../../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../../../lib/coc-common-scripts";
import axios from "axios";
import { formatNumber } from "../../../../../../lib";

export default class StudentRewardsTable extends React.PureComponent {
  state = {
    approveRejectRewardRequest: {
      action: null,
      errorMessage: "",
      loading: false,
      rewardRequest: null,
    },
    errorMessage: "",
    loading: false,
    page: 1,
    results: 12,
    rewardRequests: [],
    showApproveRejectRewardRequestConfirmationModal: false,
    success: true,
    totalRewardRequests: 0,
  };

  actionTypes = {
    Approve: "approve",
    Reject: "reject",
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getRewardRequests = (page = 1, results = this.state.results) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        success: true,
      },
      () => {
        const {
          eduStudent: { eduProgramID, eduStudentEnrollmentID },
        } = this.props;

        EduApi.getEduProgramRewardRequests(
          this.apiSignal.token,
          eduProgramID,
          page,
          results,
          undefined,
          {
            eduStudentEnrollmentId: eduStudentEnrollmentID,
          },
        )
          .then(({ rewardRequests, totalRewardRequests }) => {
            this.setState({
              loading: false,
              rewardRequests,
              totalRewardRequests,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                rewardRequests: [],
                success: false,
                totalRewardRequests: 0,
              });
            }
          });
      },
    );
  };

  toggleApproveRejectRewardRequest = (rewardRequest, approve) => {
    this.setState({
      approveRejectRewardRequest: {
        action:
          approve === true
            ? this.actionTypes.Approve
            : approve === false
            ? this.actionTypes.Reject
            : null,
        errorMessage: "",
        loading: false,
        rewardRequest: rewardRequest || null,
      },
      showApproveRejectRewardRequestConfirmationModal:
        !this.state.showApproveRejectRewardRequestConfirmationModal,
    });
  };

  approveRejectRewardRequest = async () => {
    const { approveRejectRewardRequest } = this.state;

    this.setState({
      approveRejectRewardRequest: {
        ...approveRejectRewardRequest,
        errorMessage: "",
        loading: true,
      },
    });

    try {
      const { rewardRequest } = approveRejectRewardRequest;
      const updatedRewardRequest = {
        id: rewardRequest.id,
        eduRewardID: rewardRequest.eduRewardID,
        eduStudentEnrollmentID: rewardRequest.eduStudentEnrollmentID,
        //  BE only updates status field, no need to set other irrelevant fields for post
        status:
          approveRejectRewardRequest.action === this.actionTypes.Approve
            ? "Approved"
            : "Rejected",
      };

      await EduApi.submitEduStudentRewardRequest(
        this.apiSignal.token,
        this.props.eduStudent.eduProgramID,
        updatedRewardRequest,
      );
    } catch (err) {
      this.setState({
        approveRejectRewardRequest: {
          ...approveRejectRewardRequest,
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        },
      });
      return;
    }

    if (approveRejectRewardRequest.action === this.actionTypes.Reject) {
      this.props.refreshEduStudent(); //refresh student avail credits info after rejecting a credit
    }
    this.getRewardRequests(this.state.page, this.state.results);
    this.toggleApproveRejectRewardRequest();
  };

  render() {
    const {
      eduStudent: { numOfCreditsAvailable, numOfCreditsReceived },
      viewOnly,
    } = this.props;

    const {
      approveRejectRewardRequest = {},
      loading,
      page,
      results,
      rewardRequests,
      showApproveRejectRewardRequestConfirmationModal,
      success,
      totalRewardRequests,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center mb-24">
          <p className="fw-500 xxl-text mb-24">Reward History</p>
          <div className="flex">
            <p className="medium-text">
              <span className="fw-700">Total Credits</span>{" "}
              {formatNumber(numOfCreditsReceived)}
            </p>
            <p className="medium-text ml-24">
              <span className="fw-700">Available Credits</span>{" "}
              {formatNumber(numOfCreditsAvailable)}
            </p>
          </div>
        </div>
        <PaginatedTable
          loading={loading}
          loadData={this.getRewardRequests}
          page={page}
          records={rewardRequests}
          renderHeader={() => <StudentRewardsTableHeader />}
          renderRow={(rewardRequest, index) => (
            <StudentRewardsTableRow
              key={index}
              onApproveRejectRewardRequest={
                this.toggleApproveRejectRewardRequest
              }
              rewardRequest={rewardRequest}
              viewOnly={viewOnly}
            />
          )}
          results={results}
          showResultsCount={true}
          success={success}
          totalCount={totalRewardRequests}
        />

        <ConfirmationModal
          cancel={this.toggleApproveRejectRewardRequest}
          confirm={this.approveRejectRewardRequest}
          errorMessage={approveRejectRewardRequest.errorMessage}
          loading={approveRejectRewardRequest.loading}
          message={<>
            Are you sure you want to {
              approveRejectRewardRequest.action
            } this request{
              approveRejectRewardRequest.rewardRequest &&
              approveRejectRewardRequest.rewardRequest.rewardName
                ? ` for ${approveRejectRewardRequest.rewardRequest.rewardName} reward`
                : ""
            }?
            <div style={{ fontSize: "14px", marginTop: "16px" }}>
              {approveRejectRewardRequest.action === this.actionTypes.Approve
                ? "Please note that students are notified of reward approvals."
                : "Please note that students are not notified of reward rejections."}
            </div>
          </>}
          show={showApproveRejectRewardRequestConfirmationModal}
        />
      </div>
    );
  }
}
