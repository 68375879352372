import React from "react";
import { browserHistory, Link } from "react-router";

import LamplightersCard from "./details/LamplightersCard";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import Dashboard from "./details/Dashboard";
import ShluchimTable from "./details/shluchim/ShluchimTable";
import StudentsTable from "./details/students/StudentsTable";
import { Unauthorized } from "../../lib/coc-common-components";

import LamplightersApi from "../../services/resources/LamplightersApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import queryString from "query-string";
import axios from "axios";

export default class LamplightersPage extends React.PureComponent {
  constructor(props) {
    super(props);

    const {
      location: {
        query: { tab },
      },
    } = props;
    const tabs = [
      { name: "Dashboard", id: "dashboard", component: Dashboard },
      { name: "Shluchim", id: "shluchim", component: ShluchimTable },
      { name: "Students", id: "students", component: StudentsTable },
    ];
    const tabIndex = tabs.map((tab) => tab.id).indexOf(tab);

    this.state = {
      authorized: true,

      errorMessage: "",
      loading: true,

      lamplightersSchedule: null,
      lamplightersList: [],

      shluchimList: {
        errorMessage: "",
        loading: true,
        list: [],
      },

      systemLists: {
        countries: [],
      },
      systemListsErrorMessage: "",

      tabIndex: tabIndex >= 0 ? tabIndex : 0,
      tabs,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.GrantsFullView)) {
      this.setState({ authorized: false });
    }

    const {
      params: { scheduleId },
    } = this.props;

    this.getLamplightersSchedule(scheduleId, true);
    this.getSystemLists();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { scheduleId },
      location: {
        query: { tab },
      },
    } = this.props;

    //handle schedule param update
    if (scheduleId !== prevProps.params.scheduleId) {
      this.getLamplightersSchedule(
        scheduleId,
        !this.state.lamplightersList.length, // fetch lamplighters list if none currently
        true,
      );
    }
    //handle tab update via router link
    else if (tab !== prevProps.location.query.tab) {
      const { tabs, tabIndex } = this.state;
      const index = tabs.findIndex((t) => t.id === tab);
      if (index >= 0 && index !== tabIndex) {
        this.setState({ tabIndex: index });
      }
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getLamplightersSchedule = async (
    programScheduleId,
    fetchLamplightersList = false,
    isOnChange = false,
    isRefresh = false,
  ) => {
    try {
      if (!isRefresh) {
        this.setState({
          errorMessage: "",
          loading: true,
        });
      }

      //update route imed (so that lamplighters schedule dropdown is updated)
      const {
        location: {
          query: { tab },
          search,
        },
        params: { scheduleId: prevProgramScheduleId },
      } = this.props;
      if (
        programScheduleId &&
        programScheduleId.toString() !== prevProgramScheduleId
      ) {
        const queryUpdate = isOnChange ? (tab ? `?tab=${tab}` : "") : search; //clear out filters from search query on manual schedule change
        browserHistory.replace(
          `/lamplighters/${programScheduleId}${queryUpdate}`,
        );
      }

      let lamplightersList = [...this.state.lamplightersList];
      if (fetchLamplightersList) {
        lamplightersList = await LamplightersApi.getLamplightersSchedules(
          this.apiSignal.token,
        );
        if (lamplightersList.length) {
          this.setState({ lamplightersList });
        }
      }

      if (!lamplightersList.length) {
        this.setState({
          errorMessage: "404",
          loading: false,
        });
        return;
      }

      let lamplightersProgramScheduleId = programScheduleId;
      //when no program sched is specified default to retrieve first program schedule and update url
      if (!lamplightersProgramScheduleId) {
        lamplightersProgramScheduleId = lamplightersList[0].programScheduleID;
        browserHistory.replace(
          `/lamplighters/${lamplightersProgramScheduleId}${search || ""}`,
        );
      }

      const lamplightersSchedule =
        await LamplightersApi.getLamplightersSchedule(
          this.apiSignal.token,
          lamplightersProgramScheduleId,
        );

      this.setState({
        loading: false,
        lamplightersSchedule,
      });

      if (!isRefresh) {
        this.getShluchimList(lamplightersSchedule.id);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        const is404 = (err.response && err.response.status) === 404;
        this.setState({
          errorMessage: is404
            ? "404"
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong and we could not retrieve Lamplighters details. Please try again.",
              ),
          loading: false,
        });
      }
    }
  };

  refreshLamplightersSchedule = async () => {
    await this.getLamplightersSchedule(
      this.state.lamplightersSchedule.programScheduleID,
      false,
      false,
      true,
    );
  };

  getShluchimList = async (scheduleId) => {
    this.setState({
      shluchimList: {
        errorMessage: "",
        loading: false,
        list: [],
      },
    });

    try {
      const shluchimList = await LamplightersApi.getLocationsShluchim(
        this.apiSignal.token,
        scheduleId,
      );
      this.setState({
        shluchimList: {
          errorMessage: "",
          loading: false,
          list: shluchimList,
        },
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          shluchimList: {
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
            list: [],
          },
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists(["grantStatuses"]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  toTab = (index, preserveQuery) => {
    const {
      location: { pathname, query },
    } = this.props;
    const { tabIndex, tabs } = this.state;

    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      const queryParams = {
        ...(preserveQuery ? query : {}),
        tab: tabs[index].id,
      };
      browserHistory.replace(
        `${pathname}?${queryString.stringify(queryParams)}`,
      );
    }
  };

  render() {
    const {
      location,
      mobileMode,
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      errorMessage,
      loading,
      lamplightersSchedule,
      lamplightersList,
      shluchimList,
      systemLists,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const readOnly = !AuthService.UserHasClaim(PermissionClaims.GrantsFullEdit);

    return (
      <div className="lamplighters-page page container">
        <ProgramBreadcrumbsHeader scheduleId={scheduleId} />
        {!lamplightersSchedule && loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage ? (
          errorMessage === "404" ? (
            <div className="text-center mt-32">
              <p>
                No Lamplighters grant has been configured
                {scheduleId ? " for this program schedule" : ""}
              </p>
              {!!scheduleId && (
                <Link
                  className="btn btn-info mt-16"
                  to={`/lamplighters/settings/${scheduleId}`}
                >
                  Configure Advanced Settings
                </Link>
              )}
            </div>
          ) : (
            <div className="full-page-error-text error-text">
              <img src="/img/error.svg" alt="error robot" height="240" />
              <p>{errorMessage}</p>
            </div>
          )
        ) : (
          !!lamplightersSchedule && (
            <React.Fragment>
              <LamplightersCard
                lamplightersSchedule={lamplightersSchedule}
                lamplightersList={lamplightersList}
                onChangeLamplightersSchedule={(schedId) =>
                  this.getLamplightersSchedule(schedId, false, true)
                }
                scheduleId={scheduleId}
              />

              {loading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <div
                    className="lamplighters-details-tabs flex"
                    style={{ marginLeft: "2px" }}
                  >
                    {tabs.map((tab, index) => (
                      <p
                        key={index}
                        className={`large-text ${index > 0 ? "ml-40" : ""} ${
                          tabIndex === index ? "active" : ""
                        }`}
                        onClick={() => this.toTab(index)}
                      >
                        {tab.name}
                      </p>
                    ))}
                  </div>

                  <div className="card lamplighters-card">
                    {React.createElement(tabs[tabIndex].component, {
                      location,
                      mobileMode,
                      readOnly,
                      refreshSchedule: this.refreshLamplightersSchedule,
                      schedule: lamplightersSchedule,
                      shluchimList,
                      ...systemLists,
                    })}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
