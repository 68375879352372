import React from "react";
// import moment from "moment";
import DatePicker from "react-datepicker";
import "../../assets/stylesheets/components/datepicker.min.css";

// interface DateRangePickerProps {
//   startDate: moment.Moment;
//   endDate: moment.Moment;
//   startDateField?: string;
//   endDateField?: string;
//   handleDateInputChange: (field: string) => void;
// }

const DateRangePicker = ({
  startDate,
  endDate,
  handleDateInputChange,
  startDateField = "startDate",
  endDateField = "endDate",
}) => (
  <div style={{ display: "flex", flexFlow: "row wrap", alignItems: "center" }}>
    <DatePicker
      id="startDate"
      className="form-control"
      selected={startDate}
      showMonthDropdown={true}
      showYearDropdown={true}
      dropdownMode="select"
      onChange={handleDateInputChange(startDateField)}
    />
    <span style={{ margin: "0 1em" }}>to</span>
    <DatePicker
      id="endDate"
      className="form-control"
      selected={endDate}
      showMonthDropdown={true}
      showYearDropdown={true}
      dropdownMode="select"
      onChange={handleDateInputChange(endDateField)}
    />
  </div>
);

export default DateRangePicker;
