import {
  TravelTripTypes,
  TripAcceptancePolicyOptions,
  TripRegistrationStatuses,
  TripRegistrationWorkflowSteps,
  TripTypes,
} from "./TripEventConsts";

export function getRequiredEventFields(event) {
  let incompleteFieldsByTrack = {};
  let incompleteFieldsMessage = "";

  const { isTravelTrip, tracks } = event;

  tracks.forEach((track, index) => {
    const incompleteFields = {
      settings: [],
      shluchimControls: [],
      pricing: [],
      tours: [],
      webpage: [],
      terms: [],
    };

    //TRACK OVERRIDE FIELDS:
    const {
      earlyBirdStudentDeadline,
      earlyBirdStudentPrice,
      regionIDs,
      regularStudentPrice,
      studentDepositAmount,
      trackName,
    } = track;

    if (!trackName) incompleteFields.settings.push("Track Name");

    if (regionIDs?.length) {
      const hasOverlappingRegions = tracks.find(
        (t) => t !== track && t.regionIDs?.some((r) => regionIDs.includes(r)),
      );
      if (hasOverlappingRegions)
        incompleteFields.settings.push(
          "Region restriction cannot overlap with another track",
        );
    } else if (isTravelTrip && index > 0)
      incompleteFields.settings.push("Region restriction");

    if (!regularStudentPrice && regularStudentPrice !== 0)
      incompleteFields.pricing.push("Students Trip Fee");
    if (earlyBirdStudentDeadline || earlyBirdStudentPrice) {
      if (!earlyBirdStudentPrice)
        incompleteFields.pricing.push("Students Early Bird Trip Fee");
      if (!earlyBirdStudentDeadline)
        incompleteFields.pricing.push("Students Early Bird Ends");
    }
    if (isTravelTrip && !studentDepositAmount)
      incompleteFields.pricing.push("Students Deposit Amount");

    //PRIMARY TRACK FIELDS:
    if (index === 0) {
      //SETTINGS
      const {
        latestCancellationDate,
        rewardCreditValue,
        shluchimEnrollmentStart,
        shluchimEnrollmentEnd,
        studentRegistrationStartDate,
        studentRegistrationEndDate,
        tripLocation,
        tripStartDate,
        tripEndDate,
        type,
      } = event;

      const isJewishUTrip = type === TripTypes.JewishU;
      const isLivingLinksTrip = type === TripTypes.LivingLinks;

      if (!type) incompleteFields.settings.push("Event Type");
      if (!tripStartDate)
        incompleteFields.settings.push(
          "Event Start Date (Update in Program Page)",
        );
      if (!tripEndDate)
        incompleteFields.settings.push(
          "Event End Date (Update in Program Page)",
        );
      if (!shluchimEnrollmentStart)
        incompleteFields.settings.push(
          "Shluchim Enrollment Start (Update in Program Page)",
        );
      if (!shluchimEnrollmentEnd)
        incompleteFields.settings.push(
          "Shluchim Enrollment End (Update in Program Page)",
        );
      if (!studentRegistrationStartDate)
        incompleteFields.settings.push("Student Registration Start");
      if (!studentRegistrationEndDate)
        incompleteFields.settings.push("Student Registration End");
      if (!latestCancellationDate)
        incompleteFields.settings.push("Latest Cancellation Date");
      if (isLivingLinksTrip && !tripLocation)
        incompleteFields.settings.push("Trip Location");

      //SHLUCHIM CONTROLS
      const { adminAcceptancePolicyOption, defaultShliachAcceptancePolicy } =
        event;

      if (
        adminAcceptancePolicyOption ===
          TripAcceptancePolicyOptions.ShluchimCanChoose &&
        !defaultShliachAcceptancePolicy
      )
        incompleteFields.shluchimControls.push(
          "Shluchim Acceptance Process Default",
        );

      //PRICING
      const { promoCodes, shliachPrice } = event;

      //Note - should never have this because don't allow adding incomplete promo codes even for draft
      if (
        promoCodes.some((promoCode) => {
          const { appliesTo, code, discountAmount } = promoCode;
          return !appliesTo || !code || !discountAmount;
        })
      ) {
        incompleteFields.pricing.push("Incomplete promo code(s)");
      }

      if (!isTravelTrip && !shliachPrice && shliachPrice !== 0) {
        incompleteFields.pricing.push("Shluchim Price");
      }

      if (isJewishUTrip) {
        if (!rewardCreditValue) {
          incompleteFields.pricing.push("Credit Value");
        } else if (
          promoCodes.some(
            (promoCode) => promoCode.discountAmount % rewardCreditValue !== 0,
          )
        ) {
          incompleteFields.pricing.push(
            "Promo code(s) discount amount must be evenly divisible by the credit value",
          );
        }
        if (promoCodes.length > 1) {
          incompleteFields.pricing.push("Only a single promo code is allowed");
        }
      }

      //TOURS
      const { tours } = event;

      tours.forEach((tour) => {
        const {
          autoEnrollStudents,
          durationHours,
          durationMinutes,
          isPriceIncludedInTripPrice,
          name,
          price,
          schedules,
        } = tour;

        if (!name) incompleteFields.tours.push("Untitled Tour Name");
        else if (name.toLowerCase().includes("ohel")) {
          if (!autoEnrollStudents) {
            incompleteFields.tours.push(
              `${name} must be set to auto-enroll students`,
            );
          }
          if (!isPriceIncludedInTripPrice) {
            incompleteFields.tours.push(
              `${name} price must be included in the trip fee`,
            );
          }
        } else if (!isPriceIncludedInTripPrice && !price)
          incompleteFields.tours.push(`${name || "Untitled"} Tour Price`);
        if (!durationHours && !durationMinutes)
          incompleteFields.tours.push(`${name || "Untitled"} Tour Duration`);
        if (!schedules || !schedules.length) {
          incompleteFields.tours.push(
            `${name || "Untitled"} - At least one schedule is required`,
          );
        }

        //Note - should never have this because don't allow adding incomplete schedules even for draft
        if (
          schedules.some((schedule) => {
            const { name, time } = schedule;
            return !name || !time;
          })
        ) {
          incompleteFields.tours.push(
            `${name || "Untitled"} Incomplete tour schedule(s)`,
          );
        }
      });

      //WEBPAGE
      const { tripItineraryHighlights } = event;

      if (isJewishUTrip) {
        if (!tripItineraryHighlights.length) {
          incompleteFields.webpage.push("Trip Itinerary");
        } else if (
          tripItineraryHighlights.some(
            (highlight) => !Number(highlight.dayNumber),
          )
        ) {
          incompleteFields.webpage.push("Incomplete itinerary highlight(s)");
        }
      }

      //TERMS
      const { termsAndConditions } = event;

      //Note - should never have this because don't allow adding incomplete terms even for draft
      if (
        termsAndConditions.some((terms) => {
          const { text, title, type } = terms;
          return !type || !text || (type === "LongText" && !title);
        })
      ) {
        incompleteFields.terms.push("Incomplete terms and conditions");
      }
    }

    //Create incompleteFieldsMessage Appended message & incompleteFieldsByTrack Hierarchy
    const appendIncompleteFieldsText = (fields, title) =>
      (incompleteFieldsMessage += `\n${title}:\n` + fields.join("\n"));

    if (
      Object.keys(incompleteFields).some(
        (fieldKey) => incompleteFields[fieldKey].length,
      )
    ) {
      //track has incomplete fields

      const trackKey = `${trackName || "Untitled"}${
        index > 0 ? ` [Track #${index + 1}]` : ""
      }`;
      incompleteFieldsMessage += trackKey;

      const incompleteTrackFields = {};

      if (incompleteFields.settings.length) {
        incompleteTrackFields["General Settings"] = incompleteFields.settings;
        appendIncompleteFieldsText(
          incompleteFields.settings,
          "General Settings",
        );
      }
      if (incompleteFields.shluchimControls.length) {
        incompleteTrackFields["Shluchim Controls"] =
          incompleteFields.shluchimControls;
        appendIncompleteFieldsText(
          incompleteFields.shluchimControls,
          "Shluchim Controls",
        );
      }
      if (incompleteFields.pricing.length) {
        incompleteTrackFields["Pricing"] = incompleteFields.pricing;
        appendIncompleteFieldsText(incompleteFields.pricing, "Pricing");
      }
      if (incompleteFields.tours.length) {
        incompleteTrackFields["Tours"] = incompleteFields.tours;
        appendIncompleteFieldsText(incompleteFields.tours, "Tours");
      }
      if (incompleteFields.webpage.length) {
        incompleteTrackFields["Web Page Settings"] = incompleteFields.webpage;
        appendIncompleteFieldsText(
          incompleteFields.webpage,
          "Web Page Settings",
        );
      }
      if (incompleteFields.terms.length) {
        incompleteTrackFields["Terms & Conditions"] = incompleteFields.terms;
        appendIncompleteFieldsText(
          incompleteFields.terms,
          "Terms & Conditions",
        );
      }

      if (Object.keys(incompleteTrackFields).length) {
        incompleteFieldsByTrack[trackKey] = incompleteTrackFields;
      }
    }
  });

  return {
    incompleteFieldsByTrack,
    incompleteFieldsMessage,
    isIncomplete: incompleteFieldsMessage.length > 0,
  };
}

export function isTravelTripType(tripType) {
  return TravelTripTypes.includes(tripType);
}

export function getRegistrationStatusColor(status) {
  switch (status) {
    case TripRegistrationStatuses.Rejected:
    case TripRegistrationStatuses.Cancelled:
      return "#F36464";
    case TripRegistrationStatuses.Accepted:
      return "#63C674";
    case TripRegistrationStatuses.CheckedIn:
      return "#6369D1";
    default:
      return "#F3893D";
  }
}

export function getTripRegistrationStatusesAndWorkflowSteps(
  isTravelTrip,
  statuses,
  workflowSteps,
) {
  if (!isTravelTrip) {
    return (statuses || []).filter(
      (s) => s.enumValue !== TripRegistrationStatuses.Waitlisted,
    );
  }

  return [
    ...(workflowSteps || []).filter(
      (s) => s.enumValue !== TripRegistrationWorkflowSteps.Processed,
    ),
    ...(statuses || []).filter(
      (s) =>
        s.enumValue !== TripRegistrationStatuses.Pending &&
        s.enumValue !== TripRegistrationStatuses.CheckedIn,
    ),
  ];
}

export function getTravelTripRegistrationStatusWorkflowDisplay(registration) {
  const { status, statusDisplay, workflowStepDisplay } = registration;

  switch (status) {
    case TripRegistrationStatuses.Pending:
      return workflowStepDisplay;
    default:
      return statusDisplay;
  }
}
