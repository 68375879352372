import React, { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../../../../common/Loader";
import ProgramBreadcrumbsHeader from "../../../../common/ProgramBreadcrumbsHeader";
import RegistrationDetails from "./RegistrationDetails";

import TripEventsApi from "../../../../../services/resources/TripEventsApi";
import AuthService, {
  PermissionClaims,
} from "../../../../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import { Unauthorized } from "../../../../../lib/coc-common-components";
import axios from "axios";

function StudentRegistrationPage(props) {
  const {
    params: { registrationId, scheduleId },
    route,
    router,
  } = props;

  const [authorized] = useState(
    AuthService.UserHasClaim(PermissionClaims.TripDataView)
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const [tripEvent, setTripEvent] = useState();
  const [shliachEnrollment, setShliachEnrollment] = useState();
  const [studentRegistration, setStudentRegistration] = useState();

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const getTripAndRegistrationDetails = useCallback(async () => {
    setLoading(true);

    try {
      const tripEvent = await TripEventsApi.getTripEvent(
        apiSignalRef.current.token,
        scheduleId
      );

      const [shliachEnrollment, studentRegistration] = await Promise.all([
        TripEventsApi.getTripEnrollmentByStudent(
          apiSignalRef.current.token,
          tripEvent.id,
          registrationId
        ),
        TripEventsApi.getTripRegistration(
          apiSignalRef.current.token,
          registrationId
        ),
      ]);

      setTripEvent(tripEvent);
      setShliachEnrollment(shliachEnrollment);
      setStudentRegistration(studentRegistration);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setErrorMessage(
          ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve Student Registration settings. Please try again."
          )
        );
      }
    }

    setLoading(false);
  }, [registrationId, scheduleId]);

  useEffect(() => {
    getTripAndRegistrationDetails();
  }, [getTripAndRegistrationDetails]);

  if (!authorized) {
    return <Unauthorized userName={AuthService.getCurrentUser().name} />;
  }

  const readOnlyAccess = !AuthService.UserHasClaim(
    PermissionClaims.TripDataEdit
  );

  return (
    <div className="page trip-page">
      <ProgramBreadcrumbsHeader
        getPrevPages={(sched) => [
          {
            path: `/trips/${sched.scheduleID}?tab=students`,
            title: sched.scheduleName,
          },
        ]}
        pageTitle="Student Registration"
        scheduleId={scheduleId}
        style={{ paddingLeft: "15px" }}
      />
      {loading ? (
        <div className="full-page-loader">
          <Loader />
        </div>
      ) : errorMessage || !studentRegistration ? (
        <div className="full-page-error-text error-text">
          <img src="/img/error.svg" alt="error robot" height="240" />
          <p>
            {errorMessage ||
              "Sorry, something went wrong and we could not retrieve Student Registration settings. Please try again."}
          </p>
        </div>
      ) : (
        <div className="container">
          <RegistrationDetails
            programScheduleId={scheduleId}
            readOnlyAccess={readOnlyAccess}
            registration={studentRegistration}
            route={route}
            router={router}
            shliachEnrollment={shliachEnrollment}
            tripEvent={tripEvent}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(StudentRegistrationPage);
