import React from "react";
import Autocomplete from "react-autocomplete";
import { formatFullName } from "../../../../lib";

import LamplightersApi from "../../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";

export default class StudentsTableSearch extends React.PureComponent {
  constructor(props) {
    super();

    this.state = {
      errorMessage: "",
      filteredStudents: [],
      loading: false,
      searchText: props.searchText,
      selectedStudentId: props.studentId,
      students: [],
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getAutocompleteStudents();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getAutocompleteStudents = async () => {
    this.setState({
      errorMessage: "",
      filteredStudents: [],
      loading: true,
      students: [],
    });

    try {
      const students = await LamplightersApi.getStudentsBasic(
        this.apiSignal.token,
        this.props.scheduleId,
      );

      const { searchText, selectedStudentId } = this.state;
      this.setState({
        errorMessage: "",
        filteredStudents: this.getFilteredStudents(
          students,
          searchText,
          selectedStudentId,
        ),
        loading: false,
        students: students,
      });
    } catch (err) {
      this.setState({
        errorMessage: ApiCallErrorMessageHandler(err),
        filteredStudents: [],
        loading: false,
        students: [],
      });
    }
  };

  renderSearchItem = (item, isHighlighted) => {
    const { isLamplightersActiveOnlyList } = this.props;
    return (
      <div
        key={item.studentID}
        style={{
          background: isHighlighted ? "#f5f5f5" : "white",
          cursor: "pointer",
          padding: "8px 16px",
        }}
      >
        {formatFullName(item.studentFirstName, item.studentLastName)}
        {isLamplightersActiveOnlyList && !item.isEnrolled ? (
          <span className="accent-text italic-text ml-4">Inactive</span>
        ) : (
          ""
        )}
      </div>
    );
  };

  renderSearchMenu = (items) => {
    return (
      <div className="autocomplete-menu">
        {items}
        {items.length >= 150 && (
          <p
            className="accent-text small-text italic-text"
            style={{ background: "#fff", padding: "8px 16px" }}
          >
            Max 150 results shown
          </p>
        )}
      </div>
    );
  };

  renderSearchInput = (props) => {
    const { errorMessage, loading } = this.state;

    return (
      <React.Fragment>
        <div className="search-input">
          <input
            name="se"
            placeholder={loading ? "Loading Students..." : "Search Students"}
            type="text"
            {...props}
          />
          <i
            className="material-icons accent-text-secondary pointer"
            onClick={this.submitSearch}
          >
            search
          </i>
        </div>
        {errorMessage && (
          <p className="error-text small-text">{errorMessage}</p>
        )}
      </React.Fragment>
    );
  };

  getFilteredStudents = (students, searchText, studentId) => {
    return studentId
      ? students.filter((s) => s.studentID.toString() === studentId.toString())
      : searchText && searchText.length > 1
      ? students.filter(
          (s) =>
            formatFullName(s.studentFirstName, s.studentLastName)
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) >= 0,
        )
      : [];
  };

  onSearch = (searchText) => {
    this.setState({ searchText, selectedStudentId: null });

    this.setState({
      filteredStudents: this.getFilteredStudents(
        this.state.students,
        searchText,
      ),
    });
  };

  submitSearch = () =>
    this.props.onChange("keyword", this.state.searchText, false, {
      studentId: undefined,
    });

  onSelectStudent = (studentId) => {
    const student = this.state.filteredStudents.find(
      (s) => s.studentID === studentId,
    );
    if (!student) {
      return;
    }

    const { isLamplightersActiveOnlyList, onChange } = this.props;
    const resetListActiveOnly =
      isLamplightersActiveOnlyList && !student.isEnrolled;

    this.setState({
      filteredStudents: this.getFilteredStudents(
        this.state.filteredStudents,
        "",
        studentId,
      ),
      searchText: "",
      selectedStudentId: studentId,
    });

    onChange("studentId", studentId, false, {
      keyword: undefined,
      ...(resetListActiveOnly ? { includeInactive: true } : {}),
    });
  };

  render() {
    const { filteredStudents, searchText, selectedStudentId } = this.state;

    let inputValue;
    if (selectedStudentId) {
      const student = filteredStudents.find(
        (s) => s.studentID.toString() === selectedStudentId.toString(),
      );
      if (student) {
        inputValue = formatFullName(
          student.studentFirstName,
          student.studentLastName,
        );
      }
    } else {
      inputValue = searchText;
    }

    return (
      <Autocomplete
        autoHighlight={false}
        getItemValue={(s) => s.studentID}
        onChange={(e) => this.onSearch(e.target.value)}
        onSelect={this.onSelectStudent}
        inputProps={{
          onKeyDown: (e) => {
            if (e.key === "Enter") this.submitSearch();
          },
        }}
        items={filteredStudents.slice(0, 150)} //render max 150 results at a time
        renderInput={this.renderSearchInput}
        renderMenu={this.renderSearchMenu}
        renderItem={this.renderSearchItem}
        value={inputValue}
        wrapperProps={{ className: "search-input-container" }}
      />
    );
  }
}
