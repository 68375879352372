import React from "react";
import SystemApi from "../../../../services/resources/SystemApi";
import axios from "axios";

export default class RaffleOrderFilters extends React.PureComponent {
  state = { paymentTypes: [], cashTypes: [] };

  apiSignal = axios.CancelToken.source();

  componentDidMount = async () => {
    const systemLists = await SystemApi.lists(["cashTypes", "paymentTypes"]);
    const { cashTypes = [], paymentTypes = [] } = systemLists;
    this.setState({ paymentTypes, cashTypes });
  };

  render() {
    const {
      enrollment,
      filters: { association, keyword, paymentType, referrerID },
      onChange,
      systemRaffleDonorAssociations,
    } = this.props;
    const { paymentTypes, cashTypes } = this.state;

    const paymentOptions = [
      { name: "All Payments", id: "" },
      ...paymentTypes.map((type) => ({
        name: type.displayValue,
        id: type.enumValue,
      })),
      ...cashTypes.map((type) => ({
        name: type.name,
        id: `cash_${type.id}`,
      })),
    ];

    const referrerOptions = enrollment && [
      { name: "All Teams", id: "" },
      ...enrollment.referrers,
    ];

    const associationOptions = [
      { name: "All Associations", id: "" },
      ...systemRaffleDonorAssociations.map((assoc) => ({
        name: assoc.displayValue,
        id: assoc.enumValue,
      })),
    ];

    return (
      <div>
        <div className="search-input">
          <input
            type="text"
            placeholder="Search Name, Order #, or Ticket #"
            name="keyword"
            onChange={(event) =>
              onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        <div className="mt-8 flex flex-align-center">
          <div
            className="flex flex-align-center"
            // style={{ width: "204px" }}
          >
            <p className="mr-8 nowrap-text">Filter by</p>
            <select
              className="no-border-select accent-text-secondary ml-8"
              onChange={(e) => onChange("paymentType", e.target.value)}
              name="paymentType"
              value={paymentType}
              style={{ maxWidth: "150px" }}
            >
              {paymentOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {enrollment && (
            <div
              className="flex flex-align-center ml-16 mobile-ml-0"
              // style={{ width: "204px" }}
            >
              <p className="mr-8 nowrap-text">Filter by</p>
              <select
                className="no-border-select accent-text-secondary ml-8"
                onChange={(e) => onChange("referrerID", e.target.value)}
                name="referrerID"
                value={referrerID}
              >
                {referrerOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div
            className="flex flex-align-center ml-16 mobile-ml-0"
            // style={{ width: "204px" }}
          >
            <p className="mr-8 nowrap-text">Filter by</p>
            <select
              className="no-border-select accent-text-secondary ml-8"
              onChange={(e) => onChange("association", e.target.value)}
              name="association"
              value={association}
            >
              {associationOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }
}
