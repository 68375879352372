import React from "react";
import { Link } from "react-router";
import BeneficiaryDesignationDocumentUpload from "./BeneficiaryDesignationDocumentUpload";
import EnrollmentStatusTag from "./EnrollmentStatusTag";
import TerminationReasonEditModal from "./TerminationReasonEditModal";
import moment from "moment";
import { formatFullName } from "../../../lib";

export default class EnrollmentDetails extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    showTerminationReasonEditModal: false,
  };

  editTerminationReason = async (terminationReason) => {
    this.setState({
      errorMessage: "",
      loading: true,
    });

    const { errorMessage } = await this.props.updateEnrollment(
      "terminationReason",
      terminationReason,
    );

    this.setState(
      {
        errorMessage,
        loading: false,
      },
      () => {
        if (!errorMessage) {
          this.setState({
            showTerminationReasonEditModal: false,
          });
        }
      },
    );
  };

  render() {
    const {
      enrollment,
      enrollment: {
        shliach: enrollmentShliach,
        shlucha: enrollmentShlucha,
        enrollmentDate,
        lastRenewalDate,
        nextRenewalDate,
        status,
        statusDisplay,
        terminationDate,
        terminationReason,
      },
      enrollmentStatuses,
      updateEnrollment,
    } = this.props;

    const {
      beneficiaryDesignationDocumentURL:
        shliachBeneficiaryDesignationDocumentURL,
      dob: shliachDob,
      email: shliachEmail,
      fullName: shliachFullName,
      isEnrolled: isShliachEnrolled,
      legalFirstName: shliachLegalFirstName,
      legalMiddleName: shliachLegalMiddleName,
      legalLastName: shliachLegalLastName,
      phone: shliachPhone,
      shliachID: shliachId,
    } = { ...enrollmentShliach };
    const {
      beneficiaryDesignationDocumentURL:
        shluchaBeneficiaryDesignationDocumentURL,
      dob: shluchaDob,
      email: shluchaEmail,
      fullName: shluchaFullName,
      isEnrolled: isShluchaEnrolled,
      legalFirstName: shluchaLegalFirstName,
      legalMiddleName: shluchaLegalMiddleName,
      legalLastName: shluchaLegalLastName,
      phone: shluchaPhone,
      shliachID: shluchaId,
    } = { ...enrollmentShlucha };

    const { errorMessage, loading, showTerminationReasonEditModal } =
      this.state;

    return (
      <div className="card">
        <div className="mb-24">
          <EnrollmentStatusTag
            enrollmentStatuses={enrollmentStatuses}
            status={status}
            statusDisplay={statusDisplay}
          />
        </div>
        <div className="two-column-grid">
          {isShliachEnrolled && (
            <div>
              <div className="flex flex-align-center xl-text fw-700">
                <p>{shliachFullName}</p>
                <Link
                  className="fw-700 link-text ml-16 flex flex-align-center"
                  to={`/shluchim/${shliachId}`}
                >
                  <i className="material-icons">open_in_new</i>
                </Link>
              </div>

              <div className="mt-16" style={{ gridGap: "0" }}>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Legal Name
                  </p>
                  <p>
                    {formatFullName(
                      shliachLegalFirstName,
                      shliachLegalLastName,
                      shliachLegalMiddleName,
                    )}
                  </p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Date of Birth
                  </p>
                  <p>
                    {shliachDob ? moment(shliachDob).format("MM/DD/YYYY") : ""}
                  </p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Phone
                  </p>
                  <p>{shliachPhone}</p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Email
                  </p>
                  <p>
                    {shliachEmail ? (
                      <a className="link-text" href={`mailto:${shliachEmail}`}>
                        {shliachEmail}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
          {isShluchaEnrolled && (
            <div>
              <div className="flex flex-align-center xl-text fw-700">
                <p>{shluchaFullName}</p>
                <Link
                  className="fw-700 link-text ml-16 flex flex-align-center"
                  to={`/shluchim/${shluchaId}`}
                >
                  <i className="material-icons">open_in_new</i>
                </Link>
              </div>

              <div className="mt-16" style={{ gridGap: "0" }}>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Legal Name
                  </p>
                  <p>
                    {formatFullName(
                      shluchaLegalFirstName,
                      shluchaLegalLastName,
                      shluchaLegalMiddleName,
                    )}
                  </p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Date of Birth
                  </p>
                  <p>
                    {shluchaDob ? moment(shluchaDob).format("MM/DD/YYYY") : ""}
                  </p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Phone
                  </p>
                  <p>{shluchaPhone}</p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Email
                  </p>
                  <p>
                    {shluchaEmail ? (
                      <a className="link-text" href={`mailto:${shluchaEmail}`}>
                        {shluchaEmail}
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <hr />

        <div>
          <div className="flex flex-align-center mb-8">
            <p className="fw-700" style={{ width: "144px" }}>
              Date Joined
            </p>
            <p>
              {enrollmentDate ? moment(enrollmentDate).format("MM/DD/YY") : ""}
            </p>
          </div>
          <div className="flex flex-align-center mb-8">
            <p className="fw-700" style={{ width: "144px" }}>
              Last Renewal
            </p>
            <p>
              {lastRenewalDate
                ? moment(lastRenewalDate).format("MM/DD/YY")
                : "N/A"}
            </p>
          </div>
          <div className="flex flex-align-center mb-8">
            <p
              className={`fw-700 ${
                status === enrollmentStatuses.FailedRenewal ? "error-text" : ""
              }`}
              style={{ width: "144px" }}
            >
              Next Renewal
            </p>
            <p
              className={
                status === enrollmentStatuses.FailedRenewal ? "error-text" : ""
              }
            >
              {nextRenewalDate
                ? moment(nextRenewalDate).format("MM/DD/YY")
                : "N/A"}
            </p>
          </div>
        </div>
        <hr />

        <div className="two-column-grid beneficiary-designation-forms">
          {isShliachEnrolled && (
            <BeneficiaryDesignationDocumentUpload
              documentURL={shliachBeneficiaryDesignationDocumentURL}
              documentName="shliach.beneficiaryDesignationDocumentURL"
              enrollment={enrollment}
              title="Shliach Beneficiary Designation Form"
              updateEnrollment={updateEnrollment}
            />
          )}
          {isShluchaEnrolled && (
            <BeneficiaryDesignationDocumentUpload
              documentURL={shluchaBeneficiaryDesignationDocumentURL}
              documentName="shlucha.beneficiaryDesignationDocumentURL"
              enrollment={enrollment}
              title="Shlucha Beneficiary Designation Form"
              updateEnrollment={updateEnrollment}
            />
          )}
        </div>

        {status === enrollmentStatuses.Terminated && (
          <React.Fragment>
            <hr />
            <div>
              <p>Terminated on {moment(terminationDate).format("MM/DD/YY")}</p>
              <div className="flex flex-align-center">
                <p>{terminationReason}</p>
                <i
                  className="material-icons large-text link-text ml-8"
                  onClick={() =>
                    this.setState({
                      showTerminationReasonEditModal: true,
                    })
                  }
                >
                  edit
                </i>
              </div>
            </div>

            <TerminationReasonEditModal
              close={() =>
                this.setState({
                  errorMessage: "",
                  showTerminationReasonEditModal: false,
                })
              }
              errorMessage={errorMessage}
              loading={loading}
              save={this.editTerminationReason}
              show={showTerminationReasonEditModal}
              terminationReason={terminationReason}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
