/** @file View helper functions. */

import dprop from "dot-prop-immutable";
/** Returns the attribute value of the given attribute name on the given el.
 * @param {DOMElement} el
 * @param {string} name
 * @param {any} [defaultValue]
 */
export function attribVal(el, name, defaultValue) {
  let attrib = el.attributes[name];
  if (attrib) {
    return attrib.value;
  }
  return defaultValue;
}
/** Returns the attributes of the element or its ancestors, whichever contains
 * an attribute by the given name. Returns undefined if none found.
 * @param {HTMLElement} el
 * @param {string} name
 * @returns {NamedNodeMap}
 */
export function closestAttribs(el, name) {
  while (el) {
    let attribs = el.attributes;
    if (attribs[name]) {
      return attribs;
    }
    el = el.parentElement;
  }
  return undefined;
}
/** Returns the value of the element attribute or the elements ancestor
 * attribute, whichever contains an attribute by the given name. Returns
 * undefined if none found.
 * @param {HTMLElement} el
 * @param {string} name
 * @returns {string}
 */
export function closestAttribVal(el, name) {
  while (el) {
    let attrib = el.attributes[name];
    if (attrib) {
      return attrib.value;
    }
    el = el.parentElement;
  }
  return undefined;
}
/** Enables a message to be shown (on desktop browsers) to warn the user
 * before unloading (or navigating away from) the window. Returns a function
 * to be called to disable the message and remove the `beforeunload` handler.
 */
export function confirmWindowUnload(message) {
  /**
   * Handle the window `beforeunload` event.
   * @param {Event} e
   */
  function doConfirmWindowUnload(e) {
    (e || window.event).returnValue = message;
    return message;
  }
  window.addEventListener("beforeunload", doConfirmWindowUnload);
  /** Stop handling teh window `beforeunload` event. */
  function cancelWindowUnloadHandler() {
    window.removeEventListener("beforeunload", doConfirmWindowUnload);
    message = undefined;
  }
  return cancelWindowUnloadHandler;
}
/** Deletes a state property of the given component.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field. Use dots for nesting.
 */
export function deleteStateOf(component, path) {
  component.setState((state) => dprop.delete(state, path));
}
/** Returns a handler function that passes the enter key press to a handler. */
export function enterKeyPress(handler, ...args) {
  if (!handler) {
    handler = noop;
  }
  return function handlingEnterKeyPress(e) {
    if (e.key === "Enter") {
      handler(...args);
    }
  };
}
/** Gets the first parent element that is scrollable. */
export function getScrollParent(node) {
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement && window.getComputedStyle(node).overflowY;
  const isScrollable = overflowY !== "visible" && overflowY !== "hidden";

  if (!node) {
    return null;
  } else if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  return getScrollParent(node.parentNode) || document.body;
}
/**
 * Returns a function that evaluates the given state field for truthiness
 * and returns one of two values.
 * @param {React.Component} component The component.
 * @param {string} name Name of the field in `component.state` to evaluate.
 * @param {} [whenTruthy] Value to return when the state field is truthy.
 * @param {} [whenFalsey] Value to return when the state field is falsey.
 * @returns {} Either the `whenTruthy` or `whenFalsey` param.
 */
export function ifState(component, name, whenTruthy, whenFalsey) {
  function stateIf() {
    const state = component.state;
    if (!state || !state[name]) return whenFalsey;
    return whenTruthy;
  }
  return stateIf;
}
/** @param {React.Component} component */
export function inputStateMap(component) {
  /** @param {React.SyntheticEvent} e */
  return function inputChange(e) {
    e = e || { target: {} };
    const { target } = e;
    const statePropToChange = attribVal(target, "data-change");
    const eventValueProp = attribVal(target, "data-value") || "target.value";
    let value = dprop.get(e, eventValueProp);
    if (value === undefined || value === null) {
      value = attribVal(target, "data-default", value);
    }
    setStateOf(component, statePropToChange, value);
  };
}
/** Returns an input `onChange` handler that calls `component.setState`,
 * setting the `state[path]` field when the input value changes.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field to change. Use dots for nesting.
 * @param {string} [valuePath] Path to the event object field to get. Use dots
 * for nesting. Default: `'target.value'`.
 * @param {any} [defaultValue] The default value. Default: `undefined`
 */
export function inputStateOf(
  component,
  path,
  valuePath = "target.value",
  defaultValue,
) {
  return function inputChanged(e) {
    if (!e) {
      setStateOf(component, path, defaultValue);
    } else {
      const value = dprop.get(e, valuePath);
      if (value === undefined || value === null) {
        setStateOf(component, path, defaultValue);
      } else {
        setStateOf(component, path, value);
      }
    }
  };
}
/** No operation. Empty function. */
function noop() {}
/**
 * Returns a function that calls `e.preventDefault()` before calling the
 * given handler, where `e` is the argument of the outer function. This is
 * for dealing with any component that has default behavior which must be
 * cancelled, such as handling the click event of a hyperlink (`HTML:a`).
 * @param {function} handler The inner handler to be called.
 * @returns {function} The outer function/event handler.
 * @example onClickLink = ViewHelper.preventDefault(function() { doStuff(); });
 */
export function preventDefault(handler, ...args) {
  if (!handler) {
    handler = noop;
  }
  function handlePreventDefault(e) {
    e.preventDefault();
    handler(...args);
  }
  return handlePreventDefault;
}
/** Sets a state property of the given component.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field. Use dots for nesting.
 * @param {any} value Value to assign.
 * @param {function} [callback] Called after the state is changed.
 */
export function setStateOf(component, path, value, callback) {
  component.setState((state) => dprop.set(state, path, value), callback);
}
/** Sets a state property of the given component asynchronously.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field. Use dots for nesting.
 * @param {any} value Value to assign.
 * @param {function} [callback] Called after the state is changed.
 */
export function setStateOfAsync(component, path, value) {
  return new Promise((resolve) => setStateOf(component, path, value, resolve));
}

/** Returns a function to set a state property of the given component.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field. Use dots for nesting.
 */
export function stateSetter(component, path) {
  return function settingState(value) {
    setStateOf(component, path, value);
  };
}
/**
 * Returns a function that passes the `e.target.value` to the given handler,
 * where `e` is the argument of the outer function. This is mainly for
 * dealing with `FormControl` from 'react-bootstrap' and other such
 * components.
 * @param {function} handler The function to receive the target value.
 * @returns {function} The outer function/event handler.
 * @example
 * onChangeUserName = ViewHelper.targetValue(function(userName) {
 *     this.state.userName = userName
 * });
 */
export function targetValue(handler) {
  function handleTargetValue(e) {
    const value = e.target.value;
    handler(value);
  }
  return handleTargetValue;
}
/** Toggles a boolean state property of the given component.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field. Use dots for nesting.
 */
export function toggleStateOf(component, path) {
  component.setState((state) => dprop.toggle(state, path));
}
/** Returns a function that sets a component state property to the given value
 * whenever it's called. Useful for creating buttons that change state when
 * clicked.
 * @param {React.Component} component The component.
 * @param {string} path Path to the state field. Use dots for nesting.
 */
export function triggerStateOf(component, path, value) {
  return function triggeredState() {
    setStateOf(component, path, value);
  };
}
