import AuthClient from "./AuthClient";
import {
  FileUploader,
  createUniqueNameForFile,
  trimTrailingSlash,
} from "../../lib";

/** @enum {string} */
const ImageType = {
  program_banner: { path: "program_banner" },
  program_content: { path: "program_content" },
  raffle_campaign: { path: "raffle_campaign" },
  raffle_banner: { path: "raffle_banner" },
  raffle_prize: { path: "raffle_prize" },
  rsvp_system_banner: { path: "rsvp_system_banner" },
  edu_program_banner: { path: "edu_program_banner" },
  edu_program_course: { path: "edu_program_course" },
  trip_main_image: { path: "trip_main_image" },
  trip_gallery: { path: "trip_gallery" },
  // Types used by the Shluchim portal:
  // family: { path: 'family' },
  // house: { path: 'house' },
  // house_logo: { path: 'house_logo' },
  // profile: { path: 'profile' },
  // school_logo: { path: 'school_logo' },
};

/** @enum {string} */
const DocType = {
  life_insurance_bdf: { path: "life_insurance_bdf" },
};

const ContentApi = {
  /** @param {File} file
   * @param {FileType} fileType
   */
  async uploadFile(file, fileType, isSecure, config = {}) {
    fileType = ("" + fileType).trim();
    const fileTypeInfo = ImageType[fileType] || DocType[fileType];
    if (!fileTypeInfo) {
      throw new Error(`FileType not found: ${fileType}`);
    }
    let {
      data: {
        payload: {
          blobContainerURI,
          // container,
          sas: sasQueryString,
        },
      },
    } = await AuthClient.get(`/Storage/sas${isSecure ? "?isSecure=true" : ""}`);
    blobContainerURI = trimTrailingSlash(blobContainerURI);
    const fileName = createUniqueNameForFile(file);
    const fileURL = `${blobContainerURI}/${fileTypeInfo.path}/${fileName}`;
    const uploadURL = `${fileURL}${sasQueryString}`;
    const uploader = new FileUploader(file, uploadURL, {
      upload_headers: {
        "x-ms-blob-type": "BlockBlob",
      },
      ...config,
    });
    await uploader.start();
    return fileURL;
  },
  async getSecureBlobURL(blobURI) {
    let {
      data: {
        payload: { sas: sasQueryString },
      },
    } = await AuthClient.get(`/Storage/sas/blob?blobUri=${blobURI}`);

    const secureFileURL = `${blobURI}${sasQueryString}`;

    return secureFileURL;
  },
};

export default ContentApi;
