import React, { PureComponent } from "react";
import { Link } from "react-router";
import { ModalWithButtons } from "../../../lib/coc-common-components";

// import { School } from "../../../models/School";
import DataTable /* , { DataTableColumn } */ from "../../common/DataTable";
import { SchoolMainShluchim } from "../Utils";
import SchoolsApi from "../../../services/resources/SchoolsApi";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";

// interface SchoolListProps {
//   schools: Array<School>;
// }
export class SchoolList extends PureComponent {
  state = {
    displayDeleteRestore: false,
    selectedSchoolInfo: {},
    schoolId: null,
    isActive: false,
    schools: [],
    loading: true,
    displayConfirm: "",
  };

  componentDidMount = () => {
    const schools = this.props.schools;
    this.setState({ schools });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.schools !== this.props.schools)
      this.setState({ schools: this.props.schools });
  };

  handleDeleteRestore = () => {
    this.setState(
      {
        displayDeleteRestore: "",
        loading: true,
      },
      async () => {
        if (this.state.isActive) {
          this.handleDelete(this.state.schoolId);
        } else {
          this.handleRestore(this.state.schoolId);
        }
      },
    );
  };

  handleDelete = () => {
    let selectedSchoolInfoActive = this.state.selectedSchoolInfo;

    let schoolList = this.state.schools;
    schoolList.find((c) => c.id === selectedSchoolInfoActive.id)["isActive"] =
      "False";

    SchoolsApi.delete(this.state.schoolId)
      .then((response) => {
        this.props.handleFiltersChanged(this.props.currentFilters);
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err);
        this.setState({ loading: false, displayError: errors.join("\n") });
      });
  };

  handleRestore = () => {
    let selectedSchoolInfoActive = this.state.selectedSchoolInfo;

    let schoolList = this.state.schools;
    schoolList.find((c) => c.id === selectedSchoolInfoActive.id)["isActive"] =
      "True";

    SchoolsApi.updateStatus({
      id: selectedSchoolInfoActive.id,
    })
      .then((response) => {
        this.props.handleFiltersChanged(this.props.currentFilters);
      })
      .catch((err) => {
        const errors = ApiCallErrorHandler(err);
        this.setState({ loading: false, displayError: errors.join("\n") });
      });
  };

  confirmDeleteRestoreButtons = () => {
    /** @type {Array<{ text: string; className: string; onClick }>} */
    const buttons = [];
    buttons.push({
      text: `I understand. ${
        this.state.isActive ? "Delete" : "Restore"
      } it now.`,
      className: "btn btn-danger",
      onClick: () => this.handleDeleteRestore(),
    });

    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDeleteRestore: "" }),
    });
    return buttons;
  };

  columns = [
    {
      Header: "School",
      accessor: "name",
      Cell: (c) => (
        <Link
          to={{
            pathname: `/schools/${c.original.id}`,
            data: this.state.isActive,
          }}
          style={{ cursor: "pointer" }}
        >
          {c.original.name}
        </Link>
      ),
    },
    {
      Header: "Shliach",
      id: "shliachName",
      accessor: (s) => {
        const shluchim = SchoolMainShluchim(s);
        return shluchim.shliach
          ? shluchim.shliach.lastName + " " + shluchim.shliach.firstName
          : "-";
      },
      Cell: (c) => {
        const shluchim = SchoolMainShluchim(c.original);
        return shluchim.shliach
          ? shluchim.shliach.firstName + " " + shluchim.shliach.lastName
          : "-";
      },
    },
    {
      Header: "Shlucha",
      id: "shluchaName",
      accessor: (s) => {
        const shluchim = SchoolMainShluchim(s);
        return shluchim.shlucha
          ? shluchim.shlucha.lastName + " " + shluchim.shlucha.firstName
          : "-";
      },
      Cell: (c) => {
        const shluchim = SchoolMainShluchim(c.original);
        return shluchim.shlucha
          ? shluchim.shlucha.firstName + " " + shluchim.shlucha.lastName
          : "-";
      },
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (c) => {
        return c.original.isActive === "True" ? (
          <div className="flex" style={{ paddingLeft: "24px" }}>
            {" "}
            <p className="relative active">Active</p>
            <i
              className="material-icons contacts-delete ml-8"
              onClick={() =>
                this.setState({
                  displayDeleteRestore: `You are about to delete this School`,
                  selectedSchoolInfo: c.original,
                  schoolId: c.original.id,
                  isActive: true,
                })
              }
            >
              delete
            </i>
          </div>
        ) : (
          <div className="flex" style={{ paddingLeft: "24px" }}>
            {" "}
            <p className="relative inactive">Archived</p>
            <i
              className="material-icons contacts-delete ml-8"
              onClick={() =>
                this.setState({
                  displayDeleteRestore: `You are about to restore this School `,
                  selectedSchoolInfo: c.original,
                  schoolId: c.original.id,
                  isActive: false,
                })
              }
            >
              restore_from_trash
            </i>
          </div>
        );
      },
    },
  ];
  /** @param {School} a
   * @param {School} b
   * @returns {number}
   */
  sortSchools = (a, b) => (a.name < b.name ? -1 : 1);

  render() {
    return (
      <div className="col-sm-12">
        <DataTable
          fullClientSide={true}
          columns={this.columns}
          reportData={this.state.schools.sort(this.sortSchools)}
        />
        {this.state.displayDeleteRestore && (
          <ModalWithButtons
            buttons={this.confirmDeleteRestoreButtons()}
            text={this.state.displayDeleteRestore} // TODO: Currently does not do anything. Success message should show up
          />
        )}
      </div>
    );
  }
}
