import React from "react";
import { browserHistory } from "react-router";
import SuggestionCard from "./SuggestionCard";
import SuggestionsTableHeader from "./SuggestionsTableHeader";

import axios from "axios";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { Unauthorized } from "../../../lib/coc-common-components";
import AuthService, { PermissionClaims } from "../../../services/AuthService";
import StudentsAPI from "../../../services/resources/StudentsApi";
import PaginatedTable from "../../shared/PaginatedTable";
import { notify } from "react-notify-toast";
import SuggestionsTableFilters from "./SuggestionsTableFilters";
import queryString from "query-string";

export default class SuggestionsPage extends React.Component {
  constructor(props) {
    super(props);

    const { pg, res, sortBy } = props.location.query;

    this.state = {
      authorized: true,
      errorMessage: "",
      loading: false,
      page: pg || 1,
      results: res || 12,
      sortBy: sortBy || "name",
      suggestions: [],
      totalSuggestions: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.StudentMerge)) {
      this.setState({ authorized: false });
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        query: { search, studentId, hasActiveGrant },
      },
    } = this.props;
    const {
      location: {
        query: {
          search: prevSearch,
          studentId: prevStudentId,
          hasActiveGrant: prevHasActiveGrant,
        },
      },
    } = prevProps;
    if (
      search !== prevSearch ||
      studentId !== prevStudentId ||
      hasActiveGrant !== prevHasActiveGrant
    ) {
      this.getDuplicatePeople();
    }
  }

  getDuplicatePeople = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    const {
      location: {
        query: {
          pg: prevPageQuery,
          res: prevResultsQuery,
          search,
          sortBy: prevSortByQuery,
          studentId,
          hasActiveGrant,
          ...query
        },
        pathname,
      },
    } = this.props;

    //update page, results, and sortBy query
    const pageQuery = page > 1 ? page : undefined;
    const resultsQuery = results?.toString() !== "12" ? results : undefined;
    const sortByQuery = sortBy !== "name" ? sortBy : undefined;
    if (
      pageQuery !== prevPageQuery ||
      resultsQuery !== prevResultsQuery ||
      sortByQuery !== prevSortByQuery
    ) {
      const queryUpdate = {
        ...query,
        pg: pageQuery,
        res: resultsQuery,
        sortBy: sortByQuery,
        studentId,
        search,
        hasActiveGrant,
      };
      browserHistory.replace(
        `${pathname}?${queryString.stringify(queryUpdate)}`,
      );
    }

    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        StudentsAPI.getDuplicatePeople(
          this.apiSignal.token,
          page,
          results,
          sortBy,
          search,
          studentId,
          hasActiveGrant,
        )
          .then(({ duplicates, totalDuplicates }) => {
            this.setState({
              suggestions: duplicates,
              loading: false,
              totalSuggestions: totalDuplicates,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                suggestions: [],
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
                totalSuggestions: 0,
              });
            }
          });
      },
    );
  };

  markAsNotDuplicate = (id) => {
    const { page } = this.state;
    StudentsAPI.markNotDuplicate(id, this.apiSignal.token)
      .then(() => {
        notify.show("Students have been marked as non duplicate", "success");
        this.getDuplicatePeople(page);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
          });
        }
      });
  };

  clearStudentIdFilter = () => {
    const {
      location: {
        query: { studentId, studentName, ...query },
        pathname,
      },
    } = this.props;

    browserHistory.push(`${pathname}?${queryString.stringify(query)}`);
  };

  sortOptions = [
    { id: "name", name: "Student Name" },
    { id: "newest", name: "Newest" },
  ];

  render() {
    const {
      location: {
        query: { studentId, studentName },
      },
    } = this.props;
    const {
      authorized,
      errorMessage,
      loading,
      page,
      results,
      sortBy,
      success,
      suggestions,
      totalSuggestions,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="col-sm-12 mt-48">
        <div className="list-container">
          {errorMessage ? (
            <div className="full-page-error-text error-text">
              <img src="/img/error.svg" alt="error robot" height="240" />
              <p>{errorMessage}</p>
            </div>
          ) : (
            <div>
              <div className="merge-suggestions">
                <div className="form-header">
                  <button
                    className="merge-btn btn uppercase-text fw-500"
                    onClick={() => browserHistory.push(`/students`)}
                  >
                    Manually Select Duplicates
                  </button>
                  <p className="title">Duplicate Student Merge Suggestions</p>
                  {studentId && (
                    <p className="flex flex-align-center student-tag">
                      For Student {studentName}{" "}
                      <i
                        className="material-icons link-text xl-text ml-8"
                        onClick={this.clearStudentIdFilter}
                      >
                        close
                      </i>
                    </p>
                  )}
                </div>
                <PaginatedTable
                  filterComponent={
                    <SuggestionsTableFilters location={this.props.location} />
                  }
                  loading={loading}
                  loadData={this.getDuplicatePeople}
                  page={page}
                  records={suggestions}
                  renderHeader={() => <SuggestionsTableHeader />}
                  renderRow={(suggestion, index) => (
                    <SuggestionCard
                      key={index}
                      suggestion={suggestion}
                      markAsNotDuplicate={(id) => this.markAsNotDuplicate(id)}
                    />
                  )}
                  results={results}
                  showResultsView={true}
                  sortBy={sortBy}
                  sortOptions={this.sortOptions}
                  showResultsCount={true}
                  success={success}
                  totalCount={totalSuggestions}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
