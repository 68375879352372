import React from "react";
import { ApiCallErrorHandler } from "../../../../lib/coc-common-scripts";
import { Unauthorized, Loader } from "../../../../lib/coc-common-components";

// import { OrganizationCategory } from "../../../../models/Organization";
import OrganizationApi from "../../../../services/resources/OrganizationsApi";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import OrganizationCategoryListPage from "./OrganizationCategoryListPage";

// interface OrganizationCategoryListPageContainerProps { }

// interface OrganizationCategoryListPageContainerState {
//   categories: Array<OrganizationCategory>;
//   loading: boolean;
//   displayError: string;
// }

class OrganizationCategoryListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: true,
      displayError: "",
    };
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return;
    }

    this.setState({ loading: true }, () =>
      OrganizationApi.listCategories()
        .then((response) => {
          this.setState({
            categories: response.data.results,
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryRead)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { categories } = this.state;

    return (
      <div className="col-sm-12">
        <OrganizationCategoryListPage categories={categories} />

        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default OrganizationCategoryListPageContainer;
