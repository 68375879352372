import React from "react";

export default class SuggestionsTableHeader extends React.PureComponent {
  render() {
    const columns = ["Name", "Email", "Campus", ""];

    return (
      <div className="merge-suggestions-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
