import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import Loader from "../../../common/Loader";

import TripEventsApi from "../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { pluralizeText } from "../../../../lib";
import { notify } from "react-notify-toast";
import axios from "axios";

function ShluchimBulkEnrollmentModal(props) {
  const { close, getShluchimSelection, refreshShluchim, tripEventId } = props;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [shluchim, setShluchim] = useState([]);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const shluchim = await getShluchimSelection();

      if (shluchim) {
        setShluchim(shluchim);
      } else {
        setErrorMessage("Failed to retrieve selected shluchim");
      }

      setLoading(false);
    })();
  }, [getShluchimSelection]);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    setErrorMessage("");

    try {
      await TripEventsApi.createTripEnrollments(
        apiSignalRef.current.token,
        tripEventId,
        shluchim.map((s) => s.chabadHouseID)
      );

      refreshShluchim();
      close();

      notify.show(
        `${pluralizeText("Shliach", shluchim.length, "Shluchim")} enrolled`,
        "success"
      );
    } catch (err) {
      setSubmitting(false);
      setErrorMessage(
        ApiCallErrorMessageHandler(err, "Failed to enroll selected shluchim")
      );
    }
  }, [close, refreshShluchim, shluchim, tripEventId]);

  return (
    <Modal isOpen={true} className="modal-container">
      <div className="card medium-modal trip-modal">
        <div>
          <p className="large-text mb-8">Enroll Shluchim</p>
          {loading ? (
            <Loader />
          ) : shluchim.length > 0 ? (
            <>
              <p className="accent-text-dark mb-16">
                Confirm that you'd like to enroll the following{" "}
                {shluchim.length}{" "}
                {pluralizeText("Shliach", shluchim.length, "Shluchim")}
              </p>
              <div style={{ maxHeight: "480px", overflow: "auto" }}>
                {shluchim.map((s) => (
                  <div className="mb-8" key={s.chabadHouseID}>
                    <div className="fw-700">
                      {s.shliachLastName
                        ? `${s.shliachFirstName} ${s.shliachLastName}`
                        : ""}
                      {s.shluchaLastName
                        ? `${s.shliachLastName ? " and " : ""}${
                            s.shluchaFirstName
                          } ${s.shluchaLastName}`
                        : ""}
                    </div>
                    <div className="fw-500 small-text">
                      {s.chabadHouseName ? `${s.chabadHouseName} | ` : ""}
                      {s.campusName}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            errorMessage && (
              <div className="error-text mt-4">{errorMessage}</div>
            )
          )}
        </div>
        {shluchim.length > 0 && (
          <>
            <div className="modal-btns">
              <button
                className="link-text-secondary uppercase-text"
                disabled={submitting}
                onClick={close}
              >
                Cancel
              </button>
              <button
                className={`link-text uppercase-text ml-24${
                  submitting ? " disabled" : ""
                }`}
                disabled={submitting}
                onClick={onSubmit}
              >
                {submitting ? "Enrolling..." : "Enroll"}
              </button>
            </div>
            {!submitting && errorMessage && (
              <div className="text-right error-text mt-4">
                {errorMessage}
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default React.memo(ShluchimBulkEnrollmentModal);
