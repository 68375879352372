import React from "react";

import {
  /* Contact, */ EmptyContact /* , ContactMethods */,
} from "../../../models/Contact";
import RelatedContactContactMethodsList from "./RelatedContactContactMethodsList";

// interface RelatedContactManagerProps {
//   contactData?: Contact;
//   ddStyles?: { [styleName: string]: string };
//   handleContactChange: (contact: Contact) => void;
// }

const RelatedContactManager = ({
  contactData = EmptyContact(),
  ddStyles = {},
  handleContactChange,
}) => {
  /** @param {Contact} item
   * @param {string} property
   * @param {any} newValue
   * @returns {Contact}
   */
  const updateProperty = (item, property, newValue) => ({
    ...item,
    [property]: newValue,
  });
  /** @returns {Contact} */
  const getNewState = (evt) =>
    updateProperty(
      contactData,
      evt.currentTarget.name,
      evt.currentTarget.value,
    );
  /** @param {ContactMethods} newContactMethods
   * @returns {Contact} */
  const getNewStateCM = (newContactMethods) =>
    updateProperty(contactData, "contactMethods", newContactMethods);

  const handleBasicDataChange = (evt) => handleContactChange(getNewState(evt));
  const handleContactMethodsChange = (newContactMethods) =>
    handleContactChange(getNewStateCM(newContactMethods));

  return (
    <div className="row">
      <dl className="dl-horizontal col-md-5">
        <dt>First Name</dt>
        <dd style={ddStyles}>
          <input
            type="text"
            name="firstName"
            value={contactData.firstName || ""}
            onChange={handleBasicDataChange}
            required={true}
          />
        </dd>
        <dt>Last Name</dt>
        <dd style={ddStyles}>
          <input
            type="text"
            name="lastName"
            value={contactData.lastName || ""}
            onChange={handleBasicDataChange}
            required={true}
          />
        </dd>
        <dt>Title</dt>
        <dd style={ddStyles}>
          <input
            type="text"
            name="title"
            value={contactData.title || ""}
            onChange={handleBasicDataChange}
          />
        </dd>
      </dl>
      <div className="dl-horizontal col-md-5">
        <RelatedContactContactMethodsList
          contactMethods={contactData.contactMethods || []}
          ddStyles={ddStyles}
          handleContactMethodsChange={handleContactMethodsChange}
        />
      </div>
    </div>
  );
};

export default RelatedContactManager;
