import React from "react";
import { Link } from "react-router";

export default class NotEnrolledTableRecord extends React.PureComponent {
  render() {
    const {
      notEnrolled: {
        shliachCell,
        shliachEmail,
        shliachFirstName,
        shliachID,
        shliachLastName,
        shluchaCell,
        shluchaEmail,
        shluchaFirstName,
        shluchaID,
        shluchaLastName,
      },
    } = this.props;

    return (
      <Link
        className="life-insurance-not-enrolled-table-row"
        to={`/shluchim/${shliachID || shluchaID}`}
      >
        <p>{shliachLastName || shluchaLastName}</p>
        <p>{shliachFirstName}</p>
        <p>{shluchaFirstName}</p>
        <p>{shliachCell || shluchaCell}</p>
        <p>{shliachEmail || shluchaEmail}</p>
      </Link>
    );
  }
}
