import React from "react";

export default class FadeOutErrorMessage extends React.PureComponent {
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.timeoutCallback();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  timeoutCallback = () => {
    const { timeout = 4000, onTimeout } = this.props;

    if (onTimeout) {
      setTimeout(() => {
        if (this.mounted) {
          onTimeout();
        }
      }, timeout);
    }
  };

  render() {
    const { className, message } = this.props;

    return <span className={className || "error-text"}>{message}</span>;
  }
}
