import React from "react";
import AllocationsDashboard from "./locations/AllocationsDashboard";
import LocationsTable from "./locations/LocationsTable";

import LamplightersApi from "../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class Locations extends React.PureComponent {
  state = {
    allocations: null,
    errorMessage: "",
    loading: true,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getGrantAllocations();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getGrantAllocations = () => {
    const {
      schedule: { id },
    } = this.props;

    LamplightersApi.getGrantAllocations(this.apiSignal.token, id)
      .then((allocations) => {
        this.setState({ allocations, errorMessage: "", loading: false });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          });
        }
      });
  };

  render() {
    const {
      schedule: { grants },
    } = this.props;
    const { allocations, errorMessage, loading } = this.state;

    return (
      <div className="lamplighters-settings-step">
        <div className="lamplighters-settings-step-section">
          <p className="xl-text">Locations and Allocations</p>
        </div>
        <div className="lamplighters-settings-step-section">
          <AllocationsDashboard
            allocations={allocations}
            errorMessage={errorMessage}
            grants={grants}
            loading={loading}
          />
          <LocationsTable
            {...this.props}
            allocations={allocations}
            refreshAllocations={this.getGrantAllocations}
          />
        </div>
      </div>
    );
  }
}
