import React from "react";
import Loader from "../../../common/Loader";
import { formatNumber } from "../../../../lib";

export default class AllocationsDashboard extends React.PureComponent {
  render() {
    const { errorMessage, grants, loading, allocations } = this.props;

    return (
      <div className="lamplighters-allocation-cards-grid">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text">
            Something went wrong and Allocation details could not be retrieved.
            Please refresh the page to try again.
          </p>
        ) : (
          grants &&
          grants.map((grant, index) => {
            const allocation =
              (allocations &&
                allocations.find((a) => a.grantID === grant.id)) ||
              "";
            return (
              <div className="lamplighters-allocation-card" key={index}>
                <div className="flex flex-align-center mb-16">
                  <p className="xxl-text fw-700 mr-16">
                    {formatNumber(allocation.quantity)}
                  </p>
                  <p className="small-text">Allocated {grant.name}</p>
                </div>
                <p className="error-text small-text mb-0">
                  {formatNumber(allocation.availableQuantity)} Available for
                  allocation
                </p>
              </div>
            );
          })
        )}
      </div>
    );
  }
}
