import React, { useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Button,
  Collapse,
  Typography,
  Box,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Visibility } from "@material-ui/icons";

function RegistrationRejectionConfirmationDetails(props) {
  const {
    registrationDetails: {
      campusName,
      isTravelTrip,
      programScheduleName,
      studentFirstName,
    } = {},
    sendEmail,
    toggleSendEmail,
  } = props;

  const [showEmailPreview, setShowEmailPreview] = useState(false);

  const rejectionEmailPreviewText = [
    "B”H",
    <br key="1" />,
    <br key="2" />,
    `Dear ${studentFirstName || "[Student Name]"},`,
    <br key="3" />,
    <br key="4" />,
    `Thank you for your application. We regret to inform you that we will not be able to accommodate you as part of the ${
      isTravelTrip
        ? `${
            programScheduleName || "[Program Name]"
          } trip. The trips have limited spots, and the acceptance committee looks at many factors when deciding how to prioritize applicants, including, but not limited to, region, school, age, application details, involvement in local Chabad on Campus, references, and more.`
        : `${
            campusName || "[Campus Name]"
          } group. We look forward to hosting you at a future event.`
    }`,
    <br key="5" />,
    <br key="6" />,
    `Your credit card has not been charged.`,
    <br key="7" />,
    <br key="8" />,
    "Best wishes for success,",
    <br key="9" />,
    `The ${programScheduleName || "[Program Name]"} Team`,
  ];

  return (
    <>
      <FormControlLabel
        className="trip-checkbox full-width mt-16"
        label="Notify students via email"
        control={
          <Checkbox
            checked={sendEmail}
            name="sendEmail"
            onChange={toggleSendEmail}
          />
        }
      />
      <Button
        className="preview-btn"
        disableRipple
        onClick={() => setShowEmailPreview(!showEmailPreview)}
      >
        <Visibility className="visibility-icon" />
        Preview email
        {showEmailPreview ? (
          <ExpandLess className="expand-icon" />
        ) : (
          <ExpandMore className="expand-icon" />
        )}
      </Button>
      <Collapse in={showEmailPreview}>
        <Box className="email-preview-wrapper">
          <Typography variant="body2">{rejectionEmailPreviewText}</Typography>
        </Box>
      </Collapse>
    </>
  );
}

export default React.memo(RegistrationRejectionConfirmationDetails);
