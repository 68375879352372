import AuthClient from "./AuthClient";
import queryString from "query-string";

const LamplightersApi = {
  getLamplightersSchedules: async (cancelToken) => {
    const response = await AuthClient.get("GrantSchedules", { cancelToken });
    return response.data.payload || [];
  },
  getLamplightersSchedule: async (
    cancelToken,
    programScheduleId,
    returnBlank = false,
  ) => {
    const response = await AuthClient.get(
      `GrantSchedules/byProgramSchedule/${programScheduleId}?returnBlank=${returnBlank}`,
      {
        cancelToken,
      },
    );
    return response.data.payload || {};
  },
  submitLamplightersSchedule: async (cancelToken, schedule) => {
    const response = await AuthClient.post("GrantSchedules", schedule, {
      cancelToken,
    });
    return response.data.payload || {};
  },

  getGrantAllocations: async (cancelToken, scheduleId) => {
    const response = await AuthClient.get(
      `GrantSchedules/${scheduleId}/Allocations`,
      { cancelToken },
    );
    return response.data.payload || [];
  },

  getLocations: async (
    cancelToken,
    scheduleId,
    page,
    results,
    sortByOption,
    filters = {},
    view,
    isExport,
  ) => {
    const params = {
      grantScheduleId: scheduleId,
      isExport,
      page,
      results,
      shliachId: filters.shliachId,
      sortByOption,
      text: filters.keyword,
      view,
    };
    //set sort-by-grant sort fields
    if (sortByOption && sortByOption.indexOf("-") >= 0) {
      const sortValues = sortByOption.split("-");
      params.sortByOption = sortValues[0];
      params.sortByGrantId = sortValues[1];
    }

    const response = await AuthClient.get(
      `GrantScheduleEnrollments?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return {
      locations: response.data.payload.results || [],
      totalLocations: response.data.payload.numberOfRows || 0,
    };
  },
  submitLocation: async (cancelToken, enrollment) => {
    const response = await AuthClient.post(
      "GrantScheduleEnrollments",
      enrollment,
      {
        cancelToken,
      },
    );
    return response.data.payload || {};
  },
  importLocations: async (
    cancelToken,
    scheduleId,
    importFile,
    onUploadProgress,
  ) => {
    const formData = new FormData();
    formData.append("file", importFile);

    await AuthClient.post(
      `GrantScheduleEnrollments/bulkImport?grantScheduleId=${scheduleId}`,
      formData,
      {
        cancelToken,
        "Content-Type": "multipart/form-data",
        onUploadProgress,
      },
    );
  },
  removeLocation: async (cancelToken, id) => {
    const response = await AuthClient.delete(`GrantScheduleEnrollments/${id}`, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getLocationsShluchim: async (cancelToken, scheduleId) => {
    const params = { grantScheduleID: scheduleId };

    const response = await AuthClient.get(
      `GrantScheduleEnrollments/Shluchim?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return response.data.payload || [];
  },
  getLocationAvailableGrantQuantity: async (cancelToken, id, grantId) => {
    const response = await AuthClient.get(
      `GrantScheduleEnrollments/${id}/availableGrants/${grantId}`,
      { cancelToken },
    );
    return response.data.payload;
  },
  getLocationInteractions: async (
    cancelToken,
    grantScheduleId,
    chabadHouseId,
  ) => {
    const params = {
      chabadHouseId,
      grantScheduleId,
      isExport: true,
    };
    const response = await AuthClient.get(
      `Interactions?${queryString.stringify(params)}`,
      {
        cancelToken,
      },
    );
    return (response.data.payload && response.data.payload.results) || [];
  },

  getStudents: async (
    cancelToken,
    scheduleId,
    results,
    sortByOption,
    filters = {},
    isExport,
  ) => {
    const {
      grantId,
      grantStatus,
      includeInactive,
      keyword,
      shliachId,
      studentId,
      page,
    } = filters || {};
    const params = {
      grantId,
      grantScheduleId: scheduleId,
      grantStatus,
      isEnrolled: !includeInactive,
      isExport,
      page,
      results,
      sortByOption,
      shliachId,
      studentId,
      text: studentId ? undefined : keyword, //don't include search text if studentId is selected
    };

    const response = await AuthClient.get(
      `GrantScheduleStudents?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return {
      students: response.data.payload.results || [],
      totalStudents: response.data.payload.numberOfRows || 0,
    };
  },
  getStudentsBasic: async (cancelToken, scheduleId) => {
    const response = await AuthClient.get(
      `GrantScheduleStudents/basic?grantScheduleId=${scheduleId}`,
      { cancelToken },
    );
    return response.data.payload || [];
  },
  submitStudentGrant: async (cancelToken, studentGrant) => {
    let response;
    if (!studentGrant.isEnrolled) {
      response = await AuthClient.delete(
        `GrantScheduleStudents/grant/${studentGrant.id}`,
        {
          cancelToken,
        },
      );
    } else {
      response = await AuthClient.post(
        "GrantScheduleStudents/grant",
        studentGrant,
        {
          cancelToken,
        },
      );
    }

    return response.data.payload || {};
  },
};

export default LamplightersApi;
