import React from "react";
import Loader from "../../common/Loader";
import Modal from "react-modal";

export default class TerminationReasonEditModal extends React.PureComponent {
  state = {
    terminationReason: "",
  };

  componentDidUpdate(prevProps) {
    const { show, terminationReason } = this.props;

    if (show && !prevProps.show) {
      this.setState({ terminationReason });
    }
  }

  onClose = () => {
    this.setState({ terminationReason: "" }, this.props.close);
  };

  onSave = async () => {
    await this.props.save(this.state.terminationReason);
    if (!this.props.errorMessage) {
      this.onClose();
    }
  };

  render() {
    const {
      errorMessage,
      loading,
      show,
      termationReason: initialTerminationReason,
    } = this.props;

    const { terminationReason } = this.state;

    return (
      <Modal isOpen={show} className="center-modal-container">
        <div className="modal-card card refund-modal small-modal">
          <p className="large-text fw-500">Edit Termination Reason</p>
          <div>
            <textarea
              className="custom-input"
              onChange={(event) =>
                this.setState({ terminationReason: event.target.value })
              }
              style={{
                height: "72px",
                resize: "none",
                width: "100%",
                padding: "8px",
              }}
              value={terminationReason}
            />
          </div>
          {loading ? (
            <div className="modal-btns-loader">
              <Loader />
            </div>
          ) : (
            <div className="modal-btns">
              <button
                className="btn link-text uppercase-text"
                onClick={this.onClose}
              >
                Cancel
              </button>
              <button
                className="btn link-text uppercase-text ml-24"
                disabled={terminationReason === initialTerminationReason}
                onClick={this.onSave}
              >
                Save
              </button>

              {errorMessage && (
                <span className="error-text">{errorMessage}</span>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
