import React from "react";
import Modal from "react-modal";
import DateTimePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Select from "../../../shared/Select";
import ValidatedInput from "../../../shared/ValidatedInput";
import { removeTimezoneFormatFromDate } from "../../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import moment from "moment";

export default class PromoCodeModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      submitAttempted: false,
      promoCode: {
        appliesTo: "",
        code: "",
        discountAmount: "",
        expirationDate: "",
        threshold: "",
        title: "",
      },
    };

    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    const { promoCode, show } = this.props;

    if (show && !prevProps.show) {
      const initialPromoCode = promoCode || {
        appliesTo: "",
        code: "",
        discountAmount: "",
        expirationDate: "",
        threshold: "",
        title: "",
      };

      const newState = {
        promoCode: initialPromoCode,
        submitAttempted: false,
      };
      newState.initialState = _cloneDeep(newState);

      this.setState(newState);
    }
  }

  onChangePromoCode = (name, value) => {
    this.setState({
      promoCode: {
        ...this.state.promoCode,
        [name]: value,
      },
    });
  };

  onClose = () => {
    this.props.close();
    this.setState(this.state.initialState);
  };

  onSave = () => {
    this.setState(
      {
        submitAttempted: true,
      },
      () => {
        const { save, usedCodes } = this.props;
        const {
          promoCode: { appliesTo, code, discountAmount },
        } = this.state;

        if (
          !appliesTo ||
          !code ||
          !discountAmount ||
          usedCodes.indexOf(code.trim().toLowerCase()) >= 0
        ) {
          return;
        }

        const promoToSave = { ...this.state.promoCode };
        //trim leading/trailing whitespace from codes
        if (/^\s|\s$/.test(code)) {
          //regex to check if code contains leading/trailing whitespace
          promoToSave.code = code.trim();
        }

        save(promoToSave);

        this.onClose();
      }
    );
  };

  render() {
    const {
      appliesToOptions,
      promoCode: promoCodeForEdit,
      show,
      usedCodes,
    } = this.props;

    const {
      initialState: { promoCode: initialPromoCode },
      promoCode,
      promoCode: {
        appliesTo,
        code,
        discountAmount,
        expirationDate,
        threshold,
        title,
      },
      submitAttempted,
    } = this.state;

    return (
      <Modal
        isOpen={show}
        className="new-promo-modal-container modal-container"
      >
        <div className="modal-card card new-promo-modal">
          <p className="large-text fw-500 mb-16">
            {!promoCodeForEdit ? "New" : "Edit"} Promo Code
          </p>
          <div className="flex flex-align-center mb-12">
            <label className="accent-text-dark required">Code</label>
            <ValidatedInput
              className="full-width"
              input={<input type="text" className="full-width" />}
              name="code"
              onChange={(e) =>
                this.onChangePromoCode(e.target.name, e.target.value)
              }
              required={true}
              showRequired={submitAttempted}
              showValidation={submitAttempted}
              validate={(val) =>
                val && usedCodes.indexOf(val.trim().toLowerCase()) < 0
              }
              validationMessage={
                "This code is already in use.  Promo Codes must be unique."
              }
              value={code}
            />
          </div>
          <div className="flex flex-align-center mb-12">
            <label className="accent-text-dark">Title</label>
            <input
              className="custom-input full-width"
              name="title"
              onChange={(e) =>
                this.onChangePromoCode(e.target.name, e.target.value)
              }
              type="text"
              value={title}
            />
          </div>
          <div className="flex flex-align-center mb-12">
            <label className="accent-text-dark required">For</label>
            <ValidatedInput
              className="full-width"
              input={<Select options={appliesToOptions} />}
              name="appliesTo"
              onChange={this.onChangePromoCode}
              required={true}
              showRequired={submitAttempted}
              value={appliesTo}
            />
          </div>
          <div className="flex flex-align-center mb-12">
            <label className="accent-text-dark required">Discount</label>
            <ValidatedInput
              className="full-width"
              input={
                <NumberFormat
                  className="dollar-input full-width"
                  onValueChange={({ floatValue }) =>
                    this.onChangePromoCode(
                      "discountAmount",
                      floatValue >= 0 ? floatValue : ""
                    )
                  }
                  decimalScale={2}
                  thousandSeparator={true}
                />
              }
              name="discountAmount"
              required={true}
              showRequired={submitAttempted}
              value={discountAmount}
            />
          </div>
          <div className="flex flex-align-center mb-12">
            <label className="accent-text-dark">Expiration</label>
            <DateTimePicker
              className="custom-input full-width"
              dateFormat="MM/DD/YYYY h:mm a"
              onChange={(dateTime) =>
                this.onChangePromoCode(
                  "expirationDate",
                  removeTimezoneFormatFromDate(dateTime)
                )
              }
              selected={expirationDate ? moment(expirationDate) : null}
              showTimeSelect={true}
              timeIntervals={1}
            />
          </div>
          <div className="flex flex-align-center mb-12">
            <label className="accent-text-dark">Limit</label>
            <NumberFormat
              className="custom-input limit-input"
              name="threshold"
              value={threshold}
              decimalScale={0}
              type="text"
              allowNegative={false}
              allowLeadingZeros={false}
              onValueChange={(e) => {
                if (e.floatValue === 0) {
                  this.onChangePromoCode("threshold", "");
                } else {
                  this.onChangePromoCode("threshold", e.value);
                }
              }}
            />
          </div>
          <div className="modal-btns flex flex-align-center flex-justify-end mt-16">
            <button
              className="btn link-text uppercase-text fw-500"
              onClick={this.onClose}
            >
              Cancel
            </button>
            <button
              className="btn link-text uppercase-text fw-500 ml-24"
              disabled={_isEqual(initialPromoCode, promoCode)}
              onClick={this.onSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
