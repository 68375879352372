import React, { useState } from "react";
import { browserHistory } from "react-router";
import AuthService from "../../services/AuthService";
import AuthApi from "../../services/resources/AuthApi";
import { GoogleIdentitySignin } from "./GoogleIdentitySignin";
import { getVersionedImgSrc, setSentryUser } from "../../lib";
import { useCallback } from "react";

export function Login() {
  const [errorMessage, setErrorMessage] = useState("");

  const onLogin = useCallback(function (response) {
    const googleToken = response.credential;
    AuthApi.googleLogin(googleToken)
      .then((response) => {
        localStorage.setItem("token", response.data.token);

        if (AuthService.isAuthenticated()) {
          const user = AuthService.getCurrentUser();
          setSentryUser(user.id, user.name);
        }

        browserHistory.push("/");
      })
      .catch((error) => {
        setErrorMessage(
          (error.response && error.response.data) || error.message,
        );
      });
  }, []);

  return (
    <div className="login">
      <div className="login-panel">
        <img
          className="login-logo"
          src={getVersionedImgSrc("logo.png")}
          alt=""
        />
        <div className="login-jumbo">
          <h1 className="login-header">Chabad on Campus</h1>
          <div className="login-app-name">Admin Portal</div>
        </div>
        {errorMessage && (
          <div className="alert alert-danger" role="alert">
            <strong>Login failed:</strong> {errorMessage}
          </div>
        )}
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title text-center">
              Sign in with a Google email account associated with your
              chabadoncampus.org account
            </h3>
          </div>
          <div className="panel-body">
            <GoogleIdentitySignin handleCredentialResponse={onLogin} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
