import React, { useState, useEffect, useCallback, useRef } from "react";
import { notify } from "react-notify-toast";
import { TextField, InputAdornment, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import _debounce from "lodash.debounce";
import _cloneDeep from "lodash.clonedeep";
import { pluralizeText } from "../../../lib";
import ChabadHousesApi from "../../../services/resources/ChabadHousesApi";
import RafflesApi from "../../../services/resources/RafflesApi";
import Loader from "../../common/Loader";

function Winners(props) {
  const { id, prizes = [] } = props.raffle;

  const [chabadHouseDetails, setChabadHouseDetails] = useState([]);
  const [winners, setWinners] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [defaultDropdownOptions, setDefaultDropdownOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ordersLoading, setOrdersLoading] = useState(true);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    getOrders("", true);
    // eslint-disable-next-line
  }, []);

  useEffect(function GetChabadHouseDetails() {
    ChabadHousesApi.getChabadHousesDetails().then((chabadHouses) => {
      const chabadHousesData = chabadHouses.map((ch) => ({
        chabadHouseID: ch.chabadHouseID,
        chabadHouseDisplay: `${ch.chabadHouseName} - ${[
          ch.shliachFirstName,
          ch.shluchaFirstName,
        ]
          .filter((n) => n)
          .join(" & ")} ${ch.shliachLastName || ch.shluchaLastName || ""}`,
      }));
      setChabadHouseDetails(chabadHousesData);
    });
  }, []);

  useEffect(function loadWinners() {
    RafflesApi.getWinners(id).then(({ data: savedWinners }) => {
      formatAndSetWinners(savedWinners);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const formatAndSetWinners = useCallback(
    (savedWinners) => {
      const _winners = [];
      // for each prize
      prizes.forEach((prize) => {
        // for each number of winners in prize
        Array.from({ length: prize.numberOfWinners }).forEach((_) => {
          const _savedWinner = getSavedWinner(
            _winners,
            savedWinners,
            prize.id,
            null,
          );
          _winners.push({
            ..._savedWinner,
            rafflePrizeID: prize.id,
            raffleEventID: id,
            chabadHousePrizeID: null,
            existingWinner: !!_savedWinner,
          });
        });
        // for each local ch prize
        prize.chabadHousePrizes.forEach((chp) => {
          const _existingWinner = getSavedWinner(
            _winners,
            savedWinners,
            prize.id,
            chp.id,
          );
          _winners.push({
            ..._existingWinner,
            rafflePrizeID: prize.id,
            raffleEventID: id,
            chabadHousePrizeID: chp.id,
            chabadHouseID: chp.chabadHouseID,
            existingWinner: !!_existingWinner,
          });
        });
      });
      setWinners(_winners.map((w, i) => ({ ...w, winnerID: i })));
      setLoading(false);
    },
    [prizes, id],
  );

  const getOrdersDebounced = useCallback(
    _debounce((searchText) => getOrders(searchText), 500, true),
    [],
  );

  const onSearchOrder = useCallback(
    (e) => {
      setOrdersLoading(true);
      getOrdersDebounced(e.target.value);
    },
    [getOrdersDebounced],
  );

  const getOrders = useCallback(
    async (searchText, setDefault = false) => {
      setOrdersLoading(true);

      RafflesApi.getOrders(apiSignalRef.current.token, id, 1, 12, "dateDesc", {
        keyword: searchText,
      })
        .then(({ orders }) => {
          const _orders = orders.map((order) => ({
            ...order,
            value: `${order.purchaseID} - ${order.donorFirstName} ${order.donorLastName}`,
            key: order.purchaseID,
          }));

          setDropdownOptions(_orders);
          setDefault && setDefaultDropdownOptions(_orders);
          setOrdersLoading(false);
        })
        .catch(() => {
          setOrdersLoading(false);
        });
    },
    [id, apiSignalRef],
  );

  const onChange = useCallback(
    (winner, order = null) => {
      const _winners = _cloneDeep(winners);
      const _currentIndex = winners.indexOf(winner);

      if (!order || !order.purchaseID) {
        _winners[_currentIndex].rafflePurchaseID = null;
        _winners[_currentIndex].existingWinner = false;
      } else {
        _winners[_currentIndex].rafflePurchaseID = order.purchaseID;
      }

      setWinners(_winners);
      return order?.purchaseID;
    },
    [winners],
  );

  const onSubmit = useCallback(() => {
    const _winners = winners
      .filter((w) => !!w.rafflePurchaseID)
      .map((w) => ({
        id: w.id,
        raffleEventID: w.raffleEventID,
        rafflePrizeID: w.rafflePrizeID,
        rafflePurchaseID: w.rafflePurchaseID,
        chabadHousePrizeID: w.chabadHousePrizeID,
      }));

    if (!_winners.length) return;

    RafflesApi.updateWinners(apiSignalRef.current.token, id, _winners).then(
      ({ success }) => {
        if (success) {
          notify.show("Winners have been saved", "success");
        } else {
          notify.show("An error occured while saving winners", "error");
        }
      },
    );
  }, [id, winners]);

  return loading ? (
    <Loader />
  ) : (
    <div className="new-raffle-step">
      <div className="new-raffle-step-section flex flex-align-center flex-justify-space">
        <p className="xl-text">Winners</p>
        <div>
          <button
            className="btn btn-cancel uppercase-text"
            onClick={() => window.location.reload()}
          >
            Cancel
          </button>
          <button
            className="btn btn-accent ml-16 uppercase-text"
            type="submit"
            onClick={onSubmit}
            disabled={!winners.some((w) => w.rafflePurchaseID)}
          >
            Save Changes
          </button>
        </div>
      </div>
      <div className="new-raffle-step-section">
        {prizes?.map((prize, prizeIndex) => (
          <div key={prizeIndex} className="mb-32">
            <p className="medium-text prize fw-700">
              {prize.usdPrizeTitle ||
                prize.cadPrizeTitle ||
                prize.gbpPrizeTitle}
              <span className="fw-300 small-text">
                {" "}
                ({prize.numberOfWinners}{" "}
                {pluralizeText("winner", prize.numberOfWinners)})
              </span>
            </p>
            {winners
              .filter(
                (w) => w.rafflePrizeID === prize.id && !w.chabadHousePrizeID,
              )
              .map((winner, winnerIndex) => (
                <div key={winnerIndex} className="winner-container">
                  <div className="flex flex-align-center winner-search mr-16">
                    {winner.existingWinner ? (
                      <TextField
                        id={`textfield-winners-${winnerIndex}`}
                        disabled={true}
                        label="Order ID"
                        value={`${winner.rafflePurchaseID} - ${winner.fullName}`}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                onClick={() => onChange(winner)}
                                edge="end"
                              >
                                Clear Selection
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <Autocomplete
                        options={dropdownOptions}
                        noOptionsText={
                          ordersLoading
                            ? "Searching orders..."
                            : "No orders found"
                        }
                        id={`autocomplete-winners-${winnerIndex}`}
                        onInputChange={onSearchOrder}
                        getOptionLabel={(option) => option.value}
                        getOptionSelected={() => true}
                        className="winner-autocomplete"
                        onBlur={() =>
                          setDropdownOptions(defaultDropdownOptions)
                        }
                        onChange={(_, order) => onChange(winner, order)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Order ID"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                  </div>
                </div>
              ))}
            {Boolean(prize.chabadHousePrizes?.length) && (
              <div className="winner-container">
                <hr />
                <p className="medium-text fw-700 mb-0">Local Winners</p>
                {winners
                  .filter(
                    (w) => w.rafflePrizeID === prize.id && w.chabadHousePrizeID,
                  )
                  .map((winner, winnerIndex) => (
                    <div className="winner-search" key={winnerIndex}>
                      <p className="fs-12 accent-text mt-12 mb-4">
                        {
                          chabadHouseDetails.find(
                            (ch) => ch.chabadHouseID === winner.chabadHouseID,
                          )?.chabadHouseDisplay
                        }
                      </p>
                      {winner.existingWinner ? (
                        <TextField
                          disabled={true}
                          label="Order ID"
                          value={`${winner.rafflePurchaseID} - ${winner.fullName}`}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  onClick={() => onChange(winner)}
                                  edge="end"
                                >
                                  Clear Selection
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Autocomplete
                          options={dropdownOptions}
                          noOptionsText={
                            ordersLoading
                              ? "Searching orders..."
                              : "No orders found"
                          }
                          id={`autocomplete-winners-${winnerIndex}`}
                          onInputChange={onSearchOrder}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={() => true}
                          className="winner-autocomplete"
                          onBlur={() =>
                            setDropdownOptions(defaultDropdownOptions)
                          }
                          onChange={(_, order) => onChange(winner, order)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Order ID"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const getSavedWinner = (
  existingWinners,
  savedWinners = [],
  prizeID,
  chabadHousePrizeID,
) => {
  return savedWinners.find(
    (w) =>
      w.rafflePrizeID === prizeID &&
      w.chabadHousePrizeID === chabadHousePrizeID &&
      !existingWinners.some((winner) => winner.id === w.id),
  );
};

export default React.memo(Winners);
