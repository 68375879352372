import AuthRequest from "./AuthRequest";
import queryString from "query-string";

const baseUrl = "/api/contacts";

// interface ShluchimSearchParams {
//     contactType?: string;
//     returnNamesOnly?: boolean;
//     filterCountry?: string;
//     filterState?: string;
//     filterGender?: string;
//     filterAssociation?: string;
// }
// interface StudentsSearchParams {
//     contactType?: string;
//     returnNamesOnly?: boolean;
//     filterCountry?: string;
//     filterState?: string;
// }
// interface CsvDownloadParams {
//     contactType?: string;
//     filterSearchQuery?: string;
//     filterCountry?: string;
//     filterState?: string;
//     filterGender?: string;
//     filterAssociation?: string;
//     filterContactIds?: Array<number>;
//     filterYearStartedFrom?: number;
//     filterYearStartedTo?: number;
// }

const ContactsApi = {
  get: id => {
    return AuthRequest.get(`${baseUrl}/${id}`);
  },
  getAdmin: id => {
    return AuthRequest.get(`${baseUrl}/users/${id}`);
  },
  /** @param {ShluchimSearchParams} searchParams */
  searchShluchim: (searchQuery, startingOffset, maxResults, searchParams) => {
    return AuthRequest.get(
      `${baseUrl}/shluchim?${queryString.stringify({
        searchQuery,
        startingOffset,
        maxResults,
        ...searchParams
      })}`
    );
  },
  /** @param {StudentsSearchParams} searchParams */
  searchStudents: (searchQuery, startingOffset, maxResults, searchParams) => {
    return AuthRequest.get(`${baseUrl}/students`, {
      searchQuery,
      startingOffset,
      maxResults,
      ...searchParams
    });
  },
  /** @param {CsvDownloadParams} csvParams */
  csvDownload: (searchQuery, csvParams) => {
    csvParams.filterSearchQuery = searchQuery || "";
    csvParams.filterYearStartedFrom = csvParams.filterYearStartedFrom || 0;
    csvParams.filterYearStartedTo = csvParams.filterYearStartedTo || 0;
    return AuthRequest.post(`${baseUrl}/csvdownload`, { ...csvParams });
  },
  searchAdmins: (searchQuery, returnAdminsOnly, startingOffset, maxResults) => {
    return AuthRequest.get(`${baseUrl}/users`, {
      searchQuery,
      returnAdminsOnly,
      startingOffset,
      maxResults
    });
  },
  /** @param {string} searchQuery
   * @param {number} maxResults
   */
  getShluchimNames: (searchQuery, maxResults) => {
    return AuthRequest.get(`${baseUrl}/shluchimnames`, {
      searchQuery,
      maxResults
    });
  },
  /** @param {string} searchQuery
   * @param {number} maxResults
   */
  getStudentsNames: (searchQuery, maxResults) => {
    return AuthRequest.get(`${baseUrl}/studentsnames`, {
      searchQuery,
      maxResults
    });
  },
  listContacts: request => {
    return AuthRequest.post(`${baseUrl}/listcontacts`, { request });
  },
  mergeStudent: request => {
    return AuthRequest.post(`${baseUrl}/mergestudents`, { request });
  },
  createUser: user => {
    return AuthRequest.post(`${baseUrl}/users`, { ...user });
  },
  updateUser: user => {
    return AuthRequest.post(`${baseUrl}/users/${user.id}`, { ...user });
  },
  /** @param {number} contactId
   * @param {boolean} isAdmin
   */
  updateAdmin: (contactId, isAdmin) => {
    return AuthRequest.post(`${baseUrl}/toggleuser/${contactId}`, { isAdmin });
  },
  /** @param {number} contactId */
  deleteShliach: contactId => {
    return AuthRequest.delete(`${baseUrl}/shluchim/${contactId}`);
  },
};

export default ContactsApi;
