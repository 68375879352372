import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../shared/ConfirmationModal";

export default class EventTracksTabs extends React.PureComponent {
  state = {
    deleteTrackIndex: null,
    showAddTrackConfirmationModal: false,
    showDeleteTrackConfirmationModal: false,
  };

  onAddTrack = () => {
    const { eventId, hasStartedShluchimEnrollment } = this.props;
    if (eventId && hasStartedShluchimEnrollment) {
      this.setState({ showAddTrackConfirmationModal: true });
    } else {
      this.addTrack();
    }
  };

  addTrack = () => {
    this.props.addTrack();

    if (this.state.showAddTrackConfirmationModal) {
      this.setState({ showAddTrackConfirmationModal: false });
    }
  };

  onDeleteTrack = (index) => {
    this.setState({
      deleteTrackIndex: index,
      showDeleteTrackConfirmationModal: true,
    });
  };

  deleteTrack = () => {
    this.props.deleteTrack(this.state.deleteTrackIndex);

    this.setState({
      deleteTrackIndex: null,
      showDeleteTrackConfirmationModal: false,
    });
  };

  render() {
    const { eventIsPublished, toTrack, trackIndex, tracks } = this.props;

    const {
      deleteTrackIndex,
      showAddTrackConfirmationModal,
      showDeleteTrackConfirmationModal,
    } = this.state;

    return (
      <div className="program-settings-page-subheader-left">
        <span onClick={() => browserHistory.goBack()} className="mr-24">
          <i className="material-icons link-text">arrow_back</i>
        </span>

        {tracks.map((track, index) => (
          <p
            key={index}
            className={`"large-text program-name link-text-secondary ${
              trackIndex === index ? "fw-900" : ""
            }`}
            onClick={() => toTrack(index)}
          >
            [{track.trackName || (index === 0 ? "Untitled Track" : "New Track")}
            ]
            {index > 0 && (!eventIsPublished || !track.id) && (
              <i
                className="material-icons large-text link-text-secondary accent-text ml-8"
                onClick={() => this.onDeleteTrack(index)}
              >
                close
              </i>
            )}
          </p>
        ))}
        <p
          className="accent-text-dark medium-text flex flex-align-center mb-0 link-text-secondary"
          onClick={this.onAddTrack}
        >
          <i className="material-icons mr-8">add</i>Add Track
        </p>

        <ConfirmationModal
          cancel={() =>
            this.setState({
              showAddTrackConfirmationModal: false,
            })
          }
          confirm={this.addTrack}
          message={`New tracks are NOT available for enrolled Chabad Houses, only for Chabad Houses that will be enrolled going forward.\nWould you like to proceed with adding a new track?`}
          show={showAddTrackConfirmationModal}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({
              deleteTrackIndex: null,
              showDeleteTrackConfirmationModal: false,
            })
          }
          confirm={this.deleteTrack}
          message={`Are you sure you want to delete ${
            (tracks[deleteTrackIndex] && tracks[deleteTrackIndex].trackName) ||
            "this Untitled"
          } track?`}
          show={showDeleteTrackConfirmationModal}
        />
      </div>
    );
  }
}
