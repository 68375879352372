import React from "react";
import classNames from "classnames";

// import { ClassAttributes, HTMLAttributes, ButtonHTMLAttributes } from "react";

function Container({ children, className, ...other }) {
  return (
    <div className={classNames("styled-list-container", className)} {...other}>
      {children}
    </div>
  );
}

function DeleteButton({ children, className, ...other }) {
  return (
    <button
      className={classNames("styled-list-delete-btn", className)}
      {...other}
    >
      {children}
    </button>
  );
}

function AddButton({ children, className, ...other }) {
  return (
    <button className={classNames("styled-list-add-btn", className)} {...other}>
      {children}
    </button>
  );
}

export const StyledList = {
  Container,
  DeleteButton,
  AddButton,
};
