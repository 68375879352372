import React from "react";
import classNames from "classnames";

// import { ClassAttributes, HTMLAttributes } from "react";

// export interface StyledSelectProps {
//   small?: boolean;
// }

export function StyledSelect({ children, className, small, ...other }) {
  return (
    <div
      className={classNames("styled-select", className, { small })}
      {...other}
    >
      {children}
    </div>
  );
}
