import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import CoursesTableActions from "./CoursesTableActions";
import CoursesTableFilters from "./CoursesTableFilters";
import CoursesTableHeader from "./CoursesTableHeader";
import CoursesTableRow from "./CoursesTableRow";

import EduApi from "../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class CoursesTable extends React.PureComponent {
  state = {
    courses: [],
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    results: 12,
    sortBy: "name",
    success: true,
    totalCourses: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterCourses = (filters) => {
    this.setState({ filters }, () => {
      this.getCourses();
    });
  };

  getCourses = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        EduApi.getEduProgramCourses(
          this.apiSignal.token,
          this.props.eduProgram.id,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ courses, totalCourses }) => {
            this.setState({
              courses,
              loading: false,
              totalCourses,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                courses: [],
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
                totalCourses: 0,
              });
            }
          });
      },
    );
  };

  getCoursesForExport = async () => {
    const { sortBy, filters } = this.state;

    const coursesForExport = await EduApi.getEduProgramCourses(
      this.apiSignal.token,
      this.props.eduProgram.id,
      undefined,
      undefined,
      sortBy,
      filters,
      true,
    );

    return coursesForExport.courses;
  };

  render() {
    const { eduProgram, mobileMode, viewOnly } = this.props;

    const { courses, loading, page, results, sortBy, success, totalCourses } =
      this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Courses</p>
          <CoursesTableActions
            eduProgram={eduProgram}
            getCoursesForExport={this.getCoursesForExport}
            viewOnly={viewOnly}
          />
        </div>
        <CoursesTableFilters applyFilters={this.filterCourses} />
        <div className="courses-table">
          <PaginatedTable
            loading={loading}
            loadData={this.getCourses}
            mobileMode={mobileMode}
            page={page}
            records={courses}
            renderHeader={() => <CoursesTableHeader />}
            renderRow={(course, index) => (
              <CoursesTableRow
                course={course}
                eduProgramScheduleId={eduProgram.programScheduleID}
                key={index}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            success={success}
            totalCount={totalCourses}
          />
        </div>
      </div>
    );
  }
}
