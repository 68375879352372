import React from "react";
import { Link } from "react-router";
import Select from "react-select";

export default class ProgramSchedulesCard extends React.PureComponent {
  render() {
    const {
      programSchedule: {
        hasApplicationProcess,
        programID,
        programName,
        scheduleName,
      },
      programSchedulesList,
      onChangeProgramSchedule,
      scheduleId,
    } = this.props;

    return (
      <div className="card trip-card flex flex-justify-space flex-align-center mb-40">
        {scheduleId &&
        programSchedulesList?.length > 1 &&
        programSchedulesList
          .map((p) => p.scheduleID.toString())
          .indexOf(scheduleId) >= 0 ? (
          <Select
            className="xl-text fw-700 flex trip-event-select custom-select full-width"
            clearable={false}
            onChange={({ value }) => onChangeProgramSchedule(value)}
            options={programSchedulesList.map((l) => ({
              label: l.scheduleName || `${l.programName} (Untitled)`,
              value: l.scheduleID,
            }))}
            value={scheduleId}
          />
        ) : (
          <p className="xl-text fw-700">{scheduleName || programName}</p>
        )}
        {hasApplicationProcess && (
          <Link
            className="btn custom-btn btn-accent-secondary uppercase-text btn-medium ml-32"
            to={`/programs/${programID}/schedules/${scheduleId}/applicationSettings`}
          >
            View Settings
          </Link>
        )}
      </div>
    );
  }
}
