import React from "react";
import { Link } from "react-router";
import { formatFullName, pluralizeText } from "../../../../lib";
import queryString from "query-string";
import moment from "moment";

export default class StudentsTableRow extends React.PureComponent {
  getGrantFlags = (studentGrant) => {
    const {
      ineligibleFlaggedReasons,
      isIneligibleFlagged,
      isMissingDataFlagged,
      isStudentEligibleForGrant,
      isStudentPotentialDuplicateFlagged,
      meetsAllInteractionsCounts,
      meetsMinInteractionsRangeWeeks,
      missingDataFlaggedReasons,
    } = studentGrant;

    const isFlagged =
      isIneligibleFlagged ||
      isMissingDataFlagged ||
      isStudentPotentialDuplicateFlagged;

    const flags = [];
    if (isIneligibleFlagged) {
      flags.push({
        className: "eligibility-flag",
        flaggedReasons: ineligibleFlaggedReasons,
        isIneligibleFlagged,
      });
    }
    if (isMissingDataFlagged) {
      flags.push({
        className: "missing-data-flag",
        flaggedReasons: missingDataFlaggedReasons,
        isMissingDataFlagged,
      });
    }
    if (isStudentPotentialDuplicateFlagged) {
      flags.push({
        className: "duplicate-flag",
        flaggedReasons: ["Student is a potential duplicate"],
        isStudentPotentialDuplicateFlagged,
      });
    }

    const showInvalidInteractionsRangeFlag = //show invalid time range flag when interactions time range is blocking the submission eligibility
      isStudentEligibleForGrant &&
      meetsAllInteractionsCounts &&
      !meetsMinInteractionsRangeWeeks;

    const primaryFlagClassName = isFlagged
      ? flags[0].className
      : showInvalidInteractionsRangeFlag
      ? "time-range-flag"
      : "";

    return {
      isFlagged,
      flags,
      primaryFlagClassName,
      showInvalidInteractionsRangeFlag,
    };
  };

  render() {
    const {
      grants,
      didScheduleStart,
      onActivateStudentGrant,
      onDeactivateStudentGrant,
      onSubmitStudentGrant,
      onSetPrimaryStudentGrant,
      onUnsubmitStudentGrant,
      readOnly,
      student: {
        chabadHouseName,
        campusName,
        grants: studentGrants = [],
        shliachFirstName,
        shliachLastName,
        studentCell,
        studentEmail,
        studentFirstName,
        studentGender,
        studentID,
        studentLastName,
      },
    } = this.props;
    const studentGenderDisplay =
      studentGender === "Unknown" ? "" : studentGender;
    const isLamplightersActive = studentGrants.some(
      (sg) => sg.isEnrolledInThisGrant,
    );
    //show primary grant indication if there are 2 active unsubmitted grants
    const showPrimaryGrant =
      isLamplightersActive &&
      studentGrants.filter((s) => s.isEnrolledInThisGrant && !s.isSubmitted)
        .length > 1;
    return (
      <div
        className="lamplighters-students-table-row small-text"
        style={{
          gridTemplateColumns: `240px repeat(${grants.length}, 1fr)`,
        }}
      >
        <div>
          <Link
            className="link-text-secondary fw-700"
            style={{ fontSize: "14px" }}
            to={`/students/${studentID}?${
              isLamplightersActive
                ? `studentTab=activity&li=true`
                : `studentTab=lamplighters`
            }`} //Lamplighters-active students are directed to activity tab filtered to LL interactions, Inactive students who are directed to the Lamplighters tab
          >
            {formatFullName(studentFirstName, studentLastName)}
          </Link>
          <p
            className="flex flex-align-center mt-8"
            style={{ flexFlow: "wrap" }}
          >
            <a href={`tel:${studentCell}`}>{studentCell}</a>
            {studentCell && studentEmail && (
              <span className="ml-8 mr-8">|</span>
            )}
            <a
              href={`mailto:${studentEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {studentEmail}
            </a>
            {(studentCell || studentEmail) && studentGenderDisplay && (
              <span className="ml-8 mr-8">|</span>
            )}
            <span>{studentGenderDisplay}</span>
          </p>
          <p className="mt-8">{campusName}</p>
          <p className="mt-8">{chabadHouseName}</p>
          <p className="mt-8">
            {shliachFirstName} {shliachLastName}{" "}
          </p>
        </div>
        {grants &&
          grants.map((grant, index) => {
            const studentGrant = studentGrants.find(
              (sg) => sg.grantID === grant.id,
            );

            if (!studentGrant) return <div key={index} />;

            const {
              ineligibleStudentReasons,
              isEligibleForSubmission,
              isEnrolledInThisGrant,
              isPrimary,
              isStudentEligibleForGrant,
              isSubmitted,
              minInteractionsRangeWeeks,
              requirements,
              submittedDate,
            } = studentGrant;

            const {
              isFlagged,
              flags,
              primaryFlagClassName,
              showInvalidInteractionsRangeFlag,
            } = this.getGrantFlags(studentGrant);

            return (
              <div className="student-grant-container" key={index}>
                {!isEnrolledInThisGrant ? (
                  !isStudentEligibleForGrant ? (
                    <div>
                      <p className="fw-700">Ineligible</p>
                      <div className="mt-8">
                        {ineligibleStudentReasons &&
                          ineligibleStudentReasons.map((reason, index) => (
                            <p key={index}>{reason}</p>
                          ))}
                      </div>
                    </div>
                  ) : !readOnly ? (
                    <span className="tooltip-container flex flex-align-center">
                      <p
                        className={`link-text uppercase-text activate-link${
                          !didScheduleStart ? " disabled" : ""
                        }`}
                        onClick={
                          didScheduleStart
                            ? () => onActivateStudentGrant(studentGrant)
                            : null
                        }
                      >
                        Activate
                      </p>
                      {!didScheduleStart && (
                        <span className="tooltip">
                          Student grants cannot be activated until the schedule
                          start date
                        </span>
                      )}
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  <div
                    className={`card grant-card ${
                      isFlagged
                        ? "flagged"
                        : isSubmitted
                        ? "submitted"
                        : "active"
                    }-grant-card ${isPrimary ? "primary-grant-card" : ""} ${
                      isFlagged ? primaryFlagClassName : ""
                    }`}
                  >
                    {isSubmitted ? (
                      <div className="submitted-text">
                        <p className="fw-700 mb-8">Submitted</p>
                        <p className="italic-text">
                          {moment(submittedDate).format("MM/DD/YYYY")}
                        </p>
                      </div>
                    ) : (
                      <div className="accent-text small-text mb-16">
                        {showPrimaryGrant && isPrimary && (
                          <p className="flex flex-align-center mb-8">
                            <span className="accent-text-secondary">
                              Primary grant
                            </span>
                            <span className="tooltip-container flex flex-align-center">
                              <i className="material-icons medium-text accent-text-secondary ml-8">
                                info
                              </i>
                              <span
                                className="tooltip"
                                style={{
                                  width: 184,
                                  left: -144,
                                  whiteSpace: "pre-line",
                                }}
                              >
                                Overlapping interactions will be assigned to the
                                primary grant first and then to the secondary
                                grant
                              </span>
                            </span>
                          </p>
                        )}
                        <div style={{ fontSize: "11px", lineHeight: 2 }}>
                          {requirements?.map((r, i) => (
                            <div key={i}>
                              {r.numRequiredInteractions > 0 && (
                                <p>
                                  {r.interactionsCount || 0}/
                                  {r.numRequiredInteractions}{" "}
                                  {r.classifications.join(" or ")}
                                  {" Interactions"}
                                </p>
                              )}
                              <div className="italic-text">
                                {r.israelRelatedMin > 0 && (
                                  <p>
                                    Includes {r.israelRelatedCount || 0}/
                                    {r.israelRelatedMin} required Israel/Antisemitism Related
                                    Interactions
                                  </p>
                                )}
                                {r.shabbosMealMax > 0 && (
                                  <p>
                                    Includes {r.shabbosMealCount || 0} Shabbos{" "}
                                    {pluralizeText("Meal", r.shabbosMealCount)}{" "}
                                    (max {r.shabbosMealMax} allowed)
                                  </p>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div
                      className="flex flex-align-center flex-justify-space"
                      style={{ flexFlow: "wrap-reverse" }}
                    >
                      <div className="flex flex-align-center mr-8">
                        {isFlagged &&
                          flags.map((flag, flagIndex) => (
                            <div
                              className="tooltip-container mr-8"
                              key={flagIndex}
                            >
                              <i
                                className={`material-icons accent-text-dark profile-add-icon flag-icon ${flag.className}`}
                              >
                                flag
                              </i>
                              <span className="tooltip">
                                {flag.flaggedReasons &&
                                  flag.flaggedReasons.map(
                                    (reason, reasonIndex) => (
                                      <span className="block" key={reasonIndex}>
                                        {reason}
                                      </span>
                                    ),
                                  )}
                                {flag.isStudentPotentialDuplicateFlagged && (
                                  <Link
                                    className="block link-text-secondary"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    to={`/students/merge-suggestions?${queryString.stringify(
                                      {
                                        studentId: studentID,
                                        studentName: formatFullName(
                                          studentFirstName,
                                          studentLastName,
                                        ),
                                      },
                                    )}`}
                                  >
                                    Click here to view duplicates
                                  </Link>
                                )}
                              </span>
                            </div>
                          ))}
                        {showInvalidInteractionsRangeFlag && (
                          <div className="tooltip-container mr-8">
                            <i
                              className="material-icons accent-text-dark profile-add-icon"
                              style={{ color: "#FEC609" }}
                            >
                              schedule
                            </i>
                            <span className="tooltip">
                              Interactions must take place over a range of at
                              least {minInteractionsRangeWeeks}{" "}
                              {pluralizeText("week", minInteractionsRangeWeeks)}
                            </span>
                          </div>
                        )}
                        {!readOnly &&
                          (isSubmitted ? (
                            <p
                              className="link-text-secondary uppercase-text xs-text"
                              onClick={() =>
                                onUnsubmitStudentGrant(studentGrant)
                              }
                            >
                              Unsubmit Grant
                            </p>
                          ) : isEligibleForSubmission ? (
                            <button
                              className="btn custom-btn btn-small btn-accent uppercase-text"
                              onClick={() => onSubmitStudentGrant(studentGrant)}
                            >
                              Submit Grant
                            </button>
                          ) : (
                            ""
                          ))}
                      </div>
                      {!isSubmitted && !readOnly && (
                        <div className="flex flex-align-center">
                          <p
                            className="link-text-secondary uppercase-text xs-text"
                            onClick={() =>
                              onDeactivateStudentGrant(studentGrant)
                            }
                          >
                            Deactivate
                          </p>
                          {showPrimaryGrant && !isPrimary && (
                            <p
                              className="link-text uppercase-text xs-text ml-8"
                              onClick={() =>
                                onSetPrimaryStudentGrant(studentGrant)
                              }
                            >
                              Set as primary
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}
