import React from "react";
import Select from "../../../shared/Select";
import Toggle from "../../../shared/Toggle";
import ValidatedInput from "../../../shared/ValidatedInput";
import { TripAcceptancePolicyOptions } from "../../TripEventConsts";

export default class EventShluchimControls extends React.PureComponent {
  render() {
    const {
      event: {
        adminAcceptancePolicyOption,
        canShluchimIssuePromoCodes,
        canShluchimOverridePrices,
        defaultShliachAcceptancePolicy,
      },
      onChange,
      submitAttempted,
      systemLists: { optInOutOptions, tripAcceptancePolicyOptions },
    } = this.props;

    return (
      <React.Fragment>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">
            Shluchim Controls
          </p>
        </div>
        <div className="program-settings-page-section">
          <div className="shluchim-controls-row flex flex-justify-space">
            <div className="shluchim-controls-row-left">
              <div className="flex">
                <p className="fw-700">Enable Promo Codes</p>
              </div>
              <p className="accent-text-dark mt-8">
                Allow the shluchim to generate their own promo codes to give out
                to their students.
              </p>
            </div>
            <div className="shluchim-controls-row-right">
              <Toggle
                name="canShluchimIssuePromoCodes"
                onChange={onChange}
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
                value={canShluchimIssuePromoCodes}
              />
            </div>
          </div>
          <div className="shluchim-controls-row flex flex-justify-space">
            <div className="shluchim-controls-row-left">
              <div className="flex">
                <p className="fw-700">Enable Acceptance Process</p>
              </div>
              <p className="accent-text-dark mt-8">
                Give shluchim the ability to accept or reject students upon
                registration.
              </p>
              {adminAcceptancePolicyOption ===
                TripAcceptancePolicyOptions.ShluchimCanChoose && (
                <div className="flex flex-align-center mt-16">
                  <p className="accent-text-dark mr-24">By default set to</p>
                  <ValidatedInput
                    input={
                      <Select
                        options={
                          optInOutOptions &&
                          optInOutOptions.map((option) => ({
                            display: option.displayValue,
                            value: option.enumValue,
                          }))
                        }
                      />
                    }
                    name="defaultShliachAcceptancePolicy"
                    onChange={onChange}
                    required={true}
                    showRequired={submitAttempted}
                    value={defaultShliachAcceptancePolicy}
                  />
                </div>
              )}
            </div>
            <div className="shluchim-controls-row-right">
              <Toggle
                name="adminAcceptancePolicyOption"
                onChange={async (name, value) => {
                  await onChange(name, value);
                  if (
                    value !== TripAcceptancePolicyOptions.ShluchimCanChoose &&
                    defaultShliachAcceptancePolicy
                  ) {
                    onChange("defaultShliachAcceptancePolicy", "");
                  }
                }}
                options={tripAcceptancePolicyOptions.map((t) => ({
                  value: t.enumValue,
                  display: t.displayValue,
                }))}
                value={adminAcceptancePolicyOption}
              />
            </div>
          </div>
          <div className="shluchim-controls-row flex flex-justify-space">
            <div className="shluchim-controls-row-left">
              <div className="flex">
                <p className="fw-700">Enable Price Control</p>
              </div>
              <p className="accent-text-dark mt-8">
                Allow shluchim to override prices with their own.
              </p>
            </div>
            <div className="shluchim-controls-row-right">
              <Toggle
                name="canShluchimOverridePrices"
                onChange={onChange}
                options={[
                  {
                    value: true,
                    display: "Yes",
                  },
                  {
                    value: false,
                    display: "No",
                  },
                ]}
                value={canShluchimOverridePrices}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
