import React from "react";
import FadeOutErrorMessage from "./FadeOutErrorMessage";

import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import json2csv from "json2csv";

export default class ExportCSVButton extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    success: true,
  };

  downloadCSV = (data, fileName) => {
    const { getHeaders, headers } = this.props;
    const csv = json2csv({
      data,
      fields: getHeaders ? getHeaders(data) : headers,
      withBOM: true,
    });

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportCSV = () => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        success: true,
      },
      async () => {
        const { fileName, getExportData } = this.props;

        try {
          const data = await getExportData();

          if (data && data.length) {
            this.downloadCSV(
              data,
              `${fileName}_${new Date()
                .toLocaleDateString()
                .replace(/\//g, "-")}.csv`,
            );

            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              errorMessage: "No results for export",
              loading: false,
              success: false,
            });
          }
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              errorMessage: ApiCallErrorMessageHandler(err),
              loading: false,
              success: false,
            });
          }
        }
      },
    );
  };

  render() {
    const { busyTitle, className, disabled, errorMessageClassName, title } =
      this.props;
    const { errorMessage, loading, success } = this.state;

    return (
      <div className="relative">
        <p
          className={className}
          onClick={disabled ? null : this.exportCSV}
          disabled={disabled || loading}
        >
          {loading ? busyTitle || "Exporting..." : title || "CSV Export"}
        </p>

        {!loading && !success && (
          <FadeOutErrorMessage
            className={`error-message xs-text ${errorMessageClassName || ""}`}
            message={errorMessage || "Something went wrong.  Please try again."}
            onTimeout={() =>
              this.setState({
                errorMessage: "",
                success: true,
              })
            }
          />
        )}
      </div>
    );
  }
}
