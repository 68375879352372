import React from "react";
import AuthClient from "../../../../services/resources/AuthClient";

import FormInputUpdate from "../../Form/FormInputUpdate";
import CustomSelect from "../../Form/CustomSelect";
import { FieldArray } from "formik";

import Select from "react-select";

export default class ShluchimInfo extends React.PureComponent {
  state = {
    shluchimInfoShowing: true,
  };

  componentDidMount() {
    this._ismounted = true;
    this.getShluchim();
    if (window.innerWidth < 560) {
      this.setState({
        shluchimInfoShowing: false,
      });
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  getShluchim = async () => {
    const { data } = await AuthClient.get(`Shluchim`).catch((err) => {
      return { data: [] };
    });
    if (this._ismounted) {
      this.setState({ shluchim: data.payload });
    }
  };

  onChange = (option, name, index, values) => {
    values.shluchim[index].shliachID = option.key;
    this.props.setFieldValue(name, option.value);
  };

  render() {
    const { shluchim } = this.state;
    const {
      errors,
      setFieldValue,
      touched,
      readOnly,
      values,
      sys: { shluchimPositions },
    } = this.props;
    return (
      <div
        className={
          this.state.shluchimInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="shluchim">
          {(arrayHelpers) => (
            <React.Fragment>
              <div className="flex mobile-flex-justify-space flex-align-center mb-16 mt-48">
                <p className="accent-text-dark medium-text fw-700 flex flex-align-center">
                  <span
                    onClick={() => {
                      this.setState({
                        shluchimInfoShowing: !this.state.shluchimInfoShowing,
                      });
                    }}
                  >
                    Shluchim
                  </span>
                  {readOnly ? null : (
                    <i
                      className={`material-icons link-text profile-add-icon ml-16 ${
                        !this.state.shluchimInfoShowing ? "hidden" : ""
                      }`}
                      onClick={() =>
                        arrayHelpers.unshift({
                          shliachID: "",
                          fullName: "",
                          shliachPosition: "",
                        })
                      }
                    >
                      add_circle
                    </i>
                  )}
                </p>
              </div>
              <div className="profile-form-shluchim-info profile-form-inputs">
                {values.shluchim &&
                  values.shluchim.map((shliach, index) => (
                    <div key={index}>
                      <div className="profile-form-shluchim">
                        {shliach.fullName ? (
                          <p>{shliach.fullName}</p>
                        ) : (
                          <FormInputUpdate
                            name={`shluchim[${index}].fullName`}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <Select
                                className={"custom-select"}
                                classNamePrefix="custom-select"
                                getOptionLabel={(option) => option.label}
                                name={field.name}
                                onChange={(option) =>
                                  this.onChange(
                                    option,
                                    field.name,
                                    index,
                                    values,
                                  )
                                }
                                options={
                                  shluchim &&
                                  shluchim.map((s) => ({
                                    key: s.shliachID,
                                    value: s.fullName,
                                    label: s.fullName,
                                  }))
                                }
                                placeholder="Select Existing Shliach..."
                                value={field.value}
                              />
                            )}
                          </FormInputUpdate>
                        )}
                        <div>
                          <FormInputUpdate
                            name={`shluchim[${index}].shliachPosition`}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                readOnly={readOnly}
                                options={
                                  shluchimPositions &&
                                  shluchimPositions.map((p) => ({
                                    key: p.enumValue,
                                    value: p.enumValue,
                                    label: p.displayValue,
                                  }))
                                }
                                placeholder="Select Position..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInputUpdate>
                        </div>
                        <div className="flex flex-align-center">
                          {readOnly ? null : (
                            <i
                              className="material-icons link-text hover-delete"
                              style={{
                                fontSize: "16px",
                                width: "20px",
                                marginLeft: "32px",
                              }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              delete
                            </i>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </FieldArray>
      </div>
    );
  }
}
