import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import Toggle from "../../../../shared/Toggle";
import Loader from "../../../../common/Loader";
import { formatFullName } from "../../../../../lib";
import SystemApi from "../../../../../services/resources/SystemApi";
import moment from "moment";

function EnrollmentTours(props) {
  const {
    enrollment: {
      chabadHouseName,
      enrolledByShliachID,
      shliachFirstName,
      shliachLastName,
      tours,
    },
    tripEvent: { tours: eventTours },
  } = props;

  const [transportationLists, setTransportationLists] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getTransportationLists() {
      setLoading(true);
      try {
        const systemLists = await SystemApi.lists([
          "tourTransportationOptions",
          "ohelTourTransportationOptions",
        ]);
        setTransportationLists(systemLists);
      } catch { } // no need to show error
      setLoading(false);
    }
    getTransportationLists();
  }, []);

  const getTransportationOptionDescription = (transportationOption) => {
    const { ohelTourTransportationOptions, tourTransportationOptions } =
      transportationLists;

    return (
      ohelTourTransportationOptions?.find(
        (option) => option.enumValue === transportationOption,
      )?.displayValue ||
      tourTransportationOptions?.find(
        (option) => option.enumValue === transportationOption,
      )?.displayValue ||
      transportationOption
    );
  };

  return (
    <div>
      <div>
        <Link
          className="xl-text link-text-secondary fw-700 mb-0"
          to={`/shluchim/${enrolledByShliachID}`}
        >
          {formatFullName(shliachFirstName, shliachLastName)}
        </Link>
        <p className="medium-text mb-32">{chabadHouseName}</p>
      </div>
      {loading ? (
        <Loader />
      ) : !eventTours || !eventTours.length ? (
        <p className="medium-text fw-700">No Tours</p>
      ) : (
        eventTours.map((tour) => {
          const {
            autoEnrollStudents,
            hasTransportation,
            name,
            id,
            schedules,
            shluchimDescription,
          } = tour;
          const tourEnrollment = tours.find((t) => t.tourID === id) || {};

          return (
            <div className="trip-page-form-section" key={id}>
              <p className="medium-text fw-700 mb-16">{name}</p>
              <p className="accent-text-dark mb-24 pre-wrap">
                {shluchimDescription}
              </p>
              <div>
                <div className="flex flex-align-center mb-16 mobile-block">
                  <p className="trip-tours-form-label">Offer to my group</p>
                  <Toggle
                    name={`tourEnrollment-${id}`}
                    options={[
                      { value: true, display: "Yes" },
                      { value: false, display: "No" },
                    ]}
                    readOnly={true}
                    value={!!tourEnrollment.id}
                  />
                </div>
                <div className="flex flex-align-center mb-24 mobile-block">
                  <p className="trip-tours-form-label">
                    Auto-enroll Students
                    {!autoEnrollStudents && (
                      <span className="tooltip-container">
                        <i className="material-icons large-text ml-8">info</i>
                        <span className="tooltip">
                          By choosing yes, all of your students will be
                          automatically registered in this tour. By choosing no,
                          they will have the option to register.
                        </span>
                      </span>
                    )}
                  </p>
                  <Toggle
                    name={`autoEnrollStudentsOverride-${id}`}
                    options={[
                      { value: true, display: "Yes" },
                      { value: false, display: "No" },
                    ]}
                    readOnly={true}
                    value={
                      autoEnrollStudents ||
                      tourEnrollment.autoEnrollStudentsOverride
                    }
                  />
                  {autoEnrollStudents && (
                    <span className="tooltip-container">
                      <i className="material-icons large-text ml-8">lock</i>
                      <span className="tooltip">
                        This was set by admin and can’t be changed
                      </span>
                    </span>
                  )}
                </div>

                <div className="flex flex-align-center mb-24 mobile-block">
                  <p className="trip-tours-form-label">
                    Total Shluchim/Chaperones Attending
                  </p>
                  <p>{tourEnrollment.numberOfChaperones}</p>
                </div>
                {hasTransportation && (
                  <div className="flex flex-align-center mb-16 mobile-block">
                    <p className="trip-tours-form-label">Transportation</p>
                    <p>
                      {getTransportationOptionDescription(
                        tourEnrollment.transportationOption,
                      )}
                    </p>
                  </div>
                )}
                {schedules && schedules.length > 0 && (
                  <div className="flex mobile-block">
                    <p className="trip-tours-form-label">Tour Schedule</p>
                    <div>
                      {schedules.map((schedule) => (
                        <div
                          className="flex custom-checkbox-container mb-8"
                          key={schedule.id}
                        >
                          <input
                            disabled
                            checked={
                              tourEnrollment.tourScheduleIDs &&
                              tourEnrollment.tourScheduleIDs.indexOf(
                                schedule.id,
                              ) >= 0
                            }
                            className="custom-checkbox"
                            id={`tourScheduleIDs-${id}-sched-${schedule.id}`}
                            type="checkbox"
                          />
                          <label
                            htmlFor={`tourScheduleIDs-${id}-sched-${schedule.id}`}
                          >
                            <span className="fw-700 mr-8">{schedule.name}</span>
                            {`${moment(schedule.time).format(
                              "dddd, MMM D, h:mm A",
                            )} - ${moment(schedule.calculatedEndTime).format(
                              "h:mm A",
                            )}`}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}
export default React.memo(EnrollmentTours);
