import AuthRequest from "./AuthRequest";

const baseUrl = "/api/chayenu/reporting";

const ChayenuReportingApi = {
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  get: (startDate, endDate) => {
    return AuthRequest.get(baseUrl, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  getByStudents: (startDate, endDate) => {
    return AuthRequest.get(baseUrl + "/bystudents", {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  getBySchools: (startDate, endDate) => {
    return AuthRequest.get(baseUrl + "/byschools", {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
};

export default ChayenuReportingApi;
