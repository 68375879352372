import React, { useCallback, useEffect, useRef, useState } from "react";
import RegistrationStatusConfirmationModal from "./RegistrationStatusConfirmationModal";
import RegistrationStatusMenu from "./RegistrationStatusMenu";

import TripEventsApi from "../../../../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../../../../lib/coc-common-scripts";
import { notify } from "react-notify-toast";
import axios from "axios";

import { TripRegistrationStatuses } from "../../../../../TripEventConsts";
import { getRegistrationStatusColor } from "../../../../../TripEventLogic";

function RegistrationStatusActions(props) {
  const {
    forTableRecord,
    isTravelTrip,
    programScheduleName,
    onUpdate,
    registration,
  } = props;

  const {
    id: registrationId,
    status,
    statusDisplay,
    total,
    trackName,
  } = registration;
  const campusName = forTableRecord
    ? registration.campusName
    : registration.campus?.name;
  const firstName = forTableRecord
    ? registration.studentFirstName
    : registration.student?.person?.firstName;

  const [statusUpdate, setStatusUpdate] = useState();
  const [
    showStatusUpdateConfirmationModal,
    setShowStatusUpdateConfirmationModal,
  ] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState("");

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const onStatusUpdate = useCallback((statusUpdate) => {
    setStatusUpdate(statusUpdate);
    setShowStatusUpdateConfirmationModal(true);
  }, []);

  const onCancelStatusUpdate = useCallback(() => {
    setStatusUpdate(undefined);
    setShowStatusUpdateConfirmationModal(false);
    setErrorMessage("");
  }, []);

  const onConfirmStatusUpdate = useCallback(
    async (skipRejectionEmail = false) => {
      setErrorMessage("");
      setLoading(true);

      try {
        const updatedRegistration =
          await TripEventsApi.updateTripRegistrationStatus(
            apiSignalRef.current.token,
            registrationId,
            statusUpdate,
            skipRejectionEmail
          );

        setShowStatusUpdateConfirmationModal(false);
        onUpdate(updatedRegistration);

        notify.show(`Registration has been ${statusUpdate}`, "success");
        if (!forTableRecord) setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          setErrorMessage(ApiCallErrorMessageHandler(err));
          setLoading(false);
        }
      }
    },
    [forTableRecord, onUpdate, registrationId, statusUpdate]
  );

  const onWorkflowStepUpdate = useCallback(
    async (workflowStepUpdate) => {
      setLoading(true);

      try {
        const updatedRegistration =
          await TripEventsApi.updateTripRegistrationWorkflowStep(
            apiSignalRef.current.token,
            registrationId,
            workflowStepUpdate
          );

        onUpdate(updatedRegistration);
        notify.show("Registration status has been updated", "success");
        if (!forTableRecord) setLoading(false);
      } catch (err) {
        if (!axios.isCancel(err)) {
          notify.show("Registration could not be updated", "error");
          setLoading(false);
        }
      }
    },
    [forTableRecord, onUpdate, registrationId]
  );

  return (
    <>
      {isTravelTrip ? (
        <RegistrationStatusMenu
          forTableRecord={forTableRecord}
          loading={loading}
          onChange={(statusUpdate, workflowStepUpdate) => {
            if (statusUpdate !== status) {
              onStatusUpdate(statusUpdate);
            } else {
              onWorkflowStepUpdate(workflowStepUpdate);
            }
          }}
          registration={registration}
        />
      ) : (
        <>
          {(status === TripRegistrationStatuses.Pending ||
            status === TripRegistrationStatuses.Rejected) &&
            (forTableRecord ? (
              <i
                className="material-icons ml-8 link-text-secondary"
                onClick={() =>
                  onStatusUpdate(TripRegistrationStatuses.Accepted)
                }
                style={{ color: "#76E269" }}
              >
                thumb_up
              </i>
            ) : (
              <button
                className="btn registration-btn mr-16"
                onClick={() =>
                  onStatusUpdate(TripRegistrationStatuses.Accepted)
                }
              >
                <i
                  className="material-icons medium-text mr-8"
                  style={{ color: "#76E269" }}
                >
                  thumb_up
                </i>
                Accept
              </button>
            ))}
          {status === TripRegistrationStatuses.Pending &&
            (forTableRecord ? (
              <i
                className="material-icons ml-8 link-text-secondary"
                onClick={() =>
                  onStatusUpdate(TripRegistrationStatuses.Rejected)
                }
                style={{ color: "#DC313F" }}
              >
                thumb_down
              </i>
            ) : (
              <button
                className="btn registration-btn mr-16"
                onClick={() =>
                  onStatusUpdate(TripRegistrationStatuses.Rejected)
                }
              >
                <i
                  className="material-icons medium-text mr-8"
                  style={{ color: "#DC313F" }}
                >
                  thumb_down
                </i>
                Reject
              </button>
            ))}
          {!forTableRecord && (
            <div
              className="registration-tag small-text"
              style={{
                backgroundColor: getRegistrationStatusColor(status),
              }}
            >
              {statusDisplay}
            </div>
          )}
        </>
      )}
      <RegistrationStatusConfirmationModal
        cancel={onCancelStatusUpdate}
        confirm={onConfirmStatusUpdate}
        errorMessage={errorMessage}
        loading={loading}
        registrationDetails={{
          campusName,
          isTravelTrip,
          programScheduleName,
          status,
          studentFirstName: firstName,
          total,
        }}
        show={showStatusUpdateConfirmationModal}
        status={statusUpdate}
        trackName={trackName}
      />
    </>
  );
}

export default React.memo(RegistrationStatusActions);
