/** @param {Array<any>} array
 * @param {string} key
 * @returns {{ [property: string]: any }}
 */
export function ArrayToLookup(array, key) {
  return array.reduce(
    (returnObject, arrayItem) => ({
      ...returnObject,
      [arrayItem[key]]: arrayItem,
    }),
    {},
  );
}
