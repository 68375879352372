import React from "react";
import { Link } from "react-router";

import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import viewHistory from "../../services/ViewHistory";
import { ReportingTile, ReportingTileStyled } from "../common/ReportingTile";
import {
  GetReportValue,
  MissingFieldValue,
} from "../../assets/scripts/reportingHelpers";
import LamplightersReportingApi from "../../services/resources/LamplightersReportingApi";
import LamplightersSelection from "./LamplightersSelection";

export class LamplightersReporting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedSessionId: 0,
    };

    this.handleSessionChange = this.handleSessionChange.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.LampligthersSummary)) {
      return;
    }

    viewHistory.add("Program", {
      title: "Lamplighters",
      path: "/lamplighters",
    });
    this.requestSessionList();
  }

  handleSessionChange(selectedSessionId) {
    if (selectedSessionId !== this.state.selectedSessionId) {
      this.setState({ selectedSessionId }, this.requestReportData);
    }
  }

  requestSessionList() {
    this.setState({ loading: true });

    LamplightersReportingApi.getList()
      .then((response) => {
        /** @type {Array<number>} */
        const allSessions = response.data.allSessions || [];
        const selectedSessionId = allSessions[0] || 0;
        this.setState(
          { sessions: response.data.allSessions, selectedSessionId },
          this.requestReportData,
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {AxiosPromise} */
    let apiCall;

    if (this.state.selectedSessionId) {
      apiCall = LamplightersReportingApi.getSingle(
        this.state.selectedSessionId,
      );
    } else {
      apiCall = LamplightersReportingApi.getByRange(
        moment.utc().year(),
        moment.utc().year(),
      );
    }

    apiCall
      .then((response) => {
        this.setState({
          reportData: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.LampligthersSummary)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { reportData, selectedSessionId } = this.state;

    const allocationData = reportData ? reportData.allocationData : {};
    const achievementData = reportData ? reportData.achievementData : {};
    const financialData = reportData ? reportData.financialData : {};
    /** @param {object} financialValues
     * @param {string} grantType
     */
    const financialDataForRender = (financialValues, grantType) => {
      if (!financialValues || !financialValues[grantType]) {
        return MissingFieldValue;
      }
      const currencyFormatterUS = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return (
        <div>
          {financialValues[grantType].map((f) => (
            <div key={f.status} className="container-fluid">
              <div className="row">
                <div className="col-sm-5">{f.status}</div>
                <div className="col-sm-2">{f.count}</div>
                <div className="col-sm-5">
                  {currencyFormatterUS.format(f.count * f.price)}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    };
    const financialDataValueStyle = { fontSize: "1.5em" };

    /** @type {Array<any>} */
    const sessions = (this.state.sessions && this.state.sessions) || [];

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/lamplighters">Lamplighters</Link>
              </li>
              <li>Reports</li>
            </ol>
          </div>
          <div className="col-sm-6">
            <LamplightersSelection
              sessions={sessions}
              selectedSessionId={selectedSessionId}
              handleSessionChange={this.handleSessionChange}
            />
          </div>
          {selectedSessionId > 0 && (
            <div className="col-md-12">
              <h2>{`Year ${selectedSessionId}`}</h2>
            </div>
          )}
          <div>
            <div className="col-md-12">
              <h3>Allocated</h3>
            </div>
            <div className="col-md-12">
              <div className="col-md-3">
                <ReportingTile
                  label="Intern"
                  value={GetReportValue(allocationData, "intern")}
                />
              </div>
              <div className="col-md-3">
                <ReportingTile
                  label="Learning"
                  value={GetReportValue(allocationData, "learning")}
                />
              </div>
              <div className="col-md-3">
                <ReportingTile
                  label="Engagement"
                  value={GetReportValue(allocationData, "engagement")}
                />
              </div>
            </div>
            <div className="col-md-12">
              <h3>Achievements</h3>
            </div>
            <div className="col-md-12">
              <div className="col-md-3">
                <ReportingTile
                  label="Intern"
                  value={GetReportValue(achievementData, "intern")}
                />
              </div>
              <div className="col-md-3">
                <ReportingTile
                  label="Learning"
                  value={GetReportValue(achievementData, "learning")}
                />
              </div>
              <div className="col-md-3">
                <ReportingTile
                  label="Engagement"
                  value={GetReportValue(achievementData, "engagement")}
                />
              </div>
            </div>
            <div className="col-md-12">
              <h3>Financials</h3>
            </div>
            <div className="col-md-12">
              <div className="col-md-6">
                <ReportingTileStyled
                  label="Learning"
                  value={financialDataForRender(financialData, "learning")}
                  valueStyle={financialDataValueStyle}
                />
              </div>
              <div className="col-md-6">
                <ReportingTileStyled
                  label="Engagement"
                  value={financialDataForRender(financialData, "engagement")}
                  valueStyle={financialDataValueStyle}
                />
              </div>
            </div>

            {this.state.loading && <Loader />}
          </div>
        </div>
      </div>
    );
  }
}
