import React from "react";
import Modal from "react-modal";
import DateTimePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import ValidatedInput from "../../../shared/ValidatedInput";
import { removeTimezoneFormatFromDate } from "../../../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import moment from "moment";

export default class TourScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      schedule: {
        maximumParticipants: "",
        name: "",
        time: "",
        groupSize: "",
      },
      submitAttempted: false,
    };
    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    const { schedule, show } = this.props;

    if (show && !prevProps.show) {
      const initialSchedule = schedule || {
        maximumParticipants: "",
        name: "",
        time: "",
        groupSize: "",
      };

      const newState = {
        schedule: initialSchedule,
        submitAttempted: false,
      };
      newState.initialState = _cloneDeep(newState);

      this.setState(newState);
    }
  }

  onChangeSchedule = (name, value) => {
    this.setState({
      schedule: {
        ...this.state.schedule,
        [name]: value,
      },
    });
  };

  onClose = () => {
    this.props.close();
    this.setState(this.state.initialState);
  };

  onSave = () => {
    this.setState(
      {
        submitAttempted: true,
      },
      () => {
        const {
          schedule: { name, time },
        } = this.state;

        if (!name || !time) {
          return;
        }

        this.props.save(this.state.schedule);

        this.onClose();
      },
    );
  };

  render() {
    const {
      hasStartedShluchimEnrollment,
      schedule: scheduleForEdit,
      tripEndDate,
      tripStartDate,
    } = this.props;

    const {
      initialState: { schedule: initialSchedule },
      schedule,
      schedule: { id: scheduleId, maximumParticipants, name, time, groupSize },
      submitAttempted,
    } = this.state;

    return (
      <Modal
        isOpen={this.props.show}
        className="new-schedule-modal-container modal-container"
      >
        <div className="modal-card card new-schedule-modal">
          <p className="large-text fw-500 mb-16">
            {!scheduleForEdit ? "New" : "Edit"} Schedule
          </p>
          <div className="flex flex-align-center flex-justify-space mb-12 mr-32">
            <label className="accent-text-dark">Name</label>
            <ValidatedInput
              input={<input type="text" className="schedule-modal-input" />}
              name="name"
              onChange={(e) =>
                this.onChangeSchedule(e.target.name, e.target.value)
              }
              required={true}
              showRequired={submitAttempted}
              value={name}
            />
          </div>
          <div className="flex flex-align-center flex-justify-space mb-12 mr-32">
            <label className="accent-text-dark">Date & Time</label>
            <DateTimePicker
              className={`custom-input schedule-modal-input ${submitAttempted && !time ? "error" : ""
                }`} //= required={true}
              dateFormat="MM/DD/YYYY h:mm a"
              maxDate={moment(tripEndDate)}
              minDate={moment(tripStartDate)}
              name="time"
              onChange={(date) =>
                this.onChangeSchedule(
                  "time",
                  removeTimezoneFormatFromDate(date),
                )
              }
              selected={time ? moment(time) : null}
              showTimeSelect={true}
              timeIntervals={1}
            />
          </div>
          <div className="flex flex-align-center flex-justify-space mb-12 mr-32">
            <label className="accent-text-dark">
              Max Capacity{" "}
              <span className="accent-text xs-text">(optional)</span>
            </label>
            <NumberFormat
              className="custom-input small-input"
              name="maximumParticipants"
              onChange={(e) =>
                this.onChangeSchedule(e.target.name, e.target.value)
              }
              value={maximumParticipants}
              type="text"
              allowNegative={false}
              decimalScale={0}
            />
          </div>
          <div className="flex flex-align-center flex-justify-space mb-16 mr-32">
            <label className="accent-text-dark">
              Group Size <span className="accent-text xs-text">(optional)</span>
            </label>
            <NumberFormat
              className="custom-input small-input"
              name="groupSize"
              onChange={(e) =>
                this.onChangeSchedule(e.target.name, e.target.value)
              }
              value={groupSize}
              allowNegative={false}
              decimalScale={0}
            />
          </div>
          {!!(
            hasStartedShluchimEnrollment &&
            scheduleId &&
            initialSchedule.time !== time
          ) && (
              <span className="error-text ml-8">
                <i className="material-icons large-text">warning</i>
                <span className="ml-8">
                  Note that event registration has started, and shluchim and/or
                  students may be enrolled in this tour. Please beware of the fact
                  that changing the schedule date or time may cause conflicts in
                  enrollees' schedules.
                </span>
              </span>
            )}
          <div className="modal-btns flex flex-align-center flex-justify-end mt-16">
            <button
              className="btn link-text uppercase-text fw-500"
              onClick={this.onClose}
            >
              Cancel
            </button>
            <button
              className="btn link-text uppercase-text fw-500 ml-24"
              disabled={_isEqual(initialSchedule, schedule)}
              onClick={this.onSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
