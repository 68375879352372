import React from "react";
import { browserHistory, Link } from "react-router";
import Configuration from "./RaffleWizard/Configuration";
import Prizes from "./RaffleWizard/Prizes";
import LegalText from "./RaffleWizard/LegalText";
import MarketingContent from "./RaffleWizard/MarketingContent";
import PublishConfirmationModal from "../shared/ConfirmationModal";
import FadeOutErrorMessage from "../shared/FadeOutErrorMessage";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";
import { notify } from "react-notify-toast";

import RafflesApi from "../../services/resources/RafflesApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";
import { replaceValuesInObject } from "../../lib";
import { getRequiredRaffleFields } from "./RaffleLogic";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class NewRafflePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      authorized: true,

      errorMessage: "",
      loading: true,

      publishRaffleErrorMessage: "",
      publishRaffleLoading: false,

      raffle: null,

      requestedNav: false,

      requiredRaffleFields: {},

      saveRaffleDraftErrorMessage: "",
      saveRaffleDraftLoading: false,

      showPublishConfirmationModal: false,

      submitAttempted: false,

      wizardIndex: 0,
      wizardSteps: [
        { name: "Configuration", component: Configuration },
        { name: "Prizes", component: Prizes },
        { name: "Legal Text", component: LegalText },
        { name: "Marketing Content", component: MarketingContent },
      ],
    };

    this.state.initialRaffle = _cloneDeep(this.state.raffle);
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.RaffleFullEdit)) {
      this.setState({ authorized: false });
    }

    this.props.router.setRouteLeaveHook(this.props.route, this.onLeave);

    this.getRaffleForSchedule();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { initialRaffle, raffle, requestedNav } = this.state;

    if (!requestedNav && !_isEqual(initialRaffle, raffle)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getRaffleForSchedule = () => {
    RafflesApi.getRaffle(
      this.apiSignal.token,
      this.props.params.scheduleId,
      true,
    )
      .then((raffleDetails) => {
        const newState = {
          loading: false,
        };

        const raffle = this.formatRaffleForForm(raffleDetails);

        newState.raffle = raffle;
        newState.initialRaffle = raffle;

        this.setState({ ...this.state, ...newState });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          });
        }
      });
  };

  formatRaffleForForm = (raffle) => {
    const formattedRaffle = { ...raffle };

    replaceValuesInObject(
      formattedRaffle,
      (prop) => prop === null,
      () => "",
    );

    return formattedRaffle;
  };

  getErrorClassName = (className, err) =>
    err ? `${className} error` : className;

  onChangeRaffle = (name, value) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(raffle, name, value);

    this.setState({ raffle });
  };

  onChangeEventRaffle = (event) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(raffle, event.target.name, event.target.value);

    this.setState({ raffle });
  };

  onImageUpload = (name, value) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(
      raffle,
      name,
      raffle?.defaultCampaignImages
        ? [...raffle.defaultCampaignImages, { url: value }]
        : [{ url: value }],
    );

    this.setState({ raffle });
  };

  onImageDelete = (name, value) => {
    let raffle = _cloneDeep(this.state.raffle);
    _set(raffle, name, [
      ...raffle?.defaultCampaignImages.filter((img) => img.url !== value),
    ]);

    this.setState({ raffle });
  };
  onCancelRaffle = () => {
    this.setState(
      {
        raffle: this.state.initialRaffle,
        requestedNav: true,
      },
      () => {
        //if did not create an event altogether, redirect to general grand-draw page.  otherwise redirect to raffle details page.
        browserHistory.push(
          this.state.raffle.id
            ? `/grand-draw/${this.props.params.scheduleId}`
            : "/grand-draw",
        );
      },
    );
  };

  onPublishRaffle = () => {
    const requiredRaffleFields = getRequiredRaffleFields(this.state.raffle);
    this.setState({
      requiredRaffleFields,
      showPublishConfirmationModal: true,
      submitAttempted: true,
    });
  };

  publishRaffle = () => {
    const publishedRaffle = _cloneDeep({
      ...this.state.raffle,
      isPublished: true,
    });

    this.setState(
      {
        publishRaffleErrorMessage: "",
        publishRaffleLoading: true,
      },
      () => {
        RafflesApi.updateRaffle(this.apiSignal.token, publishedRaffle)
          .then(() => {
            notify.show("Your Grand Draw has been published", "success");

            this.setState(
              {
                requestedNav: true,
              },
              () => {
                browserHistory.push(
                  `/grand-draw/${this.props.params.scheduleId}`,
                );
              },
            );
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                publishRaffleErrorMessage: ApiCallErrorMessageHandler(err),
                publishRaffleLoading: false,
              });
            }
          });
      },
    );
  };

  saveRaffleDraft = () => {
    const raffleDraft = _cloneDeep({
      ...this.state.raffle,
      isPublished: false,
    });

    this.setState(
      {
        saveRaffleDraftErrorMessage: "",
        saveRaffleDraftLoading: true,
      },
      () => {
        RafflesApi.updateRaffle(this.apiSignal.token, raffleDraft)
          .then(() => {
            notify.show("Your Grand Draw draft has been saved", "success");

            this.setState(
              {
                requestedNav: true,
              },
              () => {
                browserHistory.push(
                  `/grand-draw/${this.props.params.scheduleId}`,
                );
              },
            );
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                saveRaffleDraftErrorMessage: ApiCallErrorMessageHandler(err),
                saveRaffleDraftLoading: false,
              });
            }
          });
      },
    );
  };

  wizardNav = (index) => {
    this.setState({
      wizardIndex: index,
    });
  };

  render() {
    const {
      authorized,
      errorMessage,
      initialRaffle,
      loading,
      publishRaffleErrorMessage,
      publishRaffleLoading,
      raffle,
      requiredRaffleFields,
      saveRaffleDraftErrorMessage,
      saveRaffleDraftLoading,
      showPublishConfirmationModal,
      submitAttempted,
      wizardIndex,
      wizardSteps,
    } = this.state;

    const isDraft = raffle && !!raffle.id;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="page raffles-page">
        <ProgramBreadcrumbsHeader
          getPageTitle={(sched) => `${sched.scheduleName} - New Grand Draw`}
          scheduleId={this.props.params.scheduleId}
          style={{ marginTop: "72px", marginLeft: "16px" }}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !raffle ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong.  Please try again."}
            </p>
          </div>
        ) : raffle.isPublished ? (
          <div className="full-page-error-text">
            <p className="accent-text">
              You cannot create a Grand Draw because there is a current
              published Grand Draw. View current Grand Draw details{" "}
              <Link to={`/grand-draw/${raffle.programScheduleID}`}>here</Link>.
            </p>
          </div>
        ) : !raffle.startDate || !raffle.endDate ? (
          <div>
            In order to configure your Grand Draw, please first schedule a Grand
            Draw in the <Link to="/Programs">Programs section</Link> of the
            portal.
          </div>
        ) : (
          <React.Fragment>
            <div className="raffles-page-subheader">
              <div className="container flex flex-justify-space flex-align-center">
                <div className="raffles-page-wizard-steps">
                  <div className="flex">
                    {wizardSteps.map((step, index) => (
                      <div
                        key={index}
                        className={`wizard-step ${
                          wizardIndex === index ? "active" : ""
                        }`}
                        onClick={() => this.wizardNav(index)}
                      >
                        {!isDraft && (
                          <p className="wizard-step-number">{index + 1}</p>
                        )}
                        <p
                          className={`wizard-step-title ${
                            isDraft ? "wizard-step-title-tab" : ""
                          }`}
                        >
                          {step.name}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="raffles-page-subheader-actions">
                  <button
                    className="btn btn-cancel btn-medium uppercase-text"
                    onClick={this.onCancelRaffle}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-accent btn-medium ml-16 uppercase-text"
                    disabled={
                      saveRaffleDraftLoading || _isEqual(initialRaffle, raffle)
                    }
                    onClick={this.saveRaffleDraft}
                  >
                    {saveRaffleDraftLoading ? "Saving..." : "Save Draft"}
                  </button>
                  {!saveRaffleDraftLoading && saveRaffleDraftErrorMessage && (
                    <FadeOutErrorMessage
                      message={saveRaffleDraftErrorMessage}
                      onTimeout={() =>
                        this.setState({
                          saveRaffleDraftErrorMessage: "",
                        })
                      }
                    />
                  )}
                  {(isDraft || wizardIndex === wizardSteps.length - 1) && (
                    <button
                      className="btn btn-accent btn-medium ml-16 uppercase-text"
                      disabled={publishRaffleLoading}
                      onClick={this.onPublishRaffle}
                    >
                      Publish
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="container">
              {React.createElement(wizardSteps[wizardIndex].component, {
                getErrorClassName: this.getErrorClassName,
                onChange: this.onChangeRaffle,
                onChangeEvent: this.onChangeEventRaffle,
                onImageUpload: this.onImageUpload,
                onImageDelete: this.onImageDelete,
                raffle,
                submitAttempted,
              })}

              <div className="raffles-page-wizard-btns">
                <div className="prev-btn-container">
                  {wizardIndex > 0 && (
                    <button
                      className="btn btn-cancel btn-medium uppercase-text"
                      onClick={() => this.wizardNav(wizardIndex - 1)}
                      tabIndex={510}
                    >
                      Prev
                    </button>
                  )}
                </div>
                <div className="next-btn-container">
                  {wizardIndex < wizardSteps.length - 1 && (
                    <button
                      className="btn btn-accent btn-medium uppercase-text"
                      onClick={() => this.wizardNav(wizardIndex + 1)}
                      tabIndex={500}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <PublishConfirmationModal
          cancel={() => this.setState({ showPublishConfirmationModal: false })}
          cancelText={requiredRaffleFields.isIncomplete ? "OK" : "Cancel"}
          confirm={this.publishRaffle}
          confirmText="Publish"
          errorMessage={publishRaffleErrorMessage}
          message={
            requiredRaffleFields.isIncomplete
              ? `Please update the following required or invalid fields before publishing this Grand Draw:
                            \n${requiredRaffleFields.incompleteFields}`
              : "You are about to publish this Grand Draw. This can't be undone. Would you like to proceed?"
          }
          noConfirm={requiredRaffleFields.isIncomplete}
          loading={publishRaffleLoading}
          show={showPublishConfirmationModal}
        />
      </div>
    );
  }
}
