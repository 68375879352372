import React from "react";
import CreatableSelect from "react-select/lib/Creatable";
import get from "lodash.get";

export default class CreatableSingle extends React.PureComponent {
  render() {
    const {
      errors,
      isClearable,
      disabled,
      name,
      options,
      placeholder,
      setFieldValue,
      touched,
      readOnly,
      value,
    } = this.props;
    const error = get(errors, name) && get(touched, name);
    const styles = {
      indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
      placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
      clearIndicator: (styles) => ({
        ...styles,
        marginRight: "-8px",
        padding: "0",
      }),
    };
    return (
      <CreatableSelect
        className={`custom-select ${error ? `error` : ""} ${
          readOnly ? "read-only" : ""
        }`}
        classNamePrefix="custom-select"
        getOptionLabel={(option) => option.label}
        isClearable={isClearable}
        disabled={disabled || readOnly}
        name={name}
        onChange={(option) => setFieldValue(name, option)}
        options={options}
        placeholder={placeholder}
        styles={styles}
        value={value}
      />
    );
  }
}
