import React from "react";
import Modal from "react-modal";

export default ({ close, isOpen }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="programs-help-modal"
      contentLabel="Program HTML Editor Help"
    >
      <h3>Program HTML Editor Help</h3>
      <p>Here are some guidelines for using the Program HTML Editor.</p>
      <h4>Embedding Third Party Content in HTML</h4>
      <ul>
        <li>
          Embedding a script with a{" "}
          <em>
            <strong>src</strong>
          </em>{" "}
          attribute that loads javascript from a third party does not work due
          to browser- security.
        </li>
        <li>
          Therefore, to embed a JotForm or something similar, you must use the{" "}
          <em>
            <strong>iframe</strong>
          </em>{" "}
          embedding.
        </li>
        <li>
          Embedded JotForms will appear in a modal; a button to open the modal
          will be displayed within the section content. Please follow these
          instructions when embedding JotForms:
          <br />
          <ul>
            <li>
              In the HTML source code, create a p tag at the top level (do not
              nest within any other elements), with an id of{" "}
              <em>'jotform-link'</em>. Place the text for the button that will
              open the modal within the tag.
            </li>
            <li>
              Create another top-level p tag, with an id of <em>'jotform'</em>,
              and place the jotform iframe code within the tag. (Copy the code
              from the <em>Embed - iFrame</em> option when publishing the
              JotForm.)
            </li>
            <li>
              Set both the height and min-width of the jotform iframe to 100%,
              and 'scrolling' to yes.
            </li>
          </ul>
          Example:
          <br />
          <em style={{ whiteSpace: "pre-wrap" }}>
            {`<p id="jotform-link">Click here to view the form</p>`}
            <br />
            {`<p id="jotform">`}
            <br />
            {"  "}
            {`<iframe src="https://form.jotform.com/############" style="min-width: 100%; height: 100%; border: none;" scrolling="yes" frameborder="0">`}
            <br />
            {"  "}
            {`</iframe>`}
            <br />
            {"  "}
            {`<script type="text/javascript">`}
            <br />
            {"    "}
            {`{...jotform script content here}`}
            <br />
            {"  "}
            {`</script>`}
            <br />
            {`</p>`}
            <br />
          </em>
        </li>
        <li>
          Embedding a script tag that simply contains some JavaScript code does{" "}
          <strong>not</strong> work at this time.
        </li>
        <li>
          Please be aware that your iframe embeds will load and execute when you
          visit this <strong>editor page</strong>.
        </li>
        <li>
          Also, please note that you may receive messages that you have{" "}
          <strong>Unsaved Changes</strong> after embedding an iframe that
          changes the HTML content. We have no control over this behavior so
          just ignore the message.
        </li>
      </ul>
      <div className="programs-help-close" title="Close. (ESC)" onClick={close}>
        x
      </div>
    </Modal>
  );
};
