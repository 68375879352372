import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import PaginatedTable from "../../../shared/PaginatedTable";
import StudentsExportButton from "./StudentsExportButton";
import StudentsTableFilters from "./StudentsTableFilters";
import StudentsTableHeader from "./StudentsTableHeader";
import StudentsTableRow from "./StudentsTableRow";
import { formatFullName } from "../../../../lib";

import LamplightersApi from "../../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import queryString from "query-string";
import axios from "axios";
import Loader from "../../../common/Loader";

export default class StudentsTable extends React.PureComponent {
  constructor(props) {
    super();
    const {
      location: {
        query: {
          grantId,
          grantStatus,
          includeInactive,
          keyword,
          shliachId,
          studentId,
          page,
        },
      },
    } = props;

    this.state = {
      action: null,
      actionErrorMessage: "",
      actionLoading: false,
      actionPayload: null,
      actionValidationLoading: false,
      actionValidationMessage: "",
      errorMessage: "",
      filters: {
        grantId: grantId || "",
        grantStatus: grantStatus || "",
        includeInactive: includeInactive || false,
        keyword: keyword || "",
        shliachId: shliachId || "",
        studentId: studentId || "",
        page: page || 1,
      },
      loading: false,
      results: 12,
      showActionConfirmationModal: false,
      sortBy: "studentLastName",
      students: [],
      success: true,
      totalStudents: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterStudents = (filters, results, sortBy) => {
    this.setState({ filters }, () => {
      this.getStudents(results, sortBy);
      this.updateRouterQuery(filters);
    });
  };

  updateRouterQuery = (params) => {
    //update router query params
    const {
      location: { pathname, query },
    } = this.props;

    //clear out valueless params
    Object.keys(params).forEach((k) => (params[k] = params[k] || undefined));

    const queryParams = {
      ...query,
      ...params,
    };

    browserHistory.replace(`${pathname}?${queryString.stringify(queryParams)}`);
  };

  getStudents = (
    results = this.state.results,
    sortBy = this.state.sortBy,
    isRefresh = false,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: isRefresh ? false : true,
        results,
        sortBy,
        success: true,
      },
      () => {
        LamplightersApi.getStudents(
          this.apiSignal.token,
          this.props.schedule.id,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ students, totalStudents }) => {
            this.setState({
              loading: false,
              students,
              totalStudents,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                students: [],
                success: false,
                totalStudents: 0,
              });
            }
          });
      },
    );
  };

  getStudentsForExport = async () => {
    const { sortBy, filters } = this.state;

    const studentsForExport = await LamplightersApi.getStudents(
      this.apiSignal.token,
      this.props.schedule.id,
      undefined,
      sortBy,
      filters,
      true,
    );

    return studentsForExport.students;
  };

  //Students Grant Actions
  studentGrantActions = {
    Activate: "Activate",
    Deactivate: "Deactivate",
    SetPrimary: "SetPrimary",
    Submit: "Submit",
    Unsubmit: "Unsubmit",
    // LogInteraction: "LogInteraction",
  };

  onStudentGrantAction = async (action, actionPayload) => {
    const isGrantActivationAction =
      action === this.studentGrantActions.Activate;

    this.setState({
      action,
      actionPayload,
      actionValidationLoading: isGrantActivationAction,
      errorMessage: "",
      showActionConfirmationModal: true,
      // [action === this.studentGrantActions.LogInteraction
      //   ? "showStudentInteractionModal"
      //   : "showActionConfirmationModal"]: true,
    });

    //validate availability for student grant activation
    if (isGrantActivationAction) {
      const {
        chabadHouseName,
        enrollmentID,
        grant: { grantID, grantTypeDisplay } = "",
      } = actionPayload;

      let actionValidationMessage = "";

      //retrieve enrollment allocation details to determine availability
      try {
        const grantQuantityAvailable =
          await LamplightersApi.getLocationAvailableGrantQuantity(
            this.apiSignal.token,
            enrollmentID,
            grantID,
          );
        if (!grantQuantityAvailable) {
          actionValidationMessage = `This student cannot be activated for this ${
            grantTypeDisplay || ""
          } grant because this location has met the allocated quantity for this grant`;
        }
      } catch (err) {
        actionValidationMessage = `Cannot retrieve allocation details for ${chabadHouseName} to determine grant eligibility for this student`;
      }

      this.setState({
        actionValidationLoading: false,
        actionValidationMessage,
      });
    }
  };

  getGrantActionConfirmationTitle = () => {
    const { action } = this.state;

    let actionName = this.studentGrantActions[action];
    if (action === this.studentGrantActions.SetPrimary) {
      actionName = "Set Primary";
    }

    return `${actionName} Student Grant Confirmation`;
  };

  getGrantActionConfirmationMessage = () => {
    const { action, actionPayload } = this.state;

    const actionGrantType =
      (actionPayload &&
        actionPayload.grant &&
        actionPayload.grant.grantTypeDisplay) ||
      "";

    const studentName =
      (actionPayload &&
        formatFullName(
          actionPayload.studentFirstName,
          actionPayload.studentLastName,
        )) ||
      "this student";

    if (action === this.studentGrantActions.SetPrimary) {
      return `Are you sure you want to set this ${actionGrantType} grant to the primary grant for ${studentName}?`;
    }

    const actionName = (this.studentGrantActions[action] || "").toLowerCase();

    const actionDescriptor =
      this.studentGrantActions[action] === this.studentGrantActions.Deactivate
        ? " from the"
        : this.studentGrantActions[action] === this.studentGrantActions.Activate
        ? " for the"
        : "'s";

    let actionDisclaimer = "";
    if (
      this.studentGrantActions[action] === this.studentGrantActions.Activate &&
      actionGrantType === "Learning"
    ) {
      actionDisclaimer = `\n\nPlease confirm that ${studentName}: \n • is not currently enrolled in Sinai Scholars \n • did not participate in Sinai Scholars in the past \n • does not have plans to participate in Sinai Scholars in the future`;
    } else if (
      this.studentGrantActions[action] === this.studentGrantActions.Submit &&
      !actionPayload.grant.cityOfOrigin
    ) {
      actionDisclaimer =
        "\n\nStudent’s city of origin is not set. Setting the city of origin prior to sumbmission is strongly recommended.";
    }

    return `Are you sure you want to ${actionName} ${studentName}${actionDescriptor} ${actionGrantType} grant?${actionDisclaimer}`;
  };

  onCloseStudentGrantAction = () => {
    this.setState({
      action: null,
      actionErrorMessage: "",
      actionLoading: false,
      actionPayload: null,
      actionValidationMessage: "",
      errorMessage: "",
      showActionConfirmationModal: false,
      // showStudentInteractionModal: false,
    });
  };

  submitStudentGrantAction = async () => {
    this.setState({
      actionErrorMessage: "",
      actionLoading: true,
    });

    const {
      action,
      actionPayload: {
        grant: {
          enrollmentID,
          grantID,
          isPrimary,
          isSubmitted,
          studentGrantID,
          studentID,
        } = "",
      },
    } = this.state;

    const studentGrant = {
      enrollmentID,
      grantID,
      id: studentGrantID,
      isEnrolled: action !== this.studentGrantActions.Deactivate,
      isPrimary:
        action === this.studentGrantActions.SetPrimary ? true : isPrimary,
      isSubmitted:
        action === this.studentGrantActions.Submit
          ? true
          : action === this.studentGrantActions.Unsubmit
          ? false
          : isSubmitted,
      studentID,
    };

    try {
      await LamplightersApi.submitStudentGrant(
        this.apiSignal.token,
        studentGrant,
      );

      this.getStudents(); //TODO: when reload list on mobile, does it lose scroll position?
      this.setState({
        action: null,
        actionLoading: false,
        actionPayload: null,
        showActionConfirmationModal: false,
      });
    } catch (err) {
      this.setState({
        actionErrorMessage: ApiCallErrorMessageHandler(err),
        actionLoading: false,
      });
    }
  };

  render() {
    const { readOnly, schedule, grantStatuses = [], shluchimList } = this.props;

    const {
      didScheduleStart,
      grants,
      id: scheduleId,
      programScheduleName,
    } = schedule;

    const {
      action,
      actionErrorMessage,
      actionValidationLoading,
      actionValidationMessage,
      actionLoading,
      filters,
      loading,
      results,
      showActionConfirmationModal,
      sortBy,
      students,
      success,
      totalStudents,
    } = this.state;

    const { grantId, grantStatus, includeInactive, keyword, studentId, page } =
      filters;
    const isLamplightersActiveOnlyUnfilteredTable =
      !includeInactive && !grantId && !grantStatus && !keyword && !studentId;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Students</p>
          <div className="flex flex-align-center">
            {/* TODO: manage duplicates modal or link to page? */}
            {/* {!readOnly && (
              <button
                className="btn custom-btn btn-accent mr-16"
              >
                Manage Duplicates
              </button>
            )} */}
            <StudentsExportButton
              getStudentsForExport={this.getStudentsForExport}
              programScheduleName={programScheduleName}
            />
          </div>
        </div>
        <div className="flex flex-align-center flex-justify-space">
          <StudentsTableFilters
            applyFilters={this.filterStudents}
            grantStatuses={grantStatuses}
            filters={filters}
            grants={grants}
            scheduleId={scheduleId}
            shluchimList={shluchimList}
          />
        </div>
        <div className="lamplighters-students-table">
          <PaginatedTable
            loadData={(page, results, sortBy) => {
              this.filterStudents({ ...filters, page: page }, results, sortBy);
            }}
            loading={loading}
            noRecordsDisplay={
              isLamplightersActiveOnlyUnfilteredTable && (
                <React.Fragment>
                  <img src="/images/no_profiles.svg" alt="" />
                  <p className="mt-16 mb-8">No active lamplighter students</p>
                  <p className="accent-text mb-16">
                    There are no active lamplighter students
                  </p>
                  <button
                    className="btn btn-medium btn-accent"
                    onClick={() =>
                      this.filterStudents({ ...filters, includeInactive: true })
                    }
                    style={{ fontSize: "13px", padding: "0px 16px" }}
                  >
                    Show all students
                  </button>
                </React.Fragment>
              )
            }
            page={page}
            records={students}
            renderHeader={() => <StudentsTableHeader grants={grants} />}
            renderRow={(student, index) => (
              <StudentsTableRow
                grants={grants}
                didScheduleStart={didScheduleStart}
                key={index}
                onActivateStudentGrant={(grant) =>
                  this.onStudentGrantAction(this.studentGrantActions.Activate, {
                    ...student,
                    grant,
                  })
                }
                onDeactivateStudentGrant={(grant) =>
                  this.onStudentGrantAction(
                    this.studentGrantActions.Deactivate,
                    {
                      ...student,
                      grant,
                    },
                  )
                }
                onLogStudentInteraction={() =>
                  this.onStudentGrantAction(
                    this.studentGrantActions.LogInteraction,
                    student,
                  )
                }
                onSetPrimaryStudentGrant={(grant) =>
                  this.onStudentGrantAction(
                    this.studentGrantActions.SetPrimary,
                    {
                      ...student,
                      grant,
                    },
                  )
                }
                onSubmitStudentGrant={(grant) =>
                  this.onStudentGrantAction(this.studentGrantActions.Submit, {
                    ...student,
                    grant,
                  })
                }
                onUnsubmitStudentGrant={(grant) =>
                  this.onStudentGrantAction(this.studentGrantActions.Unsubmit, {
                    ...student,
                    grant,
                  })
                }
                readOnly={readOnly}
                student={student}
              />
            )}
            results={results}
            sortBy={sortBy}
            success={success}
            totalCount={totalStudents}
          />
        </div>

        <ConfirmationModal
          cancel={this.onCloseStudentGrantAction}
          cancelText={actionValidationMessage && "OK"}
          confirm={this.submitStudentGrantAction}
          errorMessage={actionErrorMessage}
          loading={actionLoading}
          message={
            actionValidationLoading ? (
              <Loader />
            ) : (
              actionValidationMessage ||
              this.getGrantActionConfirmationMessage()
            )
          }
          noConfirm={actionValidationLoading || !!actionValidationMessage}
          show={showActionConfirmationModal}
          title={
            actionValidationMessage
              ? `Cannot ${this.studentGrantActions[action]} Student Grant`
              : this.getGrantActionConfirmationTitle()
          }
        />
      </div>
    );
  }
}
