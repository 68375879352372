import React from "react";
import { browserHistory, Link } from "react-router";

import Configuration from "./settings/Configuration";
import ConfirmationModal from "../shared/ConfirmationModal";
import Loader from "../common/Loader";
import Locations from "./settings/Locations";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import Timeline from "./settings/Timeline";

import { Unauthorized } from "../../lib/coc-common-components";
import { notify } from "react-notify-toast";

import LamplightersApi from "../../services/resources/LamplightersApi";
import ChabadHousesApi from "../../services/resources/ChabadHousesApi";
import SystemApi from "../../services/resources/SystemApi";
import AuthService from "../../services/AuthService";
import axios from "axios";
import { PermissionClaims } from "../../services/AuthService";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import { removeEmptyFromObj, replaceValuesInObject } from "../../lib";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import queryString from "query-string";
import moment from "moment";

export default class LamplightersSettingsPage extends React.PureComponent {
  state = {
    authorized: true,

    chabadHousesList: [],
    chabadHousesListErrorMessage: "",
    chabadHousesListLoading: false,

    errorMessage: "",
    loading: true,
    hasUnsavedAllocationsChanges: false,

    initialSchedule: null,
    schedule: null,

    saveErrorMessage: "",
    saveLoading: false,
    showSaveConfirmationModal: false,
    submitAttempted: false,

    systemLists: {},
    systemListsErrorMessage: "",

    tabIndex: 0,
    tabs: [
      { name: "Timeline", id: "timeline", component: Timeline },
      {
        name: "Configuration",
        id: "configuration",
        component: Configuration,
      },
      { name: "Locations", id: "locations", component: Locations },
    ],
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.GrantsFullView)) {
      this.setState({ authorized: false });
    }

    const {
      location: {
        query: { tab },
      },
      route,
      router,
    } = this.props;
    const { tabs } = this.state;

    const currentTabIndex = tabs.map((tab) => tab.id).indexOf(tab);
    this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0, true);

    router.setRouteLeaveHook(route, this.onLeave);

    this.getSchedule();
    this.getSystemLists();
  }

  componentDidUpdate(prevProps) {
    const {
      location: {
        query: { tab },
      },
    } = this.props;

    //handle tab update via router link
    if (tab !== prevProps.location.query.tab) {
      const { tabs, tabIndex } = this.state;
      const index = tabs.findIndex((t) => t.id === tab);
      if (index >= 0 && index !== tabIndex) {
        this.setState({ tabIndex: index });
      }
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getChabadHousesForLocationsList = async () => {
    try {
      this.setState({
        chabadHousesListErrorMessage: "",
        chabadHousesListLoading: true,
      });

      const eligibleChabadHouses = await ChabadHousesApi.getChabadHousesDetails(
        this.apiSignal.token,
      );

      this.setState({
        chabadHousesList: eligibleChabadHouses,
        chabadHousesListLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          chabadHousesListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve available locations. Please try again.",
          ),
          chabadHousesListLoading: false,
        });
      }
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "grantTypes",
        "interactionClassifications",
        "interactionDurations",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  onLeave = () => {
    const { hasUnsavedAllocationsChanges, initialSchedule, schedule } =
      this.state;

    if (hasUnsavedAllocationsChanges || !_isEqual(initialSchedule, schedule)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getSchedule = () => {
    const {
      params: { scheduleId },
    } = this.props;

    LamplightersApi.getLamplightersSchedule(
      this.apiSignal.token,
      scheduleId,
      true,
    )
      .then((schedule) => {
        const newState = {
          loading: false,
        };

        newState.schedule = this.formatScheduleForForm(schedule);
        newState.initialSchedule = _cloneDeep(newState.schedule);

        this.setState({ ...this.state, ...newState });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          });
        }
      });
  };

  formatScheduleForForm = (schedule) => {
    const scheduleForForm = { ...schedule };

    //set grant configuration helper props
    scheduleForForm.grants.forEach((grant) => {
      const { minStudentAge, maxStudentAge } = grant;
      grant.hasStudentAgeCondition = !!(maxStudentAge || minStudentAge);
      grant.classificationRequirements.forEach((requirement) => {
        requirement.hasIsraelRelatedCondition = !!requirement.israelRelatedMin;
        requirement.hasShabbosMealCondition = !!requirement.shabbosMealMax;
      });
    });

    replaceValuesInObject(
      scheduleForForm,
      (p) => p === null,
      () => "",
    );

    return scheduleForForm;
  };

  getErrorClassName = (className, err) =>
    err ? `${className} error` : className;

  incompleteScheduleErrorMessage = "Please review required fields";
  isScheduleIncomplete = () => {
    const {
      schedule: { deadlines, grants, programEndDate, programStartDate },
      tabIndex,
      tabs,
    } = this.state;

    const tab = tabs[tabIndex];
    if (tab) {
      switch (tab.id) {
        case "timeline":
          return deadlines.some(
            (d) =>
              !d.name ||
              !d.date ||
              moment(d.date) < moment(programStartDate) ||
              moment(d.date) > moment(programEndDate),
          );
        case "configuration":
          return grants.some(
            (g) =>
              !g.type ||
              g.amount <= 0 ||
              g.quantity <= 0 ||
              (!g.minInteractionsRangeWeeks &&
                g.minInteractionsRangeWeeks !== 0) ||
              g.minInteractionsRangeWeeks < 0 ||
              g.classificationRequirements.some(
                (r) =>
                  !(r.classifications?.length > 0) ||
                  r.minDuration <= 0 ||
                  r.numRequiredInteractions <= 0,
              ),
          );
        default:
          return false;
      }
    }

    return false;
  };

  onChangeSchedule = (name, value, other) => {
    let schedule = _cloneDeep(this.state.schedule);
    _set(schedule, name, value);

    if (other) {
      Object.keys(other).forEach((otherKey) =>
        _set(schedule, otherKey, other[otherKey]),
      );
    }

    this.setState({ schedule }, () => {
      //revalidate incomplete error on change
      if (
        this.state.saveErrorMessage === this.incompleteScheduleErrorMessage &&
        !this.isScheduleIncomplete()
      ) {
        this.setState({ saveErrorMessage: "" });
      }
    });
  };

  onChangeScheduleEvt = (evt) =>
    this.onChangeSchedule(evt.target.name, evt.target.value);

  onCancel = () => {
    this.setState({
      schedule: _cloneDeep(this.state.initialSchedule),
      submitAttempted: false,
    });
  };

  onSaveSchedule = () => {
    this.setState({
      saveErrorMessage: "",
      submitAttempted: true,
    });
    const isIncomplete = this.isScheduleIncomplete();

    if (isIncomplete) {
      this.setState({ saveErrorMessage: this.incompleteScheduleErrorMessage });
    } else {
      this.setState({ showSaveConfirmationModal: true });
    }
  };

  saveSchedule = () => {
    const { schedule } = this.state;

    this.setState(
      {
        saveErrorMessage: "",
        saveLoading: true,
      },
      () => {
        const scheduleForSubmission = _cloneDeep(schedule);
        removeEmptyFromObj(scheduleForSubmission);
        LamplightersApi.submitLamplightersSchedule(
          this.apiSignal.token,
          scheduleForSubmission,
        )
          .then((savedSchedule) => {
            savedSchedule = this.formatScheduleForForm(savedSchedule);
            this.setState(
              {
                initialSchedule: _cloneDeep(savedSchedule),
                schedule: savedSchedule,
                saveLoading: false,
                showSaveConfirmationModal: false,
                submitAttempted: false,
              },
              () => {
                notify.show(
                  "Your Lamplighters schedule has been saved",
                  "success",
                );
              },
            );
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                saveErrorMessage: ApiCallErrorMessageHandler(err),
                saveLoading: false,
              });
            }
          });
      },
    );
  };

  toTab = (index, preserveQuery = false) => {
    const {
      location: { pathname, query },
    } = this.props;
    const { tabIndex, tabs } = this.state;

    if (this.isTabDisabled(index)) {
      return;
    }

    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      const queryParams = {
        ...(preserveQuery ? query : {}),
        tab: tabs[index].id,
      };
      browserHistory.replace(
        `${pathname}?${queryString.stringify(queryParams)}`,
      );
    }

    const currentTab = tabs[index];
    if (currentTab && currentTab.id === "locations") {
      if (!this.state.chabadHousesList.length) {
        //get available locations list for Locations tab
        this.getChabadHousesForLocationsList();
      }
    }
  };

  isTabDisabled = (index) => {
    const { hasUnsavedAllocationsChanges, initialSchedule, schedule, tabs } =
      this.state;

    return (
      !tabs[index] || //invalid tab
      hasUnsavedAllocationsChanges ||
      !_isEqual(initialSchedule, schedule) || //unsaved changes
      (tabs[index].id === "locations" && schedule && !schedule.id)
    ); //location tab is disabled until grant schedule is created on initial save
  };

  render() {
    const {
      authorized,
      chabadHousesList,
      chabadHousesListErrorMessage,
      chabadHousesListLoading,
      errorMessage,
      initialSchedule,
      loading,
      schedule,
      saveErrorMessage,
      saveLoading,
      showSaveConfirmationModal,
      submitAttempted,
      systemLists,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const hasUnsavedChanges = !_isEqual(initialSchedule, schedule);

    const readOnly = !AuthService.UserHasClaim(PermissionClaims.GrantsFullEdit);

    return (
      <div className="lamplighters-page page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !schedule ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              Sorry, something went wrong and we could not retrieve this
              Lamplighters schedule. Please try again.
            </p>
          </div>
        ) : (
          <React.Fragment>
            <div className="lamplighters-page-subheader">
              <div className="container">
                <ProgramBreadcrumbsHeader
                  pageTitle="Settings"
                  prevPages={[
                    {
                      path: `/lamplighters/${schedule.programScheduleID}`,
                      title: schedule.programScheduleName,
                    },
                  ]}
                  scheduleId={schedule.programScheduleID}
                />
              </div>
              <div className="container flex flex-justify-space flex-align-center">
                <Link
                  to={`/lamplighters/${schedule.programScheduleID}`}
                  className="mr-24"
                >
                  <i className="material-icons link-text">arrow_back</i>
                </Link>
                <ul className="flex">
                  {tabs.map((tab, index) => (
                    <span className="tooltip-container" key={index}>
                      <li
                        className={
                          tabIndex === index
                            ? "active"
                            : this.isTabDisabled(index)
                            ? "disabled"
                            : ""
                        }
                        onClick={() => this.toTab(index)}
                      >
                        {tab.name}
                      </li>
                      {tab.id === "locations" && !schedule.id && (
                        <span className="tooltip">
                          Save initial changes to Grant Schedule Settings in
                          order to access Locations
                        </span>
                      )}
                    </span>
                  ))}
                </ul>
              </div>
            </div>
            <div className="container">
              {React.createElement(tabs[tabIndex].component, {
                ...(tabs[tabIndex].id === "locations"
                  ? {
                      chabadHouses: {
                        chabadHousesList,
                        chabadHousesListErrorMessage,
                        chabadHousesListLoading,
                      },
                      setHasUnsavedAllocationsChanges: (hasChanges) =>
                        this.setState({
                          hasUnsavedAllocationsChanges: hasChanges,
                        }),
                    }
                  : {}),
                getErrorClassName: this.getErrorClassName,
                onChange: this.onChangeSchedule,
                onChangeEvent: this.onChangeScheduleEvt,
                readOnly,
                saveButtons: hasUnsavedChanges && (
                  <div className="section-save-btns">
                    <button
                      className="btn custom-btn btn-cancel btn-medium uppercase-text"
                      disabled={saveLoading}
                      onClick={this.onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn custom-btn btn-accent btn-medium ml-16 uppercase-text"
                      disabled={saveLoading}
                      onClick={this.onSaveSchedule}
                    >
                      {saveLoading ? "Saving..." : "Save"}
                    </button>
                    {!showSaveConfirmationModal && (
                      <p className="error-message">{saveErrorMessage}</p>
                    )}
                  </div>
                ),
                schedule,
                submitAttempted,
                ...systemLists,
              })}
            </div>
          </React.Fragment>
        )}

        <ConfirmationModal
          cancel={() =>
            this.setState({
              saveErrorMessage: "",
              showSaveConfirmationModal: false,
            })
          }
          confirm={this.saveSchedule}
          confirmText="Save"
          errorMessage={saveErrorMessage}
          message={`Save changes to ${
            (schedule && schedule.programScheduleName) ||
            "this Lamplighters schedule"
          }?`}
          loading={saveLoading}
          show={showSaveConfirmationModal}
        />
      </div>
    );
  }
}
