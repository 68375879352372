import AuthRequest from "./AuthRequest";

const baseUrl = "/api/mediapr/reporting";

const MediaAndPRReportingApi = {
  /** @param {import('moment').Moment} startDate
   * @param {import('moment').Moment} endDate
   */
  get: (startDate, endDate) => {
    return AuthRequest.get(baseUrl, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  },
};

export default MediaAndPRReportingApi;
