import React from "react";
// import moment from "moment";

// import { CustomEventTypes } from "../components/Events/Utils";
import { EventCategories, EventTypes } from "./EventCategories";

// export interface EventModel {
//   id?: number;
//   category: EventCategories;
//   categoryName?: string;
//   type: EventTypes;
//   typeName?: string;
//   occurredAt: moment.Moment;
//   metadata: any;
//   // customType?: CustomEventTypes;
//   // customTypeName?: string;
//   contactId?: number;
// }

// interface Input {
//   name: string;
//   label: string;
//   type: string;
//   options?: Array<{ label: string; value: string }>;
// }

// export interface EventDescription {
//   categories: Array<EventCategories>;
//   inputs: Array<Input>;
//   describer: Function;
// }
/** @type {Input} */
const occurredAtInput = {
  name: "occurredAt",
  label: "Occurred on",
  type: "date",
};
/** @type {{ [eventType: number]: EventDescription }} */
const EventDescriptor = {
  [EventTypes.IsraeLinksAttendance]: {
    categories: [EventCategories.IsraeLinks],
    inputs: [],
    describer: () => "Attended IsraeLinks",
  },
  [EventTypes.IsraeLinksRegistration]: {
    categories: [EventCategories.IsraeLinks],
    inputs: [],
    describer: () => "Registered for IsraeLinks",
  },
  [EventTypes.ShabbatonAttendance]: {
    categories: [EventCategories.Shabbaton],
    inputs: [],
    describer: ({ ProgramName }) => `Attended ${ProgramName}`,
  },
  [EventTypes.SecuritySurvey]: {
    categories: [EventCategories.Security],
    inputs: [
      occurredAtInput,
      {
        name: "surveyResult",
        label: "Survey Result",
        type: "dropdown",
        options: [
          { label: "Active Security", value: "active" },
          { label: "Passive Security", value: "passive" },
        ],
      },
    ],
    describer: ({ surveyResult }) =>
      `Security Survey - ${surveyResult} security`,
  },
  [EventTypes.RelatedNewsStory]: {
    categories: [EventCategories.MediaAndPr],
    inputs: [
      occurredAtInput,
      {
        name: "headline",
        label: "Headline",
        type: "text",
      },
      {
        name: "articleUrl",
        label: "URL",
        type: "text",
      },
      {
        name: "note",
        label: "Note",
        type: "textarea",
      },
    ],
    describer: ({ headline, articleUrl, note }) => (
      <div>
        News Story:{" "}
        <a
          href={(articleUrl.startsWith("http") ? "" : "http://") + articleUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {headline}
        </a>
        <br />
        {note}
      </div>
    ),
  },
  [EventTypes.ReceivedTefillinBankSubsidy]: {
    categories: [EventCategories.Tefillin],
    inputs: [
      occurredAtInput,
      {
        name: "dollarValue",
        label: "Dollar Value",
        type: "money",
      },
    ],
    describer: ({ dollarValue }) =>
      `Received Tefillin Bank Subsidy: $${dollarValue}`,
  },
  [EventTypes.ReceivedChayenuSubsidy]: {
    categories: [EventCategories.Chayenu],
    inputs: [
      occurredAtInput,
      {
        name: "dollarValue",
        label: "Dollar Value",
        type: "money",
      },
    ],
    describer: ({ dollarValue }) => `Received Chayenu Subsidy: $${dollarValue}`,
  },
  [EventTypes.SubmittedMonthlySnapshot]: {
    categories: [EventCategories.FundraisingBootcamp],
    inputs: [
      occurredAtInput,
      {
        name: "amountRaisedInDollars",
        label: "Amount Raised (in dollars)",
        type: "money",
      },
    ],
    describer: ({ amountRaisedInDollars }) =>
      `Submitted monthly snapshot: raised $${amountRaisedInDollars}`,
  },
  [EventTypes.SubmittedProspectProfileAndEngagementForm]: {
    categories: [EventCategories.FundraisingBootcamp],
    inputs: [occurredAtInput],
    describer: () => "Submitted Prospect Profile & Engagement Form",
  },
  [EventTypes.SubmittedCampaignSuspectsForm]: {
    categories: [EventCategories.FundraisingBootcamp],
    inputs: [occurredAtInput],
    describer: () => "Submitted Campaign Suspects Form",
  },
  [EventTypes.SubmittedTopTenDonorList]: {
    categories: [EventCategories.FundraisingBootcamp],
    inputs: [occurredAtInput],
    describer: () => "Submitted Top Ten Donor List",
  },
  [EventTypes.LamplightersParticipation]: {
    categories: [EventCategories.Lamplighters],
    inputs: [],
    describer: () => "Participated in Lamplighters",
  },
  [EventTypes.KinusAttendance]: {
    categories: [EventCategories.Kinus],
    inputs: [],
    describer: () => "Attended Kinus",
  },
  [EventTypes.RaffleParticipation]: {
    categories: [EventCategories.Raffle],
    inputs: [],
    describer: () => "Participated in Grand Draw",
  },
  [EventTypes.EstablishedRelationshipWithLocalLawEnforcement]: {
    categories: [EventCategories.Security],
    inputs: [occurredAtInput],
    describer: () => "Established relationship with local law enforcement",
  },
  [EventTypes.SecurityRelatedIncident]: {
    categories: [EventCategories.Security],
    inputs: [
      occurredAtInput,
      {
        name: "typeOfIncident",
        label: "Type of Incident",
        type: "text",
      },
      {
        name: "response",
        label: "Response",
        type: "text",
      },
      {
        name: "notes",
        label: "Notes",
        type: "textarea",
      },
    ],
    describer: ({ typeOfIncident }) => `Security Incident: ${typeOfIncident}`,
  },
  [EventTypes.ReceivedDepartmentOfHomelandSecurityGrant]: {
    categories: [EventCategories.Security],
    inputs: [
      occurredAtInput,
      {
        name: "dollarValue",
        label: "Dollar Value",
        type: "money",
      },
    ],
    describer: ({ dollarValue }) => `Received DHS grant: $${dollarValue}`,
  },
  [EventTypes.SecurityInfrastructureSurvey]: {
    categories: [EventCategories.Security],
    inputs: [
      occurredAtInput,
      {
        name: "hadSecurityCameras",
        label: "Security Cameras",
        type: "toggle",
      },
      {
        name: "hadEventGuards",
        label: "Event Guards",
        type: "toggle",
      },
      {
        name: "hadDoorBuzzers",
        label: "Door Buzzers",
        type: "toggle",
      },
      {
        name: "hadEmergencyProtocols",
        label: "Emergency Protocols",
        type: "toggle",
      },
    ],
    describer: ({
      hadSecurityCameras,
      hadEventGuards,
      hadDoorBuzzers,
      hadEmergencyProtocols,
    }) => {
      const securityAssets = [];

      if (hadSecurityCameras) {
        securityAssets.push("security cameras");
      }
      if (hadEventGuards) {
        securityAssets.push("event guards");
      }
      if (hadDoorBuzzers) {
        securityAssets.push("door buzzers");
      }
      if (hadEmergencyProtocols) {
        securityAssets.push("emergency protocols");
      }

      const securityAssetsList = securityAssets.join(", ");

      return `Had security infrastructure: ${securityAssetsList}`;
    },
  },
  [EventTypes.SecurityTrainingSurvey]: {
    categories: [EventCategories.Security],
    inputs: [
      occurredAtInput,
      {
        name: "hadBombThreatTraining",
        label: "Bomb Threat",
        type: "toggle",
      },
      {
        name: "hadActiveShooterTraining",
        label: "Active Shooter",
        type: "toggle",
      },
    ],
    describer: ({ hadBombThreatTraining, hadActiveShooterTraining }) => {
      const trainingExperiences = [];

      if (hadBombThreatTraining) {
        trainingExperiences.push("bomb threat");
      }
      if (hadActiveShooterTraining) {
        trainingExperiences.push("active shooter");
      }

      const trainingExperiencesList = trainingExperiences.join(", ");

      return `Had security training for scenarios: ${trainingExperiencesList}`;
    },
  },
  [EventTypes.ReceivedMezuzah]: {
    categories: [EventCategories.Mezuzah],
    inputs: [occurredAtInput],
    describer: () => "Received Mezuzah",
  },
  [EventTypes.ReceivedLamdeiniTravelGrant]: {
    categories: [EventCategories.Lamdeini],
    inputs: [
      occurredAtInput,
      {
        name: "dollarValue",
        label: "Dollar Value",
        type: "money",
      },
    ],
    describer: ({ dollarValue }) =>
      `Received Lamdeni Travel Grant: $${dollarValue}`,
  },
  [EventTypes.ManagingLamplighters]: {
    categories: [EventCategories.Lamplighters],
    inputs: [occurredAtInput],
    describer: () => "Managing Lamplighters for School",
  },
  [EventTypes.ManagingShabbaton]: {
    categories: [EventCategories.Shabbaton],
    inputs: [occurredAtInput],
    describer: () => "Managing Shabbaton for School",
  },
  [EventTypes.ManagingTefillin]: {
    categories: [EventCategories.Tefillin],
    inputs: [occurredAtInput],
    describer: () => "Managing Tefillin for School",
  },
  [EventTypes.ManagingChayenu]: {
    categories: [EventCategories.Chayenu],
    inputs: [occurredAtInput],
    describer: () => "Managing Chayenu for School",
  },
  [EventTypes.ManagingRaffle]: {
    categories: [EventCategories.Raffle],
    inputs: [occurredAtInput],
    describer: () => "Managing Grand Draw for School",
  },
  [EventTypes.ManagingIsraeLinks]: {
    categories: [EventCategories.IsraeLinks],
    inputs: [occurredAtInput],
    describer: () => "Managing IsraeLinks for School",
  },
  [EventTypes.ManagingKinus]: {
    categories: [EventCategories.Kinus],
    inputs: [occurredAtInput],
    describer: () => "Managing Kinus for School",
  },
  [EventTypes.ManagingSeedBuildingGrant]: {
    categories: [EventCategories.SeedBuildingGrant],
    inputs: [occurredAtInput],
    describer: () => "Managing Seed/Building Grant",
  },
  [EventTypes.ManagingLamdeini]: {
    categories: [EventCategories.Lamdeini],
    inputs: [occurredAtInput],
    describer: () => "Managing Lamdeini for School",
  },
  [EventTypes.ManagingSSS]: {
    categories: [EventCategories.SSS],
    inputs: [occurredAtInput],
    describer: () => "Managing SSS for School",
  },
  [EventTypes.AddNote]: {
    categories: [EventCategories.Notes],
    inputs: [
      occurredAtInput,
      {
        name: "note",
        label: "Note",
        type: "textarea",
      },
    ],
    describer: ({ note }) => `Note: ${note}`,
  },
  [EventTypes.DateCustomEvent]: {
    categories: [EventCategories.DateCustomEvent],
    inputs: [occurredAtInput],
    describer: (type) => type,
  },
  [EventTypes.StringCustomEvent]: {
    categories: [EventCategories.StringCustomEvent],
    inputs: [
      occurredAtInput,
      {
        name: "content",
        label: "Content",
        type: "textarea",
      },
    ],
    describer: (type, metadata) => type + ": " + metadata.content,
  },
  [EventTypes.CurrencyCustomEvent]: {
    categories: [EventCategories.CurrencyCustomEvent],
    inputs: [
      occurredAtInput,
      {
        name: "dollarValue",
        label: "Dollar Value",
        type: "money",
      },
    ],
    describer: (type, metadata) => type + ": $" + metadata.dollarValue,
  },
};

export default EventDescriptor;
