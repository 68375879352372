import React from "react";
import { Link } from "react-router";
import PaginatedTable from "../../shared/PaginatedTable";
import Select from "react-select";

import ShluchimApi from "../../../services/resources/ShluchimApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";
import moment from "moment";

const getProgramRegistrationUrl = (activity) => {
  const { adminBaseUrl, id, programID, programScheduleID } = activity;
  if (!adminBaseUrl) {
    return `/programs/${programID}/schedules/${programScheduleID}/registrations/${id}`;
  }
  const baseUrl = `${adminBaseUrl}/${programScheduleID}`;
  switch (adminBaseUrl) {
    case "/grand-draw":
      return `${baseUrl}/enrollment/${id}`;
    case "/trips":
    case "/life-insurance":
      return `${baseUrl}/enrollments/${id}`;
    case "/edu":
      return `${baseUrl}/?tab=locations`;
    case "/rsvp":
    default:
      return baseUrl;
  }
};

const ProgramActivityTableRow = ({ activity }) => {
  const { date, programName, programScheduleName, typeDisplay } = activity;
  const viewRegistrationUrl = getProgramRegistrationUrl(activity);
  return (
    <div className="program-activity-table-row">
      <p>
        {typeDisplay} {programScheduleName || programName}
      </p>
      <p>{moment(date).format("MMM D, YYYY")}</p>
      <p>
        {viewRegistrationUrl && (
          <Link to={viewRegistrationUrl}>View in {programName}</Link>
        )}
      </p>
    </div>
  );
};

export default class ProgramActivityTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activity: [],
      errorMessage: "",
      filters: {
        timeframe: this.activityTimeFrameOptions[0],
      },
      loading: false,
      page: 1,
      results: 12,
      success: true,
      total: 0,
    };
  }

  activityTimeFrameOptions = [
    { label: "All Time", value: "all" },
    { label: "Last 6 months", value: "last6Months" },
    { label: "Last year", value: "lastYear" },
    { label: "Last 2 years", value: "last2Years" },
  ];

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getActivity();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onFilterActivity = (name, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value,
        },
      },
      () => this.getActivity(),
    );
  };

  getActivity = (page = 1, results = this.state.results) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        success: true,
      },
      async () => {
        try {
          const programActivity = await ShluchimApi.getShliachProgramActivity(
            this.apiSignal.token,
            this.props.shliachId,
            page,
            results,
            this.state.filters.timeframe.value,
          );

          this.setState({
            activity: programActivity.results || [],
            total: programActivity.numberOfRows || 0,
          });
        } catch (err) {
          if (!axios.isCancel(err)) {
            this.setState({
              activity: [],
              errorMessage: ApiCallErrorMessageHandler(err),
              success: false,
              total: 0,
            });
          }
        }

        this.setState({ loading: false });
      },
    );
  };

  render() {
    const { activity, filters, loading, page, results, success, total } =
      this.state;

    return (
      <PaginatedTable
        filterComponent={
          <div className="flex flex-justify-end full-width">
            <div className="flex flex-align-center">
              <p className="small-text mr-16">Filter by</p>
              <div style={{ width: "200px" }}>
                <Select
                  className="custom-select"
                  clearable={false}
                  onChange={(val) => this.onFilterActivity("timeframe", val)}
                  options={this.activityTimeFrameOptions}
                  value={filters.timeframe}
                />
              </div>
            </div>
          </div>
        }
        loading={loading}
        loadData={this.getActivity}
        page={page}
        preHeaderMargin={4}
        records={activity}
        renderHeader={() => ""}
        renderRow={(activity, index) => (
          <ProgramActivityTableRow activity={activity} key={index} />
        )}
        results={results}
        success={success}
        totalCount={total}
      />
    );
  }
}
