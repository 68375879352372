import React from "react";
import { Link } from "react-router";
import CopyToClipboardTextbox from "../../shared/CopyToClipboardTextbox";
import Loader from "../../common/Loader";
import Select from "react-select";

const { REACT_APP_COCI_STUDENT_PORTAL_DOMAIN } = process.env;

export default class TripEventCard extends React.PureComponent {
  render() {
    const {
      hasSettingsAccess,
      loading,
      onChangeTripEvent,
      tripEvent: { programScheduleID, programScheduleName, type },
      tripEventsList,
    } = this.props;

    return (
      <div className="card trip-card mb-40">
        <div className="flex flex-justify-space flex-align-center mb-32">
          {!!tripEventsList?.length &&
          tripEventsList
            .map((p) => p.programScheduleID)
            .indexOf(programScheduleID) >= 0 ? (
            <Select
              className="xl-text fw-700 flex trip-event-select custom-select full-width"
              clearable={false}
              onChange={(tripEvent) =>
                onChangeTripEvent(
                  "tripEvent",
                  tripEvent ? tripEvent.value : null
                )
              }
              options={
                tripEventsList &&
                tripEventsList.map((trip) => ({
                  className: trip.isCurrent ? "fw-700" : "fw-400",
                  label: trip.name,
                  value: trip.programScheduleID,
                }))
              }
              searchable={false}
              value={programScheduleID}
            />
          ) : (
            <p className="xl-text fw-700">{programScheduleName}</p>
          )}
          {hasSettingsAccess && (
            <Link
              className="btn custom-btn btn-accent-secondary uppercase-text btn-medium ml-32"
              to={`/programs/trips/${programScheduleID}`}
            >
              View Settings
            </Link>
          )}
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div style={{ width: "500px" }}>
            <CopyToClipboardTextbox
              className="trip-link"
              id="trip-url"
              label="Copy Link"
              text={
                REACT_APP_COCI_STUDENT_PORTAL_DOMAIN
                  ? `${REACT_APP_COCI_STUDENT_PORTAL_DOMAIN}trips/${type}/${programScheduleID}`
                  : ""
              }
              style={{
                height: "20px",
                whiteSpace: "nowrap",
                fontSize: "14px",
              }}
            />
          </div>
        )}
      </div>
    );
  }
}
