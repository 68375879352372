import React, { useCallback, useMemo, useState } from "react";
import RadioContainer from "../Form/RadioContainer";

const NoStudentResourceProviderValue = "NA";
const NoStudentGroupValue = "None";

export default function JewishStudentLifeInfo(props) {
  const {
    handleChange,
    readOnly,
    sys: {
      chabadStudentGroupStatuses,
      chaplainStatuses,
      studentGroupTypes,
      studentResourceProviders,
      studentResources: allStudentResources,
    },
    values: {
      chabadStudentGroupStatus,
      shliachChaplainStatus,
      shluchaChaplainStatus,
      studentGroups: campusStudentGroups,
      studentResources: campusStudentResources,
    },
  } = props;

  const [collapsed, setCollapsed] = useState(window.innerWidth < 560);

  const categorizedStudentResources = useMemo(() => {
    const categorizedStudentResources = {};

    if (allStudentResources) {
      allStudentResources.forEach((r) => {
        if (!categorizedStudentResources[r.categoryDisplay]) {
          categorizedStudentResources[r.categoryDisplay] = [];
        }
        categorizedStudentResources[r.categoryDisplay].push(r);
      });
    }

    return categorizedStudentResources;
  }, [allStudentResources]);

  const handleStudentResourceChange = useCallback(
    (studentResourceId, provider, checked) => {
      let updatedCampusStudentResources = [...campusStudentResources];

      if (checked) {
        if (provider === NoStudentResourceProviderValue) {
          // if provider is N/A, clear out other providers for the resource
          updatedCampusStudentResources = updatedCampusStudentResources.filter(
            (r) => r.studentResourceID !== studentResourceId
          );
        } else if (
          updatedCampusStudentResources.some(
            (r) =>
              r.studentResourceID === studentResourceId &&
              r.provider === NoStudentResourceProviderValue
          )
        ) {
          // otherwise, exclude N/A selection for the resource
          updatedCampusStudentResources = updatedCampusStudentResources.filter(
            (r) =>
              !(
                r.studentResourceID === studentResourceId &&
                r.provider === NoStudentResourceProviderValue
              )
          );
        }

        updatedCampusStudentResources.push({
          studentResourceID: studentResourceId,
          provider,
        });
      } else {
        updatedCampusStudentResources = updatedCampusStudentResources.filter(
          (r) =>
            r.studentResourceID !== studentResourceId || r.provider !== provider
        );
      }

      handleChange({
        target: {
          name: "studentResources",
          value: updatedCampusStudentResources,
        },
      });
    },
    [campusStudentResources, handleChange]
  );

  const handleStudentGroupChange = useCallback(
    (studentGroupType, checked) => {
      let updatedCampusStudentGroups = [...campusStudentGroups];

      if (checked) {
        if (studentGroupType === NoStudentGroupValue) {
          // if group type is None, clear student groups selection
          updatedCampusStudentGroups = [];
        } else if (updatedCampusStudentGroups.includes(NoStudentGroupValue)) {
          // otherwise, exclude None selection
          updatedCampusStudentGroups = updatedCampusStudentGroups.filter(
            (g) => g !== NoStudentGroupValue
          );
        }

        updatedCampusStudentGroups.push(studentGroupType);
      } else {
        updatedCampusStudentGroups = updatedCampusStudentGroups.filter(
          (g) => g !== studentGroupType
        );
      }

      handleChange({
        target: {
          name: "studentGroups",
          value: updatedCampusStudentGroups,
        },
      });
    },
    [campusStudentGroups, handleChange]
  );

  return (
    <div
      className={`profile-form-section${
        collapsed ? " mobile-tab-collapsed" : ""
      }`}
    >
      <p
        className="accent-text-dark medium-text fw-700 mt-48 mb-16"
        onClick={() => setCollapsed(!collapsed)}
      >
        Jewish student life
      </p>
      {!collapsed && (
        <>
          <p
            className="small-text accent-text mb-16 fw-400"
            style={{ marginTop: "-12px" }}
          >
            Please select who provides student resources. If a resource isn't
            available, select n/a.
          </p>
          {campusStudentResources &&
            Object.keys(categorizedStudentResources).map((categoryDisplay) => {
              return (
                <div key={categoryDisplay}>
                  <p className="fw-700 mb-16">{categoryDisplay}</p>
                  {categorizedStudentResources[categoryDisplay].map(
                    (studentResource) => {
                      const campusStudentResourceProviders =
                        campusStudentResources
                          .filter(
                            (r) => r.studentResourceID === studentResource.id
                          )
                          .map((r) => r.provider);
                      return (
                        <div
                          className="flex mobile-block mb-16"
                          key={studentResource.id}
                        >
                          <p
                            className="accent-text fw-700 mb-8"
                            style={{ width: "380px" }}
                          >
                            {studentResource.name}
                          </p>
                          {studentResourceProviders?.map((provider, i) => {
                            const key = `${studentResource.id}-${provider.enumValue}`;
                            const checked =
                              campusStudentResourceProviders.includes(
                                provider.enumValue
                              );
                            return (
                              <div
                                className={`custom-checkbox-container${
                                  i ? " ml-40" : ""
                                } ${readOnly ? "read-only" : ""}`}
                                key={key}
                              >
                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  id={key}
                                  disabled={readOnly}
                                  onChange={(e) =>
                                    handleStudentResourceChange(
                                      studentResource.id,
                                      provider.enumValue,
                                      e.target.checked
                                    )
                                  }
                                  checked={checked}
                                />
                                <label
                                  className="flex flex-align-center"
                                  htmlFor={key}
                                >
                                  <span>{provider.displayValue}</span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          {campusStudentGroups && (
            <div>
              <p className="fw-700 mb-16">Jewish student groups</p>
              <div className="flex mobile-block mb-16">
                {studentGroupTypes?.map((studentGroupType, i) => {
                  const key = studentGroupType.enumValue;
                  const checked = campusStudentGroups.includes(
                    studentGroupType.enumValue
                  );
                  return (
                    <div
                      className={`custom-checkbox-container${
                        i ? " ml-40" : ""
                      } ${readOnly ? "read-only" : ""}`}
                      key={key}
                    >
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        id={key}
                        disabled={readOnly}
                        onChange={(e) =>
                          handleStudentGroupChange(
                            studentGroupType.enumValue,
                            e.target.checked
                          )
                        }
                        checked={checked}
                      />
                      <label className="flex flex-align-center" htmlFor={key}>
                        <span>{studentGroupType.displayValue}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <p className="fw-700 mb-16">School recognition of Chabad</p>
          <div className="profile-form-inputs">
            <div className="mr-16">
              <p className="accent-text small-text mb-4">
                Is the shliach a recognized chaplain?
              </p>
              <RadioContainer
                inputs={
                  chaplainStatuses?.map((s) => ({
                    id: `shliachChaplainStatus-${s.enumValue}`,
                    value: s.enumValue,
                    checked: s.enumValue,
                    label: s.displayValue,
                  })) || []
                }
                name="shliachChaplainStatus"
                onChange={handleChange}
                readOnly={readOnly}
                value={shliachChaplainStatus}
              />
            </div>
            <div className="ml-16">
              <p className="accent-text small-text mb-4">
                Is the shlucha a recognized chaplain?
              </p>
              <RadioContainer
                inputs={
                  chaplainStatuses?.map((s) => ({
                    id: `shluchaChaplainStatus-${s.enumValue}`,
                    value: s.enumValue,
                    checked: s.enumValue,
                    label: s.displayValue,
                  })) || []
                }
                name="shluchaChaplainStatus"
                onChange={handleChange}
                readOnly={readOnly}
                value={shluchaChaplainStatus}
              />
            </div>
            <div className="mr-16">
              <p className="accent-text small-text mb-4">
                Is the Chabad a recognized student group?
              </p>
              <RadioContainer
                inputs={
                  chabadStudentGroupStatuses?.map((s) => ({
                    id: `chabadStudentGroupStatus-${s.enumValue}`,
                    value: s.enumValue,
                    checked: s.enumValue,
                    label: s.displayValue,
                  })) || []
                }
                name="chabadStudentGroupStatus"
                onChange={handleChange}
                readOnly={readOnly}
                style={{ height: "48px", textAlign: "center" }}
                value={chabadStudentGroupStatus}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
