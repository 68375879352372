import React, { useEffect, useState } from "react";
import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";

import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import EngagementPortalLocations from "./locations/EngagementPortalLocations";

import EngagementApi from "../../services/resources/EngagementApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";

const middleDot = 0x00b7;

export default function EngagementPortalPage(props) {
  const {
    params: { scheduleId },
  } = props;

  const [lastAlgorithmRunDate, setLastAlgorithmRunDate] = useState(null);
  const [nextAlgorithmRunDate, setNextAlgorithmRunDate] = useState(null);
  const [algorithmRunDatesError, setAlgorithmRunDatesError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const results =
          await EngagementApi.getJewishInterestAlgorithmRunDates();
        if (results) {
          setLastAlgorithmRunDate(
            moment(results.lastRunDate).utc().format("M/D/YYYY")
          );
          setNextAlgorithmRunDate(
            moment(results.nextRunDate).utc().format("M/D/YYYY")
          );
        } else {
          setAlgorithmRunDatesError(
            "Algorithm run dates could not be retrieved"
          );
        }
      } catch (err) {
        var errorMessage = ApiCallErrorMessageHandler(err);
        setAlgorithmRunDatesError(
          `Algorithm run dates could not be retrieved: ${errorMessage}`
        );
      }
    })();
  }, []);

  if (!AuthService.UserHasClaim(PermissionClaims.EngagementAdminView)) {
    return <Unauthorized userName={AuthService.getCurrentUser().name} />;
  }

  return (
    <div className="engagement-page page container">
      <ProgramBreadcrumbsHeader scheduleId={scheduleId} />
      <div className="block mb-32">
        <p className="fw-900 xl-text">Engagement Portal</p>
        <p>
          {lastAlgorithmRunDate
            ? `Algorithm last run ${lastAlgorithmRunDate} ${String.fromCodePoint(
                middleDot
              )} Next run ${nextAlgorithmRunDate}`
            : algorithmRunDatesError}
        </p>
      </div>
      <div className="card">
        <EngagementPortalLocations />
      </div>
    </div>
  );
}
