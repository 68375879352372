import React from "react";

function CheckInTableHeader(props) {
  const {
    canSelectAttendees,
    readOnlyAccess,
    selectedAttendees,
    toggleSelectAllAttendees,
  } = props;

  const columns = ["Name", "Campus / Shliach", "Type", "Status"];

  return (
    <div
      className={`checkin-table-columns trip-students-table-header${readOnlyAccess ? " read-only" : ""
        }`}
    >
      {!readOnlyAccess && (
        <div className="flex flex-align-center custom-checkbox-container">
          <input
            className="custom-checkbox"
            checked={
              selectedAttendees.all && !selectedAttendees.allExcept.length
            }
            disabled={!canSelectAttendees}
            id="all-attending"
            onChange={toggleSelectAllAttendees}
            type="checkbox"
          />
          <label className="flex-align-center" htmlFor="all-attending" />
        </div>
      )}
      {columns.map((column, index) => (
        <p className="flex flex-align-center relative" key={index}>
          {column}
        </p>
      ))}
    </div>
  );
}
export default React.memo(CheckInTableHeader);
