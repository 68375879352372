import React from "react";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import PromoCodeModal from "./PromoCodeModal";
import { TripConfigApplicableFor } from "../../TripEventConsts";
import { formatCurrency, formatNumber } from "../../../../lib";
import moment from "moment";

export default class PromoCodesTable extends React.PureComponent {
  state = {
    currentPromoIndex: null,
    showDeletePromoConfirmationModal: false,
    showPromoCodeModal: false,
  };

  onAddPromoCode = () => {
    this.setState({
      showPromoCodeModal: true,
    });
  };

  onDeletePromoCode = (promoIndex) => {
    this.setState({
      currentPromoIndex: promoIndex,
      showDeletePromoConfirmationModal: true,
    });
  };

  onUpdatePromoCode = (promoIndex) => {
    this.setState({
      currentPromoIndex: promoIndex,
      showPromoCodeModal: true,
    });
  };

  addPromoCode = (newPromoCode) => {
    const { onChange, promoCodes } = this.props;

    const newPromoCodes = [...promoCodes, newPromoCode];
    onChange("promoCodes", newPromoCodes);

    this.setState({
      showPromoCodeModal: false,
    });
  };

  deletePromoCode = () => {
    const { onChange, promoCodes } = this.props;
    const { currentPromoIndex } = this.state;

    const newPromoCodes = [...promoCodes];
    newPromoCodes.splice(currentPromoIndex, 1);
    onChange("promoCodes", newPromoCodes);

    this.setState({
      currentPromoIndex: null,
      showDeletePromoConfirmationModal: false,
    });
  };

  updatePromoCode = (updatedPromoCode) => {
    const { onChange, promoCodes } = this.props;
    const { currentPromoIndex } = this.state;

    const newPromoCodes = [...promoCodes];
    newPromoCodes[currentPromoIndex] = updatedPromoCode;
    onChange("promoCodes", newPromoCodes);

    this.setState({
      currentPromoIndex: null,
      showPromoCodeModal: false,
    });
  };

  render() {
    const { isJewishUTrip, isTravelTrip, promoCodes } = this.props;

    const {
      currentPromoIndex,
      showDeletePromoConfirmationModal,
      showPromoCodeModal,
    } = this.state;

    const allowAddPromo = !isJewishUTrip || !promoCodes.length; // JewishU Trips are restricted to one promo code

    return (
      <div className="program-settings-page-section">
        <div className="flex mb-16">
          <p className="medium-text fw-700">Promo Codes</p>
          {allowAddPromo && (
            <button
              className="custom-btn btn btn-accent btn-small uppercase-text fw-400 ml-16"
              onClick={this.onAddPromoCode}
            >
              Add
            </button>
          )}
        </div>
        <div className="promo-codes-grid">
          <div className="promo-codes-grid-header">
            <p>Code</p>
            <p>Title</p>
            <p>For</p>
            <p>Discount</p>
            <p>Expiration</p>
            <p>Limit</p>
            <p>Available</p>
            <p />
          </div>
          {!!(promoCodes && promoCodes.length) ? (
            promoCodes.map((promoCode, index) => (
              <div className="promo-codes-grid-row" key={index}>
                <p>{promoCode.code}</p>
                <p>{promoCode.title}</p>
                <p>{promoCode.appliesTo}</p>
                <p>${formatCurrency(promoCode.discountAmount)}</p>
                <p>
                  {promoCode.expirationDate
                    ? moment(promoCode.expirationDate).format(
                        "MM/DD/YYYY hh:mm a"
                      )
                    : ""}
                </p>
                <p>
                  {promoCode.threshold ? formatNumber(promoCode.threshold) : ""}
                </p>
                <p>
                  {promoCode.threshold
                    ? formatNumber(
                        promoCode.threshold - (promoCode.counter || 0)
                      )
                    : ""}
                </p>
                <p className="text-right flex flex-align-center">
                  <i
                    className="material-icons large-text link-text-secondary"
                    onClick={() => this.onUpdatePromoCode(index)}
                  >
                    edit
                  </i>
                  <i
                    className="material-icons large-text ml-8 link-text-secondary"
                    onClick={() => this.onDeletePromoCode(index)}
                  >
                    delete
                  </i>
                </p>
              </div>
            ))
          ) : (
            <span>No promo codes</span>
          )}
        </div>

        <PromoCodeModal
          appliesToOptions={(isTravelTrip
            ? [TripConfigApplicableFor.Students]
            : [
                TripConfigApplicableFor.Shluchim,
                TripConfigApplicableFor.Students,
              ]
          ).map((type) => ({
            display: type,
            value: type,
          }))}
          close={() =>
            this.setState({
              currentPromoIndex: null,
              showPromoCodeModal: false,
            })
          }
          promoCode={promoCodes[currentPromoIndex]}
          save={
            currentPromoIndex === null
              ? this.addPromoCode
              : this.updatePromoCode
          }
          show={showPromoCodeModal}
          usedCodes={promoCodes
            .filter((p, i) => i !== currentPromoIndex)
            .map((promoCode) => promoCode.code.trim().toLowerCase())}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({ showDeletePromoConfirmationModal: false })
          }
          confirm={this.deletePromoCode}
          message={`Are you sure you want to delete promo code ${
            promoCodes[currentPromoIndex]
              ? promoCodes[currentPromoIndex].code
              : ""
          }?`}
          show={showDeletePromoConfirmationModal}
        />
      </div>
    );
  }
}
