import React from "react";
import ReactDOM from "react-dom";
import Router from "./routes";
import Modal from "react-modal";
import { removeImpersonationAuthCookies } from "./lib";
import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/stylesheets/main.scss";

Modal.setAppElement("body");

// appInsightsInit();
removeImpersonationAuthCookies(); //TODO: remove cookies on mount?

ReactDOM.render(<Router />, document.getElementById("root"));
