export const EduProgramTypes = {
  JewishU: "JewishU",
  SinaiScholars: "SinaiScholars",
  YourIsrael: "YourIsrael",
};

export const EduRewardTypes = {
  Trip: "Trip",
  Programs: "Programs",
  Charity: "Charity",
  Cash: "Cash"
};