import React from "react";

import { FieldArray } from "formik";
import CreatableSelect from "../../Form/CreatableSelect";
import CustomSelect from "../../Form/CustomSelect";
import FormInput from "../../Form/FormInput";
import FormInputUpdate from "../../Form/FormInputUpdate";
import RadioContainer from "../../Form/RadioContainer";

export default class SchoolsInfo extends React.PureComponent {
  state = {
    schoolsInfoShowing: true,
  };

  componentDidMount() {
    this._ismounted = true;
    if (window.innerWidth < 560) {
      this.setState({
        schoolsInfoShowing: false,
      });
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    const {
      campuses,
      errors,
      handleChange,
      require,
      setFieldValue,
      sys: { distances },
      touched,
      readOnly,
      values,
    } = this.props;
    return (
      <div
        className={
          this.state.schoolsInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <FieldArray name="campuses">
          {(arrayHelpers) => (
            <React.Fragment>
              <div className="flex flex-align-center mt-48 mb-8 mobile-flex-justify-space">
                <p
                  className="accent-text-dark medium-text fw-700"
                  onClick={() => {
                    this.setState({
                      schoolsInfoShowing: !this.state.schoolsInfoShowing,
                    });
                  }}
                >
                  Associated Schools
                </p>
                {readOnly ? null : (
                  <i
                    className={`material-icons link-text profile-add-icon ml-16 ${
                      !this.state.schoolsInfoShowing ? "hidden" : ""
                    }`}
                    onClick={() =>
                      arrayHelpers.unshift({
                        audience: "",
                        campusName: "",
                        chabadHouseID: "",
                        chabadHouseName: "",
                        isFullTime: "",
                        isPrimary: false,
                      })
                    }
                  >
                    add_circle
                  </i>
                )}
              </div>
              {this.state.schoolsInfoShowing &&
              values.campuses &&
              values.campuses.length > 0 ? (
                <p
                  className="small-text accent-text desktop-hidden tablet-hidden"
                  style={{ margin: "-8px 0 24px 0", padding: "0" }}
                >
                  Schools associated with this chabad house
                </p>
              ) : null}
              <div className="profile-form-associated-schools-info profile-form-inputs">
                {values.campuses && values.campuses.length > 0 ? (
                  <div>
                    <p className="accent-text small-text mb-0">
                      Schools associated with this chabad house
                    </p>
                    <p className="accent-text small-text text-center mb-0">
                      Primary
                    </p>
                    <div className="flex flex-align-center">
                      <p className="accent-text small-text">
                        Full / Limited Service{" "}
                      </p>
                      <div className="tooltip-container flex">
                        <i
                          className={`material-icons link-text no-hover profile-add-icon ml-16`}
                        >
                          info
                        </i>
                        <span
                          className="tooltip line-height-double"
                          style={{ whiteSpace: "normal", width: "320px" }}
                        >
                          <span className="fw-700">Full Service School: </span>
                          The vast majority of your Shlichus is dedicated to
                          this university. You live close to the campus, create
                          your programs based on the school schedule, and the
                          majority of people who attend your Chabad House are
                          from this university.
                          <br />
                          <br />
                          <span className="fw-700">
                            Limited Service School:{" "}
                          </span>
                          You offer programs to students at this university.
                          However, you do not base your programs on the school
                          schedule and the majority of people who attend your
                          Chabad House are not from this university.
                        </span>
                      </div>
                    </div>
                    <p className="accent-text small-text mb-0">
                      Dist. to Chabad House
                    </p>
                    <p />
                  </div>
                ) : null}
                {values.campuses &&
                  values.campuses.map((campus, index) => (
                    <div
                      key={index}
                      className="hover-delete-container mobile-card"
                    >
                      <div>
                        <FormInputUpdate
                          label="School Name"
                          name={`campuses[${index}].campusName`}
                          validate={require}
                          errors={errors}
                          touched={touched}
                          className="custom-input mr-12 form-required"
                        >
                          {({ field }) => (
                            <CreatableSelect
                              placeholder="Select school or create new..."
                              errors={errors}
                              touched={touched}
                              readOnly={readOnly}
                              {...field}
                              options={
                                campuses &&
                                campuses.map((c) => ({
                                  key: c.id,
                                  value: c.name,
                                  label: c.name,
                                }))
                              }
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </FormInputUpdate>
                      </div>
                      <div className="text-center">
                        <FormInput
                          label=""
                          name={`campuses[${index}].isPrimary`}
                          errors={errors}
                          touched={touched}
                          className="custom-input mr-12"
                        >
                          {({ field }) => (
                            <div className="radio-button-container">
                              <div>
                                <input
                                  type="checkbox"
                                  id={`school-${index}`}
                                  {...field}
                                  value="isPrimary"
                                  checked={campus.isPrimary}
                                  disabled={readOnly}
                                  onChange={(e) => {
                                    const currentPrimarySchoolIndex =
                                      values.campuses
                                        .map((campus) => campus.isPrimary)
                                        .indexOf(true);
                                    handleChange({
                                      target: {
                                        name: `campuses[${currentPrimarySchoolIndex}].isPrimary`,
                                        value: false,
                                      },
                                    });
                                    field.onChange(e);
                                  }}
                                />
                                <label htmlFor={`school-${index}`}>
                                  <span className="desktop-hidden tablet-hidden">
                                    Make this the primary school
                                  </span>
                                </label>
                              </div>
                            </div>
                          )}
                        </FormInput>
                      </div>

                      <div>
                        <FormInput
                          label="Full / Limited Service"
                          name={`campuses[${index}].isFullTime`}
                          validate={require}
                          className="form-required"
                          errors={errors}
                          touched={touched}
                        >
                          {({ field }) => (
                            <RadioContainer
                              errors={errors}
                              touched={touched}
                              readOnly={readOnly}
                              {...field}
                              input1={{
                                id: `fullTime-${index}`,
                                value: true,
                                checked: "true",
                                label: "FS",
                              }}
                              input2={{
                                id: `partTime-${index}`,
                                value: false,
                                checked: "false",
                                label: "LS",
                              }}
                            />
                          )}
                        </FormInput>
                      </div>
                      <div>
                        <FormInput
                          name={`campuses[${index}].distance`}
                          errors={errors}
                          touched={touched}
                          className="mr-8"
                        >
                          {({ field }) => (
                            <CustomSelect
                              placeholder="Select Dist..."
                              errors={errors}
                              touched={touched}
                              {...field}
                              options={
                                distances &&
                                distances.map((d) => ({
                                  key: d.enumValue,
                                  value: d.enumValue,
                                  label: d.displayValue,
                                  data: d,
                                }))
                              }
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </FormInput>
                      </div>
                      <div className="flex flex-align-center">
                        {readOnly ? null : (
                          <i
                            className="material-icons link-text hover-delete"
                            style={{ fontSize: "16px" }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            delete
                          </i>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          )}
        </FieldArray>
      </div>
    );
  }
}
