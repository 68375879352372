const {
  REACT_APP_API_URL = "",
  REACT_APP_DOMAIN = "",
  REACT_APP_LEGACY_API = "",
} = process.env;

const UrlBaseUtil = {
  getApiBase() {
    return REACT_APP_API_URL;
  },
  getAppDomain() {
    const { location } = window;
    return (
      REACT_APP_DOMAIN ||
      location.origin ||
      location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "")
    );
  },
  getLegacyApiBase() {
    return REACT_APP_LEGACY_API;
  },
};

export default UrlBaseUtil;
