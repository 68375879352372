import React from "react";
import Loader from "../../../common/Loader";
import Modal from "react-modal";
import NumberFormat from "react-number-format";
import Radio from "../../../shared/Radio";

import LamplightersApi from "../../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import moment from "moment";
import {
  formatDateForInput,
  formatNumber,
  removeTimezoneFormatFromDate,
  sum,
} from "../../../../lib";

export default class ManagePaymentsModal extends React.Component {
  constructor(props) {
    super();

    const sortedPaymentSchedules = props.paymentSchedules.sort(
      (ps1, ps2) =>
        new Date(ps1.paymentProcessingDate) -
        new Date(ps2.paymentProcessingDate),
    );

    this.state = {
      allocationStats: {
        allocations: [],
        errorMessage: "",
        loading: true,
      },
      errorMessage: "",
      initialPaymentSchedules: _cloneDeep(sortedPaymentSchedules),
      loading: false,
      paymentSchedules: _cloneDeep(sortedPaymentSchedules),
      submitAttempted: false,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getAllocationStats();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getAllocationStats = () => {
    const {
      schedule: { grants, id, totalPaid },
    } = this.props;

    LamplightersApi.getGrantAllocations(this.apiSignal.token, id)
      .then((allocations) => {
        const quantityAllocated = sum(allocations.map((a) => a.quantity));
        const quantityAvailable = sum(
          allocations.map((a) => a.availableQuantity),
        );
        const quantitySubmitted = sum(
          allocations.map((a) => a.submittedQuantity),
        );
        const quantityFlagged = sum(
          allocations.map(
            (a) => a.activeFlaggedQuantity + a.submittedFlaggedQuantity,
          ),
        );
        const amountSubmitted = sum(allocations.map((a) => a.submittedAmount));
        const amountOwed = amountSubmitted - totalPaid;
        const grantAllocations = grants.map((grant) => ({
          grantName: grant.name,
          ...(allocations.find((a) => a.grantID === grant.id) || {}),
        }));
        this.setState({
          allocationStats: {
            allocations: grantAllocations,
            errorMessage: "",
            loading: false,
            amountOwed,
            amountSubmitted,
            amountPaid: totalPaid,
            quantityAllocated,
            quantityAvailable,
            quantitySubmitted,
            quantityFlagged,
          },
        });
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            allocationStats: {
              allocations: [],
              errorMessage: ApiCallErrorMessageHandler(err),
              loading: false,
            },
          });
        }
      });
  };

  onClose = () => {
    this.setState(
      {
        errorMessage: "",
        loading: false,
        paymentSchedules: [],
        submitAttempted: false,
      },
      this.props.close,
    );
  };

  onCreatePaymentSchedule = () => {
    const { paymentSchedules } = this.state;
    const paymentSchedule = {
      paymentPercentage: "",
      paymentProcessingDate: "",
      useRemainingBalance: false,
    };

    if (paymentSchedules.length > 2) {
      return;
    }

    const isPayment2 = !!paymentSchedules.length;
    if (isPayment2) {
      paymentSchedule.useRemainingBalance = true;
    }

    this.setState({ paymentSchedules: [...paymentSchedules, paymentSchedule] });
  };

  onChangePaymentSchedule = (index, name, value, other) => {
    const paymentSchedules = _cloneDeep(this.state.paymentSchedules);

    paymentSchedules[index][name] = value;

    if (name === "useRemainingBalance" && value) {
      //clear out payment percentage
      if (paymentSchedules[index].paymentPercentage) {
        paymentSchedules[index].paymentPercentage = "";
      }
      //remove second payment if exists
      if (paymentSchedules.length > 1) {
        paymentSchedules.splice(1, 1);
      }
    }

    this.setState({ paymentSchedules });
  };

  onSubmit = async () => {
    this.setState({ errorMessage: "", submitAttempted: true });

    if (!this.validatePaymentSchedules()) {
      this.setState({ errorMessage: "Please complete required fields" });
      return;
    }

    const { paymentSchedules } = this.state;

    this.setState({
      loading: true,
    });

    LamplightersApi.submitLamplightersSchedule(this.apiSignal.token, {
      ...this.props.schedule,
      paymentSchedules,
    })
      .then(() => {
        this.props.refreshSchedule();
        this.onClose();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          this.setState({
            errorMessage: ApiCallErrorMessageHandler(err),
            loading: false,
          });
        }
      });
  };

  validatePaymentSchedules = () => {
    const { paymentSchedules } = this.state;

    if (!paymentSchedules.length) {
      return false;
    }

    //require payemnt date
    if (paymentSchedules.some((ps) => !ps.paymentProcessingDate)) {
      return false;
    }

    //require payment percentage for first payment
    const payment1 = paymentSchedules[0];
    if (!payment1.useRemainingBalance && !payment1.paymentPercentage) {
      return false;
    }

    //validate that payment 2 date is after payment 1 date
    const payment2 = paymentSchedules[1];
    if (
      payment2 &&
      payment2.paymentProcessingDate < payment1.paymentProcessingDate
    ) {
      return false;
    }

    return true;
  };

  render() {
    const { show } = this.props;

    const {
      allocationStats,
      errorMessage,
      initialPaymentSchedules,
      loading,
      paymentSchedules,
      submitAttempted,
    } = this.state;

    const payment1 = paymentSchedules[0];
    const payment2 = paymentSchedules[1];

    const canCreateFinalPaymentSchedule =
      !allocationStats.quantityAvailable &&
      !allocationStats.quantityFlagged &&
      allocationStats.quantityAllocated === allocationStats.quantitySubmitted;

    return (
      <Modal
        isOpen={show}
        className="modal-container lamplighters-payments-modal"
      >
        <div className="modal-card card">
          <div className="flex flex-justify-space mb-24">
            <p className="xl-text fw-700">Manage Payments</p>
            <i
              className="material-icons link-text-secondary"
              onClick={this.onClose}
            >
              close
            </i>
          </div>
          {allocationStats.loading ? (
            <Loader />
          ) : (
            <div>
              <div className="flex flex-justify-space">
                <div className="mr-40">
                  <div className="mb-32">
                    <div className="flex mb-16">
                      <p className="payment-title">Payment 1</p>
                      {!payment1 && (
                        <button
                          className="custom-btn btn btn-accent btn-small ml-16"
                          onClick={this.onCreatePaymentSchedule}
                        >
                          Create payment
                        </button>
                      )}
                    </div>
                    {!!payment1 && (
                      <div>
                        <div className="mb-16">
                          <label>Payment Amount</label>
                          {!!payment1.id ? (
                            <span className="small-text">
                              {payment1.useRemainingBalance
                                ? "Remaining Balance"
                                : payment1.paymentPercentage
                                ? `${payment1.paymentPercentage}%`
                                : ""}
                            </span>
                          ) : (
                            <Radio
                              className="mt-8"
                              name="paymentType"
                              onChange={(_, val) =>
                                this.onChangePaymentSchedule(
                                  0,
                                  "useRemainingBalance",
                                  val,
                                )
                              }
                              options={[
                                {
                                  display: (
                                    <NumberFormat
                                      allowNegative={false}
                                      className={`custom-input payment-percentage-input${
                                        submitAttempted &&
                                        !payment1.paymentPercentage
                                          ? " error"
                                          : ""
                                      }`}
                                      decimalScale={0}
                                      isAllowed={({ value }) => value <= 100}
                                      onValueChange={({ floatValue }) =>
                                        this.onChangePaymentSchedule(
                                          0,
                                          "paymentPercentage",
                                          floatValue >= 0 ? floatValue : "",
                                        )
                                      }
                                      thousandSeparator={true}
                                      value={payment1.paymentPercentage}
                                    />
                                  ),
                                  value: false,
                                },
                                {
                                  disabled: !canCreateFinalPaymentSchedule,
                                  display: "Remaining Balance",
                                  tag: !canCreateFinalPaymentSchedule ? (
                                    <p className="accent-text xs-text mt-4">
                                      All available grants must be allocated,
                                      and all allocations submitted (and
                                      unflagged) before creating a payment for
                                      the remaining balance
                                    </p>
                                  ) : (
                                    ""
                                  ),
                                  value: true,
                                },
                              ]}
                              value={payment1.useRemainingBalance}
                            />
                          )}
                        </div>
                        <div className="flex flex-align-center mb-16">
                          <label>Payment Request Date</label>
                          <p className="small-text">
                            {(payment1.id
                              ? moment(payment1.paymentRequestedDate)
                              : moment()
                            ).format("MM/DD/YYYY")}
                          </p>
                        </div>
                        <div className="flex flex-align-center mb-16">
                          <label>Payment Date</label>
                          <input
                            type="date"
                            className={`custom-input ${
                              submitAttempted && !payment1.paymentProcessingDate
                                ? "error"
                                : ""
                            }`}
                            name="paymentProcessingDate"
                            onChange={(e) =>
                              this.onChangePaymentSchedule(
                                0,
                                e.target.name,
                                removeTimezoneFormatFromDate(e.target.value),
                              )
                            }
                            value={formatDateForInput(
                              payment1.paymentProcessingDate,
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {(!payment1 || !payment1.useRemainingBalance) && (
                    <div>
                      <div className="flex mb-16">
                        <p className="payment-title">Payment 2</p>
                        {!!payment1 && !payment2 && (
                          <div className="ml-16">
                            <button
                              disabled={!canCreateFinalPaymentSchedule}
                              className="custom-btn btn btn-accent btn-small"
                              onClick={this.onCreatePaymentSchedule}
                            >
                              Create payment
                            </button>
                            {!canCreateFinalPaymentSchedule && (
                              <p className="accent-text xs-text mt-4">
                                All available grants must be allocated, and all
                                allocations submitted (and unflagged) before
                                creating final payment
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                      {!!payment2 && (
                        <div>
                          <div className="flex flex-align-center mb-16">
                            <label>Payment Amount</label>
                            <p className="small-text">Remaining Balance</p>
                          </div>
                          <div className="flex flex-align-center mb-16">
                            <label>Payment Request Date</label>
                            <p className="small-text">
                              {(payment2.id
                                ? moment(payment2.paymentRequestedDate)
                                : moment()
                              ).format("MM/DD/YYYY")}
                            </p>
                          </div>
                          <div className="mb-16">
                            <div className="flex flex-align-center">
                              <label>Payment Date</label>
                              <input
                                type="date"
                                className={`custom-input ${
                                  submitAttempted &&
                                  !payment2.paymentProcessingDate
                                    ? "error"
                                    : ""
                                }`}
                                min={formatDateForInput(
                                  payment1.paymentProcessingDate,
                                )}
                                name="paymentProcessingDate"
                                onChange={(e) =>
                                  this.onChangePaymentSchedule(
                                    1,
                                    e.target.name,
                                    removeTimezoneFormatFromDate(
                                      e.target.value,
                                    ),
                                  )
                                }
                                value={formatDateForInput(
                                  payment2.paymentProcessingDate,
                                )}
                              />
                            </div>
                            {submitAttempted &&
                              payment2.paymentProcessingDate <
                                payment1.paymentProcessingDate && (
                                <p className="error-text mt-4">
                                  Payment 2 date must be after Payment 1 date
                                </p>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex flex-justify-end">
                  {allocationStats.allocations &&
                    allocationStats.allocations.length > 0 && (
                      <div className="manage-payments-totals">
                        <div>
                          {allocationStats.allocations.map((alloc, index) => (
                            <div
                              className="flex flex-align-center flex-justify-space mb-16"
                              key={index}
                            >
                              <p className="mr-8">
                                {formatNumber(alloc.submittedQuantity)}{" "}
                                {alloc.grantName}
                              </p>
                              <p>${formatNumber(alloc.submittedAmount)}</p>
                            </div>
                          ))}
                        </div>
                        <div className="divider" />
                        <div>
                          <div className="flex flex-align-center flex-justify-space mb-16">
                            <p className="mr-8">Submitted:</p>
                            <p>
                              {formatNumber(allocationStats.quantitySubmitted)}{" "}
                              /{" "}
                              {formatNumber(allocationStats.quantityAllocated)}
                            </p>
                          </div>
                          <div className="flex flex-align-center flex-justify-space mb-16">
                            <p className="mr-8">Total Amount:</p>
                            <p>
                              ${formatNumber(allocationStats.amountSubmitted)}
                            </p>
                          </div>
                          <div className="flex flex-align-center flex-justify-space mb-16">
                            <p className="mr-8">Total Paid:</p>
                            <p>${formatNumber(allocationStats.amountPaid)}</p>
                          </div>
                          <div className="flex flex-align-center flex-justify-space">
                            <p className="mr-8">Total Owed:</p>
                            <p className="fw-700" style={{ color: "#e94746" }}>
                              ${formatNumber(allocationStats.amountOwed)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <div className="text-right mt-24">
                <div className="flex flex-align-center flex-justify-end">
                  <button
                    className="btn link-text uppercase-text"
                    disabled={loading}
                    onClick={this.onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-accent uppercase-text ml-24"
                    disabled={
                      loading ||
                      _isEqual(initialPaymentSchedules, paymentSchedules)
                    }
                    onClick={this.onSubmit}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
                {submitAttempted && !paymentSchedules.length ? (
                  <p>Please enter a payment</p>
                ) : (
                  errorMessage && <p className="error-text">{errorMessage}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
