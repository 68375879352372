import React from "react";
import NumberFormat from "react-number-format";
import PromoCodesTable from "./PromoCodesTable";
import StudentsPricingDetails from "./StudentsPricingDetails";
import ValidatedInput from "../../../shared/ValidatedInput";
import { TripTypes } from "../../TripEventConsts";

export default class EventPricing extends React.PureComponent {
  render() {
    const {
      event: {
        hasStartedShluchimEnrollment,
        hasStudentRegistrations,
        isTravelTrip,
        promoCodes,
        rewardCreditValue,
        shliachPrice,
        type,
      },
      onChange,
      onChangeEvt,
      submitAttempted,
      track: {
        earlyBirdStudentDeadline,
        earlyBirdStudentPrice,
        regularStudentPrice,
        studentDepositAmount,
      },
      trackFieldPrefix,
    } = this.props;

    const isJewishUTrip = type === TripTypes.JewishU;

    return (
      <React.Fragment>
        <div className="program-settings-page-section">
          <p className="program-settings-page-title xxl-text fw-500">Pricing</p>
          {hasStartedShluchimEnrollment && (
            <span className="accent-text">
              * Pricing changes will not affect existing enrollments or
              registrations.
            </span>
          )}
        </div>

        {!isTravelTrip && (
          <div className="program-settings-page-section">
            <p className="medium-text fw-700 mb-16">Shluchim</p>
            <div className="two-column-grid pricing-two-column-grid">
              <div className="flex flex-align-center">
                <label className="input-label">Price</label>
                <ValidatedInput
                  input={
                    <NumberFormat
                      allowNegative={false}
                      className="dollar-input"
                      onValueChange={({ floatValue }) =>
                        onChange(
                          "shliachPrice",
                          floatValue >= 0 ? floatValue : ""
                        )
                      }
                      decimalScale={2}
                      thousandSeparator={true}
                    />
                  }
                  name="shliachPrice"
                  required={true}
                  showRequired={submitAttempted && shliachPrice !== 0}
                  value={shliachPrice}
                />
              </div>
            </div>
          </div>
        )}

        <StudentsPricingDetails
          onChange={onChange}
          onChangeEvt={onChangeEvt}
          hasStudentRegistrations={hasStudentRegistrations}
          isJewishUTrip={isJewishUTrip}
          isTravelTrip={isTravelTrip}
          priceDetails={{
            earlyBirdStudentDeadline,
            earlyBirdStudentPrice,
            regularStudentPrice,
            rewardCreditValue,
            studentDepositAmount,
          }}
          trackFieldPrefix={trackFieldPrefix}
          submitAttempted={submitAttempted}
        />

        <PromoCodesTable
          isJewishUTrip={isJewishUTrip}
          isTravelTrip={isTravelTrip}
          onChange={onChange}
          promoCodes={promoCodes}
        />
      </React.Fragment>
    );
  }
}
