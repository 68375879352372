import React from "react";
import { Link } from "react-router";

import {
  CategoryTypes /* , OrganizationCategory */,
} from "../../../../models/Organization";
import DataTable /* , { DataTableColumn } */ from "../../../common/DataTable";

// interface OrganizationCategoryListProps {
//   categories: Array<OrganizationCategory>;
// }

const OrganizationCategoryList = ({ categories }) => {
  /** @type {Array<DataTableColumn>} */
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (c) => (
        <Link
          to={`/config/orgcategories/${c.original.id}`}
          style={{ cursor: "pointer" }}
        >
          {c.original.name}
        </Link>
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (c) => CategoryTypes[c.original.type] || "-",
    },
  ];
  /** @param {OrganizationCategory} a
   * @param {OrganizationCategory} b
   * @returns {number}
   */
  const sortCategories = (a, b) =>
    a.type !== b.type ? a.type - b.type : a.name < b.name ? -1 : 1;

  return (
    <div className="col-sm-12">
      <DataTable
        fullClientSide={true}
        columns={columns}
        reportData={categories.sort(sortCategories)}
      />
    </div>
  );
};

export default OrganizationCategoryList;
