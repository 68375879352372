import { asEnum } from "../lib";

export const Gender = asEnum({
  Male: 0,
  Female: 1,
  Unknown: 2,
});

/** @typedef {object} Contact
 * @property {number} [id]
 * @property {string} firstName
 * @property {string} lastName
 * @property {Gender} gender
 * @property {Date} [dob]
 * @property {string} hebrewName
 * @property {boolean} isJewish
 * @property {Address} [homeAddress]
 * @property {Array<ContactMethods>} [contactMethods]
 * @property {boolean} [isAdmin]
 * @property {string} [title]
 * @property {[propertyName: string]}
 */

/** @typedef {Contact} Student
 * @property {Array<number>} schools
 */

/** @typedef {Contact} User
 * @property {Array<Role>} roles
 * @property {Array<Claim>} claims
 */

/** @returns {Contact} */
export function EmptyContact() {
  return {
    firstName: "",
    lastName: "",
    id: -1,
    gender: Gender.Unknown,
    hebrewName: "",
    isJewish: false,
    homeAddress: EmptyAddress(),
    contactMethods: [],
  };
}
/** @returns {User} */
export function EmptyUser() {
  return { ...EmptyContact(), roles: [], claims: [] };
}

/** @typedef {object} Address
 * @property {number} [id]
 * @property {string} addressLine1
 * @property {string} addressLine2
 * @property {string} city
 * @property {string} [postalCode]
 * @property {number} [stateId]
 * @property {string} [state]
 * @property {number} [countryId]
 * @property {string} [country]
 */
/** @returns {Address} */
export function EmptyAddress() {
  return {
    addressLine1: "",
    addressLine2: "",
    city: "",
    id: -1,
  };
}

export const ContactMethodsStrings = {
  Email: "EmailAddress",
  HomePhone: "PhoneNumber",
  Mobile: "CellPhoneNumber",
};

/** @typedef {object} ContactMethods
 * @property {number} id
 * @property {"EmailAddress" | "CellPhoneNumber" | "PhoneNumber"} type
 * @property {boolean} primary
 * @property {string} value
 */

/** @returns {ContactMethods} */
export function EmptyContactMethod() {
  return {
    value: "",
    type: ContactMethodsStrings.Email,
    primary: false,
    id: -1,
  };
}

/** @param {Array<ContactMethods>} contactMethods
 * @package {string} type
 */
export function GetMainContactMethod(contactMethods, type) {
  const methods = (contactMethods || [])
    .filter((cm) => cm.type === type)
    .sort((a, b) => (a.primary ? -1 : b.primary ? 1 : 0));
  return methods.length > 0 ? methods[0].value : "";
}

/** @typedef {object} ClaimGroup
 * @property {number} id
 * @property {string} name
 * @property {number} sortOrder
 */

/** @typedef {object} Claim
 * @property {number} id
 * @property {string} name
 * @property {string} description
 * @property {string} code
 * @property {number} sortOrder
 * @property {ClaimGroup} claimGroup
 */

/** @typedef {object} Role
 * @property {number} [id]
 * @property {string} name
 * @property {string} description
 * @property {Array<Claim>} [claims]
 */
