import React from "react";
import { Link } from "react-router";

import { /* CustomPage, */ EmptyCustomPage } from "../../../models/CustomPage";
import { CustomPageManager /* , ImageType */ } from "./CustomPageManager";

// interface CustomPageManagerProps {
//   customPageData: CustomPage;
//   linksBase: string;
//   nameChanged: boolean;
//   handleCustomPageDataChange: (newValue: CustomPage) => void;
//   handleImageChange: (image: Blob, url: string, imgType: ImageType) => void;
//   handleSave: () => void;
//   handleDelete: () => void;
// }

export const CustomPageManagerPage = ({
  customPageData = EmptyCustomPage(),
  linksBase,
  nameChanged,
  handleCustomPageDataChange,
  handleImageChange,
  handleSave,
  handleDelete,
}) => {
  return (
    <div className="col-sm-12">
      <ol className="breadcrumb">
        <li>
          <Link to="/config/custompages">Custom Pages</Link>
        </li>
        <li>{customPageData.title || "[new custom page]"}</li>
      </ol>
      <div className="well well-lg">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <CustomPageManager
              customPageData={customPageData}
              linksBase={linksBase}
              nameChanged={nameChanged}
              handleCustomPageDataChange={handleCustomPageDataChange}
              handleImageChange={handleImageChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9" />
          <div className="col-sm-3">
            <span
              className="btn btn-primary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
            >
              Save
            </span>{" "}
            {(customPageData.id || 0) > 0 && (
              <span
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete();
                }}
              >
                Delete
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
