export const FilterModalStyles = {
  modal: {
    overlay: {
      zIndex: 5,
      backgroundColor: "rgba(42,63,84, 0.85)",
    },
    content: {
      top: "4%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, 0)",
      overflow: "auto",
      minWidth: "600px",
      maxHeight: "92vh",
    },
  },
  modalMobile: {
    overlay: {
      backgroundColor: "rgba(42,63,84, 0.85)",
    },
    content: {
      top: "10%",
    },
  },
};
/** @param {string} optionList
 * @returns {Array<number>}
 */
export const getSelectValues = (optionList) => {
  return optionList
    ? optionList
        .split(",")
        .filter((v) => !isNaN(+v))
        .map((v) => +v)
    : [];
};
