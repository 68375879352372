import React from "react";
import { browserHistory } from "react-router";
import { ApiCallErrorHandler } from "../../../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons /* , ModalButton */,
} from "../../../../lib/coc-common-components";

import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import OrganizationApi from "../../../../services/resources/OrganizationsApi";
import {
  /* OrganizationCategory, */ EmptyOrganizationCategory,
} from "../../../../models/Organization";
import OrganizationCategoryManagerPage from "./OrganizationCategoryManagerPage";

// interface OrganizationCategoryManagerContainerProps {
//   location: { [key: string] };
//   params: { [key: string] };
// }

// interface OrganizationCategoryManagerContainerState {
//   loading: boolean;
//   categoryData: OrganizationCategory;
//   displayMessage: string;
//   displayAndRedirectMessage: string;
//   displayDeleteMessage: string;
// }

class OrganizationCategoryManagerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      categoryData: EmptyOrganizationCategory(),
      displayMessage: "",
      displayAndRedirectMessage: "",
      displayDeleteMessage: "",
    };

    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
  }
  /** @param {React.FormEvent<HTMLFormElement>} evt */
  handleDataChange(evt) {
    this.setState({
      categoryData: {
        ...this.state.categoryData,
        [evt.currentTarget.name]: evt.currentTarget.value,
      },
    });
  }

  /** @param {OrganizationCategory} */
  handleSave(organizationCategory) {
    this.setState({ loading: true }, () => {
      const isNew = !organizationCategory.id || organizationCategory.id < 0;
      const apiCall = isNew
        ? OrganizationApi.createCategory
        : OrganizationApi.updateCategory;
      apiCall(organizationCategory)
        .then((response) => {
          this.setState({
            categoryData: response.data.result.value,
            displayAndRedirectMessage: "Category saved successfully",
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }

  /** @param {OrganizationCategory} */
  handleDelete(organizationCategory) {
    this.setState({
      displayDeleteMessage: "You are about to delete this category",
    });
  }

  deleteCategory() {
    this.setState({ displayDeleteMessage: "", loading: true }, () =>
      OrganizationApi.deleteCategory(this.state.categoryData.id)
        .then((response) => {
          this.setState({
            loading: false,
            displayAndRedirectMessage: "Category successfully deleted",
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        }),
    );
  }

  confirmDeleteButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "I understand. Delete it now.",
      className: "btn btn-danger",
      onClick: this.deleteCategory,
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDeleteMessage: "" }),
    });
    return buttons;
  }

  messageButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayMessage: "" }),
    });
    return buttons;
  }

  /** @param {string} */
  okAndRedirectButtons(redirectUrl) {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({ displayMessage: "" }, () => {
          browserHistory.push(redirectUrl);
        }),
    });
    return buttons;
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryEdit)) {
      return;
    }

    const id = Number(this.props.params.id);
    if (!Number.isNaN(id)) {
      this.setState({ loading: true }, () =>
        OrganizationApi.getCategory(id)
          .then((response) => {
            this.setState({
              categoryData: response.data,
              loading: false,
            });
          })
          .catch((err) => {
            const errors = ApiCallErrorHandler(err, true);
            this.setState({
              loading: false,
              displayMessage: errors.join("\n"),
            });
          }),
      );
    }
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.DirectoryEdit)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { categoryData } = this.state;
    return (
      <div>
        <OrganizationCategoryManagerPage
          organizationCategoryData={categoryData}
          handleDataChange={this.handleDataChange}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
        />
        {this.state.displayMessage && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayMessage}
          />
        )}
        {this.state.displayAndRedirectMessage && (
          <ModalWithButtons
            buttons={this.okAndRedirectButtons("/config/orgcategories")}
            text={this.state.displayAndRedirectMessage}
          />
        )}
        {this.state.displayDeleteMessage && (
          <ModalWithButtons
            buttons={this.confirmDeleteButtons()}
            text={this.state.displayDeleteMessage}
          />
        )}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default OrganizationCategoryManagerContainer;
