import React from "react";
import AuthClient from "../../../../services/resources/AuthClient";
import { browserHistory, Link } from "react-router";

import { Formik, Form } from "formik";
import Select from "react-select";

import Loader from "../Loader";
import FormInput from "../Form/FormInput";

export default class ChabadHouseOptions extends React.PureComponent {
  state = {
    chabadHouses: [],
  };

  componentDidMount() {
    this._ismounted = true;
    this.getChabadHouses();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  getChabadHouses = async () => {
    const { data } = await AuthClient.get(`ChabadHouses/basic`).catch((err) => {
      return { data: [] };
    });
    if (this._ismounted) {
      this.setState({ chabadHouses: data });
    }
  };

  onSubmit = async (values) => {
    const { shliach, shliachID } = this.props;
    const chabadHouseID = values.chabadHouse.key;
    let chabadHouse = {
      chabadHouseID: values.chabadHouse.key,
      chabadHouseName: values.chabadHouse.value,
      isPrimary: !shliach.chabadHouses.length,
    };
    shliach.chabadHouses.push(chabadHouse);
    const { error } = await AuthClient.post(
      `Shluchim/${shliachID}/chabadHouses`,
      shliach,
    ).catch((error) => {
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 3000);
      return { error };
    });
    if (error) {
      this.setState({ submitted: false });
      return;
    } else {
      this.props.updateMenuLists(shliachID);
      browserHistory.push(
        `/shluchim/${shliachID}/chabadHouse/${chabadHouseID}`,
      );
    }
  };

  render() {
    const { shliachID } = this.props;
    return this.state.chabadHouses.length > 0 ? (
      <Formik
        initialValues={{ chabadHouse: "" }}
        validate={this.validateForm}
        onSubmit={this.onSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => {
          return (
            <Form>
              <div className="card profile-form-card chabad-house-options-card mr-24 flex">
                <div>
                  <p className="fw-700 medium-text">Add a new Chabad House</p>
                  <Link
                    to={`/shluchim/${shliachID}/chabadHouse`}
                    className="btn btn-accent"
                  >
                    New Chabad House
                  </Link>
                </div>
                <div>
                  <p className="fw-700">OR</p>
                  <div className="line" />
                </div>
                <div>
                  <p className="fw-700 medium-text">
                    Choose an existing Chabad House
                  </p>
                  <FormInput
                    name={`chabadHouse`}
                    errors={errors}
                    touched={touched}
                    className="custom-input mr-12"
                    style={{ flex: "2" }}
                  >
                    {({ field }) => (
                      <Select
                        className={"custom-select"}
                        classNamePrefix="custom-select"
                        getOptionLabel={(option) => option.label}
                        name={field.name}
                        onChange={(option) => setFieldValue(field.name, option)}
                        options={
                          this.state.chabadHouses &&
                          this.state.chabadHouses.map((c) => ({
                            key: c.id,
                            value: c.name,
                            label: c.name,
                          }))
                        }
                        placeholder="Select Chabad House..."
                        value={field.value}
                      />
                    )}
                  </FormInput>
                  <button
                    className="link-text fw-700 link-ch-btn"
                    type="submit"
                    disabled={!values.chabadHouse ? true : false}
                  >
                    Link Shliach
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    ) : (
      <div>
        <Loader />
      </div>
    );
  }
}
