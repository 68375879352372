import React, { useState } from "react";
import FilePreviewModal from "../../../../../common/FilePreviewModal";

function RegistrationMedical(props) {
  const {
    isProofOfVaccinationRequired,
    isTravelTrip,
    registration: {
      student: {
        allergiesDisplay,
        hasAllergies,
        dietaryNotes,
        vaccinationProofUrls,
      },
      travelApplication,
    },
  } = props;

  const {
    emergencyContacts,
    hasMedicalCondition,
    isTakingMedication,
    isUnderPsychologicalCare,
    medicalConditionDescription,
    medicationDescription,
    psychologicalCareDescription,
  } = travelApplication || {};

  const [vaccinationProofPreviewUrl, setVaccinationProofPreviewUrl] =
    useState();

  return (
    <div className="trip-student-detail-tab">
      <div className="xl-text fw-700">Medical</div>
      <div className="student-detail-section">
        <div className="medium-text fw-700 mb-8">Has allergies?</div>
        <div>
          {hasAllergies ? <span className="error-text">Yes</span> : "No"}
          {!!allergiesDisplay?.length && (
            <div className="mt-8">{allergiesDisplay.join(", ")}</div>
          )}
          {dietaryNotes && <div className="mt-8">{dietaryNotes}</div>}
        </div>
      </div>
      {isTravelTrip && (
        <>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">
              Has medical conditions?
            </div>
            <div>
              {hasMedicalCondition ? (
                <span className="error-text">Yes</span>
              ) : (
                "No"
              )}
              {medicalConditionDescription && (
                <div className="mt-8">{medicalConditionDescription}</div>
              )}
            </div>
          </div>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">Taking medication?</div>
            <div>
              {isTakingMedication ? (
                <span className="error-text">Yes</span>
              ) : (
                "No"
              )}
              {medicationDescription && (
                <div className="mt-8">{medicationDescription}</div>
              )}
            </div>
          </div>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">
              Seeing a psychologist or psychiatrist?
            </div>
            <div>
              {isUnderPsychologicalCare ? (
                <span className="error-text">Yes</span>
              ) : (
                "No"
              )}
              {psychologicalCareDescription && (
                <div className="mt-8">{psychologicalCareDescription}</div>
              )}
            </div>
          </div>
          <div className="student-detail-section">
            <div className="medium-text fw-700 mb-8">Emergency contacts</div>
            <div className="double-grid-container">
              {emergencyContacts?.slice(0, 2).map((contact, index) => (
                <div className="two-column-grid" key={index}>
                  <p className="fw-700">Name</p>
                  <p>{contact.name}</p>
                  <p className="fw-700">Phone</p>
                  <p>{contact.phoneNumber}</p>
                  <p className="fw-700">Email</p>
                  <p>{contact.email}</p>
                  <p className="fw-700">Relationship</p>
                  <p>{contact.relationship}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {isProofOfVaccinationRequired && (
        <div className="student-detail-section">
          <p className="medium-text fw-700 mb-12">Proof of vaccination</p>
          {vaccinationProofUrls?.length > 0 ? (
            vaccinationProofUrls.map((url, i) => (
              <p
                className="flex flex-align-center accent-text mb-16 link-text"
                key={i}
                onClick={() => setVaccinationProofPreviewUrl(url)}
              >
                <i className="material-icons mr-8">description</i>
                Vaccination proof #{i + 1}
              </p>
            ))
          ) : (
            <p>None</p>
          )}
          <FilePreviewModal
            close={() => setVaccinationProofPreviewUrl(undefined)}
            isSecure={true}
            show={!!vaccinationProofPreviewUrl}
            title="Proof Of Vaccination"
            type="application/pdf"
            url={vaccinationProofPreviewUrl}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(RegistrationMedical);
