import React from "react";
import { Link } from "react-router";
import EnrollmentStatusTag from "./EnrollmentStatusTag";
import moment from "moment";

export default class EnrollmentsTableRecord extends React.PureComponent {
  render() {
    const {
      enrollment: {
        enrollmentDate,
        id,
        lastRenewalDate,
        shliachFirstName,
        shliachLastName,
        shluchaFirstName,
        status,
        statusDisplay,
      },
      scheduleId,
    } = this.props;

    return (
      <Link
        className="life-insurance-enrolled-table-row"
        to={`/life-insurance/${scheduleId}/enrollments/${id}`}
      >
        <p>{shliachLastName}</p>
        <p>{shliachFirstName}</p>
        <p>{shluchaFirstName}</p>
        <p className="flex flex-align-center">
          <EnrollmentStatusTag status={status} statusDisplay={statusDisplay} />
        </p>
        <p>
          {enrollmentDate ? moment(enrollmentDate).format("MM/DD/YYYY") : ""}
        </p>
        <p>
          {lastRenewalDate
            ? moment(lastRenewalDate).format("MM/DD/YYYY")
            : "N/A"}
        </p>
      </Link>
    );
  }
}
