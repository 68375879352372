import axios from "axios";

export class FileUploader {
  /** The file being uploaded.
   * @type {File}
   */
  file;
  /** Upload progress event handler.
   * @param {ProgressEvent} e Native `ProgressEvent`
   * @param {number} progress Calculated progress percentage. (`0.00 - 100.00`)
   */
  onUploadProgress = function (e, progress) {};
  /** The URL to upload to.
   * @type {string}
   */
  url;
  /** @type {object} */
  upload_headers;

  /** Creates a new `FileUploader`.
   * @param {File} file The file to upload.
   * @param {string} url The url to upload to.
   * @param {FileUploaderConfig} [config]
   */
  constructor(file, url, config = {}) {
    this.file = file;
    this.url = url;
    const { onUploadProgress, upload_headers } = config;
    if (typeof onUploadProgress === "function") {
      this.onUploadProgress = onUploadProgress;
    }
    this.upload_headers = upload_headers;
  }
  /** Cancels the current upload. */
  cancel = (message) => {
    // NOTE: During upload, this method is replaced with an axios cancel func.
  };
  /** NOT-IMPLEMENTED: Pauses the current upload. Requires resumable uploads. */
  pause = () => {
    console.error("FileUploader.pause not implemented.");
  };
  /** NOT-IMPLEMENTED: Resumes the current upload if paused.
   * Requires resumable uploads.
   */
  resume = () => {
    console.error("FileUploader.resume not implemented.");
  };
  /** Starts the upload. */
  start = async () => {
    let { file, onUploadProgress, url, upload_headers } = this;
    const uploadConfig = {
      cancelToken: new axios.CancelToken((cancel) => {
        this.cancel = cancel;
      }),
      headers: {
        "Content-Type": file.type,
        ...upload_headers,
      },
      onUploadProgress: function handleUploadProgress(e) {
        if (e.lengthComputable) {
          var progress = (e.loaded / e.total) * 100;
          onUploadProgress(e, progress);
        } else {
          // This condition is because I don't know what to do if the
          // length is not computable...
          // CONSIDER: We could use fake progress in this case too.
          onUploadProgress(e, 50);
        }
      },
    };
    const response = await axios.put(url, file, uploadConfig).catch((err) => {
      if (axios.isCancel(err)) {
        console.log(`Cancelled upload of "${file.name}"`);
        return undefined;
      }
      this.cancel = FileUploader.prototype.cancel;
      throw err;
    });
    this.cancel = FileUploader.prototype.cancel;
    return response;
  };
}
export default FileUploader;
