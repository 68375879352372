import React from "react";
import Dropzone from "react-dropzone";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import Modal from "react-modal";

import LamplightersApi from "../../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import { notify } from "react-notify-toast";
import { formatFullName } from "../../../../lib";

export default class SinaiScholarsImportModal extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      fileForUpload: null,
      loading: false,
      submitAttempted: false,
      uploadProgress: 0,
    };

    this.state.initialState = _cloneDeep(this.state);
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onClose = () => {
    this.setState(this.state.initialState, this.props.close);
  };

  selectFile = (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      this.setState({
        errorMessage: "",
        fileForUpload: file,
      });
    }
  };

  uploadRecords = async () => {
    const { fileForUpload } = this.state;

    this.setState({
      errorMessage: "",
      submitAttempted: true,
    });

    if (!fileForUpload) {
      return;
    }

    this.setState({
      errorMessage: "",
      loading: true,
      uploadProgress: 0,
    });

    try {
      const { scheduleId, reloadLocations } = this.props;

      await LamplightersApi.importLocations(
        this.apiSignal.token,
        scheduleId,
        fileForUpload,
        (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          this.setState({
            uploadProgress: progress > 100 ? 100 : progress,
          });
        },
      );

      reloadLocations();
      this.onClose();
      notify.show(`Locations have been successfully imported`, "success");
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
          uploadProgress: 0,
        });
      }
    }
  };

  render() {
    const { chabadHouses, grants, show } = this.props;
    const {
      errorMessage,
      fileForUpload,
      loading,
      submitAttempted,
      uploadProgress,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div
          className="modal-card card import-modal"
          style={{ maxHeight: "96vh", overflow: "auto" }}
        >
          <p className="xl-text fw-700">Import Locations</p>

          <p className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            Use this feature to upload a spreadsheet of multiple locations and
            their Lamplighters allocations for this Lamplighters grant.
          </p>
          <div className="accent-text-dark mt-16" style={{ lineHeight: "1.5" }}>
            <ExportCSVButton
              className="link-text flex flex-align-center mb-0"
              title="Download a list of all current Chabad Houses in the template here."
              fileName="Lamplighters-Locations-Import-Template"
              getExportData={() => chabadHouses.chabadHousesList}
              headers={[
                { label: "Chabad House ID", value: "chabadHouseID" },
                { label: "Chabad House Name", value: "chabadHouseName" },
                {
                  label: "Shliach Name",
                  value: ({ shliachFirstName, shliachLastName }) =>
                    formatFullName(shliachFirstName, shliachLastName),
                },
                {
                  label: "Shlucha Name",
                  value: ({ shluchaFirstName, shluchaLastName }) =>
                    formatFullName(shluchaFirstName, shluchaLastName),
                },
                { label: "Campus Name", value: "campusName" },
                ...grants.map((g) => g.name),
              ]}
            />{" "}
            Fill in the necessary grant allocation quantities and remove all
            Chabad Houses that will not be included in this Lamplighters grant.
            (Do not update the Chabad House name or ID fields).
          </div>
          <p className="accent-text mt-16" style={{ lineHeight: "1.5" }}>
            File must meet the following requirements before uploading: <br />
            * File must be saved in CSV or Excel format <br />
            * Delete any blank rows in your file (in Excel files, default blank
            template rows must be deleted) <br />
          </p>

          <div className="mt-24">
            <p
              className={`fw-700 uppercase-text small-text accent-text-dark mb-8 ${
                submitAttempted && !fileForUpload ? "error-text" : ""
              }`}
            >
              Select Lamplighters Import file
            </p>
            <div className="flex mb-24 flex-justify-space">
              <Dropzone
                // accept={'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                onDrop={this.selectFile}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <p className="uppercase-text fw-500 link-text">
                      {fileForUpload ? "Change " : "Select"} File
                    </p>
                  </div>
                )}
              </Dropzone>

              {fileForUpload && (
                <div className="uploading">
                  <div className="flex flex-justify-space flex-align-center">
                    <p className="fw-700 mr-8 mb-8 flex flex-align-center">
                      <i className="material-icons accent-text mr-8">
                        description
                      </i>
                      {fileForUpload.name}
                    </p>
                  </div>
                  <div className="percentage-bar relative">
                    <div className="bar" />
                    <div
                      className="filled-in"
                      style={{ width: `${uploadProgress}%` }}
                    />
                    <div className="labels flex flex-justify-space mt-8">
                      <p />
                      <p className="accent-text small-text">
                        {uploadProgress}%
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="raffle-modal-btns flex flex-justify-end flex-align-center mt-24">
            <button className="btn btn-light btn-medium" onClick={this.onClose}>
              Cancel
            </button>
            <button
              className="btn btn-accent btn-medium ml-16"
              disabled={loading}
              onClick={this.uploadRecords}
            >
              {!loading ? "Upload Locations" : "Uploading..."}
            </button>
          </div>
          {submitAttempted && errorMessage && (
            <p className="small-text error-text text-right mt-4">
              {errorMessage}
            </p>
          )}
        </div>
      </Modal>
    );
  }
}
