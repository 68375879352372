import React from "react";

import {
  /* ContactMethods, */ EmptyContactMethod,
} from "../../../models/Contact";
import ContactMethod from "../ContactMethod/ContactMethod";

// interface RelatedContactContactMethodsListProps {
//   contactMethods?: Array<ContactMethods>;
//   ddStyles?: { [styleName: string]: string };
//   handleContactMethodsChange: (newContactMethods: Array<ContactMethods>) => void;
// }

const RelatedContactContactMethodsList = ({
  contactMethods = [],
  countries = [],
  ddStyles = {},
  handleContactMethodsChange,
}) => {
  /** @param {Array<ContactMethods>} contactMethodList
   * @param {"EmailAddress" | "CellPhoneNumber" | "PhoneNumber"} type
   * @returns {Array<ContactMethods>}
   */
  const ensurePrimaryExistByType = (contactMethodList, type) => {
    const newContactMethods = contactMethodList.filter(
      (cm) => cm.type === type,
    );
    const primaryCM = newContactMethods.find((cm) => cm.primary);
    if (!primaryCM && newContactMethods.length > 0) {
      contactMethodList.find((cm) => cm.type === type).primary = true;
    }
    return newContactMethods;
  };
  /** @param {Array<ContactMethods>} contactMethodList
   * @returns {Array<ContactMethods>}
   */
  const ensurePrimaryExistForAllTypes = (contactMethodList) => {
    const newContactMethods = contactMethodList.slice();
    ensurePrimaryExistByType(newContactMethods, "EmailAddress");
    ensurePrimaryExistByType(newContactMethods, "CellPhoneNumber");
    ensurePrimaryExistByType(newContactMethods, "PhoneNumber");
    return newContactMethods;
  };
  /** @param {ContactMethods} updatedContactMethod
   * @param {Array<ContactMethods>} contactMethodList
   * @returns {Array<ContactMethods>}
   */
  const ensureSinglePrimary = (updatedContactMethod, contactMethodList) => {
    if (!updatedContactMethod.primary) {
      return contactMethodList;
    }
    return contactMethodList.map((cm) =>
      cm.id === updatedContactMethod.id ||
      !cm.primary ||
      cm.type !== updatedContactMethod.type
        ? cm
        : { ...cm, primary: false },
    );
  };
  /** @param {ContactMethods} updatedContactMethod */
  const handleDataChange = (updatedContactMethod) => {
    const newContactMethods = contactMethods.slice();
    const cmIx = newContactMethods.findIndex(
      (cm) => cm.id === updatedContactMethod.id,
    );
    newContactMethods.splice(cmIx, 1, updatedContactMethod);
    handleContactMethodsChange(
      ensurePrimaryExistForAllTypes(
        ensureSinglePrimary(updatedContactMethod, newContactMethods),
      ),
    );
  };
  /** @param {number} id */
  const handleRemoveContactMethod = (id) => {
    const newContactMethods = contactMethods.filter((cm) => cm.id !== id);
    handleContactMethodsChange(
      ensurePrimaryExistForAllTypes(newContactMethods),
    );
  };

  const handleAddContactMethod = (evt) => {
    evt.preventDefault();
    const newContactMethods = contactMethods.slice();
    newContactMethods.push({
      ...EmptyContactMethod(),
      id: -Math.floor(Math.random() * 100000 + 10000),
    });
    handleContactMethodsChange(
      ensurePrimaryExistForAllTypes(newContactMethods),
    );
  };

  return (
    <div>
      {contactMethods.map((contactMethod) => (
        <ContactMethod
          key={contactMethod.id}
          contactMethod={contactMethod}
          countries={countries}
          handleChange={handleDataChange}
          handleRemove={(evt) => {
            evt.preventDefault();
            handleRemoveContactMethod(contactMethod.id);
          }}
        />
      ))}
      <button
        className="btn btn-primary btn-sm"
        style={{ marginBottom: "0.5em" }}
        onClick={handleAddContactMethod}
      >
        Add Contact Method
      </button>
    </div>
  );
};

export default RelatedContactContactMethodsList;
