import AuthClient from "./AuthClient";
import queryString from "query-string";

const LifeInsuranceApi = {
  getLifeInsurancePolicy: async (
    cancelToken,
    scheduleId = "",
    includeEnrollmentCounts = false,
  ) => {
    const response = await AuthClient.get(
      `LifeInsurancePolicies/${scheduleId}?includeEnrollmentCounts=${includeEnrollmentCounts}`,
      { cancelToken },
    );
    return response.data.payload || {};
  },
  getLifeInsuranceEnrollments: async (
    cancelToken,
    policyId,
    page,
    results,
    sortBy,
    filters,
    enrolled,
    isExport,
  ) => {
    const params = {
      enrollmentStatuses: filters.statuses,
      isExport,
      page,
      policyId,
      results,
      sortByOption: sortBy,
      status: enrolled ? "Enrolled" : "UnEnrolled",
      text: filters.keyword,
    };

    const response = await AuthClient.get(
      `LifeInsuranceEnrollments?${queryString.stringify(params)}`,
      { cancelToken },
    );
    return {
      enrollments: response.data.payload.results || [],
      totalEnrollments: response.data.payload.numberOfRows || 0,
    };
  },
  getLifeInsuranceEnrollment: async (cancelToken, policyId, enrollmentId) => {
    const response = await AuthClient.get(
      `LifeInsuranceEnrollments/${enrollmentId}?policyId=${policyId}`,
      { cancelToken },
    );
    return response.data.payload || {};
  },
  updateLifeInsuranceEnrollment: async (cancelToken, enrollment) => {
    const response = await AuthClient.post(
      "LifeInsuranceEnrollments",
      enrollment,
      { cancelToken },
    );
    return response.data.payload || {};
  },
  lifeInsuranceEnrollmentAction: async (
    cancelToken,
    enrollmentId,
    action,
    terminationReason,
  ) => {
    const response = await AuthClient.post(
      "LifeInsuranceEnrollments/Action",
      {
        enrollmentId,
        activityType: action,
        terminationReason,
      },
      { cancelToken },
    );
    return response.data.payload || {};
  },
};

export default LifeInsuranceApi;
