import React from "react";
import { Link } from "react-router";

import {
  /* OrganizationCategory, */ EmptyOrganizationCategory,
} from "../../../../models/Organization";
import OrganizationCategoryManager from "./OrganizationCategoryManager";

// interface OrganizationCategoryManagerProps {
//   organizationCategoryData: OrganizationCategory;
//   handleDataChange: React.FormEventHandler<HTMLElement>;
//   handleSave: (oc: OrganizationCategory) => void;
//   handleDelete: (oc: OrganizationCategory) => void;
// }

const OrganizationCategoryManagerPage = ({
  organizationCategoryData = EmptyOrganizationCategory(),
  handleDataChange,
  handleSave,
  handleDelete,
}) => {
  return (
    <div className="col-sm-12">
      <ol className="breadcrumb">
        <li>
          <Link to="/config">Configure</Link>
        </li>
        <li>
          <Link to="/config/orgcategories">Organization Categories</Link>
        </li>
        <li>{organizationCategoryData.name || "[new category]"}</li>
      </ol>
      <div className="well well-lg">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <OrganizationCategoryManager
              organizationCategoryData={organizationCategoryData}
              handleDataChange={handleDataChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-9" />
          <div className="col-sm-3">
            <span
              className="btn btn-primary btn-sm"
              onClick={(e) => {
                e.preventDefault();
                handleSave(organizationCategoryData);
              }}
            >
              Save
            </span>{" "}
            {organizationCategoryData.id && organizationCategoryData.id > 0 && (
              <span
                className="btn btn-primary btn-sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleDelete(organizationCategoryData);
                }}
              >
                Delete
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationCategoryManagerPage;
