import React from "react";

export default class StudentBackgroundInfo extends React.PureComponent {
  render() {
    const {
      student: {
        fatherBackgroundDisplay,
        hasTakenIsraelCourses,
        hebrewLevelDisplay,
        jewishEducations,
        hasNoJewishEducation,
        jewishIsraelKnowledgeLevelDisplay,
        motherBackgroundDisplay,
      },
    } = this.props;
    return (
      <div className="student-profile-background-info">
        <p className="fw-500 xxl-text mb-24">Background</p>
        <div className="divider mb-24"></div>
        <div>
          <p className="fw-900 medium-text mb-16">Family Background</p>
          <div className="flex flex-align-center mb-8">
            <label className="fw-700">Mother's Background</label>
            <p>{motherBackgroundDisplay}</p>
          </div>
          <div className="flex flex-align-center">
            <label className="fw-700">Father's Background</label>
            <p>{fatherBackgroundDisplay}</p>
          </div>
        </div>
        <div className="divider mt-24 mb-24"></div>
        <div>
          <p className="fw-900 medium-text mb-16">
            Jewish Educational Background
          </p>
          {hasNoJewishEducation ? (
            <div className="flex flex-align-center mb-8">
              <label className="fw-700">
                <p>None</p>
              </label>
            </div>
          ) : (
            jewishEducations &&
            jewishEducations.map((je, index) => (
              <div className="flex flex-align-center mb-8" key={index}>
                <label className="fw-700">{je.typeDisplay}</label>
                <p>
                  {je.name}{" "}
                  {je.affiliationDisplay && `(${je.affiliationDisplay})`}
                </p>
              </div>
            ))
          )}
          <div className="flex flex-align-center">
            <label className="fw-700">Hebrew</label>
            <p>{hebrewLevelDisplay}</p>
          </div>
        </div>
        <div className="divider mt-24 mb-24"></div>
        <div>
          <p className="fw-900 medium-text mb-16">
            Israel Educational Background
          </p>
          <div className="flex flex-align-center">
            <label className="fw-700">Has taken courses on Israel</label>
            <p>
              {hasTakenIsraelCourses === true || hasTakenIsraelCourses === false
                ? hasTakenIsraelCourses
                  ? "Yes"
                  : "No"
                : ""}
            </p>
          </div>
          <div className="flex flex-align-center">
            <label className="fw-700">
              Knows about Jewish Connection to Israel
            </label>
            <p>{jewishIsraelKnowledgeLevelDisplay}</p>
          </div>
        </div>
      </div>
    );
  }
}
