import React from "react";
import moment from "moment";
import { Link } from "react-router";

import { EmailLink, PhoneNumberLink } from "../../../lib/coc-common-components";

// import { Contact } from "../../../models/Contact";
import { MosadInvolvementLevels } from "../../../models/LookupTypes";
import DateRangePicker from "../../common/DateRangePicker";
import CsvGetAndDownload from "../../common/CSVGetAndDownload";
import DataTable from "../../common/DataTable";
import ContactFunctions, { ContactType } from "../../Contact/ContactUtils";
// import { DataTableColumn } from "../../common/DataTable";
// import { ColumnConfig } from "../../common/CSVGetAndDownload";
// import { CustomEventTypes } from "../Utils";

// export interface DetailedTemplateContactsReportData {
//   name: string;
//   type: CustomEventTypes;
//   contactType: ContactType;
//   contacts: Array<Contact>;
// }

// interface ReportingDetailedTemplateContactsProps {
//   baseUrl: string;
//   eventText: string;
//   startDate: moment.Moment;
//   endDate: moment.Moment;
//   reportData: DetailedTemplateContactsReportData;
//   linksToDirectory: boolean;
//   handleDateInputChange: (field: string) => void;
// }

const ReportingDetailedTemplateContacts = ({
  baseUrl,
  eventText,
  startDate = moment.utc().subtract(2, "years"),
  endDate = moment.utc(),
  reportData,
  linksToDirectory,
  handleDateInputChange,
}) => {
  const summaryReportUrl = baseUrl.substr(0, baseUrl.indexOf("/reporting"));

  const formattedReportData = Object.assign({}, reportData, {
    contacts: reportData.contacts.map((c) => ({
      ...c,
      displayableMethods: ContactFunctions.displayableContactMethods(
        c.contactMethods,
      ),
      school:
        reportData.contactType === ContactType.shluchim
          ? c.contactAsShliach &&
            c.contactAsShliach.campuses &&
            c.contactAsShliach.campuses.length > 0 &&
            c.contactAsShliach.campuses.sort((s1, s2) =>
              s1.involvementLevelId === MosadInvolvementLevels.FullTime
                ? -1
                : 1,
            )[0]
          : (c.studentCampuses && c.studentCampuses[0]) || { name: "" },
    })),
  });

  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-12">
          <ol className="breadcrumb">
            <li>
              <Link to={summaryReportUrl}>{eventText}</Link>
            </li>
            <li>
              <Link to={summaryReportUrl}>Reports</Link>
            </li>
            <li>Participants</li>
          </ol>
        </div>
        <div className="col-sm-10" style={{ marginBottom: "1em" }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            handleDateInputChange={handleDateInputChange}
          />
        </div>
        <div className="col-sm-2" style={{ marginBottom: "1em" }}>
          <CsvGetAndDownload
            dataToDownload={formattedReportData.contacts}
            columnDefinitions={columnsForCsv(reportData.contactType)}
            fileName={`${reportData.name}_Participants_${startDate.format(
              "YYYY-MM-DD",
            )}_${endDate.format("YYYY-MM-DD")}.csv`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <DataTable
            fullClientSide={true}
            totalPageCount={0}
            columns={columnsForTable(reportData.contactType, linksToDirectory)}
            reportData={formattedReportData.contacts}
          />
        </div>
      </div>
    </div>
  );
};
/**
 *
 * @param {ContactType} contactType
 * @param {boolean} linksToDirectory
 * @returns {Array<DataTableColumn>}
 */
function columnsForTable(contactType, linksToDirectory) {
  return [
    {
      Header: "Full Name",
      id: "name",
      accessor: (c) => c.lastName + " " + c.firstName,
      Cell: (c) =>
        linksToDirectory ? (
          <Link
            to={`/${
              contactType === ContactType.shluchim ? "shluchim" : "students"
            }/${c.original.id}`}
          >
            {c.original.firstName + " " + c.original.lastName}
          </Link>
        ) : (
          c.original.firstName + " " + c.original.lastName
        ),
    },
    {
      Header: "Email",
      id: "email",
      accessor: "displayableMethods.email",
      Cell: (c) => (
        <EmailLink emailAddress={c.original.displayableMethods.email} />
      ),
    },
    {
      Header: "Mobile Phone",
      id: "mobile",
      accessor: "displayableMethods.cellNumber",
      Cell: (c) => (
        <PhoneNumberLink
          phoneNumber={c.original.displayableMethods.cellNumber}
        />
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Phone",
      accessor: "displayableMethods.phoneNumber",
      Cell: (c) => (
        <PhoneNumberLink
          phoneNumber={c.original.displayableMethods.phoneNumber}
        />
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "School",
      accessor: "school.name",
      Cell: (c) =>
        c.original.school &&
        (linksToDirectory ? (
          <Link to={`/schools/${c.original.school.campusAid}`}>
            {c.original.school.name}
          </Link>
        ) : (
          c.original.school.name
        )),
    },
    {
      Header: "DOB",
      id: "dob",
      accessor: (c) => c.dob,
      maxWidth: 120,
      Cell: (c) => (
        <span>
          {c.original.dob && moment(c.original.dob).format("MM/DD/YYYY")}
        </span>
      ),
      style: { textAlign: "center" },
    },
    {
      Header: "Gender",
      accessor: "gender",
      maxWidth: 120,
    },
  ];
}
/** @param {ContactType} contactType
 * @returns {Array<ColumnConfig>}
 */
function columnsForCsv(contactType) {
  /** @type {Array<ColumnConfig>} */
  const columns = [
    {
      columnId: "firstName",
      header: "First Name",
    },
    {
      columnId: "lastName",
      header: "Last Name",
    },
    {
      columnId: "hebrewName",
      header: "Hebrew Name",
      visible: false,
    },
    {
      columnId: "gender",
      header: "Gender",
      visible: false,
    },
    {
      columnId: "dob",
      header: "Birthday",
      visible: false,
      transform: (dob) => (dob ? moment.utc(dob).format("YYYY-MM-DD") : ""),
    },
    {
      columnId: "primaryEmails",
      dataField: "contactMethods",
      header: "Primary Email",
      transform: (contactMethods) => {
        const emails = contactMethods.filter(
          (cm) =>
            cm.type === "EmailAddress" &&
            cm.primary &&
            cm.value.indexOf("proxymail.facebook.com") < 0,
        );
        if (emails.length > 0) {
          return emails[0].value;
        }
        return "";
      },
    },
    {
      columnId: "otherEmails",
      dataField: "contactMethods",
      header: "Other Emails",
      visible: false,
      transform: (contactMethods) =>
        [
          ...contactMethods.filter(
            (cm) =>
              cm.type === "EmailAddress" &&
              !cm.primary &&
              cm.value.indexOf("proxymail.facebook.com") < 0,
          ),
        ]
          .map((p) => p.value)
          .join(", ") || "",
    },
    {
      columnId: "mobilePhones",
      dataField: "contactMethods",
      header: "Mobile Phones",
      transform: (contactMethods) =>
        [...contactMethods.filter((cm) => cm.type === "CellPhoneNumber")]
          .map((p) => p.value)
          .join(", ") || "",
    },
    {
      columnId: "homePhones",
      dataField: "contactMethods",
      header: "Phones",
      transform: (contactMethods) =>
        [...contactMethods.filter((cm) => cm.type === "PhoneNumber")]
          .map((p) => p.value)
          .join(", ") || "",
    },
  ];
  if (contactType === ContactType.shluchim) {
    columns.push(
      {
        labelGroup: "Home Address",
        columnId: "homeAddress",
        header: "Home Address Line 1",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) => (shliach && shliach.homeAddressLine1) || "",
      },
      {
        labelGroup: "Home Address",
        columnId: "homeAddress",
        header: "Line 2",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) => (shliach && shliach.homeAddressLine2) || "",
      },
      {
        labelGroup: "Home Address",
        columnId: "homeAddress",
        header: "City",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) => (shliach && shliach.homeCity) || "",
      },
      {
        labelGroup: "Home Address",
        columnId: "homeAddress",
        header: "ZIP",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) => (shliach && shliach.homePostCode) || "",
      },
      {
        labelGroup: "Home Address",
        columnId: "homeAddress",
        header: "State",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) => (shliach && shliach.homeState) || "",
      },
      {
        labelGroup: "Home Address",
        columnId: "homeAddress",
        header: "Country",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) => (shliach && shliach.homeCountry) || "",
      },
      {
        columnId: "mosadName",
        dataField: "contactAsShliach",
        header: "Mosad Name",
        visible: false,
        transform: (shliach) => shliach && shliach.mosad && shliach.mosad.name,
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad Address Line 1",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) =>
          (shliach && shliach.mosad && shliach.mosad.address) || "",
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad Line 2",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) =>
          (shliach && shliach.mosad && shliach.mosad.address2) || "",
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad City",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) =>
          (shliach && shliach.mosad && shliach.mosad.city) || "",
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad ZIP",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) =>
          (shliach && shliach.mosad && shliach.mosad.zipCode) || "",
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad State",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) =>
          (shliach && shliach.mosad && shliach.mosad.state) || "",
      },
      {
        labelGroup: "Mosad Address",
        columnId: "mosadAddress",
        header: "Mosad Country",
        dataField: "contactAsShliach",
        visible: false,
        transform: (shliach) =>
          (shliach && shliach.mosad && shliach.mosad.country) || "",
      },
      {
        columnId: "school",
        dataField: "contactAsShliach",
        header: "Schools",
        transform: (shliach) =>
          (shliach &&
            shliach.campuses &&
            shliach.campuses.map((p) => p.name).join(", ")) ||
          "",
      },
    );
  } else {
    columns.push({
      columnId: "school",
      header: "School",
      transform: (school) => (school && school.name) || "",
    });
  }
  return columns;
}

export default ReportingDetailedTemplateContacts;
