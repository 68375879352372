import React from "react";

export default class CoursesTableHeader extends React.PureComponent {
  render() {
    const columns = ["Course Name", "Category", "Date Created", "Status"];

    return (
      <div className="courses-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
