import React from "react";
import Dropzone from "react-dropzone";
import Toggle from "../../../shared/Toggle";
import ImagePreviewModal from "../ImagePreviewModal";
import ProgramHelpModal from "../ProgramHelpModal";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import ContentApi from "../../../../services/resources/ContentApi";
import classNames from "classnames";

const { REACT_APP_TINYMCE_API_KEY } = process.env;

// import { getScrollParent } from "../../../../lib";

/**
 * This hack re-focuses on a different input after the editor is mounted since
 * disabling the TinyMCE editor auto focus functionality doesn't work.
 * See https://github.com/tinymce/tinymce-react/issues/41
 */
// function hackToDisableAutoFocusOnTinyMCE(focusElementId = "name") {
//   console.log("coming into hackToDisable");
//   let attempts = 0;
//   function tryResetFocus() {
//     // console.log("ATTEMPTING TO RESET FOCUS...");
//     const { activeElement } = document;
//     // ASSUMPTION: TinyMCE implements the only focusable IFRAME on the page.
//     if (activeElement && activeElement.tagName !== "IFRAME") {
//       if (++attempts < 15) {
//         // * 100ms = try for 1500ms
//         window.setTimeout(tryResetFocus, 100);
//         return;
//       }
//     }
//     // console.log("RESETTING FOCUS...");
//     // ASSUMPTION: There is only one element with id="name".
//     const focusElement = document.getElementById(focusElementId);
//     if (focusElement) {
//       focusElement.focus();
//       const boundingClientRect = focusElement.getBoundingClientRect();
//       console.log(focusElement, boundingClientRect);
//       // Next 2 lines are only needed on Firefox (so far), to scroll top.
//       const parentScroller = getScrollParent(focusElement);
//       if (parentScroller && parentScroller.scrollTo) {
//         parentScroller.scrollTo({
//           top: boundingClientRect.top,
//           left: boundingClientRect.left
//         });
//       }
//     }
//   }
//   window.setTimeout(tryResetFocus, 100);
// }

export default class ScheduleContent extends React.PureComponent {
  state = {
    imagePreview: {
      isOpen: false,
      url: "",
    },
    showHelpModal: false,
    selectedContentSectionId: null,
  };

  // componentDidMount() {
  //   hackToDisableAutoFocusOnTinyMCE();
  // }

  componentDidUpdate(prevProps) {
    const { schedule } = this.props;
    const prevScheduleId = (prevProps.schedule || {}).id;

    if (schedule && schedule.id !== prevScheduleId) {
      this.setState({
        selectedContentSectionId: null, // clear out selected section id to avoid focus
      });
      //TODO - confirm that OK to not focus on editor to avoid having to refocus and have page jump
      // if (schedule.contents && schedule.contents[0]) {
      //   this.setState(
      //     {
      //       selectedContentSectionId: schedule.contents[0].id
      //     },
      //     () => hackToDisableAutoFocusOnTinyMCE("schedules-menu")
      //   );
      // }
    }
  }

  // PROGRAM BANNER
  dropProgramBanner = async (acceptedFiles /* , rejectedFiles */) => {
    if (acceptedFiles.length) {
      const fileURL = await ContentApi.uploadFile(
        acceptedFiles[0],
        "program_banner",
      );
      this.props.actions.changeState("schedule.programImageURL", fileURL);
    }
  };

  hideImagePreview = () => {
    this.setState({
      imagePreview: {
        isOpen: false,
        url: "",
      },
    });
  };

  showImagePreview = (e) => {
    e.stopPropagation();

    const {
      schedule: { programImageURL: url },
    } = this.props;

    this.setState({
      imagePreview: {
        isOpen: true,
        url,
      },
    });
  };

  // CONTENTS
  changeScheduleContents = (contents) => {
    this.props.actions.changeState("schedule.contents", contents);
  };

  getContentSection = (sectionId = this.state.selectedContentSectionId) => {
    const {
      schedule: { contents = [] },
    } = this.props;
    return contents.find(({ id }) => id === sectionId);
  };

  getContentSectionIndex = (
    sectionId = this.state.selectedContentSectionId,
  ) => {
    const {
      schedule: { contents = [] },
    } = this.props;
    return contents.map((c) => c.id).indexOf(sectionId);
  };

  addContentSection = (e) => {
    e.preventDefault();

    const {
      schedule: { contents = [] },
    } = this.props;

    const sectionIndex = Object.keys(contents).length;
    const sectionId = Math.min(...contents.map((c) => c.id), 0) - 1;

    const updatedContents = [...contents];
    updatedContents[sectionIndex] = {
      id: sectionId,
      name: "New Section",
      content: "",
      sortOrder: sectionIndex,
    };

    this.changeScheduleContents(updatedContents);

    this.setState({ selectedContentSectionId: sectionId });
  };

  updateContentSection = (updatedSection, sectionIndex) => {
    const {
      schedule: { contents = [] },
    } = this.props;

    const contentSectionIndex = sectionIndex || this.getContentSectionIndex();

    if (contentSectionIndex >= 0) {
      const updatedContents = [...contents];
      updatedContents[contentSectionIndex] = updatedSection;

      this.changeScheduleContents(updatedContents);
    }
  };

  changeContentSectionContent = (content) => {
    const section = this.getContentSection();
    if (!section || section.content === content) {
      return;
    }

    this.updateContentSection({
      ...section,
      content,
    });
  };

  changeContentSectionName = (e) => {
    const { value: sectionName } = e.target;

    const section = this.getContentSection();
    if (!section || section.name === sectionName) {
      return;
    }

    this.updateContentSection({
      ...section,
      name: sectionName,
    });
  };

  changeContentSectionVisibility = (isHidden) => {
    const section = this.getContentSection();
    if (!section || section.isHidden === isHidden) {
      return;
    }

    this.updateContentSection({
      ...section,
      isHidden,
    });
  };

  onSortContactSections = ({ oldIndex, newIndex }) => {
    const {
      schedule: { contents = [] },
    } = this.props;
    const orderedSectionIds = arrayMove(
      contents.sort((c1, c2) => c1.sortOrder - c2.sortOrder).map((c) => c.id),
      oldIndex,
      newIndex,
    );

    const startIndex = Math.min(oldIndex, newIndex);
    const endIndex = Math.max(oldIndex, newIndex) + 1;
    for (let i = startIndex; i < endIndex; i++) {
      this.changeContentSectionSortOrder(orderedSectionIds[i], i);
    }
  };

  changeContentSectionSortOrder = (sectionId, sortOrder) => {
    const contentSectionIndex = this.getContentSectionIndex(sectionId);

    if (contentSectionIndex >= 0) {
      const {
        schedule: { contents = [] },
      } = this.props;

      const updatedContents = [...contents];
      updatedContents[contentSectionIndex].sortOrder = sortOrder;

      this.changeScheduleContents(updatedContents);
    }
  };

  selectContentSection = (sectionId) => {
    this.setState({ selectedContentSectionId: sectionId });
  };

  deleteContentSection = (sectionId) => {
    if (
      !window.confirm("Are you sure you want to delete this content section?")
    ) {
      return;
    }

    const {
      schedule: { contents = [] },
    } = this.props;
    const { selectedContentSectionId } = this.state;

    const updatedContents = [...contents].filter((c) => c.id !== sectionId);
    this.changeScheduleContents(updatedContents);

    if (sectionId === selectedContentSectionId) {
      const contentSectionIndex = this.getContentSectionIndex(sectionId);
      this.setState({
        selectedContentSectionId: contents[contentSectionIndex + 1]
          ? contents[contentSectionIndex + 1].id
          : contents[contentSectionIndex - 1]
          ? contents[contentSectionIndex - 1].id
          : null,
      });
    }
  };

  hideHelp = () => {
    this.setState({
      showHelpModal: false,
    });
  };

  showHelp = (e) => {
    e.preventDefault();
    this.setState({
      showHelpModal: true,
    });
  };

  onEditorImageUpload = async (blobInfo, success, failure) => {
    const file = blobInfo.blob();
    const fileURL = await ContentApi.uploadFile(file, "program_content").catch(
      (err) => {
        failure(err);
        return undefined;
      },
    );
    if (fileURL) {
      success(fileURL);
    }
  };

  render() {
    const { imagePreview, selectedContentSectionId, showHelpModal } =
      this.state;

    const {
      actions,
      schedule: { contents = [], marketingMaterialURL, programImageURL },
    } = this.props;

    const {
      name: selectedSectionName = "",
      content = "",
      isHidden: selectedSectionHidden = false,
    } = this.getContentSection() || {};

    return (
      <React.Fragment>
        <p className="medium-text fw-700">Content</p>
        <div className="program-form-inputs mb-24">
          <ProgramBannerImageInput
            onDropImage={this.dropProgramBanner}
            programImageURL={programImageURL}
            showImagePreview={this.showImagePreview}
          />
          <div className="flex flex-justify-space flex-align-center relative">
            <label className="form-label" htmlFor="marketing_link">
              Marketing Material Link (URL)
            </label>
            <input
              type="text"
              id="marketing_link"
              autoComplete="off"
              value={marketingMaterialURL || ""}
              data-change="schedule.marketingMaterialURL"
              onChange={actions.changeInput}
            />
          </div>
        </div>
        <ImagePreviewModal close={this.hideImagePreview} {...imagePreview} />
        <div className="flex">
          <div style={{ width: 300, backgroundColor: "#fafafa", padding: 16 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3 style={{ margin: 0 }}>Program Sections</h3>
                <div style={{ fontFamily: "Avenir Light", fontSize: 14 }}>
                  <small>
                    <em>Drag and drop to re-order</em>
                  </small>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-accent"
                  onClick={this.addContentSection}
                  style={{
                    height: 32,
                    textTransform: "uppercase",
                    fontSize: 14,
                    padding: "0 16px",
                    lineHeight: "32px",
                  }}
                >
                  Add
                </button>
              </div>
            </div>
            <hr style={{ margin: "4px -16px" }} />
            <ScheduleContentSections
              contents={contents}
              deleteContentSection={this.deleteContentSection}
              distance={10}
              onSortEnd={this.onSortContactSections}
              selectContentSection={this.selectContentSection}
              selectedContentSectionId={selectedContentSectionId}
            />
          </div>
          <div
            className="ml-16"
            style={{
              background: "#fafafa",
              padding: 16,
              width: "calc(100% - 300px)",
            }}
          >
            <div className="flex flex-align-center program-schedule-content-row">
              {!selectedContentSectionId ? (
                <p
                  style={{
                    fontSize: 16,
                    fontFamily: "Avenir Medium",
                    fontStyle: "italic",
                    marginBottom: 16,
                  }}
                >
                  Select a Content Section to begin editing
                </p>
              ) : (
                <input
                  onChange={this.changeContentSectionName}
                  style={{
                    background: "#FAFAFA",
                    border: "solid 2px #FAFAFA",
                    boxShadow: "none",
                    fontWeight: "bold",
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingTop: 8,
                  }}
                  type="text"
                  value={selectedSectionName}
                />
              )}
              {!!selectedContentSectionId && (
                <Toggle
                  id="content_visibility"
                  onChange={(name, value) =>
                    this.changeContentSectionVisibility(value)
                  }
                  options={[
                    {
                      value: false,
                      display: "Show",
                    },
                    {
                      value: true,
                      display: "Hide",
                    },
                  ]}
                  value={selectedSectionHidden}
                />
              )}
            </div>
            <div style={{ marginTop: -5 }}>
              <div className="rfloat">
                <a
                  href="."
                  title="Get help using this editor."
                  onClick={this.showHelp}
                >
                  <small>
                    <strong>Editor Help</strong>
                  </small>
                </a>
              </div>
              <small>
                {!!selectedContentSectionId && (
                  <em>
                    Click title above to edit. Enter detail content below
                    including paragraphs, links and images...
                  </em>
                )}
              </small>
            </div>
            <TinyMCE
              apiKey={REACT_APP_TINYMCE_API_KEY}
              disabled={!selectedContentSectionId}
              init={{
                // The auto_focus property doesn't actually work at this time.
                // See hackToDisableAutoFocusOnTinyMCE comments.
                auto_focus: false,
                height: 512,
                content_style: "a { color: #53B7E8; text-decoration: none }",
                plugins:
                  "autolink code colorpicker fullscreen " +
                  "image media link lists paste " +
                  "preview table textcolor",
                menubar: "view edit insert format tools table",
                // See https://www.tiny.cloud/docs/advanced/editor-control-identifiers/
                toolbar:
                  "undo redo | " +
                  "styleselect | " +
                  "bold italic | " +
                  "fontsizeselect | " +
                  "alignleft aligncenter alignright alignjustify | " +
                  "bullist numlist outdent indent | " +
                  "link image | " +
                  "forecolor backcolor",
                images_upload_handler: this.onEditorImageUpload,
                plugin_preview_height: 650, // default: 500,
                plugin_preview_width: 845, // default: 650,
                style_formats: [
                  { title: "Subtitle", block: "h3" },
                  {
                    title: "Button",
                    inline: "span",
                    styles: {
                      "background-color": "#53B7E8",
                      "border-radius": "5px",
                      color: "white",
                      display: "inline-block",
                      padding: "12px 8px",
                    },
                  },
                ],
                extended_valid_elements:
                  "script[language|type|src],iframe[src|style|width|height|scrolling|marginwidth|marginheight|frameborder]",
                invalid_elements: "",
              }}
              value={content}
              onEditorChange={this.changeContentSectionContent}
            />
          </div>
        </div>

        <ProgramHelpModal close={this.hideHelp} isOpen={showHelpModal} />
      </React.Fragment>
    );
  }
}

const ScheduleContentSections = SortableContainer(function ({
  contents,
  deleteContentSection,
  selectContentSection,
  selectedContentSectionId,
}) {
  return (
    <div>
      {contents
        .sort((c1, c2) => c1.sortOrder - c2.sortOrder)
        .map((contentSection, index) => (
          <ContentSection
            contentSection={contentSection}
            isSelected={contentSection.id === selectedContentSectionId}
            index={index}
            key={contentSection.id}
            onDelete={deleteContentSection}
            onSelect={selectContentSection}
          />
        ))}
    </div>
  );
});

const ContentSection = SortableElement(function ({
  isSelected,
  contentSection,
  onDelete,
  onSelect,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: isSelected ? "#EDF8FD" : "white",
        border: isSelected ? "1px solid #66AFE9" : "1px solid white",
        borderRadius: 6,
        cursor: "pointer",
        padding: 8,
        margin: "8px 0",
        userSelect: "none",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onSelect(contentSection.id);
      }}
    >
      {contentSection.name || ""}
      <span
        style={{ padding: 3 }}
        onClick={(e) => {
          e.stopPropagation();
          if (onDelete) {
            onDelete(contentSection.id);
          }
        }}
      >
        <i className="icon icon-trash">
          <span className="path1" />
          <span className="path2" />
        </i>
      </span>
    </div>
  );
});

const ProgramBannerImageInput = ({
  onDropImage,
  programImageURL,
  showImagePreview,
}) => {
  const hasImage = !!programImageURL;
  return (
    <Dropzone
      title="Drag an image here or click to find one on your computer."
      accept={"image/png,image/jpeg,image/bmp,image/jpg"}
      onDrop={onDropImage}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={classNames("dropzone", {
            "no-image": !hasImage,
          })}
        >
          <input {...getInputProps()} />
          <div
            className="upload-thumbnail"
            title="Click to see larger image."
            onClick={showImagePreview}
            style={{
              backgroundImage: `${
                programImageURL ? `url('${programImageURL}')` : null
              }`,
            }}
          />

          <span style={{ display: "flex", alignItems: "center" }}>
            {hasImage ? "Change " : "Upload "}
            Program Header Image
          </span>
        </div>
      )}
    </Dropzone>
  );
};
