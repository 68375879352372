import React from "react";

export default class LocationsTableHeader extends React.PureComponent {
  render() {
    const columns = [
      "Shliach",
      "Shlucha",
      "Chabad House",
      "Campus",
      "Last Schedule Created",
      "",
    ];

    return (
      <div className="courses-locations-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
