import React from "react";
import ConfirmationModal from "../../shared/ConfirmationModal";
import PortalRedirectModal from "./PortalRedirectModal";

export default class PortalRedirectsTable extends React.PureComponent {
  state = {
    currentRedirectIndex: null,
    showDeleteRedirectConfirmationModal: false,
    showRedirectDetailsModal: false,
  };

  onAddRedirect = () =>
    this.setState({
      currentRedirectIndex: null,
      showRedirectDetailsModal: true,
    });

  onDeleteRedirect = (redirectIndex) =>
    this.setState({
      currentRedirectIndex: redirectIndex,
      showDeleteRedirectConfirmationModal: true,
    });

  onUpdateRedirect = (redirectIndex) =>
    this.setState({
      currentRedirectIndex: redirectIndex,
      showRedirectDetailsModal: true,
    });

  addRedirect = (newRedirect) => {
    this.updatePortalRedirects([...this.props.portalRedirects, newRedirect]);
  };

  deleteRedirect = () => {
    const updatedRedirects = [...this.props.portalRedirects];
    updatedRedirects.splice(this.state.currentRedirectIndex, 1);
    this.updatePortalRedirects(updatedRedirects);
  };

  updateRedirect = (updatedRedirect) => {
    const updatedRedirects = [...this.props.portalRedirects];
    updatedRedirects[this.state.currentRedirectIndex] = updatedRedirect;
    this.updatePortalRedirects(updatedRedirects);
  };

  updatePortalRedirects = (portalRedirects) => {
    this.props.updatePortalRedirects(portalRedirects);

    this.setState({
      currentRedirectIndex: null,
      showDeleteRedirectConfirmationModal: false,
      showRedirectDetailsModal: false,
    });
  };

  render() {
    const { portalDomains, portalRedirects } = this.props;
    const {
      currentRedirectIndex,
      showDeleteRedirectConfirmationModal,
      showRedirectDetailsModal,
    } = this.state;

    return (
      <div className="">
        <div className="redirects-grid">
          <div className="redirects-grid-header">
            <p>Domain</p>
            <p>Path From</p>
            <p>Path To</p>
            <p className="text-right">
              <i
                className="material-icons link-text mr-8"
                onClick={this.onAddRedirect}
              >
                add_circle
              </i>
            </p>
          </div>
          {!!portalRedirects.length ? (
            portalRedirects.map((redirect, index) => (
              <div className="redirects-grid-row" key={index}>
                <p>{redirect.domain}</p>
                <p>{redirect.pathFrom}</p>
                <p>{redirect.pathTo}</p>
                <p className="text-right">
                  <i
                    className="material-icons large-text link-text-secondary"
                    onClick={() => this.onUpdateRedirect(index)}
                  >
                    edit
                  </i>
                  <i
                    className="material-icons large-text ml-8 link-text-secondary"
                    onClick={() => this.onDeleteRedirect(index)}
                  >
                    delete
                  </i>
                </p>
              </div>
            ))
          ) : (
            <p className="no-redirects">No Portal Redirects Configured</p>
          )}
        </div>

        <PortalRedirectModal
          close={() => this.setState({ showRedirectDetailsModal: false })}
          portalDomains={portalDomains}
          portalRedirect={portalRedirects[currentRedirectIndex]}
          save={
            currentRedirectIndex === null
              ? this.addRedirect
              : this.updateRedirect
          }
          show={showRedirectDetailsModal}
        />

        <ConfirmationModal
          cancel={() =>
            this.setState({ showDeleteRedirectConfirmationModal: false })
          }
          confirm={this.deleteRedirect}
          message="Are you sure you want to delete this Portal Redirect?"
          show={showDeleteRedirectConfirmationModal}
        />
      </div>
    );
  }
}
