import React from "react";

export default class ParticipantsTableHeader extends React.PureComponent {
  render() {
    const columns = [
      "Chabad House",
      "Primary Shliach",
      "Primary Shlucha",
      "Campus",
      "Region",
      "",
    ];

    return (
      <div className="event-participants-table-header">
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
