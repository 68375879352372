import React from "react";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import PaginatedTable from "../../../../shared/PaginatedTable";
import RegistrationsShluchimTableActions from "./RegistrationsShluchimTableActions";
import RegistrationsTableFilters from "./RegistrationsTableFilters";

import ProgramsApi from "../../../../../services/resources/ProgramsApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import { formatFullName, formatNumber } from "../../../../../lib";
import axios from "axios";
import moment from "moment";
import queryString from "query-string";

export default class RegistrationsShluchimTable extends React.PureComponent {
  constructor(props) {
    super(props);

    const { pg, res, search, sortBy } = props.location.query;

    this.state = {
      errorMessage: "",
      filters: {
        keyword: search || "",
      },
      loading: false,
      page: pg || 1,
      results: res || 12,
      shluchim: [],
      sortBy: sortBy || this.sortOptions[0].value,
      success: true,
      totalShluchim: 0,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterShluchim = (filters) => {
    this.setState({ filters }, () => {
      this.getShluchim();
    });
  };

  getShluchim = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy
  ) => {
    //update page, sortBy, and search query
    const {
      location: {
        query: {
          pg: prevPageQuery,
          res: prevResultsQuery,
          sortBy: prevSortByQuery,
          search: prevSearchQuery,
          ...query
        },
        pathname,
      },
    } = this.props;
    const pageQuery = page > 1 ? page : undefined;
    const resultsQuery = results?.toString() !== "12" ? results : undefined;
    const sortByQuery = sortBy !== "name" ? sortBy : undefined;
    const searchQuery = this.state.filters.keyword || undefined;
    if (
      pageQuery !== prevPageQuery ||
      resultsQuery !== prevResultsQuery ||
      sortByQuery !== prevSortByQuery ||
      searchQuery !== prevSearchQuery
    ) {
      const queryUpdate = {
        ...query,
        pg: pageQuery,
        res: resultsQuery,
        search: searchQuery,
        sortBy: sortByQuery,
      };
      browserHistory.replace(
        `${pathname}?${queryString.stringify(queryUpdate)}`
      );
    }

    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        const {
          programSchedule: { programID, scheduleID },
        } = this.props;
        ProgramsApi.getScheduleRegistrationsShluchim(
          this.apiSignal.token,
          programID,
          scheduleID,
          page,
          results,
          sortBy,
          this.state.filters
        )
          .then(({ shluchim, totalShluchim }) => {
            this.setState({
              loading: false,
              shluchim,
              totalShluchim,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                shluchim: [],
                success: false,
                totalShluchim: 0,
              });
            }
          });
      }
    );
  };

  getShluchimForExport = async () => {
    const { sortBy, filters } = this.state;
    const {
      programSchedule: { programID, scheduleID },
    } = this.props;

    const shluchimForExport =
      await ProgramsApi.getScheduleRegistrationsShluchim(
        this.apiSignal.token,
        programID,
        scheduleID,
        undefined,
        undefined,
        sortBy,
        filters,
        true
      );

    return shluchimForExport.shluchim;
  };

  sortOptions = [
    { id: "name", name: "Name" },
    { id: "date", name: "Newest" },
  ];

  render() {
    const { mobileMode, programSchedule, title } = this.props;
    const { includesAllShluchim, registrationType } = programSchedule;
    const {
      filters,
      loading,
      shluchim,
      page,
      results,
      sortBy,
      success,
      totalShluchim,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">{title}</p>
          <RegistrationsShluchimTableActions
            getShluchimForExport={this.getShluchimForExport}
            programSchedule={programSchedule}
          />
        </div>
        <div className="program-shluchim-table">
          <PaginatedTable
            filterComponent={
              <RegistrationsTableFilters
                applyFilters={this.filterShluchim}
                filters={filters}
                showEnrolledFilter={
                  includesAllShluchim && registrationType === "Shliach"
                }
              />
            }
            loading={loading}
            loadData={this.getShluchim}
            mobileMode={mobileMode}
            page={page}
            records={shluchim}
            renderHeader={() => (
              <div className="program-registrations-shluchim-table-header">
                {[
                  "Shliach",
                  "Shlucha",
                  "Chabad House",
                  "Campus",
                  "Student Registrations",
                  "Latest Registration",
                ].map((column, index) => (
                  <p className="flex flex-align-center" key={index}>
                    {column}
                  </p>
                ))}
              </div>
            )}
            renderRow={(s, index) => (
              <div
                className="program-registrations-shluchim-table-row"
                key={index}
              >
                {s.shliachID ? (
                  <Link
                    className="link-text-secondary"
                    to={`/shluchim/${s.shliachID}/details`}
                  >
                    {formatFullName(s.shliachFirstName, s.shliachLastName)}
                  </Link>
                ) : (
                  <div />
                )}
                {s.shluchaID ? (
                  <Link
                    className="link-text-secondary"
                    to={`/shluchim/${s.shluchaID}/details`}
                  >
                    {formatFullName(s.shluchaFirstName, s.shluchaLastName)}
                  </Link>
                ) : (
                  <div />
                )}
                <Link
                  className="link-text-secondary"
                  to={`/shluchim/${s.shliachID || s.shluchaID}/chabadHouse/${
                    s.chabadHouseID
                  }`}
                >
                  {s.chabadHouseName}
                </Link>
                <Link
                  className="link-text-secondary"
                  to={`/schools/${s.campusID}`}
                >
                  {s.campusName}
                </Link>
                <p>{formatNumber(s.numberStudentRegistrations)}</p>
                <p>
                  {moment(s.latestStudentRegistrationDate).format("MM/DD/YYYY")}
                </p>
              </div>
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            sortOptions={this.sortOptions}
            success={success}
            totalCount={totalShluchim}
          />
        </div>
      </div>
    );
  }
}
