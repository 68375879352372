import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import { flattenArray } from "../../../../lib";
import moment from "moment";

export default class ShluchimExportButton extends React.PureComponent {
  getPaymentViewFields = (paymentSchedules) => {
    var paymentFields = flattenArray(
      paymentSchedules.map((ps, index) => [
        {
          label: `Payment ${index + 1} Requested Date`,
          value: () => moment(ps.paymentRequestedDate).format("MM/DD/YYYY"),
        },
        {
          label: `Payment ${index + 1} Processed Date`,
          value: () =>
            ps.paymentProcessingDate
              ? moment(ps.paymentProcessingDate).format("MM/DD/YYYY")
              : "",
        },
        {
          label: `Payment ${index + 1} Amount`,
          value: ({ payments }) =>
            (
              (payments &&
                payments.find(
                  (p) => p.grantSchedulePaymentScheduleID === ps.id,
                )) ||
              ""
            ).amount,
        },
      ]),
    );
    paymentFields.push({
      label: "Total Allocated",
      value: "amountAllocated",
    });
    return paymentFields;
  };

  getGrantViewFields = (grants) => {
    const progressMetricFields = [
      {
        label: "Total Active Students",
        value: "totalActiveStudents",
      },
      {
        label: "Active Data Percentage",
        value: "activeDataPercentage",
      },
      {
        label: "Submitted Grants",
        value: "submittedQuantity",
      },
      {
        label: "Submitted Grants Percentage",
        value: "submittedPercentage",
      },
    ];

    const getGrantAllocation = (allocations, grantId) =>
      (allocations && allocations.find((a) => a.grantID === grantId)) || "";

    const grantFields = flattenArray(
      grants.map((grant) => {
        const grantLabel = `${grant.type}${
          grant.description ? ` - ${grant.description}` : ""
        }`;

        const grantAllocationFields = [
          {
            label: `${grantLabel} Created On`,
            value: ({ allocations }) => {
              const createdOn = getGrantAllocation(
                allocations,
                grant.id,
              ).createdOn;
              return createdOn ? moment(createdOn).format("MM/DD/YYYY") : "";
            },
          },
          {
            label: `${grantLabel} Created By`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).createdBy,
          },
          {
            label: `${grantLabel} Updated On`,
            value: ({ allocations }) => {
              const updatedOn = getGrantAllocation(
                allocations,
                grant.id,
              ).updatedOn;
              return updatedOn ? moment(updatedOn).format("MM/DD/YYYY") : "";
            },
          },
          {
            label: `${grantLabel} Updated By`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).updatedBy,
          },
          {
            label: `${grantLabel} Allocated`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).quantity || 0,
          },
          {
            label: `${grantLabel} Active`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).activeQuantity || 0,
          },
          {
            label: `${grantLabel} Active & Flagged`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).activeFlaggedQuantity ||
              0,
          },
          {
            label: `${grantLabel} Submitted`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).submittedQuantity || 0,
          },
          {
            label: `${grantLabel} Summitted & Flagged`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id)
                .submittedFlaggedQuantity || 0,
          },
          {
            label: `${grantLabel} Available`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id).availableQuantity || 0,
          },
        ];

        if (grant.type === "Learning") {
          grantAllocationFields.push({
            label: `${grantLabel} With Immersion Submitted`,
            value: ({ allocations }) =>
              getGrantAllocation(allocations, grant.id)
                .submittedWithImmersionQuantity || 0,
          });
        }

        return grantAllocationFields;
      }),
    );

    return [...progressMetricFields, ...grantFields];
  };

  render() {
    const {
      getShluchimForExport,
      grants,
      programScheduleName,
      paymentSchedules,
      view,
    } = this.props;

    return (
      <ExportCSVButton
        className="btn custom-btn btn-accent flex flex-align-center"
        fileName={`${(programScheduleName || "Lamplighters").replace(
          / /g,
          "_",
        )}_Shluchim_${view === "payments" ? "Payments" : "Grants"}`}
        getExportData={getShluchimForExport}
        headers={[
          { label: "Chabad House ID", value: "chabadHouseID" },
          { label: "Chabad House", value: "chabadHouseName" },
          { label: "Chabad House Phone", value: "chabadHousePhone" },
          { label: "Chabad House Address 1", value: "chabadHouseAddress1" },
          { label: "Chabad House Address 2", value: "chabadHouseAddress2" },
          { label: "Chabad House City", value: "chabadHouseCity" },
          { label: "Chabad House State", value: "chabadHouseState" },
          { label: "Chabad House Zip", value: "chabadHouseZip" },
          { label: "Chabad House Country", value: "chabadHouseCountry" },
          { label: "Campus ID", value: "campusID" },
          { label: "Campus", value: "campusName" },
          { label: "Shliach ID", value: "shliachID" },
          { label: "Shliach Last Name", value: "shliachLastName" },
          { label: "Shliach First Name", value: "shliachFirstName" },
          { label: "Shlucha ID", value: "shluchaID" },
          { label: "Shlucha First Name", value: "shluchaFirstName" },
          { label: "Shliach Email", value: "shliachEmail" },
          { label: "Shliach Cell", value: "shliachCell" },
          { label: "Shlucha Email", value: "shluchaEmail" },
          { label: "Shlucha Cell", value: "shluchaCell" },
          ...(view === "payments"
            ? this.getPaymentViewFields(paymentSchedules)
            : this.getGrantViewFields(grants)),
        ]}
      />
    );
  }
}
