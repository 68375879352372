import React from "react";
// import { Prompt } from "react-router";
import { formatValuesForForm } from "../../../../lib";
import AuthClient from "../../../../services/resources/AuthClient";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";

import { Formik, Form } from "formik";

import Loader from "../Loader";

import GeneralInfo from "./GeneralInfo";
import StudiesInfo from "./StudiesInfo";
import PopulationInfo from "./PopulationInfo";
import JewishStudentLifeInfo from "./JewishStudentLifeInfo";

export default class School extends React.PureComponent {
  state = {
    submitted: undefined,
    campus: {},
    loading: undefined,
    submitError: {},
    saving: "",
  };

  componentDidMount() {
    const { campusID } = this.props.routeParams;
    if (campusID) this.getProfileCampus(this.props.routeParams.campusID);
    this._ismounted = true;
    this.props.router.setRouteLeaveHook(this.props.route, this.onNavigate);
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentDidUpdate(prevProps) {
    const { campusID } = this.props.routeParams;
    let reload =
      prevProps.routeParams.campusID !== this.props.routeParams.campusID;
    if (reload) {
      this.getProfileCampus(campusID);
    }
  }

  getProfileCampus = async (id) => {
    this.setState({ loading: true });
    const { data } = await AuthClient.get(`Campuses/${id}`).catch((err) => {
      return {};
    });
    if (this._ismounted) {
      this.setState({
        campus: data,
        loading: false,
      });
    }
  };

  /** Router hook. See https://github.com/ReactTraining/react-router/blob/v3/docs/guides/ConfirmingNavigation.md */
  onNavigate = (/* nextLocation */) => {
    const form = document.getElementsByClassName("school-form")[0];
    if (form && !form.classList.contains("form-dirty")) {
      return;
    } else {
      return "Unsaved changes will be lost. Are you sure you want to leave?";
    }
  };

  validate = (values) => {
    const errors = {};
    //validate
    return errors;
  };

  require = (value) => (!value && value !== false ? true : "");

  onSubmit = async (values, { resetForm }) => {
    this.setState({ saving: true });
    let { programLevels, ...campusValues } = values;
    //the program levels field handles both objects and strings...
    //TODO update program levels to handle just strings
    programLevels = programLevels.map((p) => p.value || p);
    campusValues.programLevels = programLevels;

    const { data, error } = await AuthClient.post(
      `Campuses`,
      campusValues
    ).catch((error) => {
      setTimeout(() => {
        this.setState({ submitted: undefined });
      }, 3000);
      return { error };
    });
    if (error) {
      this.setState({
        sumbmitted: false,
        submitError: error.response,
      });
      return;
    }

    this.setState({
      campus: data.payload,
      loading: false,
      submitted: true,
    });
    setTimeout(() => {
      this.setState({
        submitted: undefined,
      });
    }, 3000);

    setTimeout(() => {
      let updatedValues = formatValuesForForm({ ...this.state.campus });
      resetForm(updatedValues);
      this.props.updateMenuLists(
        this.props.routeParams.shliachID || this.props.shliachID,
        true
      );
      this.setState({ saving: false });
    }, 1500);
  };

  render() {
    const { sys } = this.props;
    const { loading, campus, submitted, submitError, saving } = this.state;
    let setNew = !this.props.routeParams.campusID;
    let newCampus = {
      name: "",
      nickname: "",
      programLevels: [],
      annualTuition: "",
      studentResidencyType: "Unknown",
      isInstitutionControlPublic: "",
      schoolCalendarType: "Unknown",
      studyAreas: [],
      graduatePopulation: "",
      undergradPopulation: "",
      jewishGraduatePopulation: "",
      jewishUndergradPopulation: "",
    };
    let readOnly = false;
    if (!AuthService.UserHasClaim(PermissionClaims.CampusEdit)) {
      readOnly = true;
    }
    return loading ? (
      <div className="card profile-form-card mr-24">
        <div>
          <Loader />
        </div>
      </div>
    ) : (
      <Formik
        initialValues={
          setNew
            ? formatValuesForForm(newCampus)
            : formatValuesForForm({ ...campus })
        }
        validateForm={this.validateForm}
        onSubmit={this.onSubmit}
      >
        {({ values, dirty, errors, touched, handleChange, setFieldValue }) => {
          return (
            <Form
              style={{ display: "flex", width: "100%" }}
              className={`school-form ${dirty ? "form-dirty" : ""} ${
                readOnly ? "read-only" : ""
              }`}
            >
              <div className="card profile-form-card mr-24">
                <div className="profile-form-sections">
                  <div className="profile-form-section">
                    <p className="accent-text small-text">
                      Review and edit school information. All information aside
                      from School recognition of Chabad will be displayed on the
                      public directory @ chabadoncampus.org/directory
                    </p>
                  </div>

                  <GeneralInfo
                    errors={errors}
                    handleChange={handleChange}
                    require={this.require}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    readOnly={readOnly}
                  />

                  <StudiesInfo
                    errors={errors}
                    require={this.require}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    sys={sys}
                    readOnly={readOnly}
                  />

                  <PopulationInfo
                    errors={errors}
                    require={this.require}
                    touched={touched}
                    values={values}
                    sys={sys}
                    readOnly={readOnly}
                  />

                  <JewishStudentLifeInfo
                    handleChange={handleChange}
                    sys={sys}
                    readOnly={readOnly}
                    values={values}
                  />
                </div>
              </div>

              {readOnly ? null : (
                <div style={{ position: "relative" }}>
                  <div className="profile-save-btn-container">
                    <button
                      className="btn btn-accent profile-save-btn"
                      type="submit"
                      disabled={!dirty ? true : false}
                      onClick={() => this.props.checkForErrors(errors)}
                    >
                      {saving ? "Saving..." : "Save"}
                    </button>
                    {submitted === false ? (
                      <p className="error-message">
                        {submitError &&
                        submitError.data &&
                        submitError.status.toString()[0] === "4"
                          ? Object.values(submitError.data).map((e, index) => {
                              return <span key={index}>{e.toString()}</span>;
                            })
                          : "Something went wrong. Please try again"}
                      </p>
                    ) : submitted && !dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">check</i> All changes
                        saved
                      </p>
                    ) : dirty ? (
                      <p className="accent-text-dark">
                        <i className="material-icons">save</i>
                        Unsaved changes
                      </p>
                    ) : null}
                    {this.props.errorMessage &&
                    Object.keys(errors).length > 0 ? (
                      <p className="error-message">
                        Please review all required fields
                      </p>
                    ) : null}
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    );
  }
}
