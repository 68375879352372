import React from "react";
import moment from "moment";

export default function StudentCoursesTableRow(props) {
  const {
    course: {
      courseScheduleName,
      didCompleteSurvey,
      firstClassDate,
      numOfClasses,
      numOfClassesAttended,
      studentCourseScheduleEnrollmentStatus,
      surveyDeadline,
    },
    studentEnrollmentStatus,
    removeCourseEnrollment,
    viewOnly,
  } = props;

  return (
    <div className="students-courses-history-table-row">
      <p>{courseScheduleName}</p>
      <p>{firstClassDate ? moment(firstClassDate).format("MM/DD/YYYY") : ""}</p>
      <p>{`${Math.max(numOfClassesAttended, 0)}/${numOfClasses}`}</p>
      <p>
        {studentEnrollmentStatus === "Rejected"
          ? "N/A"
          : studentCourseScheduleEnrollmentStatus}
      </p>
      <p>{didCompleteSurvey ? "Yes" : "No"}</p>
      <p
        className={
          !didCompleteSurvey && moment().isAfter(surveyDeadline)
            ? "error-text"
            : ""
        }
      >
        {surveyDeadline ? moment(surveyDeadline).format("MM/DD/YYYY") : ""}
      </p>
      <p>
        {!viewOnly && (
          <i
            className="link-text-secondary material-icons medium-text"
            onClick={removeCourseEnrollment}
            style={{ padding: "8px" }}
          >
            delete
          </i>
        )}
      </p>
    </div>
  );
}
