import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../shared/ConfirmationModal";
import TerminateEnrollmentModal from "./TerminateEnrollmentModal";
import { notify } from "react-notify-toast";

export default class EnrollmentActions extends React.PureComponent {
  state = {
    action: null,
    errorMessage: "",
    loading: false,

    showConfirmationModal: false,
    showTerminationModal: false,
  };

  actionTypes = {
    Activation: "Activation",
    Cancellation: "Cancellation",
    TerminationRequestCancellation: "TerminationRequestCancellation",
    Termination: "Termination",
  };

  getActionButtons = () => {
    const { enrollment, enrollmentStatuses } = this.props;

    switch (enrollment.status) {
      case enrollmentStatuses.Active:
        return (
          <button
            className="btn custom-btn btn-accent"
            onClick={() => this.onActionClick(this.actionTypes.Termination)}
          >
            Terminate
          </button>
        );
      case enrollmentStatuses.PendingActivation:
        return (
          <React.Fragment>
            <button
              className="btn btn-light"
              onClick={() => this.onActionClick(this.actionTypes.Cancellation)}
            >
              Cancel Enrollment
            </button>
            <button
              className="btn custom-btn btn-accent ml-16"
              onClick={() => this.onActionClick(this.actionTypes.Activation)}
            >
              Activate
            </button>
          </React.Fragment>
        );
      case enrollmentStatuses.PendingTermination:
        return (
          <React.Fragment>
            <button
              className="btn custom-btn btn-light"
              onClick={() =>
                this.onActionClick(
                  this.actionTypes.TerminationRequestCancellation,
                )
              }
            >
              Cancel Termination Request
            </button>
            <button
              className="btn custom-btn btn-accent ml-16"
              onClick={() => this.onActionClick(this.actionTypes.Termination)}
            >
              Terminate
            </button>
          </React.Fragment>
        );
      case enrollmentStatuses.FailedRenewal:
        return (
          <button
            className="btn custom-btn btn-accent"
            onClick={() => this.onActionClick(this.actionTypes.Termination)}
          >
            Terminate
          </button>
        );
      default:
        return null;
    }
  };

  onActionClick = (action) => {
    this.setState({
      action,
      [action === this.actionTypes.Termination
        ? "showTerminationModal"
        : "showConfirmationModal"]: true,
    });
  };

  onEnrollmentAction = async (terminationReason) => {
    this.setState({
      errorMessage: "",
      loading: true,
    });

    const { enrollment } = this.props;
    const { action } = this.state;

    const { errorMessage } = await this.props.enrollmentAction(
      enrollment.id,
      action,
      terminationReason,
    );

    this.setState(
      {
        errorMessage,
        loading: false,
      },
      () => {
        if (!errorMessage) {
          if (action === this.actionTypes.Cancellation) {
            browserHistory.push("/life-insurance");
            notify.show(
              "Life Insurance Enrollment has been cancelled",
              "success",
            );
          } else {
            this.setState({
              action: null,
              showConfirmationModal: false,
              showTerminationModal: false,
            });
          }
        }
      },
    );
  };

  render() {
    const {
      action,
      errorMessage,
      loading,
      showConfirmationModal,
      showTerminationModal,
    } = this.state;

    return (
      <React.Fragment>
        <div className="flex flex-align-center flex-justify-end mt-16">
          {this.getActionButtons()}
        </div>

        <ConfirmationModal
          cancel={() =>
            this.setState({
              action: null,
              errorMessage: "",
              showConfirmationModal: false,
            })
          }
          confirm={() => this.onEnrollmentAction()}
          cancelText="No"
          confirmText="Yes"
          errorMessage={errorMessage}
          loading={loading}
          message={
            action === this.actionTypes.Cancellation &&
            "If you choose yes, please make sure to fully refund the enrollee and cancel his/her subsription in stripe."
          }
          show={showConfirmationModal}
          title={`Are you sure you want to ${
            action === this.actionTypes.Activation
              ? "activate"
              : action === this.actionTypes.Cancellation
              ? "cancel"
              : action === this.actionTypes.Termination
              ? "terminate"
              : action === this.actionTypes.TerminationRequestCancellation
              ? "cancel the termination request for"
              : "update"
          } this enrollment?`}
        />

        <TerminateEnrollmentModal
          cancel={() =>
            this.setState({
              action: null,
              errorMessage: "",
              showTerminationModal: false,
            })
          }
          confirm={(reason) => this.onEnrollmentAction(reason)}
          errorMessage={errorMessage}
          loading={loading}
          show={showTerminationModal}
        />
      </React.Fragment>
    );
  }
}
