import React from "react";
import SelectSearch from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import debounce from "lodash.debounce";
import { removeTimezoneFormatFromDate } from "../../../lib";

export default class RewardRequestsTableFilters extends React.PureComponent {
  state = {
    campus: null,
    keyword: "",
    requestType: null,
    shliach: null,
    statuses: [],
    requestedDateFrom: null,
    requestedDateTo: null,
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter
    );
  };

  onFilter = () => {
    const {
      campus,
      keyword,
      requestedDateFrom,
      requestedDateTo,
      requestType,
      shliach,
      statuses,
    } = this.state;

    this.props.applyFilters({
      campusId: campus ? campus.value : "",
      keyword,
      shliachId: shliach ? shliach.value : "",
      statuses: statuses.map((s) => s.value),
      requestedDateFrom,
      requestedDateTo,
      requestType,
    });
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const {
      applySort,
      eduChabadHouses: { eduChabadHousesList, eduChabadHousesListLoading },
      eduRewardRequestStatuses,
      eduRewardTypes,
      sortBy,
      sortOptions,
    } = this.props;

    const {
      campus,
      keyword,
      requestedDateFrom,
      requestedDateTo,
      requestType,
      shliach,
      statuses,
    } = this.state;

    return (
      <div className="full-width courses-requests-table-filters">
        <div className="flex">
          <div className="search-input mr-16">
            <input
              type="text"
              placeholder="Search"
              name="keyword"
              onChange={(event) =>
                this.onChange(event.target.name, event.target.value, true)
              }
              value={keyword}
            />
            <i className="material-icons accent-text-secondary flex flex-align-center">
              search
            </i>
          </div>
          <div className="mr-16">
            <SelectSearch
              onChange={(val) => this.onChange("shliach", val)}
              options={
                eduChabadHousesList &&
                eduChabadHousesList.shluchim &&
                eduChabadHousesList.shluchim.map((s) => ({
                  value: s.shliachID,
                  label: s.fullName,
                }))
              }
              placeholder={
                eduChabadHousesListLoading
                  ? "Loading All Shluchim..."
                  : "All Shluchim"
              }
              style={{ height: "36px" }}
              value={shliach}
            />
          </div>
          <div className="mr-16">
            <SelectSearch
              onChange={(val) => this.onChange("campus", val)}
              options={
                eduChabadHousesList &&
                eduChabadHousesList.campuses &&
                eduChabadHousesList.campuses.map((c) => ({
                  value: c.campusID,
                  label: c.campusName,
                }))
              }
              placeholder={
                eduChabadHousesListLoading
                  ? "Loading All Campuses..."
                  : "All Campuses"
              }
              style={{ height: "36px" }}
              value={campus}
            />
          </div>
          <div>
            <SelectSearch
              onChange={(val) => this.onChange("requestType", val?.value)}
              options={
                eduRewardTypes &&
                eduRewardTypes.map((t) => ({
                  value: t.enumValue,
                  label: t.enumValue,
                }))
              }
              placeholder="All Request Types"
              style={{ height: "36px" }}
              value={requestType}
            />
          </div>
        </div>
        <div className="flex flex-align-center flex-justify-space mt-16">
          <div className="flex flex-align-center">
            <p className="mr-8">Filter Request Date</p>
            <DatePicker
              className="custom-input"
              isClearable={true}
              onChange={(date) =>
                this.onChange(
                  "requestedDateFrom",
                  removeTimezoneFormatFromDate(date)
                )
              }
              placeholderText="Date From"
              selected={requestedDateFrom && moment(requestedDateFrom)}
            />
            <DatePicker
              className="custom-input ml-8"
              isClearable={true}
              onChange={(date) =>
                this.onChange(
                  "requestedDateTo",
                  removeTimezoneFormatFromDate(date)
                )
              }
              placeholderText="Date To"
              selected={requestedDateTo && moment(requestedDateTo)}
            />
          </div>
          <div>
            <div className="flex flex-align-center">
              <p>Filter by</p>
              <SelectSearch
                className="filter-by-select"
                multi={true}
                onChange={(val) => this.onChange("statuses", val)}
                options={
                  eduRewardRequestStatuses &&
                  eduRewardRequestStatuses.map((status) => ({
                    value: status.enumValue,
                    label: status.displayValue,
                  }))
                }
                placeholder="All Statuses"
                searchable={false}
                value={statuses}
              />
            </div>
            <div className="flex flex-align-center">
              <p>Sort by</p>
              <select
                className="no-border-select accent-text-secondary ml-8"
                onChange={(event) => applySort(event.target.value)}
                name="sortBy"
                value={sortBy}
              >
                {sortOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
