import React from "react";

const TermsAndConditionsTable = (props) => {
  const {
    onAddTerms,
    onDeleteTerms,
    onUpdateTerms,
    termsAndConditions,
    termTypes,
    title,
  } = props;

  return (
    <div className="program-settings-page-section">
      <div className="flex mb-16">
        <p className="medium-text fw-700">{title}</p>
        <button
          className="custom-btn btn btn-accent btn-small uppercase-text fw-400 ml-16"
          onClick={onAddTerms}
        >
          Add
        </button>
      </div>
      {title === "Chaperones" &&
        <div className="accent-text small-text flex flex-align-center mb-16">
          These terms will be displayed when adding a chaperone to Pegisha.
        </div>
      }
      <div className="terms-grid">
        <div className="terms-grid-header">
          <p>Type</p>
          <p>Title</p>
          <p>Text</p>
          <p />
        </div>
        {!!termsAndConditions.length ? (
          termsAndConditions.map(({ termsIndex, terms }) => (
            <div className="terms-grid-row" key={termsIndex}>
              <p>
                {terms.type === termTypes.long ? "Long Text" : "Short Text"}
              </p>
              <p>{terms.title}</p>
              <p style={{ textOverflow: "ellipsis" }}>{terms.text}</p>
              <p className="text-right">
                <i
                  className="material-icons large-text link-text-secondary"
                  onClick={() => onUpdateTerms(termsIndex)}
                >
                  edit
                </i>
                <i
                  className="material-icons large-text ml-8 link-text-secondary"
                  onClick={() => onDeleteTerms(termsIndex)}
                >
                  delete
                </i>
              </p>
            </div>
          ))
        ) : (
          <span>No terms and conditions</span>
        )}
      </div>
    </div>
  );
};

export default TermsAndConditionsTable;
