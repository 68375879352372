import React from "react";
import Select from "../../../shared/CustomSelect";
import moment from "moment";

export default class CurrentJewishInvolvementDemographics extends React.PureComponent {
  render() {
    const {
      demographics: {
        currentKosherStatus = "",
        currentShabbosHolidayStatus = "",
        currentSynagogueAttendanceStatus = "",
        currentTefillinStatus = "",
        currentTorahStudyStatus = "",
        jewishAffiliation = "",
        jewishInterestLevel = "",
        jewishObservanceStatusUpdatedOn = "",
        jewishTrajectoryUpdatedOn = "",
      },
      jewishFamilyAffiliationTypes,
      observanceStatuses,
      onChange,
      participationStatuses,
      shabbosHolidayObservanceStatuses,
      studentGender,
    } = this.props;

    return (
      <React.Fragment>
        <div className="student-profile-form-section">
          <div className="profile-section-title">
            Current Jewish Involvement
            {!!jewishObservanceStatusUpdatedOn && (
              <p className="small-text accent-text line-height-double mt-8">
                Last updated on{" "}
                {moment(jewishObservanceStatusUpdatedOn).format("MM/D/YYYY")}
              </p>
            )}
          </div>
          <div className="student-profile-form-grid">
            <div className="input-container">
              <label>Shabbos and Holiday Status</label>
              <Select
                classNamePrefix="input-container-select"
                name="currentShabbosHolidayStatus"
                onChange={onChange}
                options={
                  shabbosHolidayObservanceStatuses &&
                  shabbosHolidayObservanceStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={currentShabbosHolidayStatus}
              />
            </div>
            <div className="input-container">
              <label>Kosher Status</label>
              <Select
                classNamePrefix="input-container-select"
                name="currentKosherStatus"
                onChange={onChange}
                options={
                  observanceStatuses &&
                  observanceStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={currentKosherStatus}
              />
            </div>
            <div className="input-container">
              <label>Torah Study Status</label>
              <Select
                classNamePrefix="input-container-select"
                name="currentTorahStudyStatus"
                onChange={onChange}
                options={
                  participationStatuses &&
                  participationStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={currentTorahStudyStatus}
              />
            </div>
            <div className="input-container">
              <label>Synagogue Attendance Status</label>
              <Select
                classNamePrefix="input-container-select"
                name="currentSynagogueAttendanceStatus"
                onChange={onChange}
                options={
                  participationStatuses &&
                  participationStatuses.map((s) => ({
                    label: s.displayValue,
                    value: s.enumValue,
                  }))
                }
                placeholder="Select Status"
                searchable={true}
                value={currentSynagogueAttendanceStatus}
              />
            </div>
            {studentGender !== "Female" && (
              <div className="input-container">
                <label>Tefillin Status</label>
                <Select
                  classNamePrefix="input-container-select"
                  name="currentTefillinStatus"
                  onChange={onChange}
                  options={
                    participationStatuses &&
                    participationStatuses.map((s) => ({
                      label: s.displayValue,
                      value: s.enumValue,
                    }))
                  }
                  placeholder="Select Status"
                  searchable={true}
                  value={currentTefillinStatus}
                />
              </div>
            )}
          </div>
        </div>
        <div className="student-profile-form-section">
          <div className="profile-section-title">
            Student Trajectory
            {!!jewishTrajectoryUpdatedOn && (
              <p className="small-text accent-text line-height-double mt-8">
                Current Jewish affiliation last updated on{" "}
                {moment(jewishTrajectoryUpdatedOn).format("MM/D/YYYY")}
              </p>
            )}
          </div>
          <div className="student-profile-form-grid">
            <div
              className="input-container"
              style={{ backgroundColor: "#F8F7F7" }}
            >
              <label>Jewish Interest Level</label>
              <input
                disabled
                value={jewishInterestLevel || "New"}
                style={{ backgroundColor: "#F8F7F7" }}
              />
            </div>
            <div className="input-container">
              <label>Current Jewish Affiliation</label>
              <Select
                classNamePrefix="input-container-select"
                name="jewishAffiliation"
                onChange={onChange}
                options={
                  jewishFamilyAffiliationTypes &&
                  jewishFamilyAffiliationTypes.map((t) => ({
                    label: t.displayValue,
                    value: t.enumValue,
                  }))
                }
                placeholder="Select Affiliation"
                searchable={true}
                value={jewishAffiliation}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
