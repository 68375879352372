import React from "react";
import DatePicker from "react-datepicker";
import {
  formatAmount,
  formatNumber,
  getCurrencySymbol,
} from "../../../../../lib/utils";
import Toggle from "../../../../shared/Toggle";
import { calculateRefundBreakdown } from "../../../RaffleLogic";
import moment from "moment";
import { RafflePaymentTypes } from "../../../RaffleConsts";

export default class RaffleOrderInfo extends React.PureComponent {
  render() {
    const {
      amount,
      currencyCode,
      processingFeeDonorAmount,
      quantity,
      ticketPriceAmount,
      editMode,
      order: { orderDate, paymentType, isPrivate },
      onChangeOrder,
      startDate,
      endDate,
      updateOrderValidationErrors,
      orderValidationErrors,
      refundAmount,
      singleTicketPrice,
      cociCcProcessingFeePercentage,
    } = this.props;

    const { refundTicketPriceAmount, refundProcessingFeeDonorAmount } =
      calculateRefundBreakdown(
        refundAmount,
        cociCcProcessingFeePercentage,
        singleTicketPrice,
      );

    return (
      <div>
        <p className="medium-text">Order Info</p>
        {!editMode ? (
          <>
            <div className="flex flex-align-center mb-8 mr-24 relative">
              <p className="accent-text small-text fw-700">Order Date</p>
              <p className="small-text">
                {moment(orderDate).format("MM/DD/YYYY")}
              </p>
            </div>
            <div className="flex flex-align-center mb-8 relative">
              <p className="accent-text small-text fw-700">Qty Tickets</p>
              <p className="small-text">{formatNumber(quantity)}</p>
            </div>
            <div className="flex">
              <div className="amounts-wrapper">
                <p className="total-text fw-700" style={{ color: "inherit" }}>
                  Total
                </p>
                {paymentType === RafflePaymentTypes.CreditCard &&
                  amount > 0 && (
                    <>
                      <p className="total-text accent-text ml-16">
                        Ticket Total
                      </p>
                      <p className="total-text accent-text ml-16">CC Fee</p>
                    </>
                  )}
              </div>
              <div className="amounts-values-wrapper">
                <div className="amounts-values fw-700">
                  <p className="small-text">
                    {getCurrencySymbol(currencyCode)}
                  </p>
                  <p className="small-text">
                    {formatAmount(amount, currencyCode)}
                  </p>
                </div>
                {paymentType === RafflePaymentTypes.CreditCard &&
                  amount > 0 && (
                    <>
                      <div className="amounts-values">
                        <p className="small-text">
                          {getCurrencySymbol(currencyCode)}
                        </p>
                        <p className="small-text">
                          {formatAmount(ticketPriceAmount, currencyCode)}
                        </p>
                      </div>
                      <div className="amounts-values">
                        <p className="small-text">
                          {getCurrencySymbol(currencyCode)}
                        </p>
                        <p className="small-text">
                          {formatAmount(processingFeeDonorAmount, currencyCode)}
                        </p>
                      </div>
                    </>
                  )}
              </div>
            </div>

            {!!refundAmount && (
              <div className="flex">
                <div className="amounts-wrapper">
                  {!amount ? (
                    <p className="total-text error-text fw-700">Refunded</p>
                  ) : (
                    <p className="total-text accent-text fw-700">
                      Partially Refunded
                    </p>
                  )}
                  {paymentType === RafflePaymentTypes.CreditCard && (
                    <>
                      <p className="total-text accent-text ml-16">
                        Ticket Refund
                      </p>
                      <p className="total-text accent-text ml-16">
                        CC Fee Refund
                      </p>
                    </>
                  )}
                </div>
                <div className="amounts-values-wrapper">
                  <div className="amounts-values fw-700">
                    <p className="small-text refunded">
                      {getCurrencySymbol(currencyCode)}
                    </p>
                    <p className="small-text refunded">
                      {formatAmount(refundAmount, currencyCode)}
                    </p>
                  </div>
                  {paymentType === RafflePaymentTypes.CreditCard && (
                    <>
                      <div className="amounts-values">
                        <p className="small-text refunded">
                          {getCurrencySymbol(currencyCode)}
                        </p>
                        <p className="small-text refunded">
                          {formatAmount(refundTicketPriceAmount, currencyCode)}
                        </p>
                      </div>
                      <div className="amounts-values">
                        <p className="small-text refunded">
                          {getCurrencySymbol(currencyCode)}
                        </p>
                        <p className="small-text refunded">
                          {formatAmount(
                            refundProcessingFeeDonorAmount,
                            currencyCode,
                          )}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="flex flex-align-center mb-12 mr-24 relative">
              <label className="accent-text small-text required">
                Order Date
              </label>
              <DatePicker
                id="orderDate"
                name="orderDate"
                className={`input-size custom-input 
                ${
                  orderValidationErrors.indexOf("orderDate") >= 0 ? "error" : ""
                }`}
                minDate={new Date(startDate)}
                maxDate={new Date(endDate)}
                selected={orderDate ? moment(orderDate) : null}
                onChange={(value) => {
                  const date = value ? new Date(value) : null;
                  onChangeOrder("orderDate", value);
                  const isValidDate =
                    !!date &&
                    date >= new Date(startDate) &&
                    date <= new Date(endDate);
                  updateOrderValidationErrors("orderDate", isValidDate);
                }}
              />
            </div>
            <div className="flex flex-justify-space flex-align-center mr-8 relative mb-12">
              <div className="flex flex-align-center relative">
                <label className="accent-text small-text">Donation is</label>
                <Toggle
                  className="active-toggle mr-16"
                  name="isPrivate"
                  options={[
                    {
                      value: true,
                      display: "Private",
                    },
                    {
                      value: false,
                      display: "Public",
                    },
                  ]}
                  onChange={onChangeOrder}
                  value={isPrivate}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
