import React from "react";

import { FieldArray } from "formik";
import CustomSelect from "../../Form/CustomSelect";
import FormInput from "../../Form/FormInput";
import FormInputHebrew from "../../Form/FormInputHebrew";
import { isNotValidDOB } from "../../../../../lib";

export default class FamilyInfo extends React.PureComponent {
  state = {
    familyInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        familyInfoShowing: false,
      });
    }
  }

  render() {
    const { errors, require, setFieldValue, touched, values, sys, readOnly } =
      this.props;
    return (
      <div
        className={
          this.state.familyInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center"
          onClick={() => {
            this.setState({
              familyInfoShowing: !this.state.familyInfoShowing,
            });
          }}
        >
          Family Information
        </p>
        <div className="profile-form-family-info">
          <FieldArray name="family.children">
            {(arrayHelpers) => (
              <React.Fragment>
                <p className="mt-24 mb-16 accent-text fw-700 flex flex-align-center">
                  Children
                  {readOnly ? null : (
                    <i
                      className="material-icons link-text profile-add-icon ml-16"
                      onClick={() =>
                        arrayHelpers.unshift({
                          person: {
                            firstName: "",
                            lastName: values.person.lastName,
                            hebrewName: "",
                            dob: "",
                            beforeNightfall: "",
                            gender: "",
                          },
                          schoolType: "Unknown",
                          tShirtSize: "Unknown",
                        })
                      }
                    >
                      add_circle
                    </i>
                  )}
                </p>
                {values.family &&
                  values.family.children &&
                  values.family.children.map((child, index) => (
                    <div
                      key={index}
                      className="flex flex-justify-space flex-align-center child-row-container hover-delete-container"
                    >
                      <div className="child-row">
                        <div>
                          <FormInput
                            label="First Name"
                            type="text"
                            name={`family.children[${index}].person.firstName`}
                            validate={require}
                            className="form-required"
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Gender"
                            name={`family.children[${index}].person.gender`}
                            validate={require}
                            errors={errors}
                            touched={touched}
                            className="form-required"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                options={[
                                  {
                                    key: "1",
                                    value: "Male",
                                    label: "Male",
                                  },
                                  {
                                    key: "2",
                                    value: "Female",
                                    label: "Female",
                                  },
                                ]}
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                                readOnly={readOnly}
                              />
                            )}
                          </FormInput>
                        </div>
                        <div className="relative">
                          <FormInputHebrew
                            label="Hebrew Name"
                            type="text"
                            validate={require}
                            className="form-required"
                            name={`family.children[${index}].person.hebrewName`}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        <div className="relative">
                          <FormInput
                            label="Birthday"
                            type="date"
                            name={`family.children[${index}].person.dob`}
                            validate={(date) => isNotValidDOB(date)}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                          />
                        </div>
                        <div>
                          <FormInput
                            label="Nightfall/Sunset"
                            name={`family.children[${index}].person.beforeNightfall`}
                            errors={errors}
                            touched={touched}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                readOnly={readOnly}
                                options={[
                                  {
                                    key: "1",
                                    value: true,
                                    label: "Before",
                                  },
                                  {
                                    key: "2",
                                    value: false,
                                    label: "After",
                                  },
                                ]}
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>

                        <div className="relative mt-4">
                          <FormInput
                            label={
                              <div
                                className="accent-text small-text flex flex-align-center"
                                style={{ width: 120 }}
                              >
                                <label>Hebrew Birthday</label>
                                <span className="tooltip-container ml-8 mb-4">
                                  <i
                                    className="material-icons link-text no-hover profile-add-icon"
                                    style={{ fontSize: 14 }}
                                  >
                                    info
                                  </i>
                                  <span className="tooltip">
                                    Hebrew Birthday is calculated based on
                                    Birthday and Nightfall/Sunset. It will
                                    update on save.
                                  </span>
                                </span>
                              </div>
                            }
                            type="text"
                            readOnly={true}
                            name={`family.children[${index}].person.hebrewDOB`}
                            className="custom-input mt-4"
                          />
                        </div>

                        <div>
                          <FormInput
                            label="School Type"
                            name={`family.children[${index}].schoolType`}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                readOnly={readOnly}
                                options={
                                  sys.schoolTypes &&
                                  sys.schoolTypes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>
                        <div>
                          <FormInput
                            label="T-shirt Size"
                            name={`family.children[${index}].tShirtSize`}
                            errors={errors}
                            touched={touched}
                            readOnly={readOnly}
                            className="custom-input mr-12"
                            style={{ flex: "2" }}
                          >
                            {({ field }) => (
                              <CustomSelect
                                {...field}
                                errors={errors}
                                readOnly={readOnly}
                                options={
                                  sys.youthAndAdultTShirtSizes &&
                                  sys.youthAndAdultTShirtSizes.map((t) => ({
                                    key: t.enumValue,
                                    value: t.enumValue,
                                    label: t.displayValue,
                                  }))
                                }
                                placeholder="Select..."
                                setFieldValue={setFieldValue}
                                touched={touched}
                              />
                            )}
                          </FormInput>
                        </div>
                      </div>
                      {readOnly ? null : (
                        <i
                          className="material-icons link-text ml-16 mb-16 hover-delete"
                          style={{ fontSize: "16px" }}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          delete
                        </i>
                      )}
                    </div>
                  ))}
              </React.Fragment>
            )}
          </FieldArray>
        </div>
      </div>
    );
  }
}
