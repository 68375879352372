import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import NotEnrolledTableFilters from "./NotEnrolledTableFilters";
import NotEnrolledTableHeader from "./NotEnrolledTableHeader";
import NotEnrolledTableRecord from "./NotEnrolledTableRecord";
import ExportCSVButton from "../../shared/ExportCSVButton";

import LifeInsuranceApi from "../../../services/resources/LifeInsuranceApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class LifeInsuranceNotEnrolledTable extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    results: 12,
    shluchim: [],
    sortBy: "shliachLastName",
    success: true,
    totalShluchim: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterShluchim = (filters) => {
    this.setState(
      {
        filters,
      },
      this.getNotEnrolled,
    );
  };

  getNotEnrolled = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        LifeInsuranceApi.getLifeInsuranceEnrollments(
          this.apiSignal.token,
          this.props.policyId,
          page,
          results,
          sortBy,
          this.state.filters,
          false, //enrolled flag
        )
          .then(
            ({ enrollments: shluchim, totalEnrollments: totalShluchim }) => {
              this.setState({
                loading: false,
                shluchim,
                totalShluchim,
              });
            },
          )
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                shluchim: [],
                success: false,
                totalShluchim: 0,
              });
            }
          });
      },
    );
  };

  getNotEnrolledForExport = async () => {
    const { sortBy, filters } = this.state;

    const notEnrolledForExport =
      await LifeInsuranceApi.getLifeInsuranceEnrollments(
        this.apiSignal.token,
        this.props.policyId,
        undefined,
        undefined,
        sortBy,
        filters,
        false,
        true,
      );

    return notEnrolledForExport.enrollments;
  };

  render() {
    const { mobileMode } = this.props;

    const { loading, page, results, shluchim, sortBy, success, totalShluchim } =
      this.state;

    return (
      <React.Fragment>
        <div className="flex flex-justify-space mb-24">
          <p className="xxl-text fw-500">All Shluchim</p>
          <ExportCSVButton
            className="custom-btn btn btn-accent uppercase-text"
            fileName="Life_Insurance_Not_Enrolled"
            getExportData={this.getNotEnrolledForExport}
            headers={[
              { label: "Last Name", value: "shliachLastName" },
              { label: "Shliach", value: "shliachFirstName" },
              { label: "Shlucha", value: "shluchaFirstName" },
              { label: "Shliach Email", value: "shliachEmail" },
              { label: "Shliach Phone", value: "shliachCell" },
              { label: "Shlucha Email", value: "shluchaEmail" },
              { label: "Shlucha Phone", value: "shluchaCell" },
              { label: "Shliach Category", value: "shliachCategory" },
              { label: "Shlucha Category", value: "shluchaCategory" },
            ]}
          />
        </div>

        <div className="life-insurance-enrollment-table">
          <PaginatedTable
            filterComponent={
              <NotEnrolledTableFilters applyFilters={this.filterShluchim} />
            }
            loading={loading}
            loadData={this.getNotEnrolled}
            mobileMode={mobileMode}
            page={page}
            records={shluchim}
            renderHeader={() => <NotEnrolledTableHeader />}
            renderRow={(notEnrolled) => (
              <NotEnrolledTableRecord
                key={notEnrolled.shliachID || notEnrolled.shluchaID}
                notEnrolled={notEnrolled}
              />
            )}
            results={results}
            sortBy={sortBy}
            success={success}
            totalCount={totalShluchim}
          />
        </div>
      </React.Fragment>
    );
  }
}
