import React from "react";
import { browserHistory, Link } from "react-router";
import EnrollmentOrders from "./RaffleEnrollment/RaffleEnrollmentOrders";
import EnrollmentMetrics from "./RaffleEnrollment/RaffleEnrollmentMetrics";
import Loader from "../common/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import { Unauthorized } from "../../lib/coc-common-components";

import RafflesApi from "../../services/resources/RafflesApi";
import StudentsApi from "../../services/resources/StudentsApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import axios from "axios";
import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";
import { formatFullName } from "../../lib";
import queryString from "query-string";

export default class RaffleEnrollmentPage extends React.PureComponent {
  state = {
    authorized: true,

    enrollment: null,

    errorMessage: "",
    loading: true,

    metrics: {},
    metricsErrorMessage: "",
    metricsLoading: false,

    raffle: null,

    salesOverTime: [],
    salesOverTimeErrorMessage: "",
    salesOverTimeLoading: false,
    salesOverTimeTimeFrame: "last7Days",

    salesOverTimeComparison: [],
    salesOverTimeComparisonErrorMessage: "",
    salesOverTimeComparisonLoading: false,
    salesOverTimeComparisonIncludeAllEvents: false,

    studentsList: {
      errorMessage: "",
      list: [],
      loading: false,
    },

    systemLists: {
      countries: [],
      yearOverYearChartIntervals: [],
      raffleDonorAssociations: [],
    },

    tabIndex: null,
    tabs: [
      { name: "Orders", id: "orders", component: EnrollmentOrders },
      { name: "Metrics", id: "metrics", component: EnrollmentMetrics },
    ],
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount = () => {
    if (
      !AuthService.UserHasClaim(PermissionClaims.RaffleDataView) &&
      !AuthService.UserHasClaim(PermissionClaims.RaffleChabadHouseView)
    ) {
      this.setState({ authorized: false });
    }

    const {
      location: {
        query: { tab },
      },
    } = this.props;
    const currentTabIndex = this.state.tabs
      .map((tab) => tab.id)
      .indexOf(decodeURIComponent(tab));
    this.toTab(currentTabIndex >= 0 ? currentTabIndex : 0);

    this.getRaffleAndEnrollmentDetails(() => {
      this.getEnrollmentMetrics();
      this.getAllStudents();
    });

    this.getSystemLists();
  };

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getRaffleAndEnrollmentDetails = async (onFetchDetails = null) => {
    try {
      const [raffle, enrollment] = await Promise.all([
        RafflesApi.getRaffle(
          this.apiSignal.token,
          this.props.params.scheduleId
        ),
        RafflesApi.getEnrollment(
          this.apiSignal.token,
          this.props.params.enrollmentId
        ),
      ]);

      this.setState(
        {
          enrollment,
          loading: false,
          raffle,
          ...(!raffle.isOpenForManualTicketEntry
            ? { salesOverTimeTimeFrame: "all" }
            : {}),
        },
        () => {
          if (onFetchDetails) {
            onFetchDetails();
          }
        }
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(err),
          loading: false,
        });
      }
    }
  };

  getEnrollmentMetrics = () => {
    this.setState(
      {
        metricsErrorMessage: "",
        metricsLoading: true,
      },
      () => {
        RafflesApi.getEnrollmentMetrics(
          this.apiSignal.token,
          this.props.params.enrollmentId
        )
          .then((metrics) => {
            this.setState({
              metricsLoading: false,
              metrics,
            });
            this.getEnrollmentSalesOverTime();
            this.getEnrollmentSalesOverTimeComparison();
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                metricsErrorMessage: ApiCallErrorMessageHandler(err),
                metricsLoading: false,
              });
            }
          });
      }
    );
  };

  getEnrollmentSalesOverTime = (
    timeFrame = this.state.salesOverTimeTimeFrame
  ) => {
    this.setState(
      {
        salesOverTimeErrorMessage: "",
        salesOverTimeLoading: true,
        salesOverTimeTimeFrame: timeFrame,
      },
      () => {
        RafflesApi.getEnrollmentSalesOverTime(
          this.apiSignal.token,
          this.props.params.enrollmentId,
          timeFrame,
          this.state.metrics.raffleEventID
        )
          .then((salesOverTime) => {
            this.setState({
              salesOverTimeLoading: false,
              salesOverTime,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                salesOverTimeErrorMessage: ApiCallErrorMessageHandler(err),
                salesOverTimeLoading: false,
              });
            }
          });
      }
    );
  };

  getEnrollmentSalesOverTimeComparison = (
    includeAllEvents = this.state.salesOverTimeComparisonIncludeAllEvents
  ) => {
    this.setState(
      {
        salesOverTimeComparisonErrorMessage: "",
        salesOverTimeComparisonLoading: true,
        salesOverTimeComparisonIncludeAllEvents: includeAllEvents,
      },
      () => {
        RafflesApi.getEnrollmentSalesOverTimeComparison(
          this.apiSignal.token,
          this.props.params.enrollmentId,
          includeAllEvents,
          this.state.metrics.raffleEventID
        )
          .then((salesOverTimeComparison) => {
            this.setState({
              salesOverTimeComparisonLoading: false,
              salesOverTimeComparison,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                salesOverTimeComparisonErrorMessage:
                  ApiCallErrorMessageHandler(err),
                salesOverTimeComparisonLoading: false,
              });
            }
          });
      }
    );
  };

  getAllStudents = async () => {
    const { enrollment: { chabadHouseID } = {} } = this.state;

    //don't load students for COCI seller which has no chabad house ID
    if (!chabadHouseID) {
      return;
    }

    this.setState({
      studentsList: {
        errorMessage: "",
        list: [],
        loading: true,
      },
    });

    const newStudentsListState = {
      loading: false,
    };

    try {
      const students = await StudentsApi.getStudentsByChabadHouse(
        this.apiSignal.token,
        chabadHouseID
      );
      if (!students || !students.length) {
        newStudentsListState.errorMessage = "No students found";
      } else {
        newStudentsListState.list = students;
      }
    } catch (err) {
      newStudentsListState.errorMessage = ApiCallErrorMessageHandler(
        err,
        "Something went wrong and available students could not be retrieved. Please try again."
      );
    }

    this.setState({ studentsList: newStudentsListState });
  };

  getSystemLists = async () => {
    const systemLists = await SystemApi.lists([
      "countries",
      "yearOverYearChartIntervals",
      "raffleDonorAssociations",
      "raffleTicketOptions",
    ]);
    this.setState({ systemLists });
  };

  toTab = (index) => {
    const { tabIndex, tabs } = this.state;
    const {
      location: { pathname, query },
    } = this.props;

    if (tabIndex !== index) {
      this.setState({ tabIndex: index });
      browserHistory.replace(
        `${pathname}?${queryString.stringify({
          ...(tabIndex === null ? query : {}), // maintain query params only when setting the initial tabIndex
          tab: encodeURIComponent(tabs[index].id),
        })}`
      );
    }
  };

  render() {
    const {
      mobileMode,
      params: { scheduleId },
      router,
    } = this.props;
    const {
      authorized,
      enrollment,
      errorMessage,
      loading,
      metrics,
      metricsErrorMessage,
      metricsLoading,
      raffle,
      salesOverTime,
      salesOverTimeErrorMessage,
      salesOverTimeLoading,
      salesOverTimeTimeFrame,
      salesOverTimeComparison,
      salesOverTimeComparisonErrorMessage,
      salesOverTimeComparisonLoading,
      salesOverTimeComparisonIncludeAllEvents,
      systemLists,
      tabIndex,
      tabs,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const readOnlyAccess =
      !AuthService.UserHasClaim(PermissionClaims.RaffleDataEdit) &&
      !AuthService.UserHasClaim(PermissionClaims.RaffleChabadHouseEdit);

    return (
      <div className="page raffles-page">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !enrollment ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              Sorry, something went wrong and we could not retrieve Grand Draw
              enrollment details. Please try again.
            </p>
          </div>
        ) : (
          <React.Fragment>
            <div className="raffles-page-subheader raffles-page-shliach-subheader">
              <div className="container flex flex-justify-space flex-align-center">
                <div className="go-back">
                  <Link to={`/grand-draw/${scheduleId}?view=shluchim`}>
                    <i
                      className="material-icons link-text"
                      style={{ verticalAlign: "middle" }}
                    >
                      arrow_back
                    </i>
                  </Link>
                </div>
                <ul className="flex">
                  {tabs.map((tab, index) => (
                    <li
                      key={index}
                      className={tabIndex === index ? "active" : ""}
                      onClick={() => this.toTab(index)}
                    >
                      {tab.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="container">
              {!!raffle && (
                <ProgramBreadcrumbsHeader
                  getPrevPages={(sched) => [
                    {
                      path: `/grand-draw/${raffle.programScheduleID}?view=shluchim`,
                      title: sched.scheduleName,
                    },
                  ]}
                  pageTitle="Shliach Enrollment"
                  scheduleId={raffle.programScheduleID}
                />
              )}
              <div className="raffle-shliach-card">
                <div className="mb-24">
                  {enrollment.enrolledByShliachID && (
                    <div className="xl-text fw-700 flex flex-align-center">
                      {formatFullName(
                        enrollment.shliachFirstName,
                        enrollment.shliachLastName
                      )}
                      <Link to={`/shluchim/${enrollment.enrolledByShliachID}`}>
                        <i className="accent-text-secondary link-text material-icons large-text ml-16">
                          open_in_new
                        </i>
                      </Link>
                    </div>
                  )}
                  <p className="medium-text">{enrollment.chabadHouseName}</p>
                </div>
                {React.createElement(tabs[tabIndex].component, {
                  enrollment,
                  mobileMode,
                  raffle,
                  readOnlyAccess,

                  ...(tabs[tabIndex].id === "orders"
                    ? {
                        getEnrollmentMetricsAndSales: this.getEnrollmentMetrics,
                        location: router.location,
                        systemCountries: systemLists.countries,
                        systemRaffleDonorAssociations:
                          systemLists.raffleDonorAssociations,
                      }
                    : tabs[tabIndex].id === "metrics"
                    ? {
                        errorMessage: metricsErrorMessage,
                        loading: metricsLoading,
                        metrics,

                        getSalesOverTime: this.getEnrollmentSalesOverTime,
                        salesOverTime,
                        salesOverTimeErrorMessage,
                        salesOverTimeLoading,
                        salesOverTimeTimeFrame,

                        getSalesOverTimeComparison:
                          this.getEnrollmentSalesOverTimeComparison,
                        salesOverTimeComparison,
                        salesOverTimeComparisonErrorMessage,
                        salesOverTimeComparisonLoading,
                        salesOverTimeComparisonIncludeAllEvents,

                        systemRaffleDaysToEnd:
                          systemLists.yearOverYearChartIntervals,

                        toOrdersTab: () => this.toTab(0),
                      }
                    : {}),
                })}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
