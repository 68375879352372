import React from "react";
import Loader from "../common/Loader";
import ShliachDetailsCard from "./details/ShliachDetailsCard";
import ShliachActivityTables from "./activity/ShliachActivityTables";
import { Unauthorized } from "../../lib/coc-common-components";

import ShluchimApi from "../../services/resources/ShluchimApi";
import SystemApi from "../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import axios from "axios";

export default class StudentDetailsPage extends React.PureComponent {
  state = {
    authorized: true,
    errorMessage: "",
    loading: true,
    shliachDetails: null,
    systemLists: {},
    systemListsErrorMessage: "",
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ShliachRead)) {
      this.setState({ authorized: false });
    }

    this.getShliachDetails();
    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.shliachID !== prevProps.params.shliachID) {
      this.getShliachDetails();
    }
  }

  getShliachDetails = async () => {
    this.setState({ loading: true });

    try {
      const shliachDetails = await ShluchimApi.getShliach(
        this.apiSignal.token,
        this.props.params.shliachID,
      );

      this.setState({
        shliachDetails,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve Shliach details. Please try again.",
          ),
          shliachDetails: null,
        });
      }
    }

    this.setState({ loading: false });
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists([
        "studentActivityProgramTypes",
      ]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage:
            err?.response?.status === 404
              ? "The requested Shliach could not be found"
              : ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  render() {
    const {
      params: { shliachID },
    } = this.props;

    const { authorized, errorMessage, loading, shliachDetails, systemLists } =
      this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="shliach-details-page page container">
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !shliachDetails ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong and we could not retrieve Shliach details. Please try again."}
            </p>
          </div>
        ) : (
          <div>
            <ShliachDetailsCard shliachDetails={shliachDetails} />
            <ShliachActivityTables
              {...this.props}
              shliachId={shliachID}
              shliachDetails={shliachDetails}
              systemLists={systemLists}
            />
          </div>
        )}
      </div>
    );
  }
}
