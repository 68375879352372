// import { EventModel } from "../../models/EventDescriptor";
import AuthRequest from "./AuthRequest";

const baseUrl = "/api/events";

const EventsApi = {
  /** @param {EventModel} event */
  create: (event) => {
    return AuthRequest.post(`${baseUrl}`, {
      ...event,
      occurredAt: event.occurredAt.toDate(),
    });
  },
  /** @param {EventModel} event */
  update: (event) => {
    return AuthRequest.post(`${baseUrl}/${event.id}`, {
      ...event,
      occurredAt: event.occurredAt.toDate(),
    });
  },
  /** @param {number} id */
  delete: (id) => {
    return AuthRequest.delete(`${baseUrl}/${id}`);
  },
  /** @param {EventModel} event
   * @param {Array<number>} contactIds
   */
  createBulk: (event, contactIds) => {
    return AuthRequest.post(`${baseUrl}/bulkadd`, {
      ...event,
      occurredAt: event.occurredAt.toDate(),
      contactIds,
    });
  },
};

export default EventsApi;
