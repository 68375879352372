import React from "react";
import axios from "axios";
import PaginatedTable from "../../shared/PaginatedTable";
import AddStudentModal from "./AddStudentModal";
import StudentsTableActions from "./StudentsTableActions";
import StudentsTableFilters from "./StudentsTableFilters";
import StudentsTableHeader from "./StudentsTableHeader";
import StudentsTableRow from "./StudentsTableRow";
import SinaiScholarsImportModal from "../import/SinaiScholarsImportModal";

import EduApi from "../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { EduProgramTypes } from "../EduConsts";

export default class StudentsTable extends React.PureComponent {
  state = {
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    results: 12,
    showAddStudentModal: false,
    showImportStudentsModal: false,
    students: [],
    sortBy: "studentName",
    success: true,
    totalStudents: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterStudents = (filters) => {
    this.setState({ filters }, () => {
      this.getStudents();
    });
  };

  getStudents = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        EduApi.getEduStudents(
          this.apiSignal.token,
          this.props.eduProgram.id,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(({ students, totalStudents }) => {
            this.setState({
              loading: false,
              students,
              totalStudents,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                students: [],
                success: false,
                totalStudents: 0,
              });
            }
          });
      },
    );
  };

  getStudentsForExport = async () => {
    const { sortBy, filters } = this.state;

    const studentsForExport = await EduApi.getEduStudents(
      this.apiSignal.token,
      this.props.eduProgram.id,
      undefined,
      undefined,
      sortBy,
      filters,
      true,
    );

    return studentsForExport.students;
  };

  render() {
    const {
      eduChabadHouses,
      eduProgram,
      mobileMode,
      refreshEduProgram,
      systemLists: { approvalStatuses = [] } = {},
      viewOnly,
    } = this.props;

    const {
      loading,
      page,
      results,
      showAddStudentModal,
      showImportStudentsModal,
      students,
      sortBy,
      success,
      totalStudents,
    } = this.state;

    const showLatestCourseInfo = eduProgram.type !== EduProgramTypes.JewishU;
    const hideStatus = eduProgram.type === EduProgramTypes.JewishU;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Students</p>
          <StudentsTableActions
            hideStatus={hideStatus}
            eduProgram={eduProgram}
            getStudentsForExport={this.getStudentsForExport}
            onImportStudents={() =>
              this.setState({ showImportStudentsModal: true })
            }
            showLatestCourseInfo={showLatestCourseInfo}
            onAddStudent={() => this.setState({ showAddStudentModal: true })}
            viewOnly={viewOnly}
          />
        </div>
        <div className="courses-table">
          <PaginatedTable
            filterComponent={
              <StudentsTableFilters
                applyFilters={this.filterStudents}
                approvalStatuses={approvalStatuses}
                eduChabadHouses={eduChabadHouses}
                hideStatus={hideStatus}
              />
            }
            loading={loading}
            loadData={this.getStudents}
            mobileMode={mobileMode}
            page={page}
            records={students}
            renderHeader={() => (
              <StudentsTableHeader
                hideStatus={hideStatus}
                showLatestCourseInfo={showLatestCourseInfo}
              />
            )}
            renderRow={(student, index) => (
              <StudentsTableRow
                hideStatus={hideStatus}
                eduProgramScheduleId={eduProgram.programScheduleID}
                showLatestCourseInfo={showLatestCourseInfo}
                key={index}
                student={student}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            success={success}
            totalCount={totalStudents}
          />

          <AddStudentModal
            close={() => this.setState({ showAddStudentModal: false })}
            show={showAddStudentModal}
            eduProgramID={this.props.eduProgram.id}
          />
        </div>
        <SinaiScholarsImportModal
          close={() => this.setState({ showImportStudentsModal: false })}
          reloadEduProgramDetails={() => {
            //reload students
            this.getStudents();
            //refresh program details
            refreshEduProgram();
          }}
          show={showImportStudentsModal}
          type="Course Enrollments"
        />
      </div>
    );
  }
}
