// export interface School {
//   id?: number;
//   name: string;
//   mosads: Array<any>;
// }

// export interface SchoolFilter {
//   name?: string;
//   countries: string;
//   states: string;
//   association: string;
// }
/** @returns {SchoolFilter} */
export function EmptySchoolFilter() {
  return {
    name: "",
    countries: "",
    states: "",
    association: "All",
    status: "ActiveOnly",
  };
}
