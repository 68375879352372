import React from "react";
import { browserHistory, Link } from "react-router";
import { formatFullName } from "../../../../lib/utils";
import moment from "moment";
import RegistrationStatusActions from "./studentRegistration/details/status/RegistrationStatusActions";
import {
  TripRegistrationStatuses,
  TripRegistrationWorkflowSteps,
} from "../../TripEventConsts";
import { getTravelTripRegistrationStatusWorkflowDisplay } from "../../TripEventLogic";

export default class StudentsTableRecord extends React.PureComponent {
  render() {
    const {
      onStatusUpdate,
      readOnlyAccess,
      scheduleId,
      selected,
      selectionDisabledReason,
      showSelectCheckbox,
      showMoreTableColumns,
      studentRegistration,
      toggleSelectStudent,
      tripEvent: { isTravelTrip, programScheduleName, tours },
    } = this.props;

    const {
      campusName,
      id,
      shliachFirstName,
      shliachLastName,
      studentFirstName,
      studentLastName,
      status,
      statusDisplay,
      statusWorkflowUpdatedOn,
      tourScheduleIDs,
      trackName,
      wasRejectionEmailSent,
      workflowStep,
    } = studentRegistration;

    return (
      <div
        className={`trip-students-table-row${
          readOnlyAccess ? " read-only" : ""
        }`}
      >
        <div className="flex">
          {showSelectCheckbox && (
            <div className="tooltip-container mr-8">
              <div className="flex custom-checkbox-container">
                <input
                  className="custom-checkbox"
                  checked={selected}
                  disabled={!!selectionDisabledReason}
                  id={`registration-${id}`}
                  onChange={toggleSelectStudent}
                  type="checkbox"
                />
                <label
                  className="flex-align-center"
                  htmlFor={`registration-${id}`}
                />
              </div>
              {selectionDisabledReason && (
                <span className="tooltip">{selectionDisabledReason}</span>
              )}
            </div>
          )}
          <Link
            className="link-text-secondary"
            to={`/trips/${scheduleId}/students/${id}`}
          >
            {formatFullName(studentFirstName, studentLastName)}
          </Link>
        </div>
        <div>
          <p>{campusName}</p>
          <p className="fw-400 accent-text mb-4">
            {formatFullName(shliachFirstName, shliachLastName)}
          </p>
        </div>
        {isTravelTrip ? (
          readOnlyAccess ? (
            <div>
              {getTravelTripRegistrationStatusWorkflowDisplay(
                studentRegistration
              )}
            </div>
          ) : (
            <div>
              <RegistrationStatusActions
                forTableRecord={true}
                isTravelTrip={isTravelTrip}
                onUpdate={onStatusUpdate}
                programScheduleName={programScheduleName}
                registration={studentRegistration}
              />
              {workflowStep === TripRegistrationWorkflowSteps.Eligible ? (
                <p className="fw-400 accent-text">Not ready for processing</p>
              ) : (
                status === TripRegistrationStatuses.Rejected && (
                  <p className="fw-400 accent-text">
                    Rejection email{" "}
                    {wasRejectionEmailSent ? "sent" : "not sent"}
                  </p>
                )
              )}
            </div>
          )
        ) : (
          <div>{statusDisplay}</div>
        )}
        <div>
          {statusWorkflowUpdatedOn
            ? moment(statusWorkflowUpdatedOn).format("MM/DD/YYYY")
            : ""}
        </div>
        {showMoreTableColumns && (
          <>
            <div>
              <p>{trackName}</p>
            </div>
            <div className="flex relative">
              {tours.map((tour) =>
                tour.schedules.map((tourSchedule, index) => (
                  <p
                    key={index}
                    className="students-table-row-tour text-center"
                    style={{ width: "160px", color: "#53B7E8" }}
                  >
                    {tourScheduleIDs &&
                    tourScheduleIDs.indexOf(tourSchedule.id) >= 0 ? (
                      <i className="material-icons">check</i>
                    ) : null}
                  </p>
                ))
              )}
            </div>
          </>
        )}
        <div>
          {!readOnlyAccess && (
            <>
              <i
                className="material-icons accent-text-dark link-text-secondary"
                onClick={() =>
                  browserHistory.push(`/trips/${scheduleId}/students/${id}`)
                }
              >
                edit
              </i>
              {!isTravelTrip && (
                <RegistrationStatusActions
                  forTableRecord={true}
                  isTravelTrip={isTravelTrip}
                  onUpdate={onStatusUpdate}
                  programScheduleName={programScheduleName}
                  registration={studentRegistration}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}
