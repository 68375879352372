import React from "react";

import CustomSelect from "../../Form/CustomSelect";
import FormInput from "../../Form/FormInput";
import FormInputUpdate from "../../Form/FormInputUpdate";
import RadioContainer from "../../Form/RadioContainer";

export default class AddressInfo extends React.PureComponent {
  state = {
    addressInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        addressInfoShowing: false,
      });
    }
  }

  addressLabelError = (touched, errors) => {
    return (
      touched &&
      touched.address &&
      touched.address.address1 === true &&
      touched.address.country === true &&
      touched.address.city === true &&
      touched.address.state === true &&
      touched.address.zip === true &&
      errors &&
      errors &&
      errors.address &&
      (errors.address.address1 === true ||
        errors.address.country === true ||
        errors.address.city === true ||
        errors.address.state === true ||
        errors.address.zip === true)
    );
  };

  render() {
    const {
      errors,
      hasUSAddress,
      require,
      touched,
      values,
      values: { address: { country } = {} },
      sys: { countries },
      setFieldValue,
      handleChange,
      shliachID,
    } = this.props;
    const addressLabelError = this.addressLabelError(touched, errors);
    const shliachIndex =
      values.shluchim &&
      values.shluchim.map((shliach) => shliach.shliachID).indexOf(shliachID);
    return (
      <div
        className={
          this.state.addressInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
        style={{ position: "relative" }}
      >
        <p
          className="accent-text-dark medium-text fw-700 mt-32 mb-16 flex flex-align-center flex-justify-space"
          onClick={() => {
            this.setState({
              addressInfoShowing: !this.state.addressInfoShowing,
            });
          }}
        >
          Address Information
          <i className="material-icons">
            {this.state.addressInfoShowing
              ? "keyboard_arrow_up"
              : "keyboard_arrow_down"}
          </i>
        </p>
        <FormInput name="address.hideFromPublic">
          {({ field }) => (
            <div className="flex custom-checkbox-container mb-8">
              <input
                type="checkbox"
                className="custom-checkbox"
                id="hideFromPublic"
                name={field.name}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: field.name,
                      value: e.target.checked,
                    },
                  });
                }}
                checked={field.value === true}
              />
              <label
                className="flex flex-align-center"
                htmlFor="hideFromPublic"
              >
                Please do not show this address publicly
              </label>
            </div>
          )}
        </FormInput>
        <p
          className="accent-text-dark desktop-hidden tablet-hidden"
          style={{ padding: "0", marginTop: "-8px" }}
        >
          {this.state.addressInfoShowing ? "" : "General Information"}
        </p>

        <div className="profile-form-inputs">
          <div className="flex" style={{ gridColumnStart: "span 2" }}>
            <label
              className={`accent-text small-text mt-8 form-required ${
                addressLabelError ? "error" : ""
              }`}
            >
              Address
            </label>
            <div style={{ width: "100%" }}>
              <FormInput
                type="text"
                name="address.address1"
                validate={require}
                errors={errors}
                touched={touched}
                className="mb-8"
              />
              <FormInput
                type="text"
                name="address.address2"
                errors={errors}
                touched={touched}
                className="mb-8"
                placeholder="Apt #, Suite, Floor"
              />
              <div className="address-grid">
                <FormInput
                  name="address.country"
                  validate={require}
                  errors={errors}
                  touched={touched}
                  className="mr-8"
                >
                  {({ field }) => (
                    <CustomSelect
                      placeholder="Select Country..."
                      errors={errors}
                      touched={touched}
                      {...field}
                      options={
                        countries &&
                        countries.map((c) => ({
                          key: c.name,
                          value: c.name,
                          label: c.name,
                        }))
                      }
                      setFieldValue={setFieldValue}
                    />
                  )}
                </FormInput>
                <FormInput
                  type="text"
                  name="address.city"
                  placeholder="City"
                  validate={require}
                  errors={errors}
                  touched={touched}
                  className="mr-8"
                />
                <FormInput
                  name="address.state"
                  validate={hasUSAddress(values.address) ? require : null}
                  errors={errors}
                  touched={touched}
                  className="mr-8"
                >
                  {({ field }) =>
                    country === "USA" ||
                    country === "Canada" ||
                    country === "Mexico" ? (
                      <CustomSelect
                        {...field}
                        errors={errors}
                        isClearable={true}
                        options={(
                          countries
                            .filter((c) => c.name === country)
                            .map((c) => c.states)[0] || []
                        ).map((s) => ({
                          key: s.code,
                          value: s.name,
                          label: s.name,
                        }))}
                        placeholder="Select State/Region..."
                        setFieldValue={setFieldValue}
                        touched={touched}
                      />
                    ) : (
                      <FormInputUpdate
                        name="family.address.state"
                        type="text"
                        placeholder="State/Region"
                        validate={hasUSAddress(values.family) ? require : null}
                        errors={errors}
                        touched={touched}
                        className="mr-8"
                      />
                    )
                  }
                </FormInput>
                <FormInput
                  type="text"
                  name="address.zip"
                  placeholder="Zip Code"
                  validate={hasUSAddress(values.address) ? require : null}
                  errors={errors}
                  touched={touched}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-align-center ml-24">
            <FormInput name={`shluchim[${shliachIndex}].isPrimaryResidence`}>
              {({ field }) => (
                <div className="flex custom-checkbox-container">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    id="isPrimaryResidence"
                    name={field.name}
                    onChange={(e) => {
                      if (e.target.checked)
                        handleChange({
                          target: {
                            name: field.name,
                            value: true,
                          },
                        });
                      else {
                        handleChange({
                          target: {
                            name: field.name,
                            value: false,
                          },
                        });
                      }
                    }}
                    checked={field.value === true}
                  />
                  <label
                    className="flex flex-align-center"
                    htmlFor="isPrimaryResidence"
                  >
                    Chabad house is my primary residence
                  </label>
                </div>
              )}
            </FormInput>
            <div className="tooltip-container">
              <i
                className={`material-icons link-text no-hover profile-add-icon ml-8`}
              >
                info
              </i>
              <span className="tooltip">
                This info will not be shared publicly
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
