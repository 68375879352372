import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import ShluchimBulkEnrollmentModal from "./ShluchimBulkEnrollmentModal";
import {
  flattenArray,
  formatCurrency,
  formatExcelNumberString,
} from "../../../../lib/utils";
import moment from "moment";

export default class ShluchimActions extends React.PureComponent {
  state = { showBulkEnrollmentModal: false };

  exportTypes = {
    Basic: "Basic",
    Full: "Full",
  };

  getEmailRecipientHeaders = (data) => {
    const maxEmailRecipientsCount = Math.max(
      ...data.map((d) => d.emailRecipients?.length || 0)
    );
    return flattenArray(
      Array.from({ length: maxEmailRecipientsCount }, (_, index) => [
        {
          label: `Recipient ${index + 1} First Name`,
          value: ({ emailRecipients }) =>
            (emailRecipients && emailRecipients[index]?.firstName) || "",
        },
        {
          label: `Recipient ${index + 1} Last Name`,
          value: ({ emailRecipients }) =>
            (emailRecipients && emailRecipients[index]?.lastName) || "",
        },
        {
          label: `Recipient ${index + 1} Email`,
          value: ({ emailRecipients }) =>
            (emailRecipients && emailRecipients[index]?.email) || "",
        },
      ])
    );
  };

  getShluchimExportHeaders = (exportType, enrolledView, data) => {
    const {
      eventFeesPaid,
      tripEvent: { isTravelTrip },
    } = this.props;

    return [
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Cell Phone", value: "shliachCell" },
      { label: "Shlucha ID", value: "shluchaID" },
      { label: "Shlucha First Name", value: "shluchaFirstName" },
      { label: "Shlucha Last Name", value: "shluchaLastName" },
      { label: "Shlucha Email", value: "shluchaEmail" },
      { label: "Shlucha Cell Phone", value: "shluchaCell" },
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      ...(exportType === this.exportTypes.Full
        ? [
            { label: "Chabad House Address 1", value: "chabadHouseAddress1" },
            { label: "Chabad House Address 2", value: "chabadHouseAddress2" },
            { label: "Chabad House City", value: "chabadHouseCity" },
            { label: "Chabad House State", value: "chabadHouseState" },
            {
              label: "Chabad House Zip",
              value: ({ chabadHouseZip }) =>
                formatExcelNumberString(chabadHouseZip),
            },
            { label: "Chabad House Country", value: "chabadHouseCountry" },
          ]
        : []),
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      ...(enrolledView
        ? [
            ...(!isTravelTrip
              ? [
                  {
                    label: "Registration Close Date",
                    value: ({ studentRegistrationEndDate }) =>
                      moment(studentRegistrationEndDate).format(
                        "MM/DD/YYYY h:mm A"
                      ),
                  },
                  {
                    label: "Latest Cancellation Date",
                    value: ({ latestCancellationDate }) =>
                      moment(latestCancellationDate).format(
                        "MM/DD/YYYY h:mm A"
                      ),
                  },
                ]
              : []),
            {
              label: "Total Students Accepted",
              value: "numOfStudentRegistered",
            },
            ...(!isTravelTrip
              ? [
                  {
                    label: `COCI Owe${eventFeesPaid ? "d" : "s"}`,
                    value: "cociOwes",
                  },
                  {
                    label: `Shliach Owe${eventFeesPaid ? "d" : "s"}`,
                    value: "chabadHouseOwes",
                  },
                ]
              : []),
          ]
        : []),
      ...(!isTravelTrip && exportType === this.exportTypes.Full
        ? [
            { label: "Total Paid", value: "totalPrice" },
            { label: "Total Fees", value: "cociTotalPrice" },
          ]
        : []),
      ...this.getEmailRecipientHeaders(data),
    ];
  };

  getEnrollmentsDetailsExportHeaders = (data) => {
    const { tours: allTours = [] } = (data && data.find((e) => e.tours)) || {};
    const { tracks: allTracks = [] } =
      (data && data.find((e) => e.tracks)) || {};
    const maxAttendees = Math.max(...data.map((d) => d.attendees?.length || 0));
    return [
      { label: "ID", value: "id" },
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Cell Phone", value: "shliachCell" },
      { label: "Shlucha ID", value: "shluchaID" },
      { label: "Shlucha First Name", value: "shluchaFirstName" },
      { label: "Shlucha Email", value: "shluchaEmail" },
      { label: "Shlucha Cell Phone", value: "shluchaCell" },
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      {
        label: "Total Students Accepted",
        value: "numOfStudentRegistered",
      },
      {
        label: "Total Students Pending",
        value: "numOfStudentsPending",
      },
      {
        label: "Participating Campuses",
        value: ({ campuses }) =>
          campuses && campuses.map((c) => c.name).join(","),
      },
      {
        label: "Acceptance Process",
        value: ({ acceptancePolicyOverride }) =>
          acceptancePolicyOverride === "OptIn" ? "Yes" : "No",
      },
      {
        label: "Shliach Attending",
        value: ({ isShliachAttending }) => (isShliachAttending ? "Yes" : "No"),
      },
      {
        label: "Shlucha Attending",
        value: ({ isShluchaAttending }) => (isShluchaAttending ? "Yes" : "No"),
      },
      ...Array.from({ length: maxAttendees }).map((_, index) => {
        return {
          key: `attendee-${index}`,
          label: `Attendee info`,
          value: ({ attendees }) => {
            const person = attendees[index];
            if (!person) return "";
            return `${person.firstName || ""} ${person.lastName || ""}${
              person.phoneNumber ? ` | ${person.phoneNumber}` : ""
            }${person.email ? ` | ${person.email}` : ""}`;
          },
        };
      }),
      ...flattenArray(
        allTracks.map((track) => [
          {
            key: `track-${track.id}`,
            label: `${track.name} Students Registered`,
            value: ({ tracks }) => {
              var tr = tracks && tracks.find((t) => t.id === track.id);
              if (!tr) return "";
              return tr.registrationCount;
            },
          },
          {
            key: `track-${track.id}-price`,
            label: `${track.name} Trip Fee (Early/Regular)`,
            value: ({ tracks }) => {
              var tr = tracks && tracks.find((t) => t.id === track.id);
              if (!tr) return "";
              return `${formatCurrency(
                tr.earlyBirdStudentPrice
              )}/${formatCurrency(tr.regularStudentPrice)}`;
            },
          },
        ])
      ),
      ...flattenArray(
        allTours.map((tour) => [
          {
            key: `tour-${tour.id}`,
            label: tour.name,
            value: ({ tours }) => {
              const tr = tours && tours.find((t) => t.id === tour.id);
              if (!tr || !tr.isOffering) return "None";
              return (
                tr.tourSchedulesOffered &&
                tr.tourSchedulesOffered.map((ts) => ts.name).join(", ")
              );
            },
          },
          {
            key: `tour-${tour.id}-trans`,
            label: `${tour.name} Transportation`,
            value: ({ tours }) => {
              const tr = tours && tours.find((t) => t.id === tour.id);
              if (!tr || !tr.isOffering) return "";
              return tr.transportationOption;
            },
          },
        ])
      ),
      ...this.getEmailRecipientHeaders(data),
    ];
  };

  getEnrollmentsAttendeesExportHeaders = () => {
    return [
      { label: "ID", value: "id" },
      { label: "First Name", value: "firstName" },
      { label: "Last Name", value: "lastName" },
      { label: "Phone", value: "phoneNumber" },
      { label: "Email", value: "email" },
      { label: "Relationship", value: "relationship" },
      {
        label: "Is Shliach",
        value: ({ isShliach }) => (isShliach ? "Yes" : "No"),
      },
      { label: "Main Shliach ID", value: "mainShliachID" },
      { label: "Main Shliach First Name", value: "mainShliachFirstName" },
      { label: "Main Shliach Last Name", value: "mainShliachLastName" },
      { label: "Main Shlucha ID", value: "mainShluchaID" },
      { label: "Main Shlucha First Name", value: "mainShluchaFirstName" },
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      {
        label: "Total Students Accepted",
        value: "numOfStudentRegistered",
      },
      { label: "Tracks with Students Registered", value: "tracks" },
    ];
  };

  getShluchimForExport = async (exportType) => {
    return await this.props.getShluchimForExport(exportType);
  };

  getEnrollmentsExport = async (exportType) => {
    return await this.props.getEnrollmentsReport(exportType);
  };

  render() {
    const { showBulkEnrollmentModal } = this.state;
    const {
      enrolledView,
      getShluchimSelection,
      refreshShluchim,
      selectedShluchim,
      showBulkEnrollmentButton,
      tripEvent,
    } = this.props;

    return (
      <div className="flex flex-align-center">
        {showBulkEnrollmentButton && (
          <>
            <button
              className="custom-btn btn btn-light uppercase-text ml-16"
              disabled={
                !selectedShluchim.all && !selectedShluchim.selection.length
              }
              onClick={() => this.setState({ showBulkEnrollmentModal: true })}
            >
              Enroll Shluchim
            </button>
            {showBulkEnrollmentModal && (
              <ShluchimBulkEnrollmentModal
                close={() => this.setState({ showBulkEnrollmentModal: false })}
                getShluchimSelection={getShluchimSelection}
                refreshShluchim={refreshShluchim}
                tripEventId={tripEvent.id}
              />
            )}
          </>
        )}
        <div className="dropdown-container ml-16">
          <button className="custom-btn btn btn-accent uppercase-text flex flex-align-center">
            CSV Export
            <i className="material-icons ml-8">arrow_drop_down</i>
          </button>
          <div
            className="dropdown btn-dropdown csv-export-dropdown"
            style={{ width: "205px" }}
          >
            <ExportCSVButton
              className="small-text mb-0 link-text-secondary"
              fileName={`${(tripEvent.programScheduleName || "Trip").replace(
                / /g,
                "_"
              )}_${
                enrolledView ? "Shluchim_Enrollments" : "Unenrolled_Shluchim"
              }`}
              getExportData={() =>
                this.getShluchimForExport(this.exportTypes.Basic)
              }
              getHeaders={(data) =>
                this.getShluchimExportHeaders(
                  this.exportTypes.Basic,
                  enrolledView,
                  data
                )
              }
              title="Basic Export"
            />
            {!!enrolledView && (
              <>
                <ExportCSVButton
                  className="small-text mb-0 mt-16 link-text-secondary"
                  fileName={`${(
                    tripEvent.programScheduleName || "Trip"
                  ).replace(/ /g, "_")}_Shluchim_Enrollments_Full`}
                  getExportData={() =>
                    this.getShluchimForExport(this.exportTypes.Full)
                  }
                  getHeaders={(data) =>
                    this.getShluchimExportHeaders(
                      this.exportTypes.Full,
                      enrolledView,
                      data
                    )
                  }
                  title="Full Export"
                />
                {!tripEvent.isTravelTrip && (
                  <>
                    <ExportCSVButton
                      className="small-text mb-0 mt-16 link-text-secondary"
                      fileName={`${(
                        tripEvent.programScheduleName || "Trip"
                      ).replace(/ /g, "_")}_Shluchim_Enrollment_Details`}
                      getExportData={() =>
                        this.getEnrollmentsExport("Enrollments")
                      }
                      getHeaders={this.getEnrollmentsDetailsExportHeaders}
                      title="Enrollment Details Export"
                    />
                    <ExportCSVButton
                      className="small-text mb-0 mt-16 link-text-secondary"
                      fileName={`${(
                        tripEvent.programScheduleName || "Trip"
                      ).replace(/ /g, "_")}_Attendees`}
                      getExportData={() =>
                        this.getEnrollmentsExport("Attendees")
                      }
                      getHeaders={this.getEnrollmentsAttendeesExportHeaders}
                      title="Enrollment Attendees Export"
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
