import AuthService, { PermissionClaims } from "../../../services/AuthService";
// import { NavMenuItem } from "../../../models/Menu";

export const SHLUCHIM_MENU_TEXT = "Shluchim Programs";
export const STUDENT_MENU_TEXT = "Students Programs";
/** @param {NavMenuItem} a
 * @param {NavMenuItem} b
 * @returns {number}
 */
const sortMenuItemsByText = (a, b) =>
  a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
/** @param {NavMenuItem} a
 * @param {NavMenuItem} b
 * @returns {number}
 */
const sortMenuItemsByOrder = (a, b) => (a.order < b.order ? -1 : 1);
/** @returns {Array<NavMenuItem>} */
export const getMainMenuItems = () => {
  const hasDirectoryPermission =
    AuthService.UserHasClaim(PermissionClaims.DirectoryRead) ||
    AuthService.UserHasClaim(PermissionClaims.DirectoryEdit);

  const hasRolePermission = AuthService.UserHasClaim(
    PermissionClaims.RoleManager,
  );

  // const hasIsraeLinksPermission = AuthService.UserHasClaim(PermissionClaims.IsraeLinksSummary);
  // const hasShabbatonPermission = AuthService.UserHasClaim(PermissionClaims.ShabbatonSummary);
  // const hasKinusPermission = AuthService.UserHasClaim(PermissionClaims.KinusSummary);
  // const hasMediaPRPermission = AuthService.UserHasClaim(PermissionClaims.MediaPRSummary);

  // const hasRafflePermission = AuthService.UserHasClaim(PermissionClaims.RaffleSummary);
  // const hasLampligthersPermission = AuthService.UserHasClaim(PermissionClaims.LampligthersSummary);
  // const hasChayenuPermission = AuthService.UserHasClaim(PermissionClaims.ChayenuSummary);
  // const hasTefillinPermission = AuthService.UserHasClaim(PermissionClaims.TefillinSummary);
  // const hasLifeInsurancePermission = AuthService.UserHasClaim(PermissionClaims.LifeInsuranceSummary);

  /** @type {Array<NavMenuItem>} */
  const returnValue = [];

  if (hasDirectoryPermission) {
    const menuItemDirectory = {
      text: "Directory",
      order: 1,
      items: [
        {
          text: "Schools",
          order: 1,
          urlTo: "/schools",
        },
        {
          text: "Shluchim",
          order: 2,
          urlTo: "/shluchim",
        },
        {
          text: "Students",
          order: 3,
          urlTo: "/students",
        },
        // {
        //   text: "Organizations",
        //   order: 5,
        //   urlTo: "/organizations",
        // },
        // {
        //   text: "Office Staff",
        //   order: 6,
        //   urlTo: "/office",
        // },
        {
          text: "Programs",
          order: 7,
          urlTo: "/programs",
        },
      ],
    };
    if (hasRolePermission) {
      menuItemDirectory.items.push({
        text: "Users",
        order: 4,
        urlTo: "/users",
      });
    }
    menuItemDirectory.items.sort(sortMenuItemsByOrder);
    returnValue.push(menuItemDirectory);

    // const menuItemCustomPages = {
    //   text: "Other",
    //   order: 4,
    //   items: [
    //     {
    //       text: "Custom Pages",
    //       order: 1,
    //       urlTo: "/config/custompages",
    //     },
    //   ],
    // };
    // returnValue.push(menuItemCustomPages);
  }

  // if (hasKinusPermission || hasMediaPRPermission || hasLifeInsurancePermission || hasRafflePermission) {
  //   /** @type {NavMenuItem} */
  //   const menuItemShluchim = {
  //     text: SHLUCHIM_MENU_TEXT,
  //     order: 2,
  //     items: [],
  //   };
  //   if (hasKinusPermission) {
  //     menuItemShluchim.items.push({
  //       text: "Kinus",
  //       order: 1,
  //       urlTo: "/kinus",
  //     });
  //   }
  //   if (hasMediaPRPermission) {
  //     menuItemShluchim.items.push({
  //       text: "Media & PR",
  //       order: 2,
  //       urlTo: "/mediapr",
  //     });
  //   }
  //   if (hasLifeInsurancePermission) {
  //     menuItemShluchim.items.push({
  //       text: "Life Insurance",
  //       order: 3,
  //       urlTo: "/lifeinsurance",
  //     });
  //   }
  //   if (hasRafflePermission) {
  //     menuItemShluchim.items.push({
  //       text: "Raffle",
  //       order: 4,
  //       urlTo: "/raffle",
  //     });
  //   }
  //   returnValue.push(menuItemShluchim);
  // }

  // if (
  //   hasChayenuPermission ||
  //   hasIsraeLinksPermission ||
  //   hasLampligthersPermission ||
  //   hasShabbatonPermission ||
  //   hasTefillinPermission
  // ) {
  //   /** @type {NavMenuItem} */
  //   const menuItemStudents = {
  //     text: STUDENT_MENU_TEXT,
  //     order: 3,
  //     items: [],
  //   };
  //   if (hasChayenuPermission) {
  //     menuItemStudents.items.push({
  //       text: "Chayenu",
  //       order: 1,
  //       urlTo: "/chayenu",
  //     });
  //   }
  //   if (hasIsraeLinksPermission) {
  //     menuItemStudents.items.push({
  //       text: "Israelinks",
  //       order: 2,
  //       urlTo: "/israelinks",
  //     });
  //   }
  //   if (hasLampligthersPermission) {
  //     menuItemStudents.items.push({
  //       text: "Lamplighters",
  //       order: 3,
  //       urlTo: "/lamplighters",
  //     });
  //   }
  //   if (hasShabbatonPermission) {
  //     menuItemStudents.items.push({
  //       text: "Pegisha",
  //       order: 4,
  //       urlTo: "/shabbaton",
  //     });
  //   }
  //   if (hasTefillinPermission) {
  //     menuItemStudents.items.push({
  //       text: "Tefillin",
  //       order: 5,
  //       urlTo: "/tefillin",
  //     });
  //   }
  //   returnValue.push(menuItemStudents);
  // }

  return returnValue;
};
/** @param {NavMenuItem} item
 * @returns {NavMenuItem}
 */
export function sortMenuSubItemsAlphabetically(item) {
  if (item.items && item.items.length > 0) {
    return Object.assign({}, item, {
      items: sortMenuItemsAlphabetically(item.items),
    });
  }
  return Object.assign({}, item);
}
/** @param {Array<NavMenuItem>} items
 * @returns {Array<NavMenuItem>}
 */
export function sortMenuItemsAlphabetically(items) {
  const newItems = [...items];
  const returnItems = newItems.sort(sortMenuItemsByText).map((mi, index) =>
    Object.assign({}, sortMenuSubItemsAlphabetically(mi), {
      order: index,
    }),
  );
  return returnItems;
}
