import React from "react";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { TypeaheadSearch } from "../../lib/coc-common-components";
import { browserHistory, Link } from "react-router";

import Nav from "react-bootstrap/lib/Nav";
import NavDropdown from "react-bootstrap/lib/NavDropdown";
import DropDownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import ContactsApi from "../../services/resources/ContactsApi";
import SchoolsApi from "../../services/resources/SchoolsApi";
import OrganizationsApi from "../../services/resources/OrganizationsApi";
import AdvancedSearchModal from "../AdvancedSearch/AdvancedSearchModal";
import { Organization, CategoryTypes } from "../../models/Organization";
import queryString from "query-string";

interface AdvancedSearch {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  school: string;
  chabadHouse: string;
  city: string;
  searchOptions: Array<any>;
}

// interface OmniSearchState {
//   showAdvancedSearch: boolean;
//   searchQuery: string;
//   errorMessage: string;
//   searchType: { label: string; path: string };
//   advancedSearchFilters: AdvancedSearch;
//   searchOpen: boolean;
//   omnisearchHeight: number;
// }

export class OmniSearch extends React.Component {
  placeholderEl: HTMLDivElement | null;
  omnisearchEl: HTMLDivElement | null;
  searchInputEl = { blur: function() {} };

  searchTypes = [
    { label: "Shluchim", path: "/shluchim", includeInAdvancedSearch: true },
    { label: "Students", path: "/students", includeInAdvancedSearch: true },
    { label: "Schools", path: "/schools", includeInAdvancedSearch: true },
    {
      label: "Organizations",
      path: "/organizations",
      includeInAdvancedSearch: false
    }
  ];

  fetchTypeaheadSuggestions = {
    Students: (searchQuery, startingOffset, maxSuggestions) => {
      return ContactsApi.getStudentsNames(searchQuery, maxSuggestions);
    },
    Shluchim: (searchQuery, startingOffset, maxSuggestions) => {
      return ContactsApi.getShluchimNames(searchQuery, maxSuggestions);
    },
    Schools: (searchQuery, startingOffset, maxSuggestions) => {
      return SchoolsApi.getSchoolsNames(searchQuery, maxSuggestions);
    },
    Organizations: (searchQuery, startingOffset, maxSuggestions) => {
      return OrganizationsApi.listNames(searchQuery, maxSuggestions);
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      showAdvancedSearch: false,
      searchQuery: "",
      errorMessage: "",
      advancedSearchFilters: this.emptyAdvancedSearchFilters(),
      omnisearchHeight: 0,
      searchOpen: false,
      searchType: this.getSearchType(props.location)
    };
  }

  emptyAdvancedSearchFilters = (): AdvancedSearch => ({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    school: "",
    chabadHouse: "",
    city: "",
    includeInactive: "false",
    searchOptions: []
  });

  contactTypeaheadDisplay = contact =>
    `${(contact.firstName || "")} ${(contact.lastName || "")}`.trim();

  schoolTypeaheadDisplay = school => school.name || "";

  organizationsTypeaheadDisplay = (organization: Organization) => {
    if (!this.state) {
      return "---";
    }
    if (!organization.category) {
      return "";
    }

    const organizationNameAndType = `${organization.name} - ${
      CategoryTypes[organization.category.type]
    }`;
    if (
      organization.name
        .toLowerCase()
        .includes(this.state.searchQuery.toLowerCase())
    ) {
      return organizationNameAndType;
    } else {
      const matchingContact = organization.contacts.find(c =>
        (c.firstName + " " + c.lastName)
          .toLowerCase()
          .includes(this.state.searchQuery.toLowerCase())
      );
      const matchingContactName =
        (matchingContact &&
          `${matchingContact.firstName} ${matchingContact.lastName} - `) ||
        "";
      return matchingContactName + organizationNameAndType;
    }
  };

  typeaheadDisplayers = {
    Students: this.contactTypeaheadDisplay,
    Shluchim: this.contactTypeaheadDisplay,
    Schools: this.schoolTypeaheadDisplay,
    Organizations: this.organizationsTypeaheadDisplay
  };

  openAdvancedSearch = () => this.setState({ showAdvancedSearch: true });

  closeAdvancedSearch = () => {
    this.setState({ showAdvancedSearch: false });
    this.cleanAdvancedSearch();
  };

  cleanAdvancedSearch = () =>
    this.setState({ advancedSearchFilters: this.emptyAdvancedSearchFilters() });

  changeHandler = event => {
    this.setState({
      errorMessage: "",
      advancedSearchFilters: {
        ...this.state.advancedSearchFilters,
        [event.target.name]: event.target.value
      }
    });
  };

  advancedSearch = event => {
    event.preventDefault();
    if (
      !this.state.advancedSearchFilters.firstName &&
      !this.state.advancedSearchFilters.lastName &&
      !this.state.advancedSearchFilters.email &&
      !this.state.advancedSearchFilters.phone &&
      !this.state.advancedSearchFilters.school &&
      !this.state.advancedSearchFilters.chabadHouse &&
      !this.state.advancedSearchFilters.city
    ) {
      this.setState({ errorMessage: "Please fill at least one field" });
      return;
    }
    if (
      !this.state.advancedSearchFilters.searchOptions ||
      this.state.advancedSearchFilters.searchOptions.length < 1
    ) {
      this.setState({
        errorMessage: "Please select at least one search option"
      });
      return;
    }
    this.setState({ showAdvancedSearch: false });
    var url = "";

    if (this.state.advancedSearchFilters.firstName) {
      url += `fname=${this.state.advancedSearchFilters.firstName}&`;
    }
    if (this.state.advancedSearchFilters.lastName) {
      url += `lname=${this.state.advancedSearchFilters.lastName}&`;
    }
    if (this.state.advancedSearchFilters.email) {
      url += `email=${this.state.advancedSearchFilters.email}&`;
    }
    if (this.state.advancedSearchFilters.phone) {
      url += `phone=${this.state.advancedSearchFilters.phone}&`;
    }
    if (this.state.advancedSearchFilters.school) {
      url += `school=${this.state.advancedSearchFilters.school}&`;
    }
    if (this.state.advancedSearchFilters.chabadHouse) {
      url += `chabadHouse=${this.state.advancedSearchFilters.chabadHouse}&`;
    }
    if (this.state.advancedSearchFilters.city) {
      url += `city=${this.state.advancedSearchFilters.city}&`;
    }
    if (this.state.advancedSearchFilters.includeInactive) {
      url += `includeInactive=${this.state.advancedSearchFilters.includeInactive}&`;
    }
    url += `searchOptions=${this.state.advancedSearchFilters.searchOptions
      .map(c => c.label)
      .join("-")}`;
    url = encodeURIComponent(url);
    this.cleanAdvancedSearch();
    browserHistory.push("/advancedsearch/?" + url);
  };

  updateDimensions = () =>
    this.setState({
      omnisearchHeight: (this.omnisearchEl || { clientHeight: 0 }).clientHeight
    });

  getSearchType = location => {
    const { pathname, query } = location;
    if (pathname.indexOf("advancedsearch") >= 0) {
      const searchQuery = Object.keys(query)[0]; //Note - query string is in first property key of query obj because advanced search does not correctly structure query params for proper parsing
      return (
        this.searchTypes.find(
          s =>
            (queryString.parse(searchQuery).searchOptions || "").indexOf(
              s.label
            ) >= 0
        ) || this.searchTypes[0]
      );
    } else {
      return this.searchTypes.find(s => pathname.indexOf(s.path) === 0);
    }
  };

  setSearchType = searchType => {
    this.setState({ searchType });
  };

  redirectToSearchResults = event => {
    event.preventDefault();
    if (this.searchInputEl) {
      this.searchInputEl.blur();
    }
    this.goToSearchPath(this.state.searchQuery);
  };

  onSelectTypeaheadSuggestion = item => {
    let searchQuery = "";
    if (item.name) {
      searchQuery = item.name;
    } else {
      searchQuery = this.typeaheadDisplayers[this.state.searchType.label](item);
    }
    this.setState({ searchQuery });
    this.goToSearchPath(searchQuery);
  };

  goToSearchPath = searchTerm =>
    browserHistory.push(
      `${this.state.searchType.path}?q=${encodeURIComponent(searchTerm)}`
    );

  onChange = searchQuery => this.setState({ searchQuery });

  onSearchBoxReceivesFocus = () => this.setState({ searchQuery: "" });

  storeSearchInput = inputEl => (this.searchInputEl = inputEl);

  handleChkChange = (e, filter) => {
    e.stopPropagation();
    const checked = e.target.checked;
    let { searchOptions } = this.state.advancedSearchFilters;
    if (checked && !searchOptions.some(c => c.label === filter.label)) {
      searchOptions.push(filter);
    } else {
      searchOptions = searchOptions.filter(c => c.label !== filter.label);
    }
    this.setState({
      advancedSearchFilters: {
        ...this.state.advancedSearchFilters,
        searchOptions
      }
    });
  };

  toggleSearchOpen = () => {
    this.setState(oldState => ({
      searchOpen: !oldState.searchOpen
    }));
  };

  handleSelectAll = e => {
    e.stopPropagation();
    const checked = e.target.checked;
    let searchOptions: Array<any> = [];
    if (checked) {
      this.searchTypes.forEach(filter => {
        if (filter.includeInAdvancedSearch) {
          searchOptions.push(filter);
        }
      });
    }
    this.setState({
      advancedSearchFilters: {
        ...this.state.advancedSearchFilters,
        searchOptions
      }
    });
  };

  componentDidMount() {
    // it looks like react-sidebar is rendering its content after the
    // children get mounted, so the sizes of the refs are wrong at first.
    // here we're waiting for the sidebar to finish rendering before we
    // set the initial dimensions
    setTimeout(this.updateDimensions, 0);

    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentWillReceiveProps(nextProps) {
    const searchType = this.getSearchType(nextProps.location);
    this.setSearchType(searchType);

    if (searchType) {
      this.setState({
        searchQuery: nextProps.location.query.q
      });
    }
  }

  render() {
    const hasConfigPermission =
      AuthService.UserHasClaim(PermissionClaims.DirectoryRead) ||
      AuthService.UserHasClaim(PermissionClaims.DirectoryEdit) ||
      AuthService.UserHasClaim(PermissionClaims.RoleManager);

    return (
      <div className="col-xs-12 header-container">
        {!!this.state.searchType && (
          <div
            className="omnisearch col-xs-12 col-sm-12 col-md-7"
            ref={el => (this.omnisearchEl = el)}
          >
            <form onSubmit={this.redirectToSearchResults}>
              <div className="search-header-container">
                {this.props.mobileMode && (
                  <div className="search-mobile-header">
                    <p className="bh mobile-bh">ב״ה</p>
                    <span
                      className="glyphicon glyphicon-menu-hamburger"
                      onClick={this.props.toggleSidebarOpen}
                    />
                    <Link to="/">
                      <img
                        className="search-logo"
                        src="/logo.png"
                        alt="COCI Admin"
                      />
                    </Link>
                    <span
                      className="glyphicon glyphicon-search"
                      onClick={this.toggleSearchOpen}
                    />
                  </div>
                )}

                {((this.props.mobileMode && this.state.searchOpen) ||
                  !this.props.mobileMode) && (
                  <span className="search-area">
                    <button
                      type="submit"
                      className="btn btn-primary glyphicon glyphicon-search"
                    />

                    <div className="search-typeahead">
                      <TypeaheadSearch
                        onFocus={this.onSearchBoxReceivesFocus}
                        display={i => ""}
                        maxSuggestions={10}
                        placeholder="Search Directory"
                        value={this.state.searchQuery}
                        onSelectTypeaheadSuggestion={
                          this.onSelectTypeaheadSuggestion
                        }
                        onChange={this.onChange}
                        fetchTypeaheadSuggestions={
                          this.fetchTypeaheadSuggestions[
                            this.state.searchType.label
                          ]
                        }
                        typeaheadDisplay={
                          this.typeaheadDisplayers[this.state.searchType.label]
                        }
                        storeSearchInput={this.storeSearchInput}
                      />
                    </div>

                    <DropDownButton
                      title={this.state.searchType.label}
                      id="search-filter"
                    >
                      {this.searchTypes.map(s => (
                        <MenuItem
                          key={s.label}
                          onClick={() => this.setSearchType(s)}
                        >
                          {s.label}
                        </MenuItem>
                      ))}
                    </DropDownButton>

                    <Link
                      className="advanced-search"
                      onClick={this.openAdvancedSearch}
                    >
                      Advanced
                    </Link>
                  </span>
                )}
              </div>
            </form>
          </div>
        )}

        {!this.props.mobileMode && <p className="bh">ב״ה</p>}

        {!this.props.mobileMode && (
          <Nav className="pull-right user-dropdown">
            <NavDropdown
              title={AuthService.getCurrentUser().name}
              id="admin-settings"
            >
              {hasConfigPermission && (
                <MenuItem href="/config">Configuration</MenuItem>
              )}
              <MenuItem onClick={AuthService.logout}>Logout</MenuItem>
            </NavDropdown>
          </Nav>
        )}

        <AdvancedSearchModal
          showAdvancedSearch={this.state.showAdvancedSearch}
          closeAdvancedSearch={this.closeAdvancedSearch}
          errorMessage={this.state.errorMessage}
          advancedSearch={this.advancedSearch}
          changeHandler={this.changeHandler}
          handleSelectAll={this.handleSelectAll}
          handleChkChange={this.handleChkChange}
          firstName={this.state.advancedSearchFilters.firstName}
          lastName={this.state.advancedSearchFilters.lastName}
          phone={this.state.advancedSearchFilters.phone}
          email={this.state.advancedSearchFilters.email}
          school={this.state.advancedSearchFilters.school}
          chabadHouse={this.state.advancedSearchFilters.chabadHouse}
          city={this.state.advancedSearchFilters.city}
          includeInactive={this.state.advancedSearchFilters.includeInactive}
          searchOptions={this.state.advancedSearchFilters.searchOptions}
          searchTypes={this.searchTypes}
        />
      </div>
    );
  }
}
