import React from "react";
import SelectSearch from "react-select";
import debounce from "lodash.debounce";

export default class StudentsTableFilters extends React.PureComponent {
  state = {
    campus: null,
    keyword: "",
    shliach: null,
    status: null,
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => {
    const { campus, keyword, shliach, status } = this.state;

    this.props.applyFilters({
      campusId: campus ? campus.value : "",
      keyword,
      shliachId: shliach ? shliach.value : "",
      status: status ? status.value : "",
    });
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const {
      approvalStatuses,
      hideStatus,
      eduChabadHouses: { eduChabadHousesList, eduChabadHousesListLoading },
    } = this.props;

    const { campus, keyword, shliach, status } = this.state;

    return (
      <div className="full-width courses-students-table-filters mt-24">
        <div className="search-input">
          <input
            type="text"
            placeholder="Search Student Name"
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        <div>
          <SelectSearch
            onChange={(val) => this.onChange("shliach", val)}
            options={
              eduChabadHousesList &&
              eduChabadHousesList.shluchim &&
              eduChabadHousesList.shluchim.map((s) => ({
                value: s.shliachID,
                label: s.fullName,
              }))
            }
            placeholder={
              eduChabadHousesListLoading
                ? "Loading All Shluchim..."
                : "All Shluchim"
            }
            style={{ height: "36px" }}
            value={shliach}
          />
        </div>
        <div>
          <SelectSearch
            onChange={(val) => this.onChange("campus", val)}
            options={
              eduChabadHousesList &&
              eduChabadHousesList.campuses &&
              eduChabadHousesList.campuses.map((c) => ({
                value: c.campusID,
                label: c.campusName,
              }))
            }
            placeholder={
              eduChabadHousesListLoading
                ? "Loading All Campuses..."
                : "All Campuses"
            }
            style={{ height: "36px" }}
            value={campus}
          />
        </div>
        {!hideStatus && (
          <div className="flex flex-align-center">
            <p className="accent-text-dark">Filter by</p>
            <SelectSearch
              className="filter-by-select"
              onChange={(val) => this.onChange("status", val)}
              options={
                approvalStatuses &&
                approvalStatuses.map((status) => ({
                  value: status.enumValue,
                  label: status.displayValue,
                }))
              }
              placeholder="All Statuses"
              searchable={false}
              value={status}
            />
          </div>
        )}
      </div>
    );
  }
}
