import React from "react";
import { Link } from "react-router";
import Loader from "../../../common/Loader";
import LocationCoursesTable from "./LocationCoursesTable";
import Modal from "react-modal";

import EduApi from "../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";

export default class LocationDetailsModal extends React.Component {
  state = {
    errorMessage: "",
    loading: false,
    locationDetails: null,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  componentDidUpdate(prevProps) {
    const { locationId, show } = this.props;
    if (!prevProps.show && show && locationId) {
      this.getLocationDetails(locationId);
    }
  }

  getLocationDetails = async (locationId) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        locationDetails: null,
      },
      () => {
        EduApi.getEduChabadHouse(this.apiSignal.token, locationId)
          .then((locationDetails) => {
            this.setState({
              loading: false,
              locationDetails,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                locationDetails: null,
              });
            }
          });
      },
    );
  };

  onClose = () => {
    this.setState({
      errorMessage: "",
      loading: false,
      locationDetails: null,
    });

    this.props.close();
  };

  render() {
    const { show } = this.props;
    const { errorMessage, loading, locationDetails } = this.state;

    const {
      campusID,
      campusName,
      chabadHouseID,
      chabadHouseName,
      hasScheduledAnyCourse,
      id: locationId,
      shliachCell,
      shliachEmail,
      shliachFirstName,
      shliachID,
      shliachLastName,
      shluchaCell,
      shluchaEmail,
      shluchaFirstName,
      shluchaID,
    } = locationDetails || {};

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card locations-shliach-details-modal">
          {loading ? (
            <Loader />
          ) : errorMessage ? (
            <p className="error-text">{errorMessage}</p>
          ) : (
            <React.Fragment>
              <div className="flex flex-justify-space mb-16">
                <div>
                  <div className="xl-text fw-700 flex flex-align-center mb-4">
                    {[shliachFirstName, shluchaFirstName]
                      .filter((n) => n)
                      .join(" / ")}{" "}
                    {shliachLastName}
                    <Link to={`/shluchim/${shliachID || shluchaID}`}>
                      <i className="accent-text-secondary link-text material-icons ml-16">
                        open_in_new
                      </i>
                    </Link>
                  </div>
                  <Link
                    className="accent-text-dark medium-text"
                    to={`/shluchim/${
                      shliachID || shluchaID
                    }/chabadHouse/${chabadHouseID}`}
                  >
                    {chabadHouseName}
                  </Link>
                </div>
                <i
                  className="material-icons link-text-secondary"
                  onClick={this.onClose}
                >
                  close
                </i>
              </div>
              <div className="locations-shliach-contact-details">
                <div className="two-column-grid">
                  {!!shliachID && (
                    <div>
                      <div className="flex mb-8">
                        <label className="fw-500">Shliach Email</label>
                        <a
                          href={`mailto:${shliachEmail}`}
                          className="accent-text-secondary link-text"
                        >
                          {shliachEmail}
                        </a>
                      </div>
                      <div className="flex mb-8">
                        <label className="fw-500">Shliach Phone</label>
                        <a
                          href={`tel:${shliachCell}`}
                          className="accent-text-secondary link-text"
                        >
                          {shliachCell}
                        </a>
                      </div>
                    </div>
                  )}
                  {!!shluchaID && (
                    <div>
                      <div className="flex mb-8">
                        <label className="fw-500">Shlucha Email</label>
                        <a
                          href={`mailto:${shluchaEmail}`}
                          className="accent-text-secondary link-text"
                        >
                          {shluchaEmail}
                        </a>
                      </div>
                      <div className="flex mb-8">
                        <label className="fw-500">Shlucha Phone</label>
                        <a
                          href={`tel:${shluchaCell}`}
                          className="accent-text-secondary link-text"
                        >
                          {shluchaCell}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                <div className="two-column-grid">
                  <div className="flex mb-8">
                    <label className="fw-500">Campus</label>
                    <Link
                      className="link-text-secondary"
                      to={`/schools/${campusID}`}
                    >
                      {campusName}
                    </Link>
                  </div>
                </div>
              </div>
              {hasScheduledAnyCourse && (
                <LocationCoursesTable locationId={locationId} />
              )}
            </React.Fragment>
          )}
        </div>
      </Modal>
    );
  }
}
