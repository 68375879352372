import React from "react";
import Loader from "../common/Loader";

export default class PaginatedTable extends React.Component {
  state = {
    scrollPage: 1,
  };

  componentDidMount() {
    const { loadData, page, results } = this.props;
    loadData(page, results);
  }

  getPaginationFooter = () => {
    const { loading, page: pageProp, results, totalCount } = this.props;

    let numberOfPages = Math.ceil((totalCount || 0) / results);
    if (numberOfPages < 2) {
      return null;
    }
    let pagesArray = Array(numberOfPages)
      .fill()
      .map((_, i) => i + 1);
    let page = parseInt(pageProp, 10);
    if (pagesArray.length > 10) {
      let pagesArrayCopy = pagesArray.slice(0, pagesArray.length);
      let startPg = pagesArrayCopy.shift();
      let endPg = pagesArrayCopy.pop();
      if (page < 7 && page !== 6) {
        pagesArray = pagesArray.slice(0, 6);
        pagesArray.push("afterPages", endPg);
      } else if (page === 6) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else if (page + 4 < endPg) {
        pagesArray = pagesArray.slice(page - 3, page + 2);
        pagesArray.unshift(startPg, "beforePages");
        pagesArray.push("afterPages", endPg);
      } else {
        pagesArray = pagesArray.slice(pagesArray.length - 6, pagesArray.length);
        pagesArray.unshift(startPg, "beforePages");
      }
    } else {
      pagesArray = pagesArray.slice(0, 10);
    }

    return (
      <div className={`pagination ${loading ? "disabled" : ""}`}>
        {page > 1 ? (
          <i
            className="material-icons mr-24 medium-text link-text-secondary"
            onClick={this.paginate.bind(this, page - 1)}
          >
            keyboard_arrow_left
          </i>
        ) : null}

        <ul>
          {pagesArray.map((pageNumber) =>
            typeof pageNumber === "number" ? (
              <li
                key={pageNumber}
                className={
                  this.props.page.toString() === pageNumber.toString()
                    ? "active"
                    : ""
                }
                onClick={() => this.paginate(pageNumber)}
              >
                {pageNumber}
              </li>
            ) : (
              <li key={pageNumber}>...</li>
            ),
          )}
        </ul>

        {page === numberOfPages ? null : (
          <i
            className="material-icons ml-24 medium-text link-text-secondary"
            onClick={this.paginate.bind(this, page + 1)}
          >
            keyboard_arrow_right
          </i>
        )}
      </div>
    );
  };

  getResultsView() {
    const { loading, results, sortOptions, totalCount } = this.props;

    let resultsOptions = Array(5)
      .fill()
      .map((_, i) => ++i * 12);

    return (
      totalCount > resultsOptions[0] && (
        <div className="flex flex-align-center">
          {!!sortOptions && <p className="ml-16 mr-16">|</p>}
          <p>View</p>
          <select
            className="no-border-select accent-text-secondary ml-8"
            disabled={loading}
            onChange={(event) => this.changeResults(event.target.value)}
            name="results"
            value={results}
          >
            {resultsOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )
    );
  }

  paginate = (page) => {
    const { loadData, loading, results, sortBy } = this.props;

    if (loading) {
      return;
    }

    // this.pushToRoute(page, results);

    loadData(page, results, sortBy);

    this.scrollToTop();
  };

  sort = (sortBy) => {
    const { loadData, loading, results } = this.props;

    if (loading) {
      return;
    }

    loadData(1, results, sortBy);

    this.scrollToTop();
  };

  changeResults = (resultsUpdate) => {
    const { loadData, loading, sortBy } = this.props;

    if (loading) {
      return;
    }

    // this.pushToRoute(pageUpdate, results);

    loadData(1, resultsUpdate, sortBy);

    this.scrollToTop();
  };

  scrollToTop = () => {
    const scrollWindow = document.getElementById("scroll-window");
    if (scrollWindow) {
      scrollWindow.scrollTo({ left: 0, top: 0 });
    }
  };

  //Mobile Infinite Scroll
  // pushToRoute = (page, results) => {
  //     console.log(this.props);
  //     const {
  //         location: { query, pathname },
  //     } = this.props;

  //     const search = queryString.stringify({
  //         ...query,
  //         p: page,
  //         r: results
  //     });

  //     browserHistory.replace({
  //         pathname,
  //         search: `?${search}`
  //     });
  // }

  // onScroll = (event) => { //TODO - are we doing the mobile infinite scoll thing???
  //     const { loadData, loading, page, results, sortBy, totalCount } = this.props;

  //     const hasMore =
  //         page < Math.ceil((totalCount || 0) / results);

  //     if (
  //         !loading &&
  //         hasMore &&
  //         event.target.scrollHeight -
  //         event.target.clientHeight -
  //         event.target.scrollTop <=
  //         100
  //     ) {
  //         this.setState(state => {
  //             loadData(
  //                 state.scrollPage + 1,
  //                 results,
  //                 sortBy,
  //                 true
  //             );
  //             return {
  //                 ...state,
  //                 scrollPage: state.scrollPage + 1
  //             };
  //         });
  //     }
  // };

  render() {
    const { scrollPage } = this.state;
    const {
      filterComponent,
      loading,
      noRecordsDisplay,
      preHeaderMargin,
      records,
      renderHeader,
      renderRecords,
      renderRow,
      showResultsCount,
      showResultsView,
      sortBy,
      sortOptions,
      sortControlClassName = "",
      success,
      totalCount,
    } = this.props;
    const mobileMode = false; //TODO - No mobile mode implementation for now.

    return (
      <div>
        <div
          className={`flex flex-justify-space flex-align-center mb-${preHeaderMargin || 24
            }`}
        >
          {filterComponent || <div />}
          <div className={`flex ${sortControlClassName}`}>
            {sortOptions && (
              <div className="flex flex-align-center">
                <p>Sort by</p>
                <select
                  className="no-border-select accent-text-secondary ml-8"
                  disabled={loading}
                  onChange={(event) => this.sort(event.target.value)}
                  name="sortBy"
                  value={sortBy}
                >
                  {sortOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {showResultsView ? this.getResultsView() : null}
          </div>
        </div>
        <div className="table-header">{renderHeader()}</div>
        {loading && (!mobileMode || scrollPage === 1) ? (
          <Loader />
        ) : !records.length ? (
          <div className="empty-state">
            <img src="/img/no_results.svg" alt="no results" height="200" />
            {!success ? (
              <React.Fragment>
                <p className="xxl-text mt-24 mb-16">An error has occured</p>
                <p className="medium-text accent-text fw-500">
                  Our server is being stubborn, please try again
                </p>
              </React.Fragment>
            ) : (
              noRecordsDisplay || (
                <React.Fragment>
                  <p className="xxl-text mt-24 mb-16">No results found</p>
                  <p className="medium-text accent-text fw-500">
                    Try adjusting your search terms
                  </p>
                </React.Fragment>
              )
            )}
          </div>
        ) : (
          <React.Fragment>
            <div className={mobileMode ? "table full-width-table" : "table"}>
              <div
                className={mobileMode ? "table-rows" : null}
              // onScroll={mobileMode ? this.onScroll : null}
              >
                {renderRecords
                  ? renderRecords(records)
                  : records.map((record, index) => renderRow(record, index))}

                {mobileMode && loading && (
                  <div className="mobile-loader">Loading...</div>
                )}
              </div>
            </div>
            <div className="flex flex-align-center flex-justify-space">
              {!!showResultsCount ? (
                <p className="accent-text-dark mt-24">
                  {totalCount} Result{totalCount === 1 ? "" : "s"}
                </p>
              ) : (
                <div />
              )}
              {!mobileMode && records.length > 0 && this.getPaginationFooter()}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
