import React from "react";

// import { EventTypes, EventCategories } from "../../../models/EventCategories";
import { DatePickerWrapper } from "../DatePickerWrapper";
import eventDescriptor from "../../../models/EventDescriptor";

const renderInputsForEventType = (inputs, handleInputChange, inputValues) => {
  const inputTypeRenderers = {
    dropdown: (input) => (
      <div className="form-group" key={input.name}>
        <label>{input.label}</label>
        <select
          required={true}
          className="form-control"
          defaultValue={inputValues[input.name]}
          onChange={handleInputChange(input.name)}
        >
          <option disabled={true} value="">
            Select an option
          </option>
          {input.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    ),
    text: (input) => (
      <div className="form-group" key={input.name}>
        <label>{input.label}</label>
        <input
          required={true}
          className="form-control"
          defaultValue={inputValues[input.name]}
          onChange={handleInputChange(input.name)}
          type="text"
        />
      </div>
    ),
    textarea: (input) => (
      <div className="form-group" key={input.name}>
        <label>{input.label}</label>
        <textarea
          required={true}
          className="form-control"
          defaultValue={inputValues[input.name]}
          onChange={handleInputChange(input.name)}
          rows={3}
        />
      </div>
    ),
    money: (input) => (
      <div className="form-group" key={input.name}>
        <label>{input.label}</label>
        <div className="input-group">
          <span className="input-group-addon">$</span>
          <input
            required={true}
            className="form-control currency"
            defaultValue={inputValues[input.name]}
            onChange={handleInputChange(input.name)}
            min="0"
            step="0.01"
            type="number"
            style={{ zIndex: 0 }}
          />
        </div>
      </div>
    ),
    date: (input) => (
      <div className="form-group" key={input.name}>
        <label>{input.label}</label>
        <div>
          <DatePickerWrapper
            input={input}
            selectedDate={inputValues[input.name]}
            inputChangeHandler={handleInputChange}
          />
        </div>
      </div>
    ),
    toggle: (input) => (
      <div className="form-group" key={input.name}>
        <label>
          <input
            type="checkbox"
            defaultChecked={inputValues[input.name]}
            onChange={handleInputChange(input.name)}
          />{" "}
          {input.label}
        </label>
      </div>
    ),
  };

  return (
    <div>
      {inputs &&
        inputs.map((input) => (
          <div key={input.name} style={{ maxWidth: "200px" }}>
            {inputTypeRenderers[input.type](input)}
          </div>
        ))}
    </div>
  );
};

// interface EventLayoutProps {
//   eventType: EventTypes;
//   customEventCategory: EventCategories;
//   onInputChange: (inputName: string) => void;
//   inputValues?;
// }

const EventLayout = ({
  eventType,
  customEventCategory,
  onInputChange,
  inputValues,
}) => (
  <div>
    {renderInputsForEventType(
      (customEventCategory >= 1000
        ? eventDescriptor[customEventCategory]
        : eventDescriptor[eventType]
      ).inputs,
      onInputChange,
      inputValues,
    )}
  </div>
);

export default EventLayout;
