import React from "react";
import * as Sentry from "@sentry/react";

const { NODE_ENV = "development", REACT_APP_VERSION = "0.0.0" } = process.env;

// const __DEV__ = NODE_ENV === "development";

let user;

/** See https://docs.sentry.io/learn/configuration */
Sentry.init({
  /** Where to send errors. This is NOT a secret. */
  dsn: "https://1acbd77e627249508cc29ba96e2227b3@sentry.io/1398649",
  environment: NODE_ENV,
  release: REACT_APP_VERSION,
  /** Do not send errors in development. */
  beforeSend: true // __DEV__ // temp: do not send any errors, more config needed
    ? function () {
        return null;
      }
    : function (event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        // we need to do this manually because of this issue https://github.com/getsentry/sentry-javascript/issues/3286#issuecomment-786981634
        if (event.exception) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user,
          });
        }
        return event;
      },
});

export function setSentryUser(accountId, person, email) {
  user = {
    accountId,
    personId: person.id,
    name: `${person.firstName} ${person.lastName}`,
    email,
  };
  Sentry.setUser(user);
}

export function clearSentryUser() {
  Sentry.setUser(null);
}

/**
 * Error boundary component that reports errors to https://sentry.io
 * NOTE: You don't _need_ to render this error boundary to report errors.
 * Error boundaries are primarily for catching React _rendering_ errors and
 * showing an error result UI.
 */

function ErrorFallback() {
  return (
    <div className="error-page">
      <img src="/img/error.svg" alt="error robot" height="240" />
      <div className="text text-center">
        <h2>Something's gone wrong</h2>
        <p>Our team has been notified</p>
        <div className="flex flex-justify-center">
          <button
            className="btn btn-medium btn-cancel mr-16"
            style={{ fontSize: "12px", padding: "0 8px" }}
            onClick={() => Sentry.showReportDialog()}
          >
            Report feedback
          </button>
          <a
            style={{ fontSize: "12px", padding: "0 8px" }}
            href="/"
            className="btn btn-accent btn-medium"
          >
            Take me home
          </a>
        </div>
      </div>
    </div>
  );
}

export function ErrorSentry(props) {
  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback} dialogOptions={{ user }}>
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
