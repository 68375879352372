import React from "react";
import { Link } from "react-router";
import { formatCurrency, formatNumber } from "../../lib";
import moment from "moment";

function formatTotalBreakdown(
  locale,
  currencyCode,
  total,
  ticketTotal,
  donatedProcessingFeeTotal,
) {
  return (
    <p>
      {locale} {formatCurrency(total, currencyCode)}{" "}
      {donatedProcessingFeeTotal > 0 &&
        `(${formatCurrency(ticketTotal, currencyCode)} + ${formatCurrency(
          donatedProcessingFeeTotal,
          currencyCode,
        )})`}
    </p>
  );
}

export default ({ history }) => (
  <div className="raffles-history-card">
    <p className="xl-text fw-700 mb-16">History</p>
    <div className="raffles-history-table">
      <div className="raffles-history-table-header">
        <p>Year</p>
        <p>Grand Draw Dates</p>
        <p>Participating Chabad Houses</p>
        <p>Tickets Sold</p>
        <p>Total Raised (Ticket Costs + Donated CC Fees)</p>
        <p>Total Fees Collected</p>
        <p>Net Total</p>
      </div>

      {history.length ? (
        history.map((raffle, index) => (
          <Link
            key={index}
            className="raffles-history-table-row"
            to={`/grand-draw/${raffle.programScheduleID}`}
          >
            <p>{raffle.gregorianYear}</p>
            <p>
              {moment(raffle.startDate).format("MMMM DD")} -{" "}
              {moment(raffle.endDate).format("MMMM DD")}
            </p>
            <div>
              <p>{formatNumber(raffle.chabadHouseCount)}</p>
              <p className="accent-text">
                {formatNumber(raffle.chabadHouseWithTicketsCount)} sold tickets
              </p>
            </div>
            <p>{formatNumber(raffle.ticketsSoldCount)}</p>
            <div>
              <div>
                {formatTotalBreakdown(
                  "",
                  "USD",
                  raffle.totalRaised,
                  raffle.totalTicketAmountRaised,
                  raffle.totalProcessingFeeDonorAmountRaised,
                )}
              </div>
              {raffle.totalRaised > 0 && (
                <div className="accent-text">
                  {formatTotalBreakdown(
                    "USD",
                    "USD",
                    raffle.usdTotal,
                    raffle.usdTotalTicketPriceAmount,
                    raffle.usdTotalProcessingFeeDonorAmount,
                  )}
                  {formatTotalBreakdown(
                    "",
                    "CAD",
                    raffle.cadTotal,
                    raffle.cadTotalTicketPriceAmount,
                    raffle.cadTotalProcessingFeeDonorAmount,
                  )}
                  {formatTotalBreakdown(
                    "GBP",
                    "GBP",
                    raffle.gbpTotal,
                    raffle.gbpTotalTicketPriceAmount,
                    raffle.gbpTotalProcessingFeeDonorAmount,
                  )}
                </div>
              )}
            </div>
            <div>
              <p>{formatCurrency(raffle.feesCollected, "USD")}</p>
              {raffle.feesCollected > 0 && (
                <div className="accent-text">
                  <p>
                    Ticket Fees:{" "}
                    {formatCurrency(raffle.cociFeesCollected, "USD")}
                  </p>
                  <p>
                    Processing Fees:{" "}
                    {formatCurrency(raffle.ccProcessingFeesCollected, "USD")}
                  </p>
                </div>
              )}
            </div>
            <p>{formatCurrency(raffle.netTotal, "USD")}</p>
          </Link>
        ))
      ) : (
        <p className="no-history">No Previous Grand Draws Found</p>
      )}
    </div>
  </div>
);
