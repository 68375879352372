import React from "react";

const enrollmentStatuses = {
  Active: "Active",
  FailedRenewal: "FailedRenewal",
  PendingActivation: "PendingActivation",
  PendingTermination: "PendingTermination",
  Terminated: "Terminated",
};

export default ({ status, statusDisplay }) => {
  switch (status) {
    case enrollmentStatuses.Active:
      return <span className="relative active ml-16">{statusDisplay}</span>;
    case enrollmentStatuses.Terminated:
      return <span className="relative inactive ml-16">{statusDisplay}</span>;
    case enrollmentStatuses.FailedRenewal:
    case enrollmentStatuses.PendingActivation:
    case enrollmentStatuses.PendingTermination:
      return (
        <span className="error-text flex flex-align-center">
          <i className="material-icons large-text mr-8">info_outline</i>
          {statusDisplay}
        </span>
      );
    default:
      return "";
  }
};
