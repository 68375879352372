import React from "react";

import { validateEmail } from "../../../../lib/utils";

import GeneralInfo from "./GeneralInfo";
import AddressInfo from "./AddressInfo";
import SchoolsInfo from "./SchoolsInfo";
import ContactInfo from "./ContactInfo";
import SocialInfo from "./SocialInfo";
import ShluchimInfo from "./ShluchimInfo";
import PersonnelInfo from "./PersonnelInfo";

export default class GeneralForm extends React.PureComponent {
  require = (value) => (!value && value !== false && value !== 0 ? true : "");

  hasUSAddress = (address) => address && address.country === "USA";

  validateDemographics = (value) => {
    if (value.length < 1) return true;
  };

  validateContactMethod = (value, contactMethod) => {
    if (!value) return true;
    if (contactMethod.type === "Email") {
      return !validateEmail(value) ? "Invalid Email" : "";
    }
  };

  render() {
    const {
      campuses,
      errors,
      handleChange,
      setFieldValue,
      setFieldError,
      sys,
      touched,
      shliachID,
      initialValues,
      values,
    } = this.props;
    return (
      <div className="profile-form-sections">
        <div className="profile-form-section">
          <p className="accent-text small-text line-height-double">
            Review and edit general Chabad House information. This information
            will be displayed on the public directory @
            chabadoncampus.org/directory.
          </p>
        </div>
        <GeneralInfo
          errors={errors}
          hasUSAddress={this.hasUSAddress}
          require={this.require}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          initialValues={initialValues}
          values={values}
          handleChange={handleChange}
          validateDemographics={this.validateDemographics}
        />
        <AddressInfo
          errors={errors}
          handleChange={handleChange}
          hasUSAddress={this.hasUSAddress}
          require={this.require}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          shliachID={shliachID}
          values={values}
        />
        <SchoolsInfo
          campuses={campuses}
          errors={errors}
          handleChange={handleChange}
          require={this.require}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          values={values}
        />
        <ContactInfo
          errors={errors}
          require={this.require}
          setFieldError={setFieldError}
          setFieldValue={setFieldValue}
          sys={sys}
          touched={touched}
          values={values}
          validateContactMethod={this.validateContactMethod}
        />
        {/* TODO redo social info */}
        {/* <SocialInfo errors={errors} require={this.require} touched={touched} /> */}
        <ShluchimInfo
          errors={errors}
          require={this.require}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          sys={sys}
        />
        <PersonnelInfo
          errors={errors}
          require={this.require}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
          sys={sys}
        />
      </div>
    );
  }
}
