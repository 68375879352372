import React, { useEffect, useState } from "react";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import DateTimePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import ValidatedInput from "../../../shared/ValidatedInput";
import { removeTimezoneFormatFromDate } from "../../../../lib";
import moment from "moment";

function PriceInCredits(props) {
  const {
    creditValue,
    disabled,
    name,
    onChangePrice,
    price,
    required,
    submitAttempted,
  } = props;

  const [credits, setCredits] = useState(
    price && creditValue ? price / creditValue : ""
  );

  useEffect(() => {
    if (!creditValue) {
      setCredits("");
    }
    // update price when credits or creditValue changes
    onChangePrice(name, credits * creditValue);

    // eslint-disable-next-line
  }, [credits, creditValue]);

  return (
    <div className="relative">
      <ValidatedInput
        input={
          <NumberFormat
            allowNegative={false}
            disabled={disabled || !creditValue}
            onValueChange={({ floatValue }) =>
              setCredits(floatValue >= 0 ? floatValue : "")
            }
            decimalScale={0}
            style={{ paddingRight: "24px" }}
            thousandSeparator={true}
          />
        }
        name={name}
        required={required}
        showRequired={submitAttempted}
        value={credits}
      />
      <span
        className="accent-text small-text"
        style={{
          position: "absolute",
          bottom: "5px",
          right: "5px",
        }}
      >
        ${(price || 0).toLocaleString()} Value
      </span>
    </div>
  );
}

export default class StudentsPricingDetails extends React.Component {
  state = {
    showClearEarlyBirdConfirmationModal: false,
  };

  onChangePrice = (name, value) => {
    const {
      isJewishUTrip,
      onChange,
      priceDetails: { earlyBirdStudentDeadline, earlyBirdStudentPrice },
    } = this.props;

    if (
      !isJewishUTrip &&
      name.indexOf("regularStudentPrice") >= 0 &&
      value === 0 &&
      (earlyBirdStudentDeadline || earlyBirdStudentPrice)
    ) {
      this.setState({
        showClearEarlyBirdConfirmationModal: true,
      });
    } else {
      onChange(name, value);
    }
  };

  onConfirmClearEarlyBird = () => {
    const { onChange, trackFieldPrefix } = this.props;

    onChange(`${trackFieldPrefix}.regularStudentPrice`, 0, {
      [`${trackFieldPrefix}.earlyBirdStudentPrice`]: "",
      [`${trackFieldPrefix}.earlyBirdStudentDeadline`]: "",
    });

    this.setState({ showClearEarlyBirdConfirmationModal: false });
  };

  render() {
    const {
      hasStudentRegistrations,
      isJewishUTrip,
      isTravelTrip,
      onChange,
      priceDetails: {
        earlyBirdStudentDeadline,
        earlyBirdStudentPrice,
        rewardCreditValue,
        regularStudentPrice,
        studentDepositAmount,
      },
      trackFieldPrefix,
      submitAttempted,
    } = this.props;

    const { showClearEarlyBirdConfirmationModal } = this.state;

    const isDefaultTrack = trackFieldPrefix?.endsWith("[0]");

    return (
      <div className="program-settings-page-section">
        <p className="medium-text fw-700 mb-16">Students</p>
        <div className="two-column-grid pricing-two-column-grid">
          {isJewishUTrip && isDefaultTrack && (
            <div className="flex flex-align-center">
              <label className="input-label flex">
                Credit value
                <span className="tooltip-container">
                  <i className="material-icons large-text ml-8">info</i>
                  <span className="tooltip">$ value of a single credit</span>
                </span>
              </label>
              <div className="tooltip-container" style={{ height: "auto" }}>
                <ValidatedInput
                  input={
                    <NumberFormat
                      allowNegative={false}
                      className="dollar-input"
                      disabled={hasStudentRegistrations}
                      onValueChange={({ floatValue }) =>
                        onChange(
                          "rewardCreditValue",
                          floatValue >= 0 ? floatValue : ""
                        )
                      }
                      decimalScale={0}
                      thousandSeparator={true}
                    />
                  }
                  name="rewardCreditValue"
                  required={true}
                  showRequired={submitAttempted}
                  value={rewardCreditValue}
                />
                {hasStudentRegistrations && (
                  <span className="tooltip">
                    Credit value cannot be adjusted once students have
                    registered
                  </span>
                )}
              </div>
            </div>
          )}
          <div className="flex flex-align-center">
            <label className="input-label">
              Trip Fee{isJewishUTrip ? " (# Credits)" : ""}
            </label>
            <div className="tooltip-container" style={{ height: "auto" }}>
              {isJewishUTrip ? (
                <PriceInCredits
                  creditValue={rewardCreditValue}
                  disabled={hasStudentRegistrations}
                  name={`${trackFieldPrefix}.regularStudentPrice`}
                  onChangePrice={this.onChangePrice}
                  price={regularStudentPrice}
                  required={true}
                  submitAttempted={submitAttempted}
                />
              ) : (
                <ValidatedInput
                  input={
                    <NumberFormat
                      allowNegative={false}
                      disabled={hasStudentRegistrations}
                      className="dollar-input"
                      onValueChange={({ floatValue }) =>
                        this.onChangePrice(
                          `${trackFieldPrefix}.regularStudentPrice`,
                          floatValue >= 0 ? floatValue : ""
                        )
                      }
                      decimalScale={2}
                      thousandSeparator={true}
                    />
                  }
                  name={`${trackFieldPrefix}.regularStudentPrice`}
                  required={true}
                  showRequired={submitAttempted && regularStudentPrice !== 0}
                  value={regularStudentPrice}
                />
              )}
              {hasStudentRegistrations && (
                <span className="tooltip">
                  Trip fee cannot be adjusted once students have registered
                </span>
              )}
            </div>
          </div>
          {isTravelTrip ? (
            <div className="flex flex-align-center">
              <label className="input-label">Deposit</label>
              <ValidatedInput
                input={
                  <NumberFormat
                    allowNegative={false}
                    className="dollar-input"
                    onValueChange={({ floatValue }) =>
                      this.onChangePrice(
                        `${trackFieldPrefix}.studentDepositAmount`,
                        floatValue >= 0 ? floatValue : ""
                      )
                    }
                    decimalScale={2}
                    thousandSeparator={true}
                  />
                }
                name={`${trackFieldPrefix}.studentDepositAmount`}
                required={isTravelTrip}
                showRequired={submitAttempted}
                value={studentDepositAmount}
              />
            </div>
          ) : (
            <div />
          )}
          {!isJewishUTrip && (
            <>
              <div className="flex flex-align-center">
                <label className="input-label">Early Bird Trip Fee</label>
                <div className="tooltip-container" style={{ height: "auto" }}>
                  <ValidatedInput
                    input={
                      <NumberFormat
                        allowNegative={false}
                        className="dollar-input"
                        disabled={
                          regularStudentPrice === 0 || hasStudentRegistrations
                        }
                        isAllowed={({ floatValue }) => floatValue !== 0}
                        onValueChange={({ floatValue }) =>
                          this.onChangePrice(
                            `${trackFieldPrefix}.earlyBirdStudentPrice`,
                            floatValue >= 0 ? floatValue : ""
                          )
                        }
                        decimalScale={2}
                        thousandSeparator={true}
                      />
                    }
                    name={`${trackFieldPrefix}.earlyBirdStudentPrice`}
                    required={!!earlyBirdStudentDeadline}
                    showRequired={submitAttempted}
                    value={earlyBirdStudentPrice}
                  />
                  {regularStudentPrice === 0 ? (
                    <span className="tooltip">
                      Early Bird Trip Fee is disabled when Trip Fee is $0
                    </span>
                  ) : hasStudentRegistrations ? (
                    <span className="tooltip">
                      Trip fee cannot be adjusted once students have registered
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex flex-align-center">
                <label className="input-label">Early Bird Ends</label>
                <div
                  className={
                    regularStudentPrice === 0 ? "tooltip-container" : ""
                  }
                  style={{ height: "auto" }}
                >
                  <DateTimePicker
                    className={`custom-input ${
                      submitAttempted &&
                      earlyBirdStudentPrice &&
                      !earlyBirdStudentDeadline
                        ? "error"
                        : ""
                    }`} //= required={true}
                    dateFormat="MM/DD/YYYY h:mm a"
                    disabled={regularStudentPrice === 0}
                    onChange={(dateTime) =>
                      this.onChangePrice(
                        `${trackFieldPrefix}.earlyBirdStudentDeadline`,
                        removeTimezoneFormatFromDate(dateTime)
                      )
                    }
                    selected={
                      earlyBirdStudentDeadline
                        ? moment(earlyBirdStudentDeadline)
                        : null
                    }
                    showTimeSelect={true}
                    timeIntervals={1}
                  />
                  {regularStudentPrice === 0 && (
                    <span className="tooltip">
                      Early Bird Deadline is disabled when Trip Fee is $0
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        <ConfirmationModal
          cancel={() =>
            this.setState({ showClearEarlyBirdConfirmationModal: false })
          }
          confirm={this.onConfirmClearEarlyBird}
          message="Setting Students Trip Fee to $0 will clear out your Students Early Bird Trip Fee settings.  Are you sure you want to continue?"
          show={showClearEarlyBirdConfirmationModal}
        />
      </div>
    );
  }
}
