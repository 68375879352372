import React from "react";
import ExportCSVButton from "../../../shared/ExportCSVButton";
import Loader from "../../../common/Loader";
import PieChart from "./MetricsPieChart";
import YearOverYearComparisonIntervalChart from "../../../shared/YearOverYearComparisonIntervalChart";
import TransportationMetrics from "./TransportationMetrics";

import TripEventsApi from "../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { formatNumber, pluralizeText } from "../../../../lib";
import axios from "axios";
import moment from "moment";

export default class TripMetrics extends React.PureComponent {
  state = {
    errorMessage: "",
    loading: false,
    metrics: {},
    success: true,
    registrationsYearOverYear: [],
    includeAllEventsInComparisonChart: false,
    yearOverYearChartErrorMessage: null,
    yearOverYearChartLoading: false,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getMetrics();
    this.getRegistrationsOverTimeComparison();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getMetrics = () => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        success: true,
      },
      () => {
        TripEventsApi.getTripMetrics(
          this.apiSignal.token,
          this.props.tripEvent.id
        )
          .then((metrics) => {
            this.setState({
              loading: false,
              metrics,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
              });
            }
          });
      }
    );
  };

  getTripExport = async (exportType) => {
    return await TripEventsApi.getTripReport(
      this.apiSignal.token,
      this.props.tripEvent.id,
      exportType
    );
  };

  getTourSchedulesExportHeaders = () => {
    const getTransportationCounts = (studentCount, chaperoneCount, busSize) => {
      const totalAttendees = studentCount + chaperoneCount;
      const busCount = Math.ceil((studentCount + chaperoneCount) / busSize);
      return `${totalAttendees} (${
        busSize
          ? `${busCount} ${pluralizeText("bus", busCount, "busses")}`
          : "Bus Size not set"
      })`;
    };
    return [
      { label: "Tour Name", value: "tourName" },
      {
        label: "Tour Schedule Name",
        value: "tourScheduleName",
      },
      { label: "Price", value: "price" },
      {
        label: "Included in Trip Price",
        value: ({ isPriceIncludedInTripPrice }) =>
          isPriceIncludedInTripPrice ? "Y" : "N",
      },
      {
        label: "Date & Time",
        value: ({ time }) => moment(time).format("MM/DD/YYYY hh:mm A"),
      },
      { label: "Duration Hours", value: "durationHours" },
      { label: "Duration Minutes", value: "durationMinutes" },
      {
        label: "Maximum Participants",
        value: "maximumParticipants",
      },
      {
        label: "Total Attendees Count",
        value: "allAttendingCount",
      },
      {
        label: "Chaperones/Shluchim Count",
        value: "chaperoneCount",
      },
      { label: "Student Count", value: "studentCount" },
      {
        label: "Total Groups",
        value: ({ groupSize, studentCount, chaperoneCount }) =>
          groupSize
            ? Math.ceil((studentCount + chaperoneCount) / groupSize)
            : "Group size not set",
      },
      {
        label: "Transportation To Count",
        value: ({ studentTransToCount, chapTransToCount, busSize }) =>
          getTransportationCounts(
            studentTransToCount,
            chapTransToCount,
            busSize
          ),
      },
      {
        label: "Transportation From Count",
        value: ({
          studentTransFromCount,
          chapTransFromCount,
          busSize,
          isOhelVisit,
        }) =>
          isOhelVisit
            ? "N/A"
            : getTransportationCounts(
                studentTransFromCount,
                chapTransFromCount,
                busSize
              ),
      },
      {
        label: "Transportation From Ohel -> CH Count",
        value: ({
          studentTransReturnCHCount,
          chapTransReturnCHCount,
          busSize,
          isOhelVisit,
        }) =>
          isOhelVisit
            ? getTransportationCounts(
                studentTransReturnCHCount,
                chapTransReturnCHCount,
                busSize
              )
            : "N/A",
      },
      {
        label: "Transportation From Ohel -> JFK Count",
        value: ({
          studentTransReturnJFKCount,
          chapTransReturnJFKCount,
          busSize,
          isOhelVisit,
        }) =>
          isOhelVisit
            ? getTransportationCounts(
                studentTransReturnJFKCount,
                chapTransReturnJFKCount,
                busSize
              )
            : "N/A",
      },

      {
        label: "Transportation From Ohel -> LGA Count",
        value: ({
          studentTransReturnLGACount,
          chapTransReturnLGACount,
          busSize,
          isOhelVisit,
        }) =>
          isOhelVisit
            ? getTransportationCounts(
                studentTransReturnLGACount,
                chapTransReturnLGACount,
                busSize
              )
            : "N/A",
      },
    ];
  };

  getRegistrationsOverTimeComparison = (
    includeAllEvents = this.state.includeAllEventsInComparisonChart
  ) => {
    this.setState(
      {
        yearOverYearChartErrorMessage: "",
        yearOverYearChartLoading: true,
        includeAllEventsInComparisonChart: includeAllEvents,
      },
      () => {
        TripEventsApi.getTripRegistrationsOverTimeComparison(
          this.apiSignal.token,
          this.props.tripEvent.id,
          includeAllEvents
        )
          .then((registrationsYearOverYear) => {
            this.setState({
              yearOverYearChartLoading: false,
              registrationsYearOverYear,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                yearOverYearChartErrorMessage: ApiCallErrorMessageHandler(err),
                yearOverYearChartLoading: false,
              });
            }
          });
      }
    );
  };

  render() {
    const {
      tripEvent: { programScheduleName, daysToStart, isTravelTrip },
      systemLists,
    } = this.props;
    const {
      errorMessage,
      loading,
      metrics,
      metrics: { event, studentClasses, tourSchedules, tracks },
      success,
      registrationsYearOverYear,
      includeAllEventsInComparisonChart,
      yearOverYearChartErrorMessage,
      yearOverYearChartLoading,
    } = this.state;

    const {
      allChaperonsCount,
      allStudentCount,
      campusCount,
      campusesWithStudentsCount,
      chabadHouseCount,
      chaperonPersonnelCount,
      chaperonPersonnelPercentage,
      chaperonShluchimCount,
      chaperonShluchimPercentage,
      chaperonShluchosCount,
      chaperonShluchosPercentage,
      checkedInCount,
      enrollmentsWithStudentsCount,
      femaleStudentCount,
      femaleStudentPercentage,
      maleStudentCount,
      maleStudentPercentage,
      otherChapeoronesCount,
      otherChapeoronesPercentage,
    } = event || {};

    return (
      <div className="trip-metrics">
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : !success ? (
          <div className="text-center">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p className="xxl-text mt-24 mb-16">An error has occured</p>
            <p className="medium-text accent-text fw-500">
              {errorMessage || "Our server is being stubborn, please try again"}
            </p>
          </div>
        ) : (
          Object.keys(metrics).length > 0 && (
            <>
              <div className="number-metrics">
                <div>
                  <p className="medium-text mb-16 flex flex-align-center">
                    <span className="xxl-text fw-700 mr-8">
                      {formatNumber(allStudentCount)}
                    </span>{" "}
                    {pluralizeText("Student", allStudentCount)} accepted
                  </p>
                  {!isTravelTrip && (
                    <p>
                      <span className="fw-900 mr-8 medium-text mb-0">
                        {formatNumber(checkedInCount)}
                      </span>{" "}
                      Checked in
                    </p>
                  )}
                </div>
                <div className="relative">
                  <p className="medium-text mb-16 flex flex-align-center">
                    <span className="xxl-text fw-700 mr-8">
                      {formatNumber(chabadHouseCount)}
                    </span>{" "}
                    {pluralizeText("Chabad house", chabadHouseCount)} enrolled
                  </p>
                  <p>
                    <span className="fw-900 mr-8 medium-text mb-0">
                      {formatNumber(enrollmentsWithStudentsCount)}
                    </span>{" "}
                    With students accepted
                  </p>
                </div>
                <div>
                  <p className="medium-text mb-16 flex flex-align-center">
                    <span className="xxl-text fw-700 mr-8">
                      {formatNumber(campusCount)}
                    </span>{" "}
                    {pluralizeText("School", campusCount)} enrolled
                  </p>
                  <p>
                    <span className="fw-900 mr-8 medium-text mb-0">
                      {formatNumber(campusesWithStudentsCount)}
                    </span>{" "}
                    With students accepted
                  </p>
                </div>
              </div>
              <div className="pie-chart-metrics">
                {!isTravelTrip && (
                  <div>
                    <p className="medium-text">
                      <span className="fw-900 mr-8">
                        {formatNumber(allChaperonsCount)}
                      </span>
                      {pluralizeText("Chaperone", allChaperonsCount)} with
                      accepted students
                    </p>
                    <PieChart
                      data={[
                        {
                          name: "Shluchim",
                          percentageValue: chaperonShluchimPercentage,
                          value: chaperonShluchimCount,
                        },
                        {
                          name: "Shluchos",
                          percentageValue: chaperonShluchosPercentage,
                          value: chaperonShluchosCount,
                        },
                        {
                          name: "Personnel",
                          percentageValue: chaperonPersonnelPercentage,
                          value: chaperonPersonnelCount,
                        },
                        {
                          name: "Other",
                          percentageValue: otherChapeoronesPercentage,
                          value: otherChapeoronesCount,
                        },
                      ]}
                    />
                  </div>
                )}
                <div>
                  <p className="fw-700 medium-text">
                    Accepted students by gender
                  </p>
                  <div>
                    <PieChart
                      data={[
                        {
                          name: "Male Students",
                          percentageValue: maleStudentPercentage,
                          value: maleStudentCount,
                        },
                        {
                          name: "Female Students",
                          percentageValue: femaleStudentPercentage,
                          value: femaleStudentCount,
                        },
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <p className="fw-700 medium-text">
                    Accepted students by class
                  </p>
                  <PieChart
                    data={studentClasses.map(
                      ({
                        classCount,
                        classDescription,
                        studentPercentage,
                      }) => ({
                        name: classDescription,
                        percentageValue: studentPercentage,
                        value: classCount,
                      })
                    )}
                  />
                </div>
                <div>
                  <p className="fw-700 medium-text">
                    Accepted students by track
                  </p>
                  <PieChart
                    data={tracks.map(
                      ({
                        trackName,
                        trackRegistrationCount,
                        trackRegistrationPercentage,
                      }) => ({
                        name: trackName,
                        percentageValue: trackRegistrationPercentage,
                        value: trackRegistrationCount,
                      })
                    )}
                  />
                </div>
              </div>
              {tourSchedules && tourSchedules.length > 0 && (
                <>
                  <div className="flex flex-justify-space flex-align-center mb-16">
                    <p className="fw-700 large-text">Tour Schedules</p>
                    <ExportCSVButton
                      className="custom-btn btn btn-accent uppercase-text"
                      fileName={`${(programScheduleName || "Trip").replace(
                        / /g,
                        "_"
                      )}_Tour_Schedules`}
                      getExportData={() => this.getTripExport("TourSchedules")}
                      getHeaders={this.getTourSchedulesExportHeaders}
                    />
                  </div>
                  <div className="tour-metrics">
                    {tourSchedules.map((tourSchedule) => {
                      const {
                        allAttendingCount,
                        chaperoneCount,
                        maximumParticipants,
                        studentCount,
                        tourName,
                        tourScheduleName,
                        tripTourScheduleID,
                        busSize,
                        groupSize,
                        studentTransToCount,
                        chapTransToCount,
                        studentTransFromCount,
                        chapTransFromCount,
                        studentTransReturnCHCount,
                        chapTransReturnCHCount,
                        studentTransReturnJFKCount,
                        chapTransReturnJFKCount,
                        studentTransReturnLGACount,
                        chapTransReturnLGACount,
                        isOhelVisit,
                      } = tourSchedule;

                      const attendees = studentCount + chaperoneCount;
                      const groupCount = Math.ceil(attendees / groupSize);

                      return (
                        <div key={tripTourScheduleID}>
                          <div>
                            <div className="flex flex-justify-space flex-align-center">
                              <p className="large-text fw-700 mb-8">
                                {tourName} - {tourScheduleName}
                              </p>
                              <p
                                className={
                                  allAttendingCount > maximumParticipants
                                    ? "error-text"
                                    : ""
                                }
                              >
                                {maximumParticipants > 0 &&
                                  `Max ${formatNumber(maximumParticipants)}`}
                              </p>
                            </div>
                            {studentCount > 0 ? (
                              <>
                                <p className="accent-text-dark mb-0">
                                  {formatNumber(studentCount)}{" "}
                                  {pluralizeText("Student", studentCount)} +{" "}
                                  {formatNumber(chaperoneCount)}{" "}
                                  {pluralizeText("Chaperone", chaperoneCount)}
                                  /Shluchim ={" "}
                                  <span className="fw-700">
                                    {attendees} Attendees
                                  </span>
                                </p>
                                {groupSize != null ? (
                                  <p className="accent-text-dark mb-0">
                                    {formatNumber(attendees)} Attendees ÷{" "}
                                    {groupSize} group slots ={" "}
                                    <span className="fw-700">
                                      {groupCount}{" "}
                                      {pluralizeText("Group", groupCount)}
                                    </span>
                                  </p>
                                ) : (
                                  <p className="accent-text-dark italic-text mb-0">
                                    Group size not set.
                                  </p>
                                )}
                              </>
                            ) : (
                              <p className="accent-text-dark italic-text mb-0">
                                There are no students registered.
                              </p>
                            )}
                          </div>
                          {studentCount > 0 && (
                            <div>
                              <TransportationMetrics
                                titleText="To"
                                studentCount={studentTransToCount}
                                chapsCount={chapTransToCount}
                                busSize={busSize}
                              />

                              {isOhelVisit ? (
                                <div>
                                  <TransportationMetrics
                                    titleText="From Ohel —> CH"
                                    studentCount={studentTransReturnCHCount}
                                    chapsCount={chapTransReturnCHCount}
                                    busSize={busSize}
                                  />
                                  <TransportationMetrics
                                    titleText="From Ohel —> JFK"
                                    studentCount={studentTransReturnJFKCount}
                                    chapsCount={chapTransReturnJFKCount}
                                    busSize={busSize}
                                  />
                                  <TransportationMetrics
                                    titleText="From Ohel —> LGA"
                                    studentCount={studentTransReturnLGACount}
                                    chapsCount={chapTransReturnLGACount}
                                    busSize={busSize}
                                  />
                                </div>
                              ) : (
                                <TransportationMetrics
                                  titleText="From"
                                  studentCount={studentTransFromCount}
                                  chapsCount={chapTransFromCount}
                                  busSize={busSize}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              <YearOverYearComparisonIntervalChart
                useCurrency={false}
                chartTitle="Registrations Over Time Year to Year Comparison"
                endDateDescription="Trip Start"
                eventType="trip"
                eventTitle={programScheduleName}
                data={registrationsYearOverYear}
                daysRemaining={daysToStart}
                errorMessage={yearOverYearChartErrorMessage}
                getData={this.getRegistrationsOverTimeComparison}
                loading={yearOverYearChartLoading}
                includeAllEvents={includeAllEventsInComparisonChart}
                intervals={systemLists.yearOverYearChartIntervals}
              />
            </>
          )
        )}
      </div>
    );
  }
}
