import React from "react";
import moment from "moment";
import { Link } from "react-router";

import DateRangePicker from "../../common/DateRangePicker";
import CsvGetAndDownload from "../../common/CSVGetAndDownload";
import DataTable from "../../common/DataTable";
// import { ContactType } from "../../Contact/ContactUtils";
// import { DataTableColumn } from "../../common/DataTable";
// import { ColumnConfig } from "../../common/CSVGetAndDownload";
// import { CustomEventTypes } from "../Utils";

// export interface DetailedTemplateSchoolsReportData {
//   name: string;
//   type: CustomEventTypes;
//   contactType: ContactType;
//   schools: Array<DetailedBySchoolObject>;
// }

// export interface DetailedBySchoolObject {
//   id: number;
//   name: string;
//   count: number;
// }

// interface ReportingDetailedTemplateSchoolsProps {
//   baseUrl: string;
//   eventText: string;
//   startDate: moment.Moment;
//   endDate: moment.Moment;
//   reportData: DetailedTemplateSchoolsReportData;
//   linksToDirectory: boolean;
//   handleDateInputChange: (field: string) => void;
// }

const ReportingDetailedTemplateSchools = ({
  baseUrl,
  eventText,
  startDate = moment.utc().subtract(2, "years"),
  endDate = moment.utc(),
  reportData,
  linksToDirectory,
  handleDateInputChange,
}) => {
  const summaryReportUrl = baseUrl.substr(0, baseUrl.indexOf("/reporting"));

  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-12">
          <ol className="breadcrumb">
            <li>
              <Link to={summaryReportUrl}>{eventText}</Link>
            </li>
            <li>
              <Link to={summaryReportUrl}>Reports</Link>
            </li>
            <li>Schools</li>
          </ol>
        </div>
        <div className="col-sm-10" style={{ marginBottom: "1em" }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            handleDateInputChange={handleDateInputChange}
          />
        </div>
        <div className="col-sm-2" style={{ marginBottom: "1em" }}>
          <CsvGetAndDownload
            dataToDownload={reportData.schools}
            columnDefinitions={columnsForCsv()}
            fileName={`${reportData.name}_Participants_${startDate.format(
              "YYYY-MM-DD",
            )}_${endDate.format("YYYY-MM-DD")}.csv`}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <DataTable
            fullClientSide={true}
            totalPageCount={0}
            columns={columnsForTable(reportData.contactType, linksToDirectory)}
            reportData={reportData.schools}
          />
        </div>
      </div>
    </div>
  );
};
/**
 *
 * @param {ContactType} contactType
 * @param {boolean} linksToDirectory
 * @returns {Array<DataTableColumn>}
 */
function columnsForTable(contactType, linksToDirectory) {
  return [
    {
      Header: "School",
      accessor: "name",
      Cell: (c) =>
        c.original.name &&
        (linksToDirectory ? (
          <Link to={`/schools/${c.original.id}`}>{c.original.name}</Link>
        ) : (
          c.original.name
        )),
    },
    {
      Header: "Count",
      accessor: "count",
      maxWidth: 120,
    },
  ];
}
/** @returns {Array<ColumnConfig>} */
function columnsForCsv() {
  return [
    {
      columnId: "name",
      header: "School",
    },
    {
      columnId: "count",
      header: "Count",
    },
  ];
}

export default ReportingDetailedTemplateSchools;
