import React from "react";
import SuggestionPerson from "./SuggestionPerson";

export default class SuggestionCard extends React.PureComponent {
  render() {
    const { suggestion, markAsNotDuplicate } = this.props;
    return (
      <div className="merge-suggestion-card">
        <div>
          <SuggestionPerson
            person={suggestion.person1}
            style={{ color: "#F3893D", marginBottom: 8 }}
          />
          <SuggestionPerson
            person={suggestion.person2}
            style={{ color: "#53B7E8" }}
          />
        </div>
        <div className="merge-suggestions-btns">
          <a
            className="merge-btn btn uppercase-text fw-500"
            href={`/students/merge?id=${suggestion.person1.studentID}&id=${suggestion.person2.studentID}&suggestionId=${suggestion.id}`}
          >
            Start Merge
          </a>
          <button
            className="not-duplicate-btn btn uppercase-text fw-500"
            onClick={() => markAsNotDuplicate(suggestion.id)}
          >
            Not Duplicate
          </button>
        </div>
      </div>
    );
  }
}
