import React from "react";
import MultiSelect from "../../../shared/MultiSelect";

export default class OtherDemographics extends React.PureComponent {
  render() {
    const {
      demographics: { interests = [], notes = "", personality = [] },
      interests: allInterests,
      onChange,
      onChangeEvt,
      personalities: allPersonalities,
    } = this.props;

    const interestOptions = allInterests
      ? allInterests.map((i) => ({ label: i.name, value: i.id }))
      : [];
    const personalityOptions = allPersonalities
      ? allPersonalities.map((p) => ({ label: p.name, value: p.id }))
      : [];

    return (
      <React.Fragment>
        <div className="student-profile-form-section">
          <p className="profile-section-title">Interests and Hobbies</p>
          <div className="input-container">
            <MultiSelect
              onChange={(_, vals) =>
                onChange(
                  "interests",
                  vals.map((i) => ({ id: i.value })),
                )
              }
              options={interestOptions}
              value={
                interests
                  ? interestOptions.filter((o) =>
                      interests.find((i) => i.id === o.value),
                    )
                  : []
              }
            />
          </div>
        </div>
        <div className="student-profile-form-section">
          <p className="profile-section-title">Personality</p>
          <div className="input-container">
            <MultiSelect
              onChange={(_, vals) =>
                onChange(
                  "personality",
                  vals.map((p) => ({ id: p.value })),
                )
              }
              options={personalityOptions}
              value={
                personality
                  ? personalityOptions.filter((o) =>
                      personality.find((p) => p.id === o.value),
                    )
                  : []
              }
            />
          </div>
        </div>
        <div className="student-profile-form-section">
          <p className="profile-section-title">Notes</p>
          <textarea
            className="custom-input"
            name="notes"
            onChange={onChangeEvt}
            value={notes}
          />
        </div>
      </React.Fragment>
    );
  }
}
