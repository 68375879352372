import React from "react";
import Select from "react-select";

export default class CustomSelect extends React.Component {
  render() {
    const {
      className,
      classNamePrefix,
      clearable,
      disabled,
      name,
      options,
      onChange,
      placeholder,
      defaultValue,
      value,
    } = this.props;
    const styles = {
      indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
      placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
      clearIndicator: (styles) => ({
        ...styles,
        marginRight: "-8px",
        padding: "0",
      }),
    };
    return (
      <Select
        className={`custom-select ${className ? className : ""}`}
        classNamePrefix={classNamePrefix || "custom-select"}
        clearable={clearable}
        disabled={disabled}
        name={name}
        onChange={(option) =>
          onChange(name, option === null ? "" : option.value)
        }
        options={options}
        placeholder={placeholder}
        styles={styles}
        defaultValue={defaultValue}
        value={value && options.find((o) => o.value === value)}
        menuIsOpen={true}
      />
    );
  }
}
