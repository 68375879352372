import React from "react";
import moment from "moment";
import { Link } from "react-router";

// import { ContactType } from "../../Contact/ContactUtils";
import { ReportingTile } from "../../common/ReportingTile";
import DateRangePicker from "../../common/DateRangePicker";
import * as ReportingHelpers from "../../../assets/scripts/reportingHelpers";
import GenderPieChart from "../../common/ReportingGenderPieChart";
import AgeBarChart from "../../common/ReportingAgeBarChart";
// import { CustomEventTypes } from "../Utils";

// export interface SummaryTemplateReportData {
//   name: string;
//   type: CustomEventTypes;
//   contactType: ContactType;
//   participantCount: number;
//   schoolOrMosadCount: number;
//   ageArray: Array<ReportingHelpers.AgeCount>;
//   genderArray: Array<ReportingHelpers.GenderCount>;
// }

// interface ReportingSummaryTemplateProps {
//   contactType: ContactType;
//   baseUrl: string;
//   eventText: string;
//   startDate: moment.Moment;
//   endDate: moment.Moment;
//   reportData: SummaryTemplateReportData;
//   handleDateInputChange: (field: string) => void;
// }

const ReportingSummaryTemplate = ({
  contactType,
  baseUrl,
  eventText,
  startDate = moment.utc().subtract(2, "years"),
  endDate = moment.utc(),
  reportData,
  handleDateInputChange,
}) => {
  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-12">
          <ol className="breadcrumb">
            <li>
              <Link to={baseUrl}>{eventText}</Link>
            </li>
            <li>Reports</li>
          </ol>
        </div>
        <div className="col-sm-6" style={{ marginBottom: "1em" }}>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            handleDateInputChange={handleDateInputChange}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <Link
            to={`${baseUrl}/reporting/bycontact/?startDate=${startDate}&endDate=${endDate}`}
          >
            <ReportingTile
              label="Participants"
              value={ReportingHelpers.GetReportValue(
                reportData,
                "participantCount",
              )}
            />
          </Link>{" "}
        </div>
        <div className="col-md-3">
          <Link
            to={`${baseUrl}/reporting/byschool/?startDate=${startDate}&endDate=${endDate}`}
          >
            <ReportingTile
              label={"Schools"}
              value={ReportingHelpers.GetReportValue(
                reportData,
                "schoolOrMosadCount",
              )}
            />
          </Link>{" "}
        </div>
        <div className="col-md-3">
          <ReportingTile
            label="Average Age"
            value={ReportingHelpers.GetAverageAgeAsString(reportData.ageArray)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <GenderPieChart
            genderArray={ReportingHelpers.GetGenderPercentages(
              reportData.genderArray,
            )}
            displayName="Participants"
          />
        </div>
        <div className="col-md-6">
          <AgeBarChart
            ageArray={reportData.ageArray}
            displayName="Participants"
          />
        </div>
      </div>
    </div>
  );
};

export default ReportingSummaryTemplate;
