import React from "react";

import CustomSelect from "../../Form/CustomSelect";
import FormInput from "../../Form/FormInput";
import FormInputHebrew from "../../Form/FormInputHebrew";
import FormInputUpdate from "../../Form/FormInputUpdate";
import ImpersonateUser from "../../../../shared/ImpersonateUser";
import RadioContainer from "../../Form/RadioContainer";
import AuthService, {
  PermissionClaims,
} from "../../../../../services/AuthService";
import { impersonationUserTypes, isNotValidDOB } from "../../../../../lib";
import HebrewBirthdayField from "./PersonHebrewBirthdayField";

export default class PersonalInfo extends React.PureComponent {
  state = {
    personalInfoShowing: true,
  };

  componentDidMount() {
    if (window.innerWidth < 560) {
      this.setState({
        personalInfoShowing: false,
      });
    }
  }

  render() {
    const {
      readOnly,
      errors,
      require,
      touched,
      setFieldValue,
      shliachType,
      sys: { titles },
      values,
    } = this.props;

    const profileImageURL =
      values && values.person && values.person.profileImageURL;
    const personalEmail =
      values && values.person && values.person.personalEmail;

    return (
      <div
        className={
          this.state.personalInfoShowing
            ? "profile-form-section"
            : "profile-form-section mobile-tab-collapsed"
        }
      >
        <div
          className="flex flex-align-center accent-text-dark medium-text fw-700 mt-48 mb-16"
          onClick={() => {
            this.setState({
              personalInfoShowing: !this.state.personalInfoShowing,
            });
          }}
        >
          {shliachType} Information
          {AuthService.UserHasClaim(PermissionClaims.UserImpersonation) &&
            personalEmail && (
              <ImpersonateUser
                className="ml-16"
                username={personalEmail}
                userType={impersonationUserTypes.Shliach}
              />
            )}
        </div>

        <div className="mb-16">
          <label className="line-height-double accent-text small-text">
            Profile Image
          </label>
          {profileImageURL ? (
            <img
              alt="profile"
              src={profileImageURL}
              style={{
                height: "60px",
                width: "60px",
                objectFit: "cover",
                borderRadius: "100%",
              }}
            />
          ) : (
            "Image not uploaded"
          )}
        </div>

        <div className="profile-form-inputs">
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="First Name"
              type="text"
              name="person.firstName"
              validate={require}
              className="form-required"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInput
              label="Last Name"
              type="text"
              name="person.lastName"
              validate={require}
              className="form-required"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Name called by"
              type="text"
              name="person.nickname"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>
          <div className="flex flex-justify-space flex-align-center relative">
            <FormInputHebrew
              label="Hebrew Name"
              type="text"
              name="person.hebrewName"
              placeholder="ex: Chana bas Rivkah"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
              setFieldValue={setFieldValue}
            />
          </div>

          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInputUpdate
              label="Title"
              name="person.title"
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            >
              {({ field }) => (
                <CustomSelect
                  {...field}
                  errors={errors}
                  options={
                    titles &&
                    titles.map((t) => ({
                      key: t.enumValue,
                      value: t.enumValue,
                      label: t.displayValue,
                    }))
                  }
                  placeholder="Select Title..."
                  setFieldValue={setFieldValue}
                  touched={touched}
                  readOnly={readOnly}
                />
              )}
            </FormInputUpdate>
          </div>

          <div className="flex flex-align-center relative">
            <FormInput
              label="Gender"
              name="person.gender"
              validate={require}
              className="form-required"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  readOnly={readOnly}
                  {...field}
                  input1={{
                    id: "male",
                    value: "Male",
                    checked: "Male",
                    label: "Male",
                  }}
                  input2={{
                    id: "female",
                    value: "Female",
                    checked: "Female",
                    label: "Female",
                  }}
                />
              )}
            </FormInput>
          </div>

          <div className="flex flex-justify-space flex-align-center mr-24 relative">
            <FormInput
              label="Birthday"
              type="date"
              name="person.dob"
              validate={(date) => isNotValidDOB(date)}
              errors={errors}
              touched={touched}
              readOnly={readOnly}
            />
          </div>

          <div className="flex flex-align-center relative">
            <FormInput
              label="Nightfall/Sunset"
              name="person.beforeNightfall"
              errors={errors}
              touched={touched}
            >
              {({ field }) => (
                <RadioContainer
                  errors={errors}
                  touched={touched}
                  {...field}
                  readOnly={readOnly}
                  input1={{
                    id: "before",
                    value: true,
                    checked: "true",
                    label: "Before",
                  }}
                  input2={{
                    id: "after",
                    value: false,
                    checked: "false",
                    label: "After",
                  }}
                />
              )}
            </FormInput>
          </div>

          <HebrewBirthdayField hebrewDOB={values.person.hebrewDOB} />
        </div>
      </div>
    );
  }
}
