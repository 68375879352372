import React from "react";
import { browserHistory } from "react-router";
import PaginatedTable from "../../../shared/PaginatedTable";
import ManagePaymentsModal from "./ManagePaymentsModal";
import ShluchimExportButton from "./ShluchimExportButton";
import ShluchimTableHeader from "./ShluchimTableHeader";
import ShluchimTableRow from "./ShluchimTableRow";
import Toggle from "../../../shared/Toggle";
import TableFilters from "../../../shared/TableFilters";

import LamplightersApi from "../../../../services/resources/LamplightersApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import queryString from "query-string";
import axios from "axios";

export default class ShluchimTable extends React.PureComponent {
  constructor(props) {
    super();
    const {
      location: {
        query: { keyword, view },
      },
    } = props;

    this.state = {
      errorMessage: "",
      filters: {
        keyword: keyword || "",
      },
      loading: false,
      page: 1,
      results: 12,
      shluchim: [],
      showManagePaymentsModal: false,
      sortBy: "shliachLastName",
      success: true,
      totalShluchim: 0,
      view: ((view && this.views.find((v) => v.id === view)) || this.views[0])
        .id,
    };
  }

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  views = [
    { id: "allocations", name: "Grants" },
    { id: "payments", name: "Payments" },
  ];

  onChangeView = (view) => {
    //update sortBy if grant/payment-specific sort option is selected
    let { sortBy } = this.state;
    if (sortBy && !this.baseSortOptions.find((o) => o.id === sortBy)) {
      sortBy = "shliachLastName";
    }

    this.setState({ sortBy, view }, () => {
      this.getShluchim();
      this.updateRouterQuery({ view }, true);
    });
  };

  filterShluchim = (filters) => {
    this.setState({ filters }, () => {
      this.getShluchim();
      this.updateRouterQuery(filters);
    });
  };

  updateRouterQuery = (params) => {
    //update router query params
    const {
      location: { pathname, query },
    } = this.props;

    //clear out valueless params
    Object.keys(params).forEach((k) => (params[k] = params[k] || undefined));

    const queryParams = {
      ...query,
      ...params,
    };

    browserHistory.replace(`${pathname}?${queryString.stringify(queryParams)}`);
  };

  getShluchim = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
    onRefresh,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: onRefresh ? false : true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        LamplightersApi.getLocations(
          this.apiSignal.token,
          this.props.schedule.id,
          page,
          results,
          sortBy,
          this.state.filters,
          this.state.view,
        )
          .then(({ locations: shluchim, totalLocations: totalShluchim }) => {
            this.setState({
              loading: false,
              shluchim,
              totalShluchim,
            });
            if (onRefresh) {
              onRefresh();
            }
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                shluchim: [],
                success: false,
                totalShluchim: 0,
              });
            }
          });
      },
    );
  };

  getShluchimForExport = async () => {
    const { sortBy, filters, view } = this.state;

    const locationsForExport = await LamplightersApi.getLocations(
      this.apiSignal.token,
      this.props.schedule.id,
      undefined,
      undefined,
      sortBy,
      filters,
      view,
      true,
    );

    return locationsForExport.locations;
  };

  baseSortOptions = [
    { id: "shliachLastName", name: "Shliach Name" },
    { id: "chabadHouseName", name: "Chabad House Name" },
    { id: "campusName", name: "Campus Name" },
  ];

  getSortOptions = () => {
    const {
      schedule: { grants },
    } = this.props;
    const { view } = this.state;

    const sortOptions = [...this.baseSortOptions];

    if (view === "payments") {
      sortOptions.push(
        ...[
          { id: "amountAllocated", name: "Total Allocated" },
          { id: "amountOwed", name: "Amount Owed" },
        ],
      );
    } else {
      sortOptions.push(
        ...[
          {
            id: "activeDataPercentageAsc",
            name: "Active Data Percentage - Ascending",
          },
          {
            id: "activeDataPercentageDesc",
            name: "Active Data Percentage - Descending",
          },
          {
            id: "submittedPercentageAsc",
            name: "Submitted Percentage - Ascending",
          },
          {
            id: "submittedPercentageDesc",
            name: "Submitted Percentage - Descending",
          },
        ],
      );

      const grantSortOptions = [
        {
          getSortName: (grantName) => `${grantName} - Active Ascending`,
          sortValue: "activeGrantsAsc",
        },
        {
          getSortName: (grantName) => `${grantName} - Active Descending`,
          sortValue: "activeGrantsDesc",
        },
        {
          getSortName: (grantName) => `${grantName} - Flagged Ascending`,
          sortValue: "flaggedGrantsAsc",
        },
        {
          getSortName: (grantName) => `${grantName} - Flagged Descending`,
          sortValue: "flaggedGrantsDesc",
        },
        {
          getSortName: (grantName) => `${grantName} - Submitted Ascending`,
          sortValue: "submittedGrantsAsc",
        },
        {
          getSortName: (grantName) => `${grantName} - Submitted Descending`,
          sortValue: "submittedGrantsDesc",
        },
        {
          getSortName: (grantName) => `${grantName} - Allocated Ascending`,
          sortValue: "allocatedGrantsAsc",
        },
        {
          getSortName: (grantName) => `${grantName} - Allocated Descending`,
          sortValue: "allocatedGrantsDesc",
        },
      ];

      grants.forEach((grant) => {
        sortOptions.push(
          ...grantSortOptions.map((st) => ({
            id: st.sortValue + "-" + grant.id,
            name: st.getSortName(grant.name),
            grantId: grant.id,
          })),
        );
      });
    }

    return sortOptions;
  };

  render() {
    const {
      location: { pathname },
      mobileMode,
      readOnly,
      refreshSchedule,
      schedule,
    } = this.props;

    const {
      grants,
      id: grantScheduleID,
      paymentSchedules,
      programScheduleName,
      programStartDate,
    } = schedule;

    const {
      filters,
      loading,
      page,
      results,
      shluchim,
      showManagePaymentsModal,
      sortBy,
      success,
      totalShluchim,
      view,
    } = this.state;

    const sortOptions = this.getSortOptions();

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <div className="flex flex-align-center">
            <p className="xxl-text fw-500">Shluchim</p>
            <Toggle
              className="lamplighters-shluchim-view-toggle ml-32"
              options={this.views.map((v) => ({
                value: v.id,
                display: v.name,
              }))}
              onChange={(_, val) => this.onChangeView(val)}
              value={view}
            />
          </div>
          <div className="flex flex-align-center">
            {view === "payments" && !readOnly && (
              <button
                className="btn custom-btn btn-accent mr-16"
                onClick={() => this.setState({ showManagePaymentsModal: true })}
              >
                Manage Payments
              </button>
            )}
            <ShluchimExportButton
              getShluchimForExport={this.getShluchimForExport}
              grants={grants}
              paymentSchedules={paymentSchedules}
              programScheduleName={programScheduleName}
              view={view}
            />
          </div>
        </div>
        <div className="flex flex-align-center flex-justify-space">
          <TableFilters
            applyFilters={this.filterShluchim}
            applySort={(so) => this.getShluchim(1, results, so)}
            filters={filters}
            sortBy={sortBy}
            sortOptions={sortOptions}
          />
        </div>
        <div className="lamplighters-shluchim-table">
          <PaginatedTable
            loading={loading}
            loadData={this.getShluchim}
            mobileMode={mobileMode}
            page={page}
            records={shluchim}
            renderHeader={() => (
              <ShluchimTableHeader
                grants={grants}
                paymentSchedules={paymentSchedules}
                view={view}
              />
            )}
            renderRow={(location, index) => (
              <ShluchimTableRow
                getInteractions={() =>
                  LamplightersApi.getLocationInteractions(
                    this.apiSignal.token,
                    grantScheduleID,
                    location.chabadHouseID,
                  )
                }
                grants={grants}
                key={index}
                location={location}
                paymentSchedules={paymentSchedules}
                programStartDate={programStartDate}
                view={view}
                viewStudentsPath={`${pathname}?tab=students`}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            success={success}
            totalCount={totalShluchim}
          />
        </div>

        {showManagePaymentsModal && (
          <ManagePaymentsModal
            close={() => this.setState({ showManagePaymentsModal: false })}
            paymentSchedules={paymentSchedules || []}
            refreshSchedule={() => {
              //reload schedule (for total paid)
              refreshSchedule();
              //reload shluchim
              this.getShluchim();
            }}
            schedule={schedule}
            show={showManagePaymentsModal}
          />
        )}
      </div>
    );
  }
}
