import { School } from "../../models/School";

export const RolesInMosad = {
    DIRECTOR_TEXT: "Director",
    CO_DIRECTOR_TEXT: "Co-director",
    OTHER_TEXT: "Other",
};

export function MosadMainData(mosad) {
    const mainShliach = mosad.shluchim.find(s => s.id === mosad.primaryShliachID);
    const mainShlucha = mosad.shluchim.find(s => s.id === mosad.primaryShluchaID);

    return { shliach: mainShliach, shlucha: mainShlucha };
}

export function SchoolMainMosad(school: School) {
    return MainMosad(school);
}

export function MainMosad(school: School) {
    const mainMosad = school.mosads.find(m => m.id === school.primaryMosadID);
    return mainMosad;
}

export function SchoolMainShluchim(school: School) {
    return MainShluchim(school);
}

export function MainShluchim(school: School) {
    const mainMosad = MainMosad(school);
    return mainMosad && MosadMainData(mainMosad);
}
