import Cookies from "js-cookie";

const getCookieName = (userType) =>
  `${userType.toLowerCase()}-impersonation-auth`;

const getCookieAttributes = () => {
  const domainParts = window.location.hostname.split(".");
  const rootDomain = "." + domainParts.slice(1, domainParts.length).join(".");

  return {
    domain: rootDomain,
    sameSite: "Strict",
    secure: true,
  };
};

function hasImpersonationAuthCookie(userType) {
  return !!Cookies.get(getCookieName(userType));
}

export const impersonationUserTypes = {
  Admin: "admin",
  Shliach: "shliach",
  Student: "student",
  // JewishU: "ju-student"
};

export function setImpersonationAuthCookie(userType, payload) {
  Cookies.set(
    getCookieName(userType),
    JSON.stringify(payload),
    getCookieAttributes(),
  );
}

export function removeImpersonationAuthCookies() {
  Object.keys(impersonationUserTypes).forEach((userTypeKey) => {
    const userType = impersonationUserTypes[userTypeKey];
    if (hasImpersonationAuthCookie(userType)) {
      Cookies.remove(getCookieName(userType), getCookieAttributes());
    }
  });
}
