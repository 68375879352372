import React, { useCallback, useEffect, useRef } from "react";
import { loadScript } from "../../lib";
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

export function GoogleIdentitySignin({ handleCredentialResponse }) {
  const googleButton = useRef(null);

  const renderGoogleSignin = useCallback(() => {
    const accountId = window.google?.accounts?.id;

    if (accountId) {
      // initialize google account
      accountId.initialize({
        client_id: REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        auto_select: true,
        use_fedcm_for_prompt: true,
      });

      // render signin button
      accountId.renderButton(
        googleButton.current,
        // customization attributes
        {
          theme: "filled_blue",
          size: "large",
          width: "320px",
        },
      );

      // Google one tap
      accountId.prompt();
    }

    return !!accountId;
  }, [handleCredentialResponse]);

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    // load google script
    loadScript(src)
      .then(() => {
        const rendered = renderGoogleSignin();
        // if not rendered, retry after 1 second (to accommodate edge cases where window.google object may not have been available yet if gsi script was loaded externally)
        if (!rendered) {
          setTimeout(renderGoogleSignin, 1000);
        }
      })
      .catch(console.error);
  }, [renderGoogleSignin]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div ref={googleButton} />
    </div>
  );
}
