import React from "react";
import { Link } from "react-router";

import {
  /* Organization, */ CategoryTypes /* , OrganizationFilter */,
} from "../../../models/Organization";
import CsvGetAndDownload /* , { ColumnConfig } */ from "../../common/CSVGetAndDownload";
import OrganizationList from "./OrganizationList";
import OrganizationFilterButton from "../OrganizationFilter/OrganizationFilterButton";

// interface OrganizationListPageProps {
//   organizations: Array<Organization>;
//   currentFilters: OrganizationFilter;
//   handleFiltersChanged: (filters: OrganizationFilter) => void;
// }

const OrganizationListPage = ({
  organizations,
  currentFilters,
  handleFiltersChanged,
}) => {
  /** @returns {Array<ColumnConfig>} */
  const getDownloadHeaders = () => {
    return [
      {
        columnId: "name",
        header: "Name",
      },
      {
        columnId: "type",
        header: "Type",
        dataField: "category",
        transform: (category) => category && CategoryTypes[category.type],
      },
      {
        columnId: "category",
        header: "Category",
        transform: (category) => category && category.name,
      },
      {
        columnId: "description",
        header: "Description",
        visible: false,
      },
      {
        labelGroup: "Address",
        columnId: "address",
        header: "Home Address Line 1",
        visible: false,
        transform: (address) => (address && address.addressLine1) || "",
      },
      {
        labelGroup: "Address",
        columnId: "address",
        header: "Line 2",
        visible: false,
        transform: (address) => (address && address.addressLine2) || "",
      },
      {
        labelGroup: "Address",
        columnId: "address",
        header: "City",
        visible: false,
        transform: (address) => (address && address.city) || "",
      },
      {
        labelGroup: "Address",
        columnId: "address",
        header: "ZIP",
        visible: false,
        transform: (address) => (address && address.postalCode) || "",
      },
      {
        labelGroup: "Address",
        columnId: "address",
        header: "State",
        visible: false,
        transform: (address) => (address && address.state) || "",
      },
      {
        labelGroup: "Address",
        columnId: "address",
        header: "Country",
        visible: false,
        transform: (address) => (address && address.country) || "",
      },
      {
        columnId: "contactName",
        dataField: "contacts",
        header: "Contact Name",
        transform: (contacts) =>
          contacts && contacts.length > 0
            ? `${contacts[0].firstName} ${contacts[0].lastName}`
            : "",
      },
      {
        columnId: "contactEmail",
        dataField: "contacts",
        header: "Contact Email",
        transform: (contacts) => {
          if (
            !contacts ||
            contacts.length === 0 ||
            !contacts[0].contactMethods
          ) {
            return "";
          }
          const emails = contacts[0].contactMethods
            .filter(
              (cm) =>
                cm.type === "EmailAddress" &&
                cm.value.indexOf("proxymail.facebook.com") < 0,
            )
            .sort((cm1, cm2) => (cm1.primary ? -1 : 1));
          if (emails.length === 0) {
            return "";
          }
          return emails[0].value;
        },
      },
      {
        columnId: "contactMobile",
        dataField: "contacts",
        header: "Contact Mobile Phone",
        transform: (contacts) => {
          if (
            !contacts ||
            contacts.length === 0 ||
            !contacts[0].contactMethods
          ) {
            return "";
          }
          const emails = contacts[0].contactMethods
            .filter((cm) => cm.type === "CellPhoneNumber")
            .sort((cm1, cm2) => (cm1.primary ? -1 : 1));
          if (emails.length === 0) {
            return "";
          }
          return emails[0].value;
        },
      },
      {
        columnId: "website",
        header: "Website",
        visible: false,
      },
      {
        columnId: "roomSpace",
        header: "Room Space",
        visible: false,
      },
      {
        columnId: "meetingSpace",
        header: "Meeting Space",
        visible: false,
      },
      {
        columnId: "notes",
        header: "Notes",
      },
    ];
  };
  /** @returns {string} */
  const getFileName = () => {
    return (
      "Organizations" +
      /*(this.props.location.query.q ? "_" + this.props.location.query.q : "") + */ ".csv"
    ); // eslint-disable-line
  };

  return (
    <div>
      <ol className="breadcrumb">
        <li>Directory</li>
        <li>Organizations</li>
      </ol>
      <div className="list-container">
        <h1>Organizations</h1>
        <div
          className="row"
          style={{ marginBottom: "1em", position: "relative" }}
        >
          <div className="lfloatmb1 col-sm-12">
            <div className="results-summary results-summary-small">
              <div className="results-summary-item">
                <i className="icon icon-results">
                  <span className="path1" />
                  <span className="path2" />
                </i>
                <span className="results-summary-desc">
                  <label className="control-label">Results</label>
                  {organizations.length}
                </span>
              </div>
            </div>
            <Link
              to={"/organizations/new"}
              className="btn btn-primary btn-sm rfloat"
            >
              Add New
            </Link>
            <div className="rfloat">
              <CsvGetAndDownload
                dataToDownload={organizations}
                dataPath="results"
                fileName={getFileName()}
                columnDefinitions={getDownloadHeaders()}
              />
            </div>

            <div className="filter-field">
              <OrganizationFilterButton
                handleFiltersChanged={handleFiltersChanged}
                currentFilters={currentFilters}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <OrganizationList organizations={organizations} />
        </div>
      </div>
    </div>
  );
};

export default OrganizationListPage;
