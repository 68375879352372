import React from "react";
import PaginatedTable from "../../../shared/PaginatedTable";
import LocationCoursesTableHeader from "./LocationCoursesTableHeader";
import LocationCoursesTableRow from "./LocationCoursesTableRow";

import EduApi from "../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import axios from "axios";

export default class LocationCoursesTable extends React.PureComponent {
  state = {
    courses: [],
    errorMessage: "",
    loading: false,
    page: 1,
    results: 6,
    success: true,
    totalCourses: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getCourses = (page = 1, results = this.state.results) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        success: true,
      },
      () => {
        EduApi.getEduChabadHouseCourses(
          this.apiSignal.token,
          this.props.locationId,
          page,
          results,
        )
          .then(({ courses, totalCourses }) => {
            this.setState({
              courses,
              loading: false,
              totalCourses,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                courses: [],
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
                totalCourses: 0,
              });
            }
          });
      },
    );
  };

  render() {
    const { courses, loading, page, results, success, totalCourses } =
      this.state;

    return (
      <React.Fragment>
        <div className="divider"></div>
        <p className="fw-700 mb-16">Course History</p>
        <PaginatedTable
          loading={loading}
          loadData={this.getCourses}
          page={page}
          records={courses}
          renderHeader={() => <LocationCoursesTableHeader />}
          renderRow={(course, index) => (
            <LocationCoursesTableRow course={course} key={index} />
          )}
          results={results}
          showResultsCount={true}
          success={success}
          totalCount={totalCourses}
        />
      </React.Fragment>
    );
  }
}
