import React from "react";
import moment from "moment";

export default class ShluchimTableHeader extends React.PureComponent {
  render() {
    const { grants, paymentSchedules, view } = this.props;

    const columns = ["Chabad House", "Primary Shluchim", "Campus"];
    if (view === "payments") {
      columns.push(
        "Total Allocated",
        "Amount Owed",
        paymentSchedules.map((paymentSchedule, index) => (
          <span
            className="lamplighters-shluchim-table-dynamic-cell"
            key={index}
          >
            Payment {index + 1}
            <br />
            {paymentSchedule.paymentProcessingDate &&
              moment(paymentSchedule.paymentProcessingDate).format(
                "MM/DD/YYYY",
              )}
          </span>
        )),
      );
    } else {
      columns.push(
        "Active Data Percentage",
        "Submitted Percentage",
        grants.map((grant, index) => (
          <span
            className="lamplighters-shluchim-table-dynamic-cell"
            key={index}
          >
            {grant.typeDisplay}
            {grant.description ? ` - ${grant.description}` : ""}
          </span>
        )),
        "",
      );
    }

    return (
      <div className={`lamplighters-shluchim-${view}-table-header`}>
        {columns.map((column, index) => (
          <p className="flex flex-align-center" key={index}>
            {column}
          </p>
        ))}
      </div>
    );
  }
}
