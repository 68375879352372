import React from "react";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import { formatFullName, formatNumber } from "../../../../../lib";
import moment from "moment";

export default class RegistrationsTableRow extends React.PureComponent {
  render() {
    const {
      onRemoveRegistration,
      programSchedule: { applicationSettings, programID, scheduleID },
      registration: {
        applicationStatusDisplay,
        approvalDate,
        categoryDisplay,
        campusID,
        campusName,
        cell,
        chabadHouseID,
        chabadHouseName,
        chabadHouseCity,
        chabadHouseState,
        chabadHouseCountry,
        email,
        firstName,
        id: registrationID,
        lastName,
        latestPaymentDate,
        registrationDate,
        shliachID,
        studentID,
        spouseCategoryDisplay,
        spouseCell,
        spouseEmail,
        spouseFirstName,
        spouseLastName,
        spouseShliachID,
        totalInstallments,
        totalPaid,
      },

      showApplicationDetails,
      showApprovalDetails,
      showEnrolled,
      showPaymentDetails,
      templateColumns,
    } = this.props;

    return (
      <div
        className="program-registrations-table-row"
        style={{ gridTemplateColumns: templateColumns }}
      >
        <div>
          <Link
            className="link-text-secondary"
            to={
              studentID
                ? `/students/${studentID}`
                : `/shluchim/${shliachID}/details`
            }
          >
            {formatFullName(firstName, lastName)}
          </Link>
          {spouseShliachID && (
            <div>
              /{" "}
              <Link
                className="link-text-secondary"
                to={`/shluchim/${spouseShliachID}/details`}
              >
                {formatFullName(spouseFirstName, spouseLastName)}
              </Link>
            </div>
          )}
        </div>
        {!showEnrolled && (
          <>
            <div>
              <p>{email}</p>
              {spouseEmail && <p>/ {spouseEmail}</p>}
            </div>
            <div>
              <p>{cell}</p>
              {spouseCell && <p>/ {spouseCell}</p>}
            </div>
          </>
        )}
        {shliachID && showEnrolled && (
          <Link
            className="link-text-secondary"
            to={`/shluchim/${shliachID}/chabadHouse/${chabadHouseID}`}
          >
            {chabadHouseName}
          </Link>
        )}
        <Link className="link-text-secondary" to={`/schools/${campusID}`}>
          {campusName}
        </Link>
        {showEnrolled ? (
          <>
            {showApplicationDetails ? (
              <React.Fragment>
                <p>{applicationStatusDisplay}</p>
                <p>{moment(registrationDate).format("MM/DD/YYYY")}</p>
                {showApprovalDetails && (
                  <p>
                    {approvalDate
                      ? moment(approvalDate).format("MM/DD/YYYY")
                      : ""}
                  </p>
                )}
                {showPaymentDetails && (
                  <React.Fragment>
                    <p>
                      {totalInstallments}/
                      {applicationSettings.paymentInstallments}
                    </p>
                    <p>
                      {latestPaymentDate
                        ? moment(latestPaymentDate).format("MM/DD/YYYY")
                        : ""}
                    </p>
                    <p>{totalPaid ? `$${formatNumber(totalPaid)}` : ""}</p>
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <p>{moment(registrationDate).format("MM/DD/YYYY")}</p>
            )}
            <p className="flex flex-align-center">
              <i
                className="link-text-secondary material-icons medium-text"
                onClick={(e) => {
                  e.stopPropagation();
                  browserHistory.push(
                    `/programs/${programID}/schedules/${scheduleID}/registrations/${registrationID}`
                  );
                }}
                style={{ padding: "8px" }}
              >
                edit
              </i>
              <i
                className="link-text-secondary material-icons medium-text"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveRegistration();
                }}
                style={{ padding: "8px" }}
              >
                delete
              </i>
            </p>
          </>
        ) : (
          <>
            <div>
              {chabadHouseCity && <p>{chabadHouseCity}</p>}
              {chabadHouseState && <p>/ {chabadHouseState}</p>}
              {chabadHouseCountry && <p>/ {chabadHouseCountry}</p>}
            </div>
            <div>
              {categoryDisplay && <p>{categoryDisplay}</p>}
              {spouseCategoryDisplay && <p>/ {spouseCategoryDisplay}</p>}
            </div>
          </>
        )}
      </div>
    );
  }
}
