import React from "react";
import Select from "react-select";
import get from "lodash.get";

export default class MultiSelect extends React.Component {
  render() {
    const {
      errors,
      touched,
      options,
      readOnly,
      name,
      value,
      setFieldValue,
      placeholder,
    } = this.props;
    const error = get(errors, name) && get(touched, name);
    return (
      <Select
        className={`multiselect ${error ? `error` : null} ${
          readOnly ? "read-only" : ""
        }`}
        disabled={readOnly}
        closeMenuOnSelect={true}
        multi={true}
        name={name}
        onChange={(option) => setFieldValue(name, option)}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}
