import React from "react";
import Modal from "react-modal";
import ProgramsApi from "../../../services/resources/ProgramsApi";
import SelectSearch from "react-select";
import { Fragment } from "react";
import Loader from "../../common/Loader";

export default class ManageParticipantsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      includedShluchim: [],
      selectedShliach: null,
      excludedShluchim: false,
    };
  }

  async componentDidMount() {
    const { programId, isNewProgram } = this.props;
    if (!isNewProgram) {
      await this.getProgramParticipants(programId);
    }
  }

  getProgramParticipants = async (programId) => {
    const includedShluchim = await ProgramsApi.getProgramParticipants(
      programId,
    );
    this.setState({ includedShluchim });
  };

  onSave = async () => {
    const {
      props: { programId },
      state: { includedShluchim },
    } = this;
    await ProgramsApi.saveProgramParticipants(programId, includedShluchim);

    this.props.onClose();
  };

  onClose = (dirty) => {
    if (dirty) {
      const close = window.confirm(
        "Your changes have not been saved. Are you sure you want to continue?",
      );
      if (close) {
        this.props.onClose();
      } else {
        return;
      }
    }
    this.props.onClose();
  };

  render() {
    const {
      props: { show, programId, shluchim, isNewProgram },
      state: { loading, selectedShliach, includedShluchim, excludedShluchim },
    } = this;
    const dirty =
      (!includedShluchim.length > 0 && !excludedShluchim) || selectedShliach;
    return (
      <Modal isOpen={show} className="manage-participants-modal-container">
        {!isNewProgram && !programId && loading ? ( //initial load hide empty screen
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : (
          <Fragment>
            {loading && (
              <div className="full-page-overlay-loader">
                <Loader />
              </div>
            )}
            <div className="modal-card card">
              <div className="flex flex-justify-space mb-16 ">
                <p className="xxl-text">Manage Participants</p>
                <i
                  style={{ float: "right" }}
                  className="material-icons pointer link-text-secondary"
                  onClick={() => this.onClose(dirty)}
                >
                  close
                </i>
              </div>
              <div className="divider" />
              <div style={{ marginBottom: "34px" }}>
                <p>Add Specific Shluchim</p>
                <span className="search-area flex-align-center">
                  <SelectSearch
                    className="full-width"
                    isClearable={true}
                    isSearchable={true}
                    onChange={(val) => this.setState({ selectedShliach: val })}
                    options={shluchim
                      .filter((s) => !includedShluchim.includes(s))
                      .map((s) => {
                        return {
                          value: s.shliachID,
                          label: `${s.firstName} ${s.lastName} ${
                            s.campusName ? `- ${s.campusName}` : ""
                          }`,
                        };
                      })}
                    placeholder={"Search"}
                    value={selectedShliach}
                  />
                  <p
                    className={`link-text uppercase-text add-selected-btn ml-24 ${
                      selectedShliach ? "" : "disabled-link"
                    }`}
                    onClick={() =>
                      this.setState({
                        includedShluchim: [
                          ...includedShluchim,
                          shluchim.find(
                            (s) => s.shliachID === selectedShliach.value,
                          ),
                        ],
                        selectedShliach: null,
                      })
                    }
                  >
                    Add Selected
                  </p>
                </span>
              </div>
              <div>
                <p>Included Shluchim:</p>
                <div className="manage-participants-included-shluchim-container">
                  {this.state.includedShluchim &&
                    this.state.includedShluchim.map((s, index) => (
                      <IncludedShliachChip
                        key={index}
                        shliach={s}
                        excludeShliach={() =>
                          this.setState({
                            includedShluchim:
                              this.state.includedShluchim.filter(
                                (i) => i.shliachID !== s.shliachID,
                              ),
                            excludedShluchim: true,
                          })
                        }
                      />
                    ))}
                </div>
              </div>

              <div className="manage-participant-modal-btns">
                <button
                  className="close-btn btn link-text uppercase-text fw-500 mr-20"
                  onClick={() => this.onClose(dirty)}
                >
                  Cancel
                </button>
                <button
                  className="save-btn btn uppercase-text fw-500"
                  disabled={dirty}
                  onClick={this.onSave}
                >
                  Save
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </Modal>
    );
  }
}

function IncludedShliachChip({ shliach, excludeShliach }) {
  return (
    <div className="shliach-chip">
      <span>
        {shliach.titleDisplay} {shliach.firstName} {shliach.lastName}
        {shliach.campusName ? "-" : null}
        {shliach.campusName}
        <i
          style={{ marginLeft: 50, opacity: 0.45, fontSize: 16 }}
          className="material-icons pointer link-text-secondary"
          onClick={excludeShliach}
        >
          close
        </i>
      </span>
    </div>
  );
}
