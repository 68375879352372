import React from "react";
import Modal from "react-modal";
import Select from "../../../shared/Select";
import ValidatedInput from "../../../shared/ValidatedInput";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";

export default class TermsAndConditionsModal extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      submitAttempted: false,
      terms: {
        text: "",
        title: "",
        type: "",
      },
    };
    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    const { terms, show } = this.props;

    if (show && !prevProps.show) {
      const initialTerms = terms || {
        text: "",
        title: "",
        type: "",
      };

      const newState = {
        submitAttempted: false,
        terms: initialTerms,
      };
      newState.initialState = _cloneDeep(newState);

      this.setState(newState);
    }
  }

  onChangeTerms = (name, value) => {
    this.setState(
      {
        terms: {
          ...this.state.terms,
          [name]: value,
        },
      },
      () => {
        if (
          name === "type" &&
          value === this.props.termTypes.short &&
          this.state.terms.title
        ) {
          this.onChangeTerms("title", "");
        }
      },
    );
  };

  onClose = () => {
    this.props.close();
    this.setState(this.state.initialState);
  };

  onSave = () => {
    this.setState(
      {
        submitAttempted: true,
      },
      () => {
        const {
          terms,
          terms: { text, title, type },
        } = this.state;

        if (!type || !text || (type === this.props.termTypes.long && !title)) {
          return;
        }

        this.props.save(terms);

        this.onClose();
      },
    );
  };

  render() {
    const { terms: termsForEdit, termTypes } = this.props;

    const {
      initialState: { terms: initialTerms },
      submitAttempted,
      terms,
      terms: { text, title, type },
    } = this.state;

    return (
      <Modal
        isOpen={this.props.show}
        className="new-terms-modal-container modal-container"
      >
        <div className="modal-card card new-terms-modal">
          <p className="large-text fw-500 mb-16">
            {!termsForEdit ? "New" : "Edit"} Terms and Conditions
          </p>
          <div className="flex flex-align-center mb-8">
            <label
              className="accent-text-dark"
              style={{ width: "96px", minWidth: "96px" }}
            >
              Type
            </label>
            <ValidatedInput
              input={
                <Select
                  options={[
                    {
                      display: "Long Text (with generated confirm statement)",
                      value: termTypes.long,
                    },
                    {
                      display: "Short Text",
                      value: termTypes.short,
                    },
                  ]}
                />
              }
              name="type"
              onChange={this.onChangeTerms}
              required={true}
              showRequired={submitAttempted}
              value={type}
            />
          </div>
          {type === termTypes.long && (
            <div className="flex flex-align-center mb-24">
              <label
                className="accent-text-dark"
                style={{ width: "96px", minWidth: "96px" }}
              >
                Title
              </label>
              <ValidatedInput
                input={<input type="text" className="full-width" />}
                name="title"
                onChange={(e) =>
                  this.onChangeTerms(e.target.name, e.target.value)
                }
                required={true}
                showRequired={submitAttempted}
                value={title}
              />
            </div>
          )}
          <div>
            <p className="fw-700">Text</p>
            <ValidatedInput
              input={
                <textarea placeholder="Type terms and conditions here..." />
              }
              name="text"
              onChange={(e) =>
                this.onChangeTerms(e.target.name, e.target.value)
              }
              required={true}
              showRequired={submitAttempted}
              value={text}
            />
          </div>
          <div className="modal-btns flex flex-align-center flex-justify-end mt-16">
            <button
              className="btn link-text uppercase-text fw-500"
              onClick={this.onClose}
            >
              Cancel
            </button>
            <button
              className="btn link-text uppercase-text fw-500 ml-24"
              disabled={_isEqual(initialTerms, terms)}
              onClick={this.onSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
