import React from "react";
import { Link } from "react-router";
import moment from "moment";

import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import { Loader } from "../../lib/coc-common-components";
import viewHistory from "../../services/ViewHistory";
import { ReportingTile } from "../common/ReportingTile";
import * as ReportingHelpers from "../../assets/scripts/reportingHelpers";
import KinusReportingApi from "../../services/resources/KinusReportingApi";
import KinusSelection from "./KinusSelection";
import GenderPieChart from "../common/ReportingGenderPieChart";

export class KinusReporting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedSessionId: 0,
    };

    this.handleProgramSessionChange =
      this.handleProgramSessionChange.bind(this);
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.KinusSummary)) {
      return;
    }

    viewHistory.add("Program", {
      title: "Kinus",
      path: "/kinus",
    });
    this.requestSessionList();
  }

  handleProgramSessionChange(selectedSessionId) {
    if (selectedSessionId !== this.state.selectedSessionId) {
      this.setState({ selectedSessionId }, this.requestReportData);
    }
  }

  requestSessionList() {
    this.setState({ loading: true });

    KinusReportingApi.getList()
      .then((response) => {
        /** @type {Array<number>} */
        const allSessions = response.data.allSessions || [];
        const selectedSessionId = allSessions[0] || 0;
        this.setState(
          { sessions: response.data.allSessions, selectedSessionId },
          this.requestReportData,
        );
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  requestReportData() {
    this.setState({ loading: true });
    /** @type {AxiosPromise} */
    let apiCall;

    if (this.state.selectedSessionId) {
      apiCall = KinusReportingApi.getSingle(this.state.selectedSessionId);
    } else {
      apiCall = KinusReportingApi.getByRange(
        moment.utc().year(),
        moment.utc().year(),
      );
    }

    apiCall
      .then((response) => {
        this.setState({
          reportData: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
      });
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.KinusSummary)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { reportData, selectedSessionId } = this.state;

    const genderArray = ReportingHelpers.GetGenderPercentages(
      (reportData && reportData.countByGender) || [],
    );
    const shluchimCount =
      genderArray[genderArray[0].name === "Shliach" ? 0 : 1].count;
    const shluchosCount =
      genderArray[genderArray[0].name === "Shlucha" ? 0 : 1].count;
    /** @type {Array<any>} */
    const sessions = (this.state.sessions && this.state.sessions) || [];

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <ol className="breadcrumb">
              <li>
                <Link to="/kinus">Kinus</Link>
              </li>
              <li>Reports</li>
            </ol>
          </div>
          <div className="col-sm-6">
            <KinusSelection
              kinus={sessions}
              selectedKinusId={selectedSessionId}
              handleProgramSessionChange={this.handleProgramSessionChange}
            />
          </div>
          {selectedSessionId > 0 && (
            <div className="col-md-12">
              <h2>{`Year ${selectedSessionId}`}</h2>
            </div>
          )}
          <div>
            <div className="col-md-3">
              <ReportingTile label="Shluchim" value={shluchimCount} />
            </div>
            <div className="col-md-3">
              <ReportingTile label="Shluchos" value={shluchosCount} />
            </div>
            <div className="col-md-3">
              <ReportingTile
                label="Children"
                value={ReportingHelpers.GetReportValue(
                  reportData,
                  "childrenCount",
                )}
              />
            </div>
            <div className="col-md-6">
              <GenderPieChart
                genderArray={genderArray}
                displayName="Participants"
              />
            </div>

            {this.state.loading && <Loader />}
          </div>
        </div>
      </div>
    );
  }
}
