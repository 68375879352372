import React from "react";
import Select from "react-select";

export default class MultiSelect extends React.Component {
  render() {
    const {
      disabled,
      className,
      isOptionDisabled,
      options,
      onChange,
      placeholder,
      name,
      value,
      styles,
    } = this.props;
    return (
      <Select
        className={`multiselect ${className || ""}`}
        classNamePrefix="multiselect"
        closeMenuOnSelect={true}
        disabled={disabled}
        multi={true}
        isOptionDisabled={isOptionDisabled}
        name={name}
        onChange={(options) => onChange(name, options)}
        options={options}
        placeholder={placeholder}
        styles={styles}
        value={value}
      />
    );
  }
}
