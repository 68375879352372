import React from "react";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import { Unauthorized, Loader } from "../../../lib/coc-common-components";

import CustomPagesApi from "../../../services/resources/CustomPagesApi";
import AuthService from "../../../services/AuthService";
import { PermissionClaims } from "../../../services/AuthService";
// import { CustomPage } from "../../../models/CustomPage";
import { CustomPageListPage } from "./CustomPageListPage";
import UrlBaseUtil from "../../../services/UrlBaseUtil";

// interface CustomPageListPageContainerProps { }

// interface CustomPageListPageContainerState {
//   customPages: Array<CustomPage>;
//   loading: boolean;
//   displayError: string;
// }

export class CustomPageListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customPages: [],
      loading: true,
      displayError: "",
    };
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return;
    }
    this.setState({ loading: true }, () =>
      CustomPagesApi.list()
        .then((response) => {
          this.setState({
            customPages: response.data.results,
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { customPages } = this.state;
    const linksBase = UrlBaseUtil.getAppDomain() + "/post/";

    return (
      <div className="col-sm-12">
        <CustomPageListPage
          customPages={customPages}
          hasEditPermission={true}
          linksBase={linksBase}
        />
        {this.state.loading && <Loader />}
      </div>
    );
  }
}
