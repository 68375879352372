import React from "react";
import Modal from "react-modal";

// import { CountryHlp, StateHlp } from "../../../models/Common";
import { /* SchoolFilter, */ EmptySchoolFilter } from "../../../models/School";
import { FilterModalStyles } from "../../common/Utils";
import { SchoolFilterUI } from "./SchoolFilter";
// import BasicDataApi from "../../../services/resources/BasicDataApi";
import USStatesApi from "../../../services/resources/USStatesApi";
import CountriesApi from "../../../services/resources/CountriesApi";
// import { ApiCallErrorHandler } from "../../lib/coc-common-scripts";

// interface SchoolFilterButtonProps {
//   currentFilters: SchoolFilter;
//   handleFiltersChanged: (filters: SchoolFilter) => void;
// }

// interface SchoolFilterButtonState {
//   filterState: SchoolFilter;
//   countries: Array<CountryHlp>;
//   states: Array<StateHlp>;
//   loading: boolean;
//   showingFilters: boolean;
//   displayMessage: string;
//   mobileMode: boolean;
//   mql: MediaQueryList;
// }

export class SchoolFilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterState: props.currentFilters || EmptySchoolFilter(),
      countries: [],
      states: [],
      loading: true,
      showingFilters: false,
      displayMessage: "",
      mobileMode: false,
      mql: window.matchMedia(`(min-width: 800px)`),
    };

    this.handleStateFiltersChanged = this.handleStateFiltersChanged.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  /** @param {SchoolFilter} newFilterState */
  handleStateFiltersChanged(newFilterState) {
    this.setState({ filterState: newFilterState });
  }
  /** @param {boolean} show */
  showHideFilters(show) {
    this.setState({ showingFilters: show });
  }

  handleCancel() {
    this.showHideFilters(false);
    this.handleStateFiltersChanged(this.props.currentFilters);
  }

  getButtonText() {
    const { mobileMode, filterState } = this.state;

    if (mobileMode) {
      return "Filter Results";
    }

    const filterText =
      (filterState.name ? " Name: " + filterState.name : "") +
      (filterState.countries ? " Countries: " + filterState.countries : "") +
      (filterState.states ? " States: " + filterState.states : "") +
      (filterState.association !== "All"
        ? filterState.association === "FullTime"
          ? " Full Service Only"
          : " Limited Service Only"
        : "") +
      (filterState.status ? " Status: " + filterState.status : "");

    return (
      "Filter Results" + (mobileMode || !filterText ? "" : ":" + filterText)
    );
  }

  mediaQueryChanged() {
    this.setState((state) => ({
      ...state,
      mobileMode: !(state && state.mql && state.mql.matches),
    }));
  }
  /** @param {SchoolFilterButtonProps} newProps */
  componentWillReceiveProps(newProps) {
    this.handleStateFiltersChanged(newProps.currentFilters);
  }

  componentDidMount() {
    const mql = this.state.mql;
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql, mobileMode: !mql.matches });

    // const sortByName = (a, b) => (a.name < b.name ? -1 : 1);

    this.setState({ loading: true }, () => {
      // BasicDataApi.listCountriesAndStates()
      //     .then(response => {
      //         this.setState({
      //             countries: response.data.results.countries.sort(sortByName),
      //             states: response.data.results.states.sort(sortByName),
      //             loading: false,
      //         });
      //     })
      //     .catch(err => {
      //         const errors = ApiCallErrorHandler(err, true);
      //         this.setState({ loading: false, displayMessage: errors.join("\n") });
      //     });
      this.setState({
        countries: CountriesApi.getList().map((c) => ({
          id: c.value,
          name: c.label,
        })),
        states: USStatesApi.getList().map((c) => ({
          id: c.value,
          name: c.label,
        })),
        loading: false,
      });
    });
  }

  render() {
    const { mobileMode, showingFilters, filterState, countries, states } =
      this.state;

    return (
      <div>
        <div className="filter-component">
          <span className="filter-info">
            {!this.state.mobileMode && this.getButtonText()}
          </span>
          <button
            className="btn btn-primary btn-md clear"
            onClick={() => this.showHideFilters(true)}
          >
            <i className="icon icon-filter icon-white">
              <span className="path1" />
              <span className="path2" />
            </i>{" "}
            Filter
          </button>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Modal
              isOpen={showingFilters}
              onRequestClose={() => this.showHideFilters(false)}
              contentLabel="Result Filter Menu"
              style={
                mobileMode
                  ? FilterModalStyles.modalMobile
                  : FilterModalStyles.modal
              }
            >
              <SchoolFilterUI
                filterState={filterState}
                countryList={countries}
                stateList={states}
                handleFiltersChanged={this.handleStateFiltersChanged}
              />
              <div className="text-right">
                <button
                  onClick={() => {
                    this.showHideFilters(false);
                    this.props.handleFiltersChanged(filterState);
                  }}
                  className="btn btn-primary"
                >
                  Filter
                </button>
                &nbsp;&nbsp;
                <button onClick={this.handleCancel} className="btn btn-primary">
                  Cancel
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
