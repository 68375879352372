import AuthClient from "./AuthClient";

const ChabadHousesApi = {
  getChabadHousesDetails: async (cancelToken, filterToHasCampus = true) => {
    const chabadHousesResponse = await AuthClient.get("ChabadHouses/Detailed", {
      cancelToken,
    });

    const chabadHouses = chabadHousesResponse.data.payload || [];

    return filterToHasCampus
      ? chabadHouses.filter((ch) => !!ch.campusID)
      : chabadHouses;
  },
};

export default ChabadHousesApi;
