import * as React from "react";
import { ResponsiveContainer, BarChart, XAxis, Bar, Text } from "recharts";

import {
  GetAgeArray,
  ChartColors,
} from "../../assets/scripts/reportingHelpers";

const AgeBarChart = ({ ageArray, displayName, chartColors = ChartColors }) => {
  const countByAgeArray = GetAgeArray(ageArray || []);
  return (
    <div className="well text-center">
      {countByAgeArray && (
        <ResponsiveContainer width="100%" height={250}>
          {countByAgeArray.length === 0 ? (
            <Text className="no-participants-message">
              {"No " + displayName}
            </Text>
          ) : (
            <BarChart data={countByAgeArray} margin={{ top: 15 }}>
              <XAxis dataKey="age" />
              <Bar
                dataKey="count"
                fill={chartColors[0]}
                label={true}
                isAnimationActive={false}
              />
            </BarChart>
          )}
        </ResponsiveContainer>
      )}
      <p className="reporting-stats-label">{displayName} by Age</p>
    </div>
  );
};

export default AgeBarChart;
