import React from "react";
import { browserHistory } from "react-router";
import Loader from "../../../common/Loader";
import ProgramBreadcrumbsHeader from "../../../common/ProgramBreadcrumbsHeader";

import StudentBackgroundInfo from "./background/StudentBackgroundInfo";
import StudentDetails from "./StudentDetails";
import StudentEnrollmentDetails from "./enrollments/StudentEnrollmentDetails";

import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import StudentsApi from "../../../../services/resources/StudentsApi";
import EduApi from "../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import { Unauthorized } from "../../../../lib/coc-common-components";
import queryString from "query-string";
import axios from "axios";

export default class StudentProfilePage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    student: null,
    studentEduEnrollments: [],
    errorMessage: "",
    loading: false,

    tabs: [
      {
        component: StudentBackgroundInfo,
        id: "background",
        name: "Background",
      },
    ],
    tabIndex: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.EduFullView)) {
      this.setState({ authorized: false });
    } else if (!AuthService.UserHasClaim(PermissionClaims.EduFullEdit)) {
      this.setState({ viewOnly: true });
    }
    const {
      params: { studentId },
    } = this.props;

    this.getStudentDetails(studentId);
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  toTab = (index, clearSub) => {
    const { tabs } = this.state;
    const {
      location: { pathname, query },
    } = this.props;

    if (index !== this.state.tabIndex) {
      this.setState({ tabIndex: index });

      query.tab = tabs[index].id;
      if (clearSub) query.sub = undefined;
      browserHistory.replace(`${pathname}?${queryString.stringify(query)}`);
    }
  };

  getStudentDetails = async (studentId, setLoading = true) => {
    if (setLoading) {
      this.setState({
        loading: true,
      });
    }

    try {
      const [student, studentEduEnrollments] = await Promise.all([
        StudentsApi.getStudent(this.apiSignal.token, studentId),
        EduApi.getEduStudentEnrollments(this.apiSignal.token, studentId),
      ]);

      this.setState({
        student,
        studentEduEnrollments,
        loading: false,
      });

      if (studentEduEnrollments && studentEduEnrollments.length) {
        this.getEduTabs(studentEduEnrollments);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and student details could not be retrieved. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  getEduTabId = (eduEnrollment) => eduEnrollment.id.toString();

  getEduTabs = (eduEnrollments) => {
    const tabs = [...this.state.tabs];

    eduEnrollments.forEach((enrollment) =>
      tabs.push({
        component: StudentEnrollmentDetails,
        id: this.getEduTabId(enrollment),
        name: enrollment.eduProgramName,
      }),
    );

    this.setState(
      {
        tabs,
      },
      () => {
        const {
          location: {
            query: { tab },
          },
        } = this.props;
        if (tab) {
          const currentTabIndex = tabs
            .map((t) => t.id)
            .indexOf(decodeURIComponent(tab));
          if (currentTabIndex > 0) {
            this.toTab(currentTabIndex);
          }
        }
      },
    );
  };

  render() {
    const {
      location,
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      student,
      studentEduEnrollments,
      errorMessage,
      loading,
      tabs,
      tabIndex,
      viewOnly,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="courses-students-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/edu/${sched.scheduleID}?tab=students`,
              title: `Manage ${sched.scheduleName || sched.programName}`,
            },
          ]}
          pageTitle="Students"
          scheduleId={scheduleId}
        />
        <p
          className="mr-24 link-text flex flex-align-center mb-24"
          onClick={() => browserHistory.goBack()}
        >
          <i className="material-icons link-text xl-text mr-8">arrow_back</i>
          Go back
        </p>
        {loading ? (
          <Loader />
        ) : errorMessage || !student ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>
              {errorMessage ||
                "Sorry, something went wrong and course details could not be retrieved."}
            </p>
          </div>
        ) : (
          student && (
            <React.Fragment>
              <StudentDetails student={student} />
              <div
                className="student-profile-tabs flex"
                style={{ marginLeft: "2px" }}
              >
                {tabs.map((tab, index) => (
                  <p
                    key={index}
                    className={`large-text ${index > 0 ? "ml-40" : ""} ${
                      tabIndex === index ? "active" : ""
                    }`}
                    onClick={() => this.toTab(index, true)}
                  >
                    {tab.name}
                  </p>
                ))}
              </div>
              <div className="card students-profile-info-card">
                {React.createElement(tabs[tabIndex].component, {
                  location,
                  student,
                  studentEnrollment: studentEduEnrollments.find(
                    (e) => this.getEduTabId(e) === tabs[tabIndex].id,
                  ),
                  viewOnly,
                })}
              </div>
            </React.Fragment>
          )
        )}
      </div>
    );
  }
}
