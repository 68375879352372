import AuthRequest from "./AuthRequest";

// import { CustomPage } from "../../models/CustomPage";

const baseUrl = "/api/custompages";

const CustomPagesApi = {
  /** @param {CustomPage} customPage */
  create: (customPage) => {
    return AuthRequest.post(`${baseUrl}`, customPage);
  },
  /** @param {CustomPage} customPage */
  update: (customPage) => {
    return AuthRequest.post(`${baseUrl}/${customPage.id}`, customPage);
  },
  /** @param {number} id */
  delete: (id) => {
    return AuthRequest.delete(`${baseUrl}/${id}`);
  },
  list: () => {
    return AuthRequest.get(`${baseUrl}`, {});
  },
  /** @param {number} id */
  get: (id) => {
    return AuthRequest.get(`${baseUrl}/${id}`);
  },
};

export default CustomPagesApi;
