import React from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../../shared/ConfirmationModal";
import FadeOutErrorMessage from "../../../shared/FadeOutErrorMessage";
import Loader from "../../../common/Loader";
import ProgramBreadcrumbsHeader from "../../../common/ProgramBreadcrumbsHeader";
import Radio from "../../../shared/Radio";
import Select from "react-select";
import ProgramsApi from "../../../../services/resources/ProgramsApi";
import SystemApi from "../../../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../../../lib/coc-common-scripts";
import AuthService, {
  PermissionClaims,
} from "../../../../services/AuthService";
import { Unauthorized } from "../../../../lib/coc-common-components";
import { getFormattedValuesForForm, removeEmptyFromObj } from "../../../../lib";
import { notify } from "react-notify-toast";
import { nanoid } from "nanoid/non-secure";
import axios from "axios";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class ProgramScheduleApplicationSettingsPage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    settings: null,
    initialSettings: null,

    errorMessage: "",
    loading: true,

    isRequestedPageLeave: false,
    showCancelChangesConfirmationModal: false,
    showSubmitConfirmationModal: false,
    submitAttempted: false,
    submitSettingsErrorMessage: "",
    submitSettingsLoading: false,

    systemLists: {
      applicationStatuses: [],
    },
    systemListsErrorMessage: "",
  };

  apiSignal = axios.CancelToken.source();
  incompleteSubmissionErrorMessage = "Please complete required fields";

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ProgramEdit)) {
      this.setState({ authorized: false });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getApplicationSettings();
    this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { initialSettings, isRequestedPageLeave, settings } = this.state;

    if (!isRequestedPageLeave && !_isEqual(settings, initialSettings)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  getSystemLists = async () => {
    try {
      const systemLists = await SystemApi.lists(["applicationStatuses"]);
      this.setState({ systemLists });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          systemListsErrorMessage: ApiCallErrorMessageHandler(err),
        });
      }
    }
  };

  getApplicationSettings = async () => {
    const {
      params: { programId, scheduleId },
    } = this.props;

    this.setState({
      loading: true,
    });

    try {
      let settings = await ProgramsApi.getScheduleApplicationSettings(
        this.apiSignal.token,
        programId,
        scheduleId,
        true,
      );
      settings = getFormattedValuesForForm(settings);

      this.setState({
        initialSettings: _cloneDeep(settings),
        loading: false,
        settings,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and settings could not be retrieved. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  onChangeSettings = (name, value, otherUpdates) => {
    let settings = _cloneDeep(this.state.settings);
    _set(settings, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(settings, update, otherUpdates[update]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ settings }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { submitSettingsErrorMessage } = this.state;
        if (
          submitSettingsErrorMessage &&
          submitSettingsErrorMessage === this.incompleteSubmissionErrorMessage
        ) {
          const isValid = this.validateSettings();
          if (isValid) {
            this.setState({
              submitSettingsErrorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeSettingsEvt = ({ target: { name, value } }) => {
    return this.onChangeSettings(name, value);
  };

  onCancelSettingsChanges = () => {
    const { settings, initialSettings } = this.state;
    if (_isEqual(settings, initialSettings)) {
      this.navToProgramSchedule();
      return;
    }

    this.setState({ showCancelChangesConfirmationModal: true });
  };

  cancelSettingsChanges = () => {
    this.setState({ isRequestedPageLeave: true }, this.navToProgramSchedule);
  };

  navToProgramSchedule = () => {
    const {
      params: { programId, scheduleId },
    } = this.props;
    browserHistory.push(`/programs/${programId}?sched=${scheduleId}`);
  };

  onSubmitSettings = () => {
    this.setState({
      submitAttempted: true,
      submitSettingsErrorMessage: "",
    });

    const isValid = this.validateSettings();
    if (!isValid) {
      this.setState({
        submitSettingsErrorMessage: this.incompleteSubmissionErrorMessage,
      });
      return;
    }

    this.setState({
      showSubmitConfirmationModal: true,
    });
  };

  submitSettings = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitSettingsErrorMessage: "",
      submitSettingsLoading: true,
    });

    try {
      const {
        params: { programId, scheduleId },
      } = this.props;

      const settingsForSubmission = _cloneDeep(this.state.settings);
      removeEmptyFromObj(settingsForSubmission);
      const savedSettings = await ProgramsApi.submitScheduleApplicationSettings(
        this.apiSignal.token,
        programId,
        scheduleId,
        settingsForSubmission,
      );

      const settings = getFormattedValuesForForm(savedSettings);
      this.setState(
        {
          initialSettings: settings,
          settings: _cloneDeep(settings),
          submitSettingsLoading: false,
          submitAttempted: false,
        },
        () => {
          notify.show("Your settings have been saved", "success");
        },
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitSettingsErrorMessage: ApiCallErrorMessageHandler(err),
          submitSettingsLoading: false,
        });
        this.getApplicationSettings();
      }
    }
  };

  validateSettings = () => {
    return !this.state.settings.items.find((i) => i.name === "");
  };

  getErrorClassName = (className, err) =>
    err ? `${className} error` : className;

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      errorMessage,
      initialSettings,
      loading,
      settings,
      showCancelChangesConfirmationModal,
      showSubmitConfirmationModal,
      submitSettingsErrorMessage,
      submitSettingsLoading,
      systemLists: { applicationStatuses = [] } = {},
      viewOnly,
    } = this.state;

    const {
      allowNotes,
      paymentInstallments,
      statuses,
      items,
      // useGroupPayments,
    } = settings || {};

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    return (
      <div className="program-application-form-page page container">
        <ProgramBreadcrumbsHeader
          pageTitle="Application Settings"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !settings ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <div className="card full-width">
            <div className="mb-16">
              <p className="flex flex-align-center xxl-text">
                <i
                  className="material-icons link-text mr-16"
                  onClick={() => browserHistory.goBack()}
                >
                  arrow_back
                </i>
                Application Settings
              </p>
              {/* show a message prompting user to save when there are no initial application settings saved for this program schedule yet */}
              {!initialSettings.id && (
                <p className="accent-text small-text mt-4">
                  After confirming, click Save to complete application settings
                  configuration
                </p>
              )}
            </div>
            <div className="program-application-form">
              <div className="program-application-form-section">
                <p className="medium-text fw-700 mt-16 mb-16">General</p>
                <div className="mb-24 flex flex-align-center">
                  <label
                    className="accent-text-dark"
                    style={{ minWidth: "200px", width: "200px" }}
                  >
                    Statuses
                  </label>
                  <Select
                    className="custom-select"
                    clearable={false}
                    disabled={viewOnly}
                    multi={true}
                    onChange={(val) => {
                      //don't allow removing the Applied status
                      let statusValues = val.map((v) => v.value);
                      if (statusValues.indexOf("Applied") < 0) {
                        statusValues = ["Applied", ...statusValues];
                      }
                      this.onChangeSettings("statuses", statusValues);
                    }}
                    options={
                      applicationStatuses &&
                      applicationStatuses.map((status) => ({
                        value: status.enumValue,
                        label: status.displayValue,
                      }))
                    }
                    placeholder="Select Statuses"
                    value={statuses}
                  />
                </div>
                <div className="mb-24 flex">
                  <label
                    className="accent-text-dark"
                    style={{ width: "200px" }}
                  >
                    Notes Field
                  </label>
                  <Radio
                    disabled={viewOnly}
                    name="allowNotes"
                    onChange={this.onChangeSettings}
                    options={[
                      {
                        display: "On",
                        value: true,
                      },
                      {
                        display: "Off",
                        value: false,
                      },
                    ]}
                    value={allowNotes}
                  />
                </div>
              </div>
              <div className="program-application-form-section">
                <p className="medium-text fw-700 mt-16 mb-16">Payment</p>
                <div
                  className="mb-24 flex flex-align-center"
                  style={{ width: 160 }}
                >
                  <label
                    className="accent-text-dark"
                    style={{ minWidth: "200px", width: "200px" }}
                  >
                    Payment Installments
                  </label>
                  <Select
                    className="custom-select"
                    clearable={false}
                    disabled={viewOnly}
                    onChange={({ value }) =>
                      this.onChangeSettings("paymentInstallments", value)
                    }
                    options={Array(13)
                      .fill()
                      .map((_, i) => ({
                        value: i,
                        label: i,
                      }))}
                    searchable={false}
                    style={{ width: 160 }}
                    value={paymentInstallments}
                  />
                </div>
              </div>
            </div>

            <div className="program-application-form-section ">
              <div className="mb-16">
                <div className="flex mobile-flex-justify-space flex-align-center mt-16">
                  <span className="medium-text fw-700 ">Items</span>
                  <i
                    className="material-icons link-text profile-add-icon ml-16"
                    onClick={() => {
                      const uuid = nanoid(12);
                      var newItems = [...items, { name: "", guid: uuid }];
                      this.onChangeSettings("items", newItems);
                    }}
                  >
                    add_circle
                  </i>
                </div>
                <div className="small-text mt-8">
                  Add items to enable the option to set quantities for items
                  within registrations
                </div>
                <table>
                  {items &&
                    items.map((item, index) => (
                      <tr className="flex mobile-flex-justify-space flex-align-center">
                        <td>
                          <input
                            type="text"
                            value={item.name}
                            name={`item[${index}].name`}
                            readOnly={viewOnly}
                            className={this.getErrorClassName(
                              "custom-input mt-16",
                              this.state.submitAttempted && !item.name,
                            )}
                            placeholder="Item name"
                            onChange={(e) => {
                              var newItems = items.map((x, i) =>
                                i === index
                                  ? { ...x, name: e.target.value }
                                  : { ...x },
                              );
                              this.onChangeSettings("items", newItems);
                            }}
                          />
                        </td>
                        <td>
                          {viewOnly ? null : (
                            <i
                              className="material-icons link-text hover-delete ml-16 mt-16"
                              style={{ fontSize: 20 }}
                              onClick={() => {
                                let newItems;
                                if (item.id) {
                                  newItems = items.filter(
                                    (i) => i.id !== item.id,
                                  );
                                } else if (item.guid) {
                                  newItems = items.filter(
                                    (i) => i.guid !== item.guid,
                                  );
                                }
                                this.onChangeSettings("items", newItems);
                              }}
                            >
                              delete
                            </i>
                          )}
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
            {!viewOnly && (
              <div className="program-application-form-btns relative flex flex-justify-space mt-16">
                <button
                  className="custom-btn btn-light uppercase-text"
                  disabled={submitSettingsLoading}
                  onClick={this.onCancelSettingsChanges}
                >
                  Cancel
                </button>
                {submitSettingsLoading ? (
                  <div className="medium-loader">
                    <Loader />
                  </div>
                ) : (
                  <React.Fragment>
                    <button
                      className="custom-btn btn-accent uppercase-text"
                      disabled={
                        initialSettings.id &&
                        _isEqual(settings, initialSettings)
                      }
                      onClick={this.onSubmitSettings}
                    >
                      Save
                    </button>
                    {!!submitSettingsErrorMessage && (
                      <FadeOutErrorMessage
                        message={submitSettingsErrorMessage}
                        onTimeout={() =>
                          this.setState({
                            submitSettingsErrorMessage: "",
                          })
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showCancelChangesConfirmationModal: false,
                  showSubmitConfirmationModal: false,
                })
              }
              cancelText={showCancelChangesConfirmationModal && "No"}
              confirm={
                showCancelChangesConfirmationModal
                  ? this.cancelSettingsChanges
                  : this.submitSettings
              }
              confirmText={showCancelChangesConfirmationModal && "Yes"}
              message={`Are you sure you want to ${
                showCancelChangesConfirmationModal
                  ? "cancel your changes"
                  : "save changes"
              } to application settings?`}
              show={
                showCancelChangesConfirmationModal ||
                showSubmitConfirmationModal
              }
            />
          </div>
        )}
      </div>
    );
  }
}
