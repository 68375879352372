import React from "react";
import { Link } from "react-router";
import Panel from "react-bootstrap/lib/Panel";
import PanelGroup from "react-bootstrap/lib/PanelGroup";

import AuthService, { PermissionClaims } from "../../../services/AuthService";
// import { NavMenuItem } from "../../../models/Menu";

// interface NavMenuProps {
//   items: Array<NavMenuItem>;
// }

const NavMenu = ({ items = [], count }) => {
  /** @param {NavMenuItem} a
   * @param {NavMenuItem} b
   * @returns {number}
   */
  const sortMenuItems = (a, b) => a.order - b.order;
  /** @param {NavMenuItem} menuItem */
  const renderMenuItem = (menuItem, level) => {
    return menuItem.items ? (
      renderMenuItems(menuItem, level)
    ) : (
      <li
        key={menuItem.order + "_item"}
        className="nested-nav"
        title={menuItem.text}
      >
        <Link className={`btn btn-primary btn-sm nav`} to={menuItem.urlTo}>
          {menuItem.text}
        </Link>
      </li>
    );
  };
  /** @param {NavMenuItem} menuItem */
  const renderMenuItems = (menuItem, level = 1) => {
    return (
      <Panel
        collapsible={true}
        header={menuItem.text}
        key={menuItem.order + "_panel"}
        defaultExpanded={level === 1 || level === 2 || level === 3}
      >
        <ul className="nav">
          {menuItem.items &&
            menuItem.items.map((item) => renderMenuItem(item, level + 1))}
        </ul>
      </Panel>
    );
  };
  /** @param {Array<NavMenuItem>} menuItems */
  const renderMenuHeaders = (menuItems) => {
    return (
      <React.Fragment>
        <PanelGroup className="sidebar-content">
          {menuItems.sort(sortMenuItems).map((item) => renderMenuItems(item))}
        </PanelGroup>
      </React.Fragment>
    );
  };

  return renderMenuHeaders(items);
};

export default NavMenu;
