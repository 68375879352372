import React from "react";
import Table from "react-bootstrap/lib/Table";
import moment from "moment";
import { GroupContainer } from "../Common/GroupContainer";

// import { HistoryEvent } from "../Models/Events";

// export interface EventListGroupProps {
//   groupBy: "Year" | "Program";
//   headerText: string;
//   iconClassName: string;
//   eventList: Array<HistoryEvent>;
//   disabled: boolean;
// }

// export interface EventListGroupState { }

export class EventListGroup extends React.Component {
  render() {
    const { headerText, iconClassName, groupBy, eventList } = this.props;

    return (
      <GroupContainer headerText={headerText} iconClassName={iconClassName}>
        <Table bsClass="table table-custom" responsive={true}>
          <thead>
            <tr>
              <td style={{ width: "40%" }}>Activity</td>
              <td style={{ width: "30%" }}>
                {groupBy === "Year" ? "Occurred On" : "Year"}
              </td>
              <td style={{ width: "15%" }} />
              <td style={{ width: "15%" }} />
            </tr>
          </thead>
          <tbody>
            {eventList.length === 0 ? (
              <tr>
                <td colSpan={4}>
                  <span className="no-results">No Events</span>
                </td>
              </tr>
            ) : (
              eventList.map((evt) => (
                <tr key={evt.id}>
                  <td>{evt.type}</td>
                  <td>
                    {moment
                      .utc(evt.occurredAt)
                      .format(groupBy === "Year" ? "MMM D, YYYY" : "YYYY")}
                  </td>
                  <td />
                  <td />
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </GroupContainer>
    );
  }
}
