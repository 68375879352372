import React from "react";
import Modal from "react-modal";
import Select from "react-select";
import "react-select/dist/react-select.css";

import USStatesApi from "../../services/resources/USStatesApi";
import CountriesApi from "../../services/resources/CountriesApi";
import { FilterModalStyles } from "./Utils";
import { Fragment } from "react";
import SystemApi from "../../services/resources/SystemApi";

const mql = window.matchMedia(`(min-width: 800px)`);

const initialState = {
  showResultsFilterMenu: false,
  name: "",
  gender: "All",
  countries: "All",
  states: "All",
  categories: [],
  disabledStates: false,
  activeFilters: "",
  mql: mql,
  mobileMode: false,
  yearStartedFrom: "",
  yearStartedTo: "",
  includeInactive: false,
  systemLists: {
    shliachCategories: [],
  },
};

export class DirectoryFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState, name: props.nameSearch };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.openResultFilterMenu = this.openResultFilterMenu.bind(this);
    this.closeResultFilterMenu = this.closeResultFilterMenu.bind(this);
    this.getStates = this.getStates.bind(this);
    this.getCountries = this.getCountries.bind(this);
    this.setName = this.setName.bind(this);
    this.setGender = this.setGender.bind(this);
    this.setYearStartedFrom = this.setYearStartedFrom.bind(this);
    this.setYearStartedTo = this.setYearStartedTo.bind(this);
    this.handleSelectChangeCountries =
      this.handleSelectChangeCountries.bind(this);
    this.handleSelectChangeStates = this.handleSelectChangeStates.bind(this);
    this.handleSelectChangeCategories =
      this.handleSelectChangeCategories.bind(this);
    this.getActiveFilters = this.getActiveFilters.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clearFilters) {
      this.setState(
        {
          name: newProps.nameSearch,
          gender: "All",
          countries: "All",
          states: "All",
          categories: [],
          yearStartedFrom: "",
          yearStartedTo: "",
        },
        () => this.setState({ activeFilters: this.getActiveFilters() }),
      );
    } else {
      this.setState({ activeFilters: this.getActiveFilters() });
    }
  }
  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
    this.setState({ mql: mql, mobileMode: !mql.matches });
  }
  componentDidMount() {
    this.setState({
      activeFilters: this.getActiveFilters(),
    });
    this.getSystemLists();
  }

  getSystemLists = async () => {
    const systemLists = await SystemApi.lists(["shliachCategories"]);
    this.setState({ systemLists });
  };

  mediaQueryChanged() {
    this.setState({
      mobileMode: !this.state.mql.matches,
    });
  }
  setName(val) {
    this.setState({ name: val.currentTarget.value });
  }
  setGender(val) {
    this.setState({ gender: val.currentTarget.value });
  }
  setIncludeInactive = (val) => {
    const value = val.currentTarget.value;
    if (value === "true") {
      this.setState({ includeInactive: true });
    } else if (value === "false") {
      this.setState({ includeInactive: false });
    }
  };
  setYearStartedFrom(newYearFrom) {
    this.setState({ yearStartedFrom: newYearFrom });
  }
  setYearStartedTo(newYearTo) {
    this.setState({ yearStartedTo: newYearTo });
  }
  getStates() {
    return USStatesApi.getList(false); //false to get unabbreviated states
  }
  getCountries() {
    return CountriesApi.getList();
  }
  openResultFilterMenu() {
    this.setState({ showResultsFilterMenu: true });
  }
  closeMenu() {
    this.setState({ showResultsFilterMenu: false });
  }
  getActiveFilters() {
    let filterCaption = "";
    filterCaption += this.state.name ? "Name: " + this.state.name + ", " : "";
    filterCaption +=
      this.state.countries !== "All" ? this.state.countries + ", " : "";
    filterCaption +=
      this.state.states !== "All" ? this.state.states + ", " : "";
    if (this.props.contactType === "shliach") {
      filterCaption +=
        this.state.gender !== "All"
          ? this.state.gender + ", "
          : "Shliach and Shlucha, ";
      filterCaption +=
        this.state.categories.length > 0
          ? this.state.categories.map((c) => c.label).join(" and ") + ", "
          : "";
      filterCaption +=
        this.state.yearStartedFrom > 0 && this.state.yearStartedTo > 0
          ? `Year started from ${this.state.yearStartedFrom} to ${this.state.yearStartedTo}, `
          : this.state.yearStartedFrom > 0
          ? `Year started from ${this.state.yearStartedFrom}, `
          : this.state.yearStartedTo > 0
          ? `Year started until ${this.state.yearStartedTo}, `
          : "";
    }

    if (filterCaption.trim().endsWith(",")) {
      filterCaption = filterCaption.trim().slice(0, -1);
    }
    return filterCaption;
  }
  closeResultFilterMenu() {
    this.closeMenu();
    this.setState(
      {
        activeFilters: this.getActiveFilters(),
      },
      () => this.props.handleFilters(this.state),
    );
  }
  handleSelectChangeCountries(value) {
    this.setState({ countries: value.length === 0 ? "All" : value });
    if (value === "USA" || value.length === 0) {
      this.setState({ disabledStates: false });
      return;
    }
    this.setState({ disabledStates: true });
    this.setState({ states: "All" });
  }
  handleSelectChangeStates(value) {
    this.setState({ states: value.length === 0 ? "All" : value });
  }
  handleSelectChangeCategories(value) {
    this.setState({
      categories: value.length === 0 ? [] : value,
    });
  }

  render() {
    const {
      systemLists: { shliachCategories },
    } = this.state;

    const renderShluchim = () => {
      const lowestYearFromInDtabase = 1960;

      const filterYearsFrom = []; // : Array<{ value: number; label: string }>
      const fromStart = lowestYearFromInDtabase;
      const fromEnd = this.state.yearStartedTo || new Date().getUTCFullYear();

      for (let yearFrom = fromStart; yearFrom <= fromEnd; yearFrom++) {
        filterYearsFrom.push({ value: yearFrom, label: yearFrom.toString() });
      }

      const filterYearsTo = []; // : Array<{ value: number; label: string }>
      const toStart = this.state.yearStartedFrom || lowestYearFromInDtabase;
      const toEnd = new Date().getUTCFullYear();

      for (let yearTo = toStart; yearTo <= toEnd; yearTo++) {
        filterYearsTo.push({ value: yearTo, label: yearTo.toString() });
      }

      return (
        <div>
          Genders
          <div className="radio">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Shliach"
                  checked={this.state.gender === "Shliach"}
                  onChange={this.setGender}
                />
                Shliach
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Shlucha"
                  checked={this.state.gender === "Shlucha"}
                  onChange={this.setGender}
                />
                Shlucha
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Unknown"
                  checked={this.state.gender === "Unknown"}
                  onChange={this.setGender}
                />
                Unknown
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="All"
                  checked={this.state.gender === "All"}
                  onChange={this.setGender}
                />
                Shliach and Shlucha
              </label>
            </div>
          </div>
          <br />
          <div style={{ display: "table", width: "100%" }}>
            <div style={{ display: "table-row" }}>
              <div style={{ display: "table-cell", width: "120px" }}>
                Year Started From
              </div>
              <div style={{ display: "table-cell" }}>
                <Select
                  name="form-field-name"
                  options={filterYearsFrom}
                  onChange={this.setYearStartedFrom}
                  multi={false}
                  simpleValue={true}
                  value={this.state.yearStartedFrom}
                  placeholder={"Select"}
                />
              </div>
              <div
                style={{ display: "table-cell", padding: "5px", width: "10px" }}
              >
                To
              </div>
              <div style={{ display: "table-cell" }}>
                <Select
                  name="form-field-name"
                  options={filterYearsTo}
                  onChange={this.setYearStartedTo}
                  multi={false}
                  simpleValue={true}
                  value={this.state.yearStartedTo}
                  placeholder={"Select"}
                />
              </div>
            </div>
          </div>
          <br />
        </div>
      );
    };

    return (
      <div>
        <div className="filter-component">
          <span className="filter-info">
            {!this.state.mobileMode
              ? this.state.activeFilters.length > 0 &&
                `${this.state.activeFilters} `
              : ""}
          </span>
          <button
            className="btn btn-primary btn-md clear"
            onClick={this.openResultFilterMenu}
          >
            <i className="icon icon-filter icon-white">
              <span className="path1" />
              <span className="path2" />
            </i>{" "}
            Filter
          </button>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <Modal
              isOpen={this.state.showResultsFilterMenu}
              onRequestClose={this.closeMenu}
              contentLabel="Result Filter Menu"
              style={
                this.state.mobileMode
                  ? FilterModalStyles.modalMobile
                  : FilterModalStyles.modal
              }
            >
              <h3>Filter Results</h3>
              Name{" "}
              <input
                type="text"
                className="form-control"
                value={this.state.name || ""}
                onChange={this.setName}
              />
              <br />
              <br />
              Country{" "}
              <Select
                name="form-field-name"
                options={this.getCountries()}
                onChange={this.handleSelectChangeCountries}
                multi={true}
                simpleValue={true}
                value={this.state.countries}
              />
              <br />
              <br />
              US State{" "}
              <Select
                name="form-field-name"
                options={this.getStates()}
                onChange={this.handleSelectChangeStates}
                multi={true}
                simpleValue={true}
                value={this.state.states}
                disabled={this.state.disabledStates}
                closeOnSelect={false}
              />
              <br />
              {this.props.contactType !== "student" && (
                <Fragment>
                  Category
                  <Select
                    name="form-field-name"
                    options={
                      shliachCategories &&
                      shliachCategories.map((c) => ({
                        label: c.displayValue,
                        value: c.enumValue,
                      }))
                    }
                    onChange={this.handleSelectChangeCategories}
                    multi={true}
                    value={this.state.categories}
                    closeOnSelect={false}
                  />
                </Fragment>
              )}
              <br />
              {this.props.contactType === "shliach" && renderShluchim()}
              Status
              <div className="radio">
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={false}
                      checked={this.state.includeInactive === false}
                      onChange={this.setIncludeInactive}
                    />
                    Active Only
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={true}
                      checked={this.state.includeInactive === true}
                      onChange={this.setIncludeInactive}
                    />
                    {`Active and ${
                      this.props.contactType === "shliach"
                        ? "Archived"
                        : "Inactive"
                    }`}
                  </label>
                </div>
              </div>
              <div className="text-right">
                <button
                  onClick={this.closeResultFilterMenu}
                  className="btn btn-primary"
                >
                  Filter
                </button>
                &nbsp;&nbsp;
                <button onClick={this.closeMenu} className="btn btn-primary">
                  Cancel
                </button>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}
