import React from "react";

export default class LegalText extends React.PureComponent {
  render() {
    const {
      getErrorClassName,
      onChangeEvent,
      raffle: { fullLegalText, shortLegalText, shortUkLegalText },
      submitAttempted,
    } = this.props;
    return (
      <div className="new-raffle-step">
        <div className="new-raffle-step-section">
          <p className="xl-text">Legal Text</p>
        </div>
        <div>
          <p className="medium-text fw-700 mb-16">Legal Text Summary</p>
          <div className="legal-text short">
            <textarea
              className={getErrorClassName(
                "custom-input mt-8",
                submitAttempted && !shortLegalText,
              )}
              name="shortLegalText"
              onChange={onChangeEvent}
              tabIndex={10}
              value={shortLegalText}
            />
          </div>
        </div>
        <div>
          <p className="medium-text fw-700 mb-16">UK Legal Text Summary</p>
          <div className="legal-text short">
            <textarea
              className={getErrorClassName(
                "custom-input mt-8",
                submitAttempted && !shortUkLegalText,
              )}
              name="shortUkLegalText"
              onChange={onChangeEvent}
              tabIndex={10}
              value={shortUkLegalText}
            />
          </div>
        </div>
        <div className="mt-24">
          <p className="medium-text fw-700 mb-16">Full Legal Text</p>
          <div className="legal-text long">
            <textarea
              className={getErrorClassName(
                "custom-input mt-8",
                submitAttempted && !fullLegalText,
              )}
              name="fullLegalText"
              onChange={onChangeEvent}
              tabIndex={20}
              value={fullLegalText}
            />
          </div>
        </div>
      </div>
    );
  }
}
