import React from "react";
import moment from "moment";

import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import { Loader } from "../../../lib/coc-common-components";

import viewHistory from "../../../services/ViewHistory";
// import AuthService, { PermissionClaims } from "../../../services/AuthService";
import CustomEventsApi from "../../../services/resources/CustomEventsApi";
import { EmptySummaryCustomReportData } from "../Utils";
import ReportingSummaryTemplate /* , { SummaryTemplateReportData } */ from "./SummaryTemplate";

// interface SummaryTemplateContainerProps {
//   location: { [key: string] };
//   params: { [key: string] };
// }

// interface SummaryTemplateContainerState {
//   loading: boolean;
//   startDate: moment.Moment;
//   endDate: moment.Moment;
//   reportData: SummaryTemplateReportData;
//   displayMessage: string;
//   // contactType: ContactType;
// }

class SummaryTemplateContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment.utc().subtract(1, "years"),
      endDate: moment.utc(),
      loading: true,
      reportData: EmptySummaryCustomReportData(),
      displayMessage: "",
    };

    this.handleDateInputChange = this.handleDateInputChange.bind(this);
  }

  handleDateInputChange(dateFieldName) {
    return (selectedDate) => {
      this.setState({ [dateFieldName]: selectedDate }, this.requestReportData);
    };
  }

  requestReportData() {
    this.setState({ loading: true }, () => {
      /** @type {moment.Moment} */
      const startDate = this.state.startDate.clone().startOf("day");
      /** @type {moment.Moment} */
      const endDate = this.state.endDate.clone().endOf("day");
      CustomEventsApi.getSummaryReportData(
        +this.props.params.id,
        startDate,
        endDate,
      )
        .then((response) => {
          /** @type {SummaryTemplateReportData} */
          const reportData = {
            name: response.data.name,
            type: response.data.type,
            contactType: response.data.contactType,
            participantCount: response.data.participantCount,
            schoolOrMosadCount: response.data.mosadOrSchoolCount,
            ageArray: response.data.countByAge,
            genderArray: response.data.countByGender,
          };
          viewHistory.add("Program", {
            title: reportData.name,
            path: this.props.location.pathname,
          });
          this.setState({
            reportData,
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }

  componentDidMount() {
    const id = Number(this.props.params.id);
    if (!Number.isNaN(id)) {
      this.requestReportData();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.location.pathname === this.props.location.pathname) {
      return;
    }
    this.requestReportData();
  }

  render() {
    // if (!AuthService.UserHasClaim(PermissionClaims.ccc)) {
    //     return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    // }

    const { startDate, endDate, reportData, loading } = this.state;
    return (
      <div>
        <ReportingSummaryTemplate
          contactType={reportData.contactType}
          baseUrl={this.props.location.pathname}
          eventText={reportData.name}
          startDate={startDate}
          endDate={endDate}
          reportData={reportData}
          handleDateInputChange={this.handleDateInputChange}
        />
        ;{loading && <Loader />}
      </div>
    );
  }
}

export default SummaryTemplateContainer;
