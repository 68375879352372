import React from "react";
import classNames from "classnames";
import { Link } from "react-router";
import { ApiCallErrorHandler } from "../../lib/coc-common-scripts";
import { Unauthorized, Loader } from "../../lib/coc-common-components";
import { ResultsSummaryItem } from "../common/Search";

import ProgramsApi from "../../services/resources/ProgramsApi";
import AuthService from "../../services/AuthService";
import { PermissionClaims } from "../../services/AuthService";

function renderItemRow(item) {
  const {
    audienceName,
    categoryName,
    description,
    hasChanges,
    hasRestrictions,
    id,
    isActive,
    name,
    status,
  } = item;
  return (
    <tr key={id}>
      <td>
        <Link to={`/programs/${id}`} title={description}>
          {name}
        </Link>
      </td>
      <td>{categoryName}</td>
      <td>{audienceName}</td>
      <td>{hasRestrictions && <i className="material-icons">done</i>}</td>
      <td
        className="text-center"
        style={{
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <span style={{ float: "left" }}>
          <i
            className={classNames("icon", {
              "icon-status-active": isActive,
              "icon-status-inactive": !isActive,
            })}
          />{" "}
          {status}{" "}
        </span>
        {hasChanges && (
          <em className="text-muted">There are unpublished changes.</em>
        )}
      </td>
      <td>
        <Link to={`/programs/${item.id}`}>Edit</Link>
      </td>
    </tr>
  );
}

export class ProgramsListPage extends React.Component {
  state = {
    activeCount: 0,
    inactiveCount: 0,
    items: [],
    loading: true,
    displayError: "",
  };

  componentDidMount() {
    // TODO: Change permission name...
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return;
    }
    this.setState({ loading: true }, () =>
      ProgramsApi.list()
        .then((data) => {
          this.setState({
            ...data,
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.ProgramRead)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }
    const { activeCount, inactiveCount, items, loading } = this.state;
    return (
      <div className="col-sm-12">
        <ol className="breadcrumb">
          <li>Directory</li>
          <li>Programs</li>
        </ol>
        <div className="list-container">
          <h1>Summary</h1>
          <div className="row" style={{ position: "relative" }}>
            <div className="col-sm-12 form-horizontal">
              <div className="form-group">
                <div className="results-summary">
                  <ResultsSummaryItem paths={2} value={items.length} />
                  <ResultsSummaryItem
                    icon="icon-status-active"
                    label="Active"
                    value={activeCount}
                  />
                  <ResultsSummaryItem
                    icon="icon-status-inactive"
                    label="Inactive"
                    value={inactiveCount}
                  />
                </div>
                <Link
                  className="btn btn-primary btn-md rfloat"
                  to="/programs/new"
                >
                  Add New Program
                </Link>
              </div>
            </div>
          </div>
          <div className="content-window">
            <table className="table table-custom table-custom-responsive">
              <thead>
                <tr>
                  <th>Program Name</th>
                  <th>Category</th>
                  <th>Audience</th>
                  <th>Restricted Access</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{items.map(renderItemRow)}</tbody>
            </table>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}
