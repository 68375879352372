import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatCurrency } from "../../../../lib/utils";
import Loader from "../../../common/Loader";

export default class RaffleSalesOverTimeChart extends React.Component {
  formatSales = (amount) => formatCurrency(amount, this.props.currencyCode);

  timeFrames = [
    { value: "all", display: "All time" },
    { value: "last7Days", display: "7 days" },
    { value: "last4Weeks", display: "4 weeks" },
    { value: "last2Months", display: "2 months" },
  ];

  render() {
    const {
      allowTimeFrameSelection,
      data = [],
      errorMessage,
      loading,
      timeFrame,
    } = this.props;

    const orderedData = data
      .sort((d1, d2) => new Date(d1.day) - new Date(d2.day))
      .map((item) => ({
        ...item,
        Sales: item.amount, //for display in tooltip
      }));

    return (
      <div className="mt-40 graph-outline" style={{ height: "404px" }}>
        <div className="flex flex-justify-space flex-align-center mb-16">
          <p className="large-text fw-700">Sales Over Time</p>

          {allowTimeFrameSelection && (
            <select
              className="no-border-select accent-text-secondary"
              onChange={(event) =>
                this.props.getSalesOverTime(event.target.value)
              }
              value={timeFrame}
            >
              {this.timeFrames.map((time) => (
                <option key={time.value} value={time.value}>
                  {time.display}
                </option>
              ))}
            </select>
          )}
        </div>
        {loading ? (
          <div>
            <Loader />
          </div>
        ) : errorMessage ? (
          <p className="error-text">{errorMessage}</p>
        ) : !data.length ? (
          <span>No sales found for this time frame</span>
        ) : (
          <LineChart
            data={orderedData}
            height={300}
            width={800}
            margin={{ left: 20, right: 20 }}
          >
            <CartesianGrid stroke="#eee" />

            <Line dataKey="Sales" type="monotone" stroke="#ff7300" />

            <Tooltip
              formatter={this.formatSales}
              labelFormatter={(day) => `As of ${day}`}
            />

            <XAxis dataKey="formattedDay" />

            <YAxis
              dataKey="Sales"
              tickFormatter={this.formatSales}
              type="number"
            />
          </LineChart>
        )}
      </div>
    );
  }
}
