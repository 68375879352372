import React from "react";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import { Unauthorized, Loader } from "../../../lib/coc-common-components";

import CustomEventsApi from "../../../services/resources/CustomEventsApi";
import AuthService from "../../../services/AuthService";
import { PermissionClaims } from "../../../services/AuthService";
import EventsListPage from "./EventsListPage";
// import { CustomEvent } from "../Utils";

// interface EventsListPageContainerProps {}

// interface EventsListPageContainerState {
//   events: Array<CustomEvent>;
//   loading: boolean;
//   displayError: string;
// }

class EventsListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loading: true,
      displayError: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () =>
      CustomEventsApi.list()
        .then((response) => {
          this.setState({
            events: response.data.results,
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayError: errors.join("\n") });
        }),
    );
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { events } = this.state;

    return (
      <div className="col-sm-12">
        <EventsListPage events={events} />

        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default EventsListPageContainer;
