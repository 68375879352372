import React from "react";

var style = {
  paddingLeft: "0.5em",
  paddingTop: "0.5em",
};

export const NotFound = () => (
  <div style={style}>
    <h3>404 page not found</h3>
    <p>We are sorry but the page you are looking for does not exist.</p>
  </div>
);
