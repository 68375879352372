import React from "react";
import { Link } from "react-router";

import Tabs from "react-bootstrap/lib/Tabs";
import Tab from "react-bootstrap/lib/Tab";
import Table from "react-bootstrap/lib/Table";

import viewHistory from "../services/ViewHistory";
import AuthService from "../services/AuthService";
import { EmailLink } from "../lib/coc-common-components";
import ShluchimLink from "./common/ShluchimLink";

export class Home extends React.Component {
  render() {
    const renderContactHistoryItem = (item) => {
      /** @type {string} */
      let type = item.data.historyType;
      if (type === "shliach" && item.data.gender === "Female") {
        type = "shlucha";
      } else if (type === "student") {
        type = "students";
      }
      return (
        <tr key={item.data.path}>
          <td className="align-center">
            <span className={"icon icon-" + type}>
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
              <span className="path7" />
              <span className="path8" />
              <span className="path9" />
              <span className="path10" />
              <span className="path11" />
              <span className="path12" />
              <span className="path13" />
              <span className="path14" />
            </span>
          </td>
          <td>
            <Link to={item.data.path}>{item.data.name}</Link>
          </td>
          <td>{item.data.mobilePhone}</td>
          <td>
            {item.data.email && <EmailLink emailAddress={item.data.email} />}
          </td>
        </tr>
      );
    };

    const renderContactHistoryItemMobile = (item) => {
      return (
        <tr key={item.data.path}>
          <td>
            <Link to={item.data.path}>{item.data.name}</Link>
          </td>
          <td>
            <a href={"tel:" + item.data.mobilePhone}>{item.data.mobilePhone}</a>
          </td>
        </tr>
      );
    };

    const renderShliachHistoryItem = (item) => {
      /** @type {string} */
      let type = item.data.historyType;
      if (type === "shliach" && item.data.gender === "Female") {
        type = "shlucha";
      } else if (type === "student") {
        type = "students";
      }
      if (item.data.title.includes("undefined")) {
        return;
      }
      return (
        <tr key={item.data.path}>
          <td>
            <Link to={item.data.path}>{item.data.title}</Link>
          </td>
          <td />
          <td />
        </tr>
      );
    };

    const renderSchoolHistoryItem = (item) => {
      return (
        <tr key={item.data.campusId}>
          {/* <td className="align-center">
            <span className="icon icon-school">
              <span className="path1" />
              <span className="path2" />
            </span>
          </td> */}
          <td>
            <Link to={`/schools/${item.data.campusId}`}>
              {item.data.campusName}
            </Link>
          </td>
          <td>
            {item.data.shliach && (
              <ShluchimLink
                contactId={item.data.shliach.contactId}
                firstName={item.data.shliach.firstName}
                lastName={item.data.shliach.lastName}
              />
            )}
          </td>
          <td>
            {item.data.shlucha && (
              <ShluchimLink
                contactId={item.data.shlucha.contactId}
                firstName={item.data.shlucha.firstName}
                lastName={item.data.shlucha.lastName}
              />
            )}
          </td>
        </tr>
      );
    };

    const renderSchoolHistoryItemMobile = (item) => {
      return (
        <tr key={item.data.campusId}>
          <td>
            <Link to={`/schools/${item.data.campusId}`}>
              {item.data.campusName}
            </Link>
          </td>
          <td>
            {item.data.shliach && (
              <ShluchimLink
                contactId={item.data.shliach.contactId}
                firstName={item.data.shliach.firstName}
                lastName={item.data.shliach.lastName}
              />
            )}
          </td>
        </tr>
      );
    };

    const renderOrganizationHistoryItem = (item) => {
      const organization = item.data;
      return (
        <tr key={organization.id}>
          <td className="align-center">
            <span className="icon icon-organization">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
            </span>
          </td>
          <td>
            <Link to={organization.path}>{organization.name}</Link>
          </td>
          <td>{organization.mainContactName}</td>
          <td>
            {organization.mainContactMethodType === "email" ? (
              <EmailLink emailAddress={organization.mainContactMethod} />
            ) : (
              <a href={"tel:" + organization.mainContactMethod}>
                {organization.mainContactMethod}
              </a>
            )}
          </td>
        </tr>
      );
    };

    const renderOrganizationHistoryItemMobile = (item) => {
      const organization = item.data;
      return (
        <tr key={organization.id}>
          <td>
            {organization.path && organization.name && (
              <Link to={organization.path}>{organization.name}</Link>
            )}
          </td>
          <td>
            {organization.mainContactMethodType === "email" ? (
              <EmailLink emailAddress={organization.mainContactMethod} />
            ) : (
              <a href={"tel:" + organization.mainContactMethod}>
                {organization.mainContactMethod}
              </a>
            )}
          </td>
        </tr>
      );
    };

    const renderFunctions = {
      Contact: renderContactHistoryItem,
      ContactMobile: renderContactHistoryItemMobile,
      Shliach: renderShliachHistoryItem,
      ShliachMobile: renderShliachHistoryItem,
      School: renderSchoolHistoryItem,
      SchoolMobile: renderSchoolHistoryItemMobile,
      Organization: renderOrganizationHistoryItem,
      OrganizationMobile: renderOrganizationHistoryItemMobile,
    };

    const renderSelector = (profile) => {
      const fn =
        renderFunctions[profile.type + (this.props.mobileMode ? "Mobile" : "")];
      if (!fn) {
        return renderFunctions[
          "contact" + (this.props.mobileMode ? "Mobile" : "")
        ](profile);
      }
      return fn(profile);
    };

    return (
      <div className="col-xs-12">
        {this.props.mobileMode && (
          <div className="search-mobile-header">
            <p className="bh mobile-bh">ב״ה</p>
            <span
              className="glyphicon glyphicon-menu-hamburger"
              onClick={this.props.toggleSidebarOpen}
            />
            <Link to="/">
              <img className="search-logo" src="/logo.png" alt="COCI Admin" />
            </Link>
          </div>
        )}
        <div>
          <div className="jumbotron">
            <img src="stars.png" alt="" />
            <div className="jumbotron-desc">
              <h2>Welcome, {AuthService.getCurrentUser().name}</h2>
              <p>Get started by searching above or browsing to the left.</p>
            </div>
          </div>

          <div className="row-fluid">
            <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
              <Tab
                eventKey={1}
                title={
                  this.props.mobileMode
                    ? "Profiles"
                    : "Recently Viewed Profiles"
                }
              >
                <Table bsClass="table table-custom" responsive={true}>
                  <tbody>
                    {viewHistory.recentlyViewedProfiles().length === 0 ? (
                      <tr>
                        <td colSpan={5}>
                          <span className="no-results">
                            No recently viewed profiles
                          </span>
                        </td>
                      </tr>
                    ) : (
                      viewHistory
                        .recentlyViewedProfiles()
                        .slice(0, 5)
                        .map(renderSelector)
                    )}
                  </tbody>
                </Table>
              </Tab>
              {/* <Tab eventKey={2} title={this.props.mobileMode ? "Programs" : "Recently Viewed Programs"}>
                <Table bsClass="table table-custom" responsive={true}>
                  <tbody>
                    {viewHistory.recentlyViewedPrograms().length === 0 ? (
                      <tr>
                        <td>
                          <span className="no-results">No recently viewed programs</span>
                        </td>
                      </tr>
                    ) : (
                        viewHistory
                          .recentlyViewedPrograms()
                          .slice(0, 5)
                          .map(item => (
                            <tr key={item.data.path}>
                              <td>
                                <Link
                                  onClick={() => browserHistory.push(item.data.path)}
                                  style={{ cursor: "pointer" }}>
                                  {item.data.title}
                                </Link>
                              </td>
                            </tr>
                          ))
                      )}
                  </tbody>
                </Table>
              </Tab>
             */}
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}
