import React from "react";
import Select from "../../../../shared/Select";

export default class NewOrderAddressInfo extends React.PureComponent {
  onChangeCountry = (name, value) => {
    const { address, onChange } = this.props;

    const resetState = address.state && value !== address.country;
    onChange(name, value, resetState ? { "address.state": "" } : null);
  };

  render() {
    const { countries, address, onChange, onChangeEvent } = this.props;

    return (
      <div className="mb-24 new-order-address">
        <p className="fw-700 mb-16">Address</p>
        <div className="flex flex-align-center mb-12">
          <label className="small-text accent-text">Street Address</label>
          <input
            type="text"
            className="custom-input full-width"
            name="address.address1"
            onChange={onChangeEvent}
            placeholder="Street Address"
            tabIndex={70}
            value={address.address1}
          />
        </div>
        <div className="flex flex-align-center mb-12">
          <label className="small-text accent-text">Street Address 2</label>
          <input
            type="text"
            className="custom-input full-width"
            name="address.address2"
            onChange={onChangeEvent}
            placeholder="Street Address 2"
            tabIndex={80}
            value={address.address2}
          />
        </div>
        <div className="flex flex-align-center mb-12">
          <label className="small-text accent-text">Country</label>
          <Select
            className="custom-input"
            clearable={true}
            name="address.country"
            onChange={this.onChangeCountry}
            options={
              countries &&
              countries.map((country) => ({
                display: country.name,
                value: country.name,
              }))
            }
            tabIndex={90}
            value={address.country}
          />
        </div>
        <div className="flex flex-align-center mb-12">
          <label className="small-text accent-text">City, State</label>
          <div className="flex full-width">
            <input
              type="text"
              className="custom-input mr-16"
              name="address.city"
              onChange={onChangeEvent}
              placeholder="City"
              tabIndex={100}
              value={address.city}
            />
            {address.country === "USA" ||
            address.country === "Canada" ||
            address.country === "Mexico" ? (
              <Select
                className="custom-input half-width"
                clearable={true}
                disabled={!address.country}
                name="address.state"
                onChange={onChange}
                options={
                  address.country &&
                  (
                    (
                      countries.find(
                        (country) => country.name === address.country,
                      ) || {}
                    ).states || []
                  ).map((state) => ({
                    display: state.name,
                    value: state.code,
                  }))
                }
                tabIndex={110}
                value={address.state}
              />
            ) : (
              <input
                type="text"
                className="custom-input half-width"
                name="address.state"
                onChange={onChangeEvent}
                placeholder="State"
                tabIndex={110}
                value={address.state}
              />
            )}
          </div>
        </div>
        <div className="flex flex-align-center">
          <label className="small-text accent-text">Zip</label>
          <input
            type="text"
            className="custom-input mr-16"
            name="address.zip"
            onChange={onChangeEvent}
            placeholder="Zip"
            tabIndex={120}
            value={address.zip}
          />
        </div>
      </div>
    );
  }
}
