import React, { useCallback, useEffect, useRef, useState } from "react";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../../../../shared/ConfirmationModal";

import TripEventsApi from "../../../../../../services/resources/TripEventsApi";
import { ApiCallErrorMessageHandler } from "../../../../../../lib/coc-common-scripts";
import { formatCurrency } from "../../../../../../lib";
import { notify } from "react-notify-toast";
import axios from "axios";

function RegistrationDeleteButton(props) {
  const { programScheduleId, registration } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const apiSignalRef = useRef(axios.CancelToken.source());

  useEffect(() => {
    const _apiSignal = apiSignalRef.current;
    return () => _apiSignal.cancel();
  }, []);

  const onDeleteRegistration = useCallback(async () => {
    setErrorMessage("");
    setLoading(true);

    try {
      await TripEventsApi.deleteTripRegistration(
        apiSignalRef.current.token,
        registration.id
      );

      notify.show("Registration has been deleted", "success");
      browserHistory.push(`/trips/${programScheduleId}`);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setErrorMessage(ApiCallErrorMessageHandler(err));
      }
      setLoading(false);
    }
  }, [programScheduleId, registration.id]);

  const { total, wasPaymentProcessed } = registration;

  const withRefund = wasPaymentProcessed && total > 0;

  return (
    <>
      <div className="flex flex-align-center mt-24">
        <button
          className="btn custom-btn btn-delete btn-medium uppercase-text"
          onClick={() => setShowConfirmationModal(true)}
        >
          Delete Registration
        </button>
      </div>
      <ConfirmationModal
        cancel={() => {
          setShowConfirmationModal(false);
          setErrorMessage("");
        }}
        confirm={onDeleteRegistration}
        errorMessage={errorMessage}
        loading={loading}
        message={`Are you sure you want to permanently delete this registration? ${
          withRefund
            ? `$${formatCurrency(total)} will be refunded and you`
            : "You"
        } will no longer have any record of this registration. \nOnly do this if the student needs to re-register.`}
        show={showConfirmationModal}
        title={
          <span className="flex flex-align-center">
            <i className="material-icons large-text trip-warning-icon mr-8">
              warning
            </i>
            <span>Warning</span>
          </span>
        }
      />
    </>
  );
}

export default React.memo(RegistrationDeleteButton);
