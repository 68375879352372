import React from "react";
import Toggle from "../../../shared/Toggle";
import debounce from "lodash.debounce";

export default class ShluchimTableFilter extends React.PureComponent {
  state = {
    enrolled: true,
    keyword: "",
  };

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter
    );
  };

  onFilter = () => {
    let { sortBy, sortOptions } = this.props;
    if (
      !this.state.enrolled &&
      sortOptions.find((option) => option.id === sortBy).enrolledOnly
    ) {
      sortBy = undefined;
    }
    this.props.applyFilters(this.state, sortBy);
  };

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { enrolled, keyword } = this.state;
    const { isJewishUTrip, isTravelTrip } = this.props;

    return (
      <div className="flex flex-align-center">
        <div className="search-input">
          <i className="material-icons accent-text-secondary flex flex-align-center ml-8">
            search
          </i>
          <input
            type="text"
            placeholder="Search by shliach or chabad house name"
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            style={{ padding: "0 8px" }}
            value={keyword}
          />
        </div>
        <div className="ml-24">
          <Toggle
            name="enrolled"
            onChange={this.onChange}
            options={[
              {
                value: true,
                display: "Enrolled",
              },
              {
                value: false,
                display: isTravelTrip ? (
                  <>
                    Not Enrolled
                    <span>(View & Enroll)</span>
                  </>
                ) : (
                  "Not Enrolled"
                ),
              },
            ]}
            value={enrolled}
          />
        </div>
        {isJewishUTrip && !enrolled && (
          <div className="accent-text ml-8">*JewishU locations only listed</div>
        )}
      </div>
    );
  }
}
