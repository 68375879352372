import React from "react";
import { Link } from "react-router";

// import { CustomPage } from "../../../models/CustomPage";
import DataTable /* , { DataTableColumn } */ from "../../common/DataTable";

// interface CustomPageListProps {
//   customPages: Array<CustomPage>;
//   linksBase: string;
// }

export const CustomPageList = ({ customPages, linksBase }) => {
  /** @type {Array<DataTableColumn>} */
  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: (c) => (
        <Link
          to={`/config/custompages/${c.original.id}`}
          style={{ cursor: "pointer" }}
        >
          {c.original.title}
        </Link>
      ),
    },
    {
      Header: "URL",
      accessor: "slug",
      Cell: (c) => (
        <a
          href={linksBase + c.original.slug}
          target="_blank"
          rel="noopener noreferrer"
        >
          {c.original.slug}{" "}
          <i
            className="glyphicon glyphicon-new-window"
            style={{ fontSize: "10px" }}
          />
        </a>
      ),
    },
  ];
  /** @param {CustomPage} a
   * @param {CustomPage} b
   * @returns {number}
   */
  const sortItems = (a, b) => (a.title < b.title ? -1 : 1);

  return (
    <div className="col-sm-12">
      <DataTable
        fullClientSide={true}
        columns={columns}
        reportData={customPages.sort(sortItems)}
      />
    </div>
  );
};
