import React from "react";
import { Link } from "react-router";
import { formatAddressDisplay, formatFullName } from "../../../../lib";
import moment from "moment";

export default ({
  student: {
    address,
    campus: { name: campusName } = {},
    cell,
    classDisplay,
    email,
    graduationYear,
    id: studentId,
    person: { age, dob, firstName, gender, hebrewName, lastName } = {},
    primaryShliach,
    primaryShlucha,
  },
}) => (
  <div className="card students-profile-card">
    <div className="students-profile-card-left">
      <div className="student-name mb-24">
        <Link className="xxl-text" to={`/students/${studentId}`}>
          {formatFullName(firstName, lastName)}
        </Link>
        {hebrewName && (
          <p>
            <span className="fw-700 medium-text">{hebrewName}</span>
            <span
              className="ml-4 small-text"
              style={{ display: "inline-block", fontStyle: "italic" }}
            >
              Jewish Name
            </span>
          </p>
        )}
      </div>
      <div>
        <a
          href={`mailto:${email}`}
          className="link-text medium-text block mb-8"
        >
          {email}
        </a>
        <a href={`tel:${cell}`} className="link-text medium-text block mb-8">
          {cell}
        </a>
        <p className="medium-text mb-8">
          {address &&
            formatAddressDisplay(
              address.address1,
              address.address2,
              address.city,
              address.state,
              address.zip,
              address.country,
            )}
        </p>
      </div>
    </div>
    <div className="students-profile-card-right">
      <div className="flex flex-align-center mb-8">
        <label className="fw-700">Campus</label>
        <p>{campusName}</p>
      </div>
      <div className="flex flex-align-center mb-8">
        <label className="fw-700">Shliach / Shlucha</label>
        <p>
          {[
            primaryShliach && primaryShliach.fullName,
            primaryShlucha && primaryShlucha.fullName,
          ]
            .filter((n) => n)
            .join("/")}
        </p>
      </div>
      <div className="flex flex-align-center mb-8">
        <label className="fw-700">Demographic</label>
        <p>{classDisplay}</p>
      </div>
      <div className="flex flex-align-center mb-8">
        <label className="fw-700">Graduating Year</label>
        <p>{graduationYear}</p>
      </div>
      <div className="flex flex-align-center mb-8">
        <label className="fw-700">Age / Birthday</label>
        <p>
          {age} / {dob ? moment(dob).format("MMMM D, YYYY") : ""}
        </p>
      </div>
      <div className="flex flex-align-center">
        <label className="fw-700">Gender</label>
        <p>{gender}</p>
      </div>
    </div>
  </div>
);
