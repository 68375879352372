import * as React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Text } from "recharts";

import { ChartColors } from "../../assets/scripts/reportingHelpers";

const GenderPieChart = ({
  genderArray,
  displayName,
  chartColors = ChartColors,
}) => {
  return (
    <div className="well text-center">
      {genderArray && (
        <ResponsiveContainer width="100%" height={250}>
          {genderArray[0].count + genderArray[1].count === 0 ? (
            <Text className="no-participants-message">
              {"No " + displayName}
            </Text>
          ) : (
            <PieChart>
              <Pie
                data={genderArray}
                label={({ name, value }) => `${value}% ${name}`}
                labelLine={false}
                isAnimationActive={false}
                outerRadius={80}
                cx="50%"
                cy="50%"
              >
                {genderArray.map((entry, index) => (
                  <Cell key={entry.name} fill={chartColors[index]} />
                ))}
              </Pie>
            </PieChart>
          )}
        </ResponsiveContainer>
      )}
      <p className="reporting-stats-label">{displayName} by Gender</p>
    </div>
  );
};

export default GenderPieChart;
