import React from "react";
import moment from "moment";
import { asEnum } from "../../lib";
import { EmailLink, PhoneNumberLink } from "../../lib/coc-common-components";
import {
  /* Mosad, Shliach, Address, */ Gender,
} from "../../lib/coc-common-components/Models";

// import { ContactMethods } from "../../models/Contact";

export const ContactType = asEnum({
  shluchim: 0,
  students: 1,
});

const shliachFullName = (shliach) => {
  if (!shliach) {
    return "";
  }
  const { title, firstName, lastName } = shliach;
  return `${title || ""} ${firstName || ""} ${lastName || ""}`;
};

const valueIsPresent = (value) => {
  if (!value) {
    return false;
  }
  return value.toString().trim().length > 0;
};
/** @param {Address} address */
const addressObjToString = ({
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode,
  country,
}) =>
  addressToString(addressLine1, addressLine2, city, state, postalCode, country);

const addressToString = (
  addressLine1,
  addressLine2,
  city,
  state,
  postCode,
  country,
) =>
  [addressLine1, addressLine2, city, state, postCode, country]
    .filter(valueIsPresent)
    .join(", ");

const renderOrUnavailable = (
  availability,
  content,
  backupContent = <em className="text-muted">Data Unavailable</em>,
) => {
  if (!availability) {
    return backupContent;
  }

  if (typeof content === "function") {
    return content();
  } else {
    return content;
  }
};

const showIfAvailable = (value) =>
  renderOrUnavailable(valueIsPresent(value), value);

const compileSharedPersonalInfo = (contact) => {
  const info = {
    "Facebook Photo": contact.fbid ? (
      <img
        src={`https://graph.facebook.com/${contact.fbid}/picture`}
        alt="facebook profile"
      />
    ) : null,
    "First Name": showIfAvailable(contact.firstName),
    "Last Name": showIfAvailable(contact.lastName),
    "Hebrew Name": showIfAvailable(contact.hebrewName),
    Gender: showIfAvailable(contact.gender),
    Birthdate: renderOrUnavailable(
      valueIsPresent(contact.dob),
      moment(contact.dob).format("MMMM D, YYYY"),
    ),
  };

  return info;
};

const compileSharedContactMethods = (contact, typeLabelMap) => {
  typeLabelMap = typeLabelMap || {
    EmailAddress: "Email",
    PhoneNumber: "Phone",
    CellPhoneNumber: "Cell",
  };

  return contact.contactMethods.map((contactMethod, index) => ({
    label: typeLabelMap[contactMethod.type],
    value:
      contactMethod.type === "EmailAddress" ? (
        <EmailLink emailAddress={contactMethod.value} />
      ) : (
        <PhoneNumberLink phoneNumber={contactMethod.value} />
      ),
  }));
};
/** @param {Array<ContactMethods>} cm */
const displayableContactMethods = (cm = []) => {
  /** @param {ContactMethods} cm1
   * @param {ContactMethods} cm2
   */
  const sortFunction = (cm1, cm2) => (cm1.primary ? -1 : 1);
  const emails = cm
    .filter(
      (s) =>
        s.type === "EmailAddress" &&
        s.value.indexOf("proxymail.facebook.com") < 0,
    )
    .sort(sortFunction);
  const homePhones = cm
    .filter((s) => s.type === "PhoneNumber")
    .sort(sortFunction);
  const cellPhone = cm
    .filter((s) => s.type === "CellPhoneNumber")
    .sort(sortFunction);

  const mainEmail = emails.length > 0 ? emails[0].value : "";
  const mainPhone = homePhones.length > 0 ? homePhones[0].value : "";
  const mainCell = cellPhone.length > 0 ? cellPhone[0].value : "";

  return { email: mainEmail, phoneNumber: mainPhone, cellNumber: mainCell };
};
/** @param {Mosad} mosad
 * @param {Shliach} shliach
 * @returns {Mosad}
 */
export function AddShluchimRole(mosad, shliach) {
  if (
    (mosad.shluchimRoles || []).find((sr) => sr.shliachId === shliach.shliachId)
  ) {
    return mosad;
  }
  const shluchimRoles = mosad.shluchimRoles || [];
  shluchimRoles.push({
    shliachId: shliach.shliachId,
    name: shliach.firstName + " " + shliach.lastName,
    gender: shliach.gender || Gender.unknown,
    role: "Other",
  });
  return mosad;
}

export function mainContactMethods(cm) {
  let emails = cm ? cm.filter((s) => s.type === "EmailAddress") : [];
  let phones = cm ? cm.filter((s) => s.type === "PhoneNumber") : [];
  let cells = cm ? cm.filter((s) => s.type === "CellPhoneNumber") : [];

  let mainEmail = emails.find((s) => s.primary) || (emails && emails[0]);
  let mainPhone = phones && phones[0];
  let mainCell = cells.find((s) => s.primary) || (cells && cells[0]);

  return { email: mainEmail, phoneNumber: mainPhone, cellNumber: mainCell };
}

export default {
  shliachFullName,
  valueIsPresent,
  addressToString,
  addressObjToString,
  showIfAvailable,
  renderOrUnavailable,
  compileSharedPersonalInfo,
  compileSharedContactMethods,
  displayableContactMethods,
};
