import React from "react";

export default class EventConfigTabs extends React.PureComponent {
  render() {
    const { tabIndex, tabs, toTab } = this.props;
    return (
      <div className="program-settings-page-sidebar">
        <ul>
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={
                tabIndex === index ? "active" : !tab.component ? "disabled" : ""
              }
              onClick={() => {
                if (tab.component) {
                  toTab(index);
                }
              }}
            >
              <span className="tooltip-container">
                <i className="material-icons">{tab.icon}</i>
                {!tab.component && tab.disabledTooltip && (
                  <span className="tooltip">{tab.disabledTooltip}</span>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
