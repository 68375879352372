import React from "react";
import axios from "axios";
import Modal from "react-modal";
import SelectSearch from "react-select";

import EduApi from "../../../../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../../../../lib/coc-common-scripts";
import Loader from "../../../../../common/Loader";

export default class AddToCourseModal extends React.Component {
  state = {
    errorMessage: "",
    loading: false,
    newCourse: null,
    availableCourses: [],
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getAvailableStudentCourses(this.props.studentID);
  }

  getAvailableStudentCourses = (id) => {
    this.setState({ loading: true });
    EduApi.getEduStudentAvailableCourses(
      this.apiSignal.token,
      this.props.studentID,
    )
      .then((courses) =>
        this.setState({ availableCourses: courses, loading: false }),
      )
      .catch((e) =>
        this.setState({
          getAvailableCoursesError: ApiCallErrorMessageHandler(e),
          loading: false,
        }),
      );
  };

  onClose = () => {
    this.setState(
      {
        errorMessage: "",
        loading: false,
        newCourse: null,
      },
      this.props.close,
    );
  };

  onSelectCourse = (newCourse) => {
    this.setState({ errorMessage: "", newCourse });
  };

  addStudentToCourse = async (newCourse) => {
    const addStudentToCourseResponse = {};
    try {
      await EduApi.addEduStudentToCourse(this.apiSignal.token, {
        eduCourseScheduleID: newCourse,
        eduStudentEnrollmentID: this.props.studentID,
      });
      addStudentToCourseResponse.success = true;
      //reload student courses
      this.props.getCourses();
    } catch (err) {
      if (!axios.isCancel(err)) {
        addStudentToCourseResponse.error = err;
        addStudentToCourseResponse.errorMessage =
          ApiCallErrorMessageHandler(err);
      }
    }
    return addStudentToCourseResponse;
  };

  onSubmit = async () => {
    const { newCourse } = this.state;
    if (!newCourse) {
      return;
    }
    this.setState({
      errorMessage: "",
      loading: true,
    });
    const { error, errorMessage } = await this.addStudentToCourse(
      newCourse.value,
    );
    if (error) {
      this.setState({
        errorMessage,
        loading: false,
      });
      return;
    }
    this.onClose();
  };

  render() {
    const { name, show } = this.props;
    const {
      errorMessage,
      loading,
      newCourse,
      availableCourses,
      getAvailableCoursesError,
    } = this.state;

    return (
      <Modal isOpen={show} className="modal-container">
        <div className="modal-card card medium-modal">
          <p className="large-text fw-500 mb-16">Add to Course</p>
          {/* TODO: add student name here */}
          <p>{`Choose the course you’d like to add ${name} to:`}</p>
          <SelectSearch
            disabled={loading || !!getAvailableCoursesError}
            isClearable={true}
            isSearchable={true}
            onChange={this.onSelectCourse}
            options={availableCourses.map((course) => ({
              value: course.id,
              label: course.name,
            }))}
            placeholder={loading ? "Loading Courses..." : "Select Course"}
            style={{
              height: "36px",
              width: "100%",
              border: "1px solid #edecec",
              fontSize: "14px",
            }}
            value={newCourse}
          />
          {getAvailableCoursesError && (
            <p className="error-text">{getAvailableCoursesError}</p>
          )}
          <div className="text-right mt-24">
            {loading ? (
              <div className="modal-btns-loader">
                <Loader />
              </div>
            ) : (
              <div className="mt-40">
                <button
                  className="btn link-text uppercase-text fw-700"
                  onClick={this.onClose}
                  style={{ background: "transparent", fontSize: 14 }}
                >
                  Cancel
                </button>
                <button
                  className="btn link-text uppercase-text ml-8 fw-700"
                  disabled={!newCourse}
                  onClick={this.onSubmit}
                  style={{ background: "transparent", fontSize: 14 }}
                >
                  Add
                </button>
              </div>
            )}
            {errorMessage && <p className="error-text">{errorMessage}</p>}
          </div>
        </div>
      </Modal>
    );
  }
}
