import React from "react";
import classNames from "classnames";

// import { ClassAttributes, HTMLAttributes, ButtonHTMLAttributes } from "react";

export function StyledGroupContainer({ children, className, ...other }) {
  return (
    <div className={classNames("styled-group-container", className)} {...other}>
      {children}
    </div>
  );
}

export function StyledGroupButton({ children, className, ...other }) {
  return (
    <button className={classNames("styled-group-btn", className)} {...other}>
      {children}
    </button>
  );
}
