import React from "react";
import { browserHistory } from "react-router";
import { formatFullName } from "../../../lib";
import moment from "moment";

export default class LocationsTableRow extends React.PureComponent {
  render() {
    const {
      location: {
        campusName,
        chabadHouseID,
        chabadHouseName,
        chabadHouseRsvpLink,
        memberSince,
        shliachFirstName,
        shliachID,
        shliachLastName,
        shluchaFirstName,
        shluchaID,
      },
      onRemoveLocation,
    } = this.props;

    return (
      <div
        className="rsvp-locations-table-row"
        onClick={() =>
          browserHistory.push(
            `/shluchim/${shliachID || shluchaID}/chabadHouse/${chabadHouseID}`
          )
        }
      >
        <p>{formatFullName(shliachFirstName, shliachLastName)}</p>
        <p>{formatFullName(shluchaFirstName, shliachLastName)}</p>
        <p>{chabadHouseName}</p>
        <p>{campusName}</p>
        <p>{moment(memberSince).format("MM/DD/YYYY")}</p>
        <p>
          <i
            className="link-text-secondary material-icons medium-text"
            onClick={(e) => {
              e.stopPropagation();
              window.open(chabadHouseRsvpLink);
            }}
          >
            launch
          </i>
        </p>
        <p>
          <i
            className="link-text-secondary material-icons medium-text"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveLocation();
            }}
          >
            delete
          </i>
        </p>
      </div>
    );
  }
}
