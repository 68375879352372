import React from "react";
import { browserHistory, Link } from "react-router";
import Loader from "../Contact/ShliachPages/Loader";
import ProgramBreadcrumbsHeader from "../common/ProgramBreadcrumbsHeader";
import RsvpLocationsList from "./locations/LocationsTable";

import RsvpApi from "../../services/resources/RsvpApi";
import ChabadHousesApi from "../../services/resources/ChabadHousesApi";
import { ApiCallErrorMessageHandler } from "../../lib/coc-common-scripts";
import AuthService, { PermissionClaims } from "../../services/AuthService";
import { Unauthorized } from "../../lib/coc-common-components";
import axios from "axios";

export default class RsvpSystemPage extends React.PureComponent {
  state = {
    authorized: true,

    chabadHousesList: [],
    chabadHousesListErrorMessage: "",
    chabadHousesListLoading: false,

    rsvpSystem: null,
    rsvpSystemErrorMessage: "",
    rsvpSystemLoading: false,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    //We are allowing access based on the Read permission, for this phase we are not handling Read-only Admins in the UI, assuming Admin with Read will have Edit as well.  If editing is attempted they will get an error.
    if (!AuthService.UserHasClaim(PermissionClaims.RsvpAdminSettingsRead)) {
      this.setState({ authorized: false });
    }

    this.getRsvpSystem();

    this.getChabadHousesForLocationsList();
  }

  componentDidUpdate(prevProps) {
    const {
      params: { scheduleId },
    } = this.props;
    const {
      params: { scheduleId: prevScheduleId },
    } = prevProps;
    //check for external schedule update that is not in sync with state (update from quick access programs menu, really only relevant once multiple RSVP systems can be created)
    if (scheduleId && prevScheduleId && scheduleId !== prevScheduleId) {
      const { programScheduleID } = this.state.eduProgram || {};
      if (
        programScheduleID &&
        programScheduleID.toString() !== scheduleId.toString()
      ) {
        this.getRsvpSystem();
      }
    }
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  getRsvpSystem = async () => {
    const {
      params: { scheduleId },
    } = this.props;

    try {
      this.setState({
        rsvpSystemErrorMessage: "",
        rsvpSystemLoading: true,
      });

      const rsvpSystem = await RsvpApi.getRsvpSystem(
        this.apiSignal.token,
        scheduleId,
      );

      //populate nav param with correct program schedule id for the RSVP System if no schedule is previously specified and an RSVP System exists
      const {
        location: { search },
        params: { scheduleId: prevScheduleId },
      } = this.props;

      if (!prevScheduleId && rsvpSystem && rsvpSystem.programScheduleID) {
        browserHistory.replace(
          `/rsvp/${rsvpSystem.programScheduleID}${search || ""}`,
        );
      }

      this.setState({
        rsvpSystem,
        rsvpSystemLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        const is404 = err.response && err.response.status === 404;
        this.setState({
          rsvpSystemErrorMessage: is404
            ? "404"
            : ApiCallErrorMessageHandler(
                err,
                "Sorry, something went wrong and RSVP System details could not be retrieved. Please try again.",
              ),
          rsvpSystemLoading: false,
        });
      }
    }
  };

  getChabadHousesForLocationsList = async () => {
    try {
      this.setState({
        chabadHousesListErrorMessage: "",
        chabadHousesListLoading: true,
      });

      const eligibleChabadHouses = await ChabadHousesApi.getChabadHousesDetails(
        this.apiSignal.token,
      );

      this.setState({
        chabadHousesList: eligibleChabadHouses,
        chabadHousesListLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          chabadHousesListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve available locations. Please try again.",
          ),
          chabadHousesListLoading: false,
        });
      }
    }
  };

  render() {
    const {
      params: { scheduleId: rsvpProgramScheduleId },
    } = this.props;

    const {
      authorized,

      chabadHousesList,
      chabadHousesListErrorMessage,
      chabadHousesListLoading,

      rsvpSystem,
      rsvpSystemErrorMessage,
      rsvpSystemLoading,
    } = this.state;

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const rsvpSystemNotFound = rsvpSystemErrorMessage === "404";
    const allowCreateRsvpSystem = !rsvpProgramScheduleId && rsvpSystemNotFound;

    return (
      <div className="rsvp-page page container">
        <ProgramBreadcrumbsHeader scheduleId={rsvpProgramScheduleId} />
        <div className="flex flex-align-center fw-900 xl-text mb-32">
          <p>RSVP System</p>
          {(allowCreateRsvpSystem || rsvpSystem) && (
            <Link
              className="btn custom-btn btn-accent-secondary uppercase-text ml-32"
              style={{ height: "36px" }}
              to={`/rsvp/settings${
                rsvpProgramScheduleId ? `/${rsvpProgramScheduleId}` : ""
              }`}
            >
              {allowCreateRsvpSystem ? "Create" : "Settings"}
            </Link>
          )}
        </div>
        {rsvpSystemLoading ? (
          <Loader />
        ) : rsvpSystemErrorMessage ? (
          rsvpSystemNotFound ? (
            <div className="text-center">
              {allowCreateRsvpSystem
                ? "Click Create to configure a new RSVP System"
                : "There is no RSVP System associated with this program schedule"}
            </div>
          ) : (
            <div className="error-text">
              <img src="/img/error.svg" alt="error robot" height="240" />
              <p>{rsvpSystemErrorMessage}</p>
            </div>
          )
        ) : (
          rsvpSystem && (
            <div className="card">
              <RsvpLocationsList
                chabadHouses={{
                  chabadHousesList,
                  chabadHousesListErrorMessage,
                  chabadHousesListLoading,
                }}
                rsvpSystem={rsvpSystem}
              />
            </div>
          )
        )}
      </div>
    );
  }
}
