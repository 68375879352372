import AuthClient from "./AuthClient";
import queryString from "query-string";

const ShluchimApi = {
  getShluchim: async (cancelToken) => {
    const response = await AuthClient.get("Shluchim", {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getShliach: async (cancelToken, shliachId) => {
    const response = await AuthClient.get(`Shluchim/${shliachId}/fullProfile`, {
      cancelToken,
    });
    return response.data.payload || {};
  },
  getShliachProgramActivity: async (
    cancelToken,
    shliachId,
    page,
    results,
    rangeType
  ) => {
    const params = {
      page,
      rangeType,
      results,
    };

    const response = await AuthClient.get(
      `Shluchim/${shliachId}/registrationHistory?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getShliachAccountActivity: async (cancelToken, shliachId) => {
    const activityResponse = await AuthClient.get(
      `Activity?shliachId=${shliachId}`,
      { cancelToken }
    );
    return {
      login: activityResponse.data.payload?.Login || {},
      shliachViews: activityResponse.data.payload?.ShliachViews || [],
      programViews: activityResponse.data.payload?.ProgramViews || [],
    };
  },
  getShliachStudentActivity: async (
    cancelToken,
    shliachId,
    page,
    results,
    rangeType,
    programType,
    educatorId
  ) => {
    const params = {
      includeInactive: true,
      page,
      rangeType,
      results,
      programType,
      educatorId,
      shliachId,
    };

    const response = await AuthClient.get(
      `Interactions?${queryString.stringify(params)}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  getShliachResourcesActivity: async (
    cancelToken,
    shliachId,
    page,
    results
  ) => {
    const params = {
      page,
      results,
    };

    const response = await AuthClient.get(
      `Shluchim/${shliachId}/programResourceViews?${queryString.stringify(
        params
      )}`,
      { cancelToken }
    );
    return response.data.payload || {};
  },
  restoreShliach: (contactId) => {
    return AuthClient.post(`shluchim/${contactId}/restore`);
  },
};

export default ShluchimApi;
