import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import EnrollmentsTableFilters from "./EnrollmentsTableFilters";
import EnrollmentsTableHeader from "./EnrollmentsTableHeader";
import EnrollmentsTableRecord from "./EnrollmentsTableRecord";
import ExportCSVButton from "../../shared/ExportCSVButton";

import LifeInsuranceApi from "../../../services/resources/LifeInsuranceApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import { formatFullName } from "../../../lib";
import axios from "axios";
import moment from "moment";

export default class LifeInsuranceEnrollmentsTable extends React.PureComponent {
  state = {
    enrollments: [],
    errorMessage: "",
    filters: {
      keyword: "",
      statuses: [],
    },
    loading: false,
    page: 1,
    results: 12,
    sortBy: "attentionRequired",
    success: true,
    totalEnrollments: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterEnrollments = (filters) => {
    this.setState(
      {
        filters,
      },
      this.getEnrollments,
    );
  };

  getEnrollments = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        LifeInsuranceApi.getLifeInsuranceEnrollments(
          this.apiSignal.token,
          this.props.policyId,
          page,
          results,
          sortBy,
          this.state.filters,
          true,
        )
          .then(({ enrollments, totalEnrollments }) => {
            this.setState({
              loading: false,
              enrollments,
              totalEnrollments,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                enrollments: [],
                success: false,
                totalEnrollments: 0,
              });
            }
          });
      },
    );
  };

  getEnrollmentsForExport = async () => {
    const { sortBy, filters } = this.state;

    const enrollmentsForExport =
      await LifeInsuranceApi.getLifeInsuranceEnrollments(
        this.apiSignal.token,
        this.props.policyId,
        undefined,
        undefined,
        sortBy,
        filters,
        true,
        true,
      );

    return enrollmentsForExport.enrollments;
  };

  render() {
    const { mobileMode, scheduleId, systemLists } = this.props;

    const {
      enrollments,
      loading,
      page,
      results,
      sortBy,
      success,
      totalEnrollments,
    } = this.state;

    return (
      <React.Fragment>
        <div className="flex flex-justify-space mb-24">
          <p className="xxl-text fw-500">All Shluchim</p>
          <ExportCSVButton
            className="custom-btn btn btn-accent uppercase-text"
            fileName="Life_Insurance_Enrollments"
            getExportData={this.getEnrollmentsForExport}
            headers={[
              { label: "Last Name", value: "shliachLastName" },
              { label: "Shliach", value: "shliachFirstName" },
              { label: "Shlucha", value: "shluchaFirstName" },
              { label: "Shliach Email", value: "shliachEmail" },
              { label: "Shliach Phone", value: "shliachCell" },
              {
                label: "Shliach Legal Name",
                value: ({
                  shliachLegalFirstName,
                  shliachLegalLastName,
                  shliachLegalMiddleName,
                }) =>
                  formatFullName(
                    shliachLegalFirstName,
                    shliachLegalLastName,
                    shliachLegalMiddleName,
                  ),
              },
              {
                label: "Shliach DOB",
                value: ({ shliachDOB }) =>
                  shliachDOB ? moment(shliachDOB).format("MM/DD/YYYY") : null,
              },
              { label: "Shlucha Email", value: "shluchaEmail" },
              { label: "Shlucha Phone", value: "shluchaCell" },
              {
                label: "Shlucha Legal Name",
                value: ({
                  shluchaLegalFirstName,
                  shluchaLegalLastName,
                  shluchaLegalMiddleName,
                }) =>
                  formatFullName(
                    shluchaLegalFirstName,
                    shluchaLegalLastName,
                    shluchaLegalMiddleName,
                  ),
              },
              {
                label: "Shlucha DOB",
                value: ({ shluchaDOB }) =>
                  shluchaDOB ? moment(shluchaDOB).format("MM/DD/YYYY") : null,
              },
              { label: "Status", value: "statusDisplay" },
              {
                label: "Date Joined",
                value: ({ enrollmentDate }) =>
                  enrollmentDate
                    ? moment(enrollmentDate).format("MM/DD/YYYY")
                    : null,
              },
              {
                label: "Last Renewal Date",
                value: ({ lastRenewalDate }) =>
                  lastRenewalDate
                    ? moment(lastRenewalDate).format("MM/DD/YYYY")
                    : null,
              },
              {
                label: "Next Renewal Date",
                value: ({ nextRenewalDate }) =>
                  nextRenewalDate
                    ? moment(nextRenewalDate).format("MM/DD/YYYY")
                    : null,
              },
            ]}
          />
        </div>

        <div className="life-insurance-enrollment-table">
          <PaginatedTable
            filterComponent={
              <EnrollmentsTableFilters
                allStatuses={systemLists.lifeInsuranceStatuses}
                applyFilters={this.filterEnrollments}
              />
            }
            loading={loading}
            loadData={this.getEnrollments}
            mobileMode={mobileMode}
            page={page}
            records={enrollments}
            renderHeader={() => <EnrollmentsTableHeader />}
            renderRow={(enrollment) => (
              <EnrollmentsTableRecord
                enrollment={enrollment}
                key={enrollment.id}
                scheduleId={scheduleId}
              />
            )}
            results={results}
            sortBy={sortBy}
            sortOptions={[
              { id: "attentionRequired", name: "Attention Required" },
              { id: "shliachLastName", name: "Last Name" },
            ]}
            success={success}
            totalCount={totalEnrollments}
          />
        </div>
      </React.Fragment>
    );
  }
}
