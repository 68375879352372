import React from "react";
import PaginatedTable from "../../shared/PaginatedTable";
import CourseSchedulesTableActions from "./CourseSchedulesTableActions";
import CourseSchedulesTableFilters from "./CourseSchedulesTableFilters";
import CourseSchedulesTableHeader from "./CourseSchedulesTableHeader";
import CourseSchedulesTableRow from "./CourseSchedulesTableRow";
import LocationDetailsModal from "../locations/location/LocationDetailsModal";

import EduApi from "../../../services/resources/EduApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

export default class CourseSchedulesTable extends React.PureComponent {
  state = {
    courseSchedules: [],
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    page: 1,
    results: 12,
    selectedCourseSchedule: null,
    showLocationDetailsModal: false,
    sortBy: "date",
    success: true,
    totalCourseSchedules: 0,
    totalStudentsEnrolled: 0,
  };

  apiSignal = axios.CancelToken.source();

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterCourseSchedules = (filters) => {
    this.setState({ filters }, () => {
      this.getCourseSchedules();
    });
  };

  getCourseSchedules = (
    page = 1,
    results = this.state.results,
    sortBy = this.state.sortBy,
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        results,
        sortBy,
        success: true,
      },
      () => {
        EduApi.getEduProgramCourseSchedules(
          this.apiSignal.token,
          this.props.eduProgram.id,
          page,
          results,
          sortBy,
          this.state.filters,
        )
          .then(
            ({
              courseSchedules,
              totalCourseSchedules,
              totalStudentsEnrolled,
            }) => {
              this.setState({
                courseSchedules,
                loading: false,
                totalCourseSchedules,
                totalStudentsEnrolled,
              });
            },
          )
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                courseSchedules: [],
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                success: false,
                totalCourseSchedules: 0,
                totalStudentsEnrolled: 0,
              });
            }
          });
      },
    );
  };

  getCourseSchedulesForExport = async () => {
    const { sortBy, filters } = this.state;

    const courseSchedulesForExport = await EduApi.getEduProgramCourseSchedules(
      this.apiSignal.token,
      this.props.eduProgram.id,
      undefined,
      undefined,
      sortBy,
      filters,
      true,
    );

    return courseSchedulesForExport.courseSchedules;
  };

  toggleViewDetails = (show, courseSchedule = null) => {
    this.setState({
      selectedCourseSchedule: courseSchedule,
      showLocationDetailsModal: show,
    });
  };

  render() {
    const {
      eduChabadHouses,
      eduProgram,
      mobileMode,
      systemLists: { eduCourseSemesters = [] } = {},
    } = this.props;

    const {
      courseSchedules,
      loading,
      page,
      results,
      selectedCourseSchedule,
      showLocationDetailsModal,
      sortBy,
      success,
      totalCourseSchedules,
      totalStudentsEnrolled,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Schedules</p>
          <CourseSchedulesTableActions
            eduProgram={eduProgram}
            getCourseSchedulesForExport={this.getCourseSchedulesForExport}
          />
        </div>
        <div className="courses-table">
          <PaginatedTable
            filterComponent={
              <CourseSchedulesTableFilters
                applyFilters={this.filterCourseSchedules}
                eduChabadHouses={eduChabadHouses}
                eduCourseSemesters={eduCourseSemesters}
                eduProgram={eduProgram}
              />
            }
            loading={loading}
            loadData={this.getCourseSchedules}
            mobileMode={mobileMode}
            page={page}
            records={courseSchedules}
            renderHeader={() => (
              <CourseSchedulesTableHeader
                totalStudentsEnrolled={totalStudentsEnrolled}
              />
            )}
            renderRow={(courseSched, index) => (
              <CourseSchedulesTableRow
                key={index}
                courseSchedule={courseSched}
                onViewDetails={() => this.toggleViewDetails(true, courseSched)}
              />
            )}
            results={results}
            showResultsCount={true}
            sortBy={sortBy}
            success={success}
            totalCount={totalCourseSchedules}
          />
        </div>

        <LocationDetailsModal
          close={() => this.toggleViewDetails(false)}
          locationId={
            selectedCourseSchedule &&
            selectedCourseSchedule.eduChabadHouseEnrollmentID
          }
          show={showLocationDetailsModal}
        />
      </div>
    );
  }
}
