import React from "react";
import { browserHistory } from "react-router";
import { ApiCallErrorHandler } from "../../../lib/coc-common-scripts";
import {
  Unauthorized,
  Loader,
  ModalWithButtons /* , ModalButton */,
} from "../../../lib/coc-common-components";

import AuthService, { PermissionClaims } from "../../../services/AuthService";
import CustomEventsApi from "../../../services/resources/CustomEventsApi";
import { ContactType } from "../../Contact/ContactUtils";
import { /* CustomEvent, */ EmptyCustomEvent } from "../Utils";
import CustomEventManagerPage from "./CustomEventManagerPage";

// interface CustomEventManagerContainerProps {
//   location: { [key: string] };
//   params: { [key: string] };
// }

// interface CustomEventManagerContainerState {
//   loading: boolean;
//   eventData: CustomEvent;
//   displayMessage: string;
//   displayAndRedirectMessage: string;
//   displayDeleteMessage: string;
//   contactType: ContactType;
// }

class CustomEventManagerContainer extends React.Component {
  constructor(props) {
    super(props);
    const contactType = this.props.location.search.includes("student")
      ? ContactType.students
      : ContactType.shluchim;
    this.state = {
      loading: false,
      eventData: EmptyCustomEvent(contactType),
      displayMessage: "",
      displayAndRedirectMessage: "",
      displayDeleteMessage: "",
      contactType,
    };

    this.handleEventDataChange = this.handleEventDataChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }
  /** @param {React.FormEvent<HTMLFormElement>} evt */
  handleEventDataChange(evt) {
    this.setState({
      eventData: {
        ...this.state.eventData,
        [evt.currentTarget.name]: evt.currentTarget.value,
      },
    });
  }
  /** @param {CustomEvent} customEvent */
  handleSave(customEvent) {
    this.setState({ loading: true }, () => {
      const isNew = customEvent.id < 0;
      const apiCall = isNew ? CustomEventsApi.create : CustomEventsApi.update;
      apiCall(customEvent)
        .then((response) => {
          this.setState({
            eventData: response.data.result.value,
            displayAndRedirectMessage: "Event saved successfully",
            loading: false,
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err, true);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        });
    });
  }
  /** @param {CustomEvent} customEvent */
  handleDelete(customEvent) {
    this.setState({
      displayDeleteMessage: "You are about to delete this custom event",
    });
  }

  deleteEvent() {
    this.setState({ displayDeleteMessage: "", loading: true }, () =>
      CustomEventsApi.delete(this.state.eventData.id)
        .then((response) => {
          this.setState({
            loading: false,
            displayAndRedirectMessage: "Event successfully deleted",
          });
        })
        .catch((err) => {
          const errors = ApiCallErrorHandler(err);
          this.setState({ loading: false, displayMessage: errors.join("\n") });
        }),
    );
  }

  confirmDeleteButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "I understand. Delete it now.",
      className: "btn btn-danger",
      onClick: this.deleteEvent,
    });
    buttons.push({
      text: "Cancel",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayDeleteMessage: "" }),
    });
    return buttons;
  }

  messageButtons() {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () => this.setState({ displayMessage: "" }),
    });
    return buttons;
  }
  /** @param {string} */
  okAndRedirectButtons(redirectUrl) {
    /** @type {Array<ModalButton>} */
    const buttons = [];
    buttons.push({
      text: "OK",
      className: "btn btn-primary",
      onClick: () =>
        this.setState({ displayMessage: "" }, () => {
          browserHistory.push(redirectUrl);
        }),
    });
    return buttons;
  }

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return;
    }

    const id = Number(this.props.params.id);
    if (!Number.isNaN(id)) {
      this.setState({ loading: true }, () =>
        CustomEventsApi.get(id)
          .then((response) => {
            this.setState({
              eventData: response.data,
              loading: false,
            });
          })
          .catch((err) => {
            const errors = ApiCallErrorHandler(err, true);
            this.setState({
              loading: false,
              displayMessage: errors.join("\n"),
            });
          }),
      );
    }
  }

  render() {
    if (!AuthService.UserHasClaim(PermissionClaims.CustomEventManager)) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const { eventData } = this.state;
    return (
      <div>
        <CustomEventManagerPage
          eventData={eventData}
          handleEventDataChange={this.handleEventDataChange}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
        />
        {this.state.displayMessage && (
          <ModalWithButtons
            buttons={this.messageButtons()}
            text={this.state.displayMessage}
          />
        )}
        {this.state.displayAndRedirectMessage && (
          <ModalWithButtons
            buttons={this.okAndRedirectButtons("/config/customevents")}
            text={this.state.displayAndRedirectMessage}
          />
        )}
        {this.state.displayDeleteMessage && (
          <ModalWithButtons
            buttons={this.confirmDeleteButtons()}
            text={this.state.displayDeleteMessage}
          />
        )}
        {this.state.loading && <Loader />}
      </div>
    );
  }
}

export default CustomEventManagerContainer;
