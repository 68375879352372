import { EventTypes, EventCategories } from "../../models/EventCategories";

// interface MenuCategory {
//   title: string;
//   eventTypes: {
//     [eventType: number]: string;
//   };
//   order: number;
// }

// export interface MenuOption {
//   [eventCategory: number]: MenuCategory;
// }
/** @returns {MenuOption} */
const ShliachMenuOptionsFactory = () => ({
  [EventCategories.Security]: {
    title: "Security Initiative",
    eventTypes: {
      [EventTypes.EstablishedRelationshipWithLocalLawEnforcement]:
        "Relationship with LEO",
      [EventTypes.SecurityRelatedIncident]: "Log Incident",
      [EventTypes.ReceivedDepartmentOfHomelandSecurityGrant]:
        "Record Receipt of DHS Grant",
      [EventTypes.SecurityInfrastructureSurvey]: "Security Infrastructure",
      [EventTypes.SecurityTrainingSurvey]: "Security Training",
    },
    order: 1,
  },
  [EventCategories.FundraisingBootcamp]: {
    title: "Fundraising Bootcamp",
    eventTypes: {
      [EventTypes.SubmittedMonthlySnapshot]: "Submitted Monthly Snapshot",
      [EventTypes.SubmittedTopTenDonorList]: "Submitted Top 10 Donors",
      [EventTypes.SubmittedCampaignSuspectsForm]: "Submitted Campaign Suspects",
      [EventTypes.SubmittedProspectProfileAndEngagementForm]:
        "Submitted Fillable Cultivation",
    },
    order: 2,
  },
  [EventCategories.MediaAndPr]: {
    title: "Media & PR",
    eventTypes: {
      [EventTypes.RelatedNewsStory]: "Log Media Item",
    },
    order: 3,
  },
  [EventCategories.SeedBuildingGrant]: {
    title: "Point Person for Program",
    eventTypes: {
      [EventTypes.ManagingLamplighters]: "Lamplighters",
      [EventTypes.ManagingShabbaton]: "Shabbaton",
      [EventTypes.ManagingTefillin]: "Tefillin",
      [EventTypes.ManagingChayenu]: "Chayenu",
      [EventTypes.ManagingRaffle]: "Raffle",
      [EventTypes.ManagingIsraeLinks]: "IsraeLinks",
      [EventTypes.ManagingSeedBuildingGrant]: "Seed/Building Grant",
      [EventTypes.ManagingLamdeini]: "Lamdeini",
      [EventTypes.ManagingSSS]: "SSS",
    },
    order: 4,
  },
});
/** @returns {MenuOption} */
const StudentMenuOptionsFactory = () => ({
  [EventCategories.Tefillin]: {
    title: "Tefillin",
    eventTypes: {
      [EventTypes.ReceivedTefillinBankSubsidy]: "Received Subsidy",
    },
    order: 1,
  },
  [EventCategories.Chayenu]: {
    title: "Chayenu",
    eventTypes: {
      [EventTypes.ReceivedChayenuSubsidy]: "Received Subsidy",
    },
    order: 2,
  },
  [EventCategories.Mezuzah]: {
    title: "Mezuzah",
    eventTypes: {
      [EventTypes.ReceivedMezuzah]: "Received Mezuzah",
    },
    order: 3,
  },
  [EventCategories.Lamdeini]: {
    title: "Lamdeini",
    eventTypes: {
      [EventTypes.ReceivedLamdeiniTravelGrant]: "Received Travel Grant",
    },
    order: 4,
  },
});
/** @returns {MenuOption} */
const NotesMenuOptionsFactory = () => ({
  [EventCategories.Notes]: {
    title: "Notes",
    eventTypes: {
      [EventTypes.AddNote]: "Add New Note",
    },
    order: 5,
  },
});

export const StudentMenuOptions = StudentMenuOptionsFactory();
export const ShliachMenuOptions = ShliachMenuOptionsFactory();
export const NotesMenuOptions = NotesMenuOptionsFactory();
/** @param {MenuCategory} a
 * @param {MenuCategory} b
 * @returns {number}
 */
const compare = (a, b) => a.order - b.order;
/** @param {MenuOption} menu
 * @returns {Array<any>}
 */
export function GetAsArray(menu) {
  return Object.keys(menu)
    .map((key) => Object.assign({}, menu[key], { category: key }))
    .sort(compare);
}
/** @param {EventTypes} eventType
 * @param {MenuOption} menu
 */
export function FindEventInMenu(eventType, menu) {
  return GetAsArray(menu).find((item) => item.eventTypes[eventType]);
}
/** @param {EventTypes} eventType
 * @param {MenuOption} menu
 * @returns {EventCategories}
 */
export function GetCategoryFromType(eventType, menu) {
  return FindEventInMenu(eventType, menu).category;
}
