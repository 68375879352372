import React from "react";
import { Link } from "react-router";
import { browserHistory } from "react-router";
import ConfirmationModal from "../../../../shared/ConfirmationModal";
import FadeOutErrorMessage from "../../../../shared/FadeOutErrorMessage";
import DatePicker from "react-datepicker";
import Loader from "../../../../common/Loader";
import NumberFormat from "react-number-format";
import ProgramBreadcrumbsHeader from "../../../../common/ProgramBreadcrumbsHeader";
import Select from "react-select";

import ProgramsApi from "../../../../../services/resources/ProgramsApi";
// import SystemApi from "../../../../../services/resources/SystemApi";
import { ApiCallErrorMessageHandler } from "../../../../../lib/coc-common-scripts";
import AuthService, {
  PermissionClaims,
} from "../../../../../services/AuthService";
import { Unauthorized } from "../../../../../lib/coc-common-components";
import {
  getFormattedValuesForForm,
  removeEmptyFromObj,
  removeTimezoneFormatFromDateStartOfDay,
} from "../../../../../lib";
import axios from "axios";
import moment from "moment";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";

export default class RegistrationDetailsPage extends React.PureComponent {
  state = {
    authorized: true,
    viewOnly: false,

    applicationSettings: null,
    registration: null,
    initialRegistration: null,

    errorMessage: "",
    loading: true,

    isRequestedPageLeave: false,
    showCancelChangesConfirmationModal: false,
    showSubmitConfirmationModal: false,
    submitAttempted: false,
    submitRegistrationErrorMessage: "",
    submitRegistrationLoading: false,

    // systemLists: {
    //   applicationStatuses: [],
    // },
    // systemListsErrorMessage: "",
  };

  apiSignal = axios.CancelToken.source();
  incompleteSubmissionErrorMessage = "Please complete required fields";

  componentDidMount() {
    if (!AuthService.UserHasClaim(PermissionClaims.ProgramRead)) {
      this.setState({ authorized: false });
    } else if (!AuthService.UserHasClaim(PermissionClaims.ProgramEdit)) {
      this.setState({ viewOnly: true });
    }

    const { route, router } = this.props;
    router.setRouteLeaveHook(route, this.onLeave);

    this.getRegistrationDetails();

    // this.getSystemLists();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  onLeave = () => {
    const { initialRegistration, isRequestedPageLeave, registration } =
      this.state;

    if (!isRequestedPageLeave && !_isEqual(registration, initialRegistration)) {
      return "Are you sure you want to leave this page?  Your unsaved changes will be lost.";
    }
  };

  //   getSystemLists = async () => {
  //     try {
  //       const systemLists = await SystemApi.lists(["applicationStatuses"]);
  //       this.setState({ systemLists });
  //     } catch (err) {
  //       if (!axios.isCancel(err)) {
  //         this.setState({
  //           systemListsErrorMessage: ApiCallErrorMessageHandler(err),
  //         });
  //       }
  //     }
  //   };

  getRegistrationDetails = async () => {
    const {
      params: { programId, registrationId, scheduleId },
    } = this.props;

    this.setState({
      loading: true,
    });

    try {
      const registrationDetails = await ProgramsApi.getScheduleRegistration(
        this.apiSignal.token,
        programId,
        scheduleId,
        registrationId,
      );
      const registration = getFormattedValuesForForm(registrationDetails);

      let applicationSettings = null;
      //retrieve schedule application settings if program has application process
      if (registration.programHasApplicationProcess) {
        applicationSettings = await ProgramsApi.getScheduleApplicationSettings(
          this.apiSignal.token,
          programId,
          scheduleId,
        );
      }

      this.setState({
        applicationSettings,
        initialRegistration: _cloneDeep(registration),
        loading: false,
        registration,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          errorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and registration details could not be retrieved. Please try again.",
          ),
          loading: false,
        });
      }
    }
  };

  onChangeRegistration = (name, value, otherUpdates) => {
    let registration = _cloneDeep(this.state.registration);
    _set(registration, name, value);

    if (otherUpdates) {
      Object.keys(otherUpdates).forEach((update) =>
        _set(registration, update, otherUpdates[update]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ registration }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { submitRegistrationErrorMessage } = this.state;
        if (
          submitRegistrationErrorMessage &&
          submitRegistrationErrorMessage ===
            this.incompleteSubmissionErrorMessage
        ) {
          const isValid = this.validateRegistration();
          if (isValid) {
            this.setState({
              submitRegistrationErrorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeRegistrationEvt = ({ target: { checked, name, type, value } }) => {
    return this.onChangeRegistration(
      name,
      type === "checkbox" ? checked : value,
    );
  };

  onCancelRegistrationChanges = () => {
    const { initialRegistration, registration } = this.state;
    if (_isEqual(registration, initialRegistration)) {
      const {
        params: { programId, scheduleId },
      } = this.props;
      browserHistory.push(
        `/programs/${programId}/schedules/${scheduleId}/registrations`,
      );
      return;
    }

    this.setState({ showCancelChangesConfirmationModal: true });
  };

  cancelRegistrationChanges = () => {
    this.setState(
      {
        registration: _cloneDeep(this.state.initialRegistration),
        isRequestedPageLeave: true,
        showCancelChangesConfirmationModal: false,
        submitAttempted: false,
        submitRegistrationErrorMessage: "",
      },
      () => {
        const {
          params: { programId, scheduleId },
        } = this.props;
        browserHistory.push(
          `/programs/${programId}/schedules/${scheduleId}/registrations`,
        );
      },
    );
  };

  onSubmitRegistration = () => {
    this.setState({
      submitAttempted: true,
      submitRegistrationErrorMessage: "",
    });

    const isValid = this.validateRegistration();
    if (!isValid) {
      this.setState({
        submitRegistrationErrorMessage: this.incompleteSubmissionErrorMessage,
      });
      return;
    }

    this.setState({
      showSubmitConfirmationModal: true,
    });
  };

  submitRegistration = async () => {
    this.setState({
      showSubmitConfirmationModal: false,
      submitRegistrationErrorMessage: "",
      submitRegistrationLoading: true,
    });

    try {
      const {
        params: { programId, scheduleId },
      } = this.props;
      const { applicationSettings } = this.state;

      const registrationForSubmission = _cloneDeep(this.state.registration);
      removeEmptyFromObj(registrationForSubmission);
      //filter out blank application statuses and payments
      registrationForSubmission.applicationHistory =
        registrationForSubmission.applicationHistory
          .filter((h) => h.dateTime)
          //adding sortOrder prop for sorting by status in order to ideally save in the correct order (prop is not used by the api)
          .map((h) => ({
            ...h,
            sortOrder: applicationSettings?.statusesWithDisplay.find(
              (s) => s.enumValue === h.status,
            )?.intValue,
          }))
          .sort((h1, h2) => h1.sortOrder - h2.sortOrder);
      registrationForSubmission.payments =
        registrationForSubmission.payments.filter((p) => p.paymentDate);
      const savedRegistration = await ProgramsApi.submitScheduleRegistration(
        this.apiSignal.token,
        programId,
        scheduleId,
        registrationForSubmission,
      );

      //   //combine saved registration details with registration in state to preserve informational fields
      //   const updatedRegistration = {
      //     ...this.state.registration,
      //     ...savedRegistrationDetails,
      //   };
      const updatedRegistration = getFormattedValuesForForm(savedRegistration);
      this.setState({
        initialRegistration: updatedRegistration,
        registration: _cloneDeep(updatedRegistration),
        submitRegistrationLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          submitRegistrationErrorMessage: ApiCallErrorMessageHandler(err),
          submitRegistrationLoading: false,
        });
      }
    }
  };

  validateRegistration = () => {
    const {
      registration: { payments },
    } = this.state;

    // require both amount and paymentDate for payments if either is provided
    if (payments.filter((p) => p).some((p) => !!p.amount !== !!p.paymentDate)) {
      return false;
    }

    return true;
  };

  onToggleRegistrationPerson = (registrationPerson) => {
    //toggle set/clear registration person ID property and isRegistered property in person details
    const isSpouse =
      registrationPerson.personID ===
      this.state.registration.registrationPersonSpouse?.personID;
    const personIdPropertyName = isSpouse
      ? "registrationPersonSpouseID"
      : "registrationPersonID";
    const personPropertyName = isSpouse
      ? "registrationPersonSpouse"
      : "registrationPerson";

    this.onChangeRegistration(
      personIdPropertyName,
      registrationPerson.isRegistered ? null : registrationPerson.personID,
      {
        [personPropertyName]: {
          ...registrationPerson,
          isRegistered: !registrationPerson.isRegistered,
        },
      },
    );
  };

  render() {
    const {
      params: { scheduleId },
    } = this.props;

    const {
      authorized,
      applicationSettings,
      errorMessage,
      initialRegistration,
      loading,
      registration,
      showCancelChangesConfirmationModal,
      showSubmitConfirmationModal,
      submitAttempted,
      submitRegistrationErrorMessage,
      submitRegistrationLoading,
      //   systemLists: { applicationStatuses = [] } = {},
      viewOnly,
    } = this.state;

    const {
      allowNotes,
      items,
      paymentInstallments,
      statusesWithDisplay: applicationStatuses = [],
    } = applicationSettings || {};
    const {
      applicationStatus,
      applicationHistory,
      campusName,
      chabadHouseName,
      itemQuantities,
      notes,
      payments,
      programHasApplicationProcess,
      programName,
      programScheduleName,
      registrationDate,
      registrationPerson,
      registrationPersonID,
      registrationPersonSpouse,
      registrationPersonSpouseID,
    } = registration || {};

    if (!authorized) {
      return <Unauthorized userName={AuthService.getCurrentUser().name} />;
    }

    const saveButton = !viewOnly && (
      <React.Fragment>
        <button
          className="custom-btn btn-accent uppercase-text"
          disabled={_isEqual(registration, initialRegistration)}
          onClick={this.onSubmitRegistration}
        >
          Save
        </button>
        {!!submitRegistrationErrorMessage && (
          <FadeOutErrorMessage
            className="error-text text-right block"
            message={submitRegistrationErrorMessage}
            onTimeout={() =>
              this.setState({
                submitRegistrationErrorMessage: "",
              })
            }
          />
        )}
      </React.Fragment>
    );

    return (
      <div className="program-registration-form-page page container">
        <ProgramBreadcrumbsHeader
          getPrevPages={(sched) => [
            {
              path: `/programs/${sched.programID}/schedules/${sched.scheduleID}/registrations`,
              title: "Registrations",
            },
          ]}
          pageTitle="Registration Details"
          scheduleId={scheduleId}
        />
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : errorMessage || !registration ? (
          <div className="full-page-error-text error-text">
            <img src="/img/error.svg" alt="error robot" height="240" />
            <p>{errorMessage}</p>
          </div>
        ) : (
          <React.Fragment>
            <div className="card full-width">
              <p className="flex flex-align-center xl-text fw-700">
                <i
                  className="material-icons link-text xl-text mr-16"
                  onClick={() => browserHistory.goBack()}
                >
                  {" "}
                  arrow_back
                </i>
                {programScheduleName || programName}
              </p>
              <hr />
              <div className="two-column-grid">
                {[registrationPerson, registrationPersonSpouse]
                  .filter((p) => p)
                  .map((p, i) => {
                    //allow removing a person from a registration only if there is a registered spouse
                    const canRemove = !!(
                      registrationPersonID && registrationPersonSpouseID
                    );
                    return (
                      <div
                        className={!p.isRegistered ? "unregistered-person" : ""}
                        key={i}
                      >
                        <div className="flex flex-align-center xl-text fw-700">
                          <p>{p.fullName}</p>
                          <Link
                            className="fw-700 link-text ml-16 flex flex-align-center"
                            to={
                              p.studentID
                                ? `/students/${p.studentID}`
                                : `/shluchim/${p.shliachID}/details`
                            }
                          >
                            <i className="material-icons">open_in_new</i>
                          </Link>
                        </div>
                        <div className="mt-16" style={{ gridGap: "0" }}>
                          <div className="flex flex-align-center mb-8">
                            <p className="fw-700" style={{ width: "144px" }}>
                              Date of Birth
                            </p>
                            <p>
                              {p.dob ? moment(p.dob).format("MM/DD/YYYY") : ""}
                            </p>
                          </div>
                          <div className="flex flex-align-center mb-8">
                            <p className="fw-700" style={{ width: "144px" }}>
                              Phone
                            </p>
                            <p>{p.phone}</p>
                          </div>
                          <div className="flex flex-align-center mb-8">
                            <p className="fw-700" style={{ width: "144px" }}>
                              Email
                            </p>
                            <p>
                              {p.email ? (
                                <a
                                  className="link-text"
                                  href={`mailto:${p.email}`}
                                >
                                  {p.email}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                        </div>
                        {(!p.isRegistered || canRemove) && (
                          <div
                            className="flex flex-align-center small-text fw-500 mt-16 link-text toggle-registration-link"
                            onClick={() => this.onToggleRegistrationPerson(p)}
                          >
                            <i className="material-icons xl-text">
                              {p.isRegistered ? "remove_circle" : "add_circle"}
                            </i>
                            <p className="ml-8">
                              {p.isRegistered ? "Remove from" : "Add to"}{" "}
                              Registration
                            </p>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <hr />
              <div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Chabad House
                  </p>
                  <p>{chabadHouseName}</p>
                </div>
                <div className="flex flex-align-center mb-8">
                  <p className="fw-700" style={{ width: "144px" }}>
                    Campus
                  </p>
                  <p>{campusName}</p>
                </div>
              </div>
              <hr />
              <div className="registration-form-grid">
                {programHasApplicationProcess && (
                  <div className="flex flex-align-center">
                    <p
                      className="accent-text small-text"
                      style={{ minWidth: "144px", width: "144px" }}
                    >
                      Application Status
                    </p>
                    <Select
                      className="custom-select"
                      clearable={false}
                      disabled={viewOnly}
                      onChange={({ value }) =>
                        this.onChangeRegistration("applicationStatus", value)
                      }
                      options={
                        applicationStatuses &&
                        applicationStatuses.map((status) => ({
                          value: status.enumValue,
                          label: status.displayValue,
                        }))
                      }
                      placeholder="Select Status"
                      style={{ minWidth: "163px" }}
                      value={applicationStatus}
                    />
                  </div>
                )}
                <div className="flex flex-align-center">
                  <p
                    className="accent-text small-text"
                    style={{ width: "144px" }}
                  >
                    {programHasApplicationProcess ? "Applied" : "Registered"} on
                  </p>
                  <DatePicker
                    className="custom-input"
                    clearable={false}
                    onChange={(date) => {
                      this.onChangeRegistration(
                        "registrationDate",
                        removeTimezoneFormatFromDateStartOfDay(date),
                      );
                    }}
                    selected={
                      registrationDate ? moment(registrationDate) : null
                    }
                  />
                </div>
                {applicationStatuses
                  .filter(
                    (s) =>
                      !["Applied", "Paid", "PartiallyPaid"].includes(
                        s.enumValue,
                      ),
                  )
                  .map((status, index) => {
                    const statusIndex = applicationHistory.findIndex(
                      (h) => h.status === status.enumValue,
                    );
                    const { dateTime = "" } =
                      applicationHistory[statusIndex] || {};
                    return (
                      <div className="flex flex-align-center" key={index}>
                        <p
                          className="accent-text small-text"
                          style={{ width: "144px" }}
                        >
                          {status.displayValue} on
                        </p>
                        <DatePicker
                          className="custom-input"
                          clearable={false}
                          onChange={(date) => {
                            const updatedApplicationHistory = [
                              ...applicationHistory,
                            ];
                            const updatedDate =
                              removeTimezoneFormatFromDateStartOfDay(date);
                            if (updatedApplicationHistory[statusIndex]) {
                              updatedApplicationHistory[statusIndex].dateTime =
                                updatedDate;
                            } else {
                              updatedApplicationHistory.push({
                                status: status.enumValue,
                                dateTime: updatedDate,
                              });
                            }
                            this.onChangeRegistration(
                              "applicationHistory",
                              updatedApplicationHistory,
                            );
                          }}
                          selected={dateTime ? moment(dateTime) : null}
                        />
                      </div>
                    );
                  })}
              </div>
              {!!items && (
                <React.Fragment>
                  <hr />
                  <div className="registration-form-grid">
                    {items.map((item) => {
                      const itemQuantity = itemQuantities?.find(
                        (iq) =>
                          iq.programScheduleApplicationSettingItemID ===
                          item.id,
                      );
                      return (
                        <React.Fragment>
                          <div className="flex flex-align-center">
                            <p
                              className="accent-text small-text"
                              style={{ width: "144px" }}
                            >
                              {item.name}
                            </p>

                            <NumberFormat
                              allowNegative={false}
                              className="custom-input"
                              placeholder="Quantity"
                              onValueChange={({ floatValue }) => {
                                let newItemQuantities;
                                if (itemQuantity) {
                                  newItemQuantities = itemQuantities.map((iq) =>
                                    iq.programScheduleApplicationSettingItemID ===
                                    item.id
                                      ? { ...iq, quantity: floatValue }
                                      : { ...iq },
                                  );
                                } else {
                                  newItemQuantities = [
                                    ...itemQuantities,
                                    {
                                      programScheduleApplicationSettingItemID:
                                        item.id,
                                      quantity: floatValue,
                                    },
                                  ];
                                }
                                this.onChangeRegistration(
                                  "itemQuantities",
                                  newItemQuantities,
                                );
                              }}
                              thousandSeparator={true}
                              value={itemQuantity?.quantity ?? ""}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
              {!!paymentInstallments && (
                <React.Fragment>
                  <hr />
                  <div className="registration-form-grid">
                    {Array(paymentInstallments)
                      .fill()
                      .map((_, index) => {
                        const { amount = "", paymentDate = "" } =
                          payments[index] || {};
                        //enable payment if:
                        const enablePayment =
                          index === 0 || //it's the first payment
                          !!(amount || paymentDate) || //there is payment info filled in
                          !!(
                            payments[index - 1]?.amount &&
                            payments[index - 1].paymentDate
                          ); //the prior payment has been filled in
                        return (
                          <React.Fragment key={index}>
                            <div className="flex flex-align-center">
                              <p
                                className="accent-text small-text"
                                style={{ width: "144px" }}
                              >
                                Date of Installment #{index + 1}
                              </p>
                              <DatePicker
                                className={`custom-input ${
                                  submitAttempted && amount && !paymentDate
                                    ? "error"
                                    : ""
                                }`}
                                clearable={false}
                                disabled={!enablePayment}
                                onChange={(date) => {
                                  const updatedPayments = [...payments];
                                  if (!updatedPayments[index]) {
                                    updatedPayments[index] = {};
                                  }
                                  updatedPayments[index].paymentDate =
                                    removeTimezoneFormatFromDateStartOfDay(
                                      date,
                                    );
                                  this.onChangeRegistration(
                                    "payments",
                                    updatedPayments,
                                  );
                                }}
                                selected={
                                  paymentDate ? moment(paymentDate) : null
                                }
                              />
                            </div>
                            <div className="flex flex-align-center">
                              <p
                                className="accent-text small-text"
                                style={{ width: "144px" }}
                              >
                                Amount Paid
                              </p>
                              <NumberFormat
                                allowNegative={false}
                                className={`custom-input dollar-input ${
                                  submitAttempted && paymentDate && !amount
                                    ? "error"
                                    : ""
                                }`}
                                disabled={!enablePayment}
                                onValueChange={({ floatValue }) => {
                                  const updatedPayments = [...payments];
                                  if (!updatedPayments[index]) {
                                    updatedPayments[index] = {};
                                  }
                                  updatedPayments[index].amount =
                                    floatValue >= 0 ? floatValue : "";
                                  this.onChangeRegistration(
                                    "payments",
                                    updatedPayments,
                                  );
                                }}
                                decimalScale={2}
                                thousandSeparator={true}
                                value={amount}
                              />
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </React.Fragment>
              )}

              {allowNotes && (
                <React.Fragment>
                  <hr />
                  <div className="flex">
                    <p
                      className="accent-text small-text"
                      style={{ width: "144px" }}
                    >
                      Notes
                    </p>
                    <textarea
                      className="custom-input full-width"
                      dir="auto"
                      disabled={viewOnly}
                      name="notes"
                      onChange={this.onChangeRegistrationEvt}
                      style={{ resize: "none", height: "72px" }}
                      value={notes}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
            {!viewOnly && (
              <div className="program-registration-form-btns flex flex-justify-space mt-16 relative">
                <button
                  className="custom-btn btn-light uppercase-text"
                  disabled={submitRegistrationLoading}
                  onClick={this.onCancelRegistrationChanges}
                >
                  Cancel
                </button>
                {submitRegistrationLoading ? (
                  <div className="medium-loader">
                    <Loader />
                  </div>
                ) : (
                  saveButton
                )}
              </div>
            )}
            <ConfirmationModal
              cancel={() =>
                this.setState({
                  showCancelChangesConfirmationModal: false,
                  showSubmitConfirmationModal: false,
                })
              }
              cancelText={showCancelChangesConfirmationModal && "No"}
              confirm={
                showCancelChangesConfirmationModal
                  ? this.cancelRegistrationChanges
                  : this.submitRegistration
              }
              confirmText={showCancelChangesConfirmationModal && "Yes"}
              message={`Are you sure you want to ${
                showCancelChangesConfirmationModal
                  ? "cancel your changes?"
                  : "save changes?"
              }`}
              show={
                showCancelChangesConfirmationModal ||
                showSubmitConfirmationModal
              }
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}
