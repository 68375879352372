import React from "react";
import axios from "axios";
import SystemApi from "../../../services/resources/SystemApi";
import _isEqual from "lodash.isequal";
import _cloneDeep from "lodash.clonedeep";

export default class extends React.PureComponent {
  state = {
    initialCashTypes: [],
    cashTypes: [],
    errorMessage: "",
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount = async () => {
    const systemLists = await SystemApi.lists(["cashTypes"]);
    const { cashTypes = [] } = systemLists;
    this.setState({ initialCashTypes: _cloneDeep(cashTypes), cashTypes });
  };

  addCashTypeOption = () => {
    const updatedCashTypes = this.state.cashTypes;
    updatedCashTypes.push({ name: "" });
    this.setState({ cashTypes: [...updatedCashTypes] });
  };

  editCashType = (e, i) => {
    const updatedCashTypes = this.state.cashTypes;
    updatedCashTypes[i]["name"] = e.target.value;
    this.setState({ cashTypes: [...updatedCashTypes] });
    if (this.state.errorMessage) {
      this.checkValidation();
    }
  };

  deleteCashTypeOption = (i) => {
    let updatedCashTypes = this.state.cashTypes;
    updatedCashTypes.splice(i, 1);
    this.setState({ cashTypes: [...updatedCashTypes] });
  };

  checkValidation = () => {
    const { cashTypes } = this.state;
    const hasValue = cashTypes.map((type) => {
      return !!type.name;
    });
    if (hasValue.includes(false)) {
      this.setState({ errorMessage: "Please fill out required fields" });
      return false;
    } else {
      this.setState({ errorMessage: "" });
      return true;
    }
  };

  onSubmit = async () => {
    const { cashTypes } = this.state;
    const valid = this.checkValidation();
    if (valid) {
      SystemApi.submitCashTypes(this.apiSignal.token, cashTypes).then(
        (cashTypes) => {
          this.setState({ initialCashTypes: _cloneDeep(cashTypes) });
          this.setState({ cashTypes });
        },
      );
    }
  };

  render() {
    const { initialCashTypes, cashTypes, errorMessage } = this.state;
    const dirty = !_isEqual(initialCashTypes, cashTypes);
    return (
      <div className="cash-types-page">
        <div className="card">
          <div className="flex flex-align-center flex-justify-space">
            <div className="flex flex-align-center">
              <p className="medium-text fw-700">Cash / Other Payment Options</p>
              <i
                className="material-icons accent-text-secondary xl-text ml-16 link-text-secondary"
                onClick={() => this.addCashTypeOption()}
              >
                add_circle
              </i>
            </div>
            <div className="relative">
              <button
                className="btn btn-light custom-btn btn-medium"
                onClick={() => {
                  this.props.router.goBack();
                }}
                style={{ width: 108 }}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent custom-btn btn-medium ml-16"
                disabled={!dirty ? true : false}
                onClick={() => {
                  this.onSubmit();
                }}
                style={{ width: 108 }}
              >
                {/* {this.state.saving ? "Saving..." : "Save"} */}
                Save
              </button>
              {errorMessage && (
                <p
                  className="error-text absolute"
                  style={{ right: 0, marginTop: 4, fontSize: 13 }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
          <p className="accent-text">
            Use this area to set payment options for monies collected directly
            by the Shliach
          </p>
          {cashTypes.length > 0 && (
            <p style={{ color: "#FF6B6B" }}>
              Editing any payment option below will affect any instances where
              payment option was used:{" "}
              <span className="fw-700">use extreme caution</span>
            </p>
          )}
          <div className="mt-24">
            {cashTypes.map((type, i) => {
              return (
                <div className="flex flex-align-center mb-16" key={i}>
                  <input
                    className={`custom-input ${
                      errorMessage && !type.name ? "error" : ""
                    }`}
                    onChange={(e) => this.editCashType(e, i)}
                    placeholder="Enter payment option"
                    style={{ width: 220 }}
                    value={type.name}
                  />
                  {!initialCashTypes.some((t) => t.id === type.id) && (
                    <i
                      className="material-icons link-text-secondary accent-text ml-16"
                      onClick={() => this.deleteCashTypeOption(i)}
                    >
                      delete
                    </i>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
