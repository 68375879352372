import React, { useState, useEffect, useRef } from "react";
import ConfirmationModal from "../../shared/ConfirmationModal";
import PaginatedTable from "../../shared/PaginatedTable";
import AddLocationModal from "./AddLocationModal";

import EngagementApi from "../../../services/resources/EngagementApi";
import { ApiCallErrorMessageHandler } from "../../../lib/coc-common-scripts";
import axios from "axios";

import debounce from "lodash.debounce";

import ExportCSVButton from "../../shared/ExportCSVButton";

import { browserHistory } from "react-router";
import { flattenArray, formatFullName } from "../../../lib";
import moment from "moment";
import ChabadHousesApi from "../../../services/resources/ChabadHousesApi";

export default class EngagementPortalLocations extends React.PureComponent {
  sortOptions = [
    { id: "shliachName", name: "Shliach Name" },
    { id: "shluchaName", name: "Shlucha Name" },
    { id: "chabadHouseName", name: "Chabad House Name" },
    { id: "campusName", name: "Campus Name" },
    { id: "memberSince", name: "Member Since" },
  ];

  numResults = 12;

  state = {
    errorMessage: "",
    filters: {
      keyword: "",
    },
    loading: false,
    locations: [],
    page: 1,
    removeLocation: null,
    removeLocationErrorMessage: null,
    removeLocationLoading: false,
    showRemoveLocationModal: false,
    showAddLocationModal: false,
    sortBy: this.sortOptions[0].value,
    success: true,
    totalLocations: 0,
    chabadHousesList: [],
    chabadHousesListErrorMessage: "",
    chabadHousesListLoading: false,
  };

  apiSignal = axios.CancelToken.source();

  componentDidMount() {
    this.getChabadHouses();
  }

  componentWillUnmount() {
    this.apiSignal.cancel();
  }

  filterLocations = (filters) => {
    this.setState({ filters }, () => {
      this.getLocations();
    });
  };

  getChabadHouses = async () => {
    try {
      this.setState({
        chabadHousesListErrorMessage: "",
        chabadHousesListLoading: true,
      });

      const chabadHousesList = await ChabadHousesApi.getChabadHousesDetails(
        this.apiSignal.token
      );

      this.setState({
        chabadHousesList,
        chabadHousesListLoading: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          chabadHousesListErrorMessage: ApiCallErrorMessageHandler(
            err,
            "Sorry, something went wrong and we could not retrieve available locations. Please try again."
          ),
          chabadHousesListLoading: false,
        });
      }
    }
  };

  getLocations = (
    page = 1,
    numResults = this.numResults,
    sortBy = this.state.sortBy
  ) => {
    this.setState(
      {
        errorMessage: "",
        loading: true,
        page,
        sortBy,
        success: true,
      },
      () => {
        EngagementApi.getEngagementEnrollments(
          this.apiSignal.token,
          page,
          numResults,
          sortBy,
          this.state.filters
        )
          .then(({ chabadHouses, totalChabadHouses }) => {
            this.setState({
              loading: false,
              locations: chabadHouses,
              totalLocations: totalChabadHouses,
            });
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              this.setState({
                errorMessage: ApiCallErrorMessageHandler(err),
                loading: false,
                locations: [],
                success: false,
                totalLocations: 0,
              });
            }
          });
      }
    );
  };

  getLocationsForExport = async (exportType = null) => {
    const { sortBy, filters } = this.state;

    const locationsForExport = await EngagementApi.getEngagementEnrollments(
      this.apiSignal.token,
      undefined,
      undefined,
      sortBy,
      filters,
      exportType
    );

    return locationsForExport.chabadHouses;
  };

  submitNewLocation = async (chabadHouseId) => {
    const submitLocationResponse = {};

    try {
      await EngagementApi.addEngagementEnrollment(
        chabadHouseId,
        this.apiSignal.token
      );
      submitLocationResponse.success = true;

      //reload locations
      this.getLocations();
    } catch (err) {
      if (!axios.isCancel(err)) {
        submitLocationResponse.error = err;
        submitLocationResponse.errorMessage = ApiCallErrorMessageHandler(err);
      }
    }

    return submitLocationResponse;
  };

  removeLocation = async () => {
    this.setState({
      removeLocationErrorMessage: "",
      removeLocationLoading: true,
    });

    try {
      await EngagementApi.removeEngagementEnrollment(
        this.state.removeLocation.chabadHouseID,
        this.apiSignal.token
      );

      //reload locations
      this.getLocations(this.state.page);

      this.setState({
        removeLocation: null,
        removeLocationLoading: false,
        showRemoveLocationModal: false,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.setState({
          removeLocationErrorMessage: ApiCallErrorMessageHandler(err),
          removeLocationLoading: false,
        });
      }
    }
  };

  render() {
    const { mobileMode, rsvpSystem } = this.props;

    const {
      loading,
      locations,
      page,
      removeLocation,
      removeLocationErrorMessage,
      removeLocationLoading,
      showAddLocationModal,
      showRemoveLocationModal,
      sortBy,
      success,
      totalLocations,
      chabadHousesList,
      chabadHousesListLoading,
      chabadHousesListErrorMessage,
    } = this.state;

    return (
      <div>
        <div className="flex flex-justify-space flex-align-center">
          <p className="xxl-text fw-500">Locations</p>
          <Actions
            getLocationsForExport={this.getLocationsForExport}
            onAddLocation={() => this.setState({ showAddLocationModal: true })}
            rsvpSystem={rsvpSystem}
          />
        </div>
        <div className="engagement-locations-table">
          <PaginatedTable
            filterComponent={<Filters applyFilters={this.filterLocations} />}
            loading={loading}
            loadData={this.getLocations}
            mobileMode={mobileMode}
            page={page}
            records={locations}
            renderHeader={() => <Header />}
            renderRow={(location, index) => (
              <Row
                key={index}
                location={location}
                onRemoveLocation={() =>
                  this.setState({
                    removeLocation: location,
                    showRemoveLocationModal: true,
                  })
                }
              />
            )}
            results={this.numResults}
            showResultsCount={true}
            sortBy={sortBy}
            sortOptions={this.sortOptions}
            success={success}
            totalCount={totalLocations}
          />
        </div>

        {showAddLocationModal && (
          <AddLocationModal
            chabadHousesList={chabadHousesList}
            chabadHousesListLoading={chabadHousesListLoading}
            chabadHousesListErrorMessage={chabadHousesListErrorMessage}
            close={() => this.setState({ showAddLocationModal: false })}
            submit={this.submitNewLocation}
          />
        )}

        {showRemoveLocationModal && (
          <ConfirmationModal
            cancel={() =>
              this.setState({
                removeLocation: null,
                removeLocationErrorMessage: "",
                showRemoveLocationModal: false,
              })
            }
            confirm={this.removeLocation}
            errorMessage={removeLocationErrorMessage}
            loading={removeLocationLoading}
            message={`Are you sure you'd like to remove ${
              removeLocation ? removeLocation.chabadHouseName : "this location"
            }'s access to the Engagement Portal?`}
            show={showRemoveLocationModal}
          />
        )}
      </div>
    );
  }
}

function Filters({ applyFilters }) {
  const [keyword, setKeyword] = useState("");

  const debouncedApplyFilters = useRef(debounce(applyFilters, 500));

  useEffect(() => {
    debouncedApplyFilters.current({ keyword });
  }, [keyword, applyFilters]);

  return (
    <div className="mt-24 mb-24">
      <div className="search-input">
        <input
          type="text"
          placeholder="Search Shliach Name or Chabad House Name"
          name="keyword"
          onChange={(event) => setKeyword(event.target.value)}
          value={keyword}
        />
        <i className="material-icons accent-text-secondary flex flex-align-center">
          search
        </i>
      </div>
    </div>
  );
}

function Actions({ getLocationsForExport, onAddLocation }) {
  const getExportHeaders = (data) => {
    const maxEmailRecipientsCount = Math.max(
      ...data.map((d) => d.emailRecipients?.length || 0)
    );
    return [
      { label: "Chabad House ID", value: "chabadHouseID" },
      { label: "Chabad House", value: "chabadHouseName" },
      { label: "Email", value: "chabadHouseEmail" },
      { label: "Phone", value: "chabadHousePhone" },
      { label: "Address 1", value: "chabadHouseAddress1" },
      { label: "Address 2", value: "chabadHouseAddress2" },
      { label: "City", value: "chabadHouseCity" },
      { label: "State", value: "chabadHouseState" },
      { label: "Zip", value: "chabadHouseZip" },
      { label: "Country", value: "chabadHouseCountry" },
      { label: "Campus ID", value: "campusID" },
      { label: "Campus", value: "campusName" },
      { label: "Shliach Last Name", value: "shliachLastName" },
      { label: "Shliach ID", value: "shliachID" },
      { label: "Shliach First Name", value: "shliachFirstName" },
      { label: "Shlucha ID", value: "shluchaID" },
      { label: "Shlucha First Name", value: "shluchaFirstName" },
      { label: "Shliach Email", value: "shliachEmail" },
      { label: "Shliach Cell", value: "shliachCell" },
      { label: "Shlucha Email", value: "shluchaEmail" },
      ...flattenArray(
        Array.from({ length: maxEmailRecipientsCount }, (_, index) => [
          {
            label: `Recipient ${index + 1} First Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.firstName) || "",
          },
          {
            label: `Recipient ${index + 1} Last Name`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.lastName) || "",
          },
          {
            label: `Recipient ${index + 1} Email`,
            value: ({ emailRecipients }) =>
              (emailRecipients && emailRecipients[index]?.email) || "",
          },
        ])
      ),
    ];
  };

  const getKpiExportHeaders = () => [
    { label: "Chabad House ID", value: "chabadHouseID" },
    { label: "Chabad House", value: "chabadHouseName" },
    { label: "Campus ID", value: "campusID" },
    { label: "Campus", value: "campusName" },
    { label: "Shliach ID", value: "shliachID" },
    { label: "Shliach First Name", value: "shliachFirstName" },
    { label: "Shliach Last Name", value: "shliachLastName" },
    { label: "Shlucha ID", value: "shluchaID" },
    { label: "Shlucha First Name", value: "shluchaFirstName" },
    { label: "Shlucha Last Name", value: "shluchaLastName" },
    { label: "Shliach Email", value: "shliachEmail" },
    { label: "Shliach Cell", value: "shliachCell" },
    { label: "Shlucha Email", value: "shluchaEmail" },
    { label: "Shlucha Cell", value: "shluchaCell" },
    ...getKpiMetricsExportHeaders("priorMonthThisYearMetrics", "Current"),
    ...getKpiMetricsExportHeaders("priorMonthLastYearMetrics", "Previous"),
  ];

  const getKpiMetricsExportHeaders = (fieldName, label) => [
    {
      label: `${label} Total KPI Score`,
      value: `${fieldName}.totalScore`,
    },
    getValueForCategory(
      fieldName,
      label,
      "Students",
      "UniqueStudentsInteractedWith"
    ),
    getValueForCategory(
      fieldName,
      label,
      "Percentage",
      "PercentageOfJewishStudentsInteractedWith"
    ),
    getValueForCategory(fieldName, label, "Engagements", "TotalEngagements"),
    getValueForCategory(
      fieldName,
      label,
      "Frequency",
      "PercentageOfStudentsWith3PlusEngagements"
    ),
    getValueForCategory(
      fieldName,
      label,
      "Depth",
      "PercentageOfStudentsWith5PlusFlames"
    ),
  ];

  const getValueForCategory = (
    fieldName,
    label,
    metricShortName,
    metricLongName
  ) => ({
    label: `${label} ${metricShortName} KPI Score`,
    value: ({ [fieldName]: metrics }) =>
      metrics?.kpis.find((kpi) => kpi.category === metricLongName).score,
  });

  return (
    <div className="flex" style={{ columnGap: "16px" }}>
      <button
        className="btn custom-btn btn-accent flex flex-align-center"
        onClick={onAddLocation}
      >
        Add
      </button>
      <ExportCSVButton
        className="btn custom-btn btn-accent flex flex-align-center"
        fileName="Engagement_Portal_Locations"
        getExportData={() => getLocationsForExport("default")}
        getHeaders={getExportHeaders}
      />
      <ExportCSVButton
        className="btn custom-btn btn-accent flex flex-align-center"
        fileName="Engagement_Portal_KPI_Scores"
        getExportData={() => getLocationsForExport("kpi")}
        getHeaders={getKpiExportHeaders}
        title="Export KPI Scores"
      />
    </div>
  );
}

function Header() {
  const columns = [
    "Shliach",
    "Shlucha",
    "Chabad House",
    "Campus",
    "Member Since",
    "",
  ];

  return (
    <div className="engagement-locations-table-header">
      {columns.map((column, index) => (
        <p className="flex flex-align-center" key={index}>
          {column}
        </p>
      ))}
    </div>
  );
}

function Row(props) {
  const {
    location: {
      campusName,
      chabadHouseID,
      chabadHouseName,
      memberSince,
      shliachFirstName,
      shliachID,
      shliachLastName,
      shluchaFirstName,
      shluchaID,
    },
    onRemoveLocation,
  } = props;

  return (
    <div
      className="engagement-locations-table-row"
      onClick={() =>
        browserHistory.push(
          `/shluchim/${shliachID || shluchaID}/chabadHouse/${chabadHouseID}`
        )
      }
    >
      <p>{formatFullName(shliachFirstName, shliachLastName)}</p>
      <p>{formatFullName(shluchaFirstName, shliachLastName)}</p>
      <p>{chabadHouseName}</p>
      <p>{campusName}</p>
      <p>{moment(memberSince).format("MM/DD/YYYY")}</p>
      <p>
        <i
          className="link-text-secondary material-icons medium-text"
          onClick={(e) => {
            e.stopPropagation();
            onRemoveLocation();
          }}
          style={{ padding: "8px" }}
        >
          delete
        </i>
      </p>
    </div>
  );
}
