import React from "react";
import debounce from "lodash.debounce";

export default class TableFilters extends React.PureComponent {
  constructor(props) {
    super();

    const { keyword } = props.filters;

    this.state = {
      keyword,
    };
  }

  onChange = (name, value, debounce) => {
    this.setState(
      {
        [name]: value,
      },
      debounce ? this.onFilterDebounce : this.onFilter,
    );
  };

  onFilter = () => this.props.applyFilters(this.state);

  onFilterDebounce = debounce(this.onFilter, 500);

  render() {
    const { applySort, sortBy, sortOptions } = this.props;
    const { keyword } = this.state;

    return (
      <div className="flex flex-align-center flex-justify-space full-width">
        <div className="search-input mr-20">
          <input
            type="text"
            placeholder="Search"
            name="keyword"
            onChange={(event) =>
              this.onChange(event.target.name, event.target.value, true)
            }
            value={keyword}
          />
          <i className="material-icons accent-text-secondary flex flex-align-center">
            search
          </i>
        </div>
        <div className="flex flex-align-center">
          <p>Sort by</p>
          <select
            className="no-border-select accent-text-secondary ml-8"
            onChange={(evt) => applySort(evt.target.value)}
            name="sortBy"
            style={{ maxWidth: "240px", textOverflow: "ellipsis" }}
            value={sortBy}
          >
            {sortOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}
